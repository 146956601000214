import styled from "@emotion/styled"
import { useState } from "react"
import { Dropdown } from "shared/ui"
import { Button } from "shared/ui"
import { formStages } from "entities/Course/lib/CourseStatic.js"
import { CircularProgress, Typography } from "@mui/material"
import {
  giveStageAccess as giveStageRequest,
  massStatusUpdate,
  removeStageAccess as removeStageRequest,
} from "entities/Course/api/course"
import SuccessIcon from "images/icons/Success"
import FailIcon from "images/icons/Fail"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { IStage } from "entities/Course/api/course.types"
import * as Styled from "./styles/ParicipantMassAccess.styles"

const courseStatuses = {
  "": "",
  failed: "failed",
  in_progress: "in_progress",
  teacher: "teacher",
  moderator: "moderator",
  requested: "requested",
  banned: "banned",
}

const ParicipantMassAccess = ({ allParticipants, stages }: { allParticipants: number[]; stages: IStage[] }) => {
  const course = useAppSelector(state => state.course.course)
  const [isLoading, setIsLoading] = useState(false)
  const [stage, setStage] = useState()
  const [status, setStatus] = useState()
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const isDisableAccess = !allParticipants.length

  async function giveStageAccess() {
    if (!stage) return
    initialLoading()
    const payload = { participants: allParticipants }
    await giveStageRequest(stage, payload)
      .then(() => setSuccessMessage("Доступ дан"))
      .catch(() => setErrorMessage("Не удалось изменить состояние доступа"))
      .finally(() => setIsLoading(false))
  }

  async function removeStageAccess() {
    if (!stage) return
    initialLoading()
    const payload = { participants: allParticipants }
    await removeStageRequest(stage, payload)
      .then(() => setSuccessMessage("Доступ забран"))
      .catch(() => setErrorMessage("Не удалось изменить состояние категории"))
      .finally(() => setIsLoading(false))
  }
  const initialLoading = () => {
    setIsLoading(true)
    setSuccessMessage("")
    setErrorMessage("")
  }

  async function onStatus() {
    const payload = {
      course: course?.id,
      status: status,
      participants: allParticipants,
    }
    await massStatusUpdate(payload)
      .then(() => {
        setSuccessMessage("Успешно")
      })
      .catch(() => {
        setErrorMessage("Ошибка")
      })
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>Выдача доступов</Styled.Title>
      <Styled.BalanceRow>
        <Styled.AccessWrapper>
          <Dropdown
            id={"stage-select"}
            name={"participant__new-stage"}
            value={stage}
            onChange={e => {
              setStage(e.target.value)
              setSuccessMessage("")
              setErrorMessage("")
            }}
            options={formStages(stages)}
            label={"Этап с огр. доступом"}
            style={{ width: 280 }}
            disabled={isDisableAccess}
          />
        </Styled.AccessWrapper>
        <div>
          <Button
            variant="outlined"
            onClick={giveStageAccess}
            sx={{ marginBottom: "11px", width: 155 }}
            disabled={!stage || isLoading}
            size={"small"}
          >
            ВЫДАТЬ ДОСТУП
          </Button>
          <Button
            variant="outlined"
            onClick={removeStageAccess}
            sx={{ width: 155 }}
            disabled={!stage || isLoading}
            size={"small"}
          >
            ЗАБРАТЬ ДОСТУП
          </Button>
        </div>
      </Styled.BalanceRow>
      <Styled.BalanceRow>
        <div>
          <Dropdown
            id={"stage-select"}
            name={"participant__new-stage"}
            value={status}
            onChange={e => {
              setStatus(e.target.value)
              setSuccessMessage("")
              setErrorMessage("")
            }}
            options={courseStatuses}
            label={"Статус"}
            style={{ width: 280 }}
            disabled={isDisableAccess}
          />
        </div>
        <div>
          <Button variant="outlined" onClick={onStatus} disabled={!status || isLoading} size={"small"} width={155}>
            ЗАДАТЬ СТАТУС
          </Button>
        </div>
      </Styled.BalanceRow>
      <Styled.StatusWrapper>
        {isLoading && <CircularProgress size={26} sx={{ marginTop: "16px" }} />}
        {successMessage && (
          <>
            <Styled.IconWrapper>
              <SuccessIcon />
            </Styled.IconWrapper>
            <Typography sx={{ lineHeight: "20.4px", letterSpacing: "0.06px" }}>{successMessage}</Typography>
          </>
        )}
        {errorMessage && (
          <>
            <Styled.IconWrapper>
              <FailIcon />
            </Styled.IconWrapper>
            <Typography sx={{ lineHeight: "20.4px", letterSpacing: "0.06px" }}>{errorMessage}</Typography>
          </>
        )}
      </Styled.StatusWrapper>
    </Styled.Wrapper>
  )
}

export default ParicipantMassAccess
