import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useMemo, useRef } from "react"
import { Chip } from "@mui/material"
import { zeroPad } from "shared/util"
import { styled } from "@mui/material/styles"
import { ThemeType } from "shared/themes/default.types"

interface IProps {
  timerState: { hours: number; minutes: number; seconds: number; totalSeconds: number }
  initialTime: string
}

export const Timer = ({ timerState, initialTime }: IProps) => {
  const chipRef = useRef<HTMLDivElement>(null)
  const fullTime = useMemo(() => {
    const parsedTime = initialTime?.split(":")
    return +parsedTime[0] * 3600 + +parsedTime[1] * 60 + +parsedTime[2]
  }, [initialTime])

  const timeRemaining = useMemo(() => {
    isSegment(2) && blink()
    isSegment(4) && blink()
    isSegment(8) && blink()
    isSegment(16) && blink(3)
    if (fullTime / 16 + 0.5 <= timerState.totalSeconds && fullTime / 16 - 0.5 >= timerState.totalSeconds) {
      blink(3)
    }
    return `${zeroPad(timerState.hours)}:${zeroPad(timerState.minutes)}:${zeroPad(timerState.seconds)}`
  }, [timerState])

  function isSegment(segment: number) {
    return timerState.totalSeconds - 0.5 <= fullTime / segment && fullTime / segment <= timerState.totalSeconds + 0.5
  }

  function blink(repeat: number = 2) {
    if (chipRef.current) {
      chipRef.current.style.animationIterationCount = repeat.toString()
      chipRef.current.style.animationName = "blink"
      setTimeout(() => {
        if (chipRef.current) chipRef.current.style.animationName = ""
      }, repeat * 500)
    }
  }

  return (
    <ChipStyle
      ref={chipRef}
      label={<TimeRemaining timeRemaining={timeRemaining} />}
      // @ts-ignore
      variant="contained"
      icon={<AccessTimeIcon fontSize="medium" style={{ color: "#FFF" }} />}
    />
  )
}

const TimeRemaining = ({ timeRemaining }: { timeRemaining: string }) => {
  return (
    <div>
      <span>{timeRemaining}</span>
      <LabelActicle>
        <span>ч</span>
        <span>м</span>
        <span>с</span>
      </LabelActicle>
    </div>
  )
}

const ChipStyle = styled(Chip)(({ theme }: { theme: ThemeType }) => ({
  width: 130,
  height: 54,
  borderRadius: 30,
  padding: 8,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiChip-label": {
    marginLeft: 5,
    fontSize: "14px",
    color: "#FFF",
  },
  "@keyframes blink": {
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  cursor: "unset",
  animationDuration: "0.5s",
}))

const LabelActicle = styled("div")(() => ({
  color: "#FFF",
  display: "flex",
  gap: 13,
  paddingLeft: 3,
  marginTop: -6,
  "& > span": {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "300",
  },
}))
