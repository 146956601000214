import React, { useState } from "react"
import { Button } from "shared/ui"
import { styled } from "@mui/material/styles"
import { useTheme } from "shared/context"
import { Typography, TextField } from "@mui/material"
import { Dialog } from "@mui/material"
const ButtonDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(4),
}))

const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(6),
  width: "100%",
}))

export const SendNotification = ({ isOpen, onClose, createNotification }) => {
  const theme = useTheme()
  const [helperText, setHelperText] = useState(null)
  const [text, setText] = useState("")

  async function handleNotification() {
    try {
      await createNotification(text)
      setHelperText("Готово")
    } catch {
      setHelperText("Ошибка")
    }
  }

  function getTextColor() {
    return helperText === "Готово" ? theme.palette.success.main : theme.palette.error.main
  }

  function handleClose() {
    setHelperText(null)
    setText("")
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <MainDiv>
        <TextField
          id={"notification_input"}
          variant="outlined"
          value={text}
          onChange={e => setText(e.target.value)}
          helperText="Введите сообщение"
          fullWidth
          multiline
        />
        <ButtonDiv>
          <Button
            id={"sendNotificationButton"}
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleNotification}
          >
            Отправить уведомление
          </Button>
          <Typography sx={{ color: getTextColor() }} variant={"body1"} fontSize={"medium"} fontWeight={"medium"}>
            {helperText}
          </Typography>
        </ButtonDiv>
      </MainDiv>
    </Dialog>
  )
}
