import React, { useMemo } from "react"
import { styled } from "@mui/material/styles"

import {
  Avatar,
  Link,
  Grid,
  CardActions,
  CardContent as MuiCardContent,
  CardActionArea,
  Typography as MuiTypography,
  Chip,
} from "@mui/material"
import { AvatarGroup as MuiAvatarGroup } from "@mui/material"
import { spacing } from "@mui/system"
import { useTheme } from "shared/context"
import useStyles from "./styles"

import { Card, ProgressBar } from "shared/ui"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded"

const CardContent = styled(MuiCardContent)`
  padding: 0px;
  padding-top: 16px;
`

const Typography = styled(styled(MuiTypography)(spacing))`
  font-family: Roboto;
`

export function CatalogItem(props) {
  const classes = useStyles()
  const theme = useTheme()

  const AvatarGroup = styled(MuiAvatarGroup)`
    margin-left: ${theme.spacing(2)};
  `

  const bottomColor = "#858585"
  const today = useMemo(() => {
    return new Date()
  }, [props])

  function getStyles() {
    let styles = { height: "100%" }
    if (props.visibility === "visible") {
      styles.opacity = 1.0
    } else {
      styles.opacity = 0.5
    }
    return styles
  }

  function isAccessible() {
    if (!props.status) {
      return props.visibility === "visible"
    }
    if (!props.start) {
      return props.visibility === "visible" || props.status === "moderator" || props.status === "admin"
    }
    return (
      (props.visibility === "visible" && new Date(props.start) <= today) ||
      props.status === "moderator" ||
      props.status === "admin"
    )
  }

  function stringifyDates() {
    if (!props.start || !props.finish) {
      return "Без ограничений по времени"
    }
    return props.stringifyDates(props.start, props.finish)
  }

  return (
    <Card style={getStyles()}>
      <CardActionArea
        component={Link}
        href={`/catalog/course/${props.slug}`}
        disabled={!isAccessible()}
        style={{ height: "100%" }}
      >
        <div style={{ width: "100%", height: "40%" }}>
          <Chip label={"Курс"} className={classes.courseCardChip} />
          <img src={props.image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        </div>
        <div style={{ height: "45%", paddingLeft: 24, paddingRight: 24 }}>
          <CardContent>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
              {props.variant === "progress" && props.progress.completed && (
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <CheckCircleIcon style={{ color: "#15C026" }} />
                  <Typography style={{ color: "#15C026" }}>Курс пройден</Typography>
                </div>
              )}
            </div>
          </CardContent>
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2" style={{ fontSize: 20 }}>
                  {props.title}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
          <CardContent>
            <Typography mb={4} color="textSecondary" component="p" style={{ fontSize: 16 }}>
              {props.subtitle}
            </Typography>
          </CardContent>
        </div>
        {props.variant === "catalog" && (
          <CardActions
            style={{
              justifyContent: "space-between",
              borderTop: `1px solid #E0E0E0`,
              height: "15%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 8 }}>
              <AccountCircleRoundedIcon style={{ color: bottomColor }} />
              <Typography variant={"caption"} sx={{ color: bottomColor }}>
                {props.participants_num}
              </Typography>
              <ScheduleRoundedIcon style={{ color: bottomColor }} />
              <Typography variant={"caption"} sx={{ color: bottomColor }}>
                {stringifyDates()}
              </Typography>
            </div>
            <AvatarGroup max={3}>
              {props.affiliations_info && props.affiliations_info.map(e => <Avatar alt="Logo" src={e.icon_logo} />)}
            </AvatarGroup>
          </CardActions>
        )}
        {props.variant === "progress" && (
          <CardActions
            style={{
              justifyContent: "space-between",
              borderTop: `1px solid #E0E0E0`,
              height: "15%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
          >
            <div style={{ width: "100%" }}>
              <ProgressBar
                color="primary"
                variant="determinate"
                value={props.max_score ? Math.round(((props.progress.score || 0) / props.max_score) * 100) : 0}
              />
              <Typography variant={"caption"}>
                {`${props.progress.score || 0}/${props.max_score || 0} (минимум: ${props.min_score})`}
              </Typography>
            </div>
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  )
}
