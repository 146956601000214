import React from "react"
import { Typography } from "@mui/material"
import { StatusConnectionMainDiv, StatusIndicator } from "./Iot.styles"
import { useTheme } from "shared/context"

export const HardwareConnectionStatus = ({ status }: { status: boolean }) => {

  const theme = useTheme()

  return (
    <StatusConnectionMainDiv>
      <Typography variant={"caption"} fontWeight={"medium"}>Статус</Typography>
      <StatusIndicator sx={{ backgroundColor: status ? theme.palette.success.main : theme.palette.error.main }}></StatusIndicator>
    </StatusConnectionMainDiv>
  )
}