const SliderButtonArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M8.03114 2.96782C8.10106 3.03749 8.15654 3.12029 8.19439 3.21145C8.23225 3.30261 8.25173 3.40036 8.25173 3.49907C8.25173 3.59778 8.23225 3.69551 8.19439 3.78668C8.15654 3.87784 8.10106 3.96064 8.03114 4.03032L4.81301 7.24844L14.0005 7.24844C14.1994 7.24844 14.3902 7.32746 14.5308 7.46811C14.6715 7.60876 14.7505 7.79953 14.7505 7.99844C14.7505 8.19735 14.6715 8.38812 14.5308 8.52877C14.3902 8.66942 14.1994 8.74844 14.0005 8.74844L4.81301 8.74844L8.03114 11.9678C8.17204 12.1087 8.25119 12.2998 8.25119 12.4991C8.25119 12.6983 8.17204 12.8894 8.03114 13.0303C7.89024 13.1712 7.69915 13.2504 7.49989 13.2504C7.30063 13.2504 7.10954 13.1712 6.96864 13.0303L2.46864 8.53032C2.39872 8.46064 2.34324 8.37784 2.30539 8.28668C2.26753 8.19552 2.24805 8.09778 2.24805 7.99907C2.24805 7.90035 2.26753 7.80261 2.30539 7.71145C2.34324 7.62029 2.39872 7.53749 2.46864 7.46781L6.96864 2.96782C7.03832 2.8979 7.12111 2.84242 7.21228 2.80456C7.30344 2.76671 7.40118 2.74722 7.49989 2.74722C7.5986 2.74722 7.69634 2.76671 7.7875 2.80456C7.87867 2.84242 7.96146 2.8979 8.03114 2.96782Z"
        fill="#1565C0"
      />
    </svg>
  )
}

export default SliderButtonArrowIcon
