import React, { useState, useContext } from "react";

var ContentStateContext = React.createContext();

function ContentProvider({ children }) {

  const [current, setCurrent] = useState({ section: 1, subsection: 1 })

  return (
    <ContentStateContext.Provider value={{ current, setCurrent }}>
      {children}
    </ContentStateContext.Provider>
  );
}

function useContentState() {
  var context = useContext(ContentStateContext);
  if (context === undefined) {
    throw new Error("useContentState must be used within a ContentProvider");
  }
  return context;
}

export { useContentState, ContentProvider };
