import { styled } from "@mui/styles"
import { FormHelperText } from "@mui/material"

export const FlexRow = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
}))

export const TimerWrapper = styled(FlexRow)(() => ({
  zIndex: 1000,
  position: "fixed",
  top: 100,
  right: 50,
  gap: 40,
}))

export const ScoreWrapper = styled(FlexRow)(() => ({
  width: 800,
  justifyContent: "flex-start",
  alignContent: "flex-start",
}))

export const Container = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  marginLeft: "5vw",
  marginRight: "5vw",
}))

export const FormHelper = styled(FormHelperText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  fontFamily: "Roboto",
  textAlign: "left",
  margin: "25px 0 0 0",
  lineHeight: "44px",
  paddingLeft: "20px",
}))
