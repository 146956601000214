import React from 'react';
import {withRouter} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Buttons} from "./Buttons/Buttons";
import {IconButtons} from "./IconButtons/IconButtons";
import {Texts} from "./Texts/Texts";

const UiComponents = () => {

    const StyledDiv = styled('div')({
        width: "100vw"
    })

    return (
        <StyledDiv>
            <Texts/>
            <Buttons/>
            <IconButtons/>
        </StyledDiv>
    );
};

export default withRouter(UiComponents);
