import { Card, styled } from "@mui/material"
import { boxShadow } from "../../../shared/ui"

export const CardSectionsSettings = styled(Card)(({ theme }) => ({
  padding: theme.spacing(0),
  width: "100%",
  boxShadow: "none",
  background: "transparent",
}))

export const CardTrajectoryContentItem = styled(Card)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: "#FFF",
  boxShadow: boxShadow,
  marginTop: theme.spacing(6),
}))

export const SectionButtonContainer = styled("div")(({ theme }) => ({}))

export const EditSectionModal = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(4),
}))

export const ContentItemHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}))

export const TrajectoryContentItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
}))

export const ButtonsContentItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(4),
}))

export const CreateSectionModal = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
  marginTop: theme.spacing(12),
}))
