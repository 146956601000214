import { TimerType } from "../../Test/model/test.types"

export const processAnswers = (answers: { [p: number]: string | string[] }) => {
  const normalizeAnswer = (answer: string) => {
    // Нормализация кавычек/апострофов, дефисов/тире
    const normalized = answer
      .replace(/[`'’′]/g, "'") // Замена всех апострофов/кавычек на '
      .replace(/[\u002D\u2010\u2011\u2012\u2013\u2014]/g, "-") // Замена всех дефисов/тире на дефис
      .trim() // Убираем пробелы в начале и в конце
      .replace(/\s+/g, " ") // Замена нескольких пробелов на один
      .replace(/\.$/, ""); // Убираем точку в конце

    return normalized;
  };

  const finishAnswers: { question_id: number; answer: string[] }[] = [];

  Object.keys(answers).forEach(item => {
    let answer = answers[Number(item)];

    if (typeof answer === "string") {
      answer = [normalizeAnswer(answer)];
    } else if (Array.isArray(answer)) {
      answer = answer.map(ans => normalizeAnswer(ans));
    }

    finishAnswers.push({ question_id: Number(item), answer: answer });
  });

  return finishAnswers;
};


export function parseTime(time: string) {
  const expiryTimestamp = new Date()
  if (time) {
    const parsedTime = time.split(":").map(item => Number(item))
    expiryTimestamp.setHours(parsedTime[0])
    expiryTimestamp.setMinutes(parsedTime[1])
    expiryTimestamp.setSeconds(parsedTime[2])
  }
  return expiryTimestamp
}

export function timerRestart(offestTime: number[], timer: TimerType) {
  if (!offestTime) return
  let timerOffset = new Date()
  timerOffset.setHours(timerOffset.getHours() + offestTime[0])
  timerOffset.setMinutes(timerOffset.getMinutes() + offestTime[1])
  timerOffset.setSeconds(timerOffset.getSeconds() + offestTime[2])
  timer.restart(timerOffset)
}

export const formatPlayerTime = (time: number): string => {
  if (isNaN(time)) return "0:00"
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
  return `${minutes}:${formattedSeconds}`
}
