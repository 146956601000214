import { ITeam } from "../model/Team.types"
import { TableRow, TableCell, Typography } from "@mui/material"
import { MemberRow } from "entities/Team"

interface IProps {
  progress: IMember[]
  isEdit: boolean
  setErrorMsg: () => void
  modules: string[]
  team: ITeam
}

interface IMember {
  id: number
  email: string
  name: string
  user_id: string
  member_id: string
  category: string
  status: string
  individual_module_progress: IModuleProgress[]
}

interface IModuleProgress {
  module_info: { title: string }
  cell_color: boolean
  score: number
}

interface IModules {
  modules: string[]
  index: number
  progress: IMember[]
}

const MyTeamsTable = ({ progress, isEdit, setErrorMsg, modules, team }: IProps) => {
  const formMember = (member: IMember) => ({
    participant: { email: member.email, full_name: member.name, user: member.user_id },
    category: member.category,
    status: member.status,
    email: member.email,
    id: member.member_id,
  })

  return progress.map((member, index) => {
    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={member.id}>
        <MemberRow team={team} member={formMember(member)} isEdit={isEdit} setErrorMsg={setErrorMsg} />
        <ModuleBody modules={modules} index={index} progress={progress} />
      </TableRow>
    )
  })
}

const ModuleBody = ({ modules, index, progress }: IModules) => {
  function getScore(module: string, index: number) {
    return progress[index]?.individual_module_progress.find(
      (moduleProgress: IModuleProgress) => moduleProgress.module_info.title === module
    )
  }

  if (!modules) return

  return modules.map(module => (
    <TableCell
      align={"center"}
      key={module}
      style={{
        backgroundColor: getScore(module, index)?.cell_color ? "#E6FCDE" : "#FFDEDE",
        paddingBlock: "40px",
        minWidth: "164px",
      }}
    >
      <Typography variant={"caption"} fontWeight={"medium"}>
        {getScore(module, index)?.score || 0}
      </Typography>
    </TableCell>
  ))
}
export { MyTeamsTable }
