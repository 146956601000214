import { styled } from "@mui/material/styles"
import { boxShadow } from "../../../shared/ui"
import { Card } from "@mui/material"

export const MainEditorContainer = styled("div")(({ theme }) => ({
  boxShadow: boxShadow,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  backgroundColor: "#FFF",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(8),
}))

export const CreateQuestionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
}))

export const QuestionItem = styled(Card)(({ theme }) => ({
  backgroundColor: "#FFF",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  padding: theme.spacing(6),
  boxShadow: boxShadow,
  marginTop: theme.spacing(4),
}))

export const QuestionHeader = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}))

export const QuestionHeaderItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(4),
}))

export const QuestionContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
  marginTop: theme.spacing(12),
}))

export const ContentWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%",
}))

export const ButtonsContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(20),
  marginLeft: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
}))

export const SaveButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(4),
}))

export const VariantContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
  marginLeft: theme.spacing(3),
}))
