import * as Styled from "./Member.style.ts"
import {
  memberCategories as categories,
  memberColors as colors,
  getMemberColor as getColor,
  getMemberLabel as getLabel,
  uniqueStatuses,
  statusMapper,
} from "../static/MemberStatus.ts"

export const MemberStatus = ({ category, status }) => {
  if (uniqueStatuses.includes(status)) return <Styled.Typography>{statusMapper[status]}</Styled.Typography>
  return (
    <Styled.MemberChip
      sx={{ backgroundColor: colors[getColor(category)] }}
      label={
        <Styled.ChipTypography variant={"caption"} fontWeight={"medium"}>
          {categories[getLabel(category)] || category}
        </Styled.ChipTypography>
      }
    />
  )
}
