import React, { useState, useEffect, useRef } from "react"
import { Grid } from "@mui/material"

import { EventItem } from "entities/Course"
import { CatalogItem } from "entities/Course"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useSelector } from "react-redux"

export const MyCourses = () => {
  const user = useSelector(state => state.user.user) || null
  const [rows, setRows] = useState([])
  const controllerRef = useRef()

  async function loadCourses() {
    controllerRef.current = new AbortController()
    try {
      const progress = await axios
        .get("/content/progress/", {
          params: { participant__user__id: user.id },
          signal: controllerRef.current.signal,
        })
        .then(res => res.data)

      progress
        .filter(
          e =>
            e.participant.status === "teacher" ||
            e.participant.status === "moderator" ||
            e.participant.status === "in_progress"
        )
        .forEach(e => {
          let course = e.participant.course
          axios.get(`/content/courses/`, { params: { id: course }, signal: controllerRef.current.signal }).then(r => {
            axios
              .get("/content/progress/my_progress/", {
                params: { course: course },
                signal: controllerRef.current.signal,
              })
              .then(p => {
                r.data[0].progress = p.data
                setRows(prev => {
                  return [...prev, r.data[0]]
                })
              })
          })
        })
    } catch {}
  }

  useEffect(() => {
    loadCourses()

    return () => controllerRef.current.abort()
  }, [])

  const visibilityFilter = e => {
    return e.visibility !== "hidden" || user.status === "moderator" || user.status === "admin"
  }

  if (!user.status) return null

  return (
    <div style={{ width: "100%" }}>
      {rows
        .filter(c => c.type === "event")
        .filter(visibilityFilter)
        .filter(a => a.progress.participant?.status !== "failed")
        .map(event => (
          <div>
            <EventItem key={event.id} post={event} />
          </div>
        ))}
      <Grid container={true} spacing={3} style={{ marginBottom: "40px" }}>
        {rows
          .filter(c => c.type === "course")
          .filter(visibilityFilter)
          .filter(a => a.progress.participant?.status !== "failed")
          .map(c => (
            <Grid item xs={12} md={6} lg={4} key={c.id}>
              <CatalogItem {...c} status={user.status} variant="progress" />
            </Grid>
          ))}
      </Grid>
    </div>
  )
}
