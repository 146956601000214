import React, { useRef, useState } from "react"
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { sortItems } from "entities/Course/lib/CourseStatic"
import { ParticipantRow } from "entities/Participant/ParticipantRow"
import VisibilitySensor from "react-visibility-sensor"
import { IconButton as SharedIconButton } from "shared/ui"
import { HeaderCell, NotificationIcon, ParticipantTableContainer, SortIcons } from "./ParticipantTable.style.ts"
import { ScrollButtons, TableLoader } from "shared/assets"
import { filterNumericFields } from "entities/Course/lib/filterNumericFields"
import SendSingleNotification from "features/Notifications/SendSingleNotification"

const participantFields = [
  { label: "ФИО", value: "name" },
  { label: "Почта", value: "email" },
  { label: "Статус", value: "status" },
  { label: "Категория", value: "category" },
  { label: "Этап с огр. доступом", value: "stages" },
  { label: "Баллы", value: "score" },
  { label: "Статус прохождения", value: "completed" },
  { label: "Статус активности", value: "is_active" },
]

const ParticipantTable = ({
  tasks,
  courseProgress,
  variantValue,
  isLoading,
  sortBy,
  setSortBy,
  loadMore,
  hasNextPage,
}) => {
  const [openDialog, setOpenDialog] = useState(null)
  const tableContainerRef = useRef(null)

  function handleDialog(e) {
    if (!openDialog && e) {
      const params = {
        title: variantValue.label,
        user: e.participant.user,
      }
      setOpenDialog({ ...params })
    } else {
      setOpenDialog(false)
    }
  }

  const handleScrolledToBottom = isBottomVisible => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      loadMore()
    }
  }

  return (
    <ParticipantTableContainer>
      <ScrollButtons tableRef={tableContainerRef} />
      <TableContainer ref={tableContainerRef} sx={{ backgroundColor: "#FFF", height: "80vh" }}>
        <Table stickyHeader sx={{ width: "100%", overflowX: "auto" }} size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {participantFields.map(e => (
                <TableCell align="center">
                  <HeaderCell>
                    <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: "14px" }}>
                      {e.label}
                    </Typography>
                    <SortIcons>
                      <IconButton
                        size="medium"
                        sx={{ color: "#313439" }}
                        onClick={() => setSortBy({ type: e.value, direction: -1 })}
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="medium"
                        sx={{ color: "#313439" }}
                        onClick={() => setSortBy({ type: e.value, direction: 1 })}
                      >
                        <ArrowDropUpIcon fontSize="small" />
                      </IconButton>
                    </SortIcons>
                  </HeaderCell>
                </TableCell>
              ))}
              {tasks &&
                tasks.map((t, index) => (
                  <TableCell align="center" key={t + index}>
                    <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: "14px" }}>
                      {t}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
            <TableBody>
              {courseProgress &&
                courseProgress
                  .filter(filterNumericFields)
                  .sort((a, b) => sortItems(a, b, sortBy))
                  .map(e => (
                    <TableRow key={e.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center" sx={{ paddingLeft: 7 }}>
                        <SharedIconButton
                          id={`notification-${e.participant.id}`}
                          color={"inherit"}
                          size={"medium"}
                          onClick={() => handleDialog(e)}
                        >
                          <NotificationIcon fontSize="small" />
                        </SharedIconButton>
                      </TableCell>
                      <ParticipantRow
                        tasks={tasks}
                        participant={e.participant}
                        score={e.score}
                        completed={e.completed}
                        module_progress={e.module_progress}
                      />
                    </TableRow>
                  ))}
            </TableBody>
          </VisibilitySensor>
        </Table>
        <SendSingleNotification
          user={openDialog?.user}
          title={openDialog?.title}
          isOpen={openDialog}
          onClose={handleDialog}
        />
      </TableContainer>
      {isLoading && <TableLoader>Таблица прогружается. Пожалуйста, подождите</TableLoader>}
    </ParticipantTableContainer>
  )
}

export default ParticipantTable
