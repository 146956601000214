import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TextField } from "@mui/material"
import { PhoneField } from "shared/ui/PhoneField/PhoneField"

export const FormPhoneInput = props => {
  const { name, label, type, required } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const errorMessage = errors[name] ? errors[name].message : ""

  return (
    <Controller
      as={TextField}
      name={name}
      control={control}
      render={({ field }) => (
        <PhoneField
          {...field}
          error={!!errorMessage}
          helperText={errorMessage}
          label={label}
          fullWidth={true}
          required={!!required}
        />
      )}
    />
  )
}
