import { styled } from "@mui/material/styles"
import { Typography as MuiTypography } from "@mui/material"
import { ThemeType } from "../../shared/themes/default.types"

export const Typography = styled(MuiTypography)(() => ({
  fontSize: 18,
  fontWeight: 300,
}))

export const Strong = styled("span")(() => ({
  fontWeight: 400,
}))

export const Container = styled("div")(() => ({
  display: "flex",
  paddingInline: "48px 32px",
}))

export const Wrapper = styled("div")(() => ({
  maxWidth: 614,
}))

export const AnswersListWrapper = styled("div")(() => ({
  marginTop: 24,
  maxHeight: 400,
  overflowY: "hidden",

  "&:hover": {
    overflowY: "auto",
  },
}))

export const AnswersList = styled("ul")(() => ({}))

export const AnswerItem = styled("li")(() => ({
  marginBottom: 5,
}))

export const Title = styled(MuiTypography)(({ theme }) => ({
  fontSize: "24px",
  marginBottom: theme.spacing(8),
  marginLeft: 136,
}))

export const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  "& :not(:last-child)": {
    marginBottom: theme.spacing(4),
  },
}))

export const IconWrapper = styled("div")(() => ({
  marginRight: 48,
}))

export const LeftTimeWrapper = styled("div")(() => ({
  paddingTop: 32,
  display: "flex",
  gap: 14,
}))

export const LeftTimeTitle = styled(MuiTypography)(() => ({
  color: "#125096",
  fontSize: "18px",
  lineHeight: "24px",
}))

export const LeftTimeText = styled(MuiTypography)(() => ({
  fontSize: "20px",
  lineHeight: "24px",
  "&:not(:last-child)": {
    marginRight: 50,
  },
}))

export const AgreementWrapper = styled("div")(() => ({
  paddingTop: 40,
  display: "flex",
  gap: 12,
}))

export const AgreementButton = styled("span")(({ theme, isCheck }: { isCheck: boolean; theme?: ThemeType }) => ({
  position: "relative",
  backgroundColor: "white",
  borderRadius: "50%",
  width: 24,
  height: 24,
  border: "1px solid",
  borderColor: isCheck ? theme?.palette.primary.dark : theme?.palette.additional.main,
  cursor: "pointer",

  "&::after": {
    content: "''",
    display: "block",
    transition: "opacity 0.2s ease-out",
    opacity: isCheck ? "1" : "0",
    backgroundColor: theme?.palette.primary.main,
    width: 11,
    height: 11,
    borderRadius: "50%",
    translate: "50% 50%",
  },
}))

export const AgreementText = styled(MuiTypography)(() => ({
  color: "#313439",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "155%",
}))
