export const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.6665 4.66797H3.99984C3.64622 4.66797 3.30708 4.80844 3.05703 5.05849C2.80698 5.30854 2.6665 5.64768 2.6665 6.0013V12.0013C2.6665 12.3549 2.80698 12.6941 3.05703 12.9441C3.30708 13.1942 3.64622 13.3346 3.99984 13.3346H9.99984C10.3535 13.3346 10.6926 13.1942 10.9426 12.9441C11.1927 12.6941 11.3332 12.3549 11.3332 12.0013V11.3346"
        stroke="#1565C0"
        stroke-width="1.77778"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6667 3.3334L12.6667 5.3334M13.59 4.39007C13.8526 4.12751 14.0001 3.77139 14.0001 3.40007C14.0001 3.02875 13.8526 2.67264 13.59 2.41007C13.3274 2.14751 12.9713 2 12.6 2C12.2287 2 11.8726 2.14751 11.61 2.41007L6 8.00007V10.0001H8L13.59 4.39007Z"
        stroke="#1565C0"
        stroke-width="1.77778"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
