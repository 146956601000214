import * as yup from "yup"

export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Заполните это поле.")
    .min(2, "Поле должно содержать не менее 2 символов.")
    .max(32, "Превышен лимит символов для ввода текста. Максимальное количество символов - 32."),
  contact: yup.string().required("Заполните это поле."),
  organization_type: yup.string().required("Выберите хотя бы один вариант."),
  request_variants: yup.string().required("Выберите хотя бы один вариант."),
  organization_name: yup
    .string()
    .required("Заполните это поле.")
    .max(100, "Превышен лимит символов для ввода текста. Максимальное количество символов - 100."),
  text_field_organization_type: yup.string().when("organization_type", (organizationTypes, schema) => {
    return organizationTypes[0]?.split(";").includes("Другое (указать)")
      ? schema
          .required("Заполните это поле.")
          .max(100, "Превышен лимит символов для ввода текста. Максимальное количество символов - 100.")
      : schema
  }),
  text_field_request_variants: yup.string().when("request_variants", (requestVariants, schema) => {
    return requestVariants[0]?.split(";").includes("Другое (указать)")
      ? schema
          .required("Заполните это поле.")
          .max(100, "Превышен лимит символов для ввода текста. Максимальное количество символов - 100.")
      : schema
  }),
})
