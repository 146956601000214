import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  interactTable: {
    marginTop: 24,
    marginBottom: 24,
  },
  interactTableTh: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 400,
    textAlign: "center",
    borderBottom: "1px solid #b3b3b3 !important",
  },
  interactTableTr: {
    backgroundColor: "#eff2f9",
  },
  interactTableTd: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 100,
    borderColor: "#fff",
  },
  interactTableInput: {
    backgroundColor: "#fff",
    margin: "7px 0",
    borderRadius: 6,
    fontFamily: "Roboto",
  },
  contentText: {
    "& > p": {
      fontSize: 18,
      lineHeight: "150%",
      marginTop: 18,
      marginBottom: 18,
    },
    "& > h2": {
      marginTop: 48,
      fontSize: 20,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    "& > h4": {
      marginTop: 48,
      fontSize: 16,
      fontWeight: 400,
      textTransform: "uppercase",
    },
  },
}))
