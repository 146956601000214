import React, { useEffect, useRef } from "react"
import * as Styled from "./ui/TeamOfOrganization.styles"
import { Grid, Typography } from "@mui/material"
import { useTheme } from "../../shared/context"
import { MentorItem } from "./ui/MentorItem"
//@ts-ignore
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { styled } from "@mui/material/styles"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  props: {
    index: number
    background_image: string
    main_block: {
      title: {
        text: string
        color: string
      }
      text: {
        text: string
        color: string
      }
      background_color: string
    }
    items: {
      full_name?: string
      about?: string
      avatar?: string
    }[]
  }
}

export const TeamOfOrganization = ({ handleChangeRef, props }: IProps) => {
  const theme = useTheme()
  const ref = useRef<any>(null)

  useEffect(() => {
    handleChangeRef(ref, props.index)
  }, [ref])

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.xs,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Styled.TeamOfOrgMainDiv sx={{ backgroundImage: `url(${props.background_image})` }} ref={ref}>
      <Grid
        container
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: "row",
            wlexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(6),
            [theme.breakpoints.down("sm")]: { gap: theme.spacing(4), justifyContent: "space-between" },
          },
        }}
      >
        <Styled.DescriptionDivContainer
          sx={{
            background: props.main_block.background_color || "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)",
          }}
        >
          <Styled.StyledSubtitle
            variant={"h3"}
            sx={{
              alignSelf: "flex-start",
              color: props.main_block.title.color || "#000",
            }}
          >
            {props.main_block.title.text || ""}
          </Styled.StyledSubtitle>
          <Typography
            sx={{
              color: props.main_block.text.color || "#000",
              marginTop: theme.spacing(8),
              [theme.breakpoints.down("xs")]: { marginTop: theme.spacing(6) },
            }}
            variant={"body1"}
            fontWeight={"medium"}
            fontSize={"medium"}
          >
            {props.main_block.text.text || ""}
          </Typography>
        </Styled.DescriptionDivContainer>
        <SliderDiv className="slick-slider">
          <Slider {...settings}>
            {!!props.items && props.items.map((e, index) => <MentorItem key={index} mentorItem={e} />)}
          </Slider>
        </SliderDiv>
      </Grid>
    </Styled.TeamOfOrgMainDiv>
  )
}

export const SliderDiv = styled("div")(({ theme }) => ({
  "& .slick-list": {
    "& .slick-slide > div": {
      padding: "0 8px",
    },
  },
  height: "auto",
  width: "65%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: `${0} ${theme.spacing(4)}`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: `${0} ${0}`,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    padding: `${0} ${theme.spacing(4)}`,
  },
}))
