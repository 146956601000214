import InfoTooltip from "shared/ui/InfoTooltip"
import { styled } from "@mui/material/styles"
import { ThemeType } from "shared/themes/default.types"

const EditRolesTooltip = () => {
  return <InfoTooltip text={<TooltipText />} />
}

const TooltipText = () => (
  <Text>
    <Black>Участники</Black> команды отмечены <Green>зелёным</Green> цветом. <Black>Модераторы</Black> команды состоят в
    команде, но не входят в число <Black>участников</Black>. Поэтому они отмечены <Orange>оранжевым</Orange> цветом.{" "}
    <br />
    Счётчик показывает, сколько сейчас <Black>участников</Black> в команде из максимально возможного количества{" "}
    <Black>участников</Black>, установленного организаторами мероприятия.
  </Text>
)

const Text = styled("span")(() => ({
  color: "#313439",
  lineHeight: "155%",
}))

const Green = styled("span")(({ theme }: { theme?: ThemeType }) => ({
  color: theme?.palette.status.student,
}))
const Orange = styled("span")(() => ({
  color: "#FFA000",
}))
const Black = styled("span")(() => ({
  color: "#000",
  fontStyle: "normal",
}))

export default EditRolesTooltip
