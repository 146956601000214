import React, { useEffect, useRef, useState } from "react"
import * as Styled from "./ui/Course.style.ts"
import HelperModal from "./ui/HelperModal.js"
import { CircularProgress, Typography } from "@mui/material"
import { Button, Autocomplete } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { sortStages } from "entities/Course"
import { useCourseForm } from "entities/Course/model/useCourseForm.js"
import { CourseFilters } from "./ui/CourseFilters.js"
import { TableTotalCount as ParticipantsCount } from "shared/assets"
import { getCourse } from "entities/Course/api/course"
import { trimFilterValue } from "entities/Statistic/lib/filter"
import ManageStats from "./ui/ManageStats.js"
import ParticipantTable from "./ui/ParticipantTable"
import HelperTableModal from "./ui/HelperTableModal.js"
import formCourseStatistic from "entities/Course/lib/formCourseStatistic.js"

function parseProgress(progresses) {
  for (let index = 0; index < progresses.length; index++) {
    let moduleProgress = [...progresses[index].module_progress]
    progresses[index].module_progress = Object.fromEntries(moduleProgress.map(p => [p.module_info.title, p]))
    progresses[index].info = progresses[index].participant.info
  }
  return progresses
}

export const CourseStatistics = () => {
  const { fieldValue, handleFieldValue, setFieldValue } = useCourseForm()
  const [courseProgress, setCourseProgress] = useState([])
  const [tasks, setTasks] = useState([])
  const [modules, setModules] = useState({ "": "" })
  const [isLoading, setIsLoading] = useState(false)
  const [variants, setVariants] = useState([])
  const [variantValue, setVariantValue] = useState(null)
  const [infoFields, setInfoFields] = useState([])
  const [showStatistics, setShowStatistics] = useState(null)
  const [course, setCourse] = useState(null)
  const [availableCategories, setAvailableCategories] = useState(null)
  const [nextPage, setNextPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [totalCount, setTotalCount] = useState(null)
  const [isOpenHelper, setIsOpenHelper] = useState(false)
  const [isOpenTableHelper, setIsOpenTableHelper] = useState(false)
  const [sortBy, setSortBy] = useState({ type: "score", direction: -1 })
  const [personalModules, setPersonalModules] = useState({})
  const [isTriggerFilter, setIsTriggerFilter] = useState(false)
  const controllerRef = useRef()

  useEffect(() => {
    if (!variantValue?.id) return
    getCourseInfo()
  }, [variantValue])

  useEffect(() => {
    axios.get("content/actions/participants/my_courses/?for_statistics=true").then(res => {
      setVariants(res.data.map(e => ({ id: e.course, label: e.course_title, status: e.status })))
    })
  }, [])

  async function getCourseInfo() {
    setIsLoading(true)
    try {
      const response = await getCourse(variantValue.id)
      setCourse({
        ...response,
        stages: response.stages.filter(e => !e.is_empty && e.access_type === "restricted").sort(sortStages),
      })
      if (response.use_info) {
        setInfoFields(
          response.info_fields.sort((a, b) => (a.index > b.index ? 1 : -1)).map(e => ({ id: e.id, title: e.title }))
        )
      }
      if (!response.template_props) return
      let courseProps = JSON.parse(response.template_props)
      if (courseProps.categories) {
        setAvailableCategories([...courseProps.categories.split(",").map(category => category.trim()), "Без категории"])
      } else {
        setAvailableCategories(null)
      }
      await getModules()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  async function getModules() {
    controllerRef.current = new AbortController()
    try {
      const response = await axios
        .get(`/content/modules/?course=${variantValue.id}&for_statistics=true`, {
          signal: controllerRef.current.signal,
        })
        .then(res => res.data)
      let modules = {}
      response.forEach(module => {
        modules = { ...modules, [module.title]: module.id }
      })
      setModules({ "": "", "Общий балл": "-1", ...modules })
      const newTasks = Object.keys(modules)
      setTasks(newTasks)
      await formPersonalModules()
    } catch {}
  }

  const formPersonalModules = async () => {
    controllerRef.current = new AbortController()
    let result = {}
    const response = await axios
      .get(`/content/modules/?course=${variantValue.id}`, {
        signal: controllerRef.current.signal,
      })
      .then(res => res.data)
    const filteredModules = response.filter(module => module.type === "personal")
    filteredModules.forEach(module => {
      result = { ...result, [module.title]: module.id }
    })
    setPersonalModules(result)
  }

  function getCourseProgress() {
    onReset()
    loadMore(true)
  }

  function loadMore(isFirstPage) {
    controllerRef.current = new AbortController()
    setIsLoading(true)
    axios
      .get(`/content/progress/`, {
        params: {
          page: isFirstPage ? 1 : nextPage,
          course: variantValue.id,
          ...trimFilterValue(fieldValue),
        },
        signal: controllerRef.current.signal,
      })
      .then(res => {
        if (!res.data.results) {
          setCourseProgress([])
          setTotalCount(0)
          return
        }
        const resultProgress = isFirstPage ? res.data.results : [...courseProgress, ...parseProgress(res.data.results)]
        setCourseProgress(resultProgress)
        setTotalCount(res.data.count)
        setShowStatistics(true)
        setIsLoading(false)
        setHasNextPage(res.data.next)
        setNextPage(prev => prev + 1)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleChooseCourse = (event, newValue) => {
    onReset()
    setFieldValue({})
    setVariantValue(newValue)
    setShowStatistics(false)
    setIsTriggerFilter(false)
  }

  const onReset = () => {
    onAbort()
    setCourseProgress([])
    setTotalCount(null)
    setShowStatistics(false)
  }

  const onAbort = () => {
    if (controllerRef.current) controllerRef.current.abort()
  }

  const handleChangeFilters = event => {
    const exceptionFields = ["module", "rule"]
    const isException = exceptionFields.includes(event.target.name)
    setIsTriggerFilter(prev => prev || !isException)
    handleFieldValue(event)
  }

  const handleClick = () => {
    setNextPage(1)
    getCourseProgress()
    setIsTriggerFilter(false)
  }

  const getVariant = isTriggerFilter ? "outlined" : "contained"
  const buttonLabel = isTriggerFilter ? "ПРИМЕНИТЬ ФИЛЬТР" : "ПОСМОТРЕТЬ СТАТИСТИКУ"

  return (
    <>
      <HelperModal isOpen={isOpenHelper} setIsOpen={setIsOpenHelper} />
      <HelperTableModal isOpen={isOpenTableHelper} setIsOpen={setIsOpenTableHelper} />
      <Styled.ShadowedSection>
        <Styled.Header>
          <Styled.Title variant="h4">Курс</Styled.Title>
          <Styled.Helper onClick={() => setIsOpenHelper(true)}>Как пользоваться панелью статистики?</Styled.Helper>
        </Styled.Header>
        <Styled.ActionWrapper>
          <Autocomplete
            size="large"
            id="statistics__choise"
            value={variantValue}
            onChange={handleChooseCourse}
            onReset={handleChooseCourse}
            options={variants}
            label="Название курса"
            style={{ width: 384 }}
          />
          <Button variant={getVariant} disabled={!variantValue || isLoading} onClick={handleClick} width={266}>
            {buttonLabel}
          </Button>
          {isLoading && (
            <Styled.LoaderWrapper>
              <CircularProgress size={26} />
              <Typography style={{ fontSize: "18px" }}>Пожалуйста, подождите</Typography>
            </Styled.LoaderWrapper>
          )}
        </Styled.ActionWrapper>
        <CourseFilters
          fieldValue={fieldValue}
          handleFieldValue={handleChangeFilters}
          categories={availableCategories}
          stages={course?.stages}
          modules={modules}
          disable={!variantValue?.id || isLoading}
        />
        <Styled.FormHelper variant="body1" fontWeight="small">
          {isTriggerFilter
            ? "Чтобы отобразить статистику по заданным параметрам, нажмите на кнопку «Применить фильтр»"
            : "Чтобы применить фильтр, нажмите на кнопку “Посмотреть статистику”"}
        </Styled.FormHelper>
      </Styled.ShadowedSection>
      {variantValue && course && showStatistics && (
        <Styled.SectionWrapper sx={{ marginBottom: 10 }}>
          <Styled.SubTitle
            variant="h4"
            sx={{
              marginBottom: 4,
            }}
          >{`Курс - ${variantValue.label}`}</Styled.SubTitle>
          <Styled.LightText variant="body1">{`ID: ${variantValue.id}`}</Styled.LightText>
        </Styled.SectionWrapper>
      )}
      {window.innerWidth > 1200 && courseProgress && showStatistics && course && (
        <>
          <ManageStats
            isLoading={isLoading}
            stages={course?.stages}
            scores={formCourseStatistic(courseProgress, infoFields, tasks, sortBy)}
            progress={courseProgress}
            selectedCourse={variantValue}
            availableCategories={availableCategories}
            personalModules={personalModules}
            filterCount={totalCount}
          />
          <Styled.FlexRow sx={{ marginTop: 16, marginBottom: 11 }}>
            {totalCount && <ParticipantsCount totalCount={totalCount} filterCount={courseProgress.length} />}
            <Styled.Helper onClick={() => setIsOpenTableHelper(true)}>Как взаимодействовать с таблицей?</Styled.Helper>
          </Styled.FlexRow>
          <ParticipantTable
            variantValue={variantValue}
            courseProgress={courseProgress}
            tasks={tasks}
            isLoading={isLoading}
            sortBy={sortBy}
            setSortBy={setSortBy}
            loadMore={loadMore}
            hasNextPage={hasNextPage}
          />
        </>
      )}
    </>
  )
}
