import React from "react"
import { infoType } from "../models/Questionnaire.types"
//@ts-ignore
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { QuestionItem } from "./QuestionItem"
import { moveItemInArray } from "../../../shared/util"
import { questionTypes } from "../static/static"

export const Questions = ({
  questions,
  editQuestion,
  setQuestions,
  removedFields,
  setRemovedFields,
  editVariants,
  deleteVariant,
}: {
  deleteVariant: (question_index: number, variant_index: number) => void
  editVariants: (question_index: number, variant_index: number, value: string) => void
  removedFields: number[]
  setRemovedFields: React.Dispatch<React.SetStateAction<number[]>>
  questions: infoType[]
  setQuestions: React.Dispatch<React.SetStateAction<infoType[]>>
  editQuestion: (field: keyof infoType, newValue: string | boolean | number | string[], index: number) => void
}) => {
  function onDragEnd(result: {
    destination?: {
      index: number
    }
    source?: {
      index: number
    }
  }) {
    if (!result.source || !result.destination) return
    let new_sections = [...questions]
    moveItemInArray(new_sections, result.source.index, result.destination.index)
    for (let i = 0; i < questions.length; i++) {
      new_sections[i].index = i + 1
    }
    setQuestions([...new_sections])
  }

  function removeField(question: infoType) {
    let index = questions.indexOf(question)
    let new_questions = [...questions]
    new_questions.splice(index, 1).sort((a, b) => (a.index > b.index ? 1 : -1))
    for (let i = 0; i < new_questions.length; i++) {
      new_questions[i].index = i + 1
    }
    setQuestions([...new_questions])
    if (!!question.id) setRemovedFields([...removedFields, question.id])
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="draggable-0">
          {(provided: any, snapshot: any) => (
            <div
              ref={provided.innerRef}
              style={{ width: "100%", padding: "0px 4px", zIndex: "5" }}
              {...provided.droppableProps}
            >
              {questions
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((item, index) => (
                  <Draggable draggableId={"draggable-" + index} index={index}>
                    {(provided: any, snapshot: any) => (
                      <QuestionItem
                        deleteVariant={deleteVariant}
                        removeField={removeField}
                        question={item}
                        editVariants={editVariants}
                        editQuestion={editQuestion}
                        provided={provided}
                      />
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
