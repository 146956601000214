import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  courseCardChip: {
    position: "absolute",
    margin: "20px",
    backgroundColor: "#FFFFFF",
    height: "39px",
    width: "98px",
    borderRadius: "30px",
    color: "#313439",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "155%",
  },

  Button: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    border: "1px solid #1565C0",
    background: "#fff",
    color: "#1565C0",
    width: "200px",
    float: "left",
    height: "54px",
    margin: "10px 4px",
    fontSize: "18px",
    marginLeft: "80px",
    marginBottom: "20px",
    "&:disabled": {
      color: "#98999C",
      border: "1px solid #98999C",
      cursor: "auto",
    },
  },

  titleText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    color: "white",
    lineHeight: "125%",
    fontSize: "30px",
    fontWeight: 500,
    width: "578px",
    height: "122px",
    paddingTop: "80px",
    marginLeft: "78px",
  },

  typeContainer: {
    /* marginBottom:"16.6vw",*/
    backgroundColor: "#FFFFFF",
    height: "2.8vw",
    width: "7vw",
    borderRadius: "30px",
    color: "#313439",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "155%",
    position: "absolute",
    top: 12,
    [theme.breakpoints.down("lg")]: {
      width: 88,
      height: 32,
    },
  },
  containerWrapper: {
    height: "min(25vw, 400px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingLeft: 20,
    position: "relative",
    "& div p": {
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "30vw",
    },
    [theme.breakpoints.down("sm")]: {
      height: "36vw",
      minHeight: 200,
    },
  },

  container: {
    marginBottom: "20px",
    height: "min(25vw, 400px)",
    backgroundPosition: "center",
    borderRadius: "6px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#FFFFFF",
    border: "3px solid #FBFBFB",
    boxShadow: "0px 0px 10px rgba(54, 54, 54, 0.1)",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.9",
    },
    [theme.breakpoints.down("md")]: {
      height: "30vw",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      height: "36vw",
      minHeight: 200,
      backgroundPosition: "left",
    },
  },

  inactiveContainer: {
    marginBottom: "20px",
    height: "min(25vw, 400px)",
    backgroundPosition: "center",
    borderRadius: "6px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#FFFFFF",
    border: "3px solid #FBFBFB",
    boxShadow: "0px 0px 10px rgba(54, 54, 54, 0.1)",
    opacity: "0.7",
  },
}))
