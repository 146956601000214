import React, { useCallback, useEffect } from "react"
import { Typography } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useUserState, receiveToken } from "shared/context"

const RegistrationSuccess = props => {
  const { user } = useUserState()

  useEffect(() => {
    loginUser()
  }, [props.login])

  function loginUser() {
    if (props.login.length > 0 && props.password.length > 0) {
      axios
        .post("/token/", { username: props.login, password: props.password })
        .then(res => {
          const token = res.data
          setTimeout(() => {
            receiveToken(token)
            // TODO установка состояния для юзера должна переехать в метод recieveToken
            user.setState(true)
          }, 2000)
        })
        .catch(() => {})
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 36,
        padding: 36,
      }}
    >
      <DoneIcon style={{ fontSize: 55, color: "#1F8354" }} />
      <Typography variant="body1">Регистрация на платформе успешно завершена!</Typography>
    </div>
  )
}

export default RegistrationSuccess
