import { IMember } from "entities/Member/model/Member.types"
import { CategoriesType, MembersRowType } from "./teamStatistic.types"
import { ITeam } from "entities/Team/model/Team.types"

export function formMembersRow(teamMembers: MembersRowType) {
  // @ts-ignore
  let result = []
  Object.keys(teamMembers).forEach(categoryKey => {
    result.push(teamMembers[categoryKey])
  })

  // @ts-ignore
  return result.flat(1)
}

export function formCategoriesRow(categories: CategoriesType) {
  return Object.keys(categories)
    .map(category => {
      return categories[category]
    })
    .flat()
}

export function formCategories(progress: ITeam[]) {
  const categories = { "Без роли": [], Приглашённые: [] }
  progress.forEach((team: ITeam) => {
    if (team.members) {
      const maxCategories = { "Без роли": [], Приглашённые: [] }
      team.members.forEach(member => {
        if (member.status === "invited") {
          // @ts-ignore
          maxCategories["Приглашённые"].push("Приглашённые")
        } else if (member.category === "role_none") {
          // @ts-ignore
          maxCategories["Без роли"].push("Без роли")
        } else if (member.category && member.category !== "chief_moderator") {
          // @ts-ignore
          if (maxCategories[member.category]) {
            // @ts-ignore
            maxCategories[member.category].push(member.category)
          } else {
            // @ts-ignore
            maxCategories[member.category] = [member.category]
          }
        }
      })
      Object.keys(maxCategories).forEach(maxKey => {
        // @ts-ignore
        if (!categories[maxKey]) {
          // @ts-ignore
          categories[maxKey] = maxCategories[maxKey]
          // @ts-ignore
        } else if (maxCategories[maxKey].length >= categories[maxKey]?.length) {
          // @ts-ignore
          categories[maxKey] = maxCategories[maxKey]
        }
      })
    }
  })

  return categories
}

interface TeamProgressType extends ITeam {
  membersRow: MembersRowType
}

export function formMembers(teamProgress: ITeam[], formedCategories: string[]) {
  if (!teamProgress) return teamProgress
  const emptyMembersRow = {}
  // @ts-ignore
  const teamProgressMembers: TeamProgressType[] = []

  Object.keys(formedCategories).forEach(key => {
    // @ts-ignore
    emptyMembersRow[key] = new Array(formedCategories[key].length).fill({ name: null, email: null })
  })

  teamProgress.forEach(team => {
    teamProgressMembers.push({ ...team, membersRow: emptyMembersRow })
  })

  const stringify = JSON.stringify(teamProgressMembers)
  const result = JSON.parse(stringify)

  result.forEach((team: ITeam, teamIndex: number) => {
    if (team.members) {
      team.members.forEach(member => {
        if (member.category === "chief_moderator") return
        if (member.status === "invited") {
          for (let memberIndex in result[teamIndex].membersRow["Приглашённые"]) {
            // @ts-ignore
            const memberItem = result[teamIndex].membersRow["Приглашённые"][memberIndex]
            if (memberItem.name === null) {
              // @ts-ignore
              result[teamIndex].membersRow["Приглашённые"][memberIndex] = formMember(member)
              break
            }
          }
        }
        if (member.category === "role_none") {
          for (let memberIndex in result[teamIndex].membersRow["Без роли"]) {
            // @ts-ignore
            const memberItem = result[teamIndex].membersRow["Без роли"][memberIndex]
            if (memberItem.name === null) {
              // @ts-ignore
              result[teamIndex].membersRow["Без роли"][memberIndex] = formMember(member)
              break
            }
          }
        }
        for (let memberIndex in result[teamIndex].membersRow[member.category]) {
          // @ts-ignore
          const memberItem = result[teamIndex].membersRow[member.category][memberIndex]
          if (memberItem.name === null) {
            // @ts-ignore
            result[teamIndex].membersRow[member.category][memberIndex] = formMember(member)
            break
          }
        }
      })
    }
  })

  return result
}

const formMember = (member: IMember) => ({ name: member?.participant?.full_name, email: member?.participant?.email })
