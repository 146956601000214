import * as Styled from "entities/Assignment/ui/styles/EditStudentProgress.styles"
import { IExtendFilters } from "shared/hooks/useExtendFiltersForm"
import { Autocomplete, StatusMessage, Tooltip } from "shared/ui"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import useProgressEditor from "../model/useProgressEditor"
import ResetParticipantProgress from "features/Assignment/ResetParticipantProgress"
import SaveParticipantProgress from "features/Assignment/SaveParticipantProgress"

interface IProps {
  filters: IExtendFilters
  progress: IParticipantProgress
  setProgress: any
}

const StudentProgressEditor = ({ filters, progress, setProgress }: IProps) => {
  const {
    currentModule,
    isLoading,
    message,
    score,
    displayedScore,
    userScore,
    minMaxScore,
    scoreColor,
    helperText,
    scoreOptions,
    onChangeScore,
    getTitle,
    setIsLoading,
    setMessage,
  } = useProgressEditor(filters, progress)

  return (
    <Styled.EditorWrapper>
      <Styled.EditorTitle>Прогресс пользователя за {getTitle(filters)}</Styled.EditorTitle>
      {filters.module && (
        <Styled.EditorLable margin={8}>
          Мин. Балл/ Макс. Балл: <Styled.StrongText>{minMaxScore}</Styled.StrongText>
        </Styled.EditorLable>
      )}
      <Styled.EditorLable>
        Балл пользователя:{" "}
        <Tooltip text={helperText} placement="bottom" width={352}>
          <Styled.StrongText color={scoreColor}>{userScore}</Styled.StrongText>
        </Tooltip>
      </Styled.EditorLable>
      <Styled.ButtonsWrapper>
        <Autocomplete
          id="score"
          key={filters?.module?.label}
          label="Балл"
          name="score"
          value={displayedScore?.score}
          filterChange={onChangeScore}
          onReset={onChangeScore}
          options={scoreOptions}
          disabled={!filters.module}
          style={{ minWidth: 113 }}
        />
        <SaveParticipantProgress
          progress={progress}
          currentModule={currentModule}
          score={score}
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setProgress={setProgress}
        />
        <div />
        <ResetParticipantProgress
          filters={filters}
          currentModule={currentModule}
          progress={progress}
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setProgress={setProgress}
        />
      </Styled.ButtonsWrapper>
      <StatusMessage
        size={"small"}
        isLoading={isLoading}
        successMessage={message.success}
        errorMessage={message.fail}
      />
    </Styled.EditorWrapper>
  )
}

export { StudentProgressEditor }
