import React, { useEffect, useState } from "react"
import * as Styled from "./ui/Organizations.styles"
import StatisticsProvider from "entities/Organization/model/StatisticsContext"
import EditOrganization from "features/Organization/EditOrganization"
import ChangeOrganization from "features/Organization/ChangeOrganization"
import { Tab, Tabs, Typography } from "@mui/material"
import { fetchOrganization, OrganizationCard } from "entities/Organization"
import { useDispatch, useSelector } from "react-redux"
import { Events } from "./ui/Events"
import { EditOrganizationLanding } from "features/Organization/EditOrganizationLanding"
import { ParticipantManagement } from "./ui/ParticipantManagement"
import { OrganizationStatistics } from "./tabs/OrganizationStatistics"
import { useTab } from "../../shared/hooks/useTab"
import { courseActions, organizationActions } from "../../shared/store"

export const Organizations = () => {
  const { tab, changeTab } = useTab("organizations_control_tab")
  const [openDialog, setIsOpenDialog] = useState(false)
  const dispatch = useDispatch()
  const [openEditLanding, setOpenEditLanding] = useState(false)
  const organization = useSelector(state => state.organization.currentOrganization)

  useEffect(() => {
    dispatch(organizationActions.setChangeTab(changeTab))
    if (localStorage.getItem("current_organization")) {
      dispatch(fetchOrganization(localStorage.getItem("current_organization")))
    } else {
    }
  }, [])

  useEffect(() => {
    dispatch(courseActions.setCourse(undefined))
    dispatch(organizationActions.setEvent(undefined))
    dispatch(organizationActions.setEmail(""))
    dispatch(organizationActions.setIsEmpty(false))
    dispatch(courseActions.setParticipantsAndRequestsCourse(null))
  }, [organization])

  return (
    <Styled.MainOrganizationsDiv>
      <EditOrganization />
      <EditOrganizationLanding isOpen={openEditLanding} setIsOpen={setOpenEditLanding} />
      <Tabs value={tab} onChange={changeTab} sx={{ alignSelf: "center" }}>
        <Tab value={0} label={<Typography variant={"subtitle3"}>Сведения</Typography>}></Tab>
        <Tab
          value={1}
          disabled={!organization}
          label={<Typography variant={"subtitle3"}>Участники и заявки</Typography>}
        ></Tab>
        <Tab value={2} disabled={!organization} label={<Typography variant={"subtitle3"}>События</Typography>}></Tab>
        <Tab
          value={3}
          disabled={!organization}
          label={<Typography variant={"subtitle3"}>Статистика</Typography>}
        ></Tab>
      </Tabs>
      {tab === 0 && <ChangeOrganization isOpen={openDialog} setIsOpen={setIsOpenDialog} />}
      {tab === 0 && <OrganizationCard setOpenEditLanding={setOpenEditLanding} />}
      {tab === 1 && <StatisticsProvider>
                      <ParticipantManagement />
                    </StatisticsProvider>
      }
      {tab === 2 && <Events />}
      {tab === 3 && (
        <StatisticsProvider>
          <OrganizationStatistics />
        </StatisticsProvider>
      )}
    </Styled.MainOrganizationsDiv>
  )
}
