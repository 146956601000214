import { styled } from "@mui/material/styles"
import DeleteIcon from "@mui/icons-material/Delete"
import { TableContainer } from "@mui/material"

export const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(10),
}))

export const CustomDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  cursor: "pointer",
  color: "#777777",
  "&:hover": {
    opacity: 0.5,
  },
}))

export const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  borderRadius: theme.spacing(3),
  maxHeight: "40%",
}))
