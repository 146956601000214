import React, { useState } from "react"
import { ModalSmallTemplate } from "shared/ui"
import { teamActions } from "shared/store"
import { useDispatch } from "react-redux"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

export const LeaveTeamModal = ({ isOpen, onClose, team }) => {
  const [isLeavable, setIsLeavable] = useState(true)
  const dispatch = useDispatch()

  function leaveTeam() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .delete(`content/actions/teams/${team.id}/leave/`)
      .then(res => {
        if (res.status === 204) {
          dispatch(teamActions.filterTeams(team.id))
        }
      })
      .catch(err => {
        if (err.response.status === 412) {
          setIsLeavable(false)
        }
      })
  }

  function handleClose() {
    // TODO из-за этого сброса есть визуальный баг при выходе из модалки
    setIsLeavable(true)
    onClose()
  }

  const getTitle = isLeavable ? "Вы действительно хотите покинуть команду?" : "Вы не можете покинуть команду"
  const getDescription = isLeavable
    ? "Это действия нельзя отменить. Вы хотите продолжить?"
    : "Перед тем, как покинуть команду, вы должны назначить нового Главного Администратора команды. Назначьте Главного Администратора и попробуйте снова."

  const handleLeave = () => {
    isLeavable ? leaveTeam() : handleClose()
  }

  return (
    <ModalSmallTemplate
      title={getTitle}
      text={getDescription}
      isOpen={isOpen}
      onClick={handleLeave}
      onClose={handleClose}
      isAbortButton={isLeavable}
    />
  )
}
