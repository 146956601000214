export const Russian = {
  "": "Не выбрано",
  null: "Не выбрано",
  done: "Пройдено",
  fail: "Не пройдено",
  summary: "Общий балл",
  in_progress: "Участник",
  teacher: "Преподаватель",
  mentor: "Ментор",
  moderator: "Модератор",
  requested: "В обработке",
  banned: "Заблокирован",
  failed: "Отклонен",
  admin: "Администратор",
  student: "Студент",
  active: "Активен",
  inactive: "Не активен",
  activate: "Активировать",
  deactivate: "Деактивировать",
  submitted: "Не проверено",
  accepted: "Принято",
  rejected: "Возврат",
  asc: ">",
  desc: "<",
}
