import * as Styled from "../styles/ExaminationFiles.styles"
import { AnswersWrapper } from "./ExaminationModal.styles"
import { IAssingment, IAssingmentReview } from "../../model/assignment.types"
import { ExaminationTasks } from "./"
import DownloadIcon from "images/icons/Download"

export const ExaminationAnswers = ({
  assignment,
  review,
}: {
  assignment?: IAssingment
  review?: IAssingmentReview | null
}) => {
  return (
    <AnswersWrapper>
      {assignment?.attachments?.length ? (
        <Styled.FilesWrapper>
          <Styled.FilesTitle>Файлы Задания: </Styled.FilesTitle>
          {assignment?.attachments.map(attach => (
            <File fileUrl={attach.file} />
          ))}
        </Styled.FilesWrapper>
      ) : null}
      <ExaminationTasks assignment={assignment} review={review} />
    </AnswersWrapper>
  )
}

const File = ({ fileUrl }: { fileUrl: string }) => {
  const splitedUrl = fileUrl.split("/")
  const file = splitedUrl[splitedUrl.length - 1]

  return (
    <Styled.StyledFile>
      <a href={fileUrl} target="_blank" title={fileUrl}>
        {file}
      </a>
      <DownloadIcon />
    </Styled.StyledFile>
  )
}
