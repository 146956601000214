import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
} from "@mui/material";

export const DetailedTypeEditor = (props) => {
  const [editorOnIndex, setEditorOnIndex] = useState(null)
  const [text, setText] = useState("")
  const [question, setQuestion] = useState(props.question)

  function addVariant() {
    question.variants.push({ label: text })
    setQuestion({...question})
  }

  function changeVariant(value) {
    question.variants[editorOnIndex].label = value
    setQuestion({...question})
  }

  function removeVariant(index) {
    question.variants.splice(index, 1)
    setQuestion({...question})
  }

  return (
    <>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
      <TextField
        id="answer-text-editor"
        variant="outlined"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder="Текст ответа"
        fullWidth
        multiline
        style={{ margin: 12, minWidth: 360 }}
      />
      <Button onClick={addVariant} style={{ margin: 12, width: 180 }}>Добавить вариант ответа</Button>
    </div>
    {question.variants.map((c, index) => (
      editorOnIndex === index ? (
        <TextField
          id="answer-variant-editor"
          variant="outlined"
          value={c.label}
          onChange={e => changeVariant(e.target.value)}
          onKeyDown={e => {if (e.key == "Enter") { setEditorOnIndex(null) }}}
          fullWidth
        />
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <Typography onDoubleClick={() => setEditorOnIndex(index)}>{c.label}</Typography>
          <Button onClick={() => removeVariant(index)}>Удалить</Button>
        </div>
      )
    ))}
    </>
  )
}
