import React, { useState, useCallback } from "react";
import {
  TextField,
  Typography,
  Button,
} from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import useStyles from "../../pages/admin/styles";
import { Card } from "shared/ui";
import { moveItemInArray } from "shared/util";

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }} ></div>
  } else {
    return text
  }
}

export const SortTypeEditor = (props) => {
  const classes = useStyles()

  const [editorOnIndex, setEditorOnIndex] = useState(null)
  const [text, setText] = useState("")
  const [question, setQuestion] = useState(props.question)

  const handleChangeChoice = (index) => {
    question.variants[index].checked = !question.variants[index].checked
    setQuestion({...question})
  }

  function addVariant() {
    question.variants.push({ value: question.variants.length + 1, label: text })
    setQuestion({...question})
  }

  function changeVariant(value) {
    question.variants[editorOnIndex].label = value
    setQuestion({...question})
  }

  function removeVariant(index) {
    question.variants.splice(index, 1)
    setQuestion({...question})
  }

  const onDragEnd = useCallback((result) => {
    moveItemInArray(question.variants, result.source.index, result.destination.index)
  }, [question]);

  return (
    <>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
      <TextField
        id="answer-text-editor"
        variant="outlined"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder="Текст ответа"
        fullWidth
        multiline
        style={{ margin: 12, minWidth: 360 }}
      />
      <Button onClick={addVariant} style={{ margin: 12, width: 180 }}>Добавить вариант ответа</Button>
    </div>
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Card style={{ width: 800, display: "flex", backgroundColor: '#E0E0E0' }}>
        <Droppable droppableId="draggable-0">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{ width: "100%", padding: 24 }}
              {...provided.droppableProps}
            >
              {question.variants.map((c, index) => (
                <Draggable draggableId={"draggable-" + index} index={index}>
                  {(provided, snapshot) => (
                    <Card
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {editorOnIndex === index ? (
                        <TextField
                          id="label-editor"
                          variant="outlined"
                          value={c.label}
                          onChange={e => changeVariant(e.target.value)}
                          onKeyDown={e => {if (e.key == "Enter") { setEditorOnIndex(null) }}}
                          fullWidth
                        />
                      ) : (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                          <Typography onDoubleClick={() => setEditorOnIndex(index)}>{parseText(c.label)}</Typography>
                          <Button onClick={() => removeVariant(index)}>Удалить</Button>
                        </div>
                      )}
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Card>
    </DragDropContext>
    </>
  )
}
