import React from "react"
import { TextField } from "@mui/material"
import useStyles from "./styles"

export const Detailed = ({ question, value, handleChange, result, inputType }) => {
  const classes = useStyles()
  const isNumeric = inputType === "number"

  const handleNumericChange = event => {
    const inputValue = event.target.value
    // Регулярное выражение для валидации числа
    const numericRegex = /^-?\d*([.,]?\d*)?$/

    if (numericRegex.test(inputValue) || inputValue === "" || inputValue === "-") {
      // Ограничение знаков после точки до 15
      const parts = inputValue.split(/[.,]/)
      if (parts.length > 1 && parts[1].length > 15) {
        return
      }

      handleChange(event)
    }
  }

  return (
    <TextField
      id="standard-basic"
      className={classes.testDetailed}
      sx={{
        backgroundColor: result ? (result["overall"] ? "#E6FCDE" : "#FFDEDE") : null,
        marginTop: 12,
        "& .MuiOutlinedInput-root fieldset": {
          borderColor: result ? (result["overall"] ? "#81FF81" : "#FF7373") : "#C3C3C3",
        },
      }}
      label="Ответ"
      name={question.index}
      value={!!value ? (!!value[question.index] ? value[question.index] : "") : ""}
      onChange={isNumeric ? handleNumericChange : handleChange}
      type={!isNumeric && inputType}
      fullWidth={!isNumeric}
      multiline={!isNumeric}
    />
  )
}
