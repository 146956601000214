import { IAttachment } from "../model/assignment.types"
import * as Styled from "./styles/AssignmentTableRow.styles"

const FilesCell = ({ attachments }: { attachments: IAttachment[] }) => {
  const parsFileName = (fileName: string) => {
    const arr = fileName.split("/")
    return `${arr[arr.length - 1]}`
  }

  if (!attachments.length) return <div>Не требуется</div>

  return attachments.map(attach => (
    <div>
      <Styled.FileLink href={attach.file} target="_blank" title={attach.file}>
        {parsFileName(attach.file)}
      </Styled.FileLink>
    </div>
  ))
}

export default FilesCell
