import { styled } from "@mui/material/styles"
import { AppBar, ListItem, Theme, Toolbar } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"

export const LandingAppBar = styled(AppBar)(({ theme }) => ({
  width: "100vw",
  zIndex: 1200,
  height: 75,
  backgroundColor: "#FFF",
}))

export const LandingToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const LandingLogoContainer = styled("div")(({ theme }) => ({
  width: "30%",
  marginLeft: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(2),
  },
}))

export const LandingLogo = styled("img")(
  ({ theme, isOrganizationLogo }: { theme: Theme; isOrganizationLogo: boolean }) => ({
    height: 75,
    paddingTop: isOrganizationLogo ? theme.spacing(3) : 0,
    paddingBottom: isOrganizationLogo ? theme.spacing(3) : 0,
  })
)

export const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
  height: theme.spacing(12),
  width: theme.spacing(12),
  marginRight: theme.spacing(2),
  color: "#000",
  cursor: "pointer",
  "&:hover": {
    opacity: "0.5",
  },
  [theme.breakpoints.down("sm")]: {},
}))

export const AuthUserContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "30%",
  gap: theme.spacing(4),
}))

export const CustomCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.common.black,
  height: theme.spacing(12),
  width: theme.spacing(12),
  cursor: "pointer",
  "&:hover": {
    opacity: "0.5",
  },
}))

export const LoginAndRegistrationContainer = styled("div")(({ theme }) => ({
  width: "30%",
  display: "flex",
  justifyContent: "flex-end",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
}))

export const DrawerListItem = styled(ListItem)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    background: "#EFEFEF",
  },
}))
