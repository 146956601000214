import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  CircularProgress,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import useStyles from "./styles";
import { axiosInstance as axios } from "shared/api/axiosInstance";

import carImg from "../../images/car.png"

const DriveRobot = props => {
  var classes = useStyles();
  var inputRef = useRef();
  
  const [feedback, setFeedback] = useState("")
  const [busy, setBusy] = useState(false)
  const [currentAction, setCurrentAction] = useState("")

  function keyboardInputSerializer(event) {
    if (busy) return
    setBusy(true)
    let newMessage
    if (event.key == "w") {
      newMessage = {command: "moveForward", "inputs": "[]"}
      setCurrentAction("moveForward")
    }
    if (event.key == "a") {
      newMessage = {command: "rotateLeft", "inputs": "[]"}
      setCurrentAction("rotateLeft")
    }
    if (event.key == "s") {
      newMessage = {command: "moveBackward", "inputs": "[]"}
      setCurrentAction("moveBackward")
    }
    if (event.key == "d") {
      newMessage = {command: "rotateRight", "inputs": "[]"}
      setCurrentAction("rotateRight")
    }
    if (!newMessage) {
      setBusy(false)
      return
    }
    axios.defaults.headers.common["Api-Key"] = "dfgdfYkl44s_e"
    axios.defaults.headers.common["Content-Type"] = "application/json"
    axios.post("/spec/update/10/", newMessage).then(res => {
      setFeedback(res.data.status)
      setBusy(false)
      setCurrentAction("")
    }).catch(err => {
      setFeedback("Error")
      setBusy(false)
      setCurrentAction("")
    })
  }

  useEffect(() => {
    inputRef.current.focus()
  }, []);

  return (
    <div
      style={{ position: "absolute", width: "100%", height: "100%",
      backgroundColor: "#FBFBFB", display: "flex", alignItems: "center",
      justifyContent: "center", flexDirection: "column"}}
      onKeyDown={keyboardInputSerializer}
      tabIndex="0"
      ref={inputRef}
    >
      <div style={{ width: "100%" }}>
        <Grid container spacing={0} wrap alignItems="center" justifyContent="center">
          <Grid item xs={4}>
            <Typography variant="h3" style={{ width: "100%", textAlign: "center" }}>{feedback}</Typography>
          </Grid>
          <Grid item xs={4}>
            <ArrowUpwardIcon style={currentAction == "moveForward" ? ({width: "100%", fontSize: 64, color: "blue"}) : ({width: "100%", fontSize: 64, color: "black"})} />
          </Grid>
          <Grid item xs={4}>
            {busy ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={() => keyboardInputSerializer({ key: "w" })}>
                <Typography>FORWARD</Typography>
              </Button>
            )}
          </Grid>
          <Grid item xs={4}>
            <ArrowBackIcon style={currentAction == "rotateLeft" ? ({width: "100%", fontSize: 64, color: "blue"}) : ({width: "100%", fontSize: 64, color: "black"})} />
          </Grid>
          <Grid item xs={4}>
            <img src={carImg} width="100%" height="100%" />
          </Grid>
          <Grid item xs={4}>
            <ArrowForwardIcon style={currentAction == "rotateRight" ? ({width: "100%", fontSize: 64, color: "blue"}) : ({width: "100%", fontSize: 64, color: "black"})} />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
          < ArrowDownwardIcon style={currentAction == "moveBackward" ? ({width: "100%", fontSize: 64, color: "blue"}) : ({width: "100%", fontSize: 64, color: "black"})} />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withRouter(DriveRobot);
