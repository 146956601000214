import { Typography, styled } from "@mui/material"
import { CircularProgress } from "@mui/material"

export const Wrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: 250,
}))

export const Title = styled(Typography)(() => ({
  maxWidth: 680,
}))

export const SelectWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(6),
  minWidth: 1000,
  marginBlock: `${theme.spacing(15)} ${theme.spacing(10)}`,
}))

export const LoaderWrapper = styled("div")(() => ({
  width: "50px",
  margin: "0 auto",
}))

export const Loader = styled(CircularProgress)(() => ({}))
