import styled from "@emotion/styled"
export const Options = styled.ul`
  position: absolute;
  top: 0;
  right: 32px;
  padding: unset;
  margin: unset;
  padding-block: 8px;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 384px;
  list-style: none;
  text-align: left;
`

export const Option = styled.li<{ isActive?: boolean }>`
  padding: 8px 16px;
  width: 100%;
  transition: background-color 0.2s ease;
  background-color: ${({ isActive }) => (isActive ? "#dee7f2" : "#F9F9F9")};
  cursor: pointer;

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:hover {
    background-color: #efefef;
  }
`
