import React from "react"
import { Grid, TableCell } from "@mui/material"
import { Button, Typography } from "shared/ui"
import { useTheme } from "shared/context"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useDispatch } from "react-redux"
import { teamActions } from "../../shared/store"

export const ModerateTeam = ({ team }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  // ToDo вынести запросы к api с помощью createAsyncThunk
  function fetchTeam() {
    axios.get(`/content/teams/`, { params: { id: team.id, course: team.course } }).then(res => {
      dispatch(teamActions.setRequest(res.data[0]))
    })
  }

  function approveTeam() {
    axios.put(`/content/courses/${team.course}/approve_team/`, { team: team.id }).then(res => {
      fetchTeam()
    })
  }

  function rejectTeam() {
    axios.patch(`/content/courses/${team.course}/approve_team/`, { team: team.id }).then(res => {
      fetchTeam()
    })
  }

  function revertTeam() {
    axios.put(`/content/courses/${team.course}/restore_team/`, { team: team.id }).then(res => {
      fetchTeam()
    })
  }

  return (
    <TableCell align={"center"}>
      {!!!team?.is_moderated && (
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          spacing={theme.spacing(2)}
          sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}
        >
          <Grid item sx={12}>
            <Button sx={{ width: "80%" }} height={"small"} variant={"outlined"} color={"primary"} onClick={approveTeam}>
              Принять
            </Button>
          </Grid>
          <Grid item sx={12}>
            <Button sx={{ width: "80%" }} height={"small"} variant={"outlined"} color={"primary"} onClick={rejectTeam}>
              Отклонить
            </Button>
          </Grid>
        </Grid>
      )}
      {!!team?.is_moderated && (
        <Grid container direction={"column"} justifyContent={"center"} spacing={theme.spacing(3)}>
          <Grid item sx={12}>
            <Button sx={{ width: "80%" }} height={"small"} variant={"outlined"} color={"primary"} onClick={revertTeam}>
              Вернуть
            </Button>
          </Grid>
          <Grid item sx={12}>
            <Typography variant={"caption"} fontWeight={"medium"}>
              {team.is_approved ? "Принята" : "Отклонена"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </TableCell>
  )
}
