import { styled } from "@mui/material/styles"

interface IProps {
  content: string
  isCollapsed: boolean
  setIsCollapsed: (x: any) => boolean
}

export const CollapseTextButton = ({ content, isCollapsed, setIsCollapsed }: IProps) => {
  const isNeedCollapse = content.length > 300

  const toggleCollapse = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsCollapsed((prev: boolean) => !prev)
  }

  if (!isNeedCollapse) return
  return (
    <>
      {isCollapsed && <span>...</span>}{" "}
      <TextToggler onClick={toggleCollapse} className="collapse-toggler">
        {isCollapsed ? "Развернуть сообщение" : "Свернуть сообщение"}
      </TextToggler>
    </>
  )
}

const TextToggler = styled("span")(() => ({
  color: "#3483F6",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "115%",
  textDecorationLine: "underline",
  cursor: "pointer",
}))
