import * as Styled from "./TimerStartModal.styles"
import { useBackModalRedirection } from "entities/Test/model/backCourseRedirection"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { useEffect } from "react"
import { Button } from "shared/ui"

interface IProps {
  isOpen: boolean
  onClose: () => void
  onResults: () => void
  courseSlug: string
  blockId: number
}

const TimerResultsModal = ({ isOpen, onClose, onResults, courseSlug, blockId }: IProps) => {
  const userEmail = localStorage.getItem("email")
  const localStorageKey = `${userEmail}_test_${blockId}_result_modal`

  useEffect(() => {
    const isViewed = localStorage.getItem(localStorageKey) === "viewed"
    isViewed && onResults()
  }, [isOpen])

  return (
    <ModalTimer isOpen={isOpen} onClose={onClose} size={"small"}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>Ваша попытка прохождения завершена.</Styled.Typography>
          <br />
          <Styled.Typography>Ответы отправлены на проверку.</Styled.Typography>
        </Styled.Wrapper>
        <Buttons onResults={onResults} courseSlug={courseSlug} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ onResults, courseSlug }: { onResults: () => void; courseSlug: string }) => {
  const { redirect } = useBackModalRedirection(courseSlug)

  const onEscape = () => {
    redirect()
  }

  return (
    <Styled.ButtonWrapper>
      {/* @ts-ignore */}
      <Button onClick={onResults} variant="contained" width={154}>
        Результаты
      </Button>
      {/* @ts-ignore */}
      <Button onClick={onEscape} variant="outlined" width={154}>
        Выйти
      </Button>
    </Styled.ButtonWrapper>
  )
}

export { TimerResultsModal }
