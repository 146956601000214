import React, { useState } from "react"
import { routes } from "../util/Routes"
import { signOut, useUserState } from "shared/context"
import { Button as MuiButton, Divider, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import { UserIcon } from "entities/User"
import { timerActions } from "shared/store"
//@ts-ignore
import { useHistory } from "react-router-dom"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { HeaderTooltip } from "./HeaderTooltip"

interface IProps {
  profile: {
    status: string
  } | null
  status: string
  isStaff: null
  isActive: (field: string) => string | null
  hasOrganizations: boolean
}

export const UserMenu = ({ profile, status, isStaff, isActive, hasOrganizations }: IProps) => {
  const { user, isAuthenticated } = useUserState()
  const [anchorMenu, setAnchorMenu] = useState<React.MouseEvent<HTMLButtonElement> | null>(null)
  const dispatch = useAppDispatch()
  const isRunningTimerTest = useAppSelector(state => state.timer.isRunning)
  const history = useHistory()

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    //@ts-ignore
    setAnchorMenu(event.currentTarget)
  }

  const preventLeaving = (link?: string) => {
    if (isRunningTimerTest) {
      dispatch(timerActions.setIsExitModal(true))
      link && dispatch(timerActions.setExitLink(link))
    }
    return isRunningTimerTest
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const redirect = (link: string | null) => {
    const isPreventing = preventLeaving()
    if (isPreventing) return
    history.push(link)
  }

  const handleExit = () => {
    const isPreventing = preventLeaving()
    if (isPreventing) return
    signOut()
    user.setState(false)
  }

  return (
    <>
      {isAuthenticated && <HeaderTooltip profile={profile} toggleMenu={toggleMenu} anchorMenu={anchorMenu} />}
      {/*@ts-ignore*/}
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu} sx={{ zIndex: 9999 }}>
        {(status === "admin" || status === "moderator" || isStaff) && (
          <div>
            <MenuItem sx={{ background: isActive("admin") }} onClick={() => redirect(routes.admin)}>
              <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
                Администрирование
              </Typography>
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem sx={{ background: isActive("app/profile") }} onClick={() => redirect(routes.profile)}>
          <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
            Профиль
          </Typography>
        </MenuItem>
        <MenuItem sx={{ background: isActive("statistics") }} onClick={() => redirect(routes.statistics)}>
          <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
            Управление
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => redirect(routes.organizations)}
          sx={{ display: hasOrganizations ? "" : "none", background: isActive("organizations") }}
        >
          <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
            Мои организации
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ background: isActive("iot-page") }}
          onClick={() => redirect(routes.hardware)}
          disabled={profile?.status !== "admin"}
        >
          <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
            Оборудование
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleExit}>
          <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
            Выход
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
