import React from "react"
import { TableCell, Typography } from "@mui/material"
import { ModuleType } from "../api/module.types"
import { ModuleParamsMapper } from "../static/moduleConfigs"

export const TrajectoryModuleRow = ({ module }: { module: ModuleType }) => {
  return (
    <>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {module.index}
        </Typography>
      </TableCell>
      <TableCell align={"left"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {module.title}
        </Typography>
      </TableCell>
      <TableCell align={"center"} sx={{ width: "120px" }}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {module.start}
        </Typography>
      </TableCell>
      <TableCell align={"center"} sx={{ width: "120px" }}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {module.finish}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {module.id}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {ModuleParamsMapper["content_type"][module.content_type]}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {String(ModuleParamsMapper["type"][module.type])}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {String(ModuleParamsMapper["is_active"][module.is_active ? "true" : "false"])}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {String(ModuleParamsMapper["is_required"][module.is_required ? "true" : "false"])}
        </Typography>
      </TableCell>
    </>
  )
}
