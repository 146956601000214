//@ts-nocheck
import React from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { Grid } from "@mui/material"
import { Sensor, SensorCard, sensorsIndexes, sensorTypesMapper, SwitchCard, SwitchesCard } from "entities/Iot"
import { useTheme } from "shared/context"

interface IProps {
  changeValue: (id: number, port: number, value: boolean) => void
}

export const Sensors = ({ changeValue }: IProps) => {
  const config = useAppSelector(state => state.iot.config)
  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const values = useAppSelector(state => state.iot.values)
  const theme = useTheme()

  function sensorsMapper(sensor: Sensor, outps_descr: Array<string>, inps_descr: Array<string>) {
    switch (sensor.type) {
      case "gpio_adc":
        return (
          <>
            <SensorCard
              pid={sensor.pid}
              serial={currentHardware?.serial}
              title={sensor.descr}
              value={values[sensor.pid].outps_val[0]}
              type={"gpio_adc"}
              moduleName={sensor.descr}
            />
          </>
        )
      case "dev_dio":
        return (
          <>
            <SwitchesCard
              title={sensor.descr}
              id={sensor.pid}
              changeValue={changeValue}
              outps_val={values[sensor.pid].outps_val}
              inps_val={values[sensor.pid].inps_val}
            />
          </>
        )
      case "gpio_outp":
        return (
          <>
            <SwitchCard
              title={sensor.descr}
              id={sensor.pid}
              changeValue={changeValue}
              value={values[sensor.pid].inps_val[0]}
            />
          </>
        )
      case "dometer_boqu":
        return (
          <>
            {sensor?.outps_val?.map((e: number, index: number) => (
              <>
                {outps_descr[index] !== "ModBUS адрес устройства" && (
                  <SensorCard
                    pid={sensor.pid}
                    serial={currentHardware?.serial}
                    type={sensorTypesMapper[outps_descr[index]]}
                    title={outps_descr[index]}
                    moduleName={sensor.descr}
                    value={values[sensor.pid].outps_val[index]}
                  />
                )}
              </>
            ))}
          </>
        )
      case "onew_thermo":
        return (
          <>
            {sensor?.outps_val?.map((e: number, index: number) => (
              <>
                {outps_descr[index] !== "OneWire адрес" && (
                  <SensorCard
                    pid={sensor.pid}
                    serial={currentHardware?.serial}
                    type={sensorTypesMapper[outps_descr[index]]}
                    title={outps_descr[index]}
                    moduleName={sensor.descr}
                    value={values[sensor.pid].outps_val[index]}
                  />
                )}
              </>
            ))}
          </>
        )
      default:
        return (
          <>
            {sensor?.outps_val?.map((e: number, index: number) => (
              <>
                {outps_descr[index] !== "ModBUS адрес устройства" &&
                  outps_descr[index] !== "Температура" &&
                  outps_descr[index] !== "EC" && (
                    <SensorCard
                      pid={sensor.pid}
                      serial={currentHardware?.serial}
                      type={sensorTypesMapper[outps_descr[index]]}
                      title={outps_descr[index]}
                      moduleName={sensor.descr}
                      value={values[sensor.pid].outps_val[index]}
                    />
                  )}
              </>
            ))}
          </>
        )
    }
  }

  return (
    <Grid
      container
      gap={theme.spacing(4)}
      sx={{ width: "100%", justifyContent: "center", marginBottom: theme.spacing(4) }}
    >
      {!!config && !!values && config.real_devs.map((e: Sensor) => sensorsMapper(e, e.outps_descr, e.inps_descr))}
    </Grid>
  )
}
