import * as Styled from "./TimerStartModal.styles"
import { useBackModalRedirection } from "entities/Test/model/backCourseRedirection"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { Button } from "shared/ui"

interface IProps {
  isOpen: boolean
  onClose: () => void
  courseSlug: string
}

const TimerExpiredModal = ({ isOpen, onClose, courseSlug }: IProps) => {
  return (
    <ModalTimer isOpen={isOpen} onClose={onClose} size={"small"}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>Срок прохождения теста истёк. Вы не можете начать выполнение теста.</Styled.Typography>
        </Styled.Wrapper>
        <Buttons courseSlug={courseSlug} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ courseSlug }: { courseSlug: string }) => {
  const { redirect } = useBackModalRedirection(courseSlug)

  const onEscape = () => {
    redirect()
  }

  return (
    <Styled.ButtonWrapper>
      {/* @ts-ignore */}
      <Button onClick={onEscape} variant="outlined" width={154}>
        Выйти
      </Button>
    </Styled.ButtonWrapper>
  )
}

export { TimerExpiredModal }
