import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  faqContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 100,
    paddingBottom: 100,
    paddingLeft: 124,
    paddingRight: 124,
    marginTop: 64,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  questionContainer: {
    width: "50vw",
    "& > div": {
      borderBottom: "1px solid #01D4FE",
      margin: "0 !important",
    },
    "& > div:first-child": {
      borderTop: "1px solid #01D4FE",
      borderRadius: 0,
    },
    "& > div:last-of-type": {
      borderRadius: 0,
    },
    "& > div > div > div": {
      marginBottom: 26,
    },
    "& > div > div:first-of-type > div": {
      marginTop: 26,
    },
    "& > div > div:first-of-type > div:last-of-type": {
      margin: 0,
    },
    "& > div > div > div svg": {
      width: 36,
      height: 36,
    },
    "& > div > div > div > div > div > div > div": {
      fontWeight: 100,
    },
    "& > div:before": {
      display: "none",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "42vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: 88,
    },
  },
}))
