import { utils, writeFileXLSX } from "xlsx"
import { stringify } from "csv-stringify/sync"

export enum EnumDownload {
  XLSX = ".xlsx",
  CSV = ".csv",
}

const downloadSheetFile = (title: string, scores: unknown[][], downloadType: EnumDownload) => {
  if (!scores) {
    return
  }
  if (downloadType === EnumDownload.XLSX) {
    let wb = utils.book_new()
    let ws = utils.aoa_to_sheet(scores)
    utils.book_append_sheet(wb, ws, "Scores")
    writeFileXLSX(wb, `${title}.xlsx`)
  }
  if (downloadType === EnumDownload.CSV) {
    var data = new Blob([stringify(scores)], { type: "text/csv" })
    var csvURL = window.URL.createObjectURL(data)
    var tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", `${title}.csv`)
    tempLink.click()
  }
}

export default downloadSheetFile
