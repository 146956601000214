import React, { useState } from "react"
import * as Styled from "./ui/Tags.styles"
import { CreateTag } from "features"
import { TagsList } from "./ui/TagsList"

export const Tags = () => {
  const [tags, setTags] = useState<Array<{ value: number; label: string }>>([])

  return (
    <Styled.TagsMainDiv>
      <CreateTag setTags={setTags} />
      <TagsList tags={tags} setTags={setTags} />
    </Styled.TagsMainDiv>
  )
}
