import { SendNotification } from "../../entities/Notification/ui/SendNotification"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
import Cookies from "js-cookie"

const SendSingleNotification = ({ user, title, isOpen, onClose }) => {
  async function createNotification(content) {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.post(`/notifications/`, {
      receiver: user,
      title: title,
      content: content,
    })
  }

  return <SendNotification isOpen={isOpen} onClose={onClose} createNotification={createNotification} />
}

export default SendSingleNotification
