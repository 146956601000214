import * as Styled from "shared/ui/Filters/Filter.styles"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export const Title = styled(Typography)`
  letter-spacing: 0.2px;
  max-width: 680px;
  margin-bottom: 16px;
`

export const SubTitle = styled(Styled.SubTitle)`
  margin-bottom: 48px;
`

export const LightText = styled(Styled.LightText)`
  font-size: 18px;
  margin-bottom: 40px;
`

export const ShadowedSection = styled(Styled.ShadowedSection)`
  padding: 32px 32px;
  margin-bottom: 40px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;

  & :nth-child(1) {
    flex-grow: 43;
  }
  & :nth-child(2) {
    flex-grow: 28;
  }
  & :nth-child(3) {
    flex-grow: 28;
  }
`

export const FormHelper = styled(Styled.FormHelper)`
  font-size: 18px;
  font-weight: 300;
`

export const TotalWrapper = styled.div`
  margin-bottom: 24px;
`
