import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  registrationButton: {
    background: "#1565C0",
    marginTop:"40px",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing:"0.3px",
    borderRadius: 4,
    color:"#FFFFFF",
    height:"44px",
    width:"500px",
    border:0,
    cursor: "pointer"
  },
  circle: {
    borderRadius: "50%",
    width: 32,
    height: 32,
    padding: 5,
    background: "transparent",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  dialogEmail : {
    background: "#FBFBFB",
  },
}));
