import { Chip } from "@mui/material"

const NewNotifyChip = () => {
  return (
    <Chip
      label="Новое"
      variant="outlined"
      style={{
        color: "#FA5D55",
        borderColor: "#FA5D55",
        background: "transparent",
        marginInline: 12,
        translate: "0 12px",
        fontWeight: "bold",
      }}
    />
  )
}

export { NewNotifyChip }
