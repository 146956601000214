import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"

export function findCourse() {}

export function updateCourse(content, stages, presets, infoFields, onSubmit, onError) {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  content["template_props"] = JSON.stringify(presets)
  let oldInfoFields = content["info_fields"]
  let oldStages = content["stages"]
  delete content["stages"]
  delete content["info_fields"]
  delete content["participants"]
  delete content["owner"]
  if (!!content.is_for_teams && content["team_props"] === "{}")
    content["team_props"] =
      '{"max_members":"50","members_categories":"участник", "time_interval":{"start":"2023-01-01", "end":"2030-12-31"}, "progress_method": "max_personal_module"}'
  axios
    .patch(`/content/courses/${content.id}/`, content)
    .then(onSubmit)
    .catch(err => onError(err))

  setTimeout(() => {
    if (stages.length > 0) {
      let updateStages = []
      for (let index = 0; index < stages.length; index++) {
        let stage = stages[index]
        stage.course = content.id
        if (!stage.id) {
          axios.post("/content/stages/", stage).catch(err => onError(err))
        } else if (!!stage.remove) {
          axios.delete(`/content/stages/${stage.id}/`)
        } else {
          axios.put(`/content/stages/${stage.id}/`, stage).catch(err => onError(err))
          updateStages.push(stage.id)
        }
      }
      let deletedStages = oldStages.filter(e => !updateStages.includes(e.id))
      deletedStages.forEach(item => {
        axios.delete(`/content/stages/${item.id}/`)
      })
    }
  }, 500)
}

export function addAffiliations(course, organizations, onSubmit) {
  axios.get("/organizations/").then(() => {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.put(`/content/courses/${course.id}/add_affiliations/`, { affiliations: organizations }).then(onSubmit)
  })
}

export function removeAffiliations(course, organizations, onSubmit) {
  axios.get("/organizations/").then(() => {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.patch(`/content/courses/${course.id}/add_affiliations/`, { affiliations: organizations }).then(onSubmit)
  })
}
