import React, { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Menu, MenuItem } from "@mui/material"
import { useAppSelector } from "shared/hooks/useAppSelector"
// @ts-ignore
import Cookies from "js-cookie"
import { setUserStatus, UserChip } from "entities/Iot"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch"

const options: Array<"owner" | "moderator" | "observer"> = ["moderator", "observer", "owner"]
export const ChangeUserRole = ({ user }: { user: { email: string, role: "owner" | "moderator" | "observer"} }) => {

  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selected, setSelected] = useState(user.role)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function onClickRole(role: string) {
    if (!!role && !!currentHardware) {
      dispatch(setUserStatus({ role: role, email: user.email, serial: currentHardware.serial}))
    }
  }

  return (
    <>
      {/*@ts-ignore*/}
      <ArrowDropDownIcon onClick={handleClick} sx={{ alignSelf: "center", cursor: "pointer" }} />
      {/*@ts-ignore*/}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {options.map((e: "owner" | "moderator" | "observer") => (
          <MenuItem onClick={() => onClickRole(e)} selected={e === selected}>
            <UserChip label={e} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
