import { DialogTitle, DialogActions, DialogContent, Theme } from "@mui/material"
import { styled } from "@mui/material/styles"
import ClearIcon from "@mui/icons-material/Clear"
// @ts-ignore
import themes from "shared/themes"

interface ContentWrapperProps {
  theme?: Theme
  isTopPadding?: string
  isCustomWidth?: boolean
}

export const DialogStyle = (
  isSmall: boolean,
  width?: number,
  height = 600,
  borderRadius?: number,
  fullScreen?: boolean,
  bgColor?: string
): object => ({
  style: {
    display: "inline-block",
    boxShadow: "none",
    backgroundColor: !!bgColor ? bgColor : themes.default.palette.common.white,
    minWidth: isSmall ? "600px" : `min(90%, ${width ? `${width}px` : themes.default.spacing(252.5)})`,
    minHeight: isSmall ? themes.default.spacing(58) : height ? `${height}px` : themes.default.spacing(150),
    maxHeight: fullScreen ? "auto" : height ? `${height}px` : "600px",
    borderRadius: isSmall ? "12px" : borderRadius || "0px",
    overflowY: "hidden",
  },
})

export const CloseWrapper = styled(DialogActions)(() => ({
  position: "absolute",
  right: 4,
  top: 8,
}))

export const Close = styled(ClearIcon)(() => ({
  cursor: "pointer",
  ":hover": { opacity: 0.5 },
}))

export const ContentWrapper = styled(DialogContent)<ContentWrapperProps>(({ theme, isTopPadding, isCustomWidth }) => ({
  maxHeight: isTopPadding ? "430px" : "520px",
  paddingRight: isCustomWidth ? "" : theme.spacing(18),
  paddingLeft: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    maxHeight: "80%",
  },
  [theme.breakpoints.down("xs")]: {
    maxHeight: "70%",
    paddingRight: theme.spacing(6),
  },
}))

export const DialogHeader = styled(DialogTitle)(({ theme }) => ({
  alignItems: "center",
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(6),
}))

export const HeaderWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}))

export const SubmitButton = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
}))

export const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
}))

export const AlertWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}))

export const IconWrapper = styled("div")(() => ({
  scale: "0.5",
}))
