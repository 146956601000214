import * as Mui from "@mui/material"
import styled from "@emotion/styled"

export const HeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SortIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const TableContainer = styled.div`
  position: relative;
`

export const TableWrapper = styled(Mui.TableContainer)<{ height: string }>`
  background-color: "#FFF";
  height: ${props => props.height};
  width: unset;
`

export const Table = styled(Mui.Table)`
  width: unset;
  overflow-x: auto;
`

export const Header = styled(Mui.TableHead)`
  height: 106px;
`
