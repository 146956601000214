const SaveScoreIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="3.44653" width="15" height="16.5" rx="1.5" fill="#1565C0" />
      <path
        d="M7.74686 12.8153C7.66605 12.91 7.51931 12.9087 7.44025 12.8125L6.18737 11.2888C5.88941 10.9265 5.33097 10.9383 5.0486 11.3129C4.84527 11.5827 4.85522 11.9571 5.07259 12.2157L6.76054 14.2237C7.15733 14.6957 7.8826 14.6996 8.28447 14.2319L11.9747 9.93729C12.1674 9.71304 12.1914 9.38936 12.0339 9.13916C11.7917 8.75461 11.2462 8.71511 10.9512 9.06076L7.74686 12.8153Z"
        fill="white"
      />
      <path
        d="M18.8867 4.19641C18.8867 3.7822 19.2225 3.44641 19.6367 3.44641H22.1117C22.5259 3.44641 22.8617 3.7822 22.8617 4.19641V6.14641H18.8867V4.19641Z"
        fill="#1565C0"
      />
      <path
        d="M18.8867 7.20105H22.8617V18.1985C22.8617 18.3607 22.8091 18.5185 22.7119 18.6483L21.4744 20.2996C21.1744 20.6999 20.574 20.6999 20.274 20.2996L19.0365 18.6483C18.9393 18.5185 18.8867 18.3607 18.8867 18.1985V7.20105Z"
        fill="#1565C0"
      />
    </svg>
  )
}

export default SaveScoreIcon
