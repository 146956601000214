import React, { useEffect, useRef, useState } from "react"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useTheme } from "../../shared/context"
import { Catalog as CatalogWidget, Footer, Header } from "widgets"
import { styled } from "@mui/material/styles"
import config from "../../shared/config"

const tab_types_mapper = {
  1: "event",
  2: "course",
  3: "webinar",
}

const Catalog = props => {
  const [orgId, setOrgId] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [tabs, setTabs] = useState(undefined)

  useEffect(() => {
    setIsLoading(true)
    if (!!props.match.params.organization) {
      axios.get(`/organizations/`, { params: { id: props.match.params.organization } }).then(res => {
        setOrgId(res.data[0].id)
        setIsLoading(false)
        setTabs(JSON.parse(res.data[0].template_props)?.catalog_tabs || undefined)
      })
    }
    const window_url = window.location.origin
    if (!window_url.split("//")[1].includes(config.hostApi.split("//")[1])) {
      axios.get(`/organizations/`, { params: { domain: window_url.split("//")[1] } }).then(res => {
        setOrgId(res.data[0].id)
        setIsLoading(false)
        setTabs(JSON.parse(res.data[0].template_props)?.catalog_tabs || undefined)
      })
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <div style={{ backgroundColor: "#F9F9F9" }}>
      <Header variant={"shadow"} />
      <ContentContainer>
        {!isLoading && (
          <CatalogWidget
            tabs={tabs}
            organization={!!orgId ? Number(orgId) : props.match.params.organization || null}
            tag={props.match.params?.tag || null}
          />
        )}
      </ContentContainer>
      <Footer />
    </div>
  )
}

export default Catalog

const ContentContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(16),
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))
