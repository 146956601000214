import React, { useState } from "react"
//@ts-ignore
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Typography } from "@mui/material"
import { ErrorMapType, SectionType, SubsectionType } from "../api/module.types"
import { Button, Modal, TextField } from "shared/ui"
import * as Styled from "./Module.styles"
import { moveItemInArray } from "../../../shared/util"
import { SectionItem } from "./SectionItem"
import AddIcon from "@mui/icons-material/Add"

export const Sections = ({
  sections,
  setModule,
  deletedSections,
  deletedSubsections,
  setDeletedSections,
  setDeletedSubsections,
  errors,
}: {
  errors: ErrorMapType
  deletedSections: Array<number | null>
  deletedSubsections: Array<number | null>
  setDeletedSubsections: React.Dispatch<React.SetStateAction<(number | null)[]>>
  setDeletedSections: React.Dispatch<React.SetStateAction<(number | null)[]>>
  sections: SectionType[]
  setModule: (sections: SectionType[]) => void
}) => {
  const [openCreateNewSection, setOpenCreateNewSection] = useState(false)

  function onDragEnd(result: {
    destination?: {
      index: number
    }
    source?: {
      index: number
    }
  }) {
    if (!result.source || !result.destination) return
    let new_sections = [...sections]
    moveItemInArray(new_sections, result.source.index, result.destination.index)
    for (let i = 0; i < sections.length; i++) {
      new_sections[i].index = i + 1
    }
    setModule([...new_sections])
  }

  function createNewSection(params: SectionType) {
    let new_sections = [...sections]
    new_sections = [...new_sections, { ...params, index: sections.length + 1 }]
    setModule([...new_sections])
  }

  function handleChangeSection(index: number, field: keyof SectionType, value: boolean | string | SubsectionType[]) {
    //@ts-ignore
    sections[index][field] = value
    setModule([...sections])
  }

  function deleteSection(section: SectionType) {
    let index = sections.indexOf(section)
    let new_sections = [...sections]
    new_sections.splice(index, 1).sort((a, b) => (a.index > b.index ? 1 : -1))
    for (let i = 0; i < new_sections.length; i++) {
      new_sections[i].index = i + 1
    }
    setModule([...new_sections])
    setDeletedSections(prev => [...prev, section.id])
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Styled.CardSectionsSettings>
        <Typography variant={"h4"} sx={{ marginBottom: "24px" }}>
          Структура модуля
        </Typography>
        <Droppable droppableId="draggable-0">
          {(provided: any, snapshot: any) => (
            <div
              ref={provided.innerRef}
              style={{ width: "100%", padding: "0px 4px", zIndex: "5" }}
              {...provided.droppableProps}
            >
              {sections
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((item, index) => (
                  <Draggable draggableId={"draggable-" + index} index={index}>
                    {(provided: any, snapshot: any) => (
                      <SectionItem
                        disableDelete={sections.length === 1}
                        errors={errors}
                        deletedSubsections={deletedSubsections}
                        setDeletedSubsections={setDeletedSubsections}
                        index={index}
                        deleteSection={deleteSection}
                        handleChangeSection={handleChangeSection}
                        section={item}
                        provided={provided}
                      />
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <CreateSection createNewSection={createNewSection} />
      </Styled.CardSectionsSettings>
    </DragDropContext>
  )
}

const CreateSection = ({ createNewSection }: { createNewSection: (section: SectionType) => void }) => {
  const [form, setForm] = useState<SectionType>({ id: null, title: "", is_active: false, index: 0, subsections: [] })

  const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>, field: "title") => {
    if (event.target.value.length === 128) return
    form.title = event.target.value
    setForm({ ...form })
  }

  function handleClickCreate() {
    createNewSection(form)
  }

  return (
    <Styled.CreateSectionModal>
      <TextField
        style={{ width: "300px" }}
        variant={"outlined"}
        label={"Название раздела"}
        value={form.title}
        onChange={e => handleChangeField(e, "title")}
      />
      <Button disabled={form.title.length === 0} color={"primary"} variant={"outlined"} onClick={handleClickCreate}>
        Добавить раздел
        <AddIcon sx={{ height: "100%", padding: 0, marginLeft: 2 }} />
      </Button>
    </Styled.CreateSectionModal>
  )
}
