import { Block } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"

export const backgroundStyle = result => {
  return {
    borderRadius: 6,
    border: result ? (result["overall"] ? "1px solid #81FF81" : "1px solid #FF7373") : "1px solid transparent",
    backgroundColor: result ? (result["overall"] ? "#E6FCDE" : "#FFDEDE") : null,
  }
}

export const backgroundStyleDrop = result => {
  return {
    border: result ? (result["overall"] ? "1px solid #81FF81" : "1px solid #FF7373") : "1px solid transparent",
    backgroundColor: result ? (result["overall"] ? "#E6FCDE" : "#FFDEDE") : "#F0F2F9",
  }
}

export default makeStyles(theme => ({
  mainModule: {
    paddingLeft: "25vw",
    margin: 0,
    position: "relative",
    top: 100,
  },
  paper: {
    margin: "24px 0",
    backgroundColor: "#f3f3f3",
  },
  virtualEnv: {
    marginBottom: 64,
  },
  test: {
    marginBottom: "3%",
  },
  testHeader: {
    padding: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1280,
  },
  codeEditor: {
    margin: 27,
  },
  checkButton: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#1665c1",
    float: "left",
    color: "#ffffff",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1665c1",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
    "&:hover": {
      background: "#115eb6",
      borderColor: "#115eb6",
      color: "#ffffff",
    },
    "&:disabled": {
      background: "#ebebeb",
      borderColor: "#98999d",
      color: "#98999d",
    },
  },
  checkButtonWt: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#ffffff",
    float: "left",
    color: "#1565C0",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1564c0",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  textFileName: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Roboto",
    lineHeight: 1,
  },
  textMess: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Roboto",
    lineHeight: 1,
    padding: "15px 5px",
    boxSizing: "content-box",
    height: 14,
  },
  progressBar: {
    flexGrow: 1,
    marginBottom: "6px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  barColorPromary: {
    backgroundColor: "#00C9FF",
  },
  dataStreamButton: {
    margin: 24,
  },
  checkmarkPrimary: {
    color: "#00C9FF",
    fontSize: 20,
    marginRight: 10,
  },
  checkmarkSecondary: {
    color: "#00C9FF",
    fontSize: 16,
    marginRight: 10,
    marginLeft: 18,
  },
  iconSecondary: {
    fontSize: 20,
    marginRight: 10,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },

  /* new test styles */
  /*testButtons: {
        marginTop: -12,
    },*/
  testCheckbox: {
    paddingBottom: 6,
    paddingTop: 6,
    marginLeft: 0,
    alignItems: "flex-start",
    "& span": {
      paddingTop: 0,
    },
  },
  testCheckboxLabel: {
    fontSize: 18,
    lineHeight: "125%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 300,
  },
  testDetailed: {
    borderRadius: "4px",
    margin: 0,
    display: "inline-block",
    "& textarea": {
      fontSize: 18,
      lineHeight: 1,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
    },
    "& > div": {
      paddingBottom: 10,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
    },
    "& > label": {
      fontSize: 18,
      lineHeight: 1,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      top: "-5px",
      color: "#98999C",
    },
  },
  testSort: {
    border: "1px solid #F0F2F9",
    borderRadius: 6,
    margin: 0,
    "& p": {
      fontSize: 16,
      fontFamily: "Roboto",
      fontWeight: 300,
    },
    "& > div > div": {
      marginBottom: 12,
      marginTop: 12,
    },
  },
  testMatrixTd: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 16,
    lineHeight: "24px",
  },
  testMatrixTh: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
  },
  // testAttach: {
  //     display:'block',
  //     fontFamily: 'Roboto',
  //     fontStyle: 'normal',
  //     fontWeight: 300,
  //     fontSize: 16,
  //     lineHeight: '34px',
  //     marginBottom: 16,
  //     marginTop: 16,
  //     marginLeft: 7,
  //     marginRight: 7,
  //     color:'#000',
  // },
  // testAttachLabel: {
  //     backgroundColor:'#F0F2F9',
  //     display:'block',
  //     width:'100%',
  // },
  checkButtonWt: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#ffffff",
    float: "left",
    color: "#1565C0",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1564c0",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  textFileName: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Roboto",
    lineHeight: 1,
  },
  // testAttachBlock: {
  //     "& > label": {
  //         backgroundColor:'#F0F2F9',
  //         display:'block',
  //         width:'100%',
  //         border:'1px solid #F0F2F9',
  //     },
  //     "& > label:hover": {
  //         backgroundColor:'#F0F2F9',
  //         borderColor:'#c3c3c3',
  //     },
  // },
  /* end new test styles */

  contentText: {
    "& > *": {
      marginBottom: 12,
      fontFamily: "Roboto",
      color: "rgba(49, 52, 56, 1)",
      fontSize: 18,
      fontWeight: 100,
    },
    "& p": {
      fontSize: 18,
      lineHeight: "150%",
      marginTop: 18,
      marginBottom: 18,
    },
    "& iframe": {
      width: 800,
      border: 0,
      display: "block",
      margin: "24px auto 48px",
    },
    "& a": {
      margin: 0,
      target: "_blank",
      rel: "noopener noreferrer",
      color: "#376fd0",
      textDecoration: "none",
      cursor: "pointer",
    },
    "& a:hover": {
      color: "#376fd0",
      borderBottom: "1px solid #376fd0",
    },
    "& h2": {
      marginTop: 48,
      fontSize: 20,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    "& h4": {
      marginTop: 48,
      fontSize: 16,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    "& img": {
      display: "block",
      marginTop: 40,
      marginBottom: 18,
      border: "1px solid rgba(224, 224, 224, 1)",
      borderRadius: 6,
    },
    "& ul": {
      marginLeft: 24,
      marginTop: 6,
      marginBottom: 18,
      listStyleType: "disc",
    },
    "& ol": {
      marginLeft: 24,
      marginTop: 6,
      marginBottom: 18,
      listStyleType: "decimal",
    },
    "& li": {
      lineHeight: 1.5,
      marginLeft: 10,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
    },
    "& i": {
      fontWeight: 400,
      fontStyle: "italic",
      color: "#797979",
    },
    "& .contentwrapper-cnti0 p": {
      textAlign: "justify",
    },
    //images
    "& .wrapimg, .wrapimg-cnti0": {
      marginTop: 48,
      marginBottom: 24,
      marginRight: 0,
      marginLeft: 0,
    },
    "& .wrapimg img, .wrapimg-cnti0 img": {
      marginTop: 0,
      marginBottom: 6,
      marginRight: "auto",
      marginLeft: "auto",
      display: "block",
    },
    "& .wrapimg span, .wrapimg-cnti0 span": {
      fontSize: 14,
      textAlign: "center",
      display: "block",
    },
    // red notice
    "& .notice-cnti0": {
      fontWeight: 400,
      color: "#d13636",
      fontSize: 14,
    },
    // tables
    "& .table-cnti0": {
      width: "100%",
      maxWidth: 1000,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "48px !important",
      borderCollapse: "collapse",
    },
    "& .table-cnti0 td": {
      border: "1px solid #c3c3c3",
      padding: 10,
    },
    "& .tblcenter-cnti0 td": {
      textAlign: "center",
    },
    "& .table-cnti0 th": {
      backgroundColor: "#eff2f9",
      border: "1px solid #c3c3c3",
      fontWeight: 400,
      textAlign: "center",
      padding: "5px 0",
    },
  },
}))
