import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import { ShadowedSection } from "shared/ui"
export { SectionWrapper, boxShadow } from "shared/ui"
export { Header, Title } from "shared/ui/Filters/Filter.styles"

export const Wrapper = styled(ShadowedSection)`
  min-height: 475px;
`

export const Contatiner = styled.div`
  display: flex;
  justify-content: space-between;
`
export const FiltersWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-right: min(117px, 15%);
  gap: 24px;
`

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  width: 100%;
  flex-grow: 1;
`

export const LoaderWrapper = styled.div`
  flex-grow: 1;
  margin-top: 10px;
  width: 6px;
`
export const EditorWrapper = styled.div`
  flex: 1;
`

export const EditorTitle = styled(Typography)`
  color: #313439;
  font-weight: 500;
  line-height: 125%;
  max-width: 468px;
  margin-bottom: 16px;
  margin-inline: auto;
  text-align: center;
`

export const EditorLable = styled.span`
  display: block;
  color: var(--text-primary, #313439);
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 155%;
  margin-bottom: ${(props: { margin?: number }) => props.margin}px;
`

export const StrongText = styled.span`
  color: ${(props: { color?: string }) => props.color || "#313439"};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 125%;
  max-width: 468px;
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  width: clamp(306px, 30vw, 580px);
  margin-top: 30px;
  margin-inline: auto;
  column-gap: 24px;
  row-gap: 40px;
`
