import React, { useEffect, useMemo, useState } from "react"
import useStyles from "./styles"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"

import moment from "moment"

import { editUser } from "entities/User"
import { useTheme } from "../../shared/context"

const editorFields = {
  // lastName: "Фамилия",
  // firstName: "Имя",
  // middleName: "Отчество",
  phone: "Телефон",
  country: "Страна",
  place: "Населенный пункт",
  organization: "Организация",
  birthday: "Дата рождения (в формате XX.XX.XXXX, число.месяц.год)",
  sex: "Пол",
  about: "Обо мне",
}

const ProfileEditor = props => {
  var profile = props.profile

  const classes = useStyles()
  const theme = useTheme()

  const [editedProfile, setEditedProfile] = useState(null)
  const [checked, setChecked] = useState(null)
  const [helperText, setHelperText] = useState("")

  const today = useMemo(() => {
    return new Date()
  }, [props])

  function resetEditor() {
    if (profile.full_name) {
      var name = profile.full_name.split(" ")
    } else {
      var name = ["", "", ""]
    }
    if (profile.location) {
      var location = profile.location.split(", ")
    } else {
      var location = ["", ""]
    }
    setEditedProfile({
      // lastName: name[0],
      // firstName: name[1],
      // middleName: name[2] || "",
      country: location[0],
      place: location[1],
      organization: profile.organization,
      birthday: !!profile.birthday
        ? new Date(profile.birthday).toLocaleDateString("ru-RU")
        : today.toLocaleDateString("ru-RU"),
      sex: profile.sex,
      about: profile.about,
      phone: profile.phone,
    })
    setChecked(profile.is_subscribed)
  }

  useEffect(() => {
    resetEditor()
  }, [props])

  function changeEditedValue(value, field) {
    editedProfile[field] = value
    setEditedProfile({ ...editedProfile })
  }

  const onConfirmSuccess = res => {
    props.setProfile({ ...res.data })
    setHelperText("Готово")
  }

  const onConfirmError = err => {
    let arr = Object.entries(err.response.data)
    let text = ""
    for (let i = 0; i <= arr.length - 1; i++) {
      text += `${arr[i][1]}`
    }
    setHelperText(text)
  }

  function confirmEditedProfile() {
    // profile.full_name = `${editedProfile.lastName} ${editedProfile.firstName} ${editedProfile.middleName}`
    if (!!editedProfile.country && !!editedProfile.place) {
      profile.location = `${editedProfile.country}, ${editedProfile.place}`
    } else if (!!editedProfile.country && !editedProfile.place) {
      profile.location = `${editedProfile.country}`
    } else {
      profile.location = null
    }
    profile.phone = editedProfile.phone
    profile.organization = !!editedProfile.organization ? editedProfile.organization.replace(/[«»]/g, '"') : ""
    if (!!editedProfile.birthday) {
      let birthday = editedProfile.birthday
      profile.birthday = moment(birthday, "DD.MM.YYYY").format("YYYY-MM-DD")
    } else {
      profile.birthday = moment(today, "DD.MM.YYYY").format("YYYY-MM-DD")
    }
    profile.sex = editedProfile.sex
    profile.about = editedProfile.about
    profile.is_subscribed = checked
    if (!editedProfile.country && !!editedProfile.place) setHelperText("Пожалуйста, укажите страну")
    else {
      setHelperText("")
      editUser(profile, onConfirmSuccess, onConfirmError)
    }
  }

  if (!profile || !editedProfile) return <></>

  return (
    <Grid container style={{ paddingRight: 0 }}>
      <Grid item xl={4} xs={0} style={{ paddingBottom: 40 }}>
        <Typography className={classes.profileH1}>Личные данные</Typography>
        <Typography className={classes.profileP} style={{ maxWidth: 275 }}>
          Вносите реальные данные, потому что именно они будут отображены в вашем сертификате.
        </Typography>
      </Grid>
      <Grid item container xl={8} xs={12} flexDirection="column" alignItems="flex-start" justifyContent="space-between">
        {Object.entries(editedProfile).map(
          c =>
            c[0] != "sex" &&
            c[0] != "is_subscribed" && (
              <TextField
                value={c[1]}
                onChange={e => changeEditedValue(e.target.value, c[0])}
                label={editorFields[c[0]]}
                variant="standard"
                style={{ width: "100%", marginBottom: 60 }}
              />
            )
        )}
        <FormControl>
          <FormLabel id="sex-row-radio-buttons-group-label">Пол</FormLabel>
          <RadioGroup
            row
            aria-labelledby="sex-row-radio-buttons-group-label"
            name="sex-row-radio-buttons-group"
            value={editedProfile.sex}
            onClick={e => changeEditedValue(e.target.value, "sex")}
          >
            <FormControlLabel value="Женский" control={<Radio />} label="Женский" />
            <FormControlLabel value="Мужской" control={<Radio />} label="Мужской" />
          </RadioGroup>
        </FormControl>
        <FormControlLabel
          style={{ marginTop: 60 }}
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              size="medium"
              sx={{
                color: "#313439",
                "&.Mui-checked": {
                  color: "#313439",
                },
              }}
            />
          }
          label={
            <Typography style={{ color: "black", fontSize: 14, width: "100%" }}>
              Я хочу получать рассылку о новостях платформы
            </Typography>
          }
        />
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "155%",
            color: helperText === "Готово" ? theme.palette.success.main : theme.palette.error.main,
            marginTop: 40,
          }}
        >
          {helperText}
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", gap: 40, marginTop: 10 }}
        >
          <Button variant="contained" color="primary" onClick={confirmEditedProfile}>
            СОХРАНИТЬ
          </Button>
          <Button variant="outlined" color="primary" onClick={resetEditor}>
            СБРОС ВВЕДЕННЫХ ДАННЫХ
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default ProfileEditor
