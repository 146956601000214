import * as Styled from "./Legend.styles"
import SummaryTemplateTab from "../styles/SummaryTemplateTab"
import { useState } from "react"
import { Autocomplete } from "shared/ui"
import { Size } from "shared/ui/styles.const"
import { LegendCard } from "./LegendCard"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { getBadgeIcon } from "entities/Organization/lib/getBadgeIcon"
import { getDisplayedValue } from "entities/Organization/lib/statistics"

export interface ITabOption {
  title: string
  value: number
  enable?: boolean
  hidden?: boolean
}

interface IProps {
  type: StatisticsVariants
  infoType: { id: number; value: string; label: string }
  setInfoType: (value: { id: number; value: string; label: string }) => void
  tabOptions: ITabOption[]
  infoOptions: { id: number; value: string; label: string }[]
}

const Legend = ({ type, infoType, setInfoType, tabOptions, infoOptions }: IProps) => {
  const initActiveTab = tabOptions.find(option => !option?.hidden)
  const [tab, setTab] = useState(initActiveTab?.value || 0)
  const { statistics } = useStatistics()
  const { charts, general } = statistics[type]
  const currentCharts = charts[infoType.value as "general" | "activity"] || {}
  const currentLabels = Object.keys(currentCharts)
  const statusBadge = general?.submitter.status_badge
  const legendBackgroundColor = type === "survey" ? "surveyBg" : "personalBg";

  const getWidth = (index: number) => {
    if (index === 0) return 226
    if (index === 1) return 152
    if (index === 2) return 153
    return 153
  }

  const getLabel = (value: string) => {
    if (type === "survey") {
      return `Ответы на вопрос “${value}”`
    }
    if (type === "personal") {
      return value
    }
    return ""
  }

  const handleChange = (_: any, value: any) => {
    setInfoType(value)
  }

  return (
    <Styled.LegendWrapper legendBackgroundColor={legendBackgroundColor}>
      <SummaryTemplateTab tab={tab} changeTab={(e, newTab) => setTab(newTab)} tabOptions={tabOptions} isLegend />
      {tab === 0 && (
        <>
          <Styled.GeneralItems>
            {general?.info?.map((item, index) => (
              <Styled.GeneralItem width={getWidth(index)} color={item.color}>
                <Styled.GeneralCount>{getDisplayedValue(index, item.data)}</Styled.GeneralCount>
                <Styled.GeneralLabel>{item.title}</Styled.GeneralLabel>
              </Styled.GeneralItem>
            ))}
          </Styled.GeneralItems>
          <Styled.BadgeWrapper>{getBadgeIcon(statusBadge)}</Styled.BadgeWrapper>
          <Styled.Name>{general?.submitter.full_name}</Styled.Name>
          <Styled.Email>{general?.submitter.email}</Styled.Email>
        </>
      )}
      {tab === 1 && (
        <Styled.LegendInnerWrapper>
          <Styled.SelectorWrapper>
            <Autocomplete
              label="Тип информации"
              options={infoOptions}
              size={Size.small}
              value={infoType.label}
              onChange={handleChange}
              disabled={type === "survey"}
            />
          </Styled.SelectorWrapper>
          <Styled.InfoItemsWrapper>
            <Styled.InfoItems>
              {currentLabels &&
                currentLabels.map((chart, index) => (
                  <LegendCard label={getLabel(chart)} legend={currentCharts[chart]} key={index} />
                ))}
            </Styled.InfoItems>
          </Styled.InfoItemsWrapper>
        </Styled.LegendInnerWrapper>
      )}
    </Styled.LegendWrapper>
  )
}

export { Legend }
