import React from "react"
import { useTheme, useUserState } from "shared/context"
import * as Styled from "./Header.Styled"
import { Button } from "shared/ui"

export const LoginAndRegistrationContainer = () => {
  const theme = useTheme()
  const { login, registration, isAuthenticated } = useUserState()

  return (
    <>
      {!isAuthenticated && (
        <Styled.LoginAndRegistrationContainer>
          {/*@ts-ignore*/}
          <Button onClick={() => login.setOpen(true)} variant="outlined" color="primary" size="medium">
            ВХОД
          </Button>
          {/*@ts-ignore*/}
          <Button onClick={() => registration.setOpen(true)} variant="contained" color="primary" size="medium">
            РЕГИСТРАЦИЯ
          </Button>
        </Styled.LoginAndRegistrationContainer>
      )}
    </>
  )
}
