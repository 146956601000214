import React from "react";
import { styled } from '@mui/material/styles';

import { Box, LinearProgress, Typography } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 4 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="inherit" color="black">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const ProgressBar = styled(LinearProgressWithLabel)`
  flex-row: 1;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: ${(props) => props.theme.spacing(4)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;
