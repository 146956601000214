export const inviteParticipantsFilters = {
  "": "",
  ФИО: "last_name",
  Почта: "email",
  Телефон: "phone",
  Статус: "participant_status",
  Категория: "category",
  // Команда: "team",
}
export const inviteParticipantsHeaders = [
  { label: "ФИО", value: "last_name" },
  { label: "Почта", value: "email" },
  { label: "Телефон", value: "phone" },
  { label: "Статус", value: "status" },
  { label: "Категория", value: "category" },
  { label: "Команда", value: "" },
  { label: "Дата подачи", value: "created" },
  { label: "Комментарий", value: "" },
  { label: "Этап с огр. доступом", value: "" },
  { label: "Статус активности", value: "is_active" },
]

export const filtersToHeaders = (filters: Record<string, string>): { label: string; value: string }[] =>
  // @ts-ignore
  Object.keys(filters).reduce((acc, key) => {
    if (key !== "") {
      return [...acc, { ["value"]: filters[key], ["label"]: key }]
    } else {
      return acc
    }
  }, [])
