import React, { useEffect, useRef } from "react"
import * as Styled from "./ui/OrganizationFeatureList.styles"
import { Typography } from "@mui/material"
import { FeatureItemMainDiv } from "./ui/OrganizationFeatureList.styles"
import { useTheme } from "../../shared/context"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  props: {
    index: number
    backgroundColor: string
    items: {
      icon: string
      image: string
      text: string
      title: string
      direction?: "reverse" | "normal"
      backgroundImage: string
      textColor: string
    }[]
    H2: {
      text: string
      color: string
    }
  }
}

export const OrganizationFeatureList = ({ handleChangeRef, props }: IProps) => {
  const theme = useTheme()
  const ref = useRef<any>(null)

  useEffect(() => {
    handleChangeRef(ref, props.index)
  }, [ref])

  return (
    <Styled.FeatureListMainDiv ref={ref}>
      {!!props.H2 && (
        <Typography variant={"h2"} sx={{ color: props.H2.color || "#000" }}>
          {props.H2.text || ""}
        </Typography>
      )}
      {!!props.items &&
        props.items.map(e => (
          <FeatureItemMainDiv
            sx={{
              flexDirection: e.direction === "reverse" ? "row-reverse" : "row",
              [theme.breakpoints.down("md")]: { flexDirection: "column" },
            }}
          >
            <Styled.ContentItem>
              <img src={e.icon} />
              <Typography variant={"h3"} sx={{ color: e.textColor || "#000", marginTop: theme.spacing(6) }}>
                {e.title}
              </Typography>
              <Typography
                variant={"body1"}
                fontWeight={"medium"}
                fontSize={"medium"}
                sx={{ color: e.textColor || "#000", marginTop: theme.spacing(4) }}
              >
                {e.text}
              </Typography>
            </Styled.ContentItem>
            <Styled.ImageItem src={e.image || ""} sx={{ background: e.backgroundImage || "#FFF" }} />
          </FeatureItemMainDiv>
        ))}
    </Styled.FeatureListMainDiv>
  )
}
