import { Dropdown, TextField } from "shared/ui"
import { FlexRow, FormWrapper } from "./Course.style"
import {
  statusEnum,
  progressEnum,
  formCategories,
  formStages,
  activityEnum,
  ruleEnum,
} from "entities/Course/lib/CourseStatic.js"

export const CourseFilters = ({ fieldValue, handleFieldValue, categories, stages, modules, disable }) => {
  return (
    <>
      <FormWrapper>
        <TextField
          id="statistics__field-value-name"
          variant="outlined"
          name={"participant__user__last_name__icontains"}
          value={fieldValue.participant__user__last_name__icontains}
          onChange={handleFieldValue}
          disabled={disable}
          label="Фамилия"
        />
        <TextField
          id="statistics__field-value-email"
          variant="outlined"
          name={"participant__user__email__icontains"}
          value={fieldValue.participant__user__email__icontains}
          onChange={handleFieldValue}
          disabled={disable}
          label="Почта"
        />
        <TextField
          id="statistics__field-value-phone"
          variant="outlined"
          name={"participant__user__phone"}
          value={fieldValue.participant__user__phone}
          onChange={handleFieldValue}
          disabled={disable}
          label="Номер телефона"
        />
        <Dropdown
          id={"statistics__field-value-completed"}
          name={"completed"}
          value={fieldValue.completed}
          onChange={handleFieldValue}
          disabled={disable}
          options={progressEnum}
          label={"Статус прохождения"}
        />
        <Dropdown
          id={"statistics__field-value-status"}
          name={"participant__status"}
          value={fieldValue.participant__status}
          onChange={handleFieldValue}
          disabled={disable}
          options={statusEnum}
          label={"Статус"}
        />
        <Dropdown
          id={"statistics__field-value-category"}
          name={"participant__category"}
          value={fieldValue.participant__category}
          onChange={handleFieldValue}
          disabled={disable}
          options={formCategories(categories)}
          label={"Категория"}
          isTranslate={false}
        />
        <Dropdown
          id={"statistics__field-value-stage"}
          name={"stage"}
          value={fieldValue.stage}
          onChange={handleFieldValue}
          disabled={disable}
          options={formStages(stages)}
          label={"Этап с огр. доступом"}
        />
        <Dropdown
          id={"statistics__field-value-is-active"}
          name={"is_active"}
          value={fieldValue.is_active}
          onChange={handleFieldValue}
          disabled={disable}
          options={activityEnum}
          label={"Статус активности"}
        />
      </FormWrapper>
      <FlexRow>
        <Dropdown
          id={"statistics__field-value-module"}
          name={"module"}
          value={fieldValue.module}
          onChange={handleFieldValue}
          disabled={disable}
          options={modules}
          label={"Критерий"}
          width={224}
        />
        <Dropdown
          id={"statistics__field-value-rule"}
          name={"rule"}
          value={fieldValue.rule}
          onChange={handleFieldValue}
          disabled={disable || !fieldValue.module}
          options={ruleEnum}
          label={"Правило"}
          width={115}
        />
        <TextField
          id="statistics__field-value-mark_value"
          variant="outlined"
          name={"mark_value"}
          value={fieldValue.mark_value}
          onChange={handleFieldValue}
          label="Значение"
          disabled={disable || !fieldValue.module || !fieldValue.rule}
          width={115}
        />
      </FlexRow>
    </>
  )
}
