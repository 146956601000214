// @ts-ignore
import Plot from "react-plotly.js"
import styled from "@emotion/styled"
import useSunburstChart from "entities/Organization/lib/useSunburstChart"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import dayjs from "dayjs"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"

interface IProps {
  type: StatisticsVariants
  infoType: string
}

const ChartSunburst = ({ type, infoType }: IProps) => {
  const { data, layout } = useSunburstChart(type, infoType)
  const today = dayjs().format("DD.MM.YYYY")
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)

  return (
    <ChartsWrapper>
      <ChartElement>
        <PlotWrapper>
          <Plot data={data} layout={layout} style={{ width: "100%", height: "100%" }} config={{
            displaylogo: false,
            toImageButtonOptions: {
                      filename:`${today}_Активность ученика_${currentCourse?.id || ""}_${organization?.title}_Успеваемость`,
                      format: "png",
                      width: 1920,
                      height: 1080,
                    },
          }} />
        </PlotWrapper>
        <Label>Успеваемость ученика на мероприятиях платформы</Label>
      </ChartElement>
    </ChartsWrapper>
  )
}

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 24px;
  padding-bottom: 24px;
  flex: 60%;
`

const ChartElement = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-block: auto;
`

const PlotWrapper = styled.div`
  & path {
    opacity: 1 !important;
  }
`

const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  color: #313439;
`

export { ChartSunburst }
