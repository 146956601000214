import * as Styled from "./TimerStartModal.styles"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { useEffect, useState } from "react"
import { Button } from "shared/ui"
import { parseDeadline, parseTime } from "entities/Test/lib/timer"
import { useBackModalRedirection } from "entities/Test/model/backCourseRedirection"
import { getPredeadlineDate } from "entities/Assignment/api/assignment"

interface IProps {
  isOpen: boolean
  courseSlug: string
  onClose: () => void
  onStart: () => void
  attempts: { value: number; timer: string }
  blockId: number
}

const TimerStartModal = ({ isOpen, onClose, onStart, courseSlug, attempts, blockId }: IProps) => {
  const [isAgreed, setIsAgreed] = useState(false)
  const [predeadlineDate, setPredeadlineDate] = useState("")

  useEffect(() => {
    isOpen && getDate()
    setIsAgreed(false)
  }, [isOpen])

  const getDate = async () => {
    const response = await getPredeadlineDate(blockId)
    const parsedDate = parseDeadline(response.predeadline) || ""
    setPredeadlineDate(parsedDate)
  }

  const toggleAgreed = () => setIsAgreed(prev => !prev)

  return (
    <ModalTimer isOpen={isOpen} onClose={onClose}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>Прохождение теста ограничено по времени и количеству попыток.</Styled.Typography>
          <br />
          <Styled.Typography>Вы можете начать выполнение теста не позднее {predeadlineDate} (Мск).</Styled.Typography>
          <br />
          <Styled.Typography>
            Чтобы завершить выполнение, отправьте результаты с помощью кнопки «Отправить».{" "}
            <Styled.Strong>Результаты не сохранятся</Styled.Strong>, если вы не отправите результат при выходе из теста.
          </Styled.Typography>
          <br />
          <Styled.Typography>
            Если в процессе выполнения у вас возникли <Styled.Strong>технические неполадки</Styled.Strong>, вы можете
            продолжить выполнение теста с другой вкладки или устройства в рамках отведённого времени.
          </Styled.Typography>
          <br />
          <Styled.Typography>
            Оставшееся время до завершения попытки отображено на таймере. <Styled.Strong>Таймер</Styled.Strong> на
            период технических неполадок не останавливается, время не добавляется.
          </Styled.Typography>
          <LeftTime attempts={attempts} />
          <Agreement isAgreed={isAgreed} toggleAgreed={toggleAgreed} />
        </Styled.Wrapper>
        <Buttons isAgreed={isAgreed} courseSlug={courseSlug} onStart={onStart} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ isAgreed, courseSlug, onStart }: { isAgreed: boolean; courseSlug: string; onStart: () => void }) => {
  const { redirect } = useBackModalRedirection(courseSlug)

  const onEscape = () => {
    redirect()
  }

  return (
    <Styled.ButtonWrapper>
      {/* @ts-ignore */}
      <Button onClick={onStart} variant="contained" width={154} disabled={!isAgreed}>
        Начать
      </Button>
      {/* @ts-ignore */}
      <Button onClick={onEscape} variant="outlined" width={154}>
        Выйти
      </Button>
    </Styled.ButtonWrapper>
  )
}

const LeftTime = ({ attempts }: { attempts: { value: number; timer: string } }) => {
  return (
    <Styled.LeftTimeWrapper>
      <Styled.LeftTimeTitle>Время выполнения:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>{parseTime(attempts.timer)}</Styled.LeftTimeText>
      <Styled.LeftTimeTitle>Попытка:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>
        {attempts.value} из {attempts.value}
      </Styled.LeftTimeText>
    </Styled.LeftTimeWrapper>
  )
}

const Agreement = ({ isAgreed, toggleAgreed }: { isAgreed: boolean; toggleAgreed: () => void }) => {
  return (
    <Styled.AgreementWrapper>
      <Styled.AgreementButton isCheck={isAgreed} onClick={toggleAgreed} />
      <Styled.AgreementText>Я прочитал информацию и готов к выполнению теста</Styled.AgreementText>
    </Styled.AgreementWrapper>
  )
}

export { TimerStartModal }
