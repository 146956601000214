import React, { useState } from "react"
import * as Styled from "./QuestionnaireEditor.styles"
import { infoType } from "../models/Questionnaire.types"
import { Checkbox, FormControlLabel, IconButton, Switch, Typography } from "@mui/material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import DeleteIcon from "@mui/icons-material/Delete"
import { Autocomplete, Button, Modal, TextField } from "../../../shared/ui"
import { questionTypes } from "../static/static"
import AddIcon from "@mui/icons-material/Add"
import { useTheme } from "../../../shared/context"

interface IProps {
  provided: {
    innerRef: any
    draggableProps: any
    dragHandleProps: any
  }
  editVariants: (question_index: number, variant_index: number, value: string) => void
  question: infoType
  editQuestion: (field: keyof infoType, newValue: string | boolean | number | string[], index: number) => void
  removeField: (question: infoType) => void
  deleteVariant: (question_index: number, variant_index: number) => void
}

export const QuestionItem = ({
  provided,
  question,
  editQuestion,
  removeField,
  editVariants,
  deleteVariant,
}: IProps) => {
  const [openCard, setOpenCard] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()

  function handleClickCancel() {
    setOpenModal(false)
  }

  function pushNewAnswer() {
    editQuestion(
      "props",
      // @ts-ignore
      [...(question.props?.options || []), `Ответ${question?.props?.options?.length + 1 || 1}`],
      question.index - 1
    )
  }

  function handleClickDelete() {
    removeField(question)
    setOpenModal(false)
  }

  return (
    <Styled.QuestionItem ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
      <Styled.QuestionHeader>
        <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
          {question.title}
        </Typography>
        <Styled.QuestionHeaderItem>
          {openCard && (
            <Button
              variant={"outlined"}
              //@ts-ignore
              color={"accent"}
              onClick={() => {
                setOpenModal(true)
              }}
            >
              <DeleteIcon sx={{ fontSize: "32px", marginRight: "8px" }} />
              Удалить вопрос
            </Button>
          )}
          <IconButton size="small" onClick={() => setOpenCard(!openCard)}>
            {openCard ? (
              <KeyboardArrowUpIcon sx={{ color: "#000", fontSize: 32 }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "#000", fontSize: 32 }} />
            )}
          </IconButton>
        </Styled.QuestionHeaderItem>
      </Styled.QuestionHeader>
      {openCard && (
        <Styled.QuestionContent>
          <Typography variant={"h4"}>Параметры вопроса</Typography>
          <TextField
            style={{ width: "540px", marginTop: theme.spacing(5) }}
            variant={"outlined"}
            label={"Вопрос"}
            value={question.title}
            onChange={e => editQuestion("title", e.target.value, question.index - 1)}
          />
          <Autocomplete
            onChange={(e, newValue) => !!newValue && editQuestion("type", newValue["value"], question.index - 1)}
            options={questionTypes}
            label={"Тип вопроса"}
            style={{ width: "540px", marginBottom: theme.spacing(2), marginTop: theme.spacing(5) }}
            value={questionTypes.filter(e => e.value === question.type)[0]?.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={question.visible}
                onChange={e => editQuestion("visible", e.target.checked, question.index - 1)}
              />
            }
            label="Отображать вопрос"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={question.is_required}
                onChange={e => editQuestion("is_required", e.target.checked, question.index - 1)}
              />
            }
            label="Обязательный вопрос"
          />

          {question.type !== "TextField" && question.type !== "Geo_Subject" && question.type !== "Geo_City" && (
            <>
              <Typography variant={"h4"} sx={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(8) }}>
                Варианты ответа
              </Typography>
              {!!question.props &&
                !!question.props.options &&
                question.props.options.map((e, index) => (
                  <Styled.VariantContainer>
                    <TextField
                      label={`Ответ ${index + 1}`}
                      value={e}
                      size={"small"}
                      style={{ width: "540px", marginTop: theme.spacing(5) }}
                      onChange={e => editVariants(question.index - 1, index, e.target.value)}
                      variant={"outlined"}
                    />
                    <Button
                      variant={"outlined"}
                      //@ts-ignore
                      color={"accent"}
                      size={"small"}
                      sx={{ marginTop: theme.spacing(5), width: "32px", height: "32px", padding: 0 }}
                      onClick={() => {
                        deleteVariant(question.index - 1, index)
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: "24px" }} />
                    </Button>
                  </Styled.VariantContainer>
                ))}
              <Button onClick={pushNewAnswer} variant={"outlined"} color={"primary"}>
                <AddIcon sx={{ height: "100%", padding: 0 }} />
                Добавить вариант ответа
              </Button>
            </>
          )}
        </Styled.QuestionContent>
      )}
      <Modal size={"small"} title={"Удаление вопроса"} isOpen={openModal} onClose={handleClickCancel}>
        <Styled.ContentWrapper>
          <Typography>Вы действительно хотите удалить вопрос?</Typography>
          <Styled.ButtonsContainer>
            <Button onClick={handleClickCancel}>Отменить</Button>
            {/*@ts-ignore*/}
            <Button onClick={handleClickDelete} color={"accent"} variant={"contained"}>
              Подтвердить
            </Button>
          </Styled.ButtonsContainer>
        </Styled.ContentWrapper>
      </Modal>
    </Styled.QuestionItem>
  )
}
