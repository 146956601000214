import * as Mui from "@mui/material"
import styled from "@emotion/styled"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import { ThemeType } from "shared/themes/default.types.js"
import { keyframes } from "@emotion/react"

const success = keyframes`
  from {
    background-color: white;
  }
  50% {
    background-color: #E6FCDE80;
  }
  to {
    background-color: white;
  }
`

export const TableRow = styled(Mui.TableRow)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }
  & td {
    padding-block: 0px;
    overflow: hidden;
  }
  height: 120px;
  transition: height 0.3s ease-out;

  &.success {
    animation: ${success} 1s linear;
    & td {
      border-bottom: 1px solid #228b45;
    }
  }
  &.collapse {
    height: 0;
    & > td > div {
      height: 0;
    }
  }
  &.removed {
    & > td {
      border-top: none;
      border-bottom: none;
    }
  }
`

export const FileLink = styled.a`
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  color: #313439;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

export const IconButton = styled.div`
  width: 40px;
  height: 40px;
  background-color: #1565c0;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background-color: #145197;
  }
`

export const ScoreWrapper = styled.div`
  height: 40px;
  padding-top: 8px;
`

export const Message = styled(Mui.Typography)<{ isSuccess: boolean }>`
  position: absolute;
  left: 50%;
  translate: -50% 0;
  color: ${props => (props.isSuccess ? "#228B45" : "#D82E2E")};
`

export const NotificationIcon = styled(AnnouncementIcon)(({ theme }: { theme?: ThemeType }) => ({
  color: theme?.palette.primary.main,
}))
