import React, { useEffect, useState } from "react"
import { Avatar, AvatarGroup, Chip, Grid, Typography } from "@mui/material"
import * as Styled from "./Webinar.styles"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { FormatOfWebinar } from "./FormatOfWebinar"
import { LocationChip } from "./LocationChip"
import { compareDate, stringifyDates } from "../util/util"
import { WebinarRequestedMessage } from "./WebinarRequestedMessage"
import { WebinarParticipantMessage } from "./WebinarParticipantMessage"
import { WebinarType } from "../models/Webinar.types"
import { getModules } from "../../Module/api/module"
import { ModuleType } from "../../Module/api/module.types"
import { axiosInstance } from "../../../shared/api/axiosInstance"

export const WebinarCard = ({ webinar }: { webinar: WebinarType }) => {
  const [onHover, setOnHover] = useState(false)
  const [module, setModule] = useState<ModuleType>()
  const [organization, setOrganization] = useState<null | { website: string; icon_logo: string }>(null)

  useEffect(() => {
    axiosInstance
      .get(`/organizations/${webinar.owner}/`)
      .then((res: { data: { website: string; icon_logo: string } }) => {
        setOrganization(res.data)
      })
    if (!!webinar.participant && !!webinar.stages[0]) {
      getModules(webinar.id)
        .then(res => {
          if (res.length === 0) {
          } else {
            setModule({ ...res[0] })
          }
        })
        .catch(err => {})
    }
  }, [webinar])

  function goToEvent() {
    if (webinar?.participant?.status === "requested") return
    else window.location.href = `/event/${webinar.slug}`
  }

  const handleMouseEnter = () => {
    if (webinar?.participant?.status === "requested") return
    setOnHover(true)
  }

  const handleMouseLeave = () => {
    if (webinar?.participant?.status === "requested") return
    setOnHover(false)
  }

  return (
    <Grid item xs={12} md={6} lg={!!webinar.participant ? 6 : 4}>
      <Styled.WebinarCard
        sx={{
          pointerEvents: webinar.visibility === "visible" ? "initial" : "none",
          opacity: webinar.visibility === "visible" ? 1 : 0.5,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "180px",
            paddingTop: (webinar?.participant && webinar?.participant?.status !== "requested" && "20px") || 0,
            paddingLeft: (webinar?.participant && webinar?.participant?.status !== "requested" && "20px") || 0,
            paddingRight: (webinar?.participant && webinar?.participant?.status !== "requested" && "20px") || 0,
          }}
        >
          <Styled.ImageOfWebinar
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={goToEvent}
            //@ts-ignore
            sx={{
              cursor: webinar?.participant?.status === "requested" ? "" : "pointer",
              opacity: webinar?.participant?.status === "requested" && 0.5,
              backgroundImage: `url(${webinar.image})`,
              backgroundSize: onHover ? "110%" : "100%",
              borderRadius: webinar?.participant?.status && webinar?.participant?.status !== "requested" ? 3 : 0,
            }}
          >
            <FormatOfWebinar type={webinar.badge} />
            {!!webinar.tags_info &&
              webinar.tags_info.map(e => (
                <Chip
                  sx={{ backgroundColor: "#FFF" }}
                  label={
                    <Typography variant={"caption"} fontWeight={"medium"}>
                      {e.value}
                    </Typography>
                  }
                />
              ))}
            {!!webinar.template_props.location_badge && (
              <LocationChip location={webinar.template_props.location_badge} />
            )}
            {!!webinar.template_props.text_badge && (
              <Chip
                sx={{ backgroundColor: "#FFF" }}
                label={
                  <Typography variant={"caption"} fontWeight={"medium"}>
                    {webinar.template_props.text_badge}
                  </Typography>
                }
              />
            )}
          </Styled.ImageOfWebinar>
        </div>
        <Styled.CardContent>
          <Typography
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={goToEvent}
            variant={"h3"}
            sx={{
              color: onHover ? "#1565C0" : "",
              cursor: webinar?.participant?.status === "requested" ? "" : "pointer",
              width: "100%",
            }}
          >
            {webinar?.title}
          </Typography>
          <Styled.DescriptionAndMessageDiv sx={{ height: !!webinar.participant ? 250 : "auto" }}>
            {(!webinar.participant || webinar.participant.status !== "requested") && (
              <Typography
                sx={{ alignText: "flex-start", width: "100%" }}
                variant={"body1"}
                fontWeight={"medium"}
                fontSize={"small"}
              >
                {webinar?.subtitle}
              </Typography>
            )}
            {webinar?.participant?.status !== "requested" && !!webinar.participant && (
              <WebinarParticipantMessage
                slug={webinar.slug}
                completed={webinar.participant.is_completed}
                hasCertificate={webinar.participant.has_certificate}
                badge={webinar.badge}
                isActive={compareDate(
                  !!module?.finish
                    ? `${module?.finish}T${module?.end_time || "00:00"}`
                    : `${module?.start}T${module?.time + ":00" || "00:00:00"}Z`
                )}
                link_on_offline={
                  !!module
                    ? {
                        link: module.link_on_location,
                        text: module.location_description,
                      }
                    : null
                }
                link_on_online={
                  !!module
                    ? {
                        link: module.link_on_webinar,
                        recordingLink: module.recording_link,
                      }
                    : null
                }
              />
            )}
            {webinar?.participant?.status === "requested" && <WebinarRequestedMessage />}
          </Styled.DescriptionAndMessageDiv>
        </Styled.CardContent>
        <Styled.FooterWrapper>
          <Styled.CardFooter>
            <Styled.FooterItem>
              <AccountCircleRoundedIcon sx={{ color: "#858585" }} />
              <Styled.FooterTypography variant={"caption"}>{webinar.participants_num}</Styled.FooterTypography>
            </Styled.FooterItem>
            {webinar?.start?.length > 0 && (
              <>
                <Styled.FooterItem>
                  <CalendarMonthIcon sx={{ color: "#858585" }} />
                  <Styled.FooterTypography variant={"caption"}>
                    {stringifyDates(
                      "date",
                      !webinar.participant || webinar.participant.status === "requested"
                        ? webinar.start
                        : `${module?.start}T${module?.time + ":00" || "00:00:00"}Z`
                    )}
                  </Styled.FooterTypography>
                </Styled.FooterItem>
                <Styled.FooterItem>
                  <AccessTimeIcon sx={{ color: "#858585" }} />
                  <Styled.FooterTypography variant={"caption"}>
                    {stringifyDates(
                      "time",
                      !webinar.participant || webinar.participant.status === "requested"
                        ? webinar.start
                        : `${module?.start}T${module?.time || "00:00"}Z`
                    )}
                  </Styled.FooterTypography>
                </Styled.FooterItem>
              </>
            )}
            {!!organization && (
              <AvatarGroup max={3} sx={{ marginLeft: "auto" }}>
                <Styled.CompanyAvatar
                  src={organization.icon_logo}
                  onClick={() => window.open(organization.website, "_blank")}
                />
              </AvatarGroup>
            )}
          </Styled.CardFooter>
        </Styled.FooterWrapper>
      </Styled.WebinarCard>
    </Grid>
  )
}
