import React from "react"
import ReactDOM from "react-dom/client"
import App from "app"
import * as serviceWorker from "./app/serviceWorker"
import { UserProvider } from "shared/context"
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://dbffba6572fa2ca52423833ec4948216@sentry.syncwoia.com/6",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["syncwoia.com", /^https:\/\/syncwoia.com\.com\/api/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.25,
  replaysOnErrorSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <UserProvider>
    <App />
  </UserProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
