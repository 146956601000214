import React, {useState} from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import { ReviewsEditor, ReviewsNotForUsers, TableReviews } from 'widgets';

export const ReviewsPannel = () => {

    const [type, setType] = useState("Users")

    function changeType(e){
        setType(e)
    }

    return (
        <div style={{width:"100%", marginBottom: "64px"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <FormControl>
                    <FormLabel>Тип отзывов</FormLabel>
                    <RadioGroup
                        row
                        defaultValue={type}
                        onClick={e => changeType(e.target.value)}
                    >
                        <FormControlLabel value={"Users"} control={<Radio/>} label="Отзывы пользователей платформы"/>
                        <FormControlLabel value={"Not users"} control={<Radio/>}
                                          label="Отзывы сторонних пользователей"/>
                        <FormControlLabel value={"User survey"} control={<Radio/>}
                                          label="Опрос пользователей"/>
                    </RadioGroup>
                </FormControl>
            </div>
            {type === "Users" ? (
                <div style={{width:"100%"}}>
                    <ReviewsEditor />
                </div>
            ): type === "Not users" ? (
                <div style={{width:"100%"}}>
                    <ReviewsNotForUsers/>
                </div>
            ): type === "User survey" ? (
                <TableReviews/>
            ) : (
                <div></div>
            )

            }
        </div>
    );
};
