import { IModuleProgress } from "entities/Module/model/Module.types"
import { ITeam } from "../../Team/model/Team.types"
import { CategoriesType, MembersRowType } from "./teamStatistic.types"
import { formCategoriesRow, formMembersRow } from "./teamStatistic"

type IStatisticRow = Array<number | string>

interface TeamProgressType extends ITeam {
  membersRow: MembersRowType
}

function formTeamStatistic(
  teamProgress: TeamProgressType[],
  isShowMembers: boolean,
  categories: CategoriesType,
  headers: { title: string; id: number }[]
) {
  const categoriesRow = formCategoriesRow(categories)
  const formedStatistics = teamProgress.map(team => ({
    id: team.id,
    title: team.title,
    full_name: team.full_name,
    email: team.email,
  }))
  let statistics = formedStatistics.map(s => Object.entries(s).map(e => e[1])) as IStatisticRow[]
  statistics.unshift(["ID команды", "Название команды", "ФИО Главного Администратора", "Почта Главного Администратора"])
  if (isShowMembers) {
    statistics[0].push(...categoriesRow)
    statistics = addMembers(statistics, teamProgress, categoriesRow)
  }
  statistics[0].push("Баллы", ...headers.map(header => header.title))
  statistics = addScore(statistics, teamProgress)
  statistics = addModules(statistics, teamProgress, headers)

  return statistics
}

const addMembers = (statistics: IStatisticRow[], teamProgress: TeamProgressType[], categories: string[]) => {
  let result = statistics
  teamProgress.forEach((team, index) => {
    const membersRow = formMembersRow(team.membersRow)
    membersRow?.forEach(memberItem => {
      let member = ""
      member += `${memberItem.name ?? ""} - ${memberItem.email ?? ""}\n `
      result[index + 1].push(member || " - ")
    })
  })
  return result
}

const addScore = (statistics: IStatisticRow[], teamsProgress: TeamProgressType[]) => {
  let result = statistics.map((team, index) => {
    if (index === 0) return team
    team.push(teamsProgress[index - 1]?.score || 0)
    return team
  })
  return result
}

const addModules = (
  statistics: IStatisticRow[],
  teamProgress: TeamProgressType[],
  headers: { title: string; id: number }[]
) => {
  let result = statistics
  teamProgress.forEach((team, index) => {
    headers.forEach(header => {
      const module: IModuleProgress | undefined = teamProgress[index].modules?.find(
        module => module.module_info.id === header.id
      )
      result[index + 1].push(module?.score || 0)
    })
  })

  return result
}

export default formTeamStatistic
