import React, { useState } from "react"
import { ErrorMapType, SubsectionType } from "../api/module.types"
import * as Styled from "./Module.styles"
import { Checkbox, FormControl, FormControlLabel, IconButton, Typography } from "@mui/material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Button, TextField } from "../../../shared/ui"
import DeleteIcon from "@mui/icons-material/Delete"

export const SubsectionItem = ({
  subsection,
  provided,
  handleDeleteSubsection,
  handleChangeSubsection,
  index,
  errors,
  section_index,
  disableDelete,
}: {
  disableDelete: boolean
  errors: ErrorMapType
  subsection: SubsectionType
  section_index: number
  index: number
  handleDeleteSubsection: (subsectionType: SubsectionType) => void
  handleChangeSubsection: (index: number, field: keyof SubsectionType, value: string | boolean) => void
  provided: {
    innerRef: any
    draggableProps: any
    dragHandleProps: any
  }
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Styled.CardTrajectoryContentItem
      sx={{ backgroundColor: open ? "#F7F9FE" : "#F9F9F9", zIndex: 1000 }}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      <Styled.TrajectoryContentItem>
        <Styled.ContentItemHeader onClick={() => setOpen(!open)}>
          <Typography variant={"h4"}>{`${subsection.title}`}</Typography>
          <Styled.ButtonsContentItemWrapper>
            {open && (
              //@ts-ignore
              <Button
                disabled={disableDelete}
                color={"accent"}
                variant={"outlined"}
                onClick={() => handleDeleteSubsection(subsection)}
              >
                <DeleteIcon sx={{ fontSize: "32px", marginRight: "8px" }} />
                Удалить подраздел
              </Button>
            )}
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <KeyboardArrowUpIcon sx={{ color: "#000", fontSize: 32 }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#000", fontSize: 32 }} />
              )}
            </IconButton>
          </Styled.ButtonsContentItemWrapper>
        </Styled.ContentItemHeader>
        {open && (
          <>
            <TextField
              onChange={e => handleChangeSubsection(index, "title", e.target.value)}
              value={subsection.title}
              style={{ marginTop: "24px", width: "100%" }}
              error={!!errors && !!errors[`sections[${section_index}].subsections[${index}].title`]}
              helperText={!!errors ? errors[`sections[${section_index}].subsections[${index}].title`] || "" : ""}
              label={"Название подраздела"}
              variant={"outlined"}
            />
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subsection.is_active}
                    onChange={e => handleChangeSubsection(index, "is_active", e.target.checked)}
                  />
                }
                label={"Подраздел активен"}
              />
            </FormControl>
          </>
        )}
      </Styled.TrajectoryContentItem>
    </Styled.CardTrajectoryContentItem>
  )
}
