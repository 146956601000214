import React, { useEffect, useState } from "react"
import * as Styled from "entities/Team/ui/Team.style"
import { Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { TeamTable } from "entities/Team/ui/TeamTable.js"
import { ManageTeamModal, InviteUserModal, DeleteTeamModal, LeaveTeamModal, EditRolesModal } from "features"
import { useSelector } from "react-redux"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import TeamActions from "entities/Team/ui/TeamActions.js"
import formCreationDate from "entities/Team/lib/formCreationDate"

export const TeamCard = ({ team }) => {
  const [revealed, setRevealed] = useState(false)
  const [open, setOpen] = useState(false)
  const [teamScore, setTeamScore] = useState(0.0)
  const [progress, setProgress] = useState([])
  const [progressMethod, setProgressMethod] = useState(null)
  const [modules, setModules] = useState([])
  const [creationDate, setCreationDate] = useState(formCreationDate())
  const user = useSelector(state => state.user.user)
  const theme = useTheme()

  useEffect(() => {
    if (!team.members) return
    initialTeamRequest()
    setCreationDate(formCreationDate(team.creation_date))
  }, [team])

  async function initialTeamRequest() {
    const response = await axios.get("content/actions/team_table/", { params: { team: team.id } }).then(res => res.data)
    updateTeamScore(response)
    getProgress(response)
  }

  async function updateTeamScore(response) {
    if (response.progress_method === "None") setProgressMethod("None")
    else setProgressMethod("max_personal_module")
    setTeamScore(response.team_progress.value)
  }

  async function getProgress(response) {
    setProgress(response.statistics)
    const uniqueModules = getModules(response.statistics)
    setModules(uniqueModules)
  }

  function getModules(members) {
    let result = new Set()
    let modules = []
    members.forEach(member => {
      member.individual_module_progress.forEach(module => {
        modules.push({
          title: module.module_info.title,
          index: module.module_info.index,
          stage: module.module_info.stage,
        })
      })
    })
    const sortedModules = modules.toSorted((a, b) => a.index - b.index).toSorted((a, b) => a.stage - b.stage)
    sortedModules.forEach(module => result.add(module.title))
    return Array.from(result)
  }

  const isTeamModerator =
    team.members &&
    team.members.find(
      member =>
        member.participant.user === user.id && (member.status === "moderator" || member.status === "chief_moderator")
    )

  const title = team.title + (!team.is_moderated ? " (Команда проходит модерацию)" : "")
  const moderatedText = `
Вы отправили заявку на создание команды ${creationDate}. У вас ещё нет возможности управлять этой командой, так как она находится на этапе модерации. Пожалуйста, дождитесь, когда модератор рассмотрит вашу заявку.`
  const onClose = () => setOpen(false)

  return (
    <>
      <EditRolesModal isOpen={open === "edit_roles"} onClose={onClose} team={team} />
      <InviteUserModal isOpen={open === "invite_user"} onClose={onClose} team={team} />
      <ManageTeamModal isOpen={open === "edit_team"} onClose={onClose} team={team} />
      <DeleteTeamModal isOpen={open === "delete_team"} onClose={onClose} team={team} />
      <LeaveTeamModal isOpen={open === "leave_team"} onClose={onClose} team={team} />

      <Styled.TeamSection isDisabled={!team.is_moderated}>
        <Styled.TeamHeader onClick={() => setRevealed(prev => !prev)} style={{ paddingBottom: revealed && 16 }}>
          <Typography variant={"h4"}>{title}</Typography>
          {!revealed && <Styled.DropDownIcon />}
          {revealed && <Styled.DropUpIcon />}
        </Styled.TeamHeader>
        {revealed && (
          <Styled.TeamContent>
            {team.is_moderated ? (
              <>
                <Typography
                  sx={{ marginTop: theme.spacing(4) }}
                  variant={"caption"}
                  fontSize={"small"}
                  fontWeight={"small"}
                >
                  {`ID команды: ${team.id}`}
                </Typography>
                <Styled.FlexWrapper>
                  <div>
                    <Styled.Description variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
                      {team.description}
                    </Styled.Description>
                    <Styled.TeamScoreBox display="flex" alignItems="center">
                      {progressMethod !== "None" && (
                        <>
                          <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
                            {"Общий балл команды: "}
                          </Typography>
                          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
                            {teamScore}
                          </Typography>
                        </>
                      )}
                    </Styled.TeamScoreBox>
                  </div>
                  <TeamActions setAction={setOpen} isModerator={isTeamModerator} />
                </Styled.FlexWrapper>
                <TeamTable team={team} progress={progress} modules={modules} />
              </>
            ) : (
              <Typography variant={"caption"} fontSize={"small"} fontWeight={"small"}>
                {moderatedText}
              </Typography>
            )}
          </Styled.TeamContent>
        )}
      </Styled.TeamSection>
    </>
  )
}
