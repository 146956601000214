import React from "react"
import { Button as MuiButton, Tooltip } from "@mui/material"
import { UserIcon } from "entities/User"

interface IProps {
  profile: { status: string } | null
  toggleMenu: (event: React.MouseEvent<HTMLButtonElement>) => void
  anchorMenu: React.MouseEvent<HTMLButtonElement> | null
}

export const HeaderTooltip = ({ profile, toggleMenu, anchorMenu }: IProps) => {
  return (
    <Tooltip title="Меню">
      <MuiButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="large"
        style={{ right: "2%" }}
      >
        {!!profile ? (
          <UserIcon user={profile} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
        )}
      </MuiButton>
    </Tooltip>
  )
}
