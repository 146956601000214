const SaveAssignmentIcon = () => {
  return (
    <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.125" y="1.53253" width="15" height="16.5" rx="1.5" fill="#1565C0" />
      <path d="M3.83203 5.98462H11.417" stroke="white" stroke-width="2.25" stroke-linecap="round" />
      <path d="M3.83203 9.78247H11.417" stroke="white" stroke-width="2.25" stroke-linecap="round" />
      <path d="M3.83203 13.5806H7.62451" stroke="white" stroke-width="2.25" stroke-linecap="round" />
      <path
        d="M18.0117 2.28247C18.0117 1.86826 18.3475 1.53247 18.7617 1.53247H21.2367C21.6509 1.53247 21.9867 1.86826 21.9867 2.28247V4.23247H18.0117V2.28247Z"
        fill="#1565C0"
      />
      <path
        d="M18.0117 5.28711H21.9867V16.2846C21.9867 16.4467 21.9341 16.6046 21.8369 16.7343L20.5994 18.3857C20.2994 18.786 19.699 18.786 19.399 18.3857L18.1615 16.7343C18.0643 16.6046 18.0117 16.4467 18.0117 16.2846V5.28711Z"
        fill="#1565C0"
      />
    </svg>
  )
}

export default SaveAssignmentIcon
