import { ContentType, ModuleType } from "../api/module.types"

type type_field = "TextField" | "Number" | "Date" | "File" | "Checkbox" | "Time" | "SingleChoise"

export type BlockModuleConfig = {
  type_field: type_field
  label: string
  options?: {
    label: string
    value: string
  }[]
  visible: boolean
  field: keyof ModuleType
}
export type IModuleConfig = BlockModuleConfig[]

export function settingsModuleConfig(type: ContentType, courseType: string): IModuleConfig {
  return [
    {
      field: "title",
      type_field: "TextField",
      label: "Заголовок модуля",
      visible: true,
    },
    {
      type_field: "Number",
      label: "Индекс модуля (определяет последовательность модуля в этапе)",
      visible: courseType === "event",
      field: "index",
    },
    {
      type_field: "TextField",
      label: "Описание модуля (отображается на плитке модуля)",
      visible: courseType !== "webinar",
      field: "description",
    },
    {
      type_field: "File",
      label: "Загрузить обложку",
      visible: courseType !== "webinar",
      field: "image",
    },
    {
      type_field: "SingleChoise",
      label: "Тип доступа",
      options: [
        { label: "Глобальный", value: "global" },
        { label: "Командный", value: "team" },
        { label: "Персональный", value: "personal" },
      ],
      visible: courseType !== "webinar",
      field: "type",
    },
    {
      type_field: "SingleChoise",
      label: "Тип контента",
      options: [
        { label: "Контентный модуль", value: "content" },
        { label: "Оффлайн модуль", value: "offline" },
        { label: "Онлайн модуль", value: "online" },
        { label: "Онлайн/оффлайн модуль", value: "hybrid" },
      ],
      visible: courseType !== "webinar",
      field: "content_type",
    },
    {
      type_field: "Checkbox",
      label: "Модуль доступен",
      visible: type === "content",
      field: "is_active",
    },
    {
      type_field: "Checkbox",
      label: "Обязательный для прохождения",
      visible: type === "content",
      field: "is_required",
    },
    {
      type_field: "Date",
      label: "Дата открытия",
      visible: true,
      field: "start",
    },
    {
      type_field: "Date",
      label: "Дата закрытия (Новые пользователи не смогут присоединиться ко Встрече позже этого времени)",
      visible: true,
      field: "finish",
    },
    {
      type_field: "Time",
      label: "Время начала встречи (по UTC)",
      visible: type !== "content",
      field: "time",
    },
    {
      type_field: "Time",
      label:
        "Время окончания встречи (по UTC, новые пользователи не смогут присоединиться ко Встрече позже этого времени)",
      visible: type !== "content" && courseType === "webinar",
      field: "end_time",
    },
    {
      type_field: "TextField",
      label: "Ссылка на сторонний ресурс для регистрации",
      visible: type !== "content" && type !== "online" && courseType !== "webinar",
      field: "remote_resource_link",
    },
    {
      type_field: "TextField",
      label: "Описание места проведения",
      visible: type === "offline" || type === "hybrid",
      field: "location_description",
    },
    {
      type_field: "TextField",
      label: "Ссылка на карту",
      visible: type === "offline" || type === "hybrid",
      field: "link_on_location",
    },
    {
      type_field: "TextField",
      label: "Ссылка на трансляцию",
      visible: type === "online" || type === "hybrid",
      field: "link_on_webinar",
    },
    {
      type_field: "TextField",
      label: "Ссылка на запись",
      visible: (type === "online" || type === "hybrid") && courseType === "webinar",
      field: "recording_link",
    },
    {
      type_field: "Number",
      label: "Минимальный балл",
      visible: type === "content",
      field: "min_score",
    },
    {
      type_field: "Number",
      label: "Максимальный балл",
      visible: type === "content",
      field: "max_score",
    },
  ]
}

export const ModuleParamsMapper = {
  content_type: {
    content: "Контент",
    offline: "Оффлайн",
    online: "Онлайн",
    hybrid: "Оффлайн/онлайн",
  },
  type: {
    global: "Глобальный",
    team: "Командный",
    personal: "Персональный",
  },
  is_active: {
    true: "Доступен",
    false: "-",
  },
  is_required: {
    true: "Обязательный",
    false: "-",
  },
}
