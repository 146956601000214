import { ModuleType } from "entities/Module/api/module.types"
import { IModuleInfo, IModuleProgress } from "entities/Module/model/Module.types"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import { IExtendFilters } from "shared/hooks/useExtendFiltersForm"
import { formScoreOptions } from "../lib/formOptions"
import themes from "shared/themes"

type ModuleKeysType = keyof IModuleInfo

export function findModule(filters: IExtendFilters, storedModules: ModuleType[]): ModuleType | undefined {
  if (!filters.module) return
  return storedModules.find(module => {
    return module.id === filters?.module?.value
  })
}

export function getMinMaxScore(module?: ModuleType) {
  if (!module) return ""
  const result = `${module?.min_score}/${module?.max_score}`
  return result
}

export function getScoreColor(filters: IExtendFilters, progress: IParticipantProgress, module?: ModuleType) {
  if (filters.module) return pickColor(progress?.module_progress, module)
  // @ts-ignore
  return themes.default.palette.additional.main
}

export function getHelper(
  progress: IParticipantProgress,
  filters: IExtendFilters,
  score: number,
  currentModule?: ModuleType
) {
  const isProgress = progress.module_progress.find(module => module.module_info.id === filters?.module?.value)
  if (!filters.module) return ""
  if (!isProgress) return "Ученик ещё не проходил этот модуль."
  if (!currentModule) return
  if (currentModule?.min_score < score) {
    return "Ученик успешно прошёл модуль"
  } else if (score < currentModule?.max_score) {
    return "Чтобы успешно пройти модуль, ученик должен набрать минимальный балл не только за весь модуль, но и за каждый тест модуля."
  }
}

function pickColor(moduleProgress: IModuleProgress[], currentModule?: ModuleType) {
  const module = moduleProgress.find(module => module.module_info.id === currentModule?.id)
  if (!module) return `rgba(0, 0, 0, 0.4)`
  if (currentModule && module.score < currentModule?.min_score) return themes.default.palette.error.dark
  return themes.default.palette.success.dark
}

export function getUserScore(filters: IExtendFilters, progress: IParticipantProgress) {
  if (filters.module) return countScore(progress?.module_progress, "id", filters?.module?.value)
  if (filters.stage && filters?.stage?.label)
    return Number((progress.stage_scores[filters?.stage?.label] || 0).toFixed(2))
  return Number(progress?.score.toFixed(2))
}

function countScore(moduleProgress: IModuleProgress[], key: ModuleKeysType, id: number | string) {
  const module = moduleProgress.find((module: IModuleProgress) => module.module_info[key] === id)
  return Number((module?.score || 0).toFixed(2))
}

export function getScoreOptions(filters: IExtendFilters, currentModule: ModuleType | undefined) {
  if (!filters.module) return []
  const options = formScoreOptions(0, currentModule?.max_score)
  return options
}

export const getTitle = (filters: IExtendFilters) => {
  if (filters.module) return `модуль «${filters?.module.label}»`
  if (filters.stage)
    return filters?.stage.label?.toLowerCase().includes("этап")
      ? `${filters?.stage.label}`
      : `этап «${filters?.stage.label}»`
  return `курс «${filters?.course?.label}»`
}
