import { styled } from "@mui/material/styles"
import { TableCell, TableContainer } from "@mui/material"

export const ChooseCourseMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
}))

export const ChooseCourseDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: theme.spacing(6),
}))

export const StageBlockMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}))

export const ModulesTableContainer = styled(TableContainer)(({ theme }) => ({
  background: theme.palette.background.paper,
}))

export const ModulesMainTableContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
}))

export const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

export const StageParamsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(6),
  padding: `0 ${theme.spacing(6)}`,
}))

export const ParamsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  alignItems: "flex-start",
}))

export const SavePanel = styled("div")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  background: "#20242A",
  bottom: 0,
  zIndex: "1000",
  paddingRight: theme.spacing(60),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(4),
}))
