export const memberColors = {
  moderator: "#FFA000",
  member: "#3EBD44",
  null: "#ACACAC",
}

export const memberCategories = {
  chief_moderator: "Главный Администратор",
  moderator: "Администратор",
  member: "Участник",
  integrator: "Интегатор",
  producer: "Производитель",
  teacher: "Преподаватель",
  null: "Без роли",
}

export const uniqueStatuses = ["invited", "requested", "rejected"]
export const statusMapper = {
  invited: "Приглашён",
  requested: "Запросил доступ",
  rejected: "Отклонил Приглашение",
}

export const getMemberColor = (role: string) => {
  switch (role) {
    case "moderator":
    case "chief_moderator":
      return "moderator"
    case "invited":
    case "accepted":
    case "requested":
    case "null":
    case "role_none":
    case "":
    case null:
      return "null"
    default:
      return "member"
  }
}

export const getMemberLabel = (role: string) => {
  switch (role) {
    case "invited":
    case "accepted":
    case "requested":
    case "null":
    case "role_none":
    case "":
    case null:
      return "null"
    case "member":
      return "member"
    default:
      return role
  }
}
