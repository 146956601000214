import React, { useEffect, useState } from "react"
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { wordMapper } from "shared/local"
import { courseStatuses } from "entities/Team/model/teamWordMapper"
import {useAppSelector} from "../../shared/hooks/useAppSelector";

const ParticipantInfoEditor = props => {
  const { user, infoField } = props
  const [info, setInfo] = useState(null)
  const [comment, setComment] = useState(null)
  const [helperText, setHelperText] = useState("")
  const [courseStatus, setCourseStatus] = useState(null)
  const [newStatus, setNewStatus] = useState("")
  const course = useAppSelector(state => state.course.participantsAndRequestsCourse)
  const courseAdmin = useAppSelector(state => state.course.course)

  function updateInfo(value, field) {
    info[field] = value
    setInfo({ ...info })
  }

  useEffect(() => {
    let value = !!user.info ? Object.fromEntries(user.info.map(e => [e.id, e.value])) : []
    setInfo(value)
  }, [])

  function update() {
    let value = Object.entries(info).map(e => ({ id: Number(e[0]), answer: e[1], value: e[1] }))
    axios.get("/organizations/").then(() => {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

       if (courseAdmin?.id && (!course?.id || course?.id)) {
          axios.patch(`/content/participants/update_infosubmission/`, { course: courseAdmin?.id, info: value, participant: user.id })
        .then(res => {
          setHelperText("Обновлено")
          axios.patch(`/content/participants/${user.id}/set_comment/?course=${courseAdmin?.id}`, {
            new_comment: comment,
            course: courseAdmin?.id,
          })
        })
        .catch(err => {
          setHelperText("Ошибка")
          axios.patch(`/content/participants/${user.id}/set_comment/?course=${courseAdmin?.id}`, {
            new_comment: comment,
            course:  courseAdmin?.id,
          })
        })
      }

      if(course?.id && !courseAdmin?.id){
          axios.patch(`/content/participants/${user.id}/set_comment/?course=${course?.id}`, {
            new_comment: comment,
            course: course?.id,
          })
        .then(res => {
          setHelperText("Обновлено")
        })
        .catch(err => {
          setHelperText("Ошибка")
          axios.patch(`/content/participants/${user.id}/set_comment/?course=${course?.id}`, {
            new_comment: comment,
            course: course?.id,
          })
        })
      }

    })
  }

  function setUserStatus() {
    if (!user) return
    axios.get("/profiles/my_profile/").then(() => {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      if (courseAdmin?.id || course?.id){
        axios.put(`/content/participants/set_status/`, {
          user: user.user,
          course: course?.id || courseAdmin?.id,
          status: courseStatus,
        })
        .then(res => {
          setNewStatus(`Статус доступа изменён на ${courseStatus}`)
        })
        .catch(res => {
          setNewStatus("Ошибка")
        })
      }
    })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "600", justifyContent: "center" }}>
      <Typography variant="h3" style={{ textAlign: "left" }}>
        Редактирование заявки
      </Typography>
      {!!user && (
        <Typography variant="h5" style={{ textAlign: "left" }}>
          {user.full_name}
        </Typography>
      )}
      {info &&
        infoField &&
        infoField.map(e => (
          <div>
            <TextField
              id="courseId"
              variant="outlined"
              value={info[e.id] || ""}
              onChange={p => updateInfo(p.target.value, e.id)}
              helperText={e.title}
              fullWidth
              style={{ marginTop: 24 }}
            />
          </div>
        ))}
      <TextField
        id="courseId"
        variant="outlined"
        value={comment || ""}
        onChange={e => setComment(e.target.value)}
        helperText="Комментарий"
        fullWidth
        style={{ marginTop: 24 }}
      />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ width: 300, alignSelf: "center" }}
          onClick={update}
        >
          ОБНОВИТЬ ИНФОРМАЦИЮ
        </Button>
      </div>
      <Typography
        variant="h5"
        style={
          helperText === "Обновлено"
            ? { textAlign: "center", color: "green", paddingLeft: "20px" }
            : helperText === "Ошибка"
            ? { textAlign: "center", color: "red", paddingLeft: "20px" }
            : {}
        }
      >
        {helperText}
      </Typography>
      <div style={{ display: "flex", gap: 8, marginTop: 24, marginBottom: 64 }}>
        <FormControl sx={{ minWidth: 240, marginRight: 24 }}>
          <InputLabel>{"Статус"}</InputLabel>
          <Select
            id="participantStatus"
            variant={"outlined"}
            input={<OutlinedInput label={"Статус"} />}
            value={courseStatus}
            label="Status"
            onChange={e => setCourseStatus(e.target.value)}
          >
            {courseStatuses.map(e => (
              <MenuItem value={e}>{`${wordMapper(e)}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 260 }}
          onClick={setUserStatus}
          disabled={!courseStatus}
        >
          Задать статус
        </Button>
        <Typography
          variant="h5"
          style={
            newStatus === "Ошибка"
              ? {
                  textAlign: "center",
                  color: "red",
                  paddingLeft: "20px",
                }
              : { textAlign: "center", color: "green", paddingLeft: "20px" }
          }
        >
          {newStatus}
        </Typography>
      </div>
    </div>
  )
}

export default ParticipantInfoEditor
