import { styled } from "@mui/material/styles"
import { ShadowedSection } from "shared/ui"
import { Tab, TableContainer } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

export const IotMainContainer = styled("div")(({ theme }) => ({
  borderRadius: theme.spacing(3),
  border: "4px solid #DEE7F2",
  marginTop: theme.spacing(25),
  marginLeft: theme.spacing(31),
  marginRight: theme.spacing(31),
  marginBottom: theme.spacing(10),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
}))

export const HardwareContainer = styled(ShadowedSection)(({ theme }) => ({
  width: "40%",
  opacity: 0.5,
  [theme.breakpoints.down("lg")]: {
    width: "60%",
    marginBottom: 0,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    gap: theme.spacing(4),
  },
  "&:hover": {
    opacity: 1,
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
}))

export const PanelContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
}))

export const ArrowUp = styled(KeyboardArrowUpIcon)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  padding: 0,
  margin: 0,
  cursor: "pointer",
  "$:hover": {
    opacity: "0.5",
  },
  marginLeft: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(2),
  },
}))

export const ArrowDown = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  padding: 0,
  marginLeft: theme.spacing(6),
  cursor: "pointer",
  "$:hover": {
    opacity: "0.5",
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(2),
  },
}))

export const UsersButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(4),
}))

export const TableMainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}))

export const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  borderRadius: theme.spacing(3),
  maxHeight: "80%",
  width: "100%",
}))

export const UserStatusContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
}))

export const SettingsButtonIcon = styled(SettingsApplicationsIcon)(({ theme }) => ({
  color: "#98999C",
  justifySelf: "flex-end",
  cursor: "pointer",
  height: theme.spacing(10),
  width: theme.spacing(10),
  marginLeft: "auto",
  "&:hover": {
    color: "#145197",
  },
}))

export const EyeIcon = styled(RemoveRedEyeIcon)(({ theme }) => ({
  backgroundColor: "#98999C",
  borderRadius: theme.spacing(1),
  color: "#fff",
  justifySelf: "flex-end",
  width: theme.spacing(10),
  height: theme.spacing(10),
  cursor: "pointer",
  padding: theme.spacing(1),
  "&:hover": {
    backgroundColor: "#F0F5FF",
    color: "#000",
  },
}))

export const ControllerContainer = styled("div")(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: " #1565C0",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
}))

export const SettingsIcon = styled(SettingsApplicationsIcon)(({ theme }) => ({
  color: "#FFF",
  height: theme.spacing(10),
  width: theme.spacing(10),
}))

export const ChartMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export const SettingsOfChartDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(6),
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  boxShadow:
    "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0.00)",
}))

export const BottomContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  width: "100%",
}))

export const ProgressBarContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  backgroundColor: "#DEE7F2",
  borderRadius: theme.spacing(3),
  marginLeft: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}))

export const TopContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(6),
    gap: theme.spacing(4),
  },
}))

export const PlotStyle = styled("div")(({ theme }) => ({
  width: "100%",
  "& a.modebar-btn": {
    fontSize: "24px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
    },
  },
}))
