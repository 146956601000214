import { useEffect } from "react"

interface IProps {
  assignmentIndex: number
  preventClose: (x: () => void) => void
  onPrev: () => void
  onNext: () => void
  isPrev: boolean
  isNext: boolean
  hasNextPage: boolean
  isLoading: boolean
}

const useSliderButtons = ({
  assignmentIndex,
  preventClose,
  onPrev,
  onNext,
  isPrev,
  isNext,
  hasNextPage,
  isLoading,
}: IProps) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [assignmentIndex, preventClose])

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!event.target) return
    // @ts-ignore
    if (event.target.className.includes("MuiInputBase-input")) return
    if (event.key === "ArrowLeft") {
      if (isPrev && !isLoading) preventClose(onPrev)
    }
    if (event.key === "ArrowRight") {
      if (isNext || (hasNextPage && !isLoading)) preventClose(onNext)
    }
  }
}

export default useSliderButtons
