export const TeamWordMapper = {
  "": "Не выбрано",
  null: "Не выбрано",
  in_progress: "Участник",
  teacher: "Преподаватель",
  mentor: "Ментор",
  moderator: "Администратор",
  chief_moderator: "Главный Администратор",
  student: "Студент",
  banned: "Заблокирован",
  requested: "В обработке",
  failed: "Отклонен",
}

export const courseStatuses = ["requested", "banned", "in_progress", "failed", "teacher", "moderator"]
