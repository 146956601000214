import BadgeNewbieIcon from "images/icons/Badges/BadgeNewbie"
import BadgeMiddleIcon from "images/icons/Badges/BadgeMiddle"
import BadgeExperiencedIcon from "../../../images/icons/Badges/BadgeExperienced"


export type BadgeStatusType = 1 | 2 | 3

export const getBadgeIcon = (status?: BadgeStatusType) => {
  if (status === 1) return <BadgeNewbieIcon />
  if (status === 2) return <BadgeMiddleIcon />
  if (status === 3) return <BadgeExperiencedIcon />
  return <></>
}

export const badgeInfo = {
  1: "Пользователь новичок на платформе.",
  2: "Пользователь уже участвовал в мероприятиях вашей организации. Нажмите на иконку, чтобы узнать больше.",
  3: "Пользователь участвовал только в мероприятиях другой организации.",
}
