import { IStatisticsData, IEventFilters } from "../model/StatisticsContext.types"

export const initTable = { count: 0, next: false, results: [], isLoaded: false }

const initStatistics = {
  charts: { general: {} },
  chartLabels: [],
  data: initTable,
} as IStatisticsData

export const initialStatistics = {
  requests: initStatistics,
  statuses: initStatistics,
  survey: initStatistics,
  personal: initStatistics,
}

export const initEventFilters = {
  answers: [],
  questions: [],
} as IEventFilters

export const ranges = [
  { value: "day", label: "Сутки" },
  { value: "week", label: "Неделя" },
  { value: "month", label: "Месяц" },
  { value: "all", label: "За всё время" },
  { value: "custom", label: "Другой интервал" },
]
