import { useEffect, useState } from "react"
import * as Styled from "./ui/Teams.style.js"
import { InviteHelperText } from "entities/Team/static/InviteHelperText"
import { Button, Dropdown, Modal, TextField } from "shared/ui"
import { Typography, useTheme } from "@mui/material"
import { LinkIcon } from "images/icons/Link.js"
import { useSelector, useDispatch } from "react-redux"
import { teamActions } from "shared/store"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import SuccessIcon from "images/icons/Success.js"
import Cookies from "js-cookie"
import { fetchTeamProps, getTeamCategories } from "entities/Team/model/getTeamProps.js"
import { TeamWordMapper } from "entities/Team/model/teamWordMapper"
import { geInvitetResponseMessage } from "entities/Team/lib/inviteUserResponse"
import FailIcon from "images/icons/Fail.js"

export const InviteUserModal = ({ isOpen, onClose, team }) => {
  const [email, setEmail] = useState("")
  const [role, setRole] = useState(null)
  const [alert, setAlert] = useState({ status: null, message: "" })
  const [error, setError] = useState(null)
  const [categories, setCategories] = useState({ "": "" })
  const course = useSelector(state => state.course.course)
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    setAlert({ status: null, message: "" })
  }, [email, role])

  function inviteUser() {
    const params = {
      title: team.title,
      user_email: email,
      member_category: role.trim(),
      course: course.id,
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`content/teams/${team.id}/invite/`, params)
      .then(async res => {
        const message = geInvitetResponseMessage(res.status)
        setAlert({ status: "success", message })
        await refetchTeams()
      })
      .catch(err => {
        if (err.response.status === 404) {
          return setError("Участник не обнаружен")
        }
        if (err.response.status === 406) {
          return setError("Вы не можете пригласить этого пользователя, так как он не является участником курса")
        }
        const message = geInvitetResponseMessage(err.response.status)
        setAlert({ status: "fail", message })
      })
  }

  async function refetchTeams() {
    const teams = await axios
      .get("content/actions/teams/my_teams/", { params: { course: course.id } })
      .then(res => res.data)
    dispatch(teamActions.setTeams(teams))
  }

  async function handleClipboard() {
    try {
      const link = await getLink()
      navigator.clipboard.writeText(link)
      setAlert({ status: "success", message: "Ссылка скопирована" })
    } catch (err) {
      setAlert({ status: "fail", message: "Повторите попытку позже" })
    }
  }

  async function getLink() {
    return await axios.get(`content/teams/${team.id}/get_invitation_link/`).then(res => res.data)
  }

  async function getCategories() {
    const teamProps = await fetchTeamProps(course.id)
    const fetchedCategories = getTeamCategories(teamProps)
    setCategories(fetchedCategories)
  }

  const handleChange = e => {
    setError(false)
    setEmail(e.target.value.toLowerCase())
  }

  return (
    <Modal title={"Пригласить пользователей в команду"} isOpen={isOpen} onClose={onClose}>
      <Styled.ContentWrapper>
        <InviteHelperText />
        <Typography variant="h4">Пригласить в команду</Typography>
        <Styled.FlexWrapper>
          <div style={{ maxWidth: "383px" }}>
            <Typography sx={{ maxWidth: "376px", marginTop: "26px" }}>
              Вы можете пригласить участника, отправив ссылку на приглашение
            </Typography>
            <TextField
              id={"user_email"}
              variant={"outlined"}
              name={"email"}
              label={"Почта"}
              onChange={handleChange}
              value={email}
              style={{ width: "383px", marginTop: "46px" }}
              error={error}
              helperText={error}
            />
            <Dropdown
              label={"Роль участника команды"}
              value={role}
              options={categories}
              onChange={e => setRole(e.target.value)}
              style={{ width: "383px", marginTop: "52px" }}
              customWordMapper={TeamWordMapper}
            />
          </div>
          <Styled.ButtonsWrapper>
            <Button
              variant={"outlined"}
              sx={{ display: "flex", gap: "12px" }}
              onClick={handleClipboard}
              endIcon={<LinkIcon />}
            >
              Скопировать ссылку
            </Button>
            <Button
              variant={"outlined"}
              sx={{ width: "258px", marginTop: "auto" }}
              onClick={inviteUser}
              disabled={!(email && role)}
            >
              Пригласить
            </Button>
          </Styled.ButtonsWrapper>
        </Styled.FlexWrapper>
        {alert.status && (
          <Styled.AlertWrapper sx={{ marginTop: theme.spacing(10) }}>
            {alert.message !== "Повторите попытку позже" && (
              <Styled.IconWrapper>{alert.status === "success" ? <SuccessIcon /> : <FailIcon />}</Styled.IconWrapper>
            )}
            <Typography sx={{ maxWidth: "600px" }}>{alert.message}</Typography>
          </Styled.AlertWrapper>
        )}
      </Styled.ContentWrapper>
    </Modal>
  )
}
