import styled from "@emotion/styled"

export const DownloadWrapper = styled.div`
  z-index: 500;
  position: relative;
`

export const DownloadButton = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: #1565c0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

export const Loader = styled.div`
  width: 146px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const Options = styled.ul`
  position: absolute;
  top: 30px;
  right: 0;
  padding: unset;
  margin: unset;
  padding-block: 8px;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 384px;
  list-style: none;
  text-align: left;
`

export const Option = styled.li<{ isActive?: boolean }>`
  padding: 8px 16px;
  width: 100%;
  transition: background-color 0.2s ease;
  background-color: #efefef;
  cursor: pointer;

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "#EFEFEF" : " #dee7f2")};
  }
`
