function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    let index = 0;

    while (index < array.length) {
        chunkedArray.push(array.slice(index, index + chunkSize));
        index += chunkSize;
    }

    return chunkedArray;
}

export default chunkArray
