import React, { useEffect, useRef, useState } from "react"
import { Button, Modal } from "shared/ui"
import * as Styled from "./Landing.styles"
import { PolicyText } from "shared/assets"
import { useTheme } from "shared/context"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "../lib/RequestsFormSchema"
import { LandingFormInput } from "./LandingFormInput"
import { Typography } from "@mui/material"
import { CheckBoxFormInput } from "./CheckBoxFormInput"
import EmailOk from "images/mail_ok.svg"
import EmailLate from "images/mail_error.svg"
import { organization_types, request_variants } from "../static/RequestsOptions"
import { sendRequestEmail } from "../api"

export const RequestsModal = ({
  isOpenModal,
  setIsOpenModal,
}: {
  isOpenModal: boolean
  setIsOpenModal: (state: boolean) => void
}) => {
  const theme = useTheme()
  const [imageModal, setImageModal] = useState<string | null>(null)
  const [helperText, setHelperText] = useState("")
  const methods = useForm({ resolver: yupResolver(schema) })
  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const nameRef = useRef<HTMLDivElement>(null)
  const contactRef = useRef<HTMLDivElement>(null)
  const organizationNameRef = useRef<HTMLDivElement>(null)
  const organizationTypeRef = useRef<HTMLDivElement>(null)
  const requestVariantsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (errors.name) {
        nameRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      } else if (errors.contact) {
        contactRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      } else if (errors.organization_name) {
        organizationNameRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      } else if (errors.organization_type) {
        organizationTypeRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      } else if (errors.request_variants) {
        requestVariantsRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [errors])

  const sendRequest = (data: {
    name: string
    contact: string
    organization_name: string
    organization_type: string
    request_variants: string
    text_field_organization_type: string
    text_field_request_variants: string
  }) => {
    let text = `ФИО: ${data.name}.\nКак удобнее с вами связаться: ${data.contact}.\nНазвание организации: ${
      data.organization_name
    }. \nВаша организация: ${JSON.stringify(
      data.organization_type
        .split(";")
        .filter(e => e !== "Другое (указать)")
        .join("; ")
    )}${
      data.organization_type.includes("Другое (указать)") ? "; " + data.text_field_organization_type : ""
    }. \nУточните, пожалуйста, что из нижеперечисленного подходит вам больше всего: ${JSON.stringify(
      data.request_variants
        .split(";")
        .filter(e => e !== "Другое (указать)")
        .join("; ")
    )} ${data.request_variants.includes("Другое (указать)") ? "; " + data.text_field_request_variants : ""}.`
    sendRequestEmail(data.name, text)
      .then(() => {
        //@ts-ignore
        setImageModal(EmailOk)
        setHelperText("Ваше письмо успешно отправлено! \n Новое письмо Вы можете отправить через 1 минуту.")
      })
      .catch(err => {
        let second = /\d+/.exec(err.response.data.detail) || 60
        setHelperText(`Вы можете отправить новое письмо через ${second} секунд(ы), пожалуйста, подождите.`)
        //@ts-ignore
        setImageModal(EmailLate)
      })
  }

  return (
    <Modal
      isOpen={isOpenModal}
      title={"Свяжитесь с нами, чтобы попробовать!"}
      borderRadius={theme.spacing(4)}
      onClose={() => {
        setHelperText("")
        setImageModal(null)
        setIsOpenModal(false)
      }}
    >
      {!imageModal && (
        <Styled.RequestModalMainDiv>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"large"}>
            Благодарим Вас за интерес к платформе! Мы были бы рады связаться и познакомиться с Вами. Пожалуйста,
            заполните небольшую форму ниже.
          </Typography>
          <FormProvider {...methods}>
            <Styled.RequestFormDiv>
              <div style={{ width: "100%" }} ref={nameRef}>
                <LandingFormInput
                  style={{ width: "300px", [theme.breakpoints.down("xs")]: { width: "100%" } }}
                  required={true}
                  name={"name"}
                  type={"name"}
                  label={"ФИО"}
                  disableTooltip
                />
              </div>
              <div style={{ width: "100%" }} ref={contactRef}>
                <LandingFormInput
                  style={{ width: "300px", [theme.breakpoints.down("xs")]: { width: "100%" } }}
                  required={true}
                  name={"contact"}
                  type={"text"}
                  label={"Как удобнее с вами связаться?"}
                  disableTooltip
                />
              </div>
              <div style={{ width: "100%" }} ref={organizationNameRef}>
                <LandingFormInput
                  style={{ width: "300px", [theme.breakpoints.down("xs")]: { width: "100%" } }}
                  required={true}
                  name={"organization_name"}
                  type={"text"}
                  label={"Название организации"}
                  disableTooltip
                />
              </div>
              <div style={{ width: "100%" }} ref={organizationTypeRef}>
                <CheckBoxFormInput
                  options={organization_types}
                  name={"organization_type"}
                  label={"Ваша организация:"}
                />
              </div>
              <div style={{ width: "100%" }} ref={requestVariantsRef}>
                <CheckBoxFormInput
                  options={request_variants}
                  name={"request_variants"}
                  label={"Уточните, пожалуйста, что из нижеперечисленного интересно Вам больше всего:"}
                />
              </div>
            </Styled.RequestFormDiv>
          </FormProvider>
          <Styled.SendFormContainer>
            {/*@ts-ignore*/}
            <Button
              variant={"contained"}
              color={"primary"}
              //@ts-ignore
              onClick={handleSubmit(sendRequest)}
              sx={{ [theme.breakpoints.down("sm")]: { width: "100%" } }}
            >
              ОТПРАВИТЬ
            </Button>
            <PolicyText text={"ОТПРАВИТЬ"} />
          </Styled.SendFormContainer>
        </Styled.RequestModalMainDiv>
      )}
      {!!imageModal && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 16,
          }}
        >
          <Styled.StyledResultImage src={imageModal} alt={"Результат отправки формы фидбэка"} />
          <Typography
            variant={"body1"}
            fontSize={"medium"}
            fontWeight={"medium"}
            sx={{ [theme.breakpoints.down("xs")]: { textAlign: "center" } }}
          >
            {helperText}
          </Typography>
        </div>
      )}
    </Modal>
  )
}
