import { createSlice } from "@reduxjs/toolkit"
import { fetchAssignments } from "./assignmentActions"

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState: {
    assignments: {
      data: [],
      isLoading: false,
      isLoaded: false,
      page: 1,
      hasNextPage: false,
      totalCount: 0,
    },
    filters: {},
    currentAssignment: null,
    assignmentIndex: null,
    course: { id: NaN, label: "", status: "" },
    modules: [],
    tasks: [],
    filteredTasks: [],
    examination: {
      isExamination: false,
      initStatus: null,
    },
    disableCheckbox: false,
  },
  reducers: {
    resetAssignments: state => {
      state.assignments = {
        data: [],
        isLoading: false,
        isLoaded: false,
        page: 1,
        hasNextPage: false,
        totalCount: 0,
      }
    },
    setAssignments: (state, action) => {
      state.assignments.data = action.payload.results ?? []
      state.assignments.hasNextPage = Boolean(action.payload?.next ?? state.assignments?.hasNextPage)
      state.assignments.totalCount = action.payload?.count ?? state.assignments.totalCount
      state.assignments.isLoaded = true
    },
    updateAssignments: (state, action) => {
      const result = state.assignments.data.map(assignment => {
        if (assignment.id === action.payload.id) return action.payload
        return assignment
      })
      state.assignments.data = result
    },
    filterAssignments: state => {
      const filteredData = state.assignments.data.filter(assignment => {
        return state.filters?.status ? assignment.status === state.filters?.status : true
      })
      state.assignments.data = filteredData
    },
    setCourse: (state, action) => {
      state.course = action.payload
    },
    setModules: (state, action) => {
      state.modules = action.payload
    },
    setAssignmentIndex: (state, action) => {
      state.assignmentIndex = action.payload
    },
    nextAssignment: state => {
      state.examination.initStatus = null
      state.assignmentIndex += 1
    },
    prevAssignment: state => {
      state.examination.initStatus = null
      state.assignmentIndex -= 1
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setIsLoading: (state, action) => {
      state.assignments.isLoading = action.payload
    },
    setExamination: (state, action) => {
      state.examination.isExamination = action.payload.isExamination
      state.examination.initStatus = action.payload.initStatus
    },
    setTasks: (state, action) => {
      state.tasks = action.payload
    },
    setFilteredTasks: (state, action) => {
      const filteredTasks = state.tasks.filter(task => {
        if (!action.payload.stage) {
          return true
        }
        if (action.payload.stage && !action.payload.module) {
          return action.payload.stage === task.stage
        }
        if (action.payload.module) {
          return action.payload.stage === task.stage && action.payload.module === task.module
        }
        return true
      })
      state.filteredTasks = filteredTasks.length
        ? filteredTasks.reduce(
            (acc, task) => [...acc, { id: task.id, label: task.title }],
            [{ id: null, label: "Не выбрано" }]
          )
        : []
    },
    resetFilteredTasks: (state, action) => {
      const tasks = action.payload.reduce(
        (acc, task) => [...acc, { id: task.id, label: task.title }],
        [{ id: null, label: "Не выбрано" }]
      )
      state.filteredTasks = tasks
    },
    setDisableCheckbox: (state, action) => {
      state.disableCheckbox = action.payload
    },
    decreaseTotalCount: state => {
      state.assignments.totalCount -= 1
    },
    increasePage: state => {
      state.assignments.page += 1
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAssignments.pending, state => {
        state.assignments.isLoading = true
      })
      .addCase(fetchAssignments.fulfilled, (state, action) => {
        state.assignments.isLoaded = true
        state.assignments.isLoading = false
        state.assignments.data.push(...action.payload.results)
        state.assignments.page += 1
        state.assignments.hasNextPage = Boolean(action.payload.next)
        state.assignments.totalCount = action.payload.count
      })
      .addCase(fetchAssignments.rejected, state => {
        state.assignments.isLoaded = false
        state.assignments.isLoading = false
        state.assignments.data = []
        state.assignments.page = 1
        state.assignments.hasNextPage = false
        state.assignments.totalCount = 0
      })
  },
})

export const { actions, reducer } = assignmentsSlice
