import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import "react-lazy-load-image-component/src/effects/blur.css"
import { IconButton, Tooltip } from "@mui/material"
import { Header, Footer } from "widgets"
import buttonUpDefault from "images/Button_up_default.svg"
import buttonUpHover from "images/Button_up_hover.svg"
import buttonUpPressed from "images/Button_up_default.svg"
import { CarouselItem } from "./CarouselItem"
import Carousel from "react-material-ui-carousel"
import image1 from "images/banner_01.gif"
import image2 from "images/banner_02.gif"
import image3 from "images/banner_03.gif"

import image1Low from "images/banner_01_low.gif"
import image2Low from "images/banner_02_low.gif"
import image3Low from "images/syncwoia_pic_new_3.ed09f6b9_low.png"

import * as Styled from "./Landing.styles"
import { useTheme, useUserState } from "shared/context"
import { DigitalTechnopark } from "./DigitalTechnopark"
import { RequestForUsing } from "./RequestForUsing"
import { WorkWithHardware } from "./WorkWithHardware"
import { FunctionalOfPlatform } from "./FunctionalOfPlatform"
import { OurOrganizations } from "./OurOrganizations"
import { ProgramsOfPlatform } from "./ProgramsOfPlatform"
import { Communities } from "./Communities"
import { FeedbackForm } from "widgets"

const Landing = props => {
  const [upButtonOpacity, setUpButtonOpacity] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const { registration, isAuthenticated } = useUserState()
  const specRef = useRef(null)
  const communityRef = useRef()
  const theme = useTheme()

  const backgroundGrayColor = "#F1F3F7 "
  const backgroundPinkColor = "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)"

  var items = [
    {
      title: "Syncwoia – киберфизическая платформа",
      description:
        "Современные инструменты создания и поддержки массовых форматов обучения и развития в области современных наук и технологий.",
      color: backgroundGrayColor,
      image: image1,
      lowImage: image1Low,
      textColor: "#313439",
      nextColor: "#FF6757",
      prevColor: "#FF6757",
      buttonVariant: "contained",
      buttonColor: "primary",
      textButton: isAuthenticated ? "КАТАЛОГ" : "РЕГИСТРАЦИЯ",
      onClick: () => {
        isAuthenticated ? (window.location.href = "/catalog") : registration.setOpen(true)
      },
    },
    {
      title: "Устойчивое сообщество",
      description:
        "Объединяем ведущих ученых, специалистов и разработчиков для создания, запуска и продвижения лучших продуктов и программ развития школьников и студентов.",
      color: backgroundPinkColor,
      image: image2,
      lowImage: image2Low,
      textColor: "#FFF",
      nextColor: "#FF6757",
      prevColor: "#E7EBF1",
      buttonVariant: "outlined",
      buttonColor: "additional",
      textButton: "ПОДРОБНЕЕ",
      onClick: () => {
        const element = communityRef.current
        if (element) {
          const elementTop = element?.getBoundingClientRect().top
          window.scrollTo({
            top: elementTop - 80,
            behavior: "smooth",
          })
        }
      },
    },
    {
      title: "Программы и мероприятия",
      description:
        "Присоединяйтесь к уже запущенным программам партнеров или запустите свой комплекс программ, используя современные подходы!",
      color: backgroundPinkColor,
      image: image3,
      lowImage: image3Low,
      textColor: "#FFF",
      nextColor: "#E7EBF1",
      prevColor: "#FF575A",
      buttonVariant: "outlined",
      buttonColor: "additional",
      textButton: "Каталог",
      onClick: () => {
        window.location.href = "/catalog"
      },
    },
  ]

  useEffect(() => {
    window.addEventListener("scroll", () => setUpButtonOpacity(window.scrollY === 0 ? 0 : 1))
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleClickNext()
    }, 8000)
    return () => {
      clearInterval(intervalId)
    }
  }, [activeIndex])

  const handleClickPrev = () => {
    setActiveIndex(prevIndex => (prevIndex - 1 + items.length) % items.length)
  }

  const handleClickNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % items.length)
  }

  return (
    <div style={{ maxWidth: "100vw", overflowX: "hidden", display: "flex", flexDirection: "column" }}>
      <Header history={props.history} specRef={specRef} variant={"landing"} />
      <Styled.CarouselMainContainer>
        <Styled.PrevCarouselItem sx={{ backgroundColor: items[activeIndex].prevColor }} onClick={handleClickPrev} />
        <Carousel
          animation={"slide"}
          onChange={now => {
            setActiveIndex(now)
          }}
          index={activeIndex}
          navButtonsAlwaysInvisible={true}
          autoPlay={false}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: theme.spacing(6),
            background: "#FFF",
          }}
          navButtonsProps={{
            style: {
              color: "#FA5D55",
              background: "transparent",
              border: "1px solid #313439",
              height: 12,
              width: 12,
            },
          }}
          indicatorIconButtonProps={{
            style: {
              margin: 8,
              padding: 1,
              color: "transparent",
              backgroundColor: "transparent",
              border: "1px solid #313439",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#FA5D55",
            },
          }}
          indicatorContainerProps={{
            style: {
              position: "absolute",
              zIndex: 10,
              textAlign: "center",
            },
          }}
        >
          {items.map((item, i) => (
            <CarouselItem key={i} item={activeIndex === i && item} />
          ))}
        </Carousel>
        <Styled.NextCarouselItem onClick={handleClickNext} sx={{ backgroundColor: items[activeIndex].nextColor }} />
      </Styled.CarouselMainContainer>
      <FunctionalOfPlatform />
      <WorkWithHardware />
      <RequestForUsing />
      <DigitalTechnopark />
      <OurOrganizations />
      <RequestForUsing />
      <ProgramsOfPlatform />
      <div ref={communityRef}>
        <Communities />
      </div>
      <FeedbackForm />
      <Footer />
      {upButtonOpacity === 1 && (
        <IconButton
          onClick={() => {
            window.scrollTo(0, 0)
          }}
          disableRipple
          style={{
            position: "fixed",
            right: "16px",
            width: "40px",
            bottom: "5%",
            zIndex: 10,
            cursor: "pointer",
            opacity: upButtonOpacity,
          }}
        >
          <Tooltip
            title={"Наверх"}
            classes={{ tooltip: Styled.tooltip, arrow: Styled.tooltipArrow }}
            placement={"top"}
            arrow
          >
            <Styled.ImageButtonUp
              src={buttonUpDefault}
              onMouseOver={e => (e.currentTarget.src = buttonUpDefault)}
              onMouseOut={e => (e.currentTarget.src = buttonUpDefault)}
              onMouseDown={e => (e.currentTarget.src = buttonUpPressed)}
              onMouseUp={e => (e.currentTarget.src = buttonUpHover)}
            />
          </Tooltip>
        </IconButton>
      )}
    </div>
  )
}

export default withRouter(Landing)
