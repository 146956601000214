const formCreationDate = (date: string) => {
  if (!date) {
    const d = new Date()
    return d.toLocaleDateString("ru-Ru")
  }
  const prevDate = date.split("-")
  const result = `${prevDate[2]}.${prevDate[1]}.${prevDate[0]}`
  return result
}

export default formCreationDate
