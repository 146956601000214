import { styled } from "@mui/material/styles"
import { Button } from "@mui/material"

export const MainDiv = styled("div")(({ theme, color }) => ({
  width: "100%",
  paddingLeft: theme.spacing(53),
  paddingRight: theme.spacing(53),
  paddingTop: theme.spacing(11),
  paddingBottom: theme.spacing(11),
  backgroundColor: color || "#fff",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))

export const ButtonDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
}))
