// @ts-ignore
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { SurveyStatistics } from "./statistics/SurveyStatistics"
import { SectionTabs } from "shared/ui"
import { useTab } from "shared/hooks/useTab"
import { PersonalStatistics } from "./statistics/PersonalStatistics"
import styled from "@emotion/styled"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { RequestsAndStatusesStatistics } from "./statistics/RequestsAndStatusesStatistics"
import { organizationActions } from "../../../shared/store"
import { useAppDispatch } from "../../../shared/hooks/useAppDispatch"

const tabOptions = [
  { title: "Динамика по заявкам", value: 0, enable: true },
  { title: "Данные по статусам", value: 1, enable: true },
  { title: "Сводка по данным анкеты", value: 2, enable: true },
  { title: "Активность ученика", value: 3, enable: true },
]


const OrganizationStatistics = () => {
  const { tab, changeTab } = useTab("org_request_statistics")
  const { resetStatistics } = useStatistics()
  const params = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (params.email) {
      changeTab("", 3)
    }
  }, [])

  const handleChangeTab = (participantEmail: string) => {
    if (participantEmail) {
      resetStatistics("personal")
      dispatch(organizationActions.setEmail(participantEmail))
      changeTab("", 3)
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <TabsWrapper>
        <SectionTabs value={tab} onChange={changeTab} options={tabOptions} />
      </TabsWrapper>
      {tab === 0 && <RequestsAndStatusesStatistics onChangeTab={handleChangeTab} tabType={"requests"}
                                                   enableEventDateSelector={true} />}
      {tab === 1 && <RequestsAndStatusesStatistics onChangeTab={handleChangeTab} tabType={"statuses"}
                                                   enableEventDateSelector={false} />}
      {tab === 2 && <SurveyStatistics onChangeTab={handleChangeTab} enableEventDateSelector={true} />}
      {tab === 3 && <PersonalStatistics/>}
    </div>
  )
}

const TabsWrapper = styled.div`
  z-index: 1200;
  position: sticky;
  top: 95px;
  width: fit-content;
`

export { OrganizationStatistics }
