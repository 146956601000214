import { getOrganizationParticipants } from "entities/Organization/api"
import { IParticipantsResponse } from "entities/Organization/api/types"
import { getCourseParticipants } from "entities/Course/api/course"
import { PageLocationType } from "features/CourseParticipants/ui/ParticipantsInProgress"

export function formCategories(options: string[]) {
  let result = { "Без категории": "Без категории" }
  if (!options) return result
  options.forEach(option => (result = { ...result, [option]: option }))
  return result
}

export const recieveParticipant = async (
  pageLocation: PageLocationType,
  payload: Record<string, any>,
  organizationId?: number
): Promise<IParticipantsResponse | undefined> => {
  if (pageLocation === "organizations" && organizationId) {
    return await getOrganizationParticipants(organizationId, payload)
  } else if (pageLocation === "admin_courses") {
    return await getCourseParticipants(payload)
  }
}
