import React, { useEffect, useState } from "react"
import { Button, Dialog, Grid, Link, Tab, Tabs, TextField, Typography } from "@mui/material"
import useStyles from "./styles"
import ProfileEditor from "./ProfileEditor"
import { ResetPassword } from "features"
import EditName from "./EditName"

import { UserProfile } from "entities/User"
import { Achievement } from "entities/Achivement"
import { CertificatesList, Subscribes } from "widgets"

import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"

import { Card } from "shared/ui"
import { useDispatch, useSelector } from "react-redux"
import { fetchAchievements } from "shared/store"
import { useTab } from "shared/hooks/useTab"
import { fetchProfile } from "shared/store"

const images = [...Array(16).keys()].map(num => require(`../../images/avatars/pic-${num + 1}.png`))

const Profile = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const achievements = useSelector(state => state.achievement.achievements)

  const [editPassword, setEditPassword] = useState(false)
  const [editName, setEditName] = useState(false)
  const [profile, setProfile] = useState(null)
  const [editAvatar, setEditAvatar] = useState(false)
  const [currentAvatar, setCurrentAvatar] = useState(null)
  const { tab, changeTab } = useTab("profile_tab")

  function confirmAvatar() {
    setEditAvatar(false)
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.put(`/profiles/${profile.id}/`, { ...profile, avatar: currentAvatar }).then(res => {
      axios.get("/profiles/my_profile/").then(res => {
        setProfile(res.data)
      })
    })
  }

  useEffect(() => {
    axios.get("/profiles/my_profile/").then(res => {
      setProfile(res.data)
      setCurrentAvatar(res.data.avatar)
      dispatch(fetchProfile())
    })
    dispatch(fetchAchievements())
  }, [])

  function isStaffStatus() {
    return profile.status === "teacher" || profile.status === "moderator" || profile.status === "admin"
  }

  if (!profile) return <></>

  return (
    <div style={{ width: "100%", maxWidth: 1200, margin: "auto" }}>
      <Dialog open={!!editPassword} onClose={() => setEditPassword(null)} fullWidth maxWidth="sm">
        {!!editPassword && <ResetPassword />}
      </Dialog>
      <Dialog open={!!editName} onClose={() => setEditName(null)} fullWidth maxWidth="sm">
        {!!editName && <EditName profile={profile} />}
      </Dialog>
      <Dialog open={editAvatar} onClose={() => setEditAvatar(false)} fullWidth maxWidth="md">
        <div style={{ padding: 36 }}>
          <Grid container spacing={3}>
            {!!images &&
              images.map(item => (
                <Grid item xs={3} padding={8}>
                  <img
                    src={item}
                    width="100%"
                    onClick={() => setCurrentAvatar(item)}
                    style={item === currentAvatar ? { border: "2px solid #FA5D55", borderRadius: "50%" } : {}}
                  />
                </Grid>
              ))}
          </Grid>
          {isStaffStatus() && (
            <TextField
              id="avatar-input"
              variant="outlined"
              value={currentAvatar}
              onChange={e => setCurrentAvatar(e.target.value)}
              helperText="Ссылка на аватар"
              fullWidth
            />
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={confirmAvatar}
              variant="contained"
              color="primary"
              size="large"
              style={{ width: 240, height: 50, textTransform: "uppercase", marginTop: 16 }}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </Dialog>
      <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "20px" }}>
        <Tabs value={tab} onChange={changeTab}>
          <Tab value={0} label={<Typography variant={"subtitle3"}>Профиль</Typography>}></Tab>
          <Tab value={1} label={<Typography variant={"subtitle3"}>Достижения</Typography>}></Tab>
          <Tab value={2} label={<Typography variant={"subtitle3"}>Сертификаты</Typography>}></Tab>
          <Tab value={3} label={<Typography variant={"subtitle3"}>Подписки</Typography>}></Tab>
        </Tabs>
      </div>
      {tab === 0 && (
        <UserProfile
          user={profile}
          avatarAction={
            <Button variant="contained" color="primary" onClick={() => setEditAvatar(true)}>
              ИЗМЕНИТЬ ФОТО
            </Button>
          }
          userActions={
            <div style={{ width: 120, marginRight: 20 }}>
              <Link component="button" variant="body2" underline="hover" onClick={() => setEditPassword(true)}>
                Изменить&nbsp;пароль
              </Link>
              <Link component="button" variant="body2" underline="hover" onClick={() => setEditName(true)}>
                Изменить&nbsp;ФИО
              </Link>
            </div>
          }
        />
      )}
      {tab === 0 && (
        <Card className={classes.profileContainer}>
          <ProfileEditor profile={profile} setProfile={setProfile} />
        </Card>
      )}
      {tab === 1 && (
        <Card className={classes.profileContainer}>
          <Typography className={classes.profileH1}>Мои достижения</Typography>
          <Typography className={classes.profileP}>
            Достижения — награды, которые Syncwoia даёт самым активным пользователям.
          </Typography>

          <Typography className={classes.profileH1} style={{ marginTop: 80, marginBottom: 48 }}>
            Полученные знания{" "}
          </Typography>
          <Grid container xs={12} className={classes.achievContainer} spacing={4}>
            {achievements && achievements.map(e => <Achievement {...e} />)}
          </Grid>
        </Card>
      )}
      {tab === 2 && (
        <Card className={classes.profileContainer}>
          <Typography className={classes.profileH1} style={{ marginBottom: 48 }}>
            Мои сертификаты
          </Typography>
          <Typography className={classes.profileP}>
            Полученные сертификаты или дипломы будут храниться на этой странице.
          </Typography>
          <Typography className={classes.profileP} style={{ marginBottom: 48 }}>
            Вы сможете их скачивать, распечатывать или отправлять ссылки на электронные версии.
          </Typography>
          <CertificatesList />
        </Card>
      )}
      {tab === 3 && <Subscribes />}
    </div>
  )
}

export default Profile
