import { Typography } from "@mui/material"
import { Modal, Button } from "shared/ui"
import { ButtonWrapper } from "./Modal.style"
import { Loader } from "shared/ui"
import { ReactNode } from "react"
import { Color, Variant } from "../styles.const"

interface IProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onClick?: () => void
  text?: string
  children?: ReactNode
  label?: string
  abortLabel?: string
  onAltClick?: () => void
  isAbortButton?: boolean
  isLoading?: boolean
  isDeleting?: boolean
  variant?: Variant
  width?: number
}

const ModalSmallTemplate = ({
  title,
  isOpen,
  onClose,
  onClick,
  text,
  children,
  label = "Продолжить",
  abortLabel = "Отмена",
  onAltClick,
  isAbortButton,
  isLoading,
  isDeleting,
  variant,
  width,
}: IProps) => {
  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose} size="small">
      <Typography variant={"body1"} fontSize={"small"} fontWeight={"medium"} style={{ maxWidth: "527px" }}>
        {isLoading ? <Loader /> : text || children}
      </Typography>
      {!isLoading && (
        <ButtonWrapper>
          <Button
            variant={variant || Variant.contained}
            onClick={onClick || onClose}
            color={isDeleting ? Color.accent : Color.primary}
            sx={{ marginTop: isAbortButton ? "30px" : "24px" }}
            width={width || 163}
          >
            {label}
          </Button>
          {isAbortButton && (
            <Button
              variant={Variant.outlined}
              onClick={onAltClick || onClose}
              sx={{ marginTop: "30px", marginLeft: "auto" }}
            >
              {abortLabel}
            </Button>
          )}
        </ButtonWrapper>
      )}
    </Modal>
  )
}

export { ModalSmallTemplate }
