import React, { useEffect, useState } from "react"
import * as Styled from "./Event.styles"
import { Avatar, Typography } from "@mui/material"
import { EventChip } from "./EventChip"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import { IEvent } from "../model/Event.types"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { useTheme } from "../../../shared/context"
import { axiosInstance } from "../../../shared/api/axiosInstance"

export const EventItem = ({ props }: { props: IEvent }) => {
  const theme = useTheme()
  const [organization, setOrganization] = useState<null | { website: string; icon_logo: string }>(null)

  function handleClickItem() {
    if (props.visibility === "visible") window.location.href = `/event/${props.slug}`
  }

  useEffect(() => {
    axiosInstance
      .get(`/organizations/${props.owner}/`)
      .then((res: { data: { website: string; icon_logo: string } }) => {
        setOrganization(res.data)
      })
  }, [])

  function stringifyDates(start: string, finish: string) {
    let startDate = new Date(start)
    let finishDate = new Date(finish)
    let format = new Intl.DateTimeFormat("ru-RU", { month: "long", day: "numeric" })
    if (start === finish) {
      return format.format(startDate)
    }
    return `${format.format(startDate)} - ${format.format(finishDate)}`
  }

  return (
    <Styled.EventItemMainDiv
      onClick={handleClickItem}
      sx={props.visibility === "visible" ? { cursor: "pointer" } : { opacity: "0.5" }}
    >
      <Styled.DescriptionEventItem>
        <Styled.ChipsDiv>
          <EventChip label={"Событие"} variant={"type"} />
          {props.tags_info.map(e => (
            <EventChip label={e.value} variant={"tag"} />
          ))}
        </Styled.ChipsDiv>
        <Typography
          variant={"h3"}
          sx={{ height: "104px", [theme.breakpoints.down("sm")]: { height: theme.spacing(50) } }}
        >
          {props.title}
        </Typography>
        <Styled.EventDescriptionData>
          <Styled.ContentContainer>
            <Styled.EventDataItem>
              <AccountCircleRoundedIcon sx={{ color: "#858585" }} />
              <Typography sx={{ color: "#858585", fontSize: "14px", fontWeight: "400" }}>
                {props.participants_num}
              </Typography>
            </Styled.EventDataItem>
            <Styled.EventDataItem>
              <CalendarMonthIcon sx={{ color: "#858585" }} />
              <Typography sx={{ color: "#858585", fontSize: "14px", fontWeight: "400" }}>
                {stringifyDates(props.start, props.finish)}
              </Typography>
            </Styled.EventDataItem>
          </Styled.ContentContainer>
          {!!organization && (
            <Styled.StyledAvatar
              src={organization?.icon_logo}
              onClick={() => window.open(organization?.website, "_blank")}
            />
          )}
        </Styled.EventDescriptionData>
      </Styled.DescriptionEventItem>
      <Styled.EventItemImageDiv
        sx={{ backgroundColor: JSON.parse(props.template_props).main_screen?.backgroundColor || "#FFF" }}
      >
        <Styled.EventItemImage src={JSON.parse(props.template_props).main_screen?.Image?.url} />
      </Styled.EventItemImageDiv>
    </Styled.EventItemMainDiv>
  )
}
