import React, {useEffect, useRef, useState} from "react";
import {Button, CircularProgress, TextField, Typography,} from "@mui/material";

import { axiosInstance as axios } from "shared/api/axiosInstance";
import Cookies from 'js-cookie';
import VisibilitySensor from "react-visibility-sensor";
import { UserReview } from "entities/Review";

export const ReviewsEditor = () => {
  
  const [helperText, setHelperText] = useState("")
  const [reviews, setReviews] = useState([])

  const courseRef = useRef()
  const userRef = useRef()
  const textRef = useRef()
  const scoreRef = useRef()

  const [page, setPage] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleScrolledToBottom = (isBottomVisible) => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      getReviews()
    }
  }

  function getReviews(){
    let index = page + 1
    setPage(index)
    setIsLoading(true)
    axios.get("/content/reviews/", { params: { page: index } }).then(res => {
      if (!!res.data.results) {
        setReviews([...reviews, ...res.data.results].sort((a, b) => a.is_moderated ? 1 : -1))
      }
      setHasNextPage(!!res.data.next)
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      setHasNextPage(false)
    })
  }

  useEffect(() => {
    getReviews()
  }, [])

  function updateReview() {
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    let data = {
      course: courseRef.current.value,
      user: userRef.current.value,
      text: textRef.current.value,
      score: !!scoreRef.current.value ? scoreRef.current.value : null,
    }
    axios.post("/content/reviews/", data).then(() => {
      setHelperText("Создано")
    })
  }

  function moderateReview(reviewId, action, index) {
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    if (action === "accept") {
      axios.patch(`/content/reviews/${reviewId}/`, { is_moderated: true }).then(() => {
        setHelperText("Принято")
      })
    }
    if (action === "reject") {
      axios.delete(`/content/reviews/${reviewId}/`).then(() => {
        setHelperText("Удалено")
      })
    }
    let newReviews = [ ...reviews ]
    newReviews.splice(index, 1)
    setReviews(newReviews)
  }

  return (
    <div style={{ width: "70%", margin: "auto" }}>
      <Typography variant="h5" style={{ textAlign: "center", color: "red", marginTop: 24 }}>
        {helperText}
      </Typography>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 24 }}>
        <TextField
          variant="outlined"
          helperText="ID курса"
          fullWidth
          style={{ marginTop: 24 }}
          inputRef={courseRef}
        />
        <TextField
          variant="outlined"
          helperText="ID пользователя"
          fullWidth
          style={{ marginTop: 24 }}
          inputRef={userRef}
        />
      </div>
      <TextField
        variant="outlined"
        helperText="Текст комментария"
        fullWidth
        multiline
        style={{ marginTop: 24 }}
        inputRef={textRef}
      />
      <TextField
        variant="outlined"
        helperText="Оценка"
        type="number"
        fullWidth
        style={{ marginTop: 24 }}
        inputRef={scoreRef}
      />
      <div style={{ marginTop: 24 }}>
        <Button
          onClick={updateReview}
          variant="contained"
          color="primary"
          size="large"
          style={{ width: 150, height: 50, marginBottom: 6 }}
        >
          Создать / Обновить
        </Button>
      </div>
      <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
        <div>
          {reviews.filter(x => !!x.text).map((r, index) => (
              <UserReview review={r} actions={
                <>
                {!r.is_moderated &&
                  <Button onClick={() => moderateReview(r.id, "accept", index)}>Принять</Button>
                }
                <Button onClick={() => moderateReview(r.id, "reject", index)}>Удалить</Button>
                </>
              }/>
          ))}
        </div>
      </VisibilitySensor>
      {isLoading &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 40 }}><CircularProgress size={26} /></div>
      }
    </div>
  )
}
