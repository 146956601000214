import React, { useEffect, useState } from "react"
import { Autocomplete, Button, ShadowedSection, TextField } from "shared/ui"
import * as Styled from "./TrajectoryConstructor.styles"
import { CircularProgress, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { AutocompleteEvent, OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { ICourse } from "entities/Course/api/course.types"

interface IProps {
  fetchCourse: (id: number) => void
  isLoading: boolean
}

export const ChooseCourse = ({ fetchCourse, isLoading }: IProps) => {
  const [variants, setVariants] = useState<{ id: number; label: string }[]>([])
  const [variantValue, setVariantValue] = useState<undefined | { id: number; label: string } | null>(null)
  const [fieldValue, setFieldValue] = useState("")
  const userStatus = localStorage.getItem("user_status")

  useEffect(() => {
    axios.get("content/actions/participants/my_courses/?status=moderator", {}).then(res => {
      setVariants(res.data.map((e: ICourse) => ({ id: e.course, label: e.course_title })))
    })
  }, [])

  const handleChooseCourse = (event: AutocompleteEvent, newValue: OptionType | null) => {
    let value = !!newValue ? { id: Number(newValue?.id), label: String(newValue?.label) } : null
    setVariantValue(value)
    setFieldValue(String(newValue?.id) || "")
  }

  const handleChangeFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    let variant = variants.find(v => v.id === Number(inputValue))
    setFieldValue(inputValue)
    if (variant !== undefined) {
      setVariantValue(variant)
    } else {
      setVariantValue(undefined)
    }
  }

  const handleClick = () => {
    fetchCourse(Number(fieldValue))
  }

  return (
    <ShadowedSection>
      <Styled.ChooseCourseMainDiv>
        <Typography variant={"h4"}>Выбор курса</Typography>
        <Styled.ChooseCourseDiv>
          <Autocomplete
            id={"AutoComplete_course_id_value"}
            label={"Название курса"}
            style={{ width: "250px" }}
            options={variants}
            value={variantValue}
            onChange={handleChooseCourse}
          />
          {(userStatus === "admin" || userStatus === "moderator") && (
            <>
              <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
                или
              </Typography>
              <TextField
                id={"TextField_course_id_value"}
                label={"ID курса"}
                variant={"outlined"}
                value={fieldValue}
                onChange={e => handleChangeFieldValue(e)}
              />
            </>
          )}
          <Button variant={"contained"} color={"primary"} onClick={handleClick} disabled={isLoading}>
            Применить
          </Button>
          {isLoading && <CircularProgress />}
        </Styled.ChooseCourseDiv>
      </Styled.ChooseCourseMainDiv>
    </ShadowedSection>
  )
}
