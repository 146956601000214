import { Autocomplete } from "shared/ui"
import { OrganizationStatisticsSection } from "entities/Organization/ui/OrganizationStatisticsSection"
import { EventDateSelector } from "features/Organization/ui/EventDateSelector"
import { OrganizationStatisticsTemplate } from "entities/Organization/ui/Statistics/OrganizationStatisticsTemplate"
import SurveyTemplate from "../../../../entities/Organization/ui/Statistics/SurveyTemplate"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"
import useSurveyStatistics from "../../../../features/CourseParticipants/model/useSurveyStatistics"
import { useAppDispatch } from "../../../../shared/hooks/useAppDispatch"
import { useEffect, useState } from "react"
import { ICourse } from "../../../../entities/Course/api/course.types"
import { getOrganizationParticipants } from "../../../../entities/Organization/api"
import { IEvent } from "../../../../entities/Organization/model/Organization.types"

export interface SurveyStatisticsType {
  onChangeTab: (x: string) => void
  enableEventDateSelector: boolean
}

const SurveyStatistics = ({ onChangeTab, enableEventDateSelector }: SurveyStatisticsType) => {
  const {
    event,
    onSubmit,
    isLoadingChart,
    isLoadingTable,
    submitTrigger,
    windowTab,
    setWindowTab,
    handleChangeCourse,
  } = useSurveyStatistics()
  const submitOnCloseSelector = windowTab === 1 ? onSubmit : undefined

  const [coursesList, setCoursesList] = useState<ICourse[]>([])
  const dispatch = useAppDispatch()
  const organization = useAppSelector(state => state.organization.currentOrganization)
  useEffect(() => {
    const requestCourses = async () => {
      if (!organization?.id) return
      try {
        const response = await getOrganizationParticipants(organization?.id)
        const courses = response.map((course: IEvent) => ({ id: course.id, label: course.title }))
        const options = courses.length ? [{ id: null, label: "Не выбрано" }, ...courses] : []
        setCoursesList(options)
      } catch {
      }
    }
    requestCourses().catch(e => console.log(e))
  }, [dispatch, organization])

  return (
    <>
      <OrganizationStatisticsSection
        title={"Сводка по данным анкеты мероприятия"}
        windowTab={windowTab}
        setWindowTab={setWindowTab}
        selector={
          <>
            <Autocomplete
              key={event?.title}
              label={"Название мероприятия"}
              onChange={handleChangeCourse}
              // @ts-ignore
              onReset={handleChangeCourse}
              // @ts-ignore
              options={coursesList}
              value={event?.title}
              width={540}
            />
            <EventDateSelector event={event} isLoading={isLoadingChart} onReload={submitOnCloseSelector}
                               enableEventDateSelector={enableEventDateSelector} />
          </>
        }
      >
        <SurveyTemplate event={event} isLoading={isLoadingChart} windowTab={windowTab} onSubmit={onSubmit} />
      </OrganizationStatisticsSection>
      <OrganizationStatisticsTemplate
        type={"survey"}
        isLoadingProp={isLoadingTable}
        submitTrigger={submitTrigger}
        onChangeTab={onChangeTab}
      />
    </>
  )
}

export { SurveyStatistics }
