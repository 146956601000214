import {createSlice} from "@reduxjs/toolkit";
import {fetchAchievements} from "./achievementActions";


const achievementSlice = createSlice({
    name: "achievement",
    initialState: {
        achievements: []
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAchievements.fulfilled, (state, action) => {
            state.achievements = [...action.payload]
        })
    }
})

export const {actions, reducer} = achievementSlice
