import { useEffect, useState } from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useStatistics } from "./StatisticsContext"

export interface IQuestion {
  id: number
  title: string
  answers: string[]
}

export interface IAnswer {
  question: IQuestion
  title: string
  color?: string
  data?: number
}

export const useStatisticsDataList = () => {
  const course = useAppSelector(state => state.course.course)
  const [questionOptions, setQuestionOptions] = useState<IQuestion[]>([])
  const [answerOptions, setAnswerOptions] = useState<IAnswer[]>([])
  const [activeQuestion, setActiveQuestion] = useState<IQuestion>()
  const { setAnswers, setQuestions, eventFilters } = useStatistics()
  const { answers, questions } = eventFilters

  useEffect(() => {
    parseQuestions()
  }, [course])

  useEffect(() => {
    if (activeQuestion) getAnswerOptions()
    else {
      setAnswerOptions([])
    }
  }, [activeQuestion])

  const parseQuestions = () => {
    if (!course) return
    const result: IQuestion[] = []
    course.info_fields.forEach(question => {
      if (question.type !== "Checkbox") {
        const parsedAnswer = JSON.parse(question.props)
        if (parsedAnswer.options) {
          result.push({ id: question.id, title: question.title, answers: parsedAnswer.options })
        }
      }
    })
    setQuestionOptions(result)
  }

  const getAnswerOptions = () => {
    if (!activeQuestion) return
    const newAnswers = parseAnswers(activeQuestion)
    setAnswerOptions(newAnswers)
  }

  const parseAnswers = (question: IQuestion) => {
    return question?.answers?.map(answer => ({ title: answer, question: question }))
  }

  const onSelect = (type: "question" | "answer", option: IQuestion | IAnswer) => {
    if (type === "question" && "id" in option) {
      const isChecked = questions.find(item => item.id === option.id) || false
      const newQuestions = isChecked ? questions.filter(item => item.id !== option.id) : [...questions, option]
      setQuestions(newQuestions)
      const newAnswers = []
      if (isChecked) {
        newAnswers.push(...answers.filter(item => item.question.id !== option.id))
      } else {
        newAnswers.push(...answers, ...parseAnswers(option))
      }
      setAnswers(newAnswers)
    }
    if (type === "answer" && !("id" in option)) {
      const newAnswers =
        answers.find(answer => answer.title === option.title && answer.question.id === option.question.id) || false
          ? answers.filter(answer => answer.title !== option.title || answer.question.id !== option.question.id)
          : [...answers, option]
      setAnswers(newAnswers)
      const newQuestions = []
      const isLeftAnswer = newAnswers.find(answer => answer.question.id === option.question.id)
      if (isLeftAnswer) {
        const isCheckedQuestion = questions.find(question => question.id === option.question.id) || false
        if (isCheckedQuestion) return
        newQuestions.push(...questions, questionOptions.find(question => question.id === option.question.id)!)
      } else {
        newQuestions.push(...questions.filter(item => item.id !== option.question.id))
      }
      setQuestions(newQuestions)
    }
  }

  return {
    questionOptions,
    answerOptions,
    activeQuestion,
    onSelect,
    setActiveQuestion,
  }
}
