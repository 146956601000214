import styled from "@emotion/styled"
import DownloadStatisticsTable from "features/Organization/DownloadStatisticsTable"
import { useEffect, useState } from "react"
import { Dropdown, TextField } from "shared/ui"
import { statusEnum } from "entities/Course/lib/CourseStatic.js"
import {
  personalFilters,
  requestsFilters,
  statusesFilters,
  surveyFilters,
} from "entities/Organization/static/statistics"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import useDebounce from "shared/hooks/useDebounce"

interface IProps {
  type: StatisticsVariants
  submitTrigger: boolean
  handleFilters?: any
  onDownload: (value: number | "all") => void
  isDownloading: boolean
}

const OrganizationStatisticsFilters = ({ type, submitTrigger, handleFilters, onDownload, isDownloading }: IProps) => {
  const [filter, setFilter] = useState<any>(null)
  const [value, setValue] = useState(null)
  const debouncedhandleFilters = useDebounce(handleFilters, 500)

  useEffect(() => {
    if (submitTrigger) {
      setFilter(null)
      setValue(null)
    }
  }, [submitTrigger])

  const handleFilter = (e: any) => {
    setFilter(e.target)
    if (e.target.value !== filter?.value && value) {
      setValue(null)
      debouncedhandleFilters({})
    }
  }

  const handleType = (e: any) => {
    value !== e.target.value && debouncedhandleFilters({ [filter?.value]: e.target.value })
    setValue(e.target.value)
  }

  const options = () => {
    if (type === "requests") return requestsFilters
    if (type === "statuses") return statusesFilters
    if (type === "survey") return surveyFilters
    else if (type === "personal") return personalFilters
    return {}
  }
  const participantStatuses = type === "requests" ? {
    "": "",
    failed: "failed",
    in_progress: "in_progress",
    requested: "requested",
    banned: "banned"
  } : { ...statusEnum, requested: "requested", banned: "banned" }

  const participantActivityStatuses = { active: "true", inactive: "false" }

  return (
    <Wrapper>
      <Title>Фильтры</Title>
      <FiltersWrapper>
        <Filters>
          <Dropdown
            id={"invite-filter--creterion"}
            name={"criterion"}
            value={filter?.value || undefined}
            onChange={handleFilter}
            options={options()}
            label={"Параметр фильтра"}
            width={280}
          />
          {filter?.label === "Статус" && (
            <Dropdown
              id={"invite-filter--value"}
              variant="outlined"
              name={"participant__value"}
              // @ts-ignore
              value={value}
              onChange={handleType}
              disabled={!filter?.value}
              options={participantStatuses}
              label={"Значение"}
              size={"small"}
              width={280}
            />
          )}
          {filter?.label === "Статус активности" && (
            <Dropdown
              id={"status-filter--value"}
              variant="outlined"
              name={"participant__activity-value"}
              // @ts-ignore
              value={value}
              onChange={handleType}
              disabled={!filter?.value}
              options={participantActivityStatuses}
              label={"Значение"}
              size={"small"}
              width={280}
            />
          )}
          {filter?.label !== "Статус" && filter?.label !== "Статус активности" && (
            <TextField
              key={filter?.value}
              id="activity-invite-filter--value"
              variant="outlined"
              name={"participant__value"}
              // @ts-ignore
              value={value}
              onChange={handleType}
              disabled={!filter?.value}
              label="Значение"
              size={"small"}
              width={280}
            />
          )}
        </Filters>
        <DownloadStatisticsTable type={type} isDownloading={isDownloading} onDownload={onDownload} />
      </FiltersWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-block: 12px 40px;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 20.7px;
  letter-spacing: 0.2px;
`

const FiltersWrapper = styled.div`
  margin-top: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Filters = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`

export { OrganizationStatisticsFilters }
