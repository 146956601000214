import React, { useEffect, useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import { useTimer } from "react-timer-hook"
import { formatPlayerTime, parseTime, timerRestart } from "../lib/lib"
import { LinearProgress, Typography, Slider } from "@mui/material"
import Audio from "../mock/testAudio.mp3"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"

export const AudioPlayer = ({ audio = Audio }: { audio?: string }) => {
  const [showPlayer, setShowPlayer] = useState(false)
  const [volume, setVolume] = useState(0.5) // Добавляем состояние для громкости
  const audioRef = useRef<HTMLAudioElement>(null)
  const timerState = useTimer({
    expiryTimestamp: parseTime("00:00:30"),
    autoStart: false,
    onExpire: () => {
      setShowPlayer(true)
    },
  })

  useEffect(() => {
    if (showPlayer) {
      audioRef.current?.play()
    }
  }, [showPlayer])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume // Применяем громкость к аудио элементу
    }
  }, [volume])

  function startTimer() {
    timerRestart([0, 0, 30], timerState)
  }

  useEffect(() => {
    startTimer()
  }, [])

  return (
    <MainAudioContainer>
      <audio style={{ display: "none" }} ref={audioRef} autoPlay={false}>
        <source src={audio} />
      </audio>
      {!showPlayer && (
        <>
          <Typography sx={{ fontSize: "16px", fontWeight: "400", color: "#0444A3" }}>
            Воспроизведение начнется через
          </Typography>
          <Typography sx={{ fontSize: "24px", fontWeight: "500", color: "#0444A3" }}>{timerState.seconds}</Typography>
        </>
      )}
      {showPlayer && (
        <>
          <StyledAudio>
            <LinearProgress
              sx={{ width: "60%", color: "#FFF", backgroundColor: "#000" }}
              variant="determinate"
              color={"secondary"}
              value={((audioRef.current?.currentTime || 0) * 100) / (audioRef.current?.duration || 0)}
            />
            <Typography sx={{ fontSize: "16px", fontWeight: "400", color: "#FFF" }}>
              {formatPlayerTime(audioRef.current?.currentTime || 0)} /{" "}
              {formatPlayerTime(audioRef.current?.duration || 0)}
            </Typography>
            <VolumeContainer>
              <VolumeUpIcon sx={{ color: "#FFF", height: "24px", width: "24px" }} />
              <Slider
                style={{ width: "100%", color: "#FFF" }}
                color={"primary"}
                value={volume * 100}
                onChange={(e, newValue) => setVolume((newValue as number) / 100)}
              />
            </VolumeContainer>
          </StyledAudio>
        </>
      )}
    </MainAudioContainer>
  )
}

const MainAudioContainer = styled("div")(({ theme }) => ({
  width: "100%",
  background: "#F0F2F9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: theme.spacing(4),
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  borderRadius: theme.spacing(1),
}))

export const StyledAudio = styled("div")(({ theme }) => ({
  width: "80%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: "#474544",
}))

export const VolumeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  width: "20%",
}))
