import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    backgroundColor: "#fff",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    padding: 38,
    maxWidth: "100vw",
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    marginRight: theme.spacing(2),
  },
  defaultRadio: {
    color: "#536DFE",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#536DFE",
    },
  },
  successRadio: {
    color: "#23a075",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#23a075",
    },
  },
  secondaryRadio: {
    color: "#FF5C93",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#FF5C93",
    },
  },
  warningRadio: {
    color: "#FFC260",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#FFC260",
    },
  },
  infoRadio: {
    color: "#9013FE",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#9013FE",
    },
  },
  button: {
    boxShadow: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    textTransform: "none",
    "&:active": {
      boxShadow: "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
  },
  ecommerceIcon: {
    color: "#6E6E6E",
  },
  calendarIcon: {
    color: theme.palette.primary.main,
    marginRight: 14,
  },
  margin: {
    marginBottom: 24,
  },
  changeThemeFab: {
    position: "fixed",
    top: theme.spacing(15),
    right: 0,
    zIndex: 1,
    borderRadius: 0,
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  navPadding: {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `6px !important`,
  },
  date: {
    marginRight: 38,
    color: theme.palette.mode === "dark" ? "#D6D6D6" : "#4A494A",
  },
}))
