export const TableArrowLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M4.3769 14.5869C4.00236 14.9619 3.79199 15.4703 3.79199 16.0003C3.79199 16.5303 4.00236 17.0386 4.3769 17.4136L11.9182 24.9589C12.2934 25.3341 12.8023 25.5449 13.3329 25.5449C13.8635 25.5449 14.3724 25.3341 14.7476 24.9589C15.1228 24.5838 15.3335 24.0749 15.3335 23.5443C15.3335 23.0137 15.1228 22.5048 14.7476 22.1296L10.6196 18.0003L25.9996 18.0003C26.53 18.0003 27.0387 17.7896 27.4138 17.4145C27.7889 17.0394 27.9996 16.5307 27.9996 16.0003C27.9996 15.4698 27.7889 14.9611 27.4138 14.5861C27.0387 14.211 26.53 14.0003 25.9996 14.0003L10.6196 14.0003L14.7476 9.87228C14.9333 9.6865 15.0807 9.46596 15.1812 9.22323C15.2818 8.9805 15.3335 8.72034 15.3335 8.45761C15.3335 8.19489 15.2818 7.93473 15.1812 7.692C15.0807 7.44927 14.9333 7.22872 14.7476 7.04295C14.5618 6.85717 14.3412 6.7098 14.0985 6.60926C13.8558 6.50872 13.5956 6.45697 13.3329 6.45697C13.0702 6.45697 12.81 6.50872 12.5673 6.60926C12.3246 6.7098 12.104 6.85717 11.9182 7.04295L4.3769 14.5869Z"
        fill="white"
      />
    </svg>
  )
}
