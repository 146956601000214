import { InvitesParticipantFilters } from "entities/Organization/ui/InvitesParticipantFilters"
import { ParticipantsCard } from "entities/Organization/ui/ParticipantsCard"
import { TableTotalCount as ParticipantsCount } from "shared/assets"
import { useAppSelector } from "shared/hooks/useAppSelector"
import ParticipantsTable from "entities/Organization/ui/ParticipantsTable"
import useParticipants from "../model/useParticipants"
import { PageLocationType } from "./ParticipantsInProgress"
import { courseKeyDefault, CourseKeyType } from "../../../shared/store/course/CourseSlice"
//@ts-nocheck

interface IProps {
  isTriggered: boolean
  setIsTriggered: (x: boolean) => void
  pageLocation: PageLocationType
  courseKey: CourseKeyType
}

const ParticipantsOther = ({ isTriggered, setIsTriggered, pageLocation, courseKey = courseKeyDefault }: IProps) => {
  const status = "other"
  const { isLoading, participants, total, hasNextPage, headers, handleSort, handleFilters, fetchParticipants } =
    useParticipants({pageLocation, status, isTriggered, setIsTriggered, courseKey})
  const course = useAppSelector(state => state.course[courseKey])

  if (!course?.id) return

  return (
    <ParticipantsCard title="Другие пользователи на курсе">
      <div>
        <InvitesParticipantFilters handleFilters={handleFilters} />
        <ParticipantsCount
          totalCount={total || 0}
          filterCount={participants.length}
          totalLabel={"Всего участников, соответствующих фильтру: "}
        />
        <ParticipantsTable
          status={status}
          headers={headers}
          participants={participants}
          setSortBy={handleSort}
          isLoading={isLoading}
          loadMore={fetchParticipants}
          hasNextPage={hasNextPage}
          isAccessButtons={false}
          courseKey={courseKey}
        />
      </div>
    </ParticipantsCard>
  )
}

export { ParticipantsOther }
