import {makeStyles} from "@mui/styles";

export default makeStyles(theme => (
    {

        checkButton: {
            cursor: "pointer",
            appearance: "none",
            borderRadius: "5px",
            background: "#ffffff",
            float: "left",
            color: "#1565C0",
            width: "164px",
            height: "34px",
            fontSize: "18px",
            border: "1px solid #1565C0",
        },

    })
)
