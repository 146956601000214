import * as Styled from "./styles/EditStudentProgress.styles"
import { Autocomplete, TextField } from "shared/ui"
import { Size, Variant } from "shared/ui/styles.const"
import { CircularProgress } from "@mui/material"
import { AutocompleteEvent, OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { IExtendFilters, IExtendFilter } from "shared/hooks/useExtendFiltersForm"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { getParticipantProgress } from "../api/assignment"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import { useEffect } from "react"
import { assignmentsActions } from "shared/store"
import { useDispatch } from "react-redux"
import { assignmentFiltersSchema } from "entities/Assignment/lib/assignmentFilters"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller } from "react-hook-form"
import useGetOptions, { PickedCourse } from "entities/Assignment/model/useGetOptions"
import useDebounce from "shared/hooks/useDebounce"

interface IProps {
  filters: IExtendFilters
  progress: IParticipantProgress | undefined
  handleExtendFilters: (x: { target: IExtendFilter }) => void
  resetFilters: () => void
  setProgress: (x: IParticipantProgress | undefined) => void
}

const StudentProgressFilters = ({ filters, progress, handleExtendFilters, resetFilters, setProgress }: IProps) => {
  const methods = useForm({ resolver: yupResolver(assignmentFiltersSchema) })
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
    clearErrors,
  } = methods
  const { stages, modules, isLoading, setIsLoading, handleLoadingOptions, filterModules } = useGetOptions(true)
  const dispatch = useDispatch()
  const debouncedFetch = useDebounce(fetchProgress, 500)
  const storedCourses = useAppSelector(state => state.course.courses).filter(
    (course: PickedCourse) => course.status === "moderator"
  )
  const courses = [{ id: null, value: null, label: "Не выбрано" }, ...storedCourses]

  useEffect(() => {
    dispatch(assignmentsActions.setModules(modules))
  }, [modules])

  const handleCourse = async (event: AutocompleteEvent, course: OptionType | null) => {
    if (!course || course === null) return
    resetFilters()
    handleExtendFilters({ target: { value: course.value, name: "course", label: String(course.label), id: course.id } })
    handleLoadingOptions(course, false)
    setValue("email", "", { shouldValidate: false })
    clearErrors()
    setProgress(undefined)
  }

  const handleResetCourse = () => {
    reset()
    resetFilters()
    clearErrors()
    setProgress(undefined)
  }

  const handleParticipant = (event: React.ChangeEvent<HTMLInputElement>, field: any) => {
    const trimmedValue = event.target.value.replace(/\s/g, "")
    const value = trimmedValue.toLowerCase()
    handleExtendFilters({ target: { value: value, name: "email" } })
    field.onChange(value)
    if (value) handleSubmit(() => debouncedFetch(value, filters.course?.id))()
    else setProgress(undefined)
  }

  async function fetchProgress(participantEmail: string, courseId: number) {
    try {
      setIsLoading(true)
      const response = await getParticipantProgress({
        participant__course__id: courseId,
        participant__user__email: participantEmail || "",
      })
      if (response === undefined)
        setError("email", {
          type: "manual",
          message: "Пользователь не зарегистрирован или не является участником этого мероприятия.",
        })
      setProgress(response)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const handleStage = async (event: { target: OptionType | null }) => {
    if (!event.target || event.target === null) return
    handleExtendFilters({
      target: { value: event.target.value, name: String(event.target.name), label: String(event.target.label) },
    })
    handleExtendFilters({
      target: { value: "", name: "module", label: "" },
    })
  }

  return (
    <FormProvider {...methods}>
      <Styled.FiltersWrapper>
        <Styled.Filters>
          <Autocomplete
            size={Size.large}
            id="assignment__edit__choise"
            value={filters?.course?.label}
            onChange={handleCourse}
            onReset={handleResetCourse}
            options={courses}
            label={"Название курса"}
          />
          <Controller
            name={"email"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                key={filters?.course?.label}
                variant={Variant.outlined}
                onChange={e => handleParticipant(e, field)}
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message}
                disabled={!filters?.course}
                label={"Почта"}
                disableTooltip
              />
            )}
          />
          <Autocomplete
            name={"stage"}
            key={filters?.course?.id}
            id={"assignment__edit__stage"}
            // @ts-ignore
            value={filters?.stage?.value}
            filterChange={handleStage}
            // @ts-ignore
            options={stages}
            label="Этап"
            disabled={isLoading || !progress}
            onReset={handleStage}
          />
          <Autocomplete
            name={"module"}
            key={filters?.stage?.label}
            id="assignment__edit__module"
            // @ts-ignore
            value={filters?.module?.value}
            filterChange={handleExtendFilters}
            // @ts-ignore
            options={filterModules(modules, Number(filters?.stage?.value))}
            label="Название модуля"
            disabled={isLoading || !progress || !filters?.stage}
            onReset={handleExtendFilters}
          />
        </Styled.Filters>
        <Styled.LoaderWrapper>{isLoading && <CircularProgress size={26} />}</Styled.LoaderWrapper>
      </Styled.FiltersWrapper>
    </FormProvider>
  )
}

export default StudentProgressFilters
