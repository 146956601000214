import React, { useEffect, useRef, useState } from "react"
import { ChoiseCourse } from "./ui/ChoiseCourse"
import * as Styled from "./ui/AdminTeams.style.ts"
import { SectionWrapper, ShadowedSection } from "shared/ui"
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material"
import { SetTeamStatus } from "../../features"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
import { TeamsTable } from "./ui/TeamsTable"
import { useDispatch, useSelector } from "react-redux"
import { teamActions } from "shared/store"
import { SettingTeams } from "../../features/Teams/SettingTeams"
import Cookies from "js-cookie"

export const AdminTeams = ({ status }) => {
  const [courseForSettings, setCourseForSettings] = useState(null)
  const [course, setCourse] = useState(null)
  const [page, setPage] = useState(1)
  const [errors, setErrors] = useState({})
  const [hasNextPage, setHasNextPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isModerated, setIsModerated] = useState(false)
  const [settingsLoading, setSettingsLoading] = useState(false)
  const checkBoxRef = useRef()
  const requests = useSelector(state => state.team.requests)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!course) loadFirst(course.id)
  }, [isModerated])

  const handleScrolledToBottom = isBottomVisible => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      loadMore()
    }
  }

  const handleCheckboxChange = event => {
    setIsModerated(event.target.checked)
    setPage(1)
  }

  function loadFirst(id) {
    setIsLoading(true)
    axios
      .get(`/content/teams/`, {
        params: {
          page: 1,
          course: id,
          is_moderated: isModerated ? null : false,
        },
      })
      .then(res => {
        dispatch(teamActions.setRequests(res.data.results))
        setHasNextPage(!!res.data.next)
        setPage(prev => prev + 1)
      })
      .catch(err => {
        dispatch(teamActions.setRequests([]))
      })
      .finally(setIsLoading(false))
  }

  function loadMore() {
    setIsLoading(true)
    axios
      .get(`/content/teams/`, {
        params: {
          page: page,
          course: course?.id,
          is_moderated: isModerated ? null : true,
        },
      })
      .then(res => {
        dispatch(teamActions.setRequests([...requests, ...res.data.results]))
        setIsLoading(false)
        setHasNextPage(!!res.data.next)
        setPage(prev => prev + 1)
      })
      .finally(setIsLoading(false))
  }

  async function updateCourseSettings(data) {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .put(`/content/courses/${courseForSettings.id}/`, {
        team_props: JSON.stringify(data),
        slug: courseForSettings.slug,
        title: courseForSettings.title,
        id: courseForSettings.id,
        is_for_teams: true,
        owner: courseForSettings.owner,
        template_props: courseForSettings.template_props,
      })
      .then(res => {
        setCourseForSettings(res.data)
        setErrors({})
      })
      .catch(err => {
        setCourseForSettings({ ...courseForSettings })
        if (err.response.status === 406)
          setErrors({
            members_categories: "Вы не можете удалить данную категорию.",
          })
      })
  }

  async function refreshTeamsProgress() {
    setSettingsLoading(true)
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    setTimeout(async () => {
      await axios.put(`/content/courses/${courseForSettings.id}/refresh_teams_progress/`).then(res => {
        setSettingsLoading(false)
      })
    }, 11000)
  }

  return (
    <Styled.MainDiv>
      <ChoiseCourse
        status={status}
        title={"Выбор курса"}
        course={course}
        setCourse={setCourse}
        isLoading={false}
        setIsModerated={setIsModerated}
        loadRequests={loadFirst}
      />
      {!!course && (
        <>
          <SectionWrapper>
            <Typography variant={"h4"}>{`Курс: "${course?.title}"`}</Typography>
            <Typography
              variant={"body1"}
              fontWeight={"small"}
              fontSize={"medium"}
            >{`ID курса: ${course?.id}`}</Typography>
          </SectionWrapper>
          <ShadowedSection>
            <SetTeamStatus course={course} />
          </ShadowedSection>
          <ShadowedSection>
            <Styled.TableBlockWrapped>
              <Typography variant={"h4"}>Заявки на создание команды</Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isModerated} ref={checkBoxRef} onChange={handleCheckboxChange} />}
                  label={
                    <Typography variant={"caption"} fontSize={"small"} fontWeight={"small"}>
                      Отображать архив заявок
                    </Typography>
                  }
                />
              </FormGroup>
              <TeamsTable handleScrolledToBottom={handleScrolledToBottom} isLoading={isLoading} />
            </Styled.TableBlockWrapped>
          </ShadowedSection>
        </>
      )}
      {(status === "admin" || status === "moderator") && (
        <>
          <ChoiseCourse
            status={status}
            setCourse={setCourseForSettings}
            isLoading={settingsLoading}
            title={"Настройка командных параметров курса"}
          />
          {!!courseForSettings && !!courseForSettings.is_for_teams && (
            <>
              <SettingTeams
                errors={errors}
                updateCourseSettings={updateCourseSettings}
                course={courseForSettings}
                refreshTeamsProgress={refreshTeamsProgress}
              />
            </>
          )}
          {!!courseForSettings && !courseForSettings.is_for_teams && !settingsLoading && (
            <Typography variant={"caption"} fontSize={"medium"} fontWeight={"medium"}>
              Данный курс не предназначен для команд
            </Typography>
          )}
        </>
      )}
    </Styled.MainDiv>
  )
}
