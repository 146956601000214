export const downloadIcon = () => {
  return (
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none">\n' +
    '<path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="#313439"/>\n' +
    "</svg>"
  )
}

export const userSettingsIcon = () => {
  return (
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">\n' +
    '<rect width="32" height="32" rx="4" fill="#C7C7C7"/>\n' +
    '<path d="M18.5047 11.6664H5.33398C5.06877 11.6664 4.81441 11.561 4.62688 11.3735C4.43934 11.186 4.33398 10.9316 4.33398 10.6664C4.33398 10.4012 4.43934 10.1468 4.62688 9.95929C4.81441 9.77176 5.06877 9.6664 5.33398 9.6664H18.5047C18.7117 9.08148 19.0949 8.5751 19.6016 8.21696C20.1083 7.85881 20.7135 7.6665 21.334 7.6665C21.9545 7.6665 22.5597 7.85881 23.0664 8.21696C23.5731 8.5751 23.9563 9.08148 24.1633 9.6664H26.6673C26.9325 9.6664 27.1869 9.77176 27.3744 9.95929C27.562 10.1468 27.6673 10.4012 27.6673 10.6664C27.6673 10.9316 27.562 11.186 27.3744 11.3735C27.1869 11.561 26.9325 11.6664 26.6673 11.6664H24.1633C23.9563 12.2513 23.5731 12.7577 23.0664 13.1158C22.5597 13.474 21.9545 13.6663 21.334 13.6663C20.7135 13.6663 20.1083 13.474 19.6016 13.1158C19.0949 12.7577 18.7117 12.2513 18.5047 11.6664ZM26.6673 22.3331C26.9325 22.3331 27.1869 22.2277 27.3744 22.0402C27.562 21.8526 27.6673 21.5983 27.6673 21.3331C27.6673 21.0679 27.562 20.8135 27.3744 20.626C27.1869 20.4384 26.9325 20.3331 26.6673 20.3331H13.4967C13.2896 19.7481 12.9064 19.2418 12.3997 18.8836C11.893 18.5255 11.2878 18.3332 10.6673 18.3332C10.0468 18.3332 9.44161 18.5255 8.93493 18.8836C8.42824 19.2418 8.04502 19.7481 7.83798 20.3331H5.33398C5.06877 20.3331 4.81441 20.4384 4.62688 20.626C4.43934 20.8135 4.33398 21.0679 4.33398 21.3331C4.33398 21.5983 4.43934 21.8526 4.62688 22.0402C4.81441 22.2277 5.06877 22.3331 5.33398 22.3331H7.83798C8.0444 22.9186 8.4274 23.4257 8.93416 23.7843C9.44091 24.143 10.0465 24.3356 10.6673 24.3356C11.2882 24.3356 11.8937 24.143 12.4005 23.7843C12.9072 23.4257 13.2902 22.9186 13.4967 22.3331H26.6673Z" fill="white"/>\n' +
    "</svg>"
  )
}
