import { useCallback, useState } from "react"

export const useCourseForm = () => {
  const [fieldValue, setFieldValue] = useState({})

  const handleFieldValue = useCallback(event => {
    if (event.target.name === "module" && event.target.value === "") {
      setFieldValue(prev => ({ ...prev, rule: "", mark_value: "" }))
    }
    if (event.target.value === "" || event.target.value === null) {
      setFieldValue(prev => ({ ...prev, [event.target.name]: null }))
    } else {
      setFieldValue(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
  }, [])

  return { fieldValue, handleFieldValue, setFieldValue }
}
