import React, { useEffect, useState } from "react"

import { Box, CardMedia, Chip, Grid, Button, Link, Typography } from "@mui/material"

import useStyles from "./styles"

//components
import { Accordion, Card } from "shared/ui"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

const CourseMainPage = props => {
  const classes = useStyles()

  const [content, setContent] = useState(null)
  const [accordionContent, setAccordionContent] = useState(null)
  const [helperText, setHelperText] = React.useState("")
  const [permission, setPermission] = useState(null)

  const [link, setLink] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    const ref_token = localStorage.getItem("token_ref")
    if (!!ref_token) {
      axios
        .get(`/content/actions/courses/my_status/`, {
          params: {
            course__slug: props.match.params.name,
          },
        })
        .then(r => {
          axios
            .get(`/content/courses/`, {
              params: {
                slug: props.match.params.name,
              },
            })
            .then(p => {
              axios.get(`/content/courses/${p.data[0].id}/`).then(res => {
                let data = res.data
                data.template_props = JSON.parse(data.template_props)
                let status = r.data.status
                setContent(data)
                if (data.template_props) {
                  setAccordionContent(data.template_props.description)
                }
                let currentPermission = status == "in_progress" || status == "teacher" || status == "moderator"
                setPermission(currentPermission)
                if (currentPermission) {
                  axios
                    .get("/content/modules/", {
                      params: { stage: res.data.stages[0].id, course: res.data.stages[0].course },
                    })
                    .then(m => {
                      if (!!m.data[0]) setLink(`/module/${m.data[0].id}/`)
                    })
                }
                if (r.data.status == "requested") {
                  setHelperText("Заявка рассматривается.")
                }
              })
            })
        })
    } else {
      delete axios.defaults.headers.common["Authorization"]
      axios
        .get(`/content/courses/`, {
          params: {
            slug: props.match.params.name,
          },
        })
        .then(p => {
          axios.get(`/content/courses/${p.data[0].id}/`).then(res => {
            let data = res.data
            data.template_props = JSON.parse(data.template_props)
            setContent(data)
            setAccordionContent(data.template_props.description)
          })
        })
    }
  }, [])

  function requestAccess() {
    const ref_token = localStorage.getItem("token_ref")
    if (!ref_token) {
      setHelperText("Пожалуйста, зарегистрируйтесь или войдите в аккаунт.")
      return
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .put(`/content/actions/participants/request_access/`, { course: content.id })
      .then(res => {
        setHelperText("Ваша заявка принята. Спасибо!")
      })
      .catch(err => {
        setHelperText("Упс... что-то пошло не так. Пожалуйста, свяжитесь с администрацией.")
      })
  }

  if (!content) return <></>

  return (
    <>
      <Grid container spacing={3} width="90vw" style={{ margin: "auto", maxWidth: 1280 }}>
        <Grid item xs={12}>
          <Card>
            <Grid container>
              <Grid item md={6} xs={12}>
                <CardMedia image={content.image} style={{ width: "100%", height: "100%", minHeight: 320 }} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  m={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={{ height: "calc(100% - 48px)" }}
                >
                  <Box style={{ margin: "4%" }}>
                    <>
                      <Typography variant="h3" style={{ fontWeight: "bold", marginBottom: 16 }}>
                        {content.title}
                      </Typography>
                      <Chip label={"Курс"} color={"secondary"} />
                      <Typography>
                        <div
                          className={classes.contentText}
                          style={{ maxWidth: 800 }}
                          dangerouslySetInnerHTML={{ __html: content.description }}
                        ></div>
                      </Typography>
                    </>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {permission ? (
                      <Button component={Link} href={link} color="primary" variant="contained" style={{ width: "30%" }}>
                        Перейти
                      </Button>
                    ) : helperText == "" ? (
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 20 }}>
                        <Button onClick={requestAccess} color="primary" variant="contained" style={{ width: 240 }}>
                          Записаться
                        </Button>
                      </div>
                    ) : (
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        {helperText}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {accordionContent && (
          <Grid item xs={12}>
            <Accordion content={accordionContent} permission={permission} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
              {content.affiliations_info &&
                content.affiliations_info.map(e => (
                  <div style={{ margin: 16, height: 75 }}>
                    <a href={e.website} target={"_blank"}>
                      <img src={e.logo} style={{ height: "100%", display: "block", margin: "auto" }} />
                    </a>
                  </div>
                ))}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default CourseMainPage
