import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
    achievBlock: {
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "column",
        padding:"0 0 60px 0 !important",
        gap: 20,
    },
    achievIcon: {
        width: 200,
        height: 200,
        borderRadius:200,
        textAlign: "center",
        position: "relative",
        "& div": {
            width: 80,
            height: 116,
            position: 'absolute',
            top: 42,
            left: 60,
        },  
        "& span": {
            width: "auto",
            height: "auto",
        },
    },
    achievTitle: {
        "& p": {
            fontFamily: "Roboto",
            fontSize: 18,
            lineHeight: '22px',
            fontWeight: 600,
            letterSpasing:0.08,
            width:200,
            /*padding:"20px 0 20px 0",*/
        },  
    },
    achievDate: {
        "& p": {
            fontFamily: "Roboto",
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 400,
            width:200,
            color:"#5E5C5C",
        },  
    },
}))
