import React from "react"
import { Chip, Typography } from "@mui/material"
import { useTheme } from "shared/context"


const colors = {
  moderator: "#D03FE8",
  observer: "#3EBD44",
  owner: "#FFA000"
}

export const TimeChip = ({ label }: { label: "1 день" | "3 дня" | "7 дней" | "30 дней" | "1 год"  }) => {
  const theme = useTheme()

  return (
    <Chip
      sx={{ backgroundColor: theme.palette.success.main, width: theme.spacing(20) }}
      label={
        <Typography variant={"caption"} fontWeight={"small"} sx={{ color: "#FFF" }}>
          {label}
        </Typography>
      }
    />
  )
}