import { CircularProgress } from "@mui/material"
import * as Styled from "entities/Organization/ui/styles/SummaryTemplate.styles"
import { StatisticsVariants } from "../model/StatisticsContext.types"
import { RequestsAndStatusesOptionsType } from "../ui/Statistics/chartsOptions.types"

export const requestsChartsOptions: RequestsAndStatusesOptionsType = [
  { value: "scatter", label: "График" },
  { value: "pie", label: "Круговая" },
  { value: "bar", label: "Столбчатая" },
]
export const statusesChartsOptions: RequestsAndStatusesOptionsType = [
  { value: "pie", label: "Круговая" },
  { value: "bar", label: "Столбчатая" },
]

export const dataListTooltip = {
  question:
    "В списке отображены только вопросы с вариантами ответа. При нажатии на строку с вопросом можете ознакомиться с вариантами ответа на выбранный вопрос.",
  answer:
    "В списке отображены все варианты ответов на вопрос. Можете  самостоятельно выбрать, какие варианты ответов учитывать в Сводке.",
}

export const summaryTemplateTabs = [
  { title: "Список данных", value: 0, enable: true },
  { title: "Данные по статусам", value: 1, enable: false },
]

const requestsHeaders = [
  { label: "ФИО", value: "last_name" },
  { label: "Почта", value: "email" },
  { label: "Статус пользователя", value: "status" },
  { label: "Категория", value: "category" },
  { label: "Статус активности", value: "is_active" },
]

const statusesHeaders = [
  { label: "ФИО", value: "last_name" },
  { label: "Почта", value: "email" },
  { label: "Статус пользователя", value: "status" },
  { label: "Категория", value: "category" },
  { label: "Статус активности", value: "is_active" },
]

const surveyHeaders = [
  { label: "ФИО", value: "last_name" },
  { label: "Почта", value: "email" },
  { label: "Статус", value: "status" },
  { label: "Категория", value: "category" },
  { label: "Статус активности", value: "is_active" },
]

const personalHeaders = [
  { label: "Статус", value: "status" },
  { label: "Категория", value: "category" },
  { label: "Статус активности", value: "is_active" },
  { label: "Название мероприятия", value: "course" },
  { label: "Успеваемость ученика", value: "" },
]

export const generalLegendLabels = {
  total: "мероприятия, в которых участвовал пользователь",
  organization: "мероприятия Вашей Организации",
  other: "мероприятия других Организаций",
}

export const infoLegendLabels = {
  organization: "Ваши мероприятия",
  other: "Мероприятия других орг-й",
}

export const generalLegendColors = {
  total: "#313439",
  organization: "#f2890c",
  other: "#c28c6d",
}

export const generalInfoLegendLabels = {
  activity: "Активность ученика на мероприятиях платформы",
}

export const infoLegendColors = {
  organization: "#f2890c",
  other: "#c28c6d",
}
export const requestsFilters = {
  "": "",
  "ФИО": "last_name",
  "Почта": "email",
  "Статус": "participant_status",
  "Категория": "category",
  "Статус активности": "is_active",
}
export const statusesFilters = {
  "": "",
  "ФИО": "last_name",
  "Почта": "email",
  "Статус": "participant_status",
  "Категория": "category",
  "Статус активности": "is_active",
}
export const surveyFilters = {
  "": "",
  "ФИО": "full_name",
  "Почта": "email",
  "Статус": "status",
  "Категория": "category",
  "Статус активности": "is_active",
}

export const personalFilters = {
  "": "",
  "Статус": "status",
  "Категория": "category",
  "Название мероприятия": "course",
  "Статус активности": "is_active",
}

export const pickTotalCounterLabel = (type: StatisticsVariants) => {
  if (type !== "personal") {
    return "Всего участников, соответствующих фильтру:"
  } else if (type === "personal") {
    return "Всего мероприятий, соответствующих фильтру:"
  }
}

export const pickCurrentCounterLabel = (type: StatisticsVariants) => {
  if (type === "survey") {
    return undefined
  } else if (type === "personal") return "Прогружено записей в таблице:"
}


export const requestsDownloadOptions = [
  { value: "all", label: "Все заявки по выборке", fileName: "все заявки", download_type: "total" },
]
export const statusesDownloadOptions = [
  { value: "all", label: "Данные всех участников по выборке", fileName: "все участники", download_type: "total" },
  {
    value: 1,
    label: "Только по Модераторам и Преподавателям",
    fileName: "модераторы и преподаватели",
    download_type: "staff",
  },
  { value: 2, label: "Только по активным участникам", fileName: "активные участники", download_type: "active" },
  { value: 3, label: "Только по другим пользователям", fileName: "другие пользователи", download_type: "other" },
]
export const personalDownloadOptions = [{ value: "total", label: "Активность ученика на мероприятиях по выборке" }]

export const Loader = () => (
  <Styled.LoadingLabel>
    <CircularProgress size={26} /> Пожалуйста, подождите
  </Styled.LoadingLabel>
)

export const StartPage = ({ page }: { page: "requests" | "statuses" | "survey" | "activity" }) => {
  const getLabel = () => {
    if (page === "requests" || page === "statuses") {
      return "Чтобы начать работу, выберите, по какому мероприятию вы хотите посмотреть информацию"
    }
    if (page === "survey") {
      return "Чтобы начать работу, выберите, по какому мероприятию вы хотите посмотреть сводку"
    }
    if (page === "activity") {
      return "Введите почту пользователя и нажмите “Подтвердить”, чтобы увидеть информацию"
    }
  }
  return <Styled.InitialLabel>{getLabel()}</Styled.InitialLabel>
}

export const EmptyPage = () => {
  return (
    <Styled.InitialLabel>
      <Styled.EmptyLabel>{"Данный пользователь не участвовал в мероприятиях Вашей Организации."}</Styled.EmptyLabel>
    </Styled.InitialLabel>
  )
}

export const getStatisticsHeaders = (type: StatisticsVariants): { label: string; value: string }[] => {
  if (type === "requests") return requestsHeaders
  if (type === "statuses") return statusesHeaders
  if (type === "survey") return surveyHeaders
  if (type === "personal") return personalHeaders
  return [
    {
      label: "",
      value: "",
    },
  ]
}

export const newDiagramColors = [
  ["#4a01b3", "#6e34c2", "#9267d1", "#b799e1", "#c9b3e8", "#dbccf0", "#ede6f7"],
  ["#6f2cff", "#8c56ff", "#a980ff", "#c6aaff", "#d4c0ff", "#e2d5ff", "#f1eaff"],
  ["#b3029b", "#c235af", "#d167c3", "#e19ad7", "#e8b3e1", "#f0cceb", "#f7e6f5"],
  ["#2b2eff", "#5558ff", "#8082ff", "#aaabff", "#bfc0ff", "#d5d5ff", "#eaeaff"],
  ["#9507c7", "#aa38d2", "#bf6add", "#d59ce8", "#dfb5ee", "#eacdf4", "#f4e6f9"],
]

export const newSunBurstColors: { [key: string]: string } = {
  "Получил сертификат": "#2ead58",
  "Заявка отклонена": "#ff564e",
  "Начал, но не прошел": "#f2bf0c",
  "Выбыл": "#6b6d70",
  "Проходит сейчас": "#3483F6",
  "Заблокирован": "#ff9790",
  "Модератор или преподаватель": "#d3d4d4",
}

export const getDiagramColor = (colorIndex: number, shadeIndex: number): string => {
  const existingColorIndex = colorIndex >= newDiagramColors.length ? newDiagramColors.length - 1 : colorIndex
  const existingShadeIndex = shadeIndex >= newDiagramColors[existingColorIndex].length ? newDiagramColors[existingColorIndex].length - 1 : shadeIndex
  return newDiagramColors[existingColorIndex][existingShadeIndex]
}
