export function stringifyDates(type: "date" | "time", date: string): string {
  let dateObj = new Date(date)

  let timeZoneOffset = new Date().getTimezoneOffset()

  if (type === "date") {
    const options = { day: "numeric", month: "long" }
    return dateObj.toLocaleDateString("ru-RU", options as Intl.DateTimeFormatOptions)
  } else {
    let date = new Date(dateObj.getTime() - timeZoneOffset * 1000 * 60)
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    return `${formattedHours}:${formattedMinutes}`
  }
}

export function compareDate(date: string) {
  let utc_now_date = new Date()
  let now_date = new Date(utc_now_date.getTime() + new Date().getTimezoneOffset() * 1000 * 60).getTime()
  let finishDate = new Date(date).getTime()
  console.log(new Date(utc_now_date))
  return now_date <= finishDate
}
