import * as Styled from "./ui/Team.style.ts"
import { useEffect, useState } from "react"
import { Tabs, Tab, Typography } from "@mui/material"
import { Invitations } from "./ui/Invitations.js"
import { UserTeams } from "./ui/UserTeams"

export const Teams = ({ props }) => {
  const [tab, setTab] = useState(0)
  const pathname = props.location.pathname

  useEffect(() => {
    if (pathname.includes("user-teams")) {
      setTab(0)
    }
    if (pathname.includes("invitations")) {
      setTab(1)
    }
  }, [pathname])

  const changeTab = (e, value) => {
    setTab(value)
  }

  return (
    <Styled.MainDiv>
      <Tabs value={tab} onChange={changeTab} aria-label="tabs">
        <Tab id={"TeamTab"} value={0} label={<Typography variant={"subtitle3"}>Мои команды</Typography>} />
        <Tab
          id={"InvitationTeamTab"}
          value={1}
          label={<Typography variant={"subtitle3"}>Приглашения и запросы</Typography>}
        />
      </Tabs>
      {tab === 0 && <UserTeams />}
      {tab === 1 && <Invitations />}
    </Styled.MainDiv>
  )
}
