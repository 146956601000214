import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "@mui/material"
import { useTab } from "shared/hooks/useTab"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { CourseStatistics, ParticipantStatistics, TeamStatistics } from "widgets/Statistics"

const Scores = () => {
  const [isStaff, setIsStaff] = useState(null)
  const { tab, changeTab } = useTab("statistic_subtab")

  useEffect(() => {
    axios.get("/content/actions/participants/is_staff/").then(res => {
      setIsStaff(res.data.is_staff)
    })
  }, [])

  if (!isStaff) return null

  return (
    <>
      <Tabs value={tab} onChange={changeTab} aria-label="tabs" sx={{ marginBottom: 8, marginInline: "auto" }}>
        <Tab label="Курсы" value={0} />
        <Tab label="Ученики" value={1} />
        <Tab label="Команды" value={2} />
      </Tabs>
      {tab === 0 ? <CourseStatistics /> : tab === 1 ? <ParticipantStatistics /> : <TeamStatistics />}
    </>
  )
}

export default Scores
