import * as Styled from "./styles/AssignmentTableRow.styles"
import ReviewAssignmentIcon from "images/icons/ReviewAssignment"
import SaveScoreIcon from "images/icons/SaveScore"
import SendAssignmentNotification from "../model/AssignmentNotification"
import useRowAnimation from "./styles/useRowAnimation"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Dropdown, IconButton } from "shared/ui"
import { AssignmentStatus, IAssingment } from "../model/assignment.types"
import { TableCell } from "shared/ui/TableTemplate/TableRow"
import { formatDate, toTimeZone } from "shared/util/functions"
import { saveAssignment } from "../api/assignment"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { assignmentsActions } from "shared/store"
import { tableStatusOptions as statusOptions } from "../static"
import { examinationHelpers as helpers } from "entities/Assignment/static/helpers"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { IFilters } from "shared/hooks/useFiltersForm"
import FilesCell from "./FilesCell"
import { createFeedbackNotification } from "entities/Notification/api/notifications"
import { ParticipantScoreSelector } from "features/Assignment/SetParticipantStatus"

interface IProps {
  assignment: IAssingment
  assignmentIndex: number
  setIsSubmitTrigger: (x: boolean) => void
}

const AssignmentTableRow = ({ assignment, assignmentIndex, setIsSubmitTrigger }: IProps) => {
  const [status, setStatus] = useState<string>(assignment.status)
  const initialScore = assignment.status === AssignmentStatus.accepted ? assignment.score : ""
  const [score, setScore] = useState(initialScore)
  const [isNotifyModal, setIsNotifyModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")
  const { rowRef, handleAnimation, collapsingTime } = useRowAnimation()
  const dispatch = useAppDispatch()
  const isDisableSubmit = ((score === "" || score === null) && status === AssignmentStatus.accepted) || isLoading
  const filters: IFilters = useAppSelector(state => state.assignment.filters)
  const filtersTrigger = JSON.stringify(filters)

  useEffect(() => {
    setMessage("")
  }, [score, status])

  useEffect(() => {
    setStatus(assignment.status)
    setScore(initialScore)
  }, [assignment])

  const onReview = useCallback(
    (initStatus: null | AssignmentStatus = null) => {
      if (isLoading) return
      dispatch(assignmentsActions.setAssignmentIndex(assignmentIndex))
      dispatch(assignmentsActions.setExamination({ isExamination: true, initStatus }))
    },
    [assignmentIndex]
  )

  const handleStatus = useCallback(
    (e: { target: { value: string | number; name?: string; label?: string | undefined } }) => {
      if (isLoading) return
      if (e.target.value === AssignmentStatus.rejected) {
        onReview(AssignmentStatus.rejected)
      }
      setStatus(String(e.target.value))
    },
    [onReview]
  )

  const handleScore = useCallback(
    (e: { target: { value: string | number; name?: string; label?: string | undefined } }) => {
      if (isLoading) return
      setScore(e.target.value)
    },
    []
  )

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    const feedback = status === AssignmentStatus.accepted ? helpers.accept : ""
    try {
      const payload = {
        ...assignment,
        score: Number(score),
        status: status as AssignmentStatus,
        feedback,
      }
      await saveAssignment(payload)
      setMessage("Сохранено")
      const isCollapse = filters.status === AssignmentStatus.submitted && status !== AssignmentStatus.submitted
      handleAnimation(isCollapse)
      dispatch(assignmentsActions.updateAssignments(payload))
      if (feedback) await createFeedbackNotification(feedback, assignment?.id, Number(filters?.course))
      setTimeout(() => {
        setIsLoading(false)
        if (isCollapse) {
          dispatch(assignmentsActions.filterAssignments())
          dispatch(assignmentsActions.decreaseTotalCount())
        }
      }, collapsingTime * 2 + 100)
      setIsSubmitTrigger(true)
    } catch {
      setMessage("Ошибка")
      setIsLoading(false)
    }
  }, [status, score, filtersTrigger])

  const memoSubmit = useMemo(
    () => (
      <IconButton
        id={`notification-${assignment.id}`}
        color={"inherit"}
        size={"medium"}
        onClick={onSubmit}
        disabled={isDisableSubmit}
      >
        <SaveScoreIcon />
      </IconButton>
    ),
    [status, score]
  )

  return (
    <>
      <SendAssignmentNotification
        assignmentId={assignment.id}
        courseId={assignment.course}
        isOpen={isNotifyModal}
        onClose={() => setIsNotifyModal(false)}
      />
      <Styled.TableRow key={assignment.id} ref={rowRef}>
        <TableCell width={96}>
          <IconButton
            id={`notification-${assignment.id}`}
            color={"inherit"}
            size={"medium"}
            onClick={() => !isLoading && setIsNotifyModal(true)}
            disabled={false}
          >
            <Styled.NotificationIcon />
          </IconButton>
        </TableCell>
        <TableCell value={assignment.participant.full_name} />
        <TableCell value={assignment.participant.email} />
        <TableCell value={assignment.block_title} width={200} />
        <TableCell width={200}>
          <FilesCell attachments={assignment.attachments} />
        </TableCell>
        <TableCell width={104}>
          <Styled.IconButton onClick={() => onReview()}>
            <ReviewAssignmentIcon />
          </Styled.IconButton>
        </TableCell>
        <TableCell
          width={132}
          value={`${formatDate(assignment.creation_time)} ${toTimeZone(assignment.creation_time)}`}
        />
        <TableCell width={200}>
          <Dropdown label="Статус" onChange={handleStatus} options={statusOptions} width={168} value={status} />
        </TableCell>
        <TableCell width={132}>
          <Styled.ScoreWrapper>
            <ParticipantScoreSelector assignment={assignment} handleScore={handleScore} score={score} status={status} />
          </Styled.ScoreWrapper>
        </TableCell>
        <TableCell width={104}>
          {memoSubmit}
          <Styled.Message variant="body2" fontWeight="small" isSuccess={message === "Сохранено"}>
            {message}
          </Styled.Message>
        </TableCell>
      </Styled.TableRow>
    </>
  )
}

export { AssignmentTableRow }
