import React from "react"
import { Typography } from "@mui/material"
import SidebarNavListItem from "./SidebarNavListItem"
import { styled } from "@mui/material/styles"

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: theme.typography.body2.fontSize,
  padding: theme.spacing(2),
  opacity: 0.4,
  textTransform: "uppercase",
  display: "block",
}))

const SidebarNavSection = props => {
  const { title, hasAdaptiveTest, pages, currentPrefix, setCurrent, component: Component = "nav", ...rest } = props

  return (
    <Component {...rest}>
      {title && <Title>{title}</Title>}
      {pages.map(page => (
        <SidebarNavListItem
          hasAdaptiveTest={hasAdaptiveTest}
          module={page}
          setCurrent={setCurrent}
          currentValue={{ ...currentPrefix, subsection: page.index }}
        />
      ))}
    </Component>
  )
}

export default SidebarNavSection
