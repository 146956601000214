import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

export const fetchInvitations = createAsyncThunk("invitations/fetchInvitations", async () => {
  const response = await axios.get("/content/actions/teams/my_invitations/")
  return response.data
})

export const acceptInvitation = createAsyncThunk("invitation/acceptInvitation", async invitation => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  const response = await axios.put(`/content/actions/teams/${invitation.id}/invitation_reply/`)
  return response.data
})

export const rejectInvitation = createAsyncThunk("invitation/rejectInvitation", async invitation => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  const response = await axios.patch(`/content/actions/teams/${invitation.id}/invitation_reply/`)
  return response.data
})
