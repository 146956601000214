import { DialogActions, DialogContent, Typography as MuiTypography } from "@mui/material"
import { styled } from "@mui/material/styles"
import ClearIcon from "@mui/icons-material/Clear"
import themes from "shared/themes"

export const DialogStyle = {
  style: {
    backgroundColor: "#DEF3FC",
    boxShadow: "none",
    maxHeight: themes.default.spacing(150),
    minWidth: `min(90%, ${themes.default.spacing(252.5)})`,
    borderRadius: "0",
  },
}

export const CloseWrapper = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(2)}`,
}))

export const Close = styled(ClearIcon)(() => ({
  cursor: "pointer",
  ":hover": { opacity: 0.5 },
}))

export const ContentWrapper = styled(DialogContent)(({ theme }) => ({
  padding: `0px ${theme.spacing(8)} ${theme.spacing(8)}`,
}))

export const Paragraph = styled("p")(({ theme }) => ({
  marginBlock: theme.spacing(8),
}))

export const Title = ({ children }) => (
  <MuiTypography variant="h4" sx={{ marginBottom: themes.default.spacing(4) }}>
    {children}
  </MuiTypography>
)

export const Text = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: theme.spacing(4),
}))

export const ThinText = styled("span")(() => ({
  fontWeight: 300,
  fontStyle: "italic",
}))

export const Indent = styled("p")(({ theme }) => ({
  marginBlock: theme.spacing(2),
}))

const styledLi = { marginBottom: themes.default.spacing(1) }

export const UnorderList = styled("ul")(({ theme }) => ({
  "li::marker": {
    fontSize: theme.spacing(3),
  },
  li: styledLi,
}))

export const OrderList = styled("ol")(() => ({
  li: styledLi,
}))

export const HelperWrapper = styled("div")(() => ({
  width: "100%",
  textAlign: "right",
}))
