export const ArrowBack = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M4.37739 14.5869C4.00285 14.9619 3.79248 15.4703 3.79248 16.0003C3.79248 16.5303 4.00285 17.0386 4.37739 17.4136L11.9187 24.9589C12.2939 25.3341 12.8028 25.5449 13.3334 25.5449C13.864 25.5449 14.3729 25.3341 14.7481 24.9589C15.1232 24.5838 15.334 24.0749 15.334 23.5443C15.334 23.0137 15.1232 22.5048 14.7481 22.1296L10.6201 18.0003L26.0001 18.0003C26.5305 18.0003 27.0392 17.7896 27.4143 17.4145C27.7893 17.0394 28.0001 16.5307 28.0001 16.0003C28.0001 15.4698 27.7893 14.9611 27.4143 14.5861C27.0392 14.211 26.5305 14.0003 26.0001 14.0003L10.6201 14.0003L14.7481 9.87228C14.9338 9.6865 15.0812 9.46596 15.1817 9.22323C15.2823 8.9805 15.334 8.72034 15.334 8.45761C15.334 8.19489 15.2823 7.93473 15.1817 7.692C15.0812 7.44927 14.9338 7.22872 14.7481 7.04295C14.5623 6.85717 14.3417 6.7098 14.099 6.60926C13.8563 6.50872 13.5961 6.45697 13.3334 6.45697C13.0707 6.45697 12.8105 6.50872 12.5678 6.60926C12.325 6.7098 12.1045 6.85717 11.9187 7.04295L4.37739 14.5869Z"
          fill="#575757"
        />
      </g>
    </svg>
  )
}
