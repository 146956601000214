import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import useStyles from "./styles"
import { Tab, Tabs } from "@mui/material"
import { AdaptiveTestEditor, Footer, Header, QuestionsPreview } from "widgets"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import { UserContextProvider } from "features/User/UserContext"

import { ReviewsPannel } from "./ReviewsPannel"
import { OrganizationsPannel } from "./OrganizationsPannel"
import { UserPannel } from "./UsersPannel"
import { ContentPannel } from "./ContentPannel"
import { CoursePannel } from "./CoursePannel"
import { CreateCourse, CreateContent } from "widgets"
import { AdminStaticLoaderPannel } from "./AdminStaticLoaderPannel"
import { useTab } from "shared/hooks/useTab"
import { useLocation, useParams } from "react-router-dom"
import { AdminTeams } from "../../widgets/AdminTeams/AdminTeams"
import { Tags } from "widgets"
import { Assignments } from "pages/admin/tabs/Assignments"

const Admin = props => {
  var classes = useStyles()

  const { tab, changeTab } = useTab("admin_tab")
  const [courseId, setCourseId] = useState(null)
  const [profile, setProfile] = useState(null)
  const [status, setStatus] = useState(null)
  const [isCourseModerator, setIsCourseModerator] = useState(false)

  const { pathname } = useLocation()
  const params = useParams()

  useEffect(() => {
    axios.get("/profiles/my_profile/").then(res => {
      axios
        .get("/content/actions/participants/my_courses/", {
          params: {},
        })
        .then(r => {
          res.data.teacher_in = r.data
            .filter(e => e.status === "teacher" || e.status === "moderator")
            .map(e => ({ id: e.course, title: e.course_title }))
          if (r.data.filter(e => e.status === "moderator").length > 0) setIsCourseModerator(true)
          else changeTab("admin_tab", 1)
          if (res.data.teacher_in.length === 0 && res.data.status === "moderator" && tab === 1)
            changeTab("admin_tab", 2)
          if (res.data.teacher_in.length === 0 && res.data.status !== "admin" && res.data.status !== "moderator") {
            window.location.href = "/"
          } else {
            setProfile(res.data)
            setStatus(res.data.status)
          }
        })
    })
  }, [])

  useEffect(() => {
    if (params.course) {
      if (pathname.includes(`/admin/scores/${params.course}`)) {
        setCourseId(params.course)
        changeTab("", 1)
      }
      if (pathname.includes(`/admin/courses/${params.course}`)) {
        setCourseId(params.course)
        changeTab("", 4)
      }
    } else if (pathname.includes(`/admin/courses/`)) {
      changeTab("", 4)
    }
    if (pathname.includes(`/admin/teams`)) {
      changeTab("", 10)
    }
  }, [params])

  if (!status) return <></>

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", maxWidth: "100vw" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          paddingLeft: "64px",
          paddingRight: "64px",
          marginBottom: "64px",
        }}
      >
        <Header variant={"shadow"} />
        <div className={classes.fakeToolbar} />
        <Tabs
          value={tab}
          onChange={changeTab}
          aria-label="tabs"
          style={{ margin: 48, marginInline: "auto", width: "90%" }}
          scrollButtons={"auto"}
          variant={"scrollable"}
        >
          {status == "admin" && <Tab label="Пользователи" value={0} />}
          {(status == "admin" || profile.teacher_in.length > 0) && <Tab label="Баллы" value={1} />}
          {(status == "admin" || status == "moderator") && <Tab label="Контент" value={2} />}
          {(status == "admin" || status == "moderator") && <Tab label="Создать контент" value={3} />}
          {(status === "admin" || status === "moderator" || isCourseModerator) && <Tab label="Курсы" value={4} />}
          {(status == "admin" || status == "moderator") && <Tab label="Создать курс" value={5} />}
          {status == "admin" && <Tab label="Организации" value={6} />}
          {status == "admin" && <Tab label="Отзывы" value={7} />}
          {(status == "admin" || status == "moderator") && <Tab label="Загрузка файлов" value={8} />}
          {(status == "admin" || status == "moderator") && <Tab label="Управление тегами" value={9} />}
          {(status === "admin" || status === "moderator" || isCourseModerator) && <Tab label="Команды" value={10} />}
          {(status == "admin" || status == "moderator") && <Tab label="Адаптив" value={11} />}
          {(status == "admin" || status == "moderator") && <Tab label="Адаптив превью" value={12} />}
        </Tabs>
        {tab == 0 ? (
          <UserContextProvider>
            <UserPannel />
          </UserContextProvider>
        ) : tab == 1 ? (
          <Assignments />
        ) : tab == 2 ? (
          <ContentPannel />
        ) : tab == 3 ? (
          <CreateContent />
        ) : tab == 4 ? (
          <CoursePannel course={courseId} status={status} />
        ) : tab == 5 ? (
          <CreateCourse />
        ) : tab == 6 ? (
          <OrganizationsPannel />
        ) : tab == 7 ? (
          <ReviewsPannel />
        ) : tab == 8 ? (
          <AdminStaticLoaderPannel />
        ) : tab === 9 ? (
          <Tags />
        ) : tab === 10 ? (
          <AdminTeams status={status} />
        ) : tab === 11 ? (
          <AdaptiveTestEditor />
        ) : (
          <QuestionsPreview />
        )}
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  )
}

export default withRouter(Admin)
