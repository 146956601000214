import { useState } from "react"
import { ChooseCourse } from "./ui/ChooseCourse"
import { StageBlock } from "./ui/StageBlock"
import { fetchCourseTeams, fetchUserCourses, getCourse, getCourseParticipants } from "../../entities/Course/api/course"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { trajectoryActions } from "shared/store"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { getModules } from "../../entities/Module/api/module"
import { ModuleType } from "../../entities/Module/api/module.types"
import { Button } from "shared/ui"
import * as Styled from "./ui/TrajectoryConstructor.styles"
import { CircularProgress, Typography } from "@mui/material"

export const TrajectoryConstructor = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useAppDispatch()
  const { stages, saving_status, is_changed } = useAppSelector(state => state.trajectory)

  async function fetchTrajectoryInfo(id: number) {
    dispatch(trajectoryActions.setIsChanged(false))
    setIsLoading(true)
    const course = await getCourse(id)
    const user_courses = await fetchUserCourses({
      status: "moderator",
    })
    dispatch(trajectoryActions.setCourseId(course.id))
    dispatch(trajectoryActions.setCourseType(course.type as "course" | "event" | "webinar" | null))
    dispatch(trajectoryActions.setCourseBadge(!!course.badge ? (course.badge as any) : null))
    dispatch(
      trajectoryActions.setCourses([
        ...user_courses.map(e => ({
          label: e.course_title,
          value: e.course,
        })),
      ])
    )
    let modules: ModuleType[] = []
    try {
      modules = (await getModules(id)) as ModuleType[]
      if (course.type === "webinar") {
        //@ts-ignore
        modules.filter(e => e.type === course.badge)
      }
    } catch (err) {
      modules = []
    }
    dispatch(trajectoryActions.setStages({ stages: course.stages, modules: modules }))
    let participants = await getCourseParticipants({
      status: "in_progress",
      course: course.id,
      stats: true,
      no_pagination: true,
    })
    // @ts-ignore
    let teams = await fetchCourseTeams(course.id)
    dispatch(trajectoryActions.setTeams(teams.map(e => ({ id: e.id, title: e.title }))))
    // @ts-ignore
    dispatch(trajectoryActions.setParticipants(participants.results.map(e => ({ id: e.id, email: e.email }))))
    setIsLoading(false)
  }

  function saveModules() {
    dispatch(trajectoryActions.setSaveTrigger())
    dispatch(trajectoryActions.setIsChanged(false))
    setProgress()
  }

  function setProgress() {
    setIsSaving(true)
    setTimeout(() => {
      setIsSaving(false)
      setTimeout(() => {
        dispatch(trajectoryActions.setSaveTrigger())
        dispatch(trajectoryActions.initSaveStatus())
      }, 10000)
    }, 11000)
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        marginTop: "20px",
      }}
    >
      <ChooseCourse isLoading={isLoading} fetchCourse={fetchTrajectoryInfo} />
      {stages.length > 0 && stages.map(e => <StageBlock stage={e} />)}
      {stages.length > 0 && (
        <Styled.SavePanel>
          {saving_status.length > 0 && !isSaving && (
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"} sx={{ color: "#FFF" }}>
              {saving_status.includes(true)
                ? saving_status.includes(false)
                  ? "Изменения частично сохранены"
                  : "Изменения сохранены"
                : "Изменения не сохранены"}
            </Typography>
          )}
          {isSaving && (
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"} sx={{ color: "#FFF" }}>
              Пожалуйста, подождите...
            </Typography>
          )}
          {isSaving && <CircularProgress sx={{ color: "#FFF" }} size={32} />}
          <Button disabled={!is_changed} onClick={saveModules} variant={"contained"} color={"primary"}>
            Сохранить
          </Button>
        </Styled.SavePanel>
      )}
    </div>
  )
}
