import { styled } from "@mui/material/styles"

export const SliderDiv = styled("div")(({ theme }) => ({
  "& .slick-list": {
    "& .slick-slide > div": {
      padding: "0 8px",
    },
  },
  height: "auto",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: `${0} ${theme.spacing(4)}`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: `${0} ${0}`,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    padding: `${0} ${theme.spacing(4)}`,
  },
}))
