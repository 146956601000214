import React from "react"
import * as Styled from "./Catalog.styles"
import { Tab } from "@mui/material"
import { useTheme } from "../../../shared/context"

export const Tags = ({
  tags,
  currentTags,
  setCurrentTags,
}: {
  tags: { id: number; value: string }[]
  currentTags: string[]
  setCurrentTags: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const theme = useTheme()
  function onClickTag(tag: string) {
    if (!currentTags.includes(tag)) {
      setCurrentTags(prev => [...prev, tag])
    } else {
      setCurrentTags(prev => [...prev.filter(e => e !== tag)])
    }
  }

  return (
    <Styled.TagsMainDiv>
      {tags.map(e => (
        <Tab
          onClick={() => onClickTag(e.value)}
          label={e.value}
          value={e.value}
          style={{
            border: currentTags.includes(e.value) ? "1px solid #1565C0" : "1px solid #313439",
            backgroundColor: currentTags.includes(e.value) ? "#1565C0" : "#FFF",
            borderRadius: theme.spacing(3),
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "Roboto",
            whiteSpace: "nowrap",
            height: theme.spacing(10),
            opacity: 1,
            color: currentTags.includes(e.value) ? "#FFF" : "#313439",
          }}
        />
      ))}
    </Styled.TagsMainDiv>
  )
}
