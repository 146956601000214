import { axiosInstance as axios } from "shared/api/axiosInstance"
import { ITeamProgressParams, ITeamsOnCourseParams } from "./team.types"

export const getTeamProgress = async (params: ITeamProgressParams) =>
  await axios
    .get(`/content/team_progress/`, {
      params,
    })
    .then(res => res.data)

export const getTeamsOnCourse = async (params: ITeamsOnCourseParams) =>
  await axios.get(`/content/teams/`, { params }).then(res => res.data)

export const getTeam = async (team: Number) => {
  return await axios.get(`/content/teams/${team}`).then(res => res.data)
}
