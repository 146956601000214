import React, { ReactNode } from "react"
import { Typography as MuiTypography, styled } from "@mui/material"
import { ThemeType } from "shared/themes/default.types"
import { Button } from "shared/ui"
import CheckIcon from "@mui/icons-material/Check"
import FailIcon from "images/icons/Fail"
import SuccessIcon from "images/icons/Success"

interface IProps {
  score: number
  minScore: number
  totalScore: number
  restartTest: () => void
  isManual: boolean
  hasAttempts: boolean
  textAttempts?: string
  disabledClear: boolean
}

const TestHeader = ({
  score,
  minScore,
  totalScore,
  restartTest,
  isManual,
  textAttempts,
  hasAttempts,
  disabledClear,
}: IProps) => {
  const isSuccess = score >= minScore

  const getTitle = () => {
    if (isSuccess) return "Поздравляем! Вы прошли тест!"
    else if (disabledClear) return "Тест не зачтён."
    else return "Тест не зачтён, попробуйте ещё раз."
  }

  const Icon = isSuccess ? <SuccessIcon /> : <FailIcon />

  if (isManual)
    return (
      <ManualWrapper>
        <div style={{ display: "flex" }}>
          <CheckIcon style={{ marginRight: 6, color: "#2C6BB5" }} />
          <Typography variant="h4">Ваши ответы отправлены!</Typography>
        </div>
      </ManualWrapper>
    )

  return (
    <Wrapper isSuccess={isSuccess}>
      <IconWrapper>{Icon}</IconWrapper>
      <Text>
        <Typography variant="h4">{getTitle()}</Typography>
        <Typography variant="body1" fontWeight={"medium"} fontSize={"medium"} style={{ marginTop: 12 }}>
          Ваша оценка: {score}/{totalScore}
        </Typography>
        {hasAttempts && (
          <div style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: "16px", marginTop: 12 }}>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
              Попытка:
            </Typography>
            <MuiTypography variant={"h4"}>{textAttempts}</MuiTypography>
          </div>
        )}
      </Text>
      <ButtonWrapper>
        <Button variant="contained" color="primary" onClick={restartTest} disabled={disabledClear}>
          Очистить
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

const ManualWrapper = styled("div")(() => ({
  backgroundColor: "#DEF3FC",
  borderColor: "#81D9FF",
  padding: 36,
  marginBottom: 48,
  marginTop: 58,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: 1280,
  borderRadius: "6px",
  borderWidth: "1px",
  borderStyle: "solid",
  "& h4": {
    fontSize: 18,
    lineHeight: "155%",
    fontWeight: 600,
    fontFamily: "Roboto",
  },
  "& p": {
    fontSize: 18,
    lineHeight: "155%",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
}))

const Wrapper = styled("div")(
  ({ theme, children, isSuccess }: { theme?: ThemeType; children: ReactNode; isSuccess?: boolean }) => ({
    border: `1px solid ${theme?.palette.info.dark}`,
    borderColor: isSuccess ? "#81FF81" : "#FF7373",
    borderRadius: 12,
    backgroundColor: (isSuccess && theme?.palette.success.light) || theme?.palette.error.light,
    width: "100%",
    padding: "36px",
    display: "flex",
    alignItems: "center",
    gap: 40,
  })
)

const ButtonWrapper = styled("div")(() => ({
  marginLeft: "auto",
  width: "115px",
}))

const Text = styled("p")(() => ({}))

const Typography = styled(MuiTypography)(() => ({
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "155%" /* 27.9px */,
}))

const IconWrapper = styled("div")(() => ({
  marginRight: 6,
  scale: "0.6",
}))

export { TestHeader }
