import React from "react"
import { Footer, Header } from "widgets"
import { styled } from "@mui/styles"

const PageContainer = ({ children, isFooter = true }: { children: React.ReactNode; isFooter?: boolean }) => {
  return (
    <Container>
      <Header variant={"shadow"} />
      <Wrapper>{children}</Wrapper>
      {isFooter && <Footer />}
    </Container>
  )
}

const Container = styled("div")(() => ({
  height: "100vh",
}))

const Wrapper = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: 1032,
  marginInline: "auto",
  minHeight: "calc(100vh - 112px)",
}))

export { PageContainer }
