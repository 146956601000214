//@ts-nocheck
import React, { useEffect, useRef, useState } from "react"
import * as Styled from "./Iot.styles"
import { Menu, MenuItem, Typography } from "@mui/material"
import GaugeComponent from "react-gauge-component"
import { sensorsConfig } from "../model/SensorsConfig"
import Plot from "react-plotly.js"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { useTheme } from "shared/context"
import { TimeChip } from "./TimeChip"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { procTime, saveSensorCsv } from "../utils/functions"
import { IHistory, SensorType } from "../model/Iot.types"
import { timeIntervals } from "../utils/timeIntervals"
import { chartsSettings, settingsDataOfChartSensorCard } from "../lib/ChartsSettings"
import { fetchCsvOfSensor, fetchSensorHistory } from "../api/iot"
import { downloadIcon } from "./PlotlySvgIcons"
import { GaugeLabelStyle, TickLabelsStyle } from "./Iot.styles"

interface IProps {
  title: string
  pid: number
  value: number
  serial: string
  moduleName: string
  type: SensorType
}

export const SensorCard = ({ title, value, type, serial, pid, moduleName }: IProps) => {
  const theme = useTheme()
  const [visibilityState, setVisibilityState] = useState(false)
  const [timeInterval, setTimeInterval] = useState({ label: "7 дней", value: "7d" })
  const intervalRef = useRef<{ label: string; value: string }>({ label: "7 дней", value: "7d" })
  const [layout_s, set_layout] = useState({
    ...chartsSettings,
    yaxis:
      type === "gpio_adc"
        ? { range: [0, 3.3], title: sensorsConfig[type].units }
        : { title: sensorsConfig[type].units },
  })
  const [data_s, set_data] = useState([{ ...settingsDataOfChartSensorCard }])
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClickTime = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMouseEnter = () => {
    setTooltipVisible(true)
  }

  const handleMouseLeave = () => {
    setTooltipVisible(false)
  }

  function downloadCsv() {
    let aggr = "1h"
    if (intervalRef.current.value === "365d") aggr = "1d"
    fetchCsvOfSensor(serial, pid, title, intervalRef.current.value, aggr).then(res => {
      saveSensorCsv(res.data, moduleName, title, intervalRef.current.value)
    })
  }

  function fetchData(newValue) {
    let aggregate = "1h"
    if (newValue.value === "365d") aggregate = "1d"
    fetchSensorHistory(serial, pid, newValue.value, title, aggregate).then(res => {
      setTimeInterval(newValue)
      intervalRef.current = newValue
      updateGraph(res.data)
    })
  }

  function updateGraph(data) {
    let x: Array<number> = []
    let y: Array<string> = []
    let text: Array<string> = []
    data.forEach((item: IHistory) => {
      y.push(item._value)
      x.push(item._time)
      text.push(`${procTime(item._time)}<b>Y:</b> ${item._value}`)
    })
    let new_data = data_s[0]
    new_data.y = y
    new_data.x = x
    new_data.text = text
    set_data([new_data])
    set_layout({ ...layout_s, datarevision: layout_s.datarevision + 1 })
  }

  function handleClick() {
    if (!visibilityState) {
      fetchSensorHistory(serial, pid, timeInterval.value, title, "1h").then(res => {
        setVisibilityState(true)
        updateGraph(res.data)
      })
    } else {
      setVisibilityState(false)
      setTooltipVisible(false)
    }
  }

  return (
    <>
      <Styled.SensorCard>
        <Styled.HeaderSensorCard>
          {visibilityState && (
            <KeyboardBackspaceIcon
              onClick={handleClick}
              sx={{ color: theme.palette.common.black, cursor: "pointer" }}
            />
          )}
          <div>
            <Typography variant={"h4"}>{title}</Typography>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"} sx={{ alignSelf: "flex-start" }}>
              {moduleName}
            </Typography>
          </div>
          {visibilityState && (
            <Styled.ChooseRangeContainer>
              <TimeChip label={timeInterval.label} />
              <ArrowDropDownIcon onClick={handleClickTime} sx={{ alignSelf: "center", cursor: "pointer" }} />
              <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {timeIntervals.map(e => (
                  <MenuItem
                    onClick={() => {
                      fetchData(e)
                    }}
                    selected={e.value === timeInterval.value}
                  >
                    <TimeChip label={e.label} />
                  </MenuItem>
                ))}
              </Menu>
            </Styled.ChooseRangeContainer>
          )}
        </Styled.HeaderSensorCard>
        {!visibilityState && (
          <Styled.GaugeDiv onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <GaugeComponent
              type="semicircle"
              pointer={{ type: "blob", animationDelay: 0 }}
              arc={{
                width: 0.1,
                padding: 0.01,
                cornerRadius: 1,
                subArcs: [
                  {
                    limit: sensorsConfig[type].firstLimit,
                    color: sensorsConfig[type].firstColor,
                    showTick: true,
                  },
                  {
                    limit: sensorsConfig[type].secondLimit,
                    color: sensorsConfig[type].secondColor,
                    showTick: true,
                  },
                  {
                    limit: sensorsConfig[type].thirdLimit,
                    color: sensorsConfig[type].thirdColor,
                    showTick: true,
                  },
                  {
                    limit: sensorsConfig[type].fourthLimit,
                    color: sensorsConfig[type].fourthColor,
                    showTick: true,
                  },
                  {
                    color: "#EA4228",
                  },
                ],
              }}
              color={"#000"}
              labels={{
                valueLabel: {
                  formatTextValue: value => value + sensorsConfig[type].units,
                  matchColorWithArc: true,
                  maxDecimalDigits: 2,
                  style: GaugeLabelStyle,
                },
                tickLabels: {
                  type: "outer",
                  defaultTickValueConfig: {
                    style: TickLabelsStyle,
                  },
                  //@ts-ignore
                  valueConfig: {
                    formatTextValue: value => value + sensorsConfig[type].units,
                    fontSize: 10,
                  },
                  ticks: [{ value: sensorsConfig[type].firstTick }, { value: sensorsConfig[type].secondTick }],
                },
              }}
              value={value}
              minValue={sensorsConfig[type].minValue}
              maxValue={sensorsConfig[type].maxValue}
            />
            {tooltipVisible && (
              <Typography
                variant={"body1"}
                fontWeight={"medium"}
                fontSize={"small"}
                sx={{
                  backgroundColor: "#fff",
                  position: "absolute",
                  bottom: "10px",
                  width: "100%",
                  color: "#225DCE",
                  textAlign: "center",
                  position: "absolute",
                }}
              >
                Нажмите на карточку, чтобы перейти к графику
              </Typography>
            )}
          </Styled.GaugeDiv>
        )}
        {visibilityState && (
          <Styled.PlotStyle>
            <Plot
              layout={layout_s}
              data={data_s}
              style={{ width: "100%", maxHeight: 180 }}
              useResizeHandler={false}
              config={{
                toImageButtonOptions: {
                  filename: `${moduleName}__${title}__${intervalRef.current.value}`,
                },
                displayModeBar: true,
                displaylogo: false,
                modeBarButtonsToAdd: [
                  {
                    icon: {
                      svg: [downloadIcon()],
                    },
                    name: "Скачать данные",
                    click: () => {
                      downloadCsv()
                    },
                  },
                ],
              }}
            />
          </Styled.PlotStyle>
        )}
      </Styled.SensorCard>
    </>
  )
}
