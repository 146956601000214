import moment from "moment"
import { styled } from "@mui/material/styles"

export function parseText(text, isCollapsed) {
  if (!text) return

  const isNeedCollapse = text.length > 300
  let res = []
  const slicedText = isNeedCollapse && isCollapsed ? text.slice(0, 300) : text

  slicedText.replace(
    /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
    (m, link, text) => {
      const splitedText = text?.split("\n")

      res.push(
        link ? (
          <a target={"_blank"} href={(link[0] === "w" ? "//" : "") + link} key={res.length}>
            Ссылка
          </a>
        ) : (
          <Typography>
            {splitedText.map(row => (
              <div>{row}</div>
            ))}
          </Typography>
        )
      )
    }
  )
  return res
}

export function parseDate(datetime) {
  let date = moment(datetime.split("T")[0].toString(), "YYYY/MM/DD").format("DD.MM.YYYY")
  return date
}

export function parseTime(datetime) {
  let time = datetime.split("T")[1].split(".")[0]
  time = moment.utc(time, "HH:mm:ss").add(3, "hour").format("HH:mm:ss")
  return `${time} (Мск)`
}

const Typography = styled("p")(() => ({
  overflowWrap: "break-word",
  lineHeight: "1.55",
  fontSize: "1rem",
  fontWeight: "300",
  marginBottom: "0",
}))
