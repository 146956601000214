import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

export const CommonTypeEditor = (props) => {
  const [question, setQuestion] = useState(props.question)

  return (
    <div style={{ border: "1px solid", borderRadius: 4 }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <TextField
          id="question-text"
          variant="outlined"
          value={question.text}
          onChange={e => setQuestion(q => ({...q, text: e.target.value}))}
          placeholder="Вопрос"
          fullWidth
          multiline
          style={{ margin: 12, minWidth: 360 }}
        />
        <TextField
          id="question-score"
          variant="outlined"
          value={question.score}
          onChange={e => setQuestion(q => ({...q, score: e.target.value}))}
          placeholder="Баллы"
          fullWidth
          type="number"
          style={{ margin: 12, width: 120 }}
        />
        <FormControl sx={{ minWidth: 180, margin: 6 }}>
          <InputLabel id="test-editor-score-type-select-label">Тип начисления</InputLabel>
          <Select
            labelId="test-editor-score-type-select-label"
            id="test-editor-score-type-select"
            value={question.score_type}
            label="Тип начисления"
            onChange={e => setQuestion(q => ({...q, score_type: e.target.value}))}
          >
            <MenuItem value={"multiple"}>Дробный</MenuItem>
            <MenuItem value={"binary"}>Бинарный</MenuItem>
            <MenuItem value={"positive"}>Положительный</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <TextField
            id="question-description"
            variant="outlined"
            value={question.description}
            onChange={e => setQuestion(q => ({...q, description: e.target.value}))}
            placeholder="Описание"
            fullWidth
            style={{ margin: 12, width: 360 }}
          />
          <TextField
            id="question-image"
            variant="outlined"
            value={question.image}
            onChange={e => setQuestion(q => ({...q, image: e.target.value}))}
            placeholder="Иллюстрация"
            fullWidth
            style={{ margin: 12, width: 360 }}
          />
        </div>
        <Button onClick={() => props.doUpdate(question)} style={{ margin: 12 }}>Сохранить</Button>
      </div>
    </div>
  )
}
