export const incomingTitle = "Входящие приглашения"

export const incomingHeaders = [
  "Отправитель",
  "Название курса",
  "Название Команды",
  "ID команды",
  "Ваша роль в команде",
  "Действие с приглашением",
]

export const requestTitle = "Запросы от пользователей на вступление в команду"

export const requestHeaders = [
  "Пользователь",
  "Название курса",
  "Название Команды",
  "ID команды",
  "Действие с запросом",
]

export const sentTitle = "Отправленные приглашения"

export const sentHeaders = [
  "Пользователь",
  "Название курса",
  "Название Команды",
  "ID команды",
  "Роль пользователя в команде",
  "Действие с приглашением",
]
