import React, { useState, useCallback, useEffect } from "react"
import { Typography } from "@mui/material"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import useStyles from "./styles"
import { Card } from "shared/ui"
import { backgroundStyleDrop } from "./styles"
import { compareArrays, moveItemInArray } from "shared/util"

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>
  } else {
    return (
      <Typography variant="body1" style={{ padding: 12 }}>
        {text}
      </Typography>
    )
  }
}

export const Sort = ({ question, value, index, handleChange, result, shuffle, ready }) => {
  const classes = useStyles()
  const [currentQuestionState, setCurrentQuestionState] = useState(question)

  useEffect(() => {
    if (ready) {
      let newValue = []
      let newVariants
      if (question.disableShuffle) {
        newVariants = question.variants
      } else {
        newVariants = [...shuffle(question.variants)]
      }
      for (let index = 0; index < currentQuestionState.variants?.length; index++) {
        newValue.push(`${index},${newVariants[index]?.value}`)
      }
      setCurrentQuestionState({ ...question, variants: newVariants })
      handleChange(index, newValue)
    }
  }, [question, ready])

  useEffect(() => {
    if (!value || !value?.length) return
    const variants = currentQuestionState.variants
    const stateValue = variants.map((variant, index) => `${index},${variant.value}`)
    if (compareArrays(value, stateValue)) return
    const newVariants = value.map(item => variants.find(variant => variant.value === Number(item.split(",")[1])))
    setCurrentQuestionState(q => ({ ...q, variants: newVariants }))
    const preventOverall = true
    handleChange(index, value, preventOverall)
  }, [value])

  const onDragEnd = useCallback(
    result => {
      if (!result.source || !result.destination) return
      let newValue = []
      let newVariants = [...currentQuestionState.variants]
      moveItemInArray(newVariants, result.source.index, result.destination.index)
      for (let index = 0; index < currentQuestionState.variants?.length; index++) {
        newValue.push(`${index},${newVariants[index]?.value}`)
      }
      setCurrentQuestionState(q => {
        return { ...q, variants: newVariants }
      })
      handleChange(index, newValue)
    },
    [currentQuestionState]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Card style={{ width: 800, display: "flex", ...backgroundStyleDrop(result) }} className={classes.testSort}>
        <Droppable droppableId="draggable-0">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={{ width: "100%", padding: "12px 24px" }} {...provided.droppableProps}>
              {currentQuestionState.variants.map((e, index) => (
                <Draggable draggableId={"draggable-" + index} index={index}>
                  {(provided, snapshot) => (
                    <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {parseText(e.label)}
                      </div>
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Card>
    </DragDropContext>
  )
}
