import React from "react"
import { TableCell, TableRow, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import * as Styled from "./Iot.styles"
import { StatusIndicator } from "./Iot.styles"
interface IProps {
  sensor: { pid: number, descr: string, type: string }
}

export const SensorDescription = ({ sensor }: IProps) => {

  const theme = useTheme()

  return (
    <>
      <TableRow>
        <TableCell align={"center"}>
          <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
            {sensor.pid}
          </Typography>
        </TableCell>
        <TableCell align={"center"}>
          <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
            {sensor.descr}
          </Typography>
        </TableCell>
        <TableCell align={"center"}>
          <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
            {sensor.type}
          </Typography>
        </TableCell>
        <TableCell align={"center"}>
          <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
            <Styled.SensorStatusDiv>
              <StatusIndicator sx={{ backgroundColor: theme.palette.success.main }}></StatusIndicator>
              <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
                Подключен
              </Typography>
            </Styled.SensorStatusDiv>
          </Typography>
        </TableCell>
      </TableRow>
    </>
  )
}