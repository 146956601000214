import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import * as Styled from "./Subscribes.style"
import { IOrganization } from "entities/Organization/model/Organization.types"
import { axiosInstance as axios } from "shared/api/axiosInstance"
//@ts-ignore
import Cookies from "js-cookie"
const tableFields = ["Название организации", "ID Организации", "Отписаться"]

export const OrganizationsTable = ({
  organizations,
  updateOrganizations,
}: {
  organizations: Array<IOrganization>
  updateOrganizations: () => void
}) => {
  function deleteOrganization(organization: IOrganization) {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.delete(`/organizations/${organization.id}/join/`).then(res => {
      updateOrganizations()
    })
  }

  return (
    <Styled.CustomTableContainer>
      <Table>
        {/* @ts-ignore */}
        <TableHead stickyHeader={true} sx={{ width: "100%", overflowX: "auto" }}>
          <TableRow>
            {tableFields.map(e => (
              <TableCell align={"center"}>
                <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: "14px" }}>
                  {e}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map(e => (
            <TableRow>
              <TableCell align={"center"}>
                <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
                  {e.title}
                </Typography>
              </TableCell>
              <TableCell align={"center"}>
                <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
                  {e.id}
                </Typography>
              </TableCell>
              <TableCell align={"center"}>
                <Styled.CustomDeleteIcon onClick={() => deleteOrganization(e)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Styled.CustomTableContainer>
  )
}
