import React, { useEffect, useRef } from "react"
import Carousel from "react-material-ui-carousel"
import useStyles from "./styles"
import ReviewItem from "./ReviewItem"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"
import { Line } from "shared/ui"
import { Typography } from "@mui/material"

const Reviews = ({ e, handleChangeRef }) => {
  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  return (
    <div ref={ref} className={classes.reviewsContainer} style={{ backgroundImage: `url(${e.backgroundImage})` }}>
      <Typography variant={"h2"} sx={{ color: e.H2?.color }}>
        {e.H2?.text}
      </Typography>
      <Carousel
        className={classes.reviewsCarousel}
        indicators={false}
        navButtonsProps={{ className: classes.navButtons, style: { backgroundColor: "transparent" } }}
        interval={8000}
        PrevIcon={<ArrowLeft sx={{ color: e.arrowColor }} />}
        NextIcon={<ArrowRight sx={{ color: e.arrowColor }} />}
        navButtonsAlwaysVisible={true}
      >
        {e.options.map(item => (
          <ReviewItem e={item} />
        ))}
      </Carousel>
    </div>
  )
}

export default Reviews
