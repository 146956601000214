import React from 'react';
import { useTheme } from "../../context";
import { styled } from "@mui/material/styles";

export const Typography = ({ color, children, variant }) => {

    const theme = useTheme();

    function getStyle() {
        switch (variant) {
            case "h1":
                return theme.h1;
            case "h2":
                return theme.h2;
            case "h3":
                return theme.h3;
            case "h4":
                return theme.h4;
            default:
                return theme.baseText;
        }
    }

    const StyledDiv = styled('div')({
        ...getStyle()
    });

    return (
        <StyledDiv sx={{ color: color }}>
            {children}
        </StyledDiv>
    );
};
