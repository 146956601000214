import { ModalSmallTemplate } from "shared/ui"

interface IProps {
  slug: string
  isLeaderboard: boolean
  isParticipant: boolean
}

const LeaderboardModal = ({ slug, isLeaderboard, isParticipant }: IProps) => {
  const isOpen = true
  let url = isLeaderboard ? `/event/${slug}/` : `/event/${slug}/stages`
  let text =
    !isLeaderboard && "В данный момент таблица закрыта для просмотра, вы не можете ознакомиться с рейтингом команд."

  if (!isParticipant) {
    text = "Вы не являетесь участником курса, поэтому не можете ознакомиться с рейтингом команд."
    url = `/event/${slug}/`
  }

  const handleClick = () => {
    window.location.href = url
  }

  const label = isLeaderboard ? "На страницу события" : "Траектория события "

  return (
    <ModalSmallTemplate
      isOpen={isOpen}
      onClose={handleClick}
      title={"Доступ закрыт"}
      onClick={handleClick}
      label={label}
    >
      {text}
    </ModalSmallTemplate>
  )
}

export default LeaderboardModal
