import { styled } from "@mui/material"
import WarningModal from "../../../entities/Statistic/ui/WarningModal"

import { useState } from "react"
import { TextField } from "shared/ui"
import { createNotification } from "entities/Notification/api/notifications"
import { Variant } from "shared/ui/styles.const"

interface IProps {
  isOpen: boolean
  onClose: () => void
  filterCount: number
  userCount: number
  messageTitle: string
  users: number[]
  courseId: number
}

const WarningNotifyModal = ({ isOpen, onClose, filterCount, userCount, messageTitle, users, courseId }: IProps) => {
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  async function handleNotification() {
    const payload = {
      course_id: courseId,
      users: users,
      title: messageTitle,
      content: message,
    }
    try {
      setIsLoading(true)
      await createNotification(payload)
    } catch {
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Отправка уведомления"}
      label="Отправить уведомление"
      onSubmit={handleNotification}
      isLoading={isLoading}
      isNotify
    >
      <TextWrapper>
        <TextField
          label="Текст уведомления"
          value={message}
          onChange={e => setMessage(e.target.value)}
          variant={Variant.outlined}
          width={887}
          height={64}
          multiLine={true}
          disableTooltip
        />
      </TextWrapper>
      <Caption>
        Перед отправкой уведомления, убедитесь, что в статистике отображено нужное количество пользователей
      </Caption>
      <Typography>
        Уведомление получат <Strong>{userCount}</Strong> из <Strong>{filterCount}</Strong> выбранных вами пользователей.
      </Typography>
    </WarningModal>
  )
}

const TextWrapper = styled("div")(() => ({
  paddingTop: 24,
}))

const Typography = styled("p")(() => ({
  color: "#000",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "155%",
}))

const Strong = styled("span")(() => ({
  fontWeight: 500,
  lineHeight: "115%",
  letterSpacing: "0.2px",
}))

const Caption = styled("span")(() => ({
  marginTop: 4,
  color: "#313439",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
  marginBottom: 32,
}))

export default WarningNotifyModal
