import React, {useState, useRef} from 'react';
import {Button} from "@mui/material";
import {ChangeEvent} from "react";
import useStyles from "./styles"

export const FileInput = (props) => {

    var classes = useStyles()

    const [link, setLink] = useState("")

    const [filename, setFilename] = useState("");

    const [urlVisible, setUrlVisible] = useState(false)

    const handleFileUpload = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        setFilename(name);
        let url = window.URL.createObjectURL(file)
        setLink(url)
        setUrlVisible(true)
    };

    return (
        <div style={{display:"flex", flexDirection:"row", alignItems:"center", outline: "gray"}}>
            <Button
                component="label"
                className={classes.checkButton}
            >
                Загрузить файл
                <input
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                />
            </Button>
            <div style={{display:"flex", flexDirection:"column", marginLeft:"24px"}}>
                <div>
                    {filename}
                </div>
                <div style={urlVisible ?  {visibility: 'visible'} : {display: "none"}}>
                    Ссылка на файл: <i> {link} </i>
                </div>
            </div>
        </div>
    );
};
