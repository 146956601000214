import React, { useState } from "react"
import * as Styled from "./Organizations.styles"
import { Typography } from "@mui/material"
import { Button } from "shared/ui"
import { SendNotification } from "entities/Notification/ui/SendNotification"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { axiosInstance as axios } from "shared/api/axiosInstance"
// @ts-ignore
import Cookies from "js-cookie"

const NotificationCreate = () => {
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const [openDialog, setOpenDialog] = useState(false)

  async function creatNotification(text: string) {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.post(`/organizations/${organization?.id}/notify/`, {
      content: text,
      title: organization?.title,
      link: `/organization/${organization?.slug}`,
    })
  }

  return (
    <Styled.NotificationCreateMainDiv>
      {/*@ts-ignore */}
      <SendNotification
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        createNotification={creatNotification}
      />
      <Typography variant={"h4"}>Отправить уведомления подписчикам организации</Typography>
      {/*@ts-ignore */}
      <Button variant={"outlined"} color={"primary"} onClick={() => setOpenDialog(true)}>
        Отправить уведомление
      </Button>
    </Styled.NotificationCreateMainDiv>
  )
}

export default NotificationCreate
