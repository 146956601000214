import * as Styled from "./Team.style.ts"
import { useEffect, useState } from "react"
import { CircularProgress, Typography } from "@mui/material"
import { Autocomplete, Button, ShadowedSection } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useTheme } from "shared/context"
import { ManageTeamModal } from "features"
import { useDispatch } from "react-redux"
import { teamActions, courseActions } from "shared/store"
import { useSelector } from "react-redux"
import { fetchTeamProps, getTeamCategories, getTeamLimit } from "entities/Team/model/getTeamProps.js"

export const CoursePannel = () => {
  const [variants, setVariants] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const course = useSelector(state => state.course.course)
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    getCourses()
  }, [])

  async function fetchTeams() {
    try {
      setIsLoading(true)
      const teams = await axios
        .get("content/actions/teams/my_teams/", { params: { course: course.id } })
        .then(res => res.data)
      dispatch(teamActions.setTeams(teams))
      await getTeamProps()
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const getTeamProps = async () => {
    const teamProps = await fetchTeamProps(course.id)
    const teamCategories = getTeamCategories(teamProps)
    const teamLimit = getTeamLimit(teamProps)
    dispatch(
      teamActions.setCategories({ chief_moderator: "chief_moderator", ...teamCategories, role_none: "Без роли" })
    )
    dispatch(courseActions.setMembersLimit(teamLimit))
  }

  function getCourses() {
    try {
      axios.get("content/actions/participants/my_courses/", { params: { for_teams: "true" } }).then(res => {
        setVariants(res.data.map(e => ({ id: e.course, label: e.course_title })))
      })
    } catch {}
  }

  const handleChooseCourse = (event, newValue) => {
    if (!newValue || newValue !== course) {
      dispatch(teamActions.setTeams([]))
    }
    dispatch(courseActions.setCourse(newValue))
  }

  return (
    <>
      <ManageTeamModal course={course} isOpen={isOpen} onClose={e => setIsOpen(false)} />
      <ShadowedSection>
        <Styled.TeamsCourse>
          <Typography variant={"h4"}>Список команд по курсу</Typography>
          <Styled.ChoiseCourseMainDiv>
            <Styled.ChoiseCourseDiv>
              <Autocomplete
                style={{ width: theme.spacing(96) }}
                label={"Название курса"}
                options={variants}
                value={course}
                onChange={handleChooseCourse}
              />
              <Button variant={"contained"} color={"primary"} onClick={fetchTeams} disabled={!course || isLoading}>
                Посмотреть список
              </Button>
              {isLoading && <CircularProgress size={26} />}
            </Styled.ChoiseCourseDiv>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={e => setIsOpen(true)}
              disabled={!course || isLoading}
            >
              Создать команду
            </Button>
          </Styled.ChoiseCourseMainDiv>
        </Styled.TeamsCourse>
      </ShadowedSection>
    </>
  )
}
