import { Chip, ListItemButton, ListItemText, Typography } from "@mui/material"
import { useContentState } from "shared/context"
import { styled } from "@mui/material/styles"
import CircleIcon from "@mui/icons-material/Circle"

const Item = styled(ListItemButton)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(7),
  fontWeight: theme.typography.fontWeightRegular,
  "&:hover": {
    background: "rgba(0, 0, 0, 0.08)",
    color: theme.palette.background.sidebar,
  },
}))

const TitleContainer = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  color: `rgba(${theme.palette.background.sidebar}, 1)`,
  fontSize: theme.typography.body1.fontSize,
  padding: theme.spacing(2),
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.common.white,
}))

const Badge = styled(Chip)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  height: 20,
  background: theme.palette.primary.main,
  zIndex: 1,
  "span.MuiChip-label, span.MuiChip-label:hover": {
    fontSize: 11,
    cursor: "pointer",
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const SidebarNavListItem = props => {
  const { module, currentValue, setCurrent, hasAdaptiveTest } = props
  const { current } = useContentState()

  console.log(hasAdaptiveTest)
  var moduleProgress = {
    score: module.score,
    completed: module.score === module.max_score,
    max_score: module.max_score,
  }
  var isEqual = JSON.stringify(currentValue) == JSON.stringify(current)

  return (
    <>
      <Item
        onClick={() => setCurrent(currentValue)}
        activeClassName="active"
        disabled={!module.is_active || !!hasAdaptiveTest}
        style={isEqual ? { backgroundColor: "#376fd050" } : {}}
      >
        {moduleProgress && <CircleIcon style={{ opacity: moduleProgress.completed, width: 8, color: "#4782da" }} />}
        <TitleContainer>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Title style={{ marginRight: 10 }}>{module.title}</Title>
            {!module.is_timer && !!moduleProgress.max_score && moduleProgress && (
              <Badge label={`${moduleProgress.score}/${moduleProgress.max_score}`} />
            )}
          </div>
        </TitleContainer>
      </Item>
    </>
  )
}

export default SidebarNavListItem
