import React, { useEffect, useState } from "react"
import { Button, Modal, TextField } from "shared/ui"
import { useAppSelector } from "../../shared/hooks/useAppSelector"
import * as Styled from "./ui/Iot.styles"
import { Hardware as HardwareType } from "entities/Iot"
import { RequestMessage } from "../../shared/assets/RequestMessage/RequestMessage"
import { Typography } from "@mui/material"

interface IProps {
  isOpen: boolean,
  onClose: () => void
}

export const EditHardware = ({ isOpen, onClose }: IProps) => {

  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const [hardware, setHardware] = useState<HardwareType | null>(null)

  useEffect(() => {
    if (!!currentHardware) {
      setHardware({ ...currentHardware })
    }
  }, [currentHardware])

  function handleChangeField(event: React.ChangeEvent<HTMLInputElement>, field: "name") {
    if (!!hardware) {
      hardware[field] = event.target.value
      setHardware({ ...hardware })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"default"} title={`Параметры устройства ${currentHardware?.name}`}>
      <Styled.DialogMainContainer>
        {/*@ts-ignore*/}
        <Typography variant={"body1"} fontSize={"medium"} fontWidth={"medium"}>{currentHardware?.name}</Typography>
        <Button disabled={true} variant={"outlined"} color={"accent"}>Удалить устройство</Button>
      </Styled.DialogMainContainer>
    </Modal>
  )
}