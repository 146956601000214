import React from "react"
import { Chip, Typography } from "@mui/material"
import * as Styled from "./Webinar.styles"
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined"
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined"

export const FormatOfWebinar = ({ type }: { type: "online" | "offline" | "hybrid" }) => {
  return (
    <>
      {type !== "hybrid" && <FormatChip type={type} />}
      {type === "hybrid" && (
        <>
          <FormatChip type={"online"} />
          <FormatChip type={"offline"} />
        </>
      )}
    </>
  )
}

const FormatChip = ({ type }: { type: "online" | "offline" }) => {
  function getIcon() {
    if (type === "online") return <SensorsOutlinedIcon sx={{ color: "#FFF" }} />
    else return <GroupOutlinedIcon sx={{ color: "#FFF" }} />
  }

  return (
    <Chip
      sx={{ backgroundColor: type === "online" ? "#6892FF" : "#FF5574" }}
      label={
        <Styled.FormatChipDiv>
          {getIcon()}{" "}
          <Typography variant={"caption"} fontWeight={"medium"} sx={{ color: "#FFF" }}>
            {type === "online" ? "Онлайн" : "Оффлайн"}
          </Typography>
        </Styled.FormatChipDiv>
      }
    />
  )
}
