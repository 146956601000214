import { useEffect, useState } from "react"

const useErrorMsg = <T>(value: T, delay = 10000) => {
  const [errorMsg, setErrorMsg] = useState<T | null>(value)

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg(null)
    }, delay)
  }, [errorMsg])

  return { errorMsg, setErrorMsg }
}

export default useErrorMsg
