// @ts-ignore
import Cookies from "js-cookie"
import { ICourse } from "entities/Course/api/course.types"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { CoursesPayload, IUpdateStatus } from "./participant.types"
import { IParticipantProgress } from "../model/participant.types"

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

export const updateMassPaticipants = async (payload: IUpdateStatus) =>
  await axios.put(`/content/participants/mass_update/`, payload)

export const getParticipantCourses = async (pyaload: CoursesPayload): Promise<ICourse[]> =>
  axios.get(`content/actions/participants/my_courses/`, { params: pyaload }).then(res => res.data)

export const deleteCourseProgress = async (progressId: number) =>
  axios.delete(`/content/progress/${progressId}/restart/`)

export const deleteModuleProgress = async (payload: {}): Promise<IParticipantProgress> =>
  axios.patch(`/content/module_progress/score/`, payload).then(res => res.data)

export const saveProgress = async (payload: {}): Promise<IParticipantProgress> =>
  await axios.put(`/content/module_progress/score/`, payload).then(res => res.data)

export const fetchParticipantStatus = async () => await axios.get("/content/actions/participants/is_staff/")

export const setParticipantStatus = async (params: Record<string, string | number | undefined>) =>
  await axios.put(`/content/participants/set_status/`, params)

export const setParticipantCompleted = async (params: Record<string, string | number | boolean>) =>
  await axios.put(`/content/progress/${params.progress}/score/`, { score: params.score, completed: params.completed })

export const resetParticipantCompleted = async (params: Record<string, string | number | boolean>) =>
  await axios.delete(`/content/progress/${params.progress}/restart/`)
