export const tableHeader = [
  "Индекс",
  "Название",
  "Начало",
  "Конец",
  "ID",
  "Тип контента",
  "Тип доступа",
  "Доступен/ Недоступен",
  "Обязательный для прохождения",
  "",
]
