import React, { useEffect, useState } from "react"
import * as Styled from "./ui/Tags.styles"
import { Autocomplete, Button } from "../../shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { RequestMessage } from "../../shared/assets/RequestMessage/RequestMessage"

export const AddTagToCourse = ({
  course_id,
  pushTag,
}: {
  course_id: number
  pushTag: (id: number, value: string) => void
}) => {
  const [platformTags, setPlatformTags] = useState<Array<{ value: number; label: string }>>([])
  const [currentTag, setCurrentTag] = useState<null | { value: number; label: string }>(null)
  const [error, setError] = useState<boolean | null>(null)
  const [text, setText] = useState("")

  useEffect(() => {
    axios.get("content/tags/").then(res => {
      setPlatformTags(res.data.map((e: { value: string; id: number }) => ({ label: e.value, value: e.id })))
    })
  }, [])

  function handleAddTag() {
    if (!!currentTag) {
      axios
        .put(`content/courses/${course_id}/add_tags/`, { tags: [currentTag.value] })
        .then(res => {
          setError(false)
          setText("Тег добавлен к курсу")
          if (!!currentTag) pushTag(currentTag.value, currentTag.label)
        })
        .catch(err => {
          setError(true)
          setText("Ошибка. Данный тег уже добавлен к курсу.")
        })
    }
  }

  return (
    <Styled.AddTagToCurseWrapper>
      {/*@ts-ignore*/}
      <Autocomplete
        label={"Добавить тег"}
        value={currentTag}
        options={platformTags}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          newValue: {
            label: string
            value: number
          }
        ) => setCurrentTag(newValue)}
        width={225}
      />
      {/*@ts-ignore*/}
      <Button variant={"contained"} color={"primary"} disabled={!currentTag} onClick={handleAddTag}>
        Добавить тег
      </Button>
      {text.length > 0 && <RequestMessage error={!!error} text={text} />}
    </Styled.AddTagToCurseWrapper>
  )
}
