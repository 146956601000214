// @ts-ignore
import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { ICourse, ICoursePayload, IMyStatus } from "./course.types"
import { IParticipantsResponse } from "entities/Organization/api/types"
import { GenericAbortSignal } from "axios"

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

export const getMyCourses = async (status: string): Promise<ICourse[]> =>
  await axios.get("content/actions/participants/my_courses/", { params: { status } }).then(res => res.data)

export const getCourse = async (courseId: number, params?: ICoursePayload): Promise<ICourse> =>
  await axios.get(`/content/courses/${courseId}/`, { params }).then(res => res.data)

export const getCourseBySlug = async (slug: string): Promise<ICourse[]> =>
  await axios.get(`/content/courses`, { params: { slug } }).then(res => res.data)

export const getMyStatus = async (slug: string): Promise<IMyStatus> =>
  await axios
    .get(`/content/actions/courses/my_status/`, {
      params: {
        course__slug: slug,
      },
    })
    .then(res => res.data)

export const giveStageAccess = async (
  stage: number,
  payload: {
    participants: number[]
  }
) => await axios.put(`/content/stages/${stage}/add_participants/`, payload)

export const removeStageAccess = async (
  stage: number,
  payload: {
    participants: number[]
  }
) => await axios.patch(`/content/stages/${stage}/add_participants/`, payload)

export const massStatusUpdate = async (payload: Record<string, any>) =>
  await axios.put("/content/participants/mass_update/", payload)

export const fetchUserCourses = async (params?: { [key: string]: string | undefined }) =>
  await axios.get("/content/actions/participants/my_courses/", { params: params }).then(
    (res: {
      data: {
        course_title: string
        course: number
        course_type: "event" | "course" | "webinar"
      }[]
    }) => res.data
  )

export const getCourseParticipants = async (payload: Record<string, any>): Promise<IParticipantsResponse> =>
  await axios.get(`/content/participants/`, { params: payload }).then(res => res.data)

export const downloadCourseParticipants = async (payload: Record<string, any>): Promise<IParticipantsResponse> =>
  await axios.get(`/content/participants/`, { params: payload, responseType: "arraybuffer" })

export const fetchTags = async () => await axios.get("content/tags/")

export const fetchOrgTags = async (id: number) => await axios.get(`organizations/${id}/courses_tags`)

export const fetchCourseList = async (
  params: {
    [key: string]: string | number | null | undefined | boolean
  },
  signal: GenericAbortSignal | undefined
) => await axios.get("/content/courses/", { params: { ...params }, signal: signal })

export const fetchCourseTeams = async (
  course_id: number
): Promise<
  {
    id: number
    title: string
  }[]
> => await axios.get("content/teams", { params: { course: course_id } }).then(res => res.data)
