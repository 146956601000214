import React, {useState} from 'react';
import useStyles from "./styles"
import {Dialog, DialogContent, DialogContentText, Grid} from "@mui/material";

const VideoGalleryItem = ({e}) => {

    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false)

    function handleClick(){
        setOpenDialog(true)
    }

    return (
        <Grid>
            <img src={e.preview} className={classes.previewItem} onClick={handleClick}/>
            <Dialog
                aria-labelledby="DialogTitle"
                aria-describedby="DialogDescription"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                fullWidth
                maxWidth="md"
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, // Set transparent black background for the backdrop
                }}
                PaperProps={{
                    style: { backgroundColor: 'transparent', boxShadow: 'none', overflow: 'hidden' }, // Set transparent background for the dialog content
                }}            >
                <DialogContent style={{ backgroundColor: "rgba(255, 0, 0, 0)"}}>
                    <DialogContentText id="DialogDescription" style={{ height: "100%" }}>
                        <video autoPlay={true} width="100%" loop={true} controls={true} >
                            <source
                                src={e.video}
                                type="video/mp4"/>
                        </video>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default VideoGalleryItem;
