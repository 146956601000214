import React from "react"
import { Typography } from "@mui/material"

import { Header, Footer } from "widgets"

const NoCertificate = props => {
  return (
    <div>
      <Header variant={"shadow"} />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
        <Typography variant="h3" maxWidth="50vw">
          Не выполнены условия получения сертификата. Если Вы считаете, что это ошибка, напишите в телеграмме&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={!!props.tg ? "https://" + props.tg : "https://t.me/syncwoiacom"}
          >
            {props.tg || "t.me/syncwoiacom"}
          </a>{" "}
          или на почту: support@syncwoia.com.
        </Typography>
      </div>
      <Footer />
    </div>
  )
}

export default NoCertificate
