const BadgeNewbieIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2285 10.6029C10.2285 9.16092 10.8013 7.77804 11.8209 6.75843C12.8405 5.73882 14.2234 5.16602 15.6654 5.16602C17.1073 5.16602 18.4902 5.73883 19.5098 6.75843C20.5294 7.77804 21.1022 9.16092 21.1022 10.6029C21.1022 12.0448 20.5294 13.4277 19.5098 14.4473C18.4902 15.4669 17.1073 16.0397 15.6654 16.0397C14.2234 16.0397 12.8405 15.4669 11.8209 14.4473C10.8013 13.4277 10.2285 12.0448 10.2285 10.6029Z"
        fill="#DEE7F2" stroke="#1565C0" />
      <path
        d="M3.15625 26.4062C3.15625 22.8164 6.0664 19.9062 9.65625 19.9062H21.6748C25.2647 19.9062 28.1748 22.8164 28.1748 26.4062V27.1113C28.1748 27.9398 27.5032 28.6113 26.6748 28.6113H4.65625C3.82782 28.6113 3.15625 27.9398 3.15625 27.1113V26.4062Z"
        fill="#DEE7F2" stroke="#1565C0" />
    </svg>
  )
}

export default BadgeNewbieIcon
