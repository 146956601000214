import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    tooltip: {
        width: 400,
        backgroundColor: "#555",
        color: "#fff",
        borderRadius: 6,
        padding: "7px 10px",
        zIndex: 1,
        opacity: 0,
        transition: "opacity 0.3s",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.2,
        fontStyle: "normal",
        fontFamily: "Roboto"
    },
    tooltipArrow: {
        color: "#555",
    },
}));
