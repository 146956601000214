import { getCourse } from "entities/Course/api/course"
import { getOrganizationEventStatistics, getOrganizationParticipants } from "entities/Organization/api"
import { convertAnswersToCharts } from "entities/Organization/lib/statistics"
import { IEvent } from "entities/Organization/model/Organization.types"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { RangesType, StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { IAnswer } from "entities/Organization/model/useStatisticsDataList"
import { getDiagramColor } from "entities/Organization/static/statistics"
import { useEffect, useState } from "react"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useTab } from "shared/hooks/useTab"
import { courseActions, organizationActions } from "shared/store"

export type WindowType = "menu" | "statistics"

const useSurveyStatistics = () => {
  const type = "survey" as StatisticsVariants
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const { course } = useAppSelector(state => state.course)
  const { dateRange, setChart, setTableData, resetStatistics, eventFilters } = useStatistics()
  const event = useAppSelector(state => state.organization.event)
  //const [event, setEvent] = useState<IEvent>()
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [submitTrigger, setSubmitTrigger] = useState(false)
  const { tab: windowTab, changeTab: setWindowTab } = useTab("statistics_survey")
  const { answers } = eventFilters
  const dispatch = useAppDispatch()
  const dateRangePayload = {
    date_range: dateRange.type,
    start: dateRange.type === "custom" ? dateRange.start : undefined,
    finish: dateRange.type === "custom" ? dateRange.finish : undefined,
  }

  useEffect(() => {
    requestCourses()
  }, [])

  useEffect(() => {
    onSelectedCourse()
  }, [course])

  const requestCourses = async () => {
    if (!organization?.id) return
    try {
      const response = await getOrganizationParticipants(organization?.id)
      const courses = response.map((course: IEvent) => ({ id: course.id, label: course.title }))
      const options = courses.length ? [{ id: null, label: "Не выбрано" }, ...courses] : []
      dispatch(courseActions.setCourses(options))
    } catch {}
  }

  const onSelectedCourse = () => {
    if (!course) return setWindowTab("", 0)
    dispatch(organizationActions.setEvent(event))
  }

  const handleChangeCourse = async (_: any, newValue: any) => {
    setWindowTab("", 0)
    resetStatistics(type)
    if (newValue && organization?.id) {
      try {
        setIsLoadingChart(true)
        setIsLoadingTable(true)
        const response = await getCourse(newValue.id)
        dispatch(organizationActions.setEvent(response))
        dispatch(courseActions.setCourse(response))
      } catch {
        dispatch(organizationActions.setEvent(undefined))
      } finally {
        setIsLoadingChart(false)
        setIsLoadingTable(false)
      }
    } else {
      dispatch(courseActions.setCourse(undefined))
      dispatch(organizationActions.setEvent(undefined))
    }
  }

  const onSubmit = async (dateRangePayloadProp?: { date_range: RangesType; start?: string; finish?: string }) => {
    const currentDateRangePayload = dateRangePayloadProp ?? dateRangePayload
    const parsedAnswers = Object.groupBy(answers, answer => answer.question.id) as Record<number, IAnswer[]>
    try {
      setSubmitTrigger(true)
      resetStatistics(type)
      await fetchChart(parsedAnswers, currentDateRangePayload)
      await fetchTable(parsedAnswers, currentDateRangePayload)
    } catch {
    } finally {
      setIsLoadingChart(false)
      setSubmitTrigger(false)
    }
  }

  const fetchChart = async (
    parsedAnswers: Record<number, IAnswer[]>,
    dateRange: { date_range: RangesType; start?: string; finish?: string }
  ) => {
    if (!organization?.id) return
    try {
      setIsLoadingChart(true)
      const requests = Object.keys(parsedAnswers).map((questionId: string) => {
        const answersString = parsedAnswers[questionId as unknown as number]
          .map((answer: IAnswer) => answer.title)
          .join(",")
        const payload = {
          type: "survey_chart",
          infofield_id: questionId,
          variants: answersString,
          ...dateRange,
        }
        return getOrganizationEventStatistics(organization?.id, payload).then(res => res.data)
      })
      const data = await Promise.all(requests)
      setWindowTab("", 1)
      const questions:{[key:number]:number} = {}
      const coloredAnswers = answers?.map((answer) => {
        const questionId = Object.keys(parsedAnswers).find(questionId => Number(questionId) === answer.question.id)!
        const questionIndex = Object.keys(parsedAnswers).indexOf(questionId)
        questions[questionIndex] = questions[questionIndex] + 1 || 0
        return {
          ...answer,
          color: getDiagramColor(questionIndex,questions[questionIndex]),
          data: data[questionIndex][answer.title] || 0,
        }
      })
      const chartData = convertAnswersToCharts(coloredAnswers)
      setChart(type, chartData)
    } catch {
    } finally {
      setIsLoadingChart(false)
    }
  }

  const fetchTable = async (
    parsedAnswers: Record<number, IAnswer[]>,
    dateRange: { date_range: RangesType; start?: string; finish?: string }
  ) => {
    if (!organization?.id) return
    try {
      setIsLoadingTable(true)
      const questionId = Object.keys(parsedAnswers)[0]
      const answersString = Object.values(parsedAnswers)
        .flat(1)
        .map((answer: IAnswer) => answer.title)
        .join(",")
      const tablePyaload = {
        type: "survey_table",
        infofield_id: questionId,
        variants: answersString,
        page: 1,
        ...dateRange,
      }
      const tableResponse = await getOrganizationEventStatistics(organization?.id, tablePyaload)
      setTableData(type, {
        count: tableResponse.count,
        next: tableResponse.next,
        results: tableResponse.results.data,
        isLoaded: true,
      })
    } catch {
    } finally {
      setIsLoadingTable(false)
    }
  }

  return {
    event,
    onSubmit,
    isLoadingChart,
    isLoadingTable,
    submitTrigger,
    windowTab,
    setWindowTab,
    handleChangeCourse,
  }
}

export default useSurveyStatistics
