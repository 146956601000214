import { wordMapper } from "shared/local"
import { Button, MenuItem, Menu } from "@mui/material"
import { NoOptions, useAnimation, useStyles } from "./Dropdown.styles"
import { IDropdown } from "./Dropdown.types"
import themes from "shared/themes"

export const Dropdown = (props: IDropdown) => {
  const {
    id,
    name,
    value,
    isTranslate = true,
    onChange,
    disabled,
    options,
    label,
    width,
    style,
    size,
    customWordMapper,
  } = props
  const { anchorEl, handleClick, handleClose } = useAnimation()
  const { Title, Arrow, getLabelStyle, getButtonStyle, MenuListStyle } = useStyles(anchorEl, value, width)

  return (
    <>
      <Button
        onClick={handleClick}
        sx={getButtonStyle(style, size)}
        disabled={disabled}
        size={size}
        aria-label="more"
        aria-controls={"long-menu"}
        aria-expanded={"true"}
        aria-haspopup="true"
      >
        <span style={getLabelStyle(size, disabled)}>{label}</span>
        {/* @ts-ignore */}
        <Title style={{ color: disabled && themes.default.palette.additional.light }}>
          {value !== null && value !== "" && isTranslate
            ? wordMapper(
                Object.keys(options).find((option: string) => options[option as any] === value),
                customWordMapper
              )
            : value}
        </Title>
        <Arrow />
      </Button>
      <Menu
        disablePortal
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "long-button",
          style: MenuListStyle,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        elevation={0}
      >
        {Object.keys(options).map((optionKey: any, index) => (
          <MenuItem
            key={optionKey + index}
            value={optionKey}
            onClick={e => {
              onChange({ target: { value: options[optionKey as any], name: name || "", label: optionKey } })
              handleClose()
            }}
          >
            {isTranslate || optionKey === "" || optionKey === null
              ? wordMapper(optionKey, customWordMapper)
              : optionKey}
          </MenuItem>
        ))}
        {!Object.keys(options).length && <NoOptions>Нет опций для выбора</NoOptions>}
      </Menu>
    </>
  )
}
