import React, { useState, useEffect } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import useStyles from "./styles";
import { backgroundStyle } from "./styles";

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }} ></div>
  } else {
    return text
  }
}

export const SingleChoice = ({ question, value, handleChange, result, shuffle }) => {
  const classes = useStyles()
  const [variants, setVariants] = useState(question.variants);

  useEffect(() => {
    if (!question.disableShuffle) {
      setVariants([...shuffle(question.variants)])
    }
  }, [question])

  return (
    <RadioGroup
      className={classes.testButtons}
      style={{ maxWidth: 800, ...backgroundStyle(result) }}
      value={value || ""}
      onChange={handleChange}
      name={question.index}
    >
      {
        variants.map(c => (
          <FormControlLabel
            className={classes.testCheckbox}
            classes={{ label: classes.testCheckboxLabel }}
            value={c.value}
            control={<Radio color="primary" />}
            label={parseText(c.label)}
          />
        ))
      }
    </RadioGroup>
  )
}
