import React from "react"
import { Chip, styled, Typography } from "@mui/material"

export const TagChip = ({ id, value, deleteTag }: { id: number; value: string; deleteTag: (id: number) => void }) => {
  return <StyledChip onClick={() => deleteTag(id)} label={<Typography>{value}</Typography>} />
}

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "#FCF4E3",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.5,
  },
}))
