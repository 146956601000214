import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { Autocomplete, Button } from "shared/ui"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { addParticipantInPersonalModule, deleteParticipantInPersonalModule } from "../../entities/Module/api/module"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import { Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

export const EditModuleParticipants = ({
  users,
  emails,
  module_id,
}: {
  emails: string[]
  users: number[]
  module_id: number
}) => {
  const [currentParticipant, setCurrentParticipant] = useState<
    | {
        label: string
        value: number
      }
    | undefined
  >(undefined)
  const participants = useAppSelector(state => state.trajectory.participants)
  const [moduleParticipants, setModuleParticipants] = useState<
    {
      id: number
      email: string
    }[]
  >([])
  const [textRequest, setTextRequest] = useState("")
  const [error, setError] = useState(false)

  useEffect(() => {
    let module_parts: {
      id: number
      email: string
    }[] = []
    users.forEach((item, index) => {
      module_parts.push({ id: item, email: emails[index] })
    })
    setModuleParticipants([...module_parts])
  }, [])

  function addParticipantInModule() {
    if (!!currentParticipant) {
      if (moduleParticipants.filter(e => e.id === currentParticipant.value).length > 0) {
        setTextRequest("Пользователь уже добавлен")
        setError(true)
      } else {
        addParticipantInPersonalModule([currentParticipant.value], module_id)
          .then(res => {
            setModuleParticipants(prev => [...prev, { id: currentParticipant.value, email: currentParticipant.label }])
            setTextRequest("Пользователь добавлен")
            setError(false)
            setTimeout(() => {
              setTextRequest("")
            }, 10000)
          })
          .catch(err => {
            setTextRequest("Ошибка")
            setError(true)
            setTimeout(() => {
              setTextRequest("")
            }, 10000)
          })
      }
    }
  }

  function deleteParticipant(id: number) {
    deleteParticipantInPersonalModule([id], module_id)
      .then(res => {
        setModuleParticipants(prev => [...prev.filter(e => e.id !== id)])
      })
      .catch(err => {})
  }

  return (
    <MainDiv>
      <UsersDiv>
        {moduleParticipants.map(e => (
          <UserDiv>
            <Typography variant={"body1"} fontSize={"small"} fontWeight={"medium"}>
              {e.email}
            </Typography>
            <CloseIcon sx={{ color: "#D82E2E", cursor: "pointer" }} onClick={() => deleteParticipant(e.id)} />
          </UserDiv>
        ))}
      </UsersDiv>
      <AddUserDiv>
        <Autocomplete
          //@ts-ignore
          onChange={(e, newValue) => setCurrentParticipant(newValue)}
          style={{ width: "300px" }}
          value={currentParticipant?.label}
          options={participants.map(e => ({ label: e.email, value: e.id }))}
          label={"Выберите пользователя"}
        />
        <Button onClick={addParticipantInModule} variant={"outlined"} color={"primary"}>
          Добавить пользователя
        </Button>
        {textRequest.length > 0 && <RequestMessage error={error} text={textRequest} />}
      </AddUserDiv>
    </MainDiv>
  )
}

const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
}))

const AddUserDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(4),
}))

const UsersDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  border: "1px solid #E0E0E0",
  gap: theme.spacing(4),
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
  minHeight: theme.spacing(10),
  maxHeight: theme.spacing(24),
  overflowY: "auto",
}))

const UserDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
}))
