import React, { useState } from "react"
import { ErrorMapType, SectionType, SubsectionType } from "../api/module.types"
import * as Styled from "./Module.styles"
import { Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material"
import { Button, Modal, TextField } from "shared/ui"
//@ts-ignore
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { SubsectionItem } from "./SubsectionItem"
import { moveItemInArray } from "shared/util"
import AddIcon from "@mui/icons-material/Add"

export const Subsections = ({
  subsections,
  handleChangeSubsections,
  deletedSubsections,
  setDeletedSubsections,
  section_id,
  errors,
  section_index,
}: {
  errors: ErrorMapType
  section_index: number
  section_id: number
  deletedSubsections: Array<number | null>
  setDeletedSubsections: React.Dispatch<React.SetStateAction<(number | null)[]>>
  subsections: SubsectionType[]
  handleChangeSubsections: (subsections: SubsectionType[]) => void
}) => {
  const [openCreateNewSubsection, setOpenCreateNewSubsection] = useState(false)

  function onDragEnd(result: {
    destination?: {
      index: number
    }
    source?: {
      index: number
    }
  }) {
    if (!result.source || !result.destination) return
    let new_subsections = [...subsections]
    moveItemInArray(new_subsections, result.source.index, result.destination.index)
    for (let i = 0; i < subsections.length; i++) {
      new_subsections[i].index = i + 1
    }
    handleChangeSubsections([...new_subsections])
  }

  function deleteSubsection(subsection: SubsectionType) {
    let index = subsections.indexOf(subsection)
    let new_subsections = [...subsections]
    new_subsections.splice(index, 1).sort((a, b) => (a.index > b.index ? 1 : -1))
    for (let i = 0; i < new_subsections.length; i++) {
      new_subsections[i].index = i + 1
    }
    handleChangeSubsections([...new_subsections])
    setDeletedSubsections(prev => [...prev, subsection.id])
  }

  function createNewSubsection(params: SubsectionType) {
    let new_subsections = [...subsections]
    new_subsections = [...new_subsections, { ...params, index: subsections.length + 1, section: section_id }]
    handleChangeSubsections([...new_subsections])
    setOpenCreateNewSubsection(false)
  }

  function handleChangeSubsection(index: number, field: keyof SubsectionType, value: string | boolean) {
    let new_subsections = subsections
    //@ts-ignore
    new_subsections[index][field] = value
    handleChangeSubsections([...new_subsections])
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Styled.CardSectionsSettings>
        <Droppable droppableId="draggable-4">
          {(provided: any, snapshot: any) => (
            <div
              ref={provided.innerRef}
              style={{ width: "100%", padding: "4px 4px", zIndex: "10" }}
              {...provided.droppableProps}
            >
              {subsections
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((item, index) => (
                  <Draggable draggableId={"draggable-" + index} index={index}>
                    {(provided: any, snapshot: any) => (
                      <SubsectionItem
                        disableDelete={subsections.length === 1}
                        section_index={section_index}
                        errors={errors}
                        index={index}
                        handleChangeSubsection={handleChangeSubsection}
                        handleDeleteSubsection={deleteSubsection}
                        subsection={item}
                        provided={provided}
                      />
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <CreateSubsectionModal createNewSubsection={createNewSubsection} section={0} />
      </Styled.CardSectionsSettings>
    </DragDropContext>
  )
}

const CreateSubsectionModal = ({
  createNewSubsection,
  section,
}: {
  createNewSubsection: (section: SubsectionType) => void
  section: number
}) => {
  const [form, setForm] = useState<SubsectionType>({
    id: null,
    title: "",
    is_active: false,
    index: 0,
    section: section,
  })

  const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>, field: "title") => {
    if (event.target.value.length === 128) return
    form.title = event.target.value
    setForm({ ...form })
  }

  function handleClickCreate() {
    createNewSubsection({ ...form })
  }

  return (
    <Styled.CreateSectionModal>
      <TextField
        style={{ width: "300px" }}
        variant={"outlined"}
        label={"Название подраздела"}
        value={form.title}
        onChange={e => handleChangeField(e, "title")}
      />
      <Button disabled={form.title.length === 0} color={"primary"} variant={"outlined"} onClick={handleClickCreate}>
        Добавить подраздел
        <AddIcon sx={{ height: "100%", padding: 0, marginLeft: 2 }} />
      </Button>
    </Styled.CreateSectionModal>
  )
}
