import React from "react";
import { Typography } from "@mui/material";

import { Card } from "shared/ui";

export const ProgressData = ({ progress }) => {
  return (
    <Card style={{ maxWidth: 240 }}>
      <Typography variant="h6">
        {`ID #${progress.course}`}
      </Typography>
      {Object.entries(progress).map(e => (
        e[0] != "id" && e[0] != "course" && e[0] != "email" && e[0] != "created" && e[0] != "info"
        && e[0] != "full_name" && e[0] != "phone" && e[0] != "user" && e[0] != "teams" &&
        <Typography>
            {`${e[0]}: ${e[1]}`}
        </Typography>
      ))}
    </Card>
  )
}