export const chartsSettings = {
  datarevision: 0,
  height: 150,
  margin: { l: 40, r: 0, t: 40, b: 30 },
  hoverlabel: { bgcolor: "rgba(255,255,255,0.1)", align: "left" },
  xaxis: {
    tickformat: "%b %d",
  },
}

export const settingsDataOfChartSensorCard = {
  x: [],
  y: [],
  text: [],
  hoverTemplate: "<br>{text}<br>",
  type: "scatter",
  mode: "lines",
  hoverinfo: "text",
  marker: { color: "#B4C7EB" },
}

export const settingsBigChart = {
  datarevision: 0,
  height: 500,
  margin: { l: 40, r: 0, t: 70, b: 30 },
  xaxis: {
    tickformat: "%b %d",
  },
  plot_bgcolor: "transparent",
  paper_bgcolor: "transparent",
  hoverlabel: { align: "left" },
  legend: {
    orientation: "h",
    y: 1.02,
    x: 0,
    xanchor: "left",
    yanchor: "bottom",
    bgcolor: "rgba(255, 255, 255, 0.5)",
  },
}
