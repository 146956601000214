export function stringifyDates(date: string): string {
  let dateObj = new Date(date)
  let timeZoneOffset = new Date().getTimezoneOffset()
  const options = { day: "numeric", month: "long", year: "numeric" }
  return dateObj.toLocaleDateString("ru-RU", options as Intl.DateTimeFormatOptions)
}

export function compareDate(date: string) {
  let utc_now_date = new Date()
  let now_date = new Date(utc_now_date.getTime() + new Date().getTimezoneOffset() * 1000 * 60).getTime()
  let finishDate = new Date(date).getTime()
  return now_date <= finishDate
}
