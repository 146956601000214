import React, {useState} from "react";
import useStyles from "./styles";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import { axiosInstance as axios } from "shared/api/axiosInstance";
import Cookies from 'js-cookie';

export const AdminStaticLoaderPannel = () => {
  var classes = useStyles();
  

  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState("");
  const [filePath, setFilePath] = useState("");
  const fileInput = React.createRef();

  function uploadAdminStatic() {
    setIsLoading(true)
    let file = fileInput.current.files[0]
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    axios.post(`/upload-statics/`, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `attachment; filename=${file.name}`
      }
    }).then(res => {
      setSuccess(true)
      setFilename(res.data.link)
      setIsLoading(false)
    }).catch(err => {
      setSuccess(false)
      setIsLoading(false)
    })
  }

  function deleteAdminStatic() {
    setIsLoading(true)
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    axios.put(`/delete-statics/`, { name: filePath }).then(res => {
      setSuccess(true)
      setIsLoading(false)
    }).catch(err => {
      setSuccess(false)
      setIsLoading(false)
    })
  }

  return (
    <div style={{ width: 1200, padding: 48 }}>
      <div style={{ display:"flex", alignItems:"center", outline: "gray", gap: 50 }}>
        <Button
          component="label"
          className={classes.checkButtonWt}
        >
          Выбрать файл
          <input type="file" hidden ref={fileInput} onChange={(e) => {
              if (!!e.target.files[0]) {
                setFilename(e.target.files[0].name) 
              }
              setSuccess(null)
            }}
          />
        </Button>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            className={classes.checkButton}
            onClick={uploadAdminStatic}
            disabled={!fileInput.current ? (false) : (fileInput.current.files.length === 0)}
          >
            Отправить
          </Button>
        )}
        <Typography className={classes.textFileName}>
          {filename}
        </Typography>
      </div>
      <Typography className={classes.textMess} style={ success ? { color: "#36d136" } : { color: "#d13636" } }>
        {success === null ? "" : (success ? "Отправлено" : "Ошибка при отправке")}
      </Typography>
      <hr />
      <Typography variant="body1">
        На данный момент можно удалять только папки. Чтобы удалить папку по адресу
        https://syncwoia.com/static/media/extra/1/folder1/folder2/ нужно отправить ввести в поле следующую строку: folder1__folder2
        и нажать Удалить. Число после extra не играет роли.
      </Typography>
      <TextField
        variant="outlined"
        value={filePath}
        onChange={e => setFilePath(e.target.value)}
        helperText="Введите строку вида folder1__folder2"
        fullWidth
        style={{ marginTop: 12,  marginBottom: 12 }}
      />
      {isLoading ? (
        <CircularProgress size={26} />
      ) : (
        <Button
          className={classes.checkButton}
          onClick={deleteAdminStatic}
          disabled={filePath === ""}
        >
          Удалить
        </Button>
      )}
    </div>
  )
}
