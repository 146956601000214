import React, { ChangeEvent, useEffect, useState } from "react"
import { ContentType, ErrorMapType, ModuleType } from "../api/module.types"
import { BlockModuleConfig, IModuleConfig, settingsModuleConfig } from "../static/moduleConfigs"
import { TextField } from "shared/ui"
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { FileUploader } from "shared/assets"
import { useAppSelector } from "../../../shared/hooks/useAppSelector"
import { trajectoryActions } from "../../../shared/store"
import { useAppDispatch } from "../../../shared/hooks/useAppDispatch"

interface IProps {
  module: ModuleType
  setModule: React.Dispatch<React.SetStateAction<ModuleType | null>>
  errors: ErrorMapType | null
}

type k = keyof ModuleType

export const ModuleSettingsMapper = ({ module, setModule, errors }: IProps) => {
  const [moduleConfig, setModuleConfig] = useState<IModuleConfig>([])
  const course_type = useAppSelector(state => state.trajectory.course_type)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setModuleConfig([...settingsModuleConfig(module.content_type, course_type || "")])
  }, [module.content_type, course_type])

  function handleChangeField(x: ChangeEvent<HTMLInputElement>, name: k) {
    dispatch(trajectoryActions.setIsChanged(true))
    module[name] = x.target.value as never
    !!module && setModule({ ...module })
  }

  function handleChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>, name: k) {
    dispatch(trajectoryActions.setIsChanged(true))
    module[name] = event.target.checked as never
    setModule({ ...module })
  }

  function handleUploadFile(file: string) {
    dispatch(trajectoryActions.setIsChanged(true))
    module.image = file
    setModule({ ...module })
  }

  function contentMapper(e: BlockModuleConfig) {
    if (!e.visible) return <></>
    switch (e.type_field) {
      case "TextField":
        return (
          <TextField
            error={!!errors && !!errors[e.field]}
            helperText={!!errors ? errors[e.field] : ""}
            label={e.label}
            value={!!module[e.field] ? String(module[e.field]) : ""}
            variant={"outlined"}
            style={{ width: "100%", marginTop: "24px" }}
            onChange={event => handleChangeField(event, e.field)}
          />
        )
      case "Number":
        return (
          <TextField
            error={!!errors && !!errors[e.field]}
            helperText={!!errors ? errors[e.field] : ""}
            label={e.label}
            value={!!module[e.field] ? String(module[e.field]) : ""}
            variant={"outlined"}
            style={{ width: "100%", marginTop: "24px" }}
            /*@ts-ignore*/
            onChange={event => handleChangeField(event, e.field)}
          />
        )
      case "Date":
        return (
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>
              <Typography variant={"caption"} fontWeight={"medium"} sx={{ color: "#313439" }}>
                {e.label}
              </Typography>
            </FormLabel>
            <TextField
              error={!!errors && !!errors[e.field]}
              helperText={!!errors ? errors[e.field] : ""}
              label={""}
              value={!!module[e.field] ? String(module[e.field]) : null}
              variant={"outlined"}
              style={{ width: "100%" }}
              /*@ts-ignore*/
              params={{ type: "date" }}
              onChange={event => handleChangeField(event, e.field)}
            />
          </FormControl>
        )
      case "Time":
        return (
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>
              <Typography variant={"caption"} fontWeight={"medium"} sx={{ color: "#313439" }}>
                {e.label}
              </Typography>
            </FormLabel>
            <TextField
              error={!!errors && !!errors[e.field]}
              helperText={!!errors ? errors[e.field] : ""}
              label={""}
              value={!!module[e.field] ? String(module[e.field]) : null}
              variant={"outlined"}
              style={{ width: "100%" }}
              /*@ts-ignore*/
              params={{ type: "time" }}
              onChange={event => handleChangeField(event, e.field)}
            />
          </FormControl>
        )
      case "SingleChoise":
        return (
          <FormControl>
            <Typography variant={"caption"} fontWeight={"medium"}>
              {e.label}
            </Typography>
            <RadioGroup
              sx={{ gap: "0px" }}
              value={module[e.field]}
              onChange={event => handleChangeField(event, e.field)}
            >
              {e.options?.map(c => (
                <>
                  {!!c ? (
                    <FormControlLabel sx={{ padding: 0 }} value={c.value} control={<Radio />} label={c.label} />
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </RadioGroup>
          </FormControl>
        )
      case "Checkbox":
        return (
          <FormControlLabel
            sx={{ padding: 0 }}
            control={
              <Checkbox
                checked={Boolean(module[e.field])}
                onChange={(event, value) => handleChangeCheckbox(event, e.field)}
              />
            }
            label={e.label}
          />
        )
      case "File":
        return (
          <FileUploader
            title={"Нажмите, чтобы загрузить изображение"}
            image={module[e.field] || null}
            setImage={handleUploadFile}
          />
        )
      default:
        return <></>
    }
  }

  return <>{moduleConfig?.map(e => contentMapper(e))}</>
}
