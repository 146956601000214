import React, { useState } from "react"
import * as Styled from "./Webinar.styles"
import { Typography } from "@mui/material"
import LinkIcon from "@mui/icons-material/Link"
import { Button, Modal } from "../../../shared/ui"

export const WebinarParticipantMessage = ({
  badge,
  isActive,
  link_on_online,
  link_on_offline,
  hasCertificate,
  completed,
  slug,
}: {
  hasCertificate: boolean
  completed: boolean
  slug: string
  badge: "online" | "offline" | "hybrid"
  isActive: boolean
  link_on_online: { link: string | null; recordingLink: string | null } | null
  link_on_offline: { link: string | null; text: string | null } | null
}) => {
  const [openPlaceModal, setOpenPlaceModal] = useState(false)

  function handleClickGetCertificate() {
    window.open(`/event/${slug}/certificate`)
  }

  return (
    <Styled.ParticipantWebinarMainDiv>
      {!isActive && (
        <>
          {completed && !hasCertificate && (
            <Button variant={"contained"} color={"primary"} sx={{ width: "100%" }} onClick={handleClickGetCertificate}>
              Получить сертификат
            </Button>
          )}
          {completed && hasCertificate && (
            <Button variant={"outlined"} color={"primary"} sx={{ width: "100%" }} onClick={handleClickGetCertificate}>
              Открыть сертификат
            </Button>
          )}
          {(hasCertificate || !completed) && (
            <Styled.FinishedWebinarOfflineDiv>
              <Typography sx={{ color: "#D82E2E" }}>Мероприятие завершено</Typography>
            </Styled.FinishedWebinarOfflineDiv>
          )}
        </>
      )}
      {isActive && !!link_on_offline?.text && (badge === "hybrid" || badge === "offline") && (
        <Styled.LinkContainer>
          <Styled.LinkTypography
            variant={"caption"}
            fontWeight={"medium"}
            onClick={() => {
              !!link_on_offline?.link && setOpenPlaceModal(true)
            }}
          >
            {link_on_offline.text}
          </Styled.LinkTypography>
        </Styled.LinkContainer>
      )}
      {isActive && !!link_on_online?.link && (badge === "hybrid" || badge === "online") && (
        <Styled.LinkContainer
          onClick={() => {
            !!link_on_online?.link && window.open(link_on_online.link, "_blank")
          }}
        >
          <Styled.LinkTypography variant={"caption"} fontWeight={"medium"}>
            Ссылка на трансляцию
          </Styled.LinkTypography>
          <LinkIcon sx={{ color: "#1565C0", width: 16 }} />
        </Styled.LinkContainer>
      )}
      {isActive && !link_on_online?.link && (badge === "hybrid" || badge === "online") && (
        <Typography sx={{ alignSelf: "flex-start", color: "#858585" }} variant={"caption"} fontWeight={"medium"}>
          Ссылка на трансляцию появится позже
        </Typography>
      )}
      {!isActive && !!link_on_online?.recordingLink && (badge === "hybrid" || badge === "online") && (
        <Styled.LinkContainer
          onClick={() => {
            !!link_on_online?.recordingLink && window.open(link_on_online.recordingLink, "_blank")
          }}
        >
          <Styled.LinkTypography variant={"caption"} fontWeight={"medium"}>
            Смотреть запись
          </Styled.LinkTypography>
          <LinkIcon sx={{ color: "#1565C0", width: 16 }} />
        </Styled.LinkContainer>
      )}
      <Modal isOpen={openPlaceModal} onClose={() => setOpenPlaceModal(false)} title={"Место проведения"}>
        <Styled.IFramePlace src={link_on_offline?.link || ""} />
      </Modal>
    </Styled.ParticipantWebinarMainDiv>
  )
}
