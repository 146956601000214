import React from "react"
import * as Styled from "./Iot.styles"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { SensorDescription } from "entities/Iot"
import { useAppSelector } from "../../../shared/hooks/useAppSelector"

const fields = ["ID", "Название", "Тип датчика", "Статус подключения"]

export const TableOfSensors = ({}) => {

  const config = useAppSelector(state => state.iot.config)

  return (
    <Styled.TableMainContainer>
      <Styled.CustomTableContainer>
        <Table>
          {/*@ts-ignore*/}
          <TableHead stickyHeader={true} sx={{ width: "100%", overflowX: "auto" }}>
            <TableRow>
              {fields.map(e =>
                <TableCell align={"center"}>
                  <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: "14px" }}>
                    {e}
                  </Typography>
                </TableCell>,
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {config?.real_devs.map(e =>
              <SensorDescription sensor={e} />,
            )}
          </TableBody>
        </Table>
      </Styled.CustomTableContainer>
    </Styled.TableMainContainer>
  )
}