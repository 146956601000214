import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { Card } from "shared/ui";
import {
  CommonTypeEditor,
  ChoiceTypeEditor,
  DetailedTypeEditor,
  NumericTypeEditor,
  MatrixTypeEditor,
  SortTypeEditor
} from "features/questionEditors";
import { moveItemInArray } from "shared/util";

function parseText(text, index, type) {
  if (!text) return
  if (text.startsWith("<")) {
    return (
      <>
        <div>{`${index + 1} (${type})`}</div>
        <div dangerouslySetInnerHTML={{ __html: text }} ></div>
      </>
    )
  } else {
    return `${index + 1}. ${text} (${type})`
  }
}

export const TestsEditor = ({ content, setContent, autocheck }) => {
  const [questions, setQuestions] = useState([])
  const [type, setType] = useState(null)
  const [text, setText] = useState("")
  const [currentIndex, setCurrentIndex] = useState(null)
  const [attempts, setAttempts] = useState(0)
  const [attemptsType, setAttemptsType] = useState("infinite")

  const [helperText, setHelperText] = useState("")

  var typeVariants

  if (autocheck) {
    typeVariants = ["choice", "single-choice", "matrix", "detailed", "numeric", "sort", "match"]
  } else {
    typeVariants = ["choice", "single-choice", "matrix", "detailed", "numeric", "attach", "sort", "match"]
  }

  function addQuestion() {
    let newQuestion = { text: text, type: type, index: questions.length, score: 0, answers: {} }
    if (type === "choice" || type === "detailed" || type === "numeric" || type === "single-choice" || type === "sort") {
      newQuestion.variants = []
    }
    if (type === "matrix" || type === "match") {
      newQuestion.variants_x = []
      newQuestion.variants_y = []
    }
    setQuestions([...questions, newQuestion])
  }

  function changeQuestion(question) {
    questions[currentIndex] = question
    setQuestions([...questions])
    setCurrentIndex(null)
  }

  function removeQuestion(index) {
    questions.splice(index, 1)
    setQuestions([...questions])
  }
  
  function getEditor() {
    if (currentIndex === null) return
    let type = questions[currentIndex].type
    switch (type) {
      case "choice":
        return <ChoiceTypeEditor question={questions[currentIndex]}/>
      case "detailed":
        return <DetailedTypeEditor question={questions[currentIndex]}/>
      case "numeric":
        return <NumericTypeEditor question={questions[currentIndex]}/>
      case "matrix":
        return <MatrixTypeEditor question={questions[currentIndex]}/>
      case "single-choice":
        return <ChoiceTypeEditor question={questions[currentIndex]}/>
      case "sort":
        return <SortTypeEditor question={questions[currentIndex]}/>
      case "match":
        return <MatrixTypeEditor question={questions[currentIndex]}/>
      default:
        return
    }
  }

  const onDragEnd = useCallback((result) => {
    let newQuestions = [...questions]
    moveItemInArray(newQuestions, result.source.index, result.destination.index)
    for (let index = 0; index < questions.length; index++) {
      newQuestions[index].index = index
    }
    setQuestions(newQuestions)
  }, [questions]);

  function prepareDataToCreate() {
    setContent({
      ...content,
      props: JSON.stringify({ attempts: attempts, attempts_type: attemptsType }),
      max_score: questions.map(q => q.score).reduce((a, b) => (parseInt(a) + parseInt(b)), 0),
      components: questions.map(q => {
        let { index, type, score, score_type, answers, ...props } = q
        if (type === "choice") {
          answers = props.variants.filter(e => !!e.checked).map(e => e.value.toString())
          props.variants = props.variants.map(e => ({ value: e.value, label: e.label }))
        } else if (type === "detailed") {
          answers = props.variants.map(e => e.label)
          delete props["variants"]
          score_type = "char"
        } else if (type === "numeric") {
          answers = props.variants[0]
          delete props["variants"]
          score_type = "numeric"
        } else if (type === "matrix") {
          answers = Object.entries(answers).filter(e => !!e[1]).map(e => e[0])
        } else if (type === "single-choice") {
          answers = props.variants.filter(e => !!e.checked).map(e => e.value.toString())
          props.variants = props.variants.map(e => ({ value: e.value, label: e.label }))
        } else if (type === "sort") {
          answers = props.variants.map((e, index) => `${index},${e.value}`)
        } else if (type === "match") {
          answers = Object.entries(answers).filter(e => !!e[1]).map(e => e[0])
        }
        return { index: index + 1, type, props: JSON.stringify(props),
          task: { score: score, type: score_type, key: JSON.stringify(answers), autocheck: autocheck } }
      })
    })
    setHelperText("Тест загружен в буфер")
  }

  useEffect(() => {
    setHelperText("")
  }, [currentIndex])

  return (
    <div style={{ border: "1px solid", borderRadius: 4 }}>
    <Dialog
      aria-describedby="DialogDescription"
      open={currentIndex !== null}
      onClose={() => setCurrentIndex(null)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ height: 600 }}>
        <CommonTypeEditor question={questions[currentIndex]} doUpdate={changeQuestion}/>
        {getEditor()}
      </DialogContent>
    </Dialog>
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Typography variant="h5">{helperText}</Typography>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <FormControl sx={{ minWidth: 180, margin: 6 }}>
          <InputLabel id="test-editor-type-select-label">Выберите тип вопроса</InputLabel>
          <Select
            labelId="test-editor-type-select-label"
            id="test-editor-type-select"
            value={type}
            label="Type"
            onChange={e => setType(e.target.value)}
          >
            {typeVariants.map(e => (
              <MenuItem value={e}>{e}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="question-text-editor"
          variant="outlined"
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Текст вопроса"
          fullWidth
          multiline
          style={{ margin: 12, minWidth: 360 }}
        />
        <Button onClick={addQuestion} style={{ margin: 12, width: 180 }}>Добавить вопрос</Button>
      </div>
      <Droppable droppableId="droppable-1">
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            style={{ backgroundColor: snapshot.isDraggingOver ? '#F0F0F0' : '#E0E0E0', width: 800, padding: 24 }}
            {...provided.droppableProps}
          >
            {questions.map((e, index) => (
              <Draggable draggableId={"draggable-" + index} index={index}>
                {(provided, snapshot) => (
                  <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div style={{ height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography onClick={() => setCurrentIndex(index)} variant="h5"
                        style={{ cursor: "pointer", padding: 12, maxWidth: "80%" }}
                      >
                        {parseText(e.text, e.index, e.type)}
                      </Typography>
                      <Button onClick={() => removeQuestion(index)}>Удалить</Button>
                    </div>
                  </Card>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Card>
        )}
      </Droppable>
    </DragDropContext>
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <TextField
        id="question-attempts"
        variant="outlined"
        value={attempts}
        onChange={e => setAttempts(e.target.value)}
        placeholder="Попытки"
        fullWidth
        type="number"
        style={{ margin: 12, width: 120 }}
      />
      <FormControl sx={{ minWidth: 180, margin: 6 }}>
        <InputLabel id="test-editor-attempts-select-label">Выберите тип ограничения</InputLabel>
        <Select
          labelId="test-editor-attempts-select-label"
          id="test-editor-attempts-select"
          value={attemptsType}
          label="Тип ограничений"
          onChange={e => setAttemptsType(e.target.value)}
        >
          <MenuItem value={"infinite"}>Без ограничений</MenuItem>
          <MenuItem value={"day"}>На день</MenuItem>
          <MenuItem value={"total"}>На всё время</MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={prepareDataToCreate}
        size="large"
        style={{ width: 150, height: 50 }}
      >
        Сохранить
      </Button>
    </div>
    </div>
  )
}
