import { TableCell, Typography } from "@mui/material"
import { IRequestsAndStatusesParticipant } from "entities/Organization/model/StatisticsContext.types"
import { wordMapper } from "shared/local"

const StatisticsRequestsAndStatusesRow = ({ participant }: { participant: IRequestsAndStatusesParticipant }) => {
  return (
    <>
      <TransparentCell value={participant?.full_name} />
      <TransparentCell value={participant?.email} />
      <TransparentCell value={wordMapper(participant?.status)} />
      <TransparentCell value={participant?.category} />
      <TransparentCell value={participant?.is_active ? "Активен" : "Не активен"} />
    </>
  )
}

const TransparentCell = ({ value }: { value: string }) => (
  <TableCell align="center" sx={{ maxWidth: 200, overflowWrap: "break-word" }}>
    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
      {value || "-"}
    </Typography>
  </TableCell>
)
export { StatisticsRequestsAndStatusesRow }
