import { createSlice } from "@reduxjs/toolkit"
import { ICourse } from "entities/Course/api/course.types"

export const courseKeyDefault = 'course'
export type CourseKeyType = 'course' | 'participantsAndRequestsCourse'

const initialStateValue = {
  course: null as ICourse | null,
  participantsAndRequestsCourse: null as ICourse | null,
  courses: [] as ICourse[],
  membersLimit: Infinity,
  isLoadingCourses: false,
}

const courseSlice = createSlice({
  name: "course",
  initialState: initialStateValue,
  reducers: {
    setCourse: (state, action) => {
      state.course = action.payload
    },
    setParticipantsAndRequestsCourse: (state, action) => {
      state.participantsAndRequestsCourse = action.payload
    },
    setCourses: (state, action) => {
      state.courses = action.payload
    },
    setMembersLimit: (state, action) => {
      state.membersLimit = action.payload
    },
    setIsLoadingCourses: (state, action) => {
      state.isLoadingCourses = action.payload
    },
  },
})

export const { actions, reducer } = courseSlice
