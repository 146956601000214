import React, { useRef, useState } from "react"
import * as Mui from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import VisibilitySensor from "react-visibility-sensor"
import SendGroupNotification from "features/Notifications/SendGroupNotification"
import { sortItems } from "entities/Course/lib/CourseStatic"
import { IconButton as SharedIconButton } from "shared/ui"
import { HeaderCell, NotificationIcon, ParticipantTableContainer, SortIcons } from "./ParticipantTable.style.ts"
import { ScrollButtons, TableLoader } from "shared/assets"
import { TeamRow } from "entities/Team/ui/TeamRow"
import { formCategoriesRow } from "entities/Statistic/lib/teamStatistic"
import { Checkbox } from "shared/ui"
import { wordMapper } from "shared/local"
import { TeamWordMapper } from "entities/Team/model/teamWordMapper"

const teamFields = [
  { label: "ID команды", value: "team_id" },
  { label: "Название команды", value: "team_name" },
  { label: "ФИО Главного Администратора", value: "mentor_name" },
  { label: "Почта Главного Администратора", value: "mentor_email" },
]

const CourseTable = ({
  headers,
  categories,
  progress,
  course,
  sortBy,
  setSortBy,
  isLoading,
  loadMore,
  hasNextPage,
  isShowMembers,
  setIsShowMembers,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [users, setUsers] = useState(null)
  const tableContainerRef = useRef(null)
  const categoriesRow = formCategoriesRow(categories)

  function handleDialog(team) {
    setOpenDialog(prev => !prev)
    const users = getRecieviers(team)
    setUsers(users)
  }

  const getRecieviers = team => {
    let result = []
    team?.members.forEach(member => {
      result.push(member.participant.user)
    })
    return result
  }

  const handleScrolledToBottom = isBottomVisible => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      loadMore()
    }
  }

  const handleChange = () => {
    setIsShowMembers(prev => !prev)
  }

  return (
    <>
      <SendGroupNotification
        users={users}
        title={course?.label}
        courseId={course?.id}
        isOpen={openDialog}
        onClose={handleDialog}
      />
      <ParticipantTableContainer>
        <Checkbox isChecked={isShowMembers} onChange={handleChange} label={"Отображать состав команды"} />
        <ScrollButtons tableRef={tableContainerRef} />
        <Mui.TableContainer ref={tableContainerRef} sx={{ backgroundColor: "#FFF", height: "80vh" }}>
          <Mui.Table stickyHeader sx={{ width: "100%", overflowX: "auto" }} size="small">
            <Mui.TableHead>
              <Mui.TableRow>
                <Mui.TableCell></Mui.TableCell>
                {teamFields.map(e => (
                  <SortableCell label={e.label} value={e.value} setSortBy={setSortBy} key={e.label} />
                ))}
                {isShowMembers &&
                  categoriesRow.map((category, index) => <UnsortableCell value={category} key={index} isLeft={true} />)}
                <SortableCell label={"Баллы"} value={"score"} setSortBy={setSortBy} />
                {headers.map(header => (
                  <UnsortableCell value={header.title} key={header.id} />
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
              <Mui.TableBody>
                {progress &&
                  progress
                    .sort((a, b) => sortItems(a, b, sortBy))
                    .map(team => (
                      <Mui.TableRow key={team.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <Mui.TableCell align="center" sx={{ paddingLeft: 7, height: "103px" }}>
                          {course && (
                            <SharedIconButton
                              id={`notification-${team.id}`}
                              color={"inherit"}
                              size={"medium"}
                              onClick={() => handleDialog(team)}
                            >
                              <NotificationIcon />
                            </SharedIconButton>
                          )}
                        </Mui.TableCell>
                        <TeamRow headers={headers} team={team} isShowMembers={isShowMembers} />
                      </Mui.TableRow>
                    ))}
              </Mui.TableBody>
            </VisibilitySensor>
          </Mui.Table>
          <TableLoader isLoading={isLoading}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
        </Mui.TableContainer>
      </ParticipantTableContainer>
    </>
  )
}

const SortableCell = ({ label, value, setSortBy }) => (
  <Mui.TableCell align="center">
    <HeaderCell>
      <Mui.Typography variant={"caption"} fontWeight={"small"}>
        {label}
      </Mui.Typography>
      <SortIcons>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: -1 })}
        >
          <ArrowDropDownIcon fontSize="small" />
        </Mui.IconButton>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: 1 })}
        >
          <ArrowDropUpIcon fontSize="small" />
        </Mui.IconButton>
      </SortIcons>
    </HeaderCell>
  </Mui.TableCell>
)

const UnsortableCell = ({ value, isLeft }) => (
  <Mui.TableCell align={isLeft ? "left" : "center"} sx={{ paddingLeft: isLeft && 20 }}>
    <Mui.Typography variant={"caption"} fontWeight={"small"}>
      {wordMapper(value, TeamWordMapper)}
    </Mui.Typography>
  </Mui.TableCell>
)

export default CourseTable
