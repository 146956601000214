import React, {useEffect, useState} from 'react'
import { Grid } from '@mui/material'
import useStyles from "../../pages/profile/styles";
import { Certificate } from 'entities/Certificate';
import {useDispatch, useSelector} from "react-redux";
import {fetchCertificates} from "shared/store";

export const CertificatesList = () => {

  const dispatch = useDispatch()
  const certificates = useSelector(state => state.certificate.certificates)
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchCertificates())
  }, [])

  return (
      <Grid container spacing={3} className={classes.certificatesContainer}>
        {!!certificates && certificates.map(cert => (
          <Certificate { ...cert } />
        ))}
      </Grid>
  )
}
