import { ReactNode } from "react"
import { Tooltip as MuiTooltip } from "@mui/material"
import themes from "shared/themes"

export type TooltipTheme = "dark" | "default"

interface IProps {
  children: any
  text?: string | ReactNode
  width?: number
  placement?: "right" | "bottom" | "left" | "top"
  color?: string
  backgroundColor?: string
  theme?: TooltipTheme
}

const Tooltip = ({ children, text, width = 581,theme = "default", placement = "right", color, backgroundColor }: IProps) => {
  const tooltipStyle = {
    padding: themes.default.spacing(4),
    bgcolor: pickBackgroundColor(theme) || backgroundColor,
    fontSize: themes.default.spacing(4),
    fontWeight: "400",
    lineHeight: themes.default.spacing(5),
    color: pickColor(theme) || color,
    minWidth: "fit-content",
    maxWidth: `${width}px`,
    borderRadius: themes.default.spacing(3),
  }

  function pickColor(theme: TooltipTheme) {
    // @ts-ignore
    if (theme === "default") return themes.default.palette.additional.main
    if (theme === "dark") return themes.default.palette.common.white
  }
  function pickBackgroundColor(theme: TooltipTheme) {
    if (theme === "default") return themes.default.palette.info.light
    // @ts-ignore
    if (theme === "dark") return themes.default.palette.background.tooltip
  }

  const arrowStyle = {
    color: !!backgroundColor ? backgroundColor : pickBackgroundColor(theme),
  }

  return (
    <MuiTooltip
      arrow
      title={text}
      placement={placement}
      TransitionProps={{ timeout: 300 }}
      enterDelay={200}
      componentsProps={{ tooltip: { sx: tooltipStyle }, arrow: { sx: arrowStyle } }}
    >
      {children}
    </MuiTooltip>
  )
}
export { Tooltip }
