import { styled } from "@mui/material/styles"
import { Button, TableContainer } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"

export const MainOrganizationsDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(10),
  width: "100%",
  maxWidth: "1920px",
  marginInline: "auto",
}))

export const EventsMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}))

export const CreateEventButton = styled(Button)(({ theme }) => ({
  height: 240,
  maxWidth: 240,
  background: "#F3F3F3",
  color: "#000",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4),
}))

export const IconAdd = styled(AddIcon)(({ theme }) => ({
  width: 64,
  height: 64,
  color: "#000",
}))

export const ParticipantManagementMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}))

export const FiltersDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
  marginTop: theme.spacing(11),
  marginBottom: theme.spacing(10),
}))

export const CustomDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  cursor: "pointer",
  color: "#777777",
  "&:hover": {
    opacity: 0.5,
  },
}))

export const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  borderRadius: theme.spacing(3),
  maxHeight: "400px",
  minHeight: "200px",
  position: "relative",
}))

export const NotificationCreateMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  marginTop: theme.spacing(11),
}))
