import React from 'react';
import {styled} from "@mui/material/styles";
import {useTheme} from "../../../shared/context";
import {Button} from "../../../shared/ui";

export const Buttons = () => {

    const theme = useTheme()

    const MainDiv = styled("div")({
        display:"flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper
    })

    const StyledDiv = styled("div")({
        display: "flex",
        flexDirection: "row",
        marginLeft: theme.spacing(31),
        marginRight: theme.spacing(31),
        alignItems: "center",
        marginTop: theme.spacing(8),
        justifyContent: "flex-start",
        gap: theme.spacing(8),
    })

    return (
        <MainDiv>
            <StyledDiv>
                <Button variant={"contained"} color={"primary"} height={"large_x"}>
                    Main primary
                </Button>
                <Button variant={"contained"} color={"primary"} height={"large"}>
                    Main primary
                </Button>
                <Button variant={"contained"} color={"primary"} height={"medium"}>
                    Main primary
                </Button>
                <Button variant={"contained"} color={"primary"} height={"small"} disabled={true}>
                    Main primary
                </Button>
            </StyledDiv>
            <StyledDiv>
                <Button variant={"outlined"} color={"primary"} height={"large_x"}>
                    Main outlined
                </Button>
                <Button variant={"outlined"} color={"primary"} height={"large"}>
                    Main outlined
                </Button>
                <Button variant={"outlined"} color={"primary"} height={"medium"}>
                    Main outlined
                </Button>
                <Button variant={"outlined"} color={"primary"} height={"small"} disabled={true}>
                    Main outlined
                </Button>
            </StyledDiv>
            <StyledDiv>
                <Button variant={"invert"} color={"primary"} height={"large_x"}>
                    Main invert
                </Button>
                <Button variant={"invert"} color={"primary"} height={"large"}>
                    Main invert
                </Button>
                <Button variant={"invert"} color={"primary"} height={"medium"}>
                    Main invert
                </Button>
                <Button variant={"invert"} color={"primary"} height={"small"} disabled={true}>
                    Main invert
                </Button>
            </StyledDiv>
            <StyledDiv>
                <Button variant={"contained"} color={"accent"} height={"large_x"}>
                    Accent red
                </Button>
                <Button variant={"contained"} color={"accent"} height={"large"}>
                    Accent red
                </Button>
                <Button variant={"contained"} color={"accent"} height={"medium"}>
                    Accent red
                </Button>
                <Button variant={"contained"} color={"accent"} height={"small"} disabled={true}>
                    Accent red
                </Button>
            </StyledDiv>
            <StyledDiv>
                <Button variant={"outlined"} color={"accent"} height={"large_x"}>
                    Outlined red
                </Button>
                <Button variant={"outlined"} color={"accent"} height={"large"}>
                    Outlined red
                </Button>
                <Button variant={"outlined"} color={"accent"} height={"medium"}>
                    Outlined red
                </Button>
                <Button variant={"outlined"} color={"accent"} height={"small"} disabled={true}>
                    Outlined red
                </Button>
            </StyledDiv>
            <StyledDiv>
                <Button variant={"contained"} color={"additional"} height={"large_x"}>
                    Additional contained
                </Button>
                <Button variant={"contained"} color={"additional"} height={"large"}>
                    Additional contained
                </Button>
                <Button variant={"contained"} color={"additional"} height={"medium"}>
                    Additional contained
                </Button>
                <Button variant={"contained"} color={"additional"} height={"small"} disabled={true}>
                    Additional contained
                </Button>
            </StyledDiv>
            <StyledDiv>
                <Button variant={"outlined"} color={"additional"} height={"large_x"}>
                    Additional outlined
                </Button>
                <Button variant={"outlined"} color={"additional"} height={"large"}>
                    Additional outlined
                </Button>
                <Button variant={"outlined"} color={"additional"} height={"medium"}>
                    Additional outlined
                </Button>
                <Button variant={"outlined"} color={"additional"} height={"small"} disabled={true}>
                    Additional outlined
                </Button>
            </StyledDiv>
        </MainDiv>
    );
};

