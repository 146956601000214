import { createSlice } from "@reduxjs/toolkit"

const timerSlice = createSlice({
  name: "timer",
  initialState: {
    isRunning: false,
    isExitModal: false,
    onExit: false,
    exitLink: "",
    testData: { attempts: { value: "", type: "", timer: "" }, courseSlug: "" },
  },
  reducers: {
    setIsRunningTimer: (state, action) => {
      state.isRunning = action.payload
    },
    setIsExitModal: (state, action) => {
      state.isExitModal = action.payload
    },
    setOnExit: (state, action) => {
      state.onExit = action.payload
    },
    setExitLink: (state, action) => {
      state.exitLink = action.payload
    },
    setTestData: (state, action) => {
      state.testData = action.payload
    },
    resetModalState: state => {
      state.isRunning = false
      state.isExitModal = false
      state.onExit = false
      state.exitLink = ""
      state.attempts = { value: "", type: "", timer: "" }
      state.courseSlug = ""
    },
  },
})

export const { reducer, actions } = timerSlice
