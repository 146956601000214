import { AssignmentStatus } from "entities/Assignment/model/assignment.types"
import { useRef } from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { IFilters } from "shared/hooks/useFiltersForm"

const useRowAnimation = () => {
  const rowRef = useRef<HTMLTableRowElement>(null)
  const filters: IFilters = useAppSelector(state => state.assignment.filters)
  const collapseTime = 1000
  const removeTime = 300
  const collapsingTime = collapseTime + removeTime

  const handleAnimation = (isCollapse: boolean) => {
    if (!rowRef.current) return

    rowRef.current.classList.add("success")
    setTimeout(() => {
      if (!isCollapse) {
        return rowRef.current && rowRef.current.classList.remove("success")
      }

      setTimeout(() => {
        rowRef.current && rowRef.current.classList.add("collapse")
        setTimeout(() => {
          rowRef.current && rowRef.current.classList.add("removed")
          rowRef.current && rowRef.current.classList.remove("success")
        }, removeTime)
      }, collapseTime)
    }, collapseTime)
  }

  return { rowRef, handleAnimation, collapsingTime }
}

export default useRowAnimation
