export const TableArrowRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M27.6231 17.4131C27.9976 17.0381 28.208 16.5297 28.208 15.9997C28.208 15.4697 27.9976 14.9614 27.6231 14.5864L20.0818 7.04105C19.7066 6.66586 19.1977 6.45508 18.6671 6.45508C18.1365 6.45508 17.6276 6.66586 17.2524 7.04105C16.8772 7.41625 16.6665 7.92512 16.6665 8.45572C16.6665 8.98632 16.8772 9.49519 17.2524 9.87039L21.3804 13.9997L6.00044 13.9997C5.47 13.9997 4.9613 14.2104 4.58622 14.5855C4.21115 14.9606 4.00044 15.4693 4.00044 15.9997C4.00044 16.5302 4.21115 17.0389 4.58622 17.4139C4.9613 17.789 5.47 17.9997 6.00044 17.9997L21.3804 17.9997L17.2524 22.1277C17.0667 22.3135 16.9193 22.534 16.8188 22.7768C16.7182 23.0195 16.6665 23.2797 16.6665 23.5424C16.6665 23.8051 16.7182 24.0653 16.8188 24.308C16.9193 24.5507 17.0667 24.7713 17.2524 24.9571C17.4382 25.1428 17.6588 25.2902 17.9015 25.3907C18.1442 25.4913 18.4044 25.543 18.6671 25.543C18.9298 25.543 19.19 25.4913 19.4327 25.3907C19.6754 25.2902 19.896 25.1428 20.0818 24.9571L27.6231 17.4131Z"
        fill="white"
      />
    </svg>
  )
}
