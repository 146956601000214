import { Autocomplete } from "shared/ui"
import { OrganizationStatisticsSection } from "entities/Organization/ui/OrganizationStatisticsSection"
import { DateRange, EventDateSelector } from "features/Organization/ui/EventDateSelector"
import { RequestsTemplate } from "../../../../entities/Organization/ui/Statistics/RequestsTemplate"
import { StatusesTemplate } from "../../../../entities/Organization/ui/Statistics/StatusesTemplate"
import { RangesType } from "../../../../entities/Organization/model/StatisticsContext.types"
import { useEffect, useState } from "react"
import { useStatistics } from "../../../../entities/Organization/model/StatisticsContext"
import { ICourse } from "../../../../entities/Course/api/course.types"
import {
  OrganizationStatisticsRequestsAndStatusesTemplate
} from "../../../../entities/Organization/ui/Statistics/OrganizationStatisticsRequestsAndStatusesTemplate"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"
import { useAppDispatch } from "../../../../shared/hooks/useAppDispatch"
import { getOrganizationParticipants } from "../../../../entities/Organization/api"
import { IEvent } from "../../../../entities/Organization/model/Organization.types"
import {useRequestsAndStatusesStatistics}
  from "../../../../features/CourseParticipants/model/useRequestsAndStatusesStatistics"

export interface RequestsAndStatusesStatisticsType {
  onChangeTab: (x: string) => void
  tabType: "requests" | "statuses"
  enableEventDateSelector: boolean
}

const RequestsAndStatusesStatistics = ({ onChangeTab, tabType, enableEventDateSelector }: RequestsAndStatusesStatisticsType) => {

    const {
    event,
    onSubmit,
    isLoadingTable,
    submitTrigger,
    handleChangeCourse,
  } = useRequestsAndStatusesStatistics()

  const { dateRange } = useStatistics()
  const [statisticsRangeValue, setStatisticsRangeValue] = useState<RangesType>(dateRange.type)
  const [statisticsDisplayedDate, setStatisticsDisplayedDate] = useState<DateRange>(null!)
  
  const showRangeType = (rangeType: RangesType) => {
    setStatisticsRangeValue(rangeType)
  }
  const showDisplayedDate = (displayedDate: DateRange) => {
    setStatisticsDisplayedDate(displayedDate)
  }
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const dispatch = useAppDispatch()
  const [coursesList, setCoursesList] = useState<ICourse[]>([])
  useEffect( () => {
    const requestCourses = async () => {
      if (!organization?.id) return
      try {
        const response = await getOrganizationParticipants(organization?.id)
        const courses = response.map((course: IEvent) => ({ id: course.id, label: course.title }))
        const options = courses.length ? [{ id: null, label: "Не выбрано" }, ...courses] : []
        setCoursesList(options)
      } catch {}
    }
    requestCourses().catch(e=>console.log(e))
  }, [dispatch, organization])

  return (
    <>
      <OrganizationStatisticsSection
        title={tabType === "requests"
          ? "Динамика по заявкам мероприятий организации"
          : "Данные по статусам участников на мероприятии"}
        selector={
          <>
            <Autocomplete
              key={event?.title}
              label={"Название мероприятия"}
              onChange={handleChangeCourse}
              // @ts-ignore
              onReset={handleChangeCourse}
              // @ts-ignore
              options={coursesList}
              value={event?.title}
              width={540}
            />
            <EventDateSelector event={event} isLoading={isLoadingTable} onReload={onSubmit}
                               showRangeType={showRangeType} getDisplayedDate={showDisplayedDate} enableEventDateSelector={enableEventDateSelector} />
          </>
        }
      >
        {tabType === "requests"
          ? (<RequestsTemplate isLoading={isLoadingTable} event={event} rangeType={statisticsRangeValue} statisticsDisplayedDate={statisticsDisplayedDate} />)
          : (<StatusesTemplate isLoading={isLoadingTable} event={event} rangeType={statisticsRangeValue} statisticsDisplayedDate={statisticsDisplayedDate} />)
        }
      </OrganizationStatisticsSection>

      <OrganizationStatisticsRequestsAndStatusesTemplate
        type={tabType === "requests" ? "requests" : "statuses"}
        isLoadingTable={isLoadingTable}
        submitTrigger={submitTrigger}
        onChangeTab={onChangeTab}
        event={event}
      />
      </>
  )
}

export {RequestsAndStatusesStatistics}
