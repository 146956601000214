import React from "react"
import { Box, styled, Typography } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Button } from "shared/ui"
import * as Styled from "./Landing.styles"
import { useTheme } from "../../shared/context"

const TypographyTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(6),
  },
  userSelect: "text",
}))

const TypographyDescription = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(4),
  },
  userSelect: "text",
}))

export const CarouselItem = props => {
  const theme = useTheme()
  return (
    <Styled.LandingBackground style={{ background: props.item.color }}>
      <Styled.LandingContainer>
        <Styled.SloganContainer>
          <TypographyTitle variant={"h1"} sx={{ color: props.item.textColor }}>
            {props.item.title}
          </TypographyTitle>
          <TypographyDescription
            variant={"body1"}
            fontWeight={"medium"}
            fontSize={"medium"}
            sx={{ color: props.item.textColor, fontSize: 24, zIndex: 1 }}
          >
            {props.item.description}
          </TypographyDescription>
          <Button
            variant={props.item.buttonVariant}
            color={props.item.buttonColor}
            height={"large"}
            sx={{ border: "none", [theme.breakpoints.down("xs")]: { width: "100%" } }}
            onClick={props.item.onClick}
            disableRipple
          >
            {props.item.textButton}
          </Button>
        </Styled.SloganContainer>
        <Styled.LandingImage>
          <LazyLoadImage height="100%" src={props.item.image} placeholderSrc={props.item.lowImage} effect="blur" />
        </Styled.LandingImage>
      </Styled.LandingContainer>
    </Styled.LandingBackground>
  )
}
