import React, { useEffect, useState } from "react"
import { Autocomplete, Button, ShadowedSection, TextField } from "shared/ui"
import * as Styled from "./Team.style.ts"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { isValid } from "plotly.js/src/components/colorscale/scales"

export const TeamsSetting = ({
  title,
  children,
  label,
  secondLabel,
  options,
  description,
  handleClick,
  handleReset,
  resetField,
  validator,
  secondValidator,
  errorText,
  isDisabledTextField,
  isCheckbox,
  checkboxValue,
  required,
  errors,
}) => {
  const theme = useTheme()
  const [value, setValue] = useState(null)
  const [secondValue, setSecondValue] = useState(null)
  const [isChecked, setIsChecked] = useState(checkboxValue)

  const resetHandler = () => {
    setValue(null)
    setSecondValue(null)
    handleReset(resetField)
  }

  useEffect(() => {
    if (options === null) {
      setValue(null)
    }
  }, [options])

  useEffect(() => {
    setIsChecked(checkboxValue)
  }, [checkboxValue])

  return (
    <ShadowedSection>
      <Styled.BaseSettingContainer>
        <Typography variant={"h4"} sx={{ marginBottom: theme.spacing(11) }}>
          {title}
        </Typography>
        <Styled.SettingContainer>
          <div style={{ width: "40%" }}>
            {options && (
              <Autocomplete
                onChange={(event, newValue) => setValue(newValue)}
                options={options}
                label={label}
                value={options === null ? "" : value}
                style={{ marginBottom: theme.spacing(8), width: "100%" }}
              />
            )}
            {!options && (
              <TextField
                style={{ marginBottom: theme.spacing(8), width: "100%" }}
                variant={"outlined"}
                value={value}
                label={label}
                error={!!validator && !validator(value)}
                helperText={!!errorText && errorText}
                onChange={e => setValue(e.target.value)}
                disabled={isDisabledTextField}
              />
            )}
            {!!secondLabel && (
              <TextField
                style={{ marginBottom: theme.spacing(8), width: "100%" }}
                variant={"outlined"}
                value={secondValue}
                label={secondLabel}
                helperText={!!errorText && errorText}
                error={!!secondValidator && !secondValidator(secondValue)}
                onChange={e => setSecondValue(e.target.value)}
              />
            )}
            {isCheckbox && (
              <FormControlLabel
                sx={{ marginBottom: theme.spacing(8) }}
                control={
                  <Checkbox
                    checked={Boolean(isChecked)}
                    onChange={() => setIsChecked(prev => !prev)}
                    color="primary"
                    //inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={"Учитывать прогресс Администраторов команды"}
              />
            )}
            <Typography sx={{ marginBottom: theme.spacing(8), fontSize: 14, fontWeight: 400 }} color={"error"}>
              {!!errors && errors[resetField]}
            </Typography>
            <Styled.SettingButtonContainer>
              <Button
                onClick={e => handleClick(value, secondValue, isChecked)}
                sx={{ width: "100%" }}
                variant={"outlined"}
                color={"primary"}
                disabled={
                  !value ||
                  (validator && !validator(value)) ||
                  (!!secondLabel && (!secondValue || (secondValidator && !secondValidator(secondValue))))
                }
              >
                Применить
              </Button>
              <Button
                disabled={!!required}
                sx={{ width: "100%" }}
                variant={"outlined"}
                color={"accent"}
                onClick={resetHandler}
              >
                Сбросить все
              </Button>
            </Styled.SettingButtonContainer>
          </div>
          <Styled.DescriptionSettingContainer>
            {!!description && (
              <Typography variant={"body1"} fontSize={"medium"} fontWeight={"medium"}>
                {description}
              </Typography>
            )}
            {children}
          </Styled.DescriptionSettingContainer>
        </Styled.SettingContainer>
      </Styled.BaseSettingContainer>
    </ShadowedSection>
  )
}
