import React, { useEffect, useRef } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { Line } from "shared/ui"
import { PlusOne } from "@mui/icons-material"
import useStyle from "./styles"
import AddIcon from "@mui/icons-material/Add"

const Faq = ({ e, handleChangeRef }) => {
  const classes = useStyle()
  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  return (
    <div ref={ref} style={{ backgroundColor: e.backgroundColor }}>
      <div className={classes.faqContentContainer}>
        <div>
          {!!e.H2 && (
            <Typography variant={"h2"} sx={{ color: e.H2.color }}>
              {e.H2.text}
            </Typography>
          )}
        </div>
        <div className={classes.questionContainer}>
          {e.options.map(item => (
            <Accordion sx={{ backgroundColor: e.backgroundColor, borderColor: e.lineColor }}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<AddIcon sx={{ color: e.iconColor }} />}
                icon={<PlusOne />}
              >
                <Typography variant={"body1"} sx={{ color: e.textColor }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant={"body1"} sx={{ color: e.textColor }}>
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faq
