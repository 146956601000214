import * as Styled from "./TimerStartModal.styles"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { Button } from "shared/ui"
import { parseTime } from "entities/Test/lib/timer"
import { useSelector, useDispatch } from "react-redux"
import { timerActions } from "shared/store"

interface IProps {
  isOpen: boolean
  onClose: () => void
  onContinue: () => void
  onExit: () => void
}

const TimerExitModalUnstored = ({ isOpen, onClose, onContinue, onExit }: IProps) => {
  // @ts-ignore
  const testData = useSelector(state => state.timer.testData)
  const dispatch = useDispatch()

  const handleOnExit = () => {
    dispatch(timerActions.resetModalState())
    onExit()
  }

  return (
    <ModalTimer isOpen={isOpen} onClose={onClose} size={"small"}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>
            Вы уже начали попытку выполнения теста. Вы сможете вернуться к выполнению, пока не вышло время таймера.
            После истечения времени отправка результатов будет недоступна. Вы точно хотите выйти?
          </Styled.Typography>
          <LeftTime attempts={testData.attempts} />
        </Styled.Wrapper>
        <Buttons onContinue={onContinue} onExit={handleOnExit} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ onContinue, onExit }: { onContinue: () => void; onExit: () => void }) => {
  return (
    <Styled.ButtonWrapper>
      {/* @ts-ignore */}
      <Button onClick={onContinue} variant="contained" width={154}>
        Закрыть
      </Button>
      {/* @ts-ignore */}
      <Button onClick={onExit} variant="outlined" width={154}>
        Выйти
      </Button>
    </Styled.ButtonWrapper>
  )
}

const LeftTime = ({ attempts }: { attempts: { value: number; timer: string } }) => {
  return (
    <Styled.LeftTimeWrapper>
      <Styled.LeftTimeTitle>Время выполнения:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>{parseTime(attempts.timer)}</Styled.LeftTimeText>
      <Styled.LeftTimeTitle>Попытка:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>
        {attempts.value} из {attempts.value}
      </Styled.LeftTimeText>
    </Styled.LeftTimeWrapper>
  )
}

export { TimerExitModalUnstored }
