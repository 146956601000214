import { ITeam } from "../model/Team.types"
import { TableRow } from "@mui/material"
import { MemberRow } from "entities/Team"

interface IProps {
  isEdit: boolean
  setErrorMsg: () => void
  team: ITeam
}

const bannedStatuses = ["invited", "requested", "rejected"]

const EditRolesTable = ({ isEdit, setErrorMsg, team }: IProps) => {
  return (
    team.members &&
    team.members.map(member => {
      if (bannedStatuses.includes(member.status)) return null
      return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={member.id}>
          <MemberRow team={team} member={member} isEdit={isEdit} setErrorMsg={setErrorMsg} />
        </TableRow>
      )
    })
  )
}

export { EditRolesTable }
