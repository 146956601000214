import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    mainModule: {
        paddingLeft: "25vw",
        margin: 0,
        position: "relative",
        top: 100,
    },
}));
