import React from 'react';
import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";

export const Texts = () => {

    const StyledDiv = styled('div')(({theme}) => ({
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: theme.spacing(5),
        paddingLeft: theme.spacing(31),
        paddingRight: theme.spacing(31),
        paddingTop: theme.spacing(8),
        background: theme.palette.background.paper
    }))

    return (
        <StyledDiv>
            <Typography variant={"h1"}>Заголовок h1</Typography>
            <Typography variant={"h2"}>Заголовок h2</Typography>
            <Typography variant={"h3"}>Заголовок h3</Typography>
            <Typography variant={"h4"}>Заголовок h4</Typography>
            <Typography variant={"subtitle1"}>Subtitle 1</Typography>
            <Typography variant={"subtitle2"}>Subtitle 2</Typography>
            <Typography variant={"subtitle3"}>Subtitle 3</Typography>
            <Typography variant={"caption"} fontWeight={"medium"}>Caption regular</Typography>
            <Typography variant={"caption"} fontWeight={"small"}>Caption light</Typography>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>Body base regular</Typography>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>Body base light</Typography>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>Body small regular</Typography>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"}>Body small light</Typography>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"large"}>Body large regular</Typography>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"large"}>Body large light</Typography>
            <Typography variant={"body2"}>Body small_x</Typography>
        </StyledDiv>
    );
};
