import { useState } from "react"
import { TableBody, TableRow, Typography } from "@mui/material"
import { MemberRow } from "entities/Team"
import { styled } from "@mui/material/styles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

// @ts-ignore
const InvitedMembers = ({ members }) => {
  const [isActive, setIsActive] = useState(true)
  const handleClick = () => {
    setIsActive(prev => !prev)
  }
  if (!members.length) return
  return (
    <Wrapper>
      <Minidropdown onClick={handleClick} isActive={isActive}>
        Отправлено приглашений: {members.length}
      </Minidropdown>
      <StyledTableBody>
        {isActive &&
          // @ts-ignore
          members.map(member => (
            <TableRow sx={{ "& > td": { height: 104 } }}>
              {/* @ts-ignore */}
              <MemberRow member={member} isEdit={false} />
            </TableRow>
          ))}
      </StyledTableBody>
    </Wrapper>
  )
}

const Wrapper = styled("div")(() => ({
  marginTop: 72,
  marginBottom: 20,
}))

const StyledTableBody = styled(TableBody)(() => ({
  "& > tr > td": { borderTop: "1px solid rgba(21, 101, 192, 0.3)", borderBottom: 0 },
}))

// @ts-ignore
const Minidropdown = ({ children, onClick, isActive }) => {
  return (
    <DropdownWrapper sx={{ translate: "345px 16px" }} onClick={onClick}>
      <Typography>{children}</Typography>
      <ArrowWrapper sx={{ rotate: isActive ? "0deg" : "180deg" }} />
    </DropdownWrapper>
  )
}

const DropdownWrapper = styled("div")(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  borderRadius: "8px",
  border: "1px solid #1565C0",
  background: "#FFF",
  alignItems: "center",
  padding: "4px 16px",
  paddingRight: "36px",
  cursor: "pointer",
  width: "auto",
  maxWidth: "fix-content",
  maxHeight: 32,

  "&:hover": {
    // @ts-ignore
    backgroundColor: theme.palette.additional.lightBlue,
  },
}))

const ArrowWrapper = styled(ArrowDropDownIcon)(() => ({
  position: "absolute",
  top: "50%",
  right: "8px",
  translate: "0 -50%",
  scale: "1.4",
  fill: "#1565C0",
}))

export { InvitedMembers }
