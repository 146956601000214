import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import VisibilitySensor from "react-visibility-sensor"
import { utils, writeFile, writeFileXLSX } from "xlsx"

export const TableReviews = () => {
  const [data, setData] = useState([])
  const [variantValue, setVariantValue] = useState(null)
  const [variants, setVariants] = useState([])
  const [page, setPage] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [header, setHeader] = useState([])
  const [downloadXL, setDownloadXL] = useState(false)

  const handleScrolledToBottom = isBottomVisible => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      getReviews()
    }
  }

  useEffect(() => {
    setPage(0)
    setData([])
  }, [variantValue])

  useEffect(() => {
    axios.get("/content/courses/").then(res => {
      setVariants([...res.data.map(e => ({ label: e.title, value: e.id }))])
    })
  }, [])

  function handleClick() {
    getReviews()
  }

  function downloadFile() {
    setDownloadXL(true)
    let arr = [["ФИО", "Score"]]
    header.forEach(item => {
      arr[0].push(item.question)
    })
    data.forEach(item => {
      if (item.other.length > 0) {
        let keys = []
        keys.push(item.score)
        item.other.forEach(q => {
          keys.push(q.key)
        })
        arr.push([`${item.user_data.last_name} ${item.user_data.first_name}`, ...keys])
      }
    })
    // Создание нового листа и назначение данных
    let ws = utils.aoa_to_sheet(arr) // изменили переменную data на arr
    // Создание новой книги Excel и добавление листа
    let wb = utils.book_new()
    utils.book_append_sheet(wb, ws, "Sheet1")
    // Загрузка книги в браузер как файл
    writeFile(wb, `${variantValue.label}.xlsx`)
    setDownloadXL(false)
  }

  function getReviews() {
    let index = page + 1
    setPage(index)
    setIsLoading(true)
    axios
      .get("/content/reviews/", { params: { page: index, course: variantValue.value } })
      .then(res => {
        if (!!res.data.results) {
          parseOther(res.data.results)
        }
        setHasNextPage(!!res.data.next)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        setHasNextPage(false)
      })
  }

  function parseOther(values) {
    values.map(e => (e.other = JSON.parse(e.other)))
    values.forEach(item => {
      if (item.other.length > 0) {
        setHeader(item.other)
      }
    })
    setData([...data, ...values])
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <div style={{ display: "flex", gap: 8, justifyContent: "center", alignItems: "center", marginTop: 20 }}>
        <Autocomplete
          disablePortal
          id="course__choise"
          value={variantValue}
          onChange={(event, newValue) => {
            setVariantValue(newValue)
          }}
          options={variants}
          renderInput={params => <TextField {...params} variant="outlined" style={{ width: 480 }} label="Курс" />}
        />
        <Button variant="contained" color="primary" size="large" onClick={handleClick}>
          Посмотреть
        </Button>
        {data.length > 0 && (
          <Button variant="contained" color="primary" size="large" onClick={downloadFile} disabled={downloadXL}>
            Скачать статистику
          </Button>
        )}
        {downloadXL && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 40 }}>
            <CircularProgress size={26} />
          </div>
        )}
      </div>
      <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
        <TableContainer component={Paper} style={{ marginTop: "20px", width: "90vw" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              {data.length > 0 && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Score</TableCell>
                  {header.map(e => (
                    <TableCell>{e.question}</TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {data.map(
                row =>
                  row.other.length > 0 && (
                    <TableRow>
                      <TableCell>{`${row.user_data.last_name} ${row.user_data.first_name}`}</TableCell>
                      <TableCell>{row.score}</TableCell>
                      {row.other.map(e => (
                        <TableCell component="th" scope="row">
                          {e.key || ""}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </VisibilitySensor>
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 40 }}>
          <CircularProgress size={26} />
        </div>
      )}
    </div>
  )
}
