import React from "react"
import * as Styled from "./Landing.styles"
import { Grid } from "@mui/material"
import { useTheme } from "shared/context"
import { Button } from "shared/ui"
import { programItems } from "./mock"

export const ProgramsOfPlatform = () => {
  const theme = useTheme()

  return (
    <Styled.ProgramsOfPlatformMainDiv>
      <Styled.StyledTitle variant={"h2"} sx={{ color: "#313439" }}>
        Программы и мероприятия партнеров
      </Styled.StyledTitle>
      <Styled.MiddleTypography
        variant={"body1"}
        fontWeight={"medium"}
        fontSize={"medium"}
        sx={{ [theme.breakpoints.down("sm")]: { marginBottom: theme.spacing(1) } }}
      >
        Присоединяйтесь к существующим программам или запустите свои.
      </Styled.MiddleTypography>
      <Grid
        sx={{ [theme.breakpoints.down("sm")]: { alignItems: "center", justifyContent: "center" } }}
        container
        display={"flex"}
        justifyContent={"space-between"}
        width={"auto"}
        spacing={16}
      >
        {programItems.map(e => (
          <Styled.StyledGrid item height={"80%"} flexDirection={"column"} justifyContent={"space-between"}>
            <Styled.StyledSubtitle
              variant={"h3"}
              sx={{
                color: "#313439",
                height: "64px",
                marginBottom: theme.spacing(6),
              }}
            >
              {e.title}
            </Styled.StyledSubtitle>
            <img
              src={e.image}
              onClick={() => (window.location.href = e.href)}
              style={{ width: "100%", marginTop: "auto", cursor: "pointer" }}
            />
          </Styled.StyledGrid>
        ))}
      </Grid>
      {/*@ts-ignore*/}
      <Button
        sx={{
          marginTop: theme.spacing(16),
          [theme.breakpoints.down("sm")]: { width: "60%", alignSelf: "center" },
          [theme.breakpoints.down("xs")]: { width: "100%", alignSelf: "center" },
        }}
        variant={"contained"}
        color={"primary"}
        onClick={() => {
          // @ts-ignore
          window.location.href = `/catalog/`
        }}
      >
        Все программы
      </Button>
    </Styled.ProgramsOfPlatformMainDiv>
  )
}
