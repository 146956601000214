import {
  ISubmission,
  TimerType,
  OverallType,
  FeedbackType,
  TestType,
  IndecesObject,
  ObjectValueType,
} from "../model/test.types"

export function timerRestart(offestTime: number[], timer: TimerType) {
  if (!offestTime) return
  let timerOffset = new Date()
  timerOffset.setHours(timerOffset.getHours() + offestTime[0])
  timerOffset.setMinutes(timerOffset.getMinutes() + offestTime[1])
  timerOffset.setSeconds(timerOffset.getSeconds() + offestTime[2])
  timer.restart(timerOffset)
}

export function stringifyScore(score: number, maxScore: number) {
  if (!score || score < 0) return `(Баллы 0/${maxScore})`
  return `(Баллы ${score}/${maxScore})`
}

export function parseFeedback(feedback: FeedbackType) {
  var res = {} as OverallType
  res["overall"] = feedback.score === feedback.maxScore
  return res
}

export function changeByIndex(object: IndecesObject, index: number, value: ObjectValueType) {
  object[index] = value
  return [...object]
}

export const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue

export const formTaskSubmissions = (tests: TestType[], selectedAnswers: Array<string[]>) => {
  const result = new Array()
  let answer
  for (let index = 0; index < tests.length; index++) {
    if (selectedAnswers[index]) {
      if (tests[index].type == "attach") {
        continue
      }
      if (tests[index].type == "choice") {
        answer = new Array()
        for (var item in selectedAnswers[index]) {
          if (selectedAnswers[index][item]) {
            answer.push(item)
          }
        }
        answer = JSON.stringify(answer)
      }
      if (tests[index].type == "matrix") {
        answer = new Array()
        var checked = new Array(tests[index].variants_y.length).fill(false)
        for (var item in selectedAnswers[index]) {
          if (selectedAnswers[index][item]) {
            answer.push(item)
            // @ts-expect-error
            checked[parseInt(item.split(",").pop()) - 1] = true
          }
        }
        // for (let index = 0; index < checked.length; index++) {
        //   if (!checked[index]) {
        //     answer.push(`0,${index + 1}`)
        //   }
        // }
        answer = JSON.stringify(answer)
      }
      if (tests[index].type == "detailed" || tests[index].type == "numeric") {
        answer = selectedAnswers[index][tests[index].index] || ""
      }
      if (tests[index].type == "single-choice" || tests[index].type == "sort" || tests[index].type == "match") {
        answer = JSON.stringify(selectedAnswers[index])
      }
      result.push({ task: tests[index].task, key: answer })
    }
  }
  return result
}

export function parseKeys(submissions: ISubmission[]) {
  const answers = [] as { [x: string]: boolean | string }[]
  submissions.forEach(item => {
    if (item.component.type === "choice" || item.component.type === "matrix") {
      let key = {} as { [x: string]: boolean }
      JSON.parse(item.key).forEach((e: number) => {
        key[e] = true
      })
      answers.push(key)
    } else if (item.component.type === "detailed" || item.component.type === "numeric") {
      answers.push({ [item.component.index]: item.key })
    } else answers.push(JSON.parse(item.key))
  })
  return answers
}

export function parseTime(time: string) {
  const expiryTimestamp = new Date()
  if (time) {
    const parsedTime = time.split(":").map(item => Number(item))
    expiryTimestamp.setHours(parsedTime[0])
    expiryTimestamp.setMinutes(parsedTime[1])
    expiryTimestamp.setSeconds(parsedTime[2])
  }
  return expiryTimestamp
}

export const checkIsTestEmpty = (selectedAnswers: { [x: string]: string }[], tests: TestType[]) => {
  const isNonAttached = tests.find(test => test.type !== "attach") || false

  if (!isNonAttached) return false

  const result = !selectedAnswers.find((answer, index) => {
    const isAttach = tests[index].type === "attach"
    const keys = answer ? Object.keys(answer) : []
    const isFilledAnswer = keys.find(key => {
      return answer[key] !== ""
    })

    return !isAttach && isFilledAnswer
  })
  return result
}
