import React, { useState, useCallback, useEffect } from "react"
import { Typography, Dialog } from "@mui/material"
import useStyles from "./styles"
import { useInput } from "shared/hooks"
import { useUserState } from "shared/context"
import Confirm from "./Confirm"
import RegistrationSuccess from "./RegistrationSuccess"
import { RegistrationFirstStep } from "widgets"
import { IFormData } from "entities/User"

const Registration = props => {
  var classes = useStyles()

  const { login, registration } = useUserState()
  const [step, setStep] = useState(1)
  const [userData, setUserData] = useState(null)
  const [registerHash, setRegisterHash] = useState("")

  const close = () => {
    registration.setOpen(false)
    if (step === 3) {
      setStep(1)
    }
  }

  return (
    <Dialog open={registration.open} onClose={close} fullWidth maxWidth="sm">
      <div style={{ padding: 36, paddingTop: 18, paddingBottom: 24 }}>
        {step !== 3 && (
          <Typography variant="h2" className={classes.greeting} style={{ fontWeight: 600, marginBottom: 12 }}>
            {`Регистрация на платформе`} <br />
            {`(шаг ${step} из 2)`}
          </Typography>
        )}
        {step === 1 && (
          <>
            <RegistrationFirstStep
              data={userData}
              setUserData={value => setUserData({ ...value })}
              setStep={value => setStep(value)}
              setRegisterHash={value => setRegisterHash(value)}
            />
          </>
        )}
        {step === 2 && (
          <Confirm
            {...userData}
            registerHash={registerHash}
            setStep={setStep}
            success={() => {
              setStep(3)
              setTimeout(() => {
                close()
                setStep(1)
                login.setOpen(false)
              }, 1500)
            }}
          />
        )}
        {step === 3 && <RegistrationSuccess login={userData.email} password={userData.password} />}
      </div>
    </Dialog>
  )
}

export default Registration
