import * as yup from "yup"

export const schema = yup.object().shape({
  first_name: yup
    .string()
    .required("Заполните это поле.")
    .min(2, "Поле имя должно содержать не менее 2 символов.")
    .max(32, "Превышен лимит символов для ввода имени. Максимальное количество символов - 32."),
  last_name: yup
    .string()
    .required("Заполните это поле.")
    .min(1, "Поле фамилия должно содержать не менее 1 символа.")
    .max(32, "Превышен лимит символов для ввода фамилии. Максимальное количество символов - 32."),
  middle_name: yup
    .string()
    .max(32, "Превышен лимит символов для ввода отчества. Максимальное количество символов - 32."),
  email: yup
    .string()
    .email("Почты с таким названием не существует. Проверьте правильность введённых данных.")
    .required("Заполните это поле."),
  password: yup
    .string()
    .required("Заполните это поле.")
    .matches(/^(?!\s)(?!.*\s).+$/, "Пароль не может содержать пробелы. Проверьте правильность введенных данных.")
    .min(8, "Пароль должен содержать не менее 8 символов.")
    .max(32, "Превышен лимит символов для ввода пароля."),
  passwordConfirm: yup
    .string()
    .required("Пароли не совпадают. Проверьте правильность введённых данных.")
    /*@ts-ignore*/
    .oneOf([yup.ref("password"), null], "Пароли не совпадают. Проверьте правильность введённых данных."),
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Номер телефона введён некорректно. Проверьте правильность введённых данных.")
    .required("Заполните это поле."),
})
