import React from 'react';
import useStyle from "./styles"
import {Typography} from "@mui/material";

const Advantage = ({e}) => {

    const classes = useStyle()

    return (
        <div className={classes.advantageContainer}>
            <img src={e.Image?.url} className={classes.imageAdvantage}/>
            <Typography variant={"h3"} color={e.H3?.color}>{e.H3?.text}</Typography>
            <Typography color={e.BaseText?.color}>{e.BaseText?.text}</Typography>
        </div>
    );
};

export default Advantage;
