//@ts-nocheck
import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import * as Styled from "./Organization.styles"
import { useTheme } from "shared/context"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { StyledOrganizationTitle } from "./Organization.styles"

interface IProps {
  title: string
  logo: string
  slug: string | null
  location: string
  description: string
  id: number
}

export const OrganizationCardForLanding = ({ title, logo, slug, location, description, id }: IProps) => {
  const theme = useTheme()
  const [events, setEvents] = useState([])

  useEffect(() => {
    const ref_token = localStorage.getItem("token_ref")
    if (!ref_token) {
      delete axios.defaults.headers.common["Authorization"]
    }
    axios
      .get(`/content/courses/`, { params: { type: "event", affiliations__id: id, visibility: "visible" } })
      .then(res => {
        setEvents([...res.data])
      })
  }, [])

  return (
    <Styled.OrganizationCardForLandingMainDiv>
      <Styled.OrganizationCardForLandingTitleDiv>
        <Styled.TitleWithDescriptionDiv>
          <Styled.StyledOrganizationTitle variant={"h3"}>{title}</Styled.StyledOrganizationTitle>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            {location}
          </Typography>
        </Styled.TitleWithDescriptionDiv>
        <Styled.OrganizationLogoForLanding src={logo} />
      </Styled.OrganizationCardForLandingTitleDiv>
      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </Typography>
      <Styled.OrganizationEventsItemsDiv>
        {events
          .filter(e => e.title.length < 40)
          .slice(0, 3)
          .map(e => (
            <Styled.OrganizationEventItem
              sx={{ backgroundColor: JSON.parse(e.template_props).main_screen.backgroundColor }}
              onClick={() => (window.location.href = `/event/${e.slug}`)}
            >
              <Styled.StyledEventTitle variant={"h3"} sx={{ color: theme.palette.common.white }}>
                {e.title}
              </Styled.StyledEventTitle>
              <ArrowForwardIcon sx={{ width: "40px", color: theme.palette.common.white, alignSelf: "flex-end" }} />
            </Styled.OrganizationEventItem>
          ))}
      </Styled.OrganizationEventsItemsDiv>
      {events?.length > 3 && (
        <Styled.ProgramsLink
          onClick={() => {
            window.location.href = `/catalog/organization/${id}`
          }}
        >
          Все программы
        </Styled.ProgramsLink>
      )}
    </Styled.OrganizationCardForLandingMainDiv>
  )
}
