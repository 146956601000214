import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"

export const AwaitingDialog = ({ open, children }) => {
  const text = children || "Подождите, пожалуйста, идет обработка"

  return (
    <Dialog aria-labelledby="DialogTitle" aria-describedby="DialogDescription" open={open}>
      <DialogContent>
        <DialogContentText id="DialogDescription">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{ fontFamily: "Roboto", fontSize: 16, fontWeight: 400, marginBottom: "10px" }}>{text}</div>
            <CircularProgress />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
