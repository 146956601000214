import * as Styled from "./Selector.styles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from "react"
import { Grow } from "@mui/material"
import { useClickOutside } from "shared/hooks"

interface IProps {
  children: ReactNode
  isActive: boolean
  label: string
  type: "default" | "rounded"
  isTriggerClose?: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
  resetSelector?: () => void
  onClose?: () => void
  enableEventDateSelector: boolean
}

const Selector = ({
  children,
  isActive,
  label,
  type = "default",
  isTriggerClose,
  setIsActive,
  resetSelector,
  onClose,
  enableEventDateSelector,
}: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)


  useEffect(() => {
    if (!isActive && resetSelector) {
      setTimeout(() => {
        resetSelector()
      }, 200)
    }
    if (!isActive && onClose) {
      onClose()
    }
  }, [isActive])

  useEffect(() => {
    if (isTriggerClose) setIsActive(false)
  }, [isTriggerClose])

  useClickOutside(containerRef, () => {
    if (isActive && type === "rounded") {
      setIsActive(false)
    }
  })

  return (
    <Styled.SelectorContainer ref={containerRef}>
      <Styled.SelectWrapper
        onClick={() => setIsActive(true)}
        ref={wrapperRef}
      >
        <Styled.Label isOutlined={label === "Другой интервал" && isActive} enableEventDateSelector={enableEventDateSelector}>{label}</Styled.Label>
        <Styled.IconWrapper style={{ rotate: isActive ? "180deg" : "0deg" }} enableEventDateSelector={enableEventDateSelector}>
          <ArrowDropDownIcon style={{ scale: "1.4" }} />
        </Styled.IconWrapper>
      </Styled.SelectWrapper>
      <Grow in={isActive} style={{ transformOrigin: "0 0 0" }}>
        <Styled.ContentWrapper
          wrapperRef={wrapperRef}
        >
          <Styled.Content type={type}>{children}</Styled.Content>
        </Styled.ContentWrapper>
      </Grow>
    </Styled.SelectorContainer>
  )
}

export { Selector }
