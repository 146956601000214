import { ReactNode } from "react"
import { WarningIcon } from "images/icons/Warning"
import { Typography as MuiTypography, styled } from "@mui/material"
import { ThemeType } from "shared/themes/default.types"
import { parseDeadline } from "../lib/timer"

interface IProps {
  deadline: string
  isDeadline: boolean
  score?: number
}

const TestTimerHeader = ({ score, deadline, isDeadline }: IProps) => {
  const userScore = isDeadline ? score : "подсчитываем баллы"

  return (
    <Wrapper>
      <WarningIcon />
      <Text>
        <Typography>{!isDeadline && <Strong>Ответы отправлены на проверку</Strong>}</Typography>
        <Typography>
          Ваша оценка: <Strong>{userScore}</Strong>
        </Typography>
        {!isDeadline && (
          <Typography>
            Результат будет доступен: <Strong>{parseDeadline(deadline, true)} (Мск)</Strong>
          </Typography>
        )}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled("div")(({ theme, children }: { theme?: ThemeType; children: ReactNode }) => ({
  border: `1px solid ${theme?.palette.info.dark}`,
  borderRadius: 12,
  backgroundColor: theme?.palette.info.light,
  width: "100%",
  padding: "40px 64px 32px",
  display: "flex",
  gap: 40,
}))

const Text = styled("p")(() => ({}))

const Typography = styled(MuiTypography)(() => ({
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "155%" /* 27.9px */,
}))

const Strong = styled("span")(() => ({
  fontWeight: "400",
}))

export { TestTimerHeader }
