const numField = { name: null, sign: 1, value: 0 }

export const filterNumericFields = item => {
  if (!numField.name) return true
  if (numField.name === "total_score") {
    return item.score * numField.sign > numField.value * numField.sign
  }
  if (!item.module_progress[numField.name]) return numField.sign < 0
  return item.module_progress[numField.name].score * numField.sign > numField.value * numField.sign
}
