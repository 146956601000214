import styled from "@emotion/styled"

export const ListItem = styled.li<{ isDisabled: boolean }>`
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  opacity: ${props => (props.isDisabled ? "0.5" : "1")};
`

export const ItemHeader = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: ${props => (props.isDisabled ? "auto" : "pointer")};
`

export const LabelWrapper = styled.span`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  max-width: 420px;  
`

export const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ArrowWrapper = styled.div<{ isActive: boolean }>`
  transform: ${props => !props.isActive && "rotate(-180deg)"};
`

export const Stats = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
`

export const Number = styled.span<{ color: string }>`
  color: ${props => props.color};
`

export const Answers = styled.ul`
  list-style: none;
  margin-top: 12px;
  padding-left: 30px;
`

export const Answer = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24.8px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Marker = styled.span<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${props => props.color};
`

export const Title = styled.span`
  max-width: 240px;
`

export const Count = styled.span<{ color: string }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  color: ${props => props.color};
  margin-left: auto;
`
