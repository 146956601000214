import { useState, useEffect, useMemo } from "react"
import { timerRestart, parseTime } from "entities/Test/lib/test"
import { useTimer } from "react-timer-hook"
import { getTimer, startTimer } from "entities/Assignment/api/assignment"
import { useDispatch } from "react-redux"
import { timerActions } from "shared/store"
import { getDeadline } from "entities/Assignment/api/assignment"
import { useAppSelector } from "shared/hooks/useAppSelector"

enum TestReview {
  submitted = "SUBMITTED",
}

interface IProps {
  block: { id: number; components: Array<{ props: string }>; props: string; deadline: string }
  attempts: { timer: string }
  isTimer: boolean
  isReachPredeadline: boolean
  isReachDeadline: boolean
  isLoadedSubmission: boolean
  isLoadedDealine: boolean
  submission: null | Array<string>
  handleSubmit: (x?: boolean) => void
  setIsResultsModal: (x: boolean) => void
  setIsExpiredModal: (x: boolean) => void
  setIsStartTimerModal: (x: boolean) => void
  setIsReachPredeadline: (x: boolean) => void
}

const useTimerTest = (props: IProps) => {
  const {
    block,
    attempts,
    handleSubmit,
    isTimer,
    isReachPredeadline,
    isLoadedSubmission,
    isLoadedDealine,
    setIsStartTimerModal,
    setIsResultsModal,
    setIsExpiredModal,
    setIsReachPredeadline,
  } = props
  const [leftTime, setLeftTime] = useState<null | string>(null)
  const [isFirstTestTry, setIsFirstTestTry] = useState(false)
  const [isDisplayTimer, setIsDisplayTimer] = useState(!isTimer)
  const [isDisplayTest, setIsDisplayTest] = useState(false)
  const [isCompletedTest, setIsCompletedTest] = useState(false)
  const [isLoadedTimer, setIsLoadedTimer] = useState(false)
  const isTimeElapsed = useMemo(() => leftTime === "Time elapsed", [leftTime])
  const initialTime = parseTime(attempts.timer)
  const timerState = useTimer({
    expiryTimestamp: initialTime,
    autoStart: false,
    onExpire: () => handleSubmit(true),
  })
  // таймер вызывает onExpire метод на 1 секунде, а не на 0
  const isLeftTime = useMemo(
    () => isTimer && timerState.totalSeconds !== 0 && timerState.totalSeconds !== 1 && leftTime !== "Time elapsed",
    [timerState]
  )
  usePreventLeaving(isLeftTime, block.id, timerState)

  useEffect(() => {
    restartTest()
  }, [block.id])

  useEffect(() => {
    if (!isLoadedTimer || !isLoadedSubmission || !isLoadedDealine) return
    isTimer && initTimerTest()
  }, [isLoadedTimer, isLoadedSubmission, isLoadedDealine])

  async function restartTest() {
    setIsFirstTestTry(false)
    setIsStartTimerModal(false)
    setIsResultsModal(false)
    setIsExpiredModal(false)
    setIsDisplayTest(!isTimer)
    setIsDisplayTimer(false)
    setLeftTime(null)
    setIsCompletedTest(false)
    setIsLoadedTimer(false)
    await getLeftTime()
  }

  function initTimerTest() {
    if (isFirstTestTry && !isReachPredeadline) {
      return setIsStartTimerModal(true)
    }
    if (!isTimeElapsed && !isCompletedTest && !isReachPredeadline) {
      const time = typeof leftTime === "string" ? leftTime.split(":").map(e => parseInt(e, 10)) : [0, 0, 0]
      timerRestart(time, timerState)
      setIsDisplayTimer(true)
      setIsDisplayTest(true)
      return
    }
    if (isTimeElapsed && isCompletedTest) {
      setIsResultsModal(true)
    }
    if (isReachPredeadline && !isCompletedTest) {
      setIsExpiredModal(true)
    }
  }

  async function getLeftTime() {
    try {
      const response = await getTimer(block.id)
      const isCompleted = response.data.test_completed
      setIsCompletedTest(isCompleted)
      setIsFirstTestTry(response.status === 204)
      isCompleted ? setLeftTime("Time elapsed") : setLeftTime(response.data.residual_time)
    } catch {
    } finally {
      setIsLoadedTimer(true)
    }
  }

  const startNewTimer = async () => {
    try {
      const isDelayedStart = await checkDelayedStart()
      if (isDelayedStart) return
      await startTimer({ content_block: block.id })
      setIsStartTimerModal(false)
      setIsDisplayTest(true)
      setIsDisplayTimer(true)
      const time = attempts.timer?.split(":").map(e => parseInt(e, 10))
      timerRestart(time, timerState)
    } catch {}
  }

  const checkDelayedStart = async () => {
    const response = await getDeadline(block.id, true)
    setIsReachPredeadline(response.is_deadline)
    if (response.is_deadline) setIsStartTimerModal(false)
    return response.is_deadline
  }

  return {
    timerState,
    startNewTimer,
    isDisplayTest,
    isDisplayTimer,
    isTimeElapsed,
    isLeftTime,
    setIsDisplayTimer,
    setIsDisplayTest,
  }
}

const usePreventLeaving = (isLeftTime: string | boolean | null, blockId: number, timerState: any) => {
  const dispatch = useDispatch()
  const isRunningTimer = useAppSelector(state => state.timer.isRunning)

  useEffect(() => {
    dispatch(timerActions.setIsRunningTimer(isLeftTime))
    if (!isRunningTimer) return
    const handleBeforeUnload = (event: any) => {
      const message =
        "Вы сможете вернуться к выполнению теста, пока не вышло время таймера. После истечения времени отправка результатов будет недоступна. Вы точно хотите закрыть страницу?"
      event.returnValue = message
      return message
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [timerState, isRunningTimer, blockId])
}

export default useTimerTest
