import styled from "@emotion/styled"
import SuccessIcon from "images/icons/Success"
import FailIcon from "images/icons/Fail"
import { ThemeType } from "shared/themes/default.types"
import { CircularProgress, Typography } from "@mui/material"

interface IProps {
  isLoading?: boolean
  successMessage?: string
  errorMessage?: string
  size?: Size
}

type Size = "small" | "big"

const StatusMessage = ({ isLoading, successMessage, errorMessage, size = "big" }: IProps) => {
  if (isLoading)
    return (
      <StatusWrapper>
        <CircularProgress size={26} sx={{ marginTop: size === "big" ? "16px" : "" }} />
      </StatusWrapper>
    )

  return (
    <StatusWrapper>
      {successMessage && (
        <MessageWrapper isSmall={size === "small"}>
          <SuccessIcon />
          <Typography sx={{ lineHeight: "20.4px", letterSpacing: "0.06px" }}>{successMessage}</Typography>
        </MessageWrapper>
      )}
      {errorMessage && (
        <MessageWrapper isSmall={size === "small"}>
          <FailIcon />
          <Typography sx={{ lineHeight: "20.4px", letterSpacing: "0.06px" }}>{errorMessage}</Typography>
        </MessageWrapper>
      )}
    </StatusWrapper>
  )
}

const StatusWrapper = styled("div")(({ theme }: { theme?: ThemeType }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme?.spacing(6),
  marginTop: theme?.spacing(8),
}))

const MessageWrapper = styled.div<{ isSmall: boolean }>`
  display: ${props => (props.isSmall ? "flex" : "block")};
  gap: 16px;
  align-items: center;

  & > svg {
    scale: ${props => (props.isSmall ? 0.5 : 1)};
  }
`

export { StatusMessage }
