import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TextField } from "../../../shared/ui"

export const LandingFormInput = ({ name, label, type = "text", required, style, disableTooltip = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors[name] ? errors[name].message : ""

  const handleChange = (e, field) => {
    if (type === "email") {
      const trimmedValue = e.target.value.replace(/\s/g, "")
      const lowerCaseValue = trimmedValue.toLowerCase()
      field.onChange(lowerCaseValue)
    } else if (type === "name") {
      let newValue = e.target.value
      if (!newValue.charAt(0).match(/\p{L}/u)) newValue = newValue.slice(1)
      if (
        newValue.slice(-1) === " " ||
        newValue.slice(-1) === "-" ||
        newValue === "" ||
        newValue.slice(-1).match(/\p{L}/u)
      ) {
        field.onChange(newValue)
      }
    } else {
      field.onChange(e.target.value)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          style={!!style && { ...style }}
          variant={"outlined"}
          onChange={e => handleChange(e, field)}
          focused={!!field.value}
          error={!!errorMessage}
          helperText={errorMessage}
          label={label}
          fullWidth
          required={!!required}
          type={"text"}
          disableTooltip={disableTooltip}
        />
      )}
    />
  )
}
