import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  mainScreenContainer: {
    minHeight: "40.108vw",
    /*height: "48vw",*/
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },

  mainScreenContentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginLeft: 124,
    marginRight: 124,
    paddingTop: 96,
    paddingBottom: 96,

    baseText: {
      fontSize: 20,
    },

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 60,
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },

  mainScreenTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    zIndex: 4,
    position: "relative",

    "& > div:last-of-type": {
      fontSize: 20,
    },

    [theme.breakpoints.up("lg")]: {
      gap: 75,
      width: "30vw",
      "& > div:last-of-type": {
        fontSize: 20,
      },
    },
    [theme.breakpoints.down("lg")]: {
      gap: 45,
      width: "35vw",
      "& > div:last-of-type": {
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {},
  },
  imageMain: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      marginTop: 75,
    },
  },
}))
