import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { reducer as assignmentReducer } from "./assignment/assignmentsSlice"
import { reducer as invitationReducer } from "./invitation/invitationSlice"
import { reducer as userReducer } from "./user/userSlice"
import { reducer as achievementReducer } from "./achievement/achievementSlice"
import { reducer as certificateReducer } from "./certificate/certificateSlice"
import { reducer as teamReducer } from "./team/TeamSlice"
import { reducer as courseReducer } from "./course/CourseSlice"
import { reducer as organizationReducer } from "./organizations/OrganizationSlice"
import { reducer as hardwareReducer } from "./iot/IotSlice"
import { reducer as timerReducer } from "./timer/timerSlice"
import { reducer as testReducer } from "./test/testSlice"
import { reducer as trajectoryReducer } from "./trajectory/trajectorySlice"

const rootReducer = combineReducers({
  assignment: assignmentReducer,
  achievement: achievementReducer,
  certificate: certificateReducer,
  invitation: invitationReducer,
  user: userReducer,
  team: teamReducer,
  course: courseReducer,
  organization: organizationReducer,
  iot: hardwareReducer,
  timer: timerReducer,
  test: testReducer,
  trajectory: trajectoryReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { actions as assignmentsActions } from "./assignment/assignmentsSlice"
export { fetchAchievements } from "./achievement/achievementActions"
export { fetchAssignments } from "./assignment/assignmentActions"
export { fetchCertificates } from "./certificate/certificateActions"
export { fetchInvitations, acceptInvitation, rejectInvitation } from "./invitation/invitationActions"
export { fetchProfile } from "./user/userActions"
export { actions as teamActions } from "./team/TeamSlice"
export { actions as courseActions } from "./course/CourseSlice"
export { actions as organizationActions } from "./organizations/OrganizationSlice"
export { actions as iotActions } from "./iot/IotSlice"
export { actions as timerActions } from "./timer/timerSlice"
export { actions as testActions } from "./test/testSlice"
export { actions as trajectoryActions } from "./trajectory/trajectorySlice"
