import styled from "@emotion/styled"

interface LegendWrapperProps {
  legendBackgroundColor: "requestsBg" | "statusesBg" | "surveyBg" | "personalBg";
}

export const LegendWrapper = styled.div<LegendWrapperProps>`
  flex: 40%;
  max-width: 40%;
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 46px 24px;
  min-height: clamp(500px, 44vw, 700px);
  background-color: ${({ legendBackgroundColor }) => (legendBackgroundColor === "requestsBg" ? "#E6F4F380" :
          legendBackgroundColor === "statusesBg" ? "#EEF0F080" :
                  legendBackgroundColor === "surveyBg" ? "#EDE6F880" :
                          legendBackgroundColor === "personalBg" ? "#FEF4E780" :
                                  "#FFFFFF")}

}
`
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  gap: 24px;
`
export const RequestsTotalContainer = styled.div`
  color: #0873A1;
`
export const RequestsTotalCount = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 48px;
  line-height: 115%;
`
export const RequestsTotalLabel = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
`

export const InProgressFailedContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`
export const InProgressContainer = styled.div`
  color: ${({ color }) => (color === "scatter" ? "#00000066" : "#2EAD58")}
`
export const FailedContainer = styled.div`
  color: ${({ color }) => (color === "scatter" ? "#00000066" : "#FF564E")}
`
export const RequestedContainer = styled.div`
  color: ${({ color }) => (color === "scatter" ? "#00000066" : "#6B6D70")}
`
export const RequestedCount = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 48px;
  line-height: 115%;
`
export const RequestedLabel = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
`
export const HandleRequests = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color:#1565C0;
  position: absolute;
  right: 0;
  bottom: 0;
`
export const LegendInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const SelectorWrapper = styled.div`
  max-width: 323px;
`
export const InfoItemsWrapper = styled.div`
  flex: 1;
  position: relative;
`
export const TotalUsersContainer = styled.div`
  color: #1B7CEA;
`
export const TotalUsersCount = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 48px;
  line-height: 115%;
`
export const TotalUsersLabel = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
`

export const ModeratorsTeachersContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`
export const ModeratorsContainer = styled.div`
  color: #CA44E0;
`
export const TeachersContainer = styled.div`
  color: #25C6D0;
`
export const ModeratorsCount = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 48px;
  line-height: 115%;
`
export const ModeratorsLabel = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
`
export const TeachersCount = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 48px;
  line-height: 115%;
`
export const TeachersLabel = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
`

export const ActiveContainer = styled.div`
  color: #3EC344;
`
export const OthersContainer = styled.div`
  color: #7B85AB;
`

export const InfoItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: -24px;
  padding-right: 16px;
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  bottom: -46px;
  padding-bottom: 46px;
  overflow-y: auto;
  scrollbar-gutter: stable;
`
export const GeneralItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  row-gap: 16px;
  max-width: 340px;
  margin-bottom: 32px;
`
export const GeneralItem = styled.div<{ width: number; color: string }>`
  text-align: left;
  color: ${props => props.color};
  max-width: ${props => `${props.width}px`};
`
export const GeneralCount = styled.p`
  font-size: 48px;
  font-weight: 300;
  line-height: 58.09px;
  margin: unset;
  margin-bottom: -4px;
`
export const GeneralLabel = styled.p`
  margin: unset;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
`
export const BadgeWrapper = styled.div`
  width: fit-content;
  padding: 10.6px;
  scale: 2;
`
export const Name = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  color: #313439;
  margin: unset;
  margin-bottom: 4px;
`

export const Email = styled.span`
  font-size: 16px;
  font-weight: 300;
  line-height: 24.8px;
  color: #313439;
`
