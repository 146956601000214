import React, { useEffect, useRef, useState } from "react"
import * as Styled from "./ui/Iot.styles"
import { Hardware } from "./Hardware"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { fetchAllowedHardware, getToken } from "entities/Iot"
import { Card } from "shared/ui"
import { CircularProgress, Tabs, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { iotActions } from "../../shared/store"
import { EyeIcon } from "./ui/Iot.styles"
import { Sensors } from "./ui/Sensors"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
import { useTab } from "../../shared/hooks/useTab"
import { Charts } from "./ui/Charts"

const Iot = () => {
  const theme = useTheme()
  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const connectionStatus = useAppSelector(state => state.iot.connectionStatus)
  const dispatch = useAppDispatch()
  const userStatus = useAppSelector(state => state.iot.userStatus)
  const users = useAppSelector(state => state.iot.users)
  const gotConfig = useAppSelector(state => state.iot.gotConfig)
  const [isOpenChooseParam, setIsOpenChooseParams] = useState<boolean>(false)
  const ws = useRef<WebSocket | null>(null)
  const { changeTab, tab } = useTab("iot_tab")
  const ref_token = localStorage.getItem("token_ref")

  useEffect(() => {
    dispatch(fetchAllowedHardware())
  }, [])

  useEffect(() => {
    getToken(ref_token as string).then(res => {
      dispatch(iotActions.updateConfig(null))
      dispatch(iotActions.updateUiValues(null))
      dispatch(iotActions.setGotConfig(false))
      dispatch(iotActions.setConnectionStatus(false))
      const token = res.data.access
      axios.defaults.headers.common["Authorization"] = "Bearer " + token
      if (!!currentHardware) wsOpen(token)
      return () => {
        ws.current?.close()
      }
    })
  }, [currentHardware])

  useEffect(() => {
    if (!!currentHardware) {
      getToken(ref_token as string).then(res => {
        const token = res.data.access
        axios.defaults.headers.common["Authorization"] = "Bearer " + token
        wsOpen(token)
        return () => {
          ws.current?.close()
        }
      })
    }
  }, [userStatus])

  function wsOpen(token: string) {
    ws.current?.close()
    ws.current = new WebSocket(`wss://syncwoia.com/iot/${userStatus}/${currentHardware?.serial}/${token}/`)
    ws.current.binaryType = "arraybuffer"
    ws.current.onopen = () => {}
    ws.current.onmessage = res => {
      let data = JSON.parse(res.data)
      if (data.is_revise) {
        delete data["is_revise"]
        dispatch(iotActions.setConnectionStatus(true))
        dispatch(iotActions.updateConfig(data))
        dispatch(iotActions.setGotConfig(true))
      }
      if (data.real_devs && !gotConfig) {
        dispatch(iotActions.updateConfig(data))
        dispatch(iotActions.setGotConfig(true))
        dispatch(iotActions.setConnectionStatus(true))
      }
      if (data.devs_state) {
        dispatch(iotActions.setConnectionStatus(true))
        dispatch(iotActions.updateUiValues(data))
        dispatch(iotActions.setConnectionStatus(true))
      }
      if (data.state) {
        dispatch(iotActions.updateUiValue(data))
        dispatch(iotActions.setConnectionStatus(true))
      }
      if (data.status && (data.status === "observer" || data.status === "controller")) {
        dispatch(iotActions.setUserStatus(data.status))
        dispatch(iotActions.setConnectionStatus(true))
      }
      if (!!data.status && data.status === "disconnected") {
        dispatch(iotActions.setConnectionStatus(false))
        dispatch(iotActions.setGotConfig(false))
      }
    }
    ws.current.onclose = evt => {
      console.log("Disconnected: " + evt.reason)
    }
  }

  function changeValue(id: number, port: number, value: boolean) {
    let data = { set_state: [{ pid: id, port: port, val: Number(value) }] }
    if (!!ws.current) {
      ws.current.send(JSON.stringify(data))
    }
  }

  function handleClickStatus() {
    if (userStatus === "observer") dispatch(iotActions.setUserStatus("controller"))
    else dispatch(iotActions.setUserStatus("observer"))
  }

  return (
    <Styled.IotMainContainer>
      <Styled.TopContainerDiv>
        <Hardware />
        <Tabs
          value={tab}
          onChange={changeTab}
          sx={{
            [theme.breakpoints.down("sm")]: {
              marginLeft: 0,
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          <Styled.StyledTab
            sx={{
              borderRadius: theme.spacing(4),
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              marginBottom: theme.spacing(1),
              marginLeft: theme.spacing(1),
              color: tab === 0 ? "#1565C0" : "#313439",
              backgroundColor: tab === 0 ? "#DEF3FC" : "#FFF",
            }}
            label={
              <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
                Показатели
              </Typography>
            }
            value={0}
          ></Styled.StyledTab>
          <Styled.StyledTab
            sx={{
              borderRadius: theme.spacing(3),
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              marginRight: theme.spacing(1),
              marginBottom: theme.spacing(1),
              color: tab === 1 ? "#1565C0" : "#313439",
              backgroundColor: tab === 1 ? "#DEF3FC" : "#FFF",
            }}
            onClick={() => setIsOpenChooseParams(true)}
            label={
              <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
                Сравнение графиков
              </Typography>
            }
            value={1}
          ></Styled.StyledTab>
        </Tabs>
      </Styled.TopContainerDiv>
      {tab === 0 && (
        <>
          <Sensors changeValue={changeValue} />
        </>
      )}
      {tab === 1 && <Charts isOpenChooseParam={isOpenChooseParam} setIsOpenChooseParams={setIsOpenChooseParams} />}
      <Styled.BottomContainerDiv>
        {tab === 0 && !!currentHardware && users.length > 0 && connectionStatus && (
          <Card sx={{ width: "300px", padding: theme.spacing(4), [theme.breakpoints.down("xs")]: { width: "250px" } }}>
            <Styled.UserStatusContainer>
              {userStatus === "observer" && (
                <>
                  <RemoveRedEyeIcon sx={{ marginRight: theme.spacing(4) }} />
                  <Typography variant={"body1"} fontSize={"medium"} fontWeight={"medium"}>
                    Наблюдение
                  </Typography>
                  <Styled.SettingsButtonIcon onClick={handleClickStatus} />
                </>
              )}
              {userStatus === "controller" && (
                <>
                  <EyeIcon sx={{ marginRight: theme.spacing(4) }} onClick={handleClickStatus} />
                  <Styled.ControllerContainer>
                    <Styled.SettingsIcon />
                    <Typography sx={{ color: "#FFF" }} variant={"body1"} fontSize={"medium"} fontWeight={"medium"}>
                      Управление
                    </Typography>
                  </Styled.ControllerContainer>
                </>
              )}
            </Styled.UserStatusContainer>
          </Card>
        )}
        {!!currentHardware && !gotConfig && connectionStatus && (
          <Styled.ProgressBarContainer>
            <CircularProgress size={theme.spacing(6)} />
          </Styled.ProgressBarContainer>
        )}
      </Styled.BottomContainerDiv>
    </Styled.IotMainContainer>
  )
}

export default Iot
