import { IExtendFilters } from "shared/hooks/useExtendFiltersForm"
import { ModalSmallTemplate } from "shared/ui"
import { Variant } from "shared/ui/styles.const"

interface IProps {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  filters: IExtendFilters
}

const WarningResetModal = ({ isOpen, onClose, onClick, filters }: IProps) => {
  const label = getLabel()

  function getLabel() {
    if (filters.module) return "выбранный модуль"
    if (filters.stage) return "выбранный этап"
    return "весь курс"
  }

  return (
    <ModalSmallTemplate
      isOpen={isOpen}
      onClose={onClose}
      onClick={onClick}
      title={"Предупреждение"}
      onAltClick={onClose}
      isAbortButton={true}
      label="Подтвердить"
      abortLabel="Отмена"
      variant={Variant.outlined}
      isDeleting
    >
      Вы собираетесь обнулить прогресс ученика за {label}. Это действие нельзя обратить. Пожалуйста, подтвердите
      обнуление прогресса.
    </ModalSmallTemplate>
  )
}

export default WarningResetModal
