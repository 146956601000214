import React from "react"
import { Grid } from "@mui/material"
import * as Styled from "./styles"

const PartnersItem = ({ e }) => {
  return (
    <Styled.PartnerItem href={!!e.website && e.website} target="_blank" style={{ width: "100%" }}>
      <Styled.IconPartner src={e.url} alt={e.url} />
    </Styled.PartnerItem>
  )
}

export default PartnersItem
