import { Chip } from '@mui/material'

export function getStatusChip(status) {

  switch (status) {
     case "student":
        return <Chip label={"Пользователь"} style={{ backgroundColor: "#3EBD44", color: "#FFF" }}/>
     case "moderator":
        return <Chip label={"Модератор"} style={{ backgroundColor: "#D03FE8", color: "#FFF" }}/>
     case "admin":
        return <Chip label={"Администратор"} style={{ backgroundColor: "#F33D3D", color: "#FFF" }}/>
  }
}
