import React, { useEffect, useState } from "react"
import { Button, CircularProgress, FormHelperText, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import useStyles from "./styles"
import CheckIcon from "@mui/icons-material/Check"
import { InteractiveTable } from "widgets"

import { stringify } from "csv-stringify/sync"
import { SubmissionFeedback } from "features"

export function TableBlock(props) {
  var classes = useStyles()

  const [tableContent, setTableContent] = useState(null)
  const [presets, setPresets] = useState("")
  const [width, setWidth] = useState("65vw")
  const [isLoading, setIsLoading] = useState(false)
  const [helperText, setHelperText] = useState(" ")
  const [showHeader, setShowHeader] = useState(false)
  const [submission, setSubmission] = useState(null)

  useEffect(() => {
    setShowHeader(false)
    let preset = JSON.parse(props.block.props)
    setPresets(preset)
    if (!!preset.width) {
      setWidth(parseInt(preset.width, 10))
    } else {
      setWidth("65vw")
    }
    if (!!preset.table) {
      setTableContent(new Array(preset.table.rows.length).fill(0).map(() => ({})))
      axios
        .get(`/content/actions/assignments/get_actual_submission/`, {
          params: {
            content_block: props.block.id,
          },
        })
        .then(res => {
          setSubmission(res.data)
        })
    }
  }, [props.block.id])

  function createCSVTable(table, rows, columns) {
    var csvContent = [...table]
    for (let index = 0; index < csvContent.length; index++) {
      csvContent[index][""] = rows[index]
    }
    csvContent = [{ "": "", ...Object.fromEntries(columns.map(e => [e, e])) }, ...csvContent]
    return [csvContent, ["", ...columns]]
  }

  function sendTable() {
    setIsLoading(true)
    let [csvContent, columns] = createCSVTable(tableContent, presets.table.rows, presets.table.columns)
    if (!props.block.components || !props.block.components[0] || !props.block.components[0].task) {
      setIsLoading(false)
      return
    }
    let answers = [{ task: props.block.components[0].task, key: JSON.stringify(csvContent) }]
    let csvTableFile = new Blob([stringify(csvContent, { columns: columns })], { type: "text/csv" })
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post("/content/actions/assignments/submit/", {
        content_block: props.block.id,
        task_submissions: answers,
        course: props.block.course,
      })
      .then(res => {
        axios
          .put(`/content/assignments/${res.data.id}/attach/`, csvTableFile, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Content-Disposition": `attachment; filename=table.csv`,
            },
          })
          .then(res => {
            setHelperText("Ответы отправлены")
            setShowHeader(true)
            setIsLoading(false)
            window.scrollTo(0, 0)
            axios
              .get(`/content/actions/assignments/get_actual_submission/`, {
                params: {
                  content_block: props.block.id,
                },
              })
              .then(res => {
                setSubmission(res.data)
              })
          })
          .catch(err => {
            setHelperText("Ошибка при отправке")
            setIsLoading(false)
            window.scrollTo(0, 0)
          })
      })
      .catch(err => {
        if (err.response.status === 403) {
          setHelperText("Отказано в доступе")
        } else if (err.response.status === 406) {
          setHelperText("Превышено число попыток")
        } else if (err.response.status === 412) {
          setHelperText("Период сдачи завершён")
        } else if (err.response.status === 417) {
          setHelperText("Ваша работа уже отправлена и передана на проверку")
        } else if (err.response.status === 423) {
          setHelperText("Ваша работа уже принята")
        } else {
          setHelperText("Неизвестная ошибка")
        }
        setIsLoading(false)
        window.scrollTo(0, 0)
      })
  }

  if (!presets) return <></>

  return (
    <div
      style={{
        marginLeft: "5vw",
        marginRight: "5vw",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {props.title && (
        <Typography variant="h3" style={{ fontWeight: "bold", marginBottom: 24, width: width }}>
          {props.title}
        </Typography>
      )}
      {!!showHeader && (
        <div
          className={classes.testHeader}
          style={{ backgroundColor: "#DEF3FC", borderColor: "#81D9FF", width: "100%" }}
        >
          <div style={{ display: "flex" }}>
            <CheckIcon style={{ marginRight: 6, color: "#2C6BB5" }} />
            <Typography variant="h4">Ваши ответы отправлены!</Typography>
          </div>
        </div>
      )}
      {submission && <SubmissionFeedback submission={submission} />}
      {presets && presets.table && (
        <div style={{ width: width }}>
          <InteractiveTable
            rows={presets.table.rows}
            columns={presets.table.columns}
            content={tableContent}
            setContent={setTableContent}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress size={26} />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={sendTable}
                className={classes.testButtonSubmit}
                style={{ float: "left", marginTop: 25 }}
              >
                Отправить
              </Button>
            )}
            <FormHelperText
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Roboto",
                textAlign: "left",
                margin: "25px 0 0 0",
                lineHeight: "44px",
                paddingLeft: "20px",
              }}
            >
              {helperText}
            </FormHelperText>
          </div>
        </div>
      )}
    </div>
  )
}
