const SuccessIcon = () => {
  return (
    <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1773 28.4776L3.79432 17.194L0 20.9552L15.1773 36L47.7 3.76119L43.9057 0L15.1773 28.4776Z"
        fill="#1F8354"
      />
    </svg>
  )
}

export default SuccessIcon
