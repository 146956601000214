import usePrevious from "shared/hooks/usePrevious"
import { ModalSmallTemplate } from "shared/ui"
import { Variant } from "shared/ui/styles.const"

interface IProps {
  isOpen: boolean
  onClose: () => void
  errorDialog: { message: string; type: string }
}

const ErrorDownloadModal = ({ isOpen, onClose, errorDialog }: IProps) => {
  const { message, type } = errorDialog
  const title = `Ошибка скачивания ${type === "table" ? "таблицы" : "архива"}`
  const prevMessage = usePrevious(message)

  return (
    <ModalSmallTemplate
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      onAltClick={onClose}
      label="ОК"
      abortLabel="Отмена"
      variant={Variant.contained}
      width={70}
    >
      {message || prevMessage}
    </ModalSmallTemplate>
  )
}

export default ErrorDownloadModal
