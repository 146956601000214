import defaultTheme from "./default"
import typographies from "./Typographies"
import { createTheme } from "@mui/material/styles"

const overrides = {
  overrides: {
    MuiTableCell: {
      root: {},
    },
  },
  components: {
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: "18px",
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          fontSize: "18px",
        },
      },
    },
  },
}

export default {
  default: createTheme({ ...defaultTheme, ...typographies, ...overrides }),
}
