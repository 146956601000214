import {makeStyles} from "@mui/styles";

export default makeStyles(theme => ({

    button: {
        height: 64,
        width: 415,
        borderRadius:12,
        fontFamily: "Roboto",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: 24,
        letterSpacing: 0.3,
        textTransform: "uppercase",
        "&:hover": {
           opacity: 0.8
        },
    },

    defaultButton: {
        backgroundColor: "transparent",
        borderRadius: 8,
        border: "1px solid #FFF",
        width: 160,
        height: 40,
        color: "#FFF",
        fontFamily: "Roboto",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: 0.3,
        textTransform: "uppercase",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
         },
    },

    H1: {
        fontFamily: "Roboto",
        fontSize: 40,
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "115%",
        letterSpacing: 2,
        [theme.breakpoints.down("md")] : {
            width:"60%" /* ??? only for org page*/
        },
        [theme.breakpoints.down("sm")] : {
            width:"100%",
            fontSize:32,
        },
    },

    H2: {
        fontFamily: "Roboto",
        fontSize: 30,
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "155%",
        position: "relative",
        border: "none !important",
        paddingBottom:30, /* only for org page */
        [theme.breakpoints.down("sm")] : {
            fontSize:28,
        },
    },

    line: {
        width: 100,
        height: 2,
        left: 0,
        bottom: 0,
        background: '#01D4FE',
        position: 'absolute',
        display: 'block',
        content: "''",
    },

    H3: {
        fontFamily: "Roboto",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "125%",
        textTransform: "uppercase",
        [theme.breakpoints.down("sm")] : {
            fontSize:18,
        },
    },

    H4: {
        fontFamily: "Roboto",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "155%",
        [theme.breakpoints.down("sm")] : {
            fontSize:18,
        },
    },

    baseText: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "155%",
        [theme.breakpoints.down("sm")] : {
            fontSize:16,
        },
    },

    imageContainer: {
        "& span": {
            display: "flex !important"
        }
    },

    textField: {
        backgroundColor: "transparent",
        fontFamily: "Roboto",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "135%",
        borderRadius: 0,
        "&> fieldset": {
            border: "none",
        }
    },

    mainButton: {
        backgroundColor:"#01D4FE",
        paddingLeft: 24,
        paddingRight: 24,
        height: 40,
        borderRadius: 8,
        fontFamily: "Roboto",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        textTransform: "uppercase",
        letterSpacing: 0.3,
        "&:hover": {
            opacity: 0.8
        },
    }

}))
