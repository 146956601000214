import { useState } from "react"
import { OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { getCourse } from "entities/Course/api/course"
import { sortStages } from "entities/Course"
import { getModules } from "entities/Module/api/module"
import { ModuleType } from "entities/Module/api/module.types"
import { IStage } from "entities/Course/api/course.types"
import { getTasks } from "../api/assignment"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { assignmentsActions } from "shared/store"
import { getTeamsOnCourse } from "entities/Team/api/team"
import { ITeam } from "entities/Team/model/Team.types"
import { convertToPickedCourse, filterModules, formScoreOptions } from "../lib/formOptions"

export type PickedOption = {
  id: number | null
  label: string | number
}

export interface PickedCourse extends PickedOption {
  status: string
}

const useGetOptions = (isProgressEditor?: boolean) => {
  const [stages, setStages] = useState<PickedOption[]>([])
  const [modules, setModules] = useState<ModuleType[]>([])
  const [teams, setTeams] = useState<PickedOption[] | null>(null)
  const [scores, setScores] = useState<PickedOption[]>([])
  const [isForTeams, setIsForTeams] = useState(false)
  const [criteria] = useState<OptionType>({ "": "", Балл: "score" })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const handleLoadingOptions = async (course: OptionType, isFetchingTeams = true) => {
    const pickedCourse: PickedCourse = convertToPickedCourse(course)
    try {
      setIsLoading(true)
      const response = await Promise.all([
        getStageOptions(pickedCourse),
        getModuleOptions(pickedCourse),
        getTaskOptions(pickedCourse),
      ])
      const isForTeams = response[0]
      // if (isForTeams && isFetchingTeams) await getTeams(pickedCourse)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function getStageOptions(course: PickedCourse) {
    if (!course.id) return
    const payload = isProgressEditor ? { for_progress_reset: true } : { for_score_page: true }
    const response = await getCourse(course.id, payload)
    const sortedStages = response.stages.filter(e => !e.is_empty).toSorted(sortStages)
    const formedStages = sortedStages.reduce(
      (acc: PickedOption[], stage: IStage) => [...acc, { id: stage.id, label: stage.title }],
      [{ id: null, label: "Не выбрано" }]
    )
    setStages(formedStages)
    return response.is_for_teams
  }

  async function getModuleOptions(course: PickedCourse) {
    let payload
    if (isProgressEditor) {
      payload = { for_progress_reset: true }
    } else {
      payload = { for_score_page: true }
    }
    const response = await getModules(Number(course.id), payload)
    setModules(response)
  }

  async function getTaskOptions(course: PickedCourse) {
    if (!course.id) return
    const response = await getTasks({ course: course.id })
    dispatch(assignmentsActions.setTasks(response))
    dispatch(assignmentsActions.resetFilteredTasks(response))
    const { max } = findMax(response)
    const scoreOptions = formScoreOptions(0, max)
    setScores(scoreOptions)
  }

  const findMax = (blocks: { max_score: number; min_score: number }[]) => {
    let max = 0

    blocks.forEach(block => {
      if (block.max_score > max) max = block.max_score
    })
    return { max }
  }

  async function getTeams(course: PickedCourse) {
    if (!course.id) return
    setIsForTeams(true)
    const response = await getTeamsOnCourse({ course: course.id, is_approved: true })
    const formedTeams = response.reduce(
      (acc: PickedOption[], team: ITeam) => [...acc, { id: team.id, label: team.title }],
      [{ id: null, label: "Не выбрано" }]
    )
    setTeams(formedTeams)
  }

  return {
    stages,
    modules,
    teams,
    scores,
    criteria,
    isLoading,
    setTeams,
    isForTeams,
    setIsForTeams,
    setIsLoading,
    handleLoadingOptions,
    filterModules,
  }
}

export default useGetOptions
