import { Tab as MuiTab, Tabs as MuiTabs } from "@mui/material"
import { styled } from "@mui/material"

const radius = 25

export const Tabs = styled(MuiTabs)(() => ({
  borderRadius: radius,
  width: "max-content",
  border: "1px solid #E0E0E0",
  "& .MuiTabs-indicator": {
    display: "none",
  },
}))

export const Tab = styled(MuiTab)`
  text-transform: none;
  background-color: white;
  border: 1px solid transparent;

  &.Mui-selected {
    border: 1px solid #1565c0;
    background-color: #def3fc;

    &:first-of-type {
      border-top-left-radius: ${radius}px;
      border-bottom-left-radius: ${radius}px;
    }
    &:last-of-type {
      border-top-right-radius: ${radius}px;
      border-bottom-right-radius: ${radius}px;
    }
  }
  &.Mui-disabled {
    background-color: #f9f9f9;
    & span {
      color: #00000066;
    }
  }
`

export const Label = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  color: #313439;
`
