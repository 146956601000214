import React from 'react';
import {Grid, Typography} from "@mui/material";

export const UserReview = ({ review, actions }) => {
  return (
    <div>
      <hr/>
      <Typography style={{fontWeight: "bold", margin: 6}}>{`ID Курса: ${review.course}`}</Typography>
      <Typography style={{fontWeight: "bold", margin: 6}}>{`${review.user_data.last_name} ${review.user_data.first_name}`}</Typography>
      <Typography style={{margin: 6}}>{review.text}</Typography>
      {actions}
    </div>
  )
}

export const NonUserReview = ({ review, actions }) => {
  return (
    <Grid item key={review.id}>
        <div>
            <Typography
                style={{fontWeight: "bold", margin: 6}}>{`ID Курса: ${review.course}`}</Typography>
            <Typography
                style={{fontWeight: "bold", margin: 6}}>{`ID Отзыва: ${review.id}`}</Typography>
            <Typography style={{fontWeight: "bold", margin: 6}}>{review.full_name}</Typography>
            <Typography style={{margin: 6}}>{review.position}</Typography>
            <Typography style={{margin: 6}}>{review.text}</Typography>
            {actions}
        </div>
    </Grid>
  )
}
