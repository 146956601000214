import React from 'react'
import {
   Grid,
   Typography,
} from '@mui/material'
import useStyles from "./styles";

function timeProcess(time){
  let date = new Date(time)
  let format = new Intl.DateTimeFormat('ru-RU', { month: 'long', day: 'numeric', year: 'numeric' })
  return `${format.format(date)}`
}

export const Certificate = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={4} className={classes.certificatesBlock}>
      <div className={classes.certificatesIcon}>
        <a href={props.file}><span style={{backgroundImage:`url(${props.image})`}}></span></a>
      </div>
      <div className={classes.certificatesTitle}>
        <Typography variant='body1'>{props.extra_data.title}</Typography>
      </div>
      <div className={classes.certificatesDate} >
        <Typography variant='body1'>{timeProcess(props.creation_date)}</Typography>
      </div>
    </Grid>
  )
}
