import React from "react"
import { Divider, Drawer as MuiDrawer, Link, List, ListItem } from "@mui/material"
import * as Styled from "./Header.Styled"
import { LoginAndRegistrationContainer } from "./LoginAndRegistrationContainer"
//@ts-ignore
import { useHistory } from "react-router-dom"
import { timerActions } from "shared/store"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { routes } from "../util/Routes"
import { signOut, useTheme, useUserState } from "shared/context"
import { UserIcon } from "../../../entities/User"
import { Button } from "../../../shared/ui"

interface IProps {
  profile: {
    status: string
  } | null
  status: string
  isStaff: null
  isActive: (field: string) => string | null
  buttonText?: string
  catalogLink?: string | null
  hasOrganizations: boolean
}

export const Drawer = ({ profile, status, isStaff, isActive, hasOrganizations, buttonText = "КАТАЛОГ", catalogLink }: IProps) => {
  const theme = useTheme()
  const { user, isAuthenticated } = useUserState()
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const dispatch = useAppDispatch()
  const isRunningTimerTest = useAppSelector(state => state.timer.isRunning)
  const history = useHistory()

  const toggleDrawer = () => {
    setOpenDrawer(prevState => !prevState)
  }

  const preventLeaving = (link?: string) => {
    if (isRunningTimerTest) {
      dispatch(timerActions.setIsExitModal(true))
      link && dispatch(timerActions.setExitLink(link))
    }
    return isRunningTimerTest
  }

  const redirect = (link: string | null) => {
    const isPreventing = preventLeaving()
    if (isPreventing) return
    history.push(link)
  }

  const handleExit = () => {
    const isPreventing = preventLeaving()
    if (isPreventing) return
    signOut()
    user.setState(false)
  }

  return (
    <>
      {!openDrawer && <Styled.CustomMenuIcon onClick={toggleDrawer} />}
      <MuiDrawer onClose={toggleDrawer} anchor={"right"} open={openDrawer} sx={{ zIndex: 1000 }}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "flex-start" }}>
          <List>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Styled.CustomCloseIcon onClick={toggleDrawer} />
              {!!profile && isAuthenticated && <UserIcon user={profile} />}
            </ListItem>
          </List>
          <Divider />
          <List sx={{ width: "320px", paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) }}>
            <Styled.DrawerListItem
              onClick={() => redirect(!!catalogLink ? catalogLink : routes.catalog)}
              sx={{ background: isActive("app/catalog") }}
            >
              <div style={{ fontWeight: 400, color: "black", fontSize: "16px" }}>{buttonText}</div>
            </Styled.DrawerListItem>
          </List>
          <Divider />
          {isAuthenticated && (
            <>
              <List sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(4) }}>
                {(status === "admin" || status === "moderator" || isStaff) && (
                  <Styled.DrawerListItem onClick={() => redirect(routes.admin)} sx={{ background: isActive("admin") }}>
                    Администрирование
                  </Styled.DrawerListItem>
                )}
                <Styled.DrawerListItem
                  onClick={() => redirect(routes.profile)}
                  sx={{ background: isActive("app/profile") }}
                >
                  Профиль
                </Styled.DrawerListItem>
                <Styled.DrawerListItem
                  onClick={() => redirect(routes.statistics)}
                  sx={{ background: isActive("statistics") }}
                >
                  Управление
                </Styled.DrawerListItem>
                <Styled.DrawerListItem
                  onClick={() => redirect(routes.organizations)}
                  sx={{ display: hasOrganizations ? "" : "none", background: isActive("organizations") }}
                >
                  Мои организации
                </Styled.DrawerListItem>
                {profile?.status === "admin" && (
                  <Styled.DrawerListItem
                    onClick={() => redirect(routes.hardware)}
                    sx={{ background: isActive("iot-page") }}
                  >
                    Оборудование
                  </Styled.DrawerListItem>
                )}
              </List>
              <Divider />
              {/*@ts-ignore*/}
              <Button
                sx={{
                  marginLeft: theme.spacing(6),
                  marginRight: theme.spacing(6),
                  marginTop: theme.spacing(6),
                }}
                color={"primary"}
                variant={"outlined"}
                onClick={handleExit}
              >
                Выход
              </Button>
            </>
          )}
          <List sx={{ marginTop: theme.spacing(6) }}>
            <ListItem>
              <LoginAndRegistrationContainer />
            </ListItem>
          </List>
        </div>
      </MuiDrawer>
    </>
  )
}
