import React, { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Menu, MenuItem } from "@mui/material"
import { MemberChip } from "entities/Organization/ui/MemberChip"
import { OrganizationMember } from "entities/Organization/model/Organization.types"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useAppSelector } from "shared/hooks/useAppSelector"
// @ts-ignore
import Cookies from "js-cookie"

const options = ["moderator", "follower"]
export const SetMemberStatus = ({
  user,
  updateUser,
}: {
  user: OrganizationMember
  updateUser: (member: OrganizationMember, role: string) => void
}) => {
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selected, setSelected] = useState(user.member_role)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function onClickRole(role: string) {
    if (!!role) {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios.patch(`/organizations/${organization?.id}/promote/`, { email: user.email }).then(res => {
        updateUser(user, role)
        setSelected(role)
        setAnchorEl(null)
      })
    }
  }

  return (
    <>
      {/*@ts-ignore*/}
      <ArrowDropDownIcon onClick={handleClick} sx={{ alignSelf: "center", cursor: "pointer" }} />
      {/*@ts-ignore*/}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {options.map(e => (
          <MenuItem onClick={() => onClickRole(e)} selected={e === selected}>
            <MemberChip label={e} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
