import { getAssignments } from "entities/Assignment/api/assignment"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchAssignments = createAsyncThunk(
  "assignment/fetchAssignments",
  async (filters: { [x: string]: string | number | undefined }) => {
    const response = await getAssignments(filters)
    return { ...response, filters }
  }
)
