import React, { useEffect, useRef } from "react"
import * as Styled from "./ui/OrganizationFooter.styles"
import { Avatar, Typography } from "@mui/material"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  icon_logo?: string
  title: string
  props: {
    index: number
    backgroundColor?: string
    textColor?: string
    contacts: {
      text: string
      email?: string
      telegram?: string
      vk?: string
    }
    catalog_title: string
    catalog: {
      title: string
      url: string
    }[]
  }
}

export const OrganizationFooter = ({ handleChangeRef, icon_logo, props, title }: IProps) => {
  const ref = useRef<any>(null)

  useEffect(() => {
    handleChangeRef(ref, props.index)
  }, [ref])

  return (
    <Styled.FooterMainDiv sx={{ backgroundColor: props.backgroundColor || "#FFF" }} ref={ref}>
      <Styled.FooterItemMainDiv>
        <Styled.LogoContainer src={icon_logo} />
        {!!props.contacts && (
          <Typography sx={{ color: props.textColor || "#FFF", fontSize: "14px", fontWeight: "400" }}>
            <div dangerouslySetInnerHTML={{ __html: props.contacts.text || "" }}></div>
          </Typography>
        )}
      </Styled.FooterItemMainDiv>
      {!!props.catalog && (
        <Styled.FooterItemMainDiv>
          <Typography sx={{ color: props.textColor || "#FFF", fontSize: "18px", fontWeight: "700", marginTop: 2 }}>
            {props.catalog_title || "Каталог"}
          </Typography>
          <Styled.CoursesDiv>
            {props.catalog.map(e => (
              <Typography
                onClick={() => window.open(e.url, "_blank")}
                sx={{
                  cursor: "pointer",
                  color: props.textColor || "#FFF",
                  fontSize: "14px",
                  fontWeight: "400",
                  "&:hover": { opacity: 0.5 },
                }}
              >
                {e.title}
              </Typography>
            ))}
          </Styled.CoursesDiv>
        </Styled.FooterItemMainDiv>
      )}
    </Styled.FooterMainDiv>
  )
}
