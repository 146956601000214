export const sensorsIndexes: {
  [key: string]: number
} = {
  5: 1,
  6: 0,
  7: 2,
  4: 3,
  0: 5,
  1: 6,
  2: 7,
  3: 8,
  8: 4,
}
