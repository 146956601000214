import React, { ReactNode, useEffect, useRef, useState } from "react"
import * as Styled from "./SubskribeBlock.style"
import { Button, Typography } from "@mui/material"
import { useTheme, useUserState } from "shared/context"
import { axiosInstance as axios } from "shared/api/axiosInstance"
// @ts-ignore
import Cookies from "js-cookie"

interface IProps {
  handleChangeRef: (ref: React.RefObject<any>, index: number) => void
  id: number
  e: {
    index: number
    backgroundColor?: string
    Typography?: {
      variant?:
        | "body1"
        | "body2"
        | "button"
        | "caption"
        | "h1"
        | "h2"
        | "h3"
        | "h4"
        | "h5"
        | "h6"
        | "inherit"
        | "overline"
        | "subtitle1"
        | "subtitle2"
      color?: string
      fontSize?: string
      fontWeight?: string
      text: string | ReactNode
    }
    Button: {
      text?: string
      backgroundColor?: string
      color?: string
      borderColor?: string
      hoverBackgroundColor: string
    }
  }
}
const SubscribeBlock = ({ e, handleChangeRef, id }: IProps) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const [isFollower, setIsFollower] = useState(false)
  const { isAuthenticated } = useUserState()

  useEffect(() => {
    handleChangeRef(ref, e.index)
    axios
      .get(`/organizations/${id}/is_member/`)
      .then(res => {
        setIsFollower(res.data.status)
      })
      .catch(err => {
        setIsFollower(false)
      })
  }, [ref])

  function handleClickButton() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    if (isAuthenticated) {
      axios
        .post(`/organizations/${id}/join/`)
        .then(res => {
          setIsFollower(true)
        })
        .catch(err => {
          setIsFollower(false)
        })
    }
  }

  return (
    <Styled.MainDiv ref={ref} color={e?.backgroundColor}>
      {!!e?.Typography && (
        <Typography
          variant={e.Typography.variant}
          sx={{ color: e.Typography.color }}
          fontSize={e.Typography.fontSize}
          fontWeight={e.Typography.fontWeight}
        >
          {e.Typography?.text}
        </Typography>
      )}
      {!!e.Button && (
        <Styled.ButtonDiv>
          <Button
            variant={"outlined"}
            color={"primary"}
            disabled={isFollower}
            sx={{
              borderRadius: theme.spacing(3),
              color: e.Button?.color,
              backgroundColor: e.Button?.backgroundColor,
              borderColor: e.Button?.borderColor,
              "&:hover": { backgroundColor: e.Button?.hoverBackgroundColor || e.Button?.backgroundColor },
            }}
            onClick={handleClickButton}
          >
            {e.Button.text}
          </Button>
          {isFollower && (
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"} sx={{ color: "#FFF" }}>
              Вы уже подписаны
            </Typography>
          )}
        </Styled.ButtonDiv>
      )}
    </Styled.MainDiv>
  )
}

export default SubscribeBlock
