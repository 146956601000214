import React from "react"
import { Typography } from "@mui/material"
import useStyles from "./styles"
import moment from "moment"

const statusTranslation = { submitted: "Не проверено", accepted: "Принято", rejected: "Возврат" }

export const SubmissionFeedback = ({ submission }) => {
  var classes = useStyles()

  function formatDate() {
    let newDate = moment(submission.creation_time.split("T")[0].toString(), "YYYY/MM/DD").format("DD.MM.YYYY")
    return newDate
  }

  function toTimeZone() {
    let time = submission.creation_time.split("T")[1].split(".")[0]
    return moment.utc(time, "HH:mm:ss").add(3, "hour").format("HH:mm:ss")
  }

  function parseText(text) {
    let res = []
    text &&
      text.replace(
        /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
        (m, link, text) => {
          res.push(
            link ? (
              <a target={"_blank"} href={(link[0] === "w" ? "//" : "") + link} key={res.length}>
                Ссылка
              </a>
            ) : (
              <Typography className={classes.feedbackTextMess} style={{ display: "inline" }}>
                {text}
              </Typography>
            )
          )
        }
      )
    return res
  }

  return (
    <div className={classes.submissionFeedback}>
      <div>
        <Typography className={classes.feedbackText}>
          <b>Статус</b>
          {`: ${statusTranslation[submission.status]}`}
        </Typography>
        <Typography className={classes.feedbackText}>
          <b>Дата/Время отправки работы</b>
          {`: ${formatDate()}/${toTimeZone()} (Мск)`}
        </Typography>
        {submission.attachments.length > 0 && (
          <div>
            <Typography className={classes.feedbackText}>
              <b>Ваши файлы, отправленные на проверку:</b>
            </Typography>
            {submission.attachments.map((e, index) => (
              <div>
                <div className={classes.filename}>
                  {`${e.filename.split("/").pop()} `}
                  <a key={index} href={e.file} style={{ cursor: "pointer" }}>
                    Скачать файл
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
        <div>{submission.status !== "submitted" && parseText(submission.feedback)}</div>
      </div>
    </div>
  )
}
