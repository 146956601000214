import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import useStyles from "./styles";
import withStyles from '@mui/styles/withStyles';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }} ></div>
  } else {
    return text
  }
}

export const MatrixTypeEditor = (props) => {
  const classes = useStyles()

  const [editorOnIndex, setEditorOnIndex] = useState(null)
  const [text, setText] = useState("")
  const [question, setQuestion] = useState(props.question)

  const handleChangeChoice = (index) => {
    question.answers[index] = !question.answers[index]
    setQuestion({...question})
  }

  function addVariantX() {
    question.variants_x.push({ value: question.variants_x.length + 1, label: text })
    setQuestion({...question})
  }

  function addVariantY() {
    question.variants_y.push({ value: question.variants_y.length + 1, label: text })
    setQuestion({...question})
  }

  function changeVariant(value) {
    if (editorOnIndex < question.variants_x.length) {
      question.variants_x[editorOnIndex].label = value
    } else {
      question.variants_y[editorOnIndex - question.variants_x.length].label = value
    }
    setQuestion({...question})
  }

  function removeVariant(index) {
    if (index < question.variants_x.length) {
      question.variants_x.splice(index, 1)
    } else {
      question.variants_y.splice(index - question.variants_x.length, 1)
    }
    setQuestion({...question})
  }

  return (
    <>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
      <TextField
        id="answer-text-editor"
        variant="outlined"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder="Текст ответа"
        fullWidth
        multiline
        style={{ margin: 12, minWidth: 360 }}
      />
      <Button onClick={addVariantY} style={{ margin: 12, width: 180 }}>Добавить строку</Button>
      <Button onClick={addVariantX} style={{ margin: 12, width: 180 }}>Добавить столбец</Button>
    </div>
    <TableContainer component={Paper} style={{ flexGrow: 1, marginBottom: 48, maxWidth: 800 }}>
      <Table aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {question.variants_x.map((c, index_x) => (
              <TableCell align="center">
                {editorOnIndex === index_x ? (
                  <TextField
                    id="label-editor"
                    variant="outlined"
                    value={c.label}
                    onChange={e => changeVariant(e.target.value)}
                    onKeyDown={e => {if (e.key == "Enter") { setEditorOnIndex(null) }}}
                    fullWidth
                  />
                ) : (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <Typography onDoubleClick={() => setEditorOnIndex(index_x)}>{parseText(c.label)}</Typography>
                    <Button onClick={() => removeVariant(index_x)}>Удалить</Button>
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {question.variants_y.map((r, index_y) => (
            <StyledTableRow
              key={r.label}
            >
              <TableCell component="th" scope="row">
                {editorOnIndex === question.variants_x.length + index_y ? (
                  <TextField
                    id="label-editor"
                    variant="outlined"
                    value={r.label}
                    onChange={e => changeVariant(e.target.value)}
                    onKeyDown={e => {if (e.key == "Enter") { setEditorOnIndex(null) }}}
                    fullWidth
                  />
                ) : (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <Typography onDoubleClick={() => setEditorOnIndex(question.variants_x.length + index_y)}>{parseText(r.label)}</Typography>
                    <Button onClick={() => removeVariant(question.variants_x.length + index_y)}>Удалить</Button>
                  </div>
                )}
              </TableCell>
              {question.variants_x.map((c, index_x) => (
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    checked={question.answers[c.value + "," + r.value] || false}
                    onChange={event => handleChangeChoice(event.target.value)}
                    value={c.value + "," + r.value}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}
