import React, { useEffect, useState } from "react"
import * as Styled from "./ui/AdminCourse.styles"
import { Typography } from "@mui/material"
import { AddTagToCourse } from "features"
import { TagChip } from "entities/Tags"
import { axiosInstance, axiosInstance as axios } from "shared/api/axiosInstance"
//@ts-ignore
import Cookies from "js-cookie"

export const AdminCourse = ({
  course_id,
  course_tags,
}: {
  course_id: number
  course_tags: Array<{ id: number; value: string }>
}) => {
  const [tags, setTags] = useState<Array<{ id: number; value: string }>>([])

  useEffect(() => {
    setTags([...course_tags])
  }, [course_tags])

  function pushTag(id: number, value: string) {
    setTags(prev => [...prev, { id: id, value: value }])
  }

  function deleteCourseTag(id: number) {
    axiosInstance.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.patch(`content/courses/${course_id}/add_tags/`, { tags: [id] }).then(res => {
      let new_tags = tags.filter(e => e.id !== id)
      setTags([...new_tags])
    })
  }

  return (
    <Styled.TagsCourseMainDiv>
      <Typography variant={"h4"}>Настройка тегов курса</Typography>
      <AddTagToCourse course_id={course_id} pushTag={(id: number, value: string) => pushTag(id, value)} />
      <Styled.TagsList>
        {tags.map(e => (
          <TagChip id={e.id} value={e.value} deleteTag={(id: number) => deleteCourseTag(id)} />
        ))}
      </Styled.TagsList>
    </Styled.TagsCourseMainDiv>
  )
}
