import { styled } from "@mui/material"

export const UserWebinarsMainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: theme.spacing(31),
}))
