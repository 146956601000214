import * as Styled from "entities/Statistic/ui/TeamLeaderbord.styled"
// @ts-ignore
import { useParams } from "react-router-dom"
import { Autocomplete, Button, Loader } from "shared/ui"
import { useEffect, useState } from "react"
import { ArrowDown } from "images/icons/ArrowDown"
import { LeaderboardTable } from "entities/Statistic/ui/LeaderboardTable"
import { getTeamProgress } from "entities/Team/api/team"
import { ITeamProgress } from "entities/Statistic/model/TeamProgress.types"
import { getCourse, getCourseBySlug, getMyStatus } from "entities/Course/api/course"
import { sortItems } from "entities/Course/lib/CourseStatic"
import LeaderboardModal from "entities/Statistic/ui/LeaderboardModal"
import { ParticipantStatus } from "entities/Participant/model/statuses.types"

interface ITeamRanked {
  id: number
  score: number
  rank?: number
  title: string
}

const AllowedStatuses = [ParticipantStatus.in_progress, ParticipantStatus.teacher, ParticipantStatus.moderator]

const TeamLeaderboard = () => {
  const [teams, setTeams] = useState<ITeamRanked[]>([])
  const [options, setOptions] = useState<string[]>([])
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null)
  const [isLoadingCourse, setIsLoadingCourse] = useState(true)
  const [isLoadingTeams, setIsLoadingTeams] = useState(true)
  const [page, setPage] = useState(1)
  const [courseTitle, setCourseTitle] = useState("")
  const [accented, setAccented] = useState<null | HTMLTableRowElement>(null)
  const [isLeaderboard, setIsLeaderboard] = useState(true)
  const [isParticipant, setIsParticipant] = useState(true)
  const initialSort = { type: "score", direction: -1 }
  const params = useParams()
  const courseId = params.course as number

  useEffect(() => {
    initialLoading()
  }, [])

  const initialLoading = async () => {
    try {
      const isDisplay = await chechLeaderboardAccess()
      const isAllowed = await checkStatus()
      if (isDisplay && isAllowed) {
        await loadCourseInfo()
        await loadTeams()
      }
    } catch {
    } finally {
    }
  }

  const chechLeaderboardAccess = async () => {
    const response = await getCourseBySlug(params.name)
    const isDisplay = JSON.parse(response[0].team_props)?.is_leaderboard?.show === "true" || false
    setIsLeaderboard(isDisplay)
    return isDisplay
  }

  const checkStatus = async () => {
    const response = await getMyStatus(params.name)
    const isAllowed = AllowedStatuses.includes(response.status)
    setIsParticipant(isAllowed)
    return isAllowed
  }

  const loadCourseInfo = async () => {
    try {
      const response = await getCourse(courseId)
      setCourseTitle(`«${response.title}»`)
    } catch {
    } finally {
      setIsLoadingCourse(false)
    }
  }

  const loadTeams = async () => {
    try {
      setIsLoadingTeams(true)
      const params = {
        page: page,
        course: courseId,
      }
      const teams = await getTeamProgress(params).then(res => res.results)
      const rankedTeams = rankTeams(teams)
      const options = formOptions(teams)
      setTeams(prev => [...prev, ...rankedTeams])
      setOptions(prev => [...prev, ...options])
      setPage(prev => ++prev)
    } catch {
    } finally {
      setIsLoadingTeams(false)
    }
  }

  const rankTeams = (teams: ITeamProgress[]) => {
    const result = teams.map(team => ({ id: team.team.id, score: team.score, title: team.team.title || "" }))
    const sorted = result.sort((a, b) => sortItems(a, b, initialSort))
    const ranked = sorted.map((team, index) => ({ ...team, rank: index + 1 }))
    return ranked
  }

  const formOptions = (teams: ITeamProgress[]) => {
    return teams.map(team => team.team.title || "")
  }

  const handleSelect = (e: React.SyntheticEvent, value: string) => {
    setSelectedTeam(value)
  }

  const handleScroll = () => {
    const rows = Array.from(document.querySelectorAll("tr"))
    const team = rows.filter(row => row.lastElementChild?.textContent === selectedTeam)[0]
    setAccented(prev => {
      if (accented && prev !== team) {
        accented.style.backgroundColor = "#FFF"
      }
      return team
    })
    team.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  if (isLoadingCourse) return <Loader />

  if (!isLeaderboard || !isParticipant)
    return <LeaderboardModal isLeaderboard={isLeaderboard} isParticipant={isParticipant} slug={params.name} />

  return (
    <Styled.Wrapper>
      <Styled.Title variant="h3">Рейтинг команд Курса {courseTitle}</Styled.Title>
      <Styled.SelectWrapper>
        {/* @ts-ignore */}
        <Autocomplete
          label={"Название команды"}
          onChange={handleSelect}
          options={options}
          value={selectedTeam}
          style={{ minWidth: 328 }}
        />
        {/* @ts-ignore */}
        <Button onClick={handleScroll} variant={"outlined"} endIcon={<ArrowDown />} disabled={!selectedTeam}>
          перейти к команде
        </Button>
      </Styled.SelectWrapper>
      <LeaderboardTable
        teams={teams}
        // TODO перенести загрузку таблицы в компонент таблицы
        isLoadingTeams={isLoadingTeams}
        accented={accented}
        loadMore={loadTeams}
        initialSort={initialSort}
      />
    </Styled.Wrapper>
  )
}

export { TeamLeaderboard }
