import React, { useState } from 'react';
import {Tab as MuiTab, Tabs, tabsClasses} from '@mui/material';
import useStyle from './styles';
import {Typography} from "../../../shared/ui";

const Tab = ({e, refs, titles}) => {

    const classes = useStyle();
    const [tab, setTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        if (refs.current && refs.current[newValue] && refs.current[newValue].ref) {
            return refs.current[newValue].ref.current.scrollIntoView();
        }
    };

    return (
        <div className={classes.tabsWrapper}>
        <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="event-tabs"
            style={{backgroundColor: e.backgroundColor}}
            className={classes.mainTabs}
            TabIndicatorProps={{
                style: {height: 10, backgroundColor: e.lineColor},
            }}
            scrollButtons={"auto"}
            variant={"scrollable"}
            sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                    color: e.arrowColor,
                    opacity: "1.0",
                }
            }}
        >
            {titles.map((title, index) => (
                <MuiTab
                    key={index}
                    icon={<Typography color={tab === index ? e.activeTextColor : e.textColor}>{title}</Typography>}
                    value={index}
                    sx={{
                        '&:hover': {
                            opacity: 0.5,
                        },
                    }}
                />
            ))}
        </Tabs>
        </div>
    );
};

export default Tab;
