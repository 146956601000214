import React from "react"
import * as Styled from "./Catalog.styles"
import { InputAdornment, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { Button } from "../../../shared/ui"
import { useTheme } from "../../../shared/context"

export const SearchBox = ({
  searchString,
  setSearchString,
}: {
  searchString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
}) => {
  const theme = useTheme()
  return (
    <Styled.SearchBox>
      <TextField
        id="catalog__search"
        variant="outlined"
        margin="normal"
        placeholder="Название мероприятия"
        value={searchString}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={e => setSearchString(e.target.value)}
        fullWidth
        style={{
          borderRadius: "8px",
          maxWidth: 650,
          backgroundColor: "white",
          margin: 0,
          marginRight: "24px",
        }}
      />
    </Styled.SearchBox>
  )
}
