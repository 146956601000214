export const geInvitetResponseMessage = (status: number) => {
  switch (status) {
    case 201:
      return "Приглашение отправлено. Информацию о том, принял ли пользователь приглашение, Вы можете увидеть в Таблице Приглашений."

    case 409:
      return "Участник уже в команде."

    case 412:
      return "Выбранная роль не доступна на данном курсе."

    case 416:
      return "Нельзя отправить пришлашение на роль главного Администратора."

    case 423:
      return "Приглашение уже было отправлено."

    case 424:
      return "Пользователь с данной почтой не зарегистрирован. Пожалуйста, проверьте правильность введённых данных."

    case 425:
      return "Вы не можете пригласить данного пользователя в команду, так как он ещё не подал заявку на участие в мероприятии."

    case 426:
      return "Вы не можете пригласить данного пользователя в команду, так как его заявка на участие в мероприятии ещё не была одобрена модератором курса."

    case 428:
      return "Вы не можете пригласить данного пользователя в команду, так как он выбыл из курса или был заблокирован."
  }
}
