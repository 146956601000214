import * as Styled from "entities/Organization/ui/styles/SummaryTemplate.styles"
import QuestionsListCard from "entities/Organization/ui/QuestionsListCard"
import {useStatisticsDataList} from "../model/useStatisticsDataList"
import { dataListTooltip } from "../static/statistics"
import { useStatistics } from "../model/StatisticsContext"

const QUESTIONS_LIMIT = 4

const StatisticsDataList = () => {
  const { questionOptions, answerOptions, activeQuestion, onSelect, setActiveQuestion } = useStatisticsDataList()
  const { eventFilters } = useStatistics()

  return (
    <Styled.CardWrapper>
      <QuestionsListCard
        title="Вопросы из анкеты"
        tooltip={dataListTooltip.question}
        type={"question"}
        options={questionOptions}
        activeQuestion={activeQuestion}
        onSelect={onSelect}
        selected={eventFilters.questions}
        setActiveQuestion={setActiveQuestion}
        limit={QUESTIONS_LIMIT}
      />
      <QuestionsListCard
        title={`Варианты ответа на вопрос ${activeQuestion ? `"${activeQuestion?.title}"` : ""}`}
        tooltip={dataListTooltip.answer}
        type={"answer"}
        options={answerOptions}
        onSelect={onSelect}
        selected={eventFilters.answers}
      />
    </Styled.CardWrapper>
  )
}

export default StatisticsDataList
