export const procTime = (time: string): string => {
  const newTime = time.split("T")
  return `<b>X</b>: ${newTime[0]} <br>${newTime[1].split("Z")[0]},</br>`
}

export const saveSensorCsv = (content: string, moduleName: string, title: string, range: string) => {
  const blob = new Blob([content.replace(/,/g, ";")], { type: "text/csv;charset=utf-8;" })
  const downloadLink = document.createElement("a")
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.download = `${moduleName}_${title}_${range}.csv`
  downloadLink.click()
}

export const saveChartCsv = (
  content: string,
  e: {
    pid: number
    label: string
  },
  startDate: string,
  finishDate: string
) => {
  const blob = new Blob([content], { type: "text/csv" })
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute("download", `data_${e.pid}_${e.label}_${startDate + ":00Z"}_${finishDate + ":00Z"}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
