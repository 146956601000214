import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
export { AlertWrapper, IconWrapper } from "../../../shared/ui/Modal/Modal.style"

export const ContentWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 50,
}))

export const MainTeamStatusDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
}))

export const FlexWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
}))

export const TeamStatusDiv = styled(FlexWrapper)(() => ({
  alignItems: "flex-end",
}))

export const MessageMapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(3),
}))

export const SendRequest = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}))

export const ChooseCourse = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
  marginTop: theme.spacing(12),
  marginBottom: 20,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(6),
  },
}))

export const ChooseTeam = styled("div")(() => ({
  display: "flex",
  gap: 24,
  alignItems: "flex-end",
}))

export const TeamButtonWrapper = styled("div")(() => ({
  marginTop: 26,
  minWidth: 225,
}))

export const HelperText = styled(Typography)(() => ({
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  maxWidth: 357,
}))

export const FormWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(5),
  alignItems: "center",
}))

export const FormDescription = styled("div")(({ theme }) => ({
  paddingTop: 24,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3.5),
}))

export const SubmitButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(6),
}))

export const ButtonsWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}))

export const ChoiseCourseContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
}))
