import React, { useState } from "react"
// import {ContentState, convertToRaw, EditorState} from 'draft-js';
// import {Editor} from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useStyles from "./styles"
import { Button, TextField, Typography } from "@mui/material"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { JsonEditor } from "shared/assets"

export const ContentPannel = () => {
  var classes = useStyles()

  const [courseId, setCourseId] = useState("1")
  const [themeId, setThemeId] = useState("1")
  const [moduleId, setModuleId] = useState(null)
  const [componentId, setComponentId] = useState(null)
  const [content, setContent] = useState({})
  const [helperText, setHelperText] = useState("")
  // const [state, setState] = useState(EditorState.createEmpty());
  // const [enableEditor, setEnableEditor] = useState(false);
  const [presets, setPresets] = useState(null)
  const [tasks, setTasks] = useState([])
  const [components, setComponents] = useState([])

  // const onEditorStateChange = (editorState) => {
  //   setState(editorState);
  //   if (enableEditor) {
  //     updateContent(draftToHtml(convertToRaw(editorState.getCurrentContent())), "text")
  //   }
  // };

  function updateContent(value, field) {
    content[field] = value
    setContent({ ...content })
    setHelperText("")
  }

  function clearOnUpdate() {
    setContent({})
    setPresets(null)
    setTasks([])
    setComponents([])
  }

  function findContentBlock() {
    if (!courseId || !themeId || !moduleId || !componentId) return
    axios
      .get(`/content/modules/${courseId}/`)
      .then(res => {
        let subsectionId
        for (let section of res.data.sections) {
          if (section.index == themeId) {
            for (let subsection of section.subsections) {
              if (subsection.index == moduleId) {
                subsectionId = subsection.id
                break
              }
            }
            break
          }
        }
        axios
          .get(`/content/subsections/${subsectionId}/`)
          .then(r => {
            let content_block = null
            for (let block of r.data.blocks) {
              if (block.index == componentId) {
                content_block = block
                break
              }
            }
            if (!!content_block) {
              if (!!content_block.deadline) {
                let deadline = content_block.deadline.split("T")
                content_block.deadline_date = deadline[0]
                content_block.deadline_time_UTC = deadline[1].split(".")[0].replace("Z", "")
                delete content_block.deadline
              } else {
                content_block.deadline_date = null
                content_block.deadline_time_UTC = null
              }
              setContent(content_block)
              // if (res.data[0].text) {
              //   let blocksFromHtml = htmlToDraft(content_block.text);
              //   let { contentBlocks, entityMap } = blocksFromHtml;
              //   setState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
              // }
              if (!!content_block.props) {
                setPresets(JSON.parse(content_block.props))
              } else {
                setPresets({})
              }
              if (content_block.components.length > 0) {
                content_block.components.forEach(comp => {
                  axios.get(`/content/tasks/${comp.task}/`).then(task => {
                    setTasks(prev => {
                      return [...prev, task.data]
                    })
                  })
                })
              }
              setComponents(content_block.components.map(c => ({ ...c, props: JSON.parse(c.props) })))
              setHelperText("")
            } else {
              setHelperText("Does not exist")
            }
          })
          .catch(err => {
            setHelperText("Does not exist")
          })
      })
      .catch(err => {
        setHelperText("Does not exist")
      })
  }

  function updateComponent() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    content["props"] = JSON.stringify(presets)
    if (content.deadline_date) {
      content.deadline = `${content.deadline_date}T${content.deadline_time_UTC || "23:59:59"}Z`
    } else {
      content.deadline = null
    }
    delete content.deadline_date
    delete content.deadline_time_UTC
    delete content["components"]
    axios
      .put(`/content/content-blocks/${content.id}/`, content)
      .then(() => {
        components
          .map(c => ({ ...c, props: JSON.stringify(c.props) }))
          .forEach(component => {
            if (!component.id) {
              axios.post("/content/components/", { ...component, content_block: content.id }).catch(err => {
                setHelperText("Error in components")
                clearOnUpdate()
                window.scrollTo(0, 0)
              })
            } else if (!!component.remove) {
              axios.delete(`/content/components/${component.id}/`)
            } else {
              axios.patch(`/content/components/${component.id}/`, component).catch(err => {
                setHelperText("Error in components")
                clearOnUpdate()
                window.scrollTo(0, 0)
              })
              if (!!component.task) {
                let task
                for (let checkpoint of tasks) {
                  if (checkpoint.id === component.task) {
                    task = checkpoint
                  }
                }
                axios.patch(`/content/tasks/${component.task}/`, task).catch(err => {
                  setHelperText("Error in components")
                  clearOnUpdate()
                  window.scrollTo(0, 0)
                })
              }
            }
          })
        if (tasks.length > 0) {
          tasks.forEach(task => {
            if (!task.id) {
              axios.post("/content/tasks/", task).catch(err => {
                setHelperText("Error in tasks")
                clearOnUpdate()
                window.scrollTo(0, 0)
              })
            } else if (!!task.remove) {
              axios.delete(`/content/tasks/${task.id}/`)
            } else {
              axios.patch(`/content/tasks/${task.id}/`, task).catch(err => {
                setHelperText("Error in tasks")
                clearOnUpdate()
                window.scrollTo(0, 0)
              })
            }
          })
        }
        setHelperText("Confirmed")
        clearOnUpdate()
        window.scrollTo(0, 0)
      })
      .catch(err => {
        setHelperText("Error")
        window.scrollTo(0, 0)
      })
  }

  function deleteComponent() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .delete(`/content/content-blocks/${content.id}/`)
      .then(res => {
        setHelperText("Confirmed")
        window.scrollTo(0, 0)
      })
      .catch(err => {
        setHelperText("Error")
        window.scrollTo(0, 0)
      })
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: 1200 }}>
        <TextField
          id="courseId"
          variant="outlined"
          value={courseId}
          onChange={e => setCourseId(e.target.value)}
          helperText="ID модуля"
          fullWidth
          style={{ margin: 48 }}
        />
        <TextField
          id="themeId"
          variant="outlined"
          value={themeId}
          onChange={e => setThemeId(e.target.value)}
          helperText="Индекс раздела"
          fullWidth
          style={{ margin: 48 }}
        />
        <TextField
          id="moduleId"
          variant="outlined"
          value={moduleId}
          onChange={e => setModuleId(e.target.value)}
          helperText="Индекс подраздела"
          fullWidth
          style={{ margin: 48 }}
        />
        <TextField
          id="componentId"
          variant="outlined"
          value={componentId}
          onChange={e => setComponentId(e.target.value)}
          helperText="Индекс контентного блока"
          fullWidth
          style={{ margin: 48 }}
        />
        <div style={{ margin: 48 }}>
          <Button
            onClick={findContentBlock}
            variant="contained"
            color="primary"
            size="large"
            style={{ width: 240, height: 50, marginBottom: 6 }}
          >
            Найти контентный блок
          </Button>
        </div>
      </div>
      <div style={{ width: 1200, padding: 48 }}>
        <Typography variant="h5" style={{ textAlign: "center", color: "red" }}>
          {helperText}
        </Typography>
        {Object.entries(content).map(
          c =>
            c[0] != "id" &&
            c[0] != "props" &&
            c[0] != "components" &&
            c[0] != "structure_position" &&
            c[0] != "deadline" && (
              <TextField
                variant="outlined"
                value={c[1]}
                onChange={e => updateContent(e.target.value, c[0])}
                placeholder={c[0]}
                type="email"
                helperText={c[0]}
                fullWidth
                multiline
                style={{ margin: 12 }}
              />
            )
        )}
        {presets && (
          <>
            <Typography>Props</Typography>
            <JsonEditor data={presets} setter={setPresets} />
          </>
        )}
        <Typography>Components</Typography>
        <JsonEditor data={components} setter={setComponents} />
        <Typography>Tasks</Typography>
        <JsonEditor data={tasks} setter={setTasks} />
        {/* {enableEditor && 
          <div>
            <Editor
              editorState={state}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        } */}
        <Button
          onClick={updateComponent}
          variant="outlined"
          color="primary"
          size="large"
          style={{ width: 150, height: 50 }}
        >
          Обновить
        </Button>
        <Button
          onClick={deleteComponent}
          variant="outlined"
          color="primary"
          size="large"
          style={{ width: 150, height: 50 }}
        >
          Удалить
        </Button>
        {/* {exists && 
          <><Typography>
            Включить визуальный редактор (не рекомендуется использовать с контентом, при создании которого редактор не использовался)
          </Typography>
          <Checkbox
            checked={enableEditor}
            onChange={() => setEnableEditor(!enableEditor)}
            color="primary"
          /></>
        } */}
      </div>
    </>
  )
}
