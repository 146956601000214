import { TableCell, Typography } from "@mui/material"
import { accessTypeMapper } from "../lib/Mappers"

export const ModeratedTeam = ({ team }) => {
  return (
    <>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {team?.members?.find(e => e.category === "chief_moderator")?.participant.full_name}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {team?.members?.find(e => e.category === "chief_moderator")?.participant.email}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {team?.title}{" "}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {accessTypeMapper(team?.access_type)}{" "}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"}>
          {team?.id}{" "}
        </Typography>
      </TableCell>
    </>
  )
}
