export const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <g opacity="0.3">
      <path
        d="M22 42C24.6269 42.0034 27.2286 41.4876 29.6556 40.4823C32.0826 39.477 34.2869 38.002 36.142 36.142C38.002 34.2869 39.477 32.0826 40.4823 29.6556C41.4876 27.2286 42.0034 24.6269 42 22C42.0033 19.3731 41.4875 16.7714 40.4822 14.3444C39.4769 11.9175 38.0019 9.71312 36.142 7.85801C34.2869 5.99804 32.0826 4.52301 29.6556 3.5177C27.2286 2.51239 24.6269 1.99661 22 2.00002C19.3731 1.99667 16.7714 2.51247 14.3444 3.51778C11.9175 4.52309 9.71312 5.99809 7.85801 7.85801C5.99809 9.71312 4.52309 11.9175 3.51778 14.3444C2.51247 16.7714 1.99667 19.3731 2.00002 22C1.99661 24.6269 2.51239 27.2286 3.5177 29.6556C4.52301 32.0826 5.99804 34.2869 7.85801 36.142C9.71312 38.0019 11.9175 39.4769 14.3444 40.4822C16.7714 41.4875 19.3731 42.0033 22 42V42Z"
        stroke="#0444A3"
        stroke-width="3.5"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 34.333C22.663 34.333 23.2989 34.0696 23.7678 33.6008C24.2366 33.1319 24.5 32.496 24.5 31.833C24.5 31.17 24.2366 30.5341 23.7678 30.0652C23.2989 29.5964 22.663 29.333 22 29.333C21.337 29.333 20.7011 29.5964 20.2322 30.0652C19.7634 30.5341 19.5 31.17 19.5 31.833C19.5 32.496 19.7634 33.1319 20.2322 33.6008C20.7011 34.0696 21.337 34.333 22 34.333Z"
        fill="#0444A3"
      />
      <path d="M22 11L22 25" stroke="#0444A3" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
)
