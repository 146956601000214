import { Typography } from "@mui/material"
import { styled } from "@mui/styles"
import { useState } from "react"
import { Button } from "shared/ui"
import { ModalSmallTemplate } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

const ActionsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2.5),
  marginBlock: theme.spacing(4),
}))

const InvitationActions = ({ member, isActions }) => {
  const [reaction, setReaction] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")

  const params = {
    user_id: member.participant.user,
    course: member.course_id,
    title: member.team_title,
  }

  const handleAccept = async () => {
    if (member?.category) {
      try {
        setIsLoading(true)
        axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
        await axios.put(`content/actions/teams/${member.team_id}/invitation_reply/`, {
          ...params,
          // TODO в будущем добавятся статусы для модеров
          invitation_status: "member",
        })
        setReaction("Принято")
      } catch (err) {
        if (err.response.status === 404) {
          setIsOpen(true)
          setTitle("Участник не обнаружен")
        }
        if (err.response.status === 412) {
          setTitle("Вы не можете присоединиться к команде.")
          setMessage(`Превышено максимальное число участников в команде.`)
          setIsOpen(true)
        }
        if (err.response.status === 416) {
          setTitle("Вы не можете присоединиться к команде.")
          setMessage(
            `Вы достигли предела по участию в нескольких командах с выбранной ролью на курсе.
             Покиньте одну из ваших команд и попробуйте снова.`
          )
          setIsOpen(true)
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      try {
        axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
        await axios.put(`/content/teams/${member.team_id}/request_reply/`, params)
        setReaction("Запрос принят")
      } catch (err) {
        if (err.response.status === 404) {
          setIsOpen(true)
          setTitle("Участник не обнаружен")
        }
        if (err.response.status === 412) {
          setTitle("Это действие предназначено только для обработки приглашённых участников.")
          setIsOpen(true)
        }
        if (err.response.status === 416) {
          setTitle("Вы не можете пригласить пользователя.")
          setMessage("Пользователь достиг предела по участию в нескольких командах с выбранной ролью на курсе.")
          setIsOpen(true)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleReject = async () => {
    if (member?.category) {
      try {
        setIsLoading(true)
        axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
        await axios.patch(`content/actions/teams/${member.team_id}/invitation_reply/`, params)
        setReaction("Отклонено")
      } catch (err) {
        if (err.response.status === 404) {
          setIsOpen(true)
          setTitle("Участник не обнаружен")
        }
        if (err.response.status === 412) {
          setTitle("Это действие предназначено только для обработки приглашённых участников.")
          setIsOpen(true)
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      try {
        setIsLoading(true)
        axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
        await axios.patch(`/content/teams/${member.team_id}/request_reply/`, params)
        setReaction("Запрос отклонен")
      } catch {
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleRevert = async () => {
    try {
      setIsLoading(true)
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      const url = isActions
        ? `content/actions/teams/${member.team_id}/restore_request/`
        : `content/teams/${member.team_id}/restore_request/`
      await axios.put(url, { title: member.team_title, user_email: member.participant.email })
      setReaction("")
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setTitle("")
    setMessage("")
  }

  if (member?.reaction) {
    return (
      <ActionsWrapper sx={{ width: "132px" }}>
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ marginBlock: "28.5px", textAlign: "center" }}>
          {member?.reaction}
        </Typography>
      </ActionsWrapper>
    )
  }

  if (reaction)
    return (
      <ActionsWrapper>
        <Button variant="outlined" size={"small"} sx={{ width: "132px" }} onClick={handleRevert} disabled={isLoading}>
          Вернуть
        </Button>
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ marginBlock: "7.5px" }}>
          {reaction}
        </Typography>
      </ActionsWrapper>
    )

  const getTitle = title || "Достигнуто максимальное количество участников в команде"
  const getDescription = message || "Свяжитесь с Администратором команды или дождитесь повторного приглашения."

  return (
    <>
      <ModalSmallTemplate title={getTitle} text={getDescription} isOpen={isOpen} onClose={handleClose} />
      <ActionsWrapper>
        <Button variant="outlined" size={"small"} sx={{ width: "132px" }} onClick={handleAccept} disabled={isLoading}>
          Принять
        </Button>
        <Button variant="outlined" size={"small"} sx={{ width: "132px" }} onClick={handleReject} disabled={isLoading}>
          Отклонить
        </Button>
      </ActionsWrapper>
    </>
  )
}

export { InvitationActions }
