import * as Styled from "./TimerStartModal.styles"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { useEffect, useState } from "react"
import { Button } from "shared/ui"

interface IAnswer {
  [key: number]: string
}

interface ITest {
  content_block: number
  id: number
  index: number
  score: number
  task: number
  text: string
  type: string
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (openResultModal: boolean, forceCommit: boolean) => void
  selectedAnswers: IAnswer[]
  tests: ITest[]
  fileInput: { files?: File[] }[]
}

interface IEmptyAnswer {
  index: number
  text: string
}

const TimerSubmitModal = ({ isOpen, onClose, onSubmit, selectedAnswers, tests, fileInput }: IProps) => {
  const [emptyAnswers, setEmptyAnswers] = useState<IEmptyAnswer[]>([])

  useEffect(() => {
    if (!isOpen) return
    formEmptyAnswers(selectedAnswers)
  }, [isOpen])

  const formEmptyAnswers = (answers: IAnswer[]) => {
    const result = [] as IEmptyAnswer[]

    tests.forEach((test, index) => {
      let isFilled
      if (test.type === "attach") {
        const value = fileInput[index]?.files
        isFilled = value && Boolean(value.length)
      } else {
        const values = answers[index] ? Object.values(answers[index]) : [""]
        isFilled = Boolean(values.find(value => value !== "" && value !== false))
      }
      if (!isFilled) {
        result.push({ index: index + 1, text: tests[index].text })
      }
    })
    setEmptyAnswers(result)
  }

  const handleSubmit = () => {
    onClose()
    onSubmit(true, true)
  }

  return (
    <ModalTimer isOpen={isOpen} onClose={onClose}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>
            Вы не ответили на некоторые вопросы из теста. За эти вопросы{" "}
            <Styled.Strong>не будут начислены баллы</Styled.Strong>, что повлияет на результаты проверки. Вы
            действительно хотите отправить работу?
          </Styled.Typography>
          <AnswersList emptyAnswers={emptyAnswers} />
        </Styled.Wrapper>
        <Buttons onSubmit={handleSubmit} onClose={onClose} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ onSubmit, onClose }: { onSubmit: () => void; onClose: () => void }) => {
  return (
    <Styled.ButtonWrapper>
      {/* @ts-ignore */}
      <Button onClick={onSubmit} variant="contained" width={154}>
        Отправить
      </Button>
      {/* @ts-ignore */}
      <Button onClick={onClose} variant="outlined" width={154}>
        Выйти
      </Button>
    </Styled.ButtonWrapper>
  )
}

const AnswersList = ({ emptyAnswers }: { emptyAnswers: IEmptyAnswer[] }) => {
  return (
    <Styled.AnswersListWrapper>
      <Styled.Typography>Список вопросов:</Styled.Typography>
      <Styled.AnswersList>
        {emptyAnswers.map(answer => (
          <Styled.AnswerItem key={answer.index}>
            <Styled.Typography>
              <Styled.Strong>{answer.index}</Styled.Strong>. {answer.text}
            </Styled.Typography>
          </Styled.AnswerItem>
        ))}
      </Styled.AnswersList>
    </Styled.AnswersListWrapper>
  )
}

export { TimerSubmitModal }
