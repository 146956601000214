import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  checkButton: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#1665c1",
    float: "left",
    color: "#ffffff",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1665c1",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
    "&:hover":{
        background: "#115eb6",
        borderColor: "#115eb6",
        color: "#ffffff",
    },
    "&:disabled":{
        background: "#ebebeb",
        borderColor: "#98999d",
        color: "#98999d",
    },
  },
  checkButtonWt: {
      cursor: "pointer",
      appearance: "none",
      borderRadius: "4px",
      background: "#ffffff",
      float: "left",
      color: "#1565C0",
      width: "200px",
      height: "44px",
      fontSize: "16px",
      border: "1px solid #1564c0",
      textTransform: "uppercase",
      fontWeight: 400,
      fontFamily: "Roboto",
  },
  textFileName: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: 1, 
  },
  textMess: {
      fontWeight: 400, 
      fontSize: 14,
      fontFamily: "Roboto",
      lineHeight:1,
      padding:"15px 5px",
      boxSizing: "content-box",
      height:14,
  },
  testCheckbox: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  testCheckboxLabel: {
      lineHeight: 1.3
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  landingBackground: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(45deg, #E4595C, #FB7B5E);",
    height: "100vh"
  },
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  progressSection: {
    marginBottom: theme.spacing(1)
  },
  progressTitle: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1)
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    justifyContent: "space-between",
    width: "100%"
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  alignStandaloneElement: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center"
  },
  tableWidget: {
    overflowX: "auto"
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  legendElementText: {
    marginLeft: theme.spacing(1)
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%"
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2)
  },
  serverOverviewElementChartWrapper: {
    width: "100%"
  },
  mainChartBody: {
    overflowX: "auto"
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap"
    }
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3)
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important"
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25
  },
  mainChartLegentElement: {
    fontSize: "14px !important",
    marginLeft: theme.spacing(1)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  fixIconRight: {
    "& .MuiSelect-icon": {
      right: 4
    }
  },
  actionsIcon: {
    color: "#76767B"
  },
}));
