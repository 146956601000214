export const TrophyCircle = () => {
  return (
    <svg width="114" height="60" viewBox="0 0 114 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle id="Ellipse 165" cx="57" cy="30" r="57" fill="url(#paint0_radial_403_6419)" fill-opacity="0.5" />
      <defs>
        <radialGradient
          id="paint0_radial_403_6419"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(57 30) rotate(-90) scale(61.1912)"
        >
          <stop stop-color="#1565C0" stop-opacity="0" />
          <stop offset="1" stop-color="#1565C0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
