import * as Styled from "./OrganizationStatisticsTable.styles"
import * as Mui from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import VisibilitySensor from "react-visibility-sensor"
import { useRef } from "react"
import { ScrollButtons, TableLoader } from "shared/assets"
import { SurveyRow } from "./StatisticsSurveyRow"
import { StatisticsPersonalRow } from "./StatisticsPersonalRow"
import { OrganizationStatisticsActions } from "./OrganizationStatisticsActions"
import {
  IPersonal, IRequestsAndStatusesParticipant,
  ISurveyParticipant,
  StatisticsVariants,
} from "entities/Organization/model/StatisticsContext.types"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import {StatisticsRequestsAndStatusesRow} from "./StatisticsRequestsAndStatusesRow";

interface IProps {
  type: StatisticsVariants
  headers: { label: string; value: string }[]
  participants: ISurveyParticipant[] | IPersonal[] | IRequestsAndStatusesParticipant[]
  setSortBy: (sortData: { type: string; direction: number }) => void
  isLoading: boolean
  loadMore: () => void
  hasNextPage: boolean
  onChangeTab?: (e: string) => void
  isBadges?: boolean
}

const OrganizationStatisticsTable = (props: IProps) => {
  const {
    type,
    headers,
    participants,
    setSortBy,
    isLoading,
    loadMore,
    hasNextPage,
    onChangeTab,
    isBadges = false,
  } = props
  const { eventFilters } = useStatistics()
  const { questions } = eventFilters
  // @ts-ignore
  const tableContainerRef = useRef(null)

  const handleScrolledToBottom = (isBottomVisible: boolean) => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      loadMore()
    }
  }

  return (
    <Styled.ParticipantTableContainer>
      <ScrollButtons tableRef={tableContainerRef} />
      <Mui.TableContainer ref={tableContainerRef} sx={{ backgroundColor: "#FFF", height: "clamp(515px, 50vh, 620px)" }}>
        <Mui.Table stickyHeader sx={{ width: "100%", overflowX: "auto" }} size="small">
          <Mui.TableHead>
            <Mui.TableRow>
              <Mui.TableCell sx={{ maxWidth: 154, fontWeight: 300, fontSize: 14, lineHeight: "17.5px" }}>
                {(type !== "personal") && "Взаимодействие с пользователем"}
              </Mui.TableCell>
              {headers.map(header =>
                header.value ? (
                  <SortableCell label={header.label} value={header.value} setSortBy={setSortBy} key={header.label} />
                ) : (
                  <UnsortableCell value={header.label} key={header.label} type={type} />
                )
              )}
              {type === "survey" &&
                questions?.map((question, index) => <UnsortableCell value={`Ответы на вопрос: “${question.title}”`} key={index} />)}
            </Mui.TableRow>
          </Mui.TableHead>
          <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
            <Mui.TableBody>
              {participants &&
                participants.map((participant, index) => (
                  <Mui.TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }, height: 104 }}>
                    <Mui.TableCell sx={{ maxWidth: 144 }}>
                      {(type === "requests" || type === "statuses") && <OrganizationStatisticsActions
                        type={type}
                        participant={participant as IRequestsAndStatusesParticipant}
                        onChangeTab={onChangeTab}
                        isBadges={isBadges}
                      />}
                      {type === "survey" && <OrganizationStatisticsActions
                        type={type}
                        participant={participant as ISurveyParticipant}
                        onChangeTab={onChangeTab}
                        isBadges={isBadges}
                      />}
                    </Mui.TableCell>
                    {(type === "requests" || type === "statuses") && (
                      <StatisticsRequestsAndStatusesRow participant={participant as IRequestsAndStatusesParticipant}/>
                    )}
                    {type === "survey" && (
                      <SurveyRow participant={participant as ISurveyParticipant} questions={questions} />
                    )}
                    {type === "personal" && <StatisticsPersonalRow participant={participant as IPersonal} />}
                  </Mui.TableRow>
                ))}
              {!participants.length && !isLoading && (
                <Mui.TableRow>
                  <Mui.TableCell
                    colSpan={headers.length + questions.length}
                    align="center"
                    sx={{ border: 0, height: 104, width: "100%"}}
                  >
                    Нет результатов, соответствующих фильтру. Проверьте правильность введённых данных.
                  </Mui.TableCell>
                </Mui.TableRow>
              )}
            </Mui.TableBody>
          </VisibilitySensor>
        </Mui.Table>
        <TableLoader isLoading={isLoading}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
      </Mui.TableContainer>
    </Styled.ParticipantTableContainer>
  )
}

const SortableCell = ({
  label,
  value,
  setSortBy,
}: {
  label: string
  value: string
  setSortBy: (sortData: { type: string; direction: number }) => void
}) => (
  <Mui.TableCell align="center">
    <Styled.HeaderCell>
      <Mui.Typography variant={"caption"} fontWeight={"small"}>
        {label}
      </Mui.Typography>
      <Styled.SortIcons>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: -1 })}
        >
          <ArrowDropDownIcon fontSize="small" />
        </Mui.IconButton>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: 1 })}
        >
          <ArrowDropUpIcon fontSize="small" />
        </Mui.IconButton>
      </Styled.SortIcons>
    </Styled.HeaderCell>
  </Mui.TableCell>
)

const UnsortableCell = ({ value, isLeft, type }: any) => (
  <Mui.TableCell
    align={isLeft ? "left" : "center"}
    sx={{ paddingLeft: isLeft && 20, paddingBottom: type === "personal" ? 10 : 0 }}
  >
    <Mui.Typography variant={"caption"} fontWeight={"small"}>
      {value}
    </Mui.Typography>
  </Mui.TableCell>
)

export default OrganizationStatisticsTable
