// @ts-ignore
import Plot from "react-plotly.js"
import styled from "@emotion/styled"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { convertStatisticsToChart } from "entities/Organization/lib/statistics"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { filterTotalFromCharts, getMargin, getSize } from "entities/Organization/lib/handlePieChart"
import dayjs from "dayjs"

interface IProps {
  type: StatisticsVariants
  infoType: string
}

interface IPlot {
  values: number[]
  colors: string[]
  labels: string[]
}

const ChartPie = ({ type, infoType }: IProps) => {
  const { course } = useAppSelector(state => state.course)
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)
  const { statistics } = useStatistics()
  const { chartLabels, charts } = statistics[type]
  const displayedTitles = chartLabels?.join(", ")
  const isFewCuestions = chartLabels?.length > 1
  const ending = isFewCuestions ? "ы" : ""
  const chrartsCount = chartLabels?.length
  const margin = getMargin(chrartsCount)
  const size = getSize(chrartsCount)
  const currentCharts = charts[infoType as "general" | "activity"] || {}
  const filteredCharts = filterTotalFromCharts(currentCharts)
  const today = dayjs().format("DD.MM.YYYY")
  const pickLabel = () => {
    if (type === "survey") {
      return `Ответы на вопрос${ending} “${displayedTitles}” анкеты мероприятия “${course?.title}”`
    }
    if (type === "personal") {
      return chartLabels
    }
  }

  return (
    <ChartsSection>
      <ChartsWrappper isWrap={chartLabels?.length > 2} isScroll={chartLabels?.length > 2}>
        {chartLabels?.map(chartTitle => {
          const legendItems = filteredCharts[chartTitle]
          const plot = convertStatisticsToChart(legendItems) as IPlot
          const isFullfilled = plot.values.find(value => value !== null)
          if (!isFullfilled) return null
          return (
            <ChartElement width={size}>
              <PlotWrapper>
                <Plot
                  data={[plot]}
                  layout={{
                    showlegend: false,
                    autosize: true,
                    width: size,
                    height: size,
                    margin: { t: margin, l: margin, r: margin, b: margin },
                  }}
                  config={{
                    displaylogo: false,
                    toImageButtonOptions: {
                      filename: type === "survey"
                        ? `${today}_Сводка по данным анкеты_${currentCourse?.id || ""}_${organization?.title}`
                        : `${today}_Активность ученика_${currentCourse?.id || ""}_${organization?.title}_Общая информация`,
                      format: "png",
                      width: 1920,
                      height: 1080,
                    },
                  }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                />
              </PlotWrapper>
              {isFewCuestions && <Label>{chartTitle}</Label>}
              {!isFewCuestions && <Label>{pickLabel()}</Label>}
            </ChartElement>
          )
        })}
      </ChartsWrappper>
    </ChartsSection>
  )
}

const ChartsSection = styled.div`
  padding-left: 24px;
  padding-bottom: 24px;
  flex: 60%;
`

const ChartsWrappper = styled.div<{ isWrap: boolean; isScroll: boolean }>`
  display: flex;
  height: 100%;
  margin-inline: auto;
  gap: 8px;
  flex-direction: row;
  flex-wrap: ${props => (props.isWrap ? "wrap" : "nowrap")};
  align-items: center;
  justify-content: space-evenly;
  overflow-y: ${props => (props.isScroll ? "auto" : "visible")};
`

const ChartElement = styled.div<{ width: number }>`
  max-width: ${props => props.width}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`

const PlotWrapper = styled.div`
  overflow: visible;
`

const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  color: #313439;
  text-align: center;
  height: 86px;
`

export { ChartPie }
