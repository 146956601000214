export const courseTypesVariants = [
  { label: "События", value: "event", visibility: true },
  { label: "Встречи", value: "webinar", visibility: true },
  { label: "Кружки", value: "kruzhok", visibility: true },
]

export const tab_types_mapper: { [key: number]: string } = {
  1: "event",
  2: "webinar",
  3: "kruzhok",
}
