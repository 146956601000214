import React, { useEffect, useState } from "react"
import { TeamsSetting } from "entities/Team"
import { Chip, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import moment from "moment"

const categoriesConstraints = [
  { value: "member", label: "Ученик" },
  { value: "accepted", label: "Без роли" },
]

const scoreMethods = [
  { value: "max_personal_module", label: "Сумма по лучшим баллам участника за курс" },
  { value: "None", label: "Отсутствует" },
]

const leaderboardOption = [
  { value: "true", label: "Видно" },
  { value: "false", label: "Скрыто" },
]

export const SettingTeams = ({ course, updateCourseSettings, refreshTeamsProgress, errors }) => {
  const theme = useTheme()
  const [teamProps, setTeamProps] = useState(null)
  const isDateValid = dateString => {
    return moment(dateString, "YYYY-MM-DD", true).isValid()
  }

  const isNumberField = field => {
    return /^\d+$/.test(field) && Number(field) < 101
  }

  useEffect(() => {
    if (!!course) {
      let props = JSON.parse(course.team_props)
      setTeamProps(props)
    }
  }, [course])

  function sendMaxMembers(v) {
    teamProps["max_members"] = v
    setTeamProps({ ...teamProps })
    updateCourseSettings(teamProps)
  }

  function addCategory(v) {
    let existingArray = teamProps.members_categories?.split(", ") || []
    if (!existingArray.includes(v)) {
      existingArray.push(v)
      teamProps.members_categories = existingArray.join(", ")
      setTeamProps({ ...teamProps })
      updateCourseSettings(teamProps)
    }
  }

  // TODO я предлагаю назвать эту переменну v8, в честь движка
  async function addScoreMethod(v1, secondValue, isChecked) {
    teamProps.progress_method = v1.value
    teamProps.moderator_score = isChecked
    setTeamProps({ ...teamProps })
    await updateCourseSettings(teamProps)
    await refreshTeamsProgress()
    if (v1.value === "None") {
      handleReset("is_leaderboard")
    }
  }

  function addTeamCreationTime(v1, v2) {
    teamProps.time_interval = { start: v1, end: v2 }
    setTeamProps({ ...teamProps })
    updateCourseSettings(teamProps)
  }

  function addTeamCreationCategories(v) {
    let existingArray = teamProps.teamcreation_categories?.split(", ") || []
    if (!existingArray.includes(v.label)) {
      existingArray.push(v.label)
      teamProps.teamcreation_categories = existingArray.join(", ")
      setTeamProps({ ...teamProps })
      updateCourseSettings(teamProps)
    }
  }

  function addCategoriesConstraints(v1, v2) {
    let data = teamProps?.categories_constraints || {}
    data[v1.value] = v2
    teamProps["categories_constraints"] = data
    setTeamProps({ ...teamProps })
    updateCourseSettings(teamProps)
  }

  function handleClickChip(v, obj) {
    const categories = teamProps[obj].split(",")
    if (categories.length === 1 && obj === "members_categories") return
    const index = categories.indexOf(v)
    if (index !== -1) {
      categories.splice(index, 1)
    }
    if (categories.length === 0) {
      delete teamProps[obj]
    } else {
      teamProps[obj] = categories.join(",")
    }
    setTeamProps({ ...teamProps })
    updateCourseSettings(teamProps)
  }

  function handleReset(field) {
    if (field === "progress_method") {
      let newProps = teamProps
      newProps.progress_method = "None"
      newProps.moderator_score = false
      setTeamProps(newProps)
      updateCourseSettings(newProps)
    } else {
      const keys = Object.keys(teamProps)
      const filteredKeys = keys.filter(key => key !== field)
      let newProps = {}
      filteredKeys.forEach(key => {
        newProps[key] = teamProps[key]
      })
      setTeamProps(newProps)
      updateCourseSettings(newProps)
    }
  }

  function handleLeaderboard(e) {
    const value = teamProps?.progress_method !== "None" && e.value.toString()
    const updatedLeaderboad = {
      ...teamProps,
      is_leaderboard: { ...teamProps.is_leaderboard, enabled: value, show: "false" },
    }
    setTeamProps(updatedLeaderboad)
    updateCourseSettings(updatedLeaderboad)
  }

  return (
    <>
      <TeamsSetting
        required={true}
        label={"Способ подсчета"}
        title={"Выбор способа подсчёта командного прогресса (Обязательно для заполнения)"}
        options={scoreMethods}
        description={
          teamProps?.progress_method == "max_personal_module" ? (
            <span>
              Если несколько участников выполнили командный модуль, то в командный прогресс зачтётся результат участника
              с наибольшим количеством баллов за модуль. <br /> <br /> Модули, пройденные Администраторами команды,
              {teamProps.moderator_score ? "" : " не "} будут учтены в командном прогрессе.
            </span>
          ) : (
            "Командный прогресс на курсе считаться не будет."
          )
        }
        handleClick={addScoreMethod}
        handleReset={handleReset}
        resetField={"progress_method"}
        isCheckbox={true}
        checkboxValue={teamProps?.moderator_score}
      ></TeamsSetting>
      <TeamsSetting
        label={"Вид условия"}
        title={"Условия допуска пользователей к созданию команд"}
        description={
          !!teamProps?.teamcreation_categories
            ? "Создать команду могут пользователи, удовлетворяющие следующим условиям:"
            : "Создать команду могут все пользователи, так как не заданы условия."
        }
        options={JSON.parse(course?.template_props)
          .categories?.split(",")
          .map(e => ({ label: e, value: e }))}
        handleClick={addTeamCreationCategories}
        handleReset={handleReset}
        resetField={"teamcreation_categories"}
      >
        <Grid container sx={{ width: "100%" }} flexDirection={"row"} spacing={theme.spacing(6)}>
          {!!teamProps?.teamcreation_categories &&
            teamProps?.teamcreation_categories.split(",").map(e => (
              <Grid item xs={4}>
                <Chip
                  onClick={r => handleClickChip(e, "teamcreation_categories")}
                  sx={{ backgroundColor: "#3EBD44", padding: "6px 12px", cursor: "pointer" }}
                  label={<Typography sx={{ color: theme.palette.common.white }}>{e}</Typography>}
                />
              </Grid>
            ))}
        </Grid>
      </TeamsSetting>
      <TeamsSetting
        required={true}
        label={"Дата начала фазы командообразования"}
        title={"Ограничение по времени создания команд (обязательно для заполнения)"}
        description={
          teamProps?.time_interval?.start && teamProps?.time_interval?.end
            ? `Участники смогут создать команду в промежуток с ${teamProps?.time_interval?.start} до ${teamProps?.time_interval?.end}`
            : "Временной интервал для создания команд не задан."
        }
        secondLabel={"Дата завершения фазы командообразования"}
        handleClick={addTeamCreationTime}
        handleReset={handleReset}
        resetField={"time_interval"}
        validator={isDateValid}
        secondValidator={isDateValid}
        errorText={"Укажите дату в формате YYYY-MM-DD"}
      ></TeamsSetting>
      <TeamsSetting
        required={true}
        label={"Количество участников"}
        title={"Ограничение по количеству участников в команде (обязательно для заполнения)"}
        description={
          !!teamProps?.max_members
            ? "Количество участников в команде:"
            : "Ограничение по количеству участников в команде не задано."
        }
        handleClick={sendMaxMembers}
        handleReset={handleReset}
        resetField={"max_members"}
        validator={isNumberField}
        errorText={"Введите число"}
      >
        {!!teamProps?.max_members && (
          <Chip
            sx={{ backgroundColor: "#FCF4E3" }}
            label={<Typography>{`${teamProps?.max_members} участников (-а)`}</Typography>}
          />
        )}
      </TeamsSetting>
      <TeamsSetting
        label={"Название категории"}
        errors={errors}
        required={true}
        title={"Настройка командных категорий курса (обязательно для заполнения)"}
        description={
          !!teamProps?.members_categories
            ? "Категории команд на данном курсе:"
            : "Категории команд на данном курсе не заданы."
        }
        handleClick={addCategory}
        handleReset={handleReset}
        resetField={"members_categories"}
      >
        <Grid container sx={{ width: "100%" }} flexDirection={"row"} spacing={theme.spacing(6)}>
          {!!teamProps?.members_categories &&
            teamProps?.members_categories.split(",").map(e => (
              <Grid item>
                <Chip
                  onClick={r => handleClickChip(e, "members_categories")}
                  sx={{ backgroundColor: "#3EBD44", padding: "6px 12px" }}
                  label={<Typography sx={{ color: theme.palette.common.white }}>{e}</Typography>}
                />
              </Grid>
            ))}
        </Grid>
      </TeamsSetting>
      <TeamsSetting
        label={"Название категории"}
        secondLabel={"Количество команд"}
        options={categoriesConstraints}
        secondValidator={isNumberField}
        title={"Ограничение на участие в нескольких командах"}
        description={
          !!teamProps?.categories_constraints
            ? "Максимальное число команд, в которых может состоять участник курса:"
            : "Максимальное число команд, в которых может состоять участник курса не задано"
        }
        handleClick={addCategoriesConstraints}
        handleReset={handleReset}
        resetField={"categories_constraints"}
        errorText={"Введите число"}
      >
        {!!teamProps?.categories_constraints && (
          <Table sx={{ width: "100%", backgroundColor: "#DEF3FC4D", borderRadius: "20px" }}>
            <TableBody>
              {!!teamProps?.categories_constraints?.member && (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>
                    <Chip
                      sx={{ backgroundColor: "#3EBD44", padding: "6px 12px" }}
                      label={<Typography sx={{ color: theme.palette.common.white }}>Ученик</Typography>}
                    />
                  </TableCell>
                  <TableCell align={"right"}>
                    <Chip
                      sx={{ backgroundColor: "#FCF4E3" }}
                      label={<Typography>{`${teamProps?.categories_constraints?.member} команд (-ы)`}</Typography>}
                    />
                  </TableCell>
                </TableRow>
              )}
              {!!teamProps?.categories_constraints?.accepted && (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>
                    <Chip
                      sx={{ backgroundColor: "#ACACAC", padding: "6px 12px" }}
                      label={<Typography sx={{ color: theme.palette.common.white }}>Без роли</Typography>}
                    />
                  </TableCell>
                  <TableCell align={"right"}>
                    <Chip
                      sx={{ backgroundColor: "#FCF4E3" }}
                      label={<Typography>{`${teamProps?.categories_constraints?.accepted} команд (-ы)`}</Typography>}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TeamsSetting>
      <TeamsSetting
        title={"Видимость турнирной таблицы"}
        description={
          (teamProps?.progress_method === "None" && "Не выбран метод подсчёта командного прогресса") ||
          `Видимость турнирной таблицы: ${teamProps?.is_leaderboard?.enabled === "true" ? "видно" : "скрыто"}`
        }
        label={"Видимость"}
        options={teamProps?.progress_method !== "None" ? leaderboardOption : null}
        handleClick={handleLeaderboard}
        handleReset={handleReset}
        resetField={"is_leaderboard"}
        isDisabledTextField={teamProps?.progress_method === "None"}
      ></TeamsSetting>
    </>
  )
}
