import React, { useEffect, useRef, useState } from "react"
import { Button, CircularProgress, Typography } from "@mui/material"

import useStyles from "./styles"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { SubmissionFeedback } from "features"
import cyrillicToTranslit from "cyrillic-to-translit-js"

export const FileUploadBlock = props => {
  const classes = useStyles()
  const [success, setSuccess] = useState(null)
  const [helperText, setHelperText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [presets, setPresets] = useState(null)
  const [filename, setFilename] = useState("")
  const [width, setWidth] = useState("65vw")
  const [submission, setSubmission] = useState(null)
  const fileInput = useRef()

  const statusTranslation = { submitted: "Не проверено", accepted: "Принято", rejected: "Возврат" }

  function getFilename(file) {
    if (!!presets && !!presets.filename) {
      let filename_split = file.name.split(".")
      return `${presets.filename}.${filename_split[filename_split.length - 1]}`
    } else {
      return file.name
    }
  }

  const handleSendFile = () => {
    setIsLoading(true)
    let file = fileInput.current.files[0]
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post("/content/actions/assignments/submit/", { content_block: props.block.id })
      .then(res => {
        axios
          .put(`/content/assignments/${res.data.id}/attach/`, file, {
            headers: {
              "Content-Type": "application/octet-stream",
              "Content-Disposition": `attachment; filename=${props.block.id}_${cyrillicToTranslit()
                .transform(getFilename(file), "_")
                .toLowerCase()}`,
            },
          })
          .then(res => {
            fileInput.current.value = ""
            setSuccess(true)
            setHelperText("Отправлено")
            setFilename("")
            setIsLoading(false)
          })
          .catch(err => {
            setSuccess(false)
            setHelperText("Ошибка при отправке")
            setIsLoading(false)
          })
      })
      .catch(err => {
        setSuccess(false)
        if (err.response.status === 403) {
          setHelperText("Отказано в доступе")
        } else if (err.response.status === 406) {
          setHelperText("Превышено число попыток")
        } else if (err.response.status === 412) {
          setHelperText("Период сдачи завершён")
        } else if (err.response.status === 417) {
          setHelperText("Ваша работа уже отправлена и передана на проверку")
        } else if (err.response.status === 423) {
          setHelperText("Ваша работа уже принята")
        } else {
          setHelperText("Неизвестная ошибка")
        }
        setIsLoading(false)
      })
  }

  useEffect(() => {
    let preset = JSON.parse(props.block.props)
    setPresets(preset)
    if (!!preset.width) {
      setWidth(parseInt(preset.width, 10))
    } else {
      setWidth("65vw")
    }
    axios
      .get(`/content/actions/assignments/get_actual_submission/`, {
        params: {
          content_block: props.block.id,
        },
      })
      .then(res => {
        setSubmission(res.data)
      })
  }, [props.id])

  return (
    <div style={{ width: width, marginLeft: "5vw", marginRight: "5vw", marginBottom: "48px" }}>
      {submission && <SubmissionFeedback submission={submission} />}
      <div style={{ display: "flex", alignItems: "center", outline: "gray", gap: 50, marginTop: 48 }}>
        <Button component="label" className={classes.checkButtonWt}>
          Выбрать файл
          <input
            type="file"
            hidden
            ref={fileInput}
            onChange={e => {
              setFilename(e.target.files[0]?.name)
              setSuccess(null)
              setHelperText("")
            }}
          />
        </Button>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            className={classes.checkButton}
            onClick={handleSendFile}
            disabled={!fileInput.current ? false : fileInput.current.files.length === 0}
          >
            Отправить
          </Button>
        )}
        <Typography className={classes.textFileName}>{filename}</Typography>
      </div>
      <Typography className={classes.textMess} style={success ? { color: "#000000" } : { color: "#FF0000" }}>
        {helperText}
      </Typography>
    </div>
  )
}
