import React, { useEffect, useRef } from "react"
import chunkArray from "../Utils"
import PartnersItem from "./PartnersItem"
import { Grid, Typography } from "@mui/material"
// @ts-ignore
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SliderDiv } from "../../../shared/ui/Slider"
import * as Styled from "./styles"
import { useTheme } from "../../../shared/context"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined"

interface IProps {
  e: {
    index: number
    backgroundColor: string
    arrowColor: string
    options: { website: string; url: string }[]
    H2: {
      text: string
      color: string
    }
  }
  handleChangeRef: (ref: any, index: number) => void
}

const Partners = ({ e, handleChangeRef }: IProps) => {
  const ref = useRef<any>()
  const theme = useTheme()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref, handleChangeRef, e.index])

  const settings = {
    dots: false,
    infinite: e.options.length >= 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    nextArrow: <ArrowCircleRightOutlinedIcon />,
    prevArrow: <ArrowCircleLeftOutlinedIcon />,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.xs,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Styled.PartnersMainDiv ref={ref} style={{ backgroundColor: e.backgroundColor }}>
      {!!e.H2 && (
        <Typography variant={"h2"} sx={{ color: e.H2?.color }}>
          {e.H2?.text}
        </Typography>
      )}
      <SliderDiv
        className="slick-slider"
        sx={{
          ["& .slick-arrow"]: {
            color: e.arrowColor || "#000",
            fontSize: "128px",
            height: "48px",
            "&:hover": {
              color: e.arrowColor || "#000",
              opacity: "0.5",
            },
          },
        }}
      >
        <Slider {...settings}>
          {!!e.options && e.options.length > 0 && e.options.map((opt, index) => <PartnersItem key={index} e={opt} />)}
        </Slider>
      </SliderDiv>
    </Styled.PartnersMainDiv>
  )
}

export default Partners
