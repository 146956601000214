import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { Autocomplete, Button, TextField } from "../../../shared/ui"
import { JsonEditor } from "../../../shared/assets"
import { deleteAdminQuestion, editAdminQuestion } from "../api/api"
import { RequestMessage } from "../../../shared/assets/RequestMessage/RequestMessage"
import { TextField as MuiTextField } from "@mui/material"
const questionTypes = [
  { label: "Аудио", value: "audio" },
  { label: "Заполнить пропуски", value: "missing_word" },
  { label: "Бинарный", value: "binary" },
]
export const AdminQuestionItem = ({
  question,
  key,
  deleteQuestionItem,
}: {
  question: adminQuestions
  key: number
  deleteQuestionItem: (id: number) => void
}) => {
  const [item, setItem] = useState<adminQuestions>(question)
  const [error, setError] = useState(false)
  const [statusText, setStatusText] = useState("")
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false)

  function saveQuestion() {
    if (!!question.id) {
      editAdminQuestion(question.id, item)
        .then(res => {
          setStatusText("Успешно")
          setError(false)
        })
        .catch(err => {
          setStatusText("Ошибка")
          setError(true)
        })
    }
  }

  function deleteQuestion() {
    if (!!question.id) {
      deleteAdminQuestion(question.id).then(res => {
        //@ts-ignore
        deleteQuestionItem(question.id)
      })
    }
  }

  function handleChange(field: "question" | "content_type" | "correct_answer" | "file", value: string | null) {
    item[field] = !!value ? value : ""
    setItem({ ...item })
  }

  function handleChangeCorrectAnswer(value: any) {
    item["correct_answer"] = JSON.stringify(value)
    setItem({ ...item })
  }

  function handleChangeProps(value: { [p: string]: string }) {
    item["props"] = value
    setItem({ ...item })
  }

  function handleNumberField(field: "max_score" | "difficulty_level" | "type", value: number | null) {
    item[field] = !!value ? value : 0
    setItem({ ...item })
  }

  return (
    <MainQuestionContainer key={key}>
      <MuiTextField
        variant={"outlined"}
        multiline={true}
        style={{ width: "100%" }}
        label={"Вопрос"}
        value={item.question}
        onChange={e => handleChange("question", e.target.value)}
      />
      <Autocomplete
        style={{ width: "300px" }}
        value={questionTypes.filter(e => e.value === item.content_type)[0]}
        options={questionTypes}
        label={"Тип вопроса"}
        /*@ts-ignore*/
        onChange={(e, value) => handleChange("content_type", value["value"])}
      />
      <TextField
        variant={"outlined"}
        style={{ width: "300px" }}
        label={"Тип"}
        value={String(item.type)}
        onChange={e => handleNumberField("type", Number(e.target.value))}
      />
      <TextField
        variant={"outlined"}
        style={{ width: "300px" }}
        label={"Ссылка на файл"}
        value={item.file}
        onChange={e => handleChange("file", e.target.value)}
      />
      <TextField
        variant={"outlined"}
        style={{ width: "300px" }}
        label={"Сложность"}
        value={String(item.difficulty_level)}
        onChange={e => handleNumberField("difficulty_level", Number(e.target.value))}
      />
      <TextField
        variant={"outlined"}
        style={{ width: "300px" }}
        label={"Максимальный балл"}
        value={String(item.max_score)}
        onChange={e => handleNumberField("max_score", Number(e.target.value))}
      />
      <Button variant={"outlined"} color={"primary"} onClick={() => setShowCorrectAnswers(prev => !prev)}>
        {showCorrectAnswers ? "Скрыть правильные ответы" : "Показать правильные ответы"}
      </Button>
      {showCorrectAnswers && <JsonEditor data={JSON.parse(item.correct_answer)} setter={handleChangeCorrectAnswer} />}
      <JsonEditor data={item.props} setter={handleChangeProps} />
      <ButtonsContainer>
        <Button variant={"contained"} color={"primary"} onClick={saveQuestion}>
          Сохранить
        </Button>
        <Button variant={"contained"} color={"error"} onClick={deleteQuestion}>
          Удалить
        </Button>
        {statusText.length > 0 && <RequestMessage error={error} text={statusText} />}
      </ButtonsContainer>
    </MainQuestionContainer>
  )
}

const MainQuestionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(5),
  marginBottom: theme.spacing(10),
  width: "100%",
}))

const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
}))
