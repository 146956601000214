import { styled } from "@mui/material/styles"

export const SensorStatusDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
}))

export const StatusIndicator = styled("div")(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(4),
  borderRadius: 100,
}))

export const StatusConnectionMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  padding: 0,
  marginLeft: theme.spacing(3),
  width: "100%",
}))

export const SensorCard = styled("div")(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  border: "1px solid rgba(49, 52, 57, 0.30)",
  display: "flex",
  gap: theme.spacing(4),
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "320px",
  maxHeight: "340px",
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export const SwitchesCard = styled("div")(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  border: "1px solid rgba(49, 52, 57, 0.30)",
  display: "flex",
  gap: theme.spacing(4),
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "40%",
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export const SwitchesDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(8),
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.background.default,
}))

export const ChartMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  padding: theme.spacing(10),
}))

export const SettingsOfChartDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const GaugeDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
}))

export const GaugeLabelStyle = {
  textShadow: "none",
  fontFamily: "Roboto",
  lineHeight: 1.55,
  fontWeight: "400",
  fontSize: "35px",
  width: "100px",
}

export const TickLabelsStyle = {
  fontFamily: "Roboto",
  fontSize: "0.85rem",
  lineHeight: 1.25,
  letterSpacing: "0.001em",
  fontWeight: "400",
}

export const HeaderSensorCard = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignSelf: "flex-start",
  gap: theme.spacing(2),
}))

export const ChooseRangeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
}))

export const PlotStyle = styled("div")(({ theme }) => ({
  width: "100%",
  "& a.modebar-btn": {
    fontSize: "20px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
    },
  },
}))
