import React, { useEffect, useState } from "react"
import { HardwareConfig } from "entities/Iot"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { Button } from "shared/ui"
import * as Styled from "./ui/Iot.styles"
import { useTheme } from "shared/context"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { iotActions } from "shared/store"
import { DateFormInput } from "./ui/DateFormInput"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "entities/Iot"

interface IProps {
  config: HardwareConfig
  downloadData: (
    params: Array<{
      pid: number
      label: string
    }>
  ) => void
}

export const ChangeChartParams = ({ config, downloadData }: IProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { paramsChecked, sensorsChecked, finishDate, startDate, startTime, finishTime } = useAppSelector(
    state => state.iot.chartSettings
  )
  const [sensors, setSensors] = useState<
    Array<{
      pid: number
      descr: string
    }>
  >([])
  const [params, setParams] = useState<
    Array<{
      pid: number
      label: string
    }>
  >([])
  const methods = useForm({ resolver: yupResolver(schema) })
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  useEffect(() => {
    if (!!config) {
      setSensors(config.real_devs.map(e => ({ pid: e.pid, descr: e.descr })))
      let new_params: Array<{
        pid: number
        label: string
      }> = []
      config.real_devs.forEach(item => {
        let val: Array<{
          pid: number
          label: string
        }> = []
        if (item.type === "dev_dio") {
          val = item.outps_descr.map(e => ({ pid: item.pid, label: e }))
          val = [...val, ...item.inps_descr.map(e => ({ pid: item.pid, label: e }))]
        } else if (item.type === "dometer_boqu" || item.type === "onew_thermo") {
          val = item.outps_descr.filter(e => e !== "OneWire адрес").map(e => ({ pid: item.pid, label: e }))
        } else {
          val = item.outps_descr
            .filter(e => e !== "EC" && e !== "Температура")
            .map(e => ({
              pid: item.pid,
              label: e,
            }))
        }
        new_params.push(...val)
      })
      setParams(new_params)
    }
  }, [config])

  function handleClickButton(data: { dateStart: string; dateFinish: string; timeStart: string; timeFinish: string }) {
    let firstDate = new Date(data.dateStart + "T" + data.timeStart + "Z").toISOString()
    let secondDate = new Date(data.dateFinish + "T" + data.timeFinish + "Z").toISOString()
    let today = new Date().toISOString()
    if (firstDate > today) {
      setError("dateStart", { message: "Дата и время начала должны быть не позднее текущей даты." })
    } else if (secondDate > today) {
      setError("dateFinish", { message: "Дата и время окончания должны быть не позднее текущей даты." })
    } else if (secondDate <= firstDate) {
      setError("dateFinish", { message: "Дата и время окончания должны быть позднее даты и времени начала." })
    } else downloadData(paramsChecked)
  }

  return (
    <Styled.ChangeChartMainDiv>
      <Styled.SettingChartDiv>
        <Styled.SensorsDiv>
          {/*@ts-ignore*/}
          <Typography sx={{ color: "#313439", marginBottom: theme.spacing(6) }} variant={"subtitle3"}>
            Название датчика
          </Typography>
          {sensors.map(e => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={sensorsChecked.includes(e.pid)}
                  onChange={event => {
                    dispatch(iotActions.handleSensorChecked({ event: event, item: e }))
                  }}
                />
              }
              label={e.descr}
            />
          ))}
        </Styled.SensorsDiv>
        <Styled.SensorsDiv>
          {/*@ts-ignore*/}
          <Typography sx={{ color: "#313439", marginBottom: theme.spacing(6) }} variant={"subtitle3"}>
            Название показателя
          </Typography>
          {params
            .filter(e => sensorsChecked.includes(e.pid) && e.label !== "ModBUS адрес устройства")
            .map(e => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={paramsChecked.filter(p => p.pid === e.pid && p.label === e.label).length === 1}
                    onChange={event => {
                      dispatch(iotActions.handleParamsChecked({ event: event, item: e }))
                    }}
                  />
                }
                label={e.label}
              />
            ))}
        </Styled.SensorsDiv>
        <Styled.TimeIntervalMainDiv>
          {/*@ts-ignore*/}
          <Typography variant={"subtitle3"} sx={{ color: "#313439" }}>
            Интервал показаний (по UTC)
          </Typography>
          <FormProvider {...methods}>
            <Styled.ItemTimeDiv>
              <DateFormInput type={"date"} value={startDate} name={"dateStart"} label={"Дата начала"} required={true} />
              <DateFormInput
                type={"time"}
                value={startTime}
                name={"timeStart"}
                label={"Время начала"}
                required={true}
              />
            </Styled.ItemTimeDiv>
            <Styled.ItemTimeDiv>
              <DateFormInput
                type={"date"}
                value={finishDate}
                name={"dateFinish"}
                label={"Дата окончания"}
                required={true}
              />
              <DateFormInput
                type={"time"}
                value={finishTime}
                name={"timeFinish"}
                label={"Время окончания"}
                required={true}
              />
            </Styled.ItemTimeDiv>
          </FormProvider>
        </Styled.TimeIntervalMainDiv>
      </Styled.SettingChartDiv>
      {/*@ts-ignore*/}
      <Button
        variant={"contained"}
        color={"primary"}
        sx={{
          marginLeft: "auto",
          bottom: 0,
          [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            marginLeft: 0,
          },
        }}
        onClick={handleSubmit(handleClickButton)}
        disabled={paramsChecked.length < 1}
      >
        Подтвердить
      </Button>
    </Styled.ChangeChartMainDiv>
  )
}
