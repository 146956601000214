import { TableCell, Typography } from "@mui/material"
import { MemberStatus } from "../../Member/ui/MemberStatus"
import { TrashIcon } from "images/icons/trash"
import { styled } from "@mui/styles"
import { SelectRole } from "./SelectRole"
import { useDispatch, useSelector } from "react-redux"
import { teamActions } from "shared/store"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import getSetCategoryMessage from "../static/SetCategoryMapper"
import Cookies from "js-cookie"

const DeleteWrapper = styled("div")(() => ({
  cursor: "pointer",
  "&:hover": {
    opacity: "0.5",
  },
}))

export const MemberRow = ({ member, team, isEdit, setErrorMsg }) => {
  const course = useSelector(state => state.course.course)
  const user = useSelector(state => state.user.user)
  const categories = useSelector(state => state.team.categories)
  const dispatch = useDispatch()
  const isModerator = user.status.includes("moderator") || user.status.includes("admin")
  const isDeletable =
    user.email !== member.participant.email && !["chief_moderator", "moderator"].includes(member.category)

  const onChange = (category, member) => {
    const params = {
      user_id: member.participant.user,
      user_email: member.email,
      member_category: category.trim(),
      title: team.title,
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    if (category !== "moderator" && category !== "chief_moderator") {
      axios
        .put(`content/teams/${team.id}/set_category/`, params)
        .then(() => updateMember(category, member))
        .catch(res => {
          const message = getSetCategoryMessage(res.response.status)
          setErrorMsg(message)
        })
    } else {
      axios
        .put(`content/teams/${team.id}/promote/`, params)
        .then(() => updateMember(category, member))
        .catch(res => {
          if (res.response.status === 409) {
            const message = getSetCategoryMessage(412)
            setErrorMsg(message)
          }
          if (res.response.status === 412 || res.response.status === 423) {
            setErrorMsg("Передайте права Главного Администратора другому участнику.")
          }
        })
    }
  }

  const updateMember = (category, member) => {
    const newStatus = getNewStatus(category)

    if (category.trim() === "chief_moderator") {
      dispatch(
        teamActions.downgradeChiefModerator({
          teamId: team.id,
          memberId: member.id,
        })
      )
    }

    dispatch(
      teamActions.updateMember({
        teamId: team.id,
        memberId: member.id,
        category: category.trim(),
        status: newStatus,
      })
    )
  }

  const getNewStatus = category => {
    const result = category.trim().includes("moderator")
      ? "moderator"
      : (category !== "role_none" && "member") || "accepted"

    return result
  }

  const handleStatus = value => {
    onChange(value, member)
  }

  const onDelete = member => {
    const params = { ...formParams(member, team), course: course.id }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .put(`content/teams/${team.id}/kick/`, params)
      .then(res => {
        if (res.status === 204) {
          dispatch(teamActions.filterMember({ teamId: team.id, memberId: member.id }))
        }
      })
      .catch(res => {
        if (res.response.status === 409) {
          setErrorMsg("Вы не можете удалить себя из команды")
        }
      })
  }

  const formParams = (member, team) => ({
    user_id: member.participant.user,
    user_email: member.email,
    title: team.title,
  })

  return (
    <>
      <TableCell
        align={"center"}
        style={{
          paddingBlock: "16px",
          minWidth: "180px",
          width: "180px",
        }}
      >
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ maxWidth: "147px", textWrap: "wrap" }}>
          {member?.participant.full_name}
        </Typography>
      </TableCell>
      {isModerator && (
        <TableCell align={"center"} style={{ minWidth: "200px" }}>
          <Typography variant={"caption"} fontWeight={"medium"}>
            {member?.participant.email}
          </Typography>
        </TableCell>
      )}
      <TableCell align={isEdit ? "right" : "center"} style={{ maxWidth: 230, width: 230 }}>
        {isEdit ? (
          <SelectRole
            categories={categories}
            category={member?.category}
            onChange={handleStatus}
            isModerator={isModerator}
          />
        ) : (
          <MemberStatus category={member?.category} status={member.status} />
        )}
      </TableCell>
      {isEdit && (
        <TableCell align={"center"} style={{ minWidth: "100px" }}>
          {isDeletable && (
            <DeleteWrapper onClick={() => onDelete(member)}>
              <TrashIcon color={"#777777"} />
            </DeleteWrapper>
          )}
        </TableCell>
      )}
    </>
  )
}
