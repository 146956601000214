import React from "react"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker"
import { TextField as MuiTextField } from "@mui/material"
import { TextFieldProps } from "@mui/material/TextField"
import { dateFormat } from "features/Organization/ui/EventDateSelector"
import { Dayjs } from "dayjs"
import styled from "@emotion/styled"

interface IPickerProps {
  label: string
  date: Dayjs | null
  onChangeDate: (value: Dayjs | null) => void
  disableDate: (date: Dayjs) => boolean
  ref?: React.RefObject<HTMLDivElement>
}

export const StyledDateRangePicker = ({ label, date, onChangeDate, ref, disableDate }: IPickerProps) => {
  return (
    <div>
      <Label>{label}</Label>
      <MuiDatePicker
        format={dateFormat}
        slots={{ textField: StyledTextField }}
        slotProps={{
          popper: {
            disablePortal: true,
          },
        }}
        value={date}
        onChange={onChangeDate}
        ref={ref}
        shouldDisableDate={disableDate}
        views={["year", "month", "day"]}
      />
    </div>
  )
}

const StyledTextField = React.forwardRef((props: TextFieldProps, ref: React.Ref<HTMLDivElement>) => (
  <MuiTextField
    {...props}
    ref={ref}
    size="small"
    variant="outlined"
    InputProps={{
      ...props?.InputProps,
      style: {
        borderRadius: "8px",
        backgroundColor: "#fff",
        width: "160px",
      },
    }}
  />
))

const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
`
