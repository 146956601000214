import React, { useEffect, useRef } from "react"
import * as Styled from "./ui/OrganizationAdvantages.styles"
import { Typography } from "@mui/material"
import { useTheme } from "../../shared/context"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  props: {
    index: number
    H2: {
      text: string
      color: string
    }
    options: {
      image: string
      backgroundColor: string
      text: {
        text: string
        color: string
      }
      title: {
        text: string
        color: string
      }
    }[]
  }
}

export const OrganizationAdvantages = ({ handleChangeRef, props }: IProps) => {
  const theme = useTheme()
  const ref = useRef<any>(null)

  useEffect(() => {
    handleChangeRef(ref, props.index)
  }, [ref])

  return (
    <Styled.OrganizationAdvantagesMainDiv ref={ref}>
      {!!props.H2 && (
        <Typography variant={"h2"} sx={{ color: props.H2.color || "#000" }}>
          {props.H2.text}
        </Typography>
      )}
      <Styled.AdvantagesList>
        {!!props.options &&
          props.options.map(e => (
            <Styled.AdvantageItem
              sx={{
                backgroundColor: e.backgroundColor || "#FFF",
                borderRadius: theme.spacing(3),
                padding: !!e.backgroundColor ? theme.spacing(12) : 0,
              }}
            >
              <img src={e.image} />
              {!!e.title && (
                <Typography variant={"h3"} sx={{ color: e.title.color || "#000", marginTop: theme.spacing(6) }}>
                  {e.title.text}
                </Typography>
              )}
              {!!e.text && (
                <Typography
                  variant={"body1"}
                  fontSize={"medium"}
                  fontWeight={"medium"}
                  sx={{ color: e.text.color || "#000", marginTop: theme.spacing(4) }}
                >
                  {e.text.text}
                </Typography>
              )}
            </Styled.AdvantageItem>
          ))}
      </Styled.AdvantagesList>
    </Styled.OrganizationAdvantagesMainDiv>
  )
}
