import React, { useEffect, useRef, useState } from "react"
import Plot from "react-plotly.js"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"
import { RequestsAndStatusesChartType } from "./RequestsAndStatusesCharts"
import { RangesType, StatisticsVariants } from "../../model/StatisticsContext.types"
import dayjs from "dayjs"
import { getRequestsAndStatusesChart } from "../../api"
import { ChartsOptions } from "./ChartsOptions"
import { useClickOutside } from "../../../../shared/hooks"

export type RequestPieChartType = {
  changeChartType: (title: RequestsAndStatusesChartType) => void
  rangeType?: RangesType
  type: StatisticsVariants
};

export type GettingPieChartDataType = {
  color: string
  data: number
  title: string
}
export type RequestPieChartDataType = GettingPieChartDataType[]

const RequestsAndStatusesPieChart = ({ changeChartType, rangeType, type }: RequestPieChartType) => {
  const [isActive, setIsActive] = useState(false)
  const [rotationValue, setRotationValue] = useState(0)
  const today = dayjs().format("DD.MM.YYYY")
  const refChartsOptions = useRef<HTMLDivElement>(null)
  useClickOutside(refChartsOptions, () => setIsActive(false));
  const changeChartActivity = () => {
    setIsActive(!isActive)
  }
  const changeRotationValue = () => {
    setRotationValue((prevRotationValue) => (prevRotationValue === 0 ? 180 : 0))
  }

  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)
  const [requestsPieChartData, setRequestsPieChartData] = useState<RequestPieChartDataType>([])
  const [statusesPieChartData, setStatusesPieChartData] = useState<RequestPieChartDataType>([])

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        course: currentCourse?.id,
        date_range: rangeType,
        type: type === "requests" ? "applications_chart" : "statuses_chart",
      }
      await getRequestsAndStatusesChart(organization?.id, payload)
        .then(res => {
          if (type === "requests") {
            setRequestsPieChartData([
              { color: "#2EAD58", data: res.approved, title: "Принятые" },
              { color: "#FF564E", data: res.rejected, title: "Отклонённые" },
              { color: "#6B6D70", data: res.requested, title: "Необработанные" },
            ])
          } else {
            setStatusesPieChartData([
              { color: "#CA44E0", data: res.moderators, title: "Модераторы" },
              { color: "#25C6D0", data: res.teachers, title: "Преподаватели" },
              { color: "#3EC344", data: res.active, title: "Активные участники" },
              { color: "#7B85AB", data: res.others, title: "Другие пользователи" },
            ])
          }
        })
    }
    fetchData()
  }, [rangeType])

  let requestsValues = requestsPieChartData.map(el => el.data)
  let requestsLabels = requestsPieChartData.map(el => el.title)
  let requestsColors = requestsPieChartData.map(el => el.color)
  let statusesValues = statusesPieChartData.map(el => el.data)
  let statusesLabels = statusesPieChartData.map(el => el.title)
  let statusesColors = statusesPieChartData.map(el => el.color)

  const showChangeChartIcon = () => {
    return (
      "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"Layer_1\" data-name=\"Layer 1\" viewBox=\"0 0 24 24\">\n" +
      "<path d=\"m0,24h10v-10H0v10Zm2-8h6v6H2v-6ZM24,5.5c0-3.032-2.468-5.5-5.5-5.5s-5.5,2.468-5.5,5.5,2.468,5.5,5.5,5.5,5.5-2.468,5.5-5.5Zm-9,0c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5-3.5-1.57-3.5-3.5ZM5.362,2h-3.362V0h5c1.103,0,2,.897,2,2v5h-2v-3.665c-3.079,1.765-5,5.025-5,8.665H0C0,7.89,2.044,4.189,5.362,2Zm13.276,20h3.362v2h-5c-1.103,0-2-.897-2-2v-5h2v3.666c3.079-1.765,5-5.025,5-8.666h2c0,4.109-2.043,7.812-5.362,10Z\" fill=\"#313439\"/>\n" +
      "</svg>"
    )
  }
  const showReverseChartIcon = () => {
    return (
      "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"Layer_2\" data-name=\"Layer 2\" viewBox=\"0 0 24 24\">\n" +
      "<path d=\"m1,6V1h2v3.104C5.256,1.542,8.524,0,12,0c6.617,0,12,5.383,12,12h-2c0-5.514-4.486-10-10-10-3.154,0-6.115,1.51-7.991,4h3.991v2H3c-1.103,0-2-.897-2-2Zm20,10h-5v2h3.991c-1.877,2.49-4.837,4-7.991,4-5.514,0-10-4.486-10-10H0c0,6.617,5.383,12,12,12,3.476,0,6.744-1.542,9-4.104v3.104h2v-5c0-1.103-.897-2-2-2Z\"/>\n" +
      "</svg>"
    )
  }
  const modeBar = [
    {
      icon: {
        svg: [showChangeChartIcon()],
      },
      name: "Смена вида визуализации",
      click: changeChartActivity,
    },
    {
      icon: {
        svg: [showReverseChartIcon()],
      },
      name: "Смена формата визуализации",
      click: changeRotationValue,
    },
  ]

  // @ts-ignore
  return (
    <>
      <>
        <Plot
          data={[{
            type: "pie",
            hole: 0.4,
            values: type === "requests" ? requestsValues : statusesValues,
            labels: type === "requests" ? requestsLabels : statusesLabels,
            marker: {
              colors: type === "requests" ? requestsColors : statusesColors,
            },
            rotation: rotationValue,
          }]}
          layout={{
            showlegend: false,
            autosize: true,

          }}
          useResizeHandler
          config={{
            modeBarButtonsToRemove: ["select2d", "autoScale2d", "zoom2d", "lasso2d"],
            displaylogo: false,
            //@ts-ignore
            modeBarButtonsToAdd: modeBar,
            toImageButtonOptions: {
              filename: type === "requests"
                ? `${today}_Динамика по заявкам_${currentCourse?.id || ""}_${organization?.title}_Круговая диаграмма`
                : `${today}_Данные по статусам_${currentCourse?.id || ""}_${organization?.title}_Круговая диаграмма`,
              format: "png",
              width: 1920,
              height: 1080,
            },
          }}
        />
        {isActive && (
          <div ref={refChartsOptions}>
            <ChartsOptions chartType={"pie"} changeChartType={changeChartType} type={type} isActive={isActive} />
          </div>
        )}
      </>
      {type === "requests"
        ? <div>Данные по заявкам мероприятия "{currentCourse?.title}"</div>
        : <div>Данные по статусам участников на мероприятии "{currentCourse?.title}"</div>
      }
    </>
  )
}

export default RequestsAndStatusesPieChart