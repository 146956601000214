import React, { useState } from "react"
import { Autocomplete, Button, Modal, TextField } from "shared/ui"
import { useAppSelector } from "shared/hooks/useAppSelector"
import * as Styled from "./ui/Iot.styles"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import { axiosInstance as axios } from "shared/api/axiosInstance"
//@ts-ignore
import Cookies from "js-cookie"

const statuses = [
  { label: "Модератор", value: "moderator" },
  { label: "Наблюдатель", value: "observer" },
]

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const AddUser = ({ isOpen, onClose }: IProps) => {
  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const [user, setUser] = useState("")
  const [status, setStatus] = useState<{ value: string; label: string } | null>(null)
  const [error, setError] = useState(false)
  const [text, setText] = useState("")

  function allowedUser() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`/hardware/${currentHardware?.serial}/allow_access/`, { role: status?.value, email: user })
      .then(res => {
        setText("Пользователь добавлен")
        setError(false)
      })
      .catch(err => {
        if (err.response.status === 404) {
          setText("Пользователь не найден")
          setError(true)
        }
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Пригласить пользователя к устройству ${currentHardware?.name}`}>
      <Styled.DialogMainContainer>
        {/*@ts-ignore*/}
        <TextField
          style={{ width: "300px" }}
          variant={"outlined"}
          value={user}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser(e.target.value)}
          label={"Почта"}
        />
        <Styled.StatusDiv>
          {/*@ts-ignore*/}
          <Autocomplete
            style={{ width: "300px" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: { label: string; value: string }) =>
              setStatus(newValue)
            }
            value={status}
            options={statuses}
            label={"Статус пользователя"}
          />
          {/*@ts-ignore*/}
          <Button variant={"outlined"} color={"primary"} onClick={allowedUser}>
            Добавить пользователя
          </Button>
        </Styled.StatusDiv>
        {text.length > 0 && <RequestMessage error={error} text={text} />}
      </Styled.DialogMainContainer>
    </Modal>
  )
}
