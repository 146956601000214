import React, { useState, useContext } from "react";

var UserContext = React.createContext();

function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

function useUserData() {
  var context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAssignmentsData must be used within a AssignmentsContextProvider");
  }
  return context;
}

export { useUserData, UserContextProvider };
