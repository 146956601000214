import React, { useEffect, useState } from "react"
import { styled } from "@mui/material"
import { Autocomplete, Button } from "shared/ui"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import { AutocompleteEvent, OptionType } from "../../shared/ui/Autocomplete/Autocomplete.types"
import { copyModule, fetchModule, getModules } from "../../entities/Module/api/module"
import { ModuleType } from "../../entities/Module/api/module.types"
import { useAppSelector } from "../../shared/hooks/useAppSelector"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch"
import { trajectoryActions } from "../../shared/store"

interface IProps {
  stage_id: number
}

export const CopyModule = ({ stage_id }: IProps) => {
  const courses = useAppSelector(state => state.trajectory.courses)
  const dispatch = useAppDispatch()
  const [text, setText] = useState("")
  const [error, setError] = useState(false)
  const [modules, setModules] = useState<{ value: number; label: string }[]>([])
  const [courseValue, setCourseValue] = useState<{ value: number; label: string } | null>(null)
  const [moduleValue, setModuleValue] = useState<{ value: number; label: string } | null>(null)

  const handleCourseChange = async (e: AutocompleteEvent, newValue: OptionType | null) => {
    setCourseValue(newValue as { label: string; value: number } | null)
    setModules([])
    setModuleValue(null)
    if (!!newValue) {
      let response = await getModules(Number(newValue.value))
      setModules([...response.map(c => ({ label: c.title, value: c.id }))])
    }
  }

  const handleModuleChange = (e: AutocompleteEvent, newValue: OptionType | null) => {
    setModuleValue(newValue as { label: string; value: number } | null)
  }

  function handleClickCopyModule() {
    if (!!moduleValue) {
      copyModule(moduleValue?.value, stage_id)
        .then((res: { data: number }) => {
          setError(false)
          setText("Успешно")
          fetchModule(res.data).then(res => {
            dispatch(trajectoryActions.pushNewModule(res.data as ModuleType))
          })
        })
        .catch(err => {
          setError(true)
          if (err.response.status === 409) {
            setText("Вы не можете создать более одного модуля.")
          } else {
            setText("Ошибка")
          }
        })
    }
  }

  return (
    <CopyModuleMainDiv>
      <Autocomplete
        onChange={handleCourseChange}
        style={{ width: "300px" }}
        options={courses}
        value={courseValue}
        label={"Выберите курс"}
      />
      <Autocomplete
        onChange={handleModuleChange}
        value={moduleValue}
        disabled={!courseValue}
        style={{ width: "300px" }}
        options={modules}
        label={"Выберите модуль"}
      />
      <ActionContainer>
        <Button
          disabled={!courseValue && !moduleValue}
          variant={"outlined"}
          color={"primary"}
          onClick={handleClickCopyModule}
          sx={{ width: "250px" }}
        >
          Копировать модуль
        </Button>
        {text.length > 0 && <RequestMessage error={error} text={text} />}
      </ActionContainer>
    </CopyModuleMainDiv>
  )
}

const CopyModuleMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
  marginTop: theme.spacing(12),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  flexWrap: "wrap",
}))

const ActionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
}))
