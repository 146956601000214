import * as Styled from "entities/Assignment/ui/styles/AssignmentExport.styles"
import { useEffect, useState } from "react"
import { AwaitingDialog, TableTotalCount } from "shared/assets"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { Autocomplete, Button } from "shared/ui"
import { OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { Variant } from "shared/ui/styles.const"
import { getAssignmentsForDownload } from "entities/Assignment/api/assignment"
import { downloadArchiveError } from "entities/Assignment/static/errorMessages"
import { IFilters } from "shared/hooks/useFiltersForm"
import ErrorDownloadModal from "entities/Assignment/ui/ErrorDownloadModal"
import { downloadFile } from "shared/util/downloadFile"
import { emptyTask } from "entities/Assignment/static/export"

const AssignmentExport = () => {
  const filters: IFilters = useAppSelector(state => state.assignment.filters)
  const { data, totalCount } = useAppSelector(state => state.assignment.assignments)
  const { course, filteredTasks: tasks } = useAppSelector(state => state.assignment)
  const [selectedAssignment, setSelectedAssignment] = useState<OptionType | null>(emptyTask)
  const [isDownloading, setIsDownloading] = useState(false)
  const [errorDialog, setErrorDialog] = useState({ type: "", message: "" })
  const payload = { ...filters, download: "table", content_block: selectedAssignment?.id || filters.content_block }

  useEffect(() => {
    const task: OptionType = tasks.find((task: OptionType) => task.id === filters?.content_block) || emptyTask
    setSelectedAssignment({ id: task.id, label: task.label })

    return () => {
      setSelectedAssignment(emptyTask)
    }
  }, [filters])

  const handleDownloadTable = async () => downloadData({ type: "table" })

  const handleDownloadArchive = async () => downloadData({ type: "files" })

  async function downloadData(action: { type: "files" | "table" }) {
    setIsDownloading(true)
    try {
      const filesPayload = { ...payload, download: action.type }
      const response = await getAssignmentsForDownload(filesPayload)
      const fileName = `${action.type}_${course.id}.${action.type === "files" ? "zip" : "xlsx"}`
      downloadFile(response, fileName)
    } catch (err: any) {
      if (err.response.status === 400 || err.response.status === 409)
        setErrorDialog({ type: action.type, message: downloadArchiveError.emptyFiles })
      if (err.response.status === 411) setErrorDialog({ type: action.type, message: downloadArchiveError.emptyResult })
      if (err.response.status === 417) setErrorDialog({ type: action.type, message: downloadArchiveError.duplication })
      if (err.response.status === 500) setErrorDialog({ type: action.type, message: downloadArchiveError.server })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      <ErrorDownloadModal
        isOpen={Boolean(errorDialog.message)}
        onClose={() => setErrorDialog(prev => ({ ...prev, message: "" }))}
        errorDialog={errorDialog}
      />
      <AwaitingDialog open={isDownloading}>Пожалуйста, не покидайте страницу. Идёт загрузка файла</AwaitingDialog>
      <Styled.Title variant="h3">Таблица заданий по курсу «{course.label}»</Styled.Title>
      <Styled.LightText variant="body1">{`ID: ${course.id}`}</Styled.LightText>
      <Styled.ShadowedSection>
        <Styled.SubTitle variant="h4">Экспорт данных</Styled.SubTitle>
        <Styled.ButtonsWrapper>
          <Autocomplete
            options={tasks}
            label="Название задания"
            onChange={(e, value) => setSelectedAssignment(value)}
            onReset={e => setSelectedAssignment({ id: e.target.value, label: e.target.label })}
            width={400}
            value={String(selectedAssignment?.label)}
          />
          <Button
            onClick={handleDownloadArchive}
            variant={Variant.outlined}
            width={264}
            disabled={!selectedAssignment?.id}
            sx={{ marginLeft: 6 }}
          >
            Скачать архив файлов
          </Button>
          <Button
            onClick={handleDownloadTable}
            variant={Variant.outlined}
            width={264}
            disabled={!selectedAssignment?.id}
            sx={{ marginLeft: 4 }}
          >
            Скачать таблицу ответов
          </Button>
        </Styled.ButtonsWrapper>
        <Styled.FormHelper>
          Будут экспортированы данные по работам, соответствующим выбранным фильтрам.
        </Styled.FormHelper>
      </Styled.ShadowedSection>
      <Styled.TotalWrapper>
        <TableTotalCount
          totalCount={totalCount}
          filterCount={data.length}
          totalLabel="Всего работ, соответствующих фильтру:"
          currentLabel="Прогружено работ в таблице:"
        />
      </Styled.TotalWrapper>
    </>
  )
}

export { AssignmentExport }
