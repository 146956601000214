import styled from "@emotion/styled"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import SendGroupNotification from "features/Notifications/SendGroupNotification"
import { Button } from "shared/ui"
import { Typography } from "@mui/material"
import { useState } from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"

const ParticipantsMassNotification = ({ allUsers }: { allUsers: number[] }) => {
  const [isNotify, setIsNotify] = useState(false)
  const course = useAppSelector(state => state.course.participantsAndRequestsCourse)

  return (
    <>
      <SendGroupNotification
        users={allUsers}
        title={course?.title}
        courseId={course?.id}
        isOpen={isNotify}
        onClose={() => setIsNotify(false)}
      />
      <Wrapper>
        <SubTitle variant="h4">Отправить уведомления участникам курса</SubTitle>
        <Row sx={{ maxWidth: "293px" }}>
          <Button
            variant="outlined"
            onClick={() => setIsNotify(true)}
            startIcon={<AnnouncementIcon style={{ fontSize: "medium" }} />}
            width={293}
            disabled={!allUsers.length}
          >
            Отправить уведомление
          </Button>
          <Description>Уведомление будет отправлено всем участникам, соответствующим фильтру.</Description>
        </Row>
      </Wrapper>
    </>
  )
}

const Wrapper = styled("div")`
  padding-block: 12px;
`

const SubTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20.7px;
  letter-spacing: 0.2px;
`

const Row = styled("div")(() => ({
  display: "flex",
  gap: "24px",
  alignItems: "center",
  marginBlock: "40px 24px",
}))

const Description = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: "16px",
  fontWeight: "300",
}))

export default ParticipantsMassNotification
