import styled from "@emotion/styled"
import { Tab as MuiTab, Tabs as MuiTabs } from "@mui/material"

interface IProps {
  tab: number | null
  changeTab: (event: React.SyntheticEvent, newValue: number) => void
  tabOptions: {
    title: string
    value: number
    enable?: boolean
    hidden?: boolean
  }[]
  isLegend?: boolean
}

const SummaryTemplateTab = ({ tab, changeTab, tabOptions, isLegend = false }: IProps) => {
  return (
    <Tabs value={tab} onChange={changeTab} isLegend={isLegend}>
      {tabOptions.map(option => (
        <Tab
          label={<span>{option.title}</span>}
          disabled={!option.enable}
          isLegend={isLegend}
          isHidden={option.hidden}
        />
      ))}
    </Tabs>
  )
}

export const Tabs = styled(MuiTabs)<{ isLegend?: boolean }>`
  position: ${props => (props.isLegend ? "absolute" : "relative")};
  top: ${props => props.isLegend && "0"};
  right: ${props => props.isLegend && "24px"};
  display: flex;
  max-height: 32px;
  min-height: 32px;
  padding-left: 11px;

  & .MuiTabs-indicator {
    display: none;
  }
`

export const Tab = styled(MuiTab)<{ isLegend?: boolean; isDisabled?: boolean; isHidden?: boolean }>`
  display: ${props => props.isHidden && "none"};
  max-height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  padding: 5px 10px;
  width: fit-content;
  border: 1px solid #e0e0e0;
  color: #313439;
  background-color: #fff;
  border-top-left-radius: ${props => (props.isLegend ? "0px" : "12px")};
  border-top-right-radius: ${props => (props.isLegend ? "0px" : "12px")};
  border-bottom-left-radius: ${props => (props.isLegend ? "12px" : "0px")};
  border-bottom-right-radius: ${props => (props.isLegend ? "12px" : "0px")};
  text-transform: unset;

  &.Mui-selected {
    color: #313439;
    border-color: #1565c0;
    background-color: #def3fc;
  }
  &.Mui-disabled {
    background-color: #f9f9f9;
    border-color: transparent;
    & span {
      color: #00000066;
    }
  }
  &.MuiButtonBase-root {
    min-height: unset;
  }
`

export default SummaryTemplateTab
