import React, { FunctionComponent, SVGAttributes, useState } from "react"
import * as Styled from "./ui/Organizations.styles"
import { Typography } from "@mui/material"
import { Button } from "../../shared/ui"
import TypeEvent from "images/type_event.svg"
import TypeWebinar from "images/type_webinar.svg"

const options = [
  {
    title: "Событие",
    subtitle: "Мероприятие любой длительности, в рамках которого можно размещать контент образовательной программы.",
    image: TypeEvent,
    type: "event",
  },
  {
    title: "Встреча",
    subtitle:
      "Мероприятие с коротким периодом проведения. Используется для проведения дней открытых дверей, конференций и т.п.",
    image: TypeWebinar,
    type: "webinar",
  },
]

export const CreateCourse = () => {
  const [courseType, setCourseType] = useState("event")

  function handleOpenForm() {
    if (courseType === "webinar") window.open("https://forms.yandex.ru/cloud/6605811e068ff009296b3efc/", "_blank")
    else window.open("https://forms.yandex.ru/cloud/65e9911890fa7b0583b91705/", "_blank")
  }

  return (
    <Styled.CreateCourseMainDiv>
      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
        Передайте информацию о том, какое мероприятие необходимо создать. Для этого выберите тип мероприятия и нажмите
        на кнопку “Открыть форму”.
      </Typography>
      {/*@ts-ignore*/}
      <Typography variant={"subtitle3"} sx={{ marginTop: "40px" }}>
        Тип мероприятия
      </Typography>
      <Styled.TypeOfCourseDiv>
        {options.map(e => (
          <TypeOfCourseItem {...e} clicked={courseType === e.type} onClick={() => setCourseType(e.type)} />
        ))}
      </Styled.TypeOfCourseDiv>
      {/*@ts-ignore*/}
      <Button
        variant={"contained"}
        color={"primary"}
        sx={{ marginLeft: "auto", marginTop: "24px" }}
        onClick={handleOpenForm}
      >
        Открыть форму
      </Button>
    </Styled.CreateCourseMainDiv>
  )
}

const TypeOfCourseItem = ({
  image,
  title,
  subtitle,
  clicked,
  onClick,
}: {
  image: FunctionComponent<SVGAttributes<SVGElement>>
  title: string
  subtitle: string
  clicked: boolean
  onClick: () => void
}) => {
  return (
    <Styled.TypeOfCourseItem
      onClick={onClick}
      sx={{
        backgroundColor: clicked ? "#DEE7F2" : "#FFF",
      }}
    >
      {/*@ts-ignore*/}
      <img src={image} alt={title} />
      {/*@ts-ignore*/}
      <Typography variant={"subtitle3"} sx={{ marginTop: "20px" }}>
        {title}
      </Typography>
      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
        {subtitle}
      </Typography>
    </Styled.TypeOfCourseItem>
  )
}
