import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import useStyles from './styles'

import { axiosInstance as axios } from "shared/api/axiosInstance";
import Cookies from 'js-cookie';

const reasonVariants = [
  "Опечатка в ФИО",
  "ФИО не в том порядке",
  "Нужно дополнить данные",
  "Сменились паспортные данные",
]

const EditName = ({ profile }) => {
  
  const classes = useStyles()

  const [name, setName] = useState({
    first_name: profile.first_name,
    last_name: profile.last_name,
    middle_name: profile.middle_name,
    about: "",
  })
  const [helperText, setHelperText] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeName = (e) => {
    setName({ ...name, [e.target.name]: e.target.value })
  }

  function changeName() {
    if (name.about === "") {
      setHelperText("Пожалуйста, укажите причину")
      return
    }
    setIsLoading(true)
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    axios.put(`/profiles/request_edit_profile/`, name).then(() => {
      setHelperText("Запрос направлен администраторам")
      setIsLoading(false)
    }).catch(err => {
      setHelperText("Ошибка")
      setIsLoading(false)
    })
  }

  return (
    <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", width: "100%", gap: 10, padding: 36 }}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={name.last_name || ''}
        name="last_name"
        helperText="Фамилия"
        onChange={handleChangeName}
      />
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={name.first_name || ''}
        name="first_name"
        helperText="Имя"
        onChange={handleChangeName}
      />
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={name.middle_name || ''}
        name="middle_name"
        helperText="Отчество"
        onChange={handleChangeName}
      />
      <FormControl>
        <Select
          id="participantStatus"
          value={name.about}
          name="about"
          onChange={handleChangeName}
        >
          {reasonVariants.map(e => (
            <MenuItem value={e}>{`${e}`}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Причина</FormHelperText>
      </FormControl>
      <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            onClick={changeName}
            variant="contained"
            color="primary"
            size="large"
            style={{ width: 240, height: 50, marginBottom: 6 }}
          >
            Сохранить
          </Button>
        )}
        <Typography
          variant={'body1'}
          weight={'medium'}
        >
          {helperText}
        </Typography>
      </div>
    </div>
  );
}

export default EditName;
