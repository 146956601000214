import React from "react";
import { Typography } from "@mui/material";

import { Card } from "shared/ui";

export const UserData = ({ user }) => {
  return (
    <Card>
      {Object.entries(user).map(c => (
        c[0] != "progress" &&
        <Typography style={{width:"300px", wordBreak: "break-word"}}>
          {`${c[0]}: ${c[1]}`}
        </Typography>
      ))}
    </Card>
  )
}
