import {KeyboardEvent} from "react"
import PersonalTemplate from "../../../../entities/Organization/ui/Statistics/PersonalTemplate"
import { Button, TextField } from "shared/ui"
import { OrganizationStatisticsSection } from "entities/Organization/ui/OrganizationStatisticsSection"
import { Size, Variant } from "shared/ui/styles.const"
import { OrganizationStatisticsTemplate } from "entities/Organization/ui/Statistics/OrganizationStatisticsTemplate"
import {usePersonalStatistics} from "../../../../features/CourseParticipants/model/usePersonalStatistics"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"

const PersonalStatistics = () => {
  const { isLoaded, isLoadingChart, isLoadingTable, isEmpty, onSubmit, email, setEmail, submitTrigger } =
    usePersonalStatistics()
  const emailProp = useAppSelector(state => state.organization.email)
  const handleKeyDown = (event : KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && email && (!emailProp || emailProp !== email)) {
      onSubmit()
    }
  }

  return (
    <>
      <OrganizationStatisticsSection
        title={"Активность ученика на мероприятиях платформы"}
        isBackButtonProp={false}
        selector={
          <>
            <TextField
              label={"Почта пользователя"}
              variant={Variant.outlined}
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              width={540}
            />
            <Button
              variant={Variant.contained}
              size={Size.small}
              sx={{ marginLeft: 4 }}
              onClick={onSubmit}
              disabled={!email || !!emailProp && emailProp === email}
            >
              Подтвердить
            </Button>
          </>
        }
      >
        <PersonalTemplate isLoaded={isLoaded} isLoading={isLoadingChart} isEmpty={isEmpty} />
      </OrganizationStatisticsSection>
      <OrganizationStatisticsTemplate isLoadingProp={isLoadingTable} submitTrigger={submitTrigger} type={"personal"} isShowEmptyTable={isEmpty}/>
    </>
  )
}

export { PersonalStatistics }
