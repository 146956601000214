export const sensorTypesMapper = {
  Температура: "temperature",
  gpio_adc: "gpio_adc",
  ОВП: "orp",
  EC: "es",
  Соленость: "salt",
  Проводимость: "conductivity",
  TDS: "tds",
  Ph: "ph",
  "Растворенный кислород, мг/мл": "dissolvedOxygen",
  "Концентрация растворенного кислорода": "concentrationOxygen",
}
