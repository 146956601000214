import { createSlice } from "@reduxjs/toolkit"

const teamSlice = createSlice({
  name: "team",
  initialState: {
    requests: [],
    invitations: {
      inbox: [],
      requests: [],
      sent: [],
    },
    currentTeam: null,
    teams: [],
    categories: { "": "" },
  },
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    filterTeams: (state, action) => {
      state.teams = state.teams.filter(prevTeam => prevTeam.id !== action.payload)
    },
    updateTeams: (state, action) => {
      state.teams = state.teams.map(prevTeam => {
        if (prevTeam.id === action.payload.id) return { ...prevTeam, ...action.payload.team }
        return prevTeam
      })
    },
    addNewTeam: (state, action) => {
      state.teams = [...state.teams, action.payload.team]
    },
    filterMember: (state, action) => {
      state.teams = state.teams.map(prevTeam => {
        if (prevTeam.id === action.payload.teamId) {
          const filteredMembers = prevTeam.members.filter(member => member.id !== action.payload.memberId)
          return { ...prevTeam, members: filteredMembers }
        } else return prevTeam
      })
    },
    updateMember: (state, action) => {
      state.teams = state.teams.map(prevTeam => {
        if (prevTeam.id === action.payload.teamId) {
          const updatedMembers = prevTeam.members.map(member => {
            if (member.id === action.payload.memberId) {
              return { ...member, category: action.payload.category, status: action.payload.status }
            } else return member
          })
          return { ...prevTeam, members: updatedMembers }
        } else return prevTeam
      })
    },
    downgradeChiefModerator: (state, action) => {
      state.teams = state.teams.map(prevTeam => {
        if (prevTeam.id === action.payload.teamId) {
          const updatedMembers = prevTeam.members.map(member => {
            if (member.category === "chief_moderator") {
              return { ...member, category: "moderator" }
            } else return member
          })
          return { ...prevTeam, members: updatedMembers }
        } else return prevTeam
      })
    },
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload
    },
    setRequests: (state, action) => {
      state.requests = action.payload
    },
    setInvitation: (state, action) => {
      state.invitations[action.payload.type].forEach((item, index) => {
        if (item.id === action.payload.data.id) {
          state.invitations[action.payload.type][index] = { ...action.payload }
        }
      })
    },
    setRequest: (state, action) => {
      state.requests.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.requests[index] = { ...action.payload }
        }
      })
    },
  },
})

export const { actions, reducer } = teamSlice
