import * as Mui from "@mui/material"
import * as Styled from "./LeaderboardTable.style"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import VisibilitySensor from "react-visibility-sensor"
import { sortItems } from "entities/Course/lib/CourseStatic"
import { TableLoader } from "shared/assets"
import { TableCell, Typography } from "@mui/material"
import { useEffect, useState } from "react"

export interface ITeam {
  id: number
  score: number
  title?: string
  rank?: number
}

export interface ISortBy {
  type: string
  direction: number
}

interface IProps {
  teams: ITeam[]
  accented: HTMLTableRowElement | null
  loadMore: () => void
  initialSort: ISortBy
  isLoadingTeams: boolean
}

const teamFields = [
  { label: "Позиция команды", value: "rank" },
  { label: "Общий балл", value: "score" },
  { label: "Название команды", value: "team_name" },
]

const LeaderboardTable = (props: IProps) => {
  const { teams, accented, isLoadingTeams, initialSort, loadMore } = props
  const [sortBy, setSortBy] = useState<ISortBy>(initialSort)
  const sortedTeam = teams.toSorted((a, b) => sortItems(a, b, sortBy))

  useEffect(() => {
    if (accented) {
      accented.style.backgroundColor = "rgba(222, 243, 252, 0.20)"
    }
  }, [accented])

  const handleScrolledToBottom = () => {
    if (sortedTeam.length && !isLoadingTeams) {
      loadMore()
    }
  }

  return (
    <Styled.Container>
      <Styled.TableContainer>
        <Mui.Table stickyHeader>
          <Mui.TableHead>
            <Mui.TableRow>
              {teamFields.map(e => (
                <SortableCell label={e.label} value={e.value} setSortBy={setSortBy} key={e.label} />
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
            <Mui.TableBody>
              {sortedTeam.map((team, index) => (
                <Mui.TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TeamRow team={team} />
                </Mui.TableRow>
              ))}
            </Mui.TableBody>
          </VisibilitySensor>
        </Mui.Table>
        <TableLoader isLoading={isLoadingTeams}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
      </Styled.TableContainer>
    </Styled.Container>
  )
}

// TODO вынести SortableCell, TransparantCell и UnsortableCell в Shared. Так же для TeamRow и CourseTable
const SortableCell = ({
  label,
  value,
  setSortBy,
}: {
  label: string
  value: string
  setSortBy: ({ type, direction }: ISortBy) => void
}) => (
  <Mui.TableCell align="center" style={{ maxWidth: 50, padding: 16 }}>
    <Styled.HeaderCell>
      <Mui.Typography variant={"caption"} fontWeight={"small"}>
        {label}
      </Mui.Typography>
      <Styled.SortIcons>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: -1 })}
        >
          <ArrowDropDownIcon fontSize="small" />
        </Mui.IconButton>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: 1 })}
        >
          <ArrowDropUpIcon fontSize="small" />
        </Mui.IconButton>
      </Styled.SortIcons>
    </Styled.HeaderCell>
  </Mui.TableCell>
)

const TeamRow = ({ team }: { team: ITeam }) => {
  return (
    <>
      <TransparantCell value={team.rank} />
      <TransparantCell value={team.score} />
      <TransparantCell value={team.title} />
    </>
  )
}

const TransparantCell = ({ value }: { value?: string | number }) => (
  <TableCell align="center" style={{ padding: "12px 16px" }}>
    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
      {value ?? "-"}
    </Typography>
  </TableCell>
)

export { LeaderboardTable }
