import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  descriptionCompanyContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(31),
    paddingRight: theme.spacing(31),
    paddingBottom: theme.spacing(16),
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    gap: 40,
    [theme.breakpoints.down("lg")]: {
      paddingTop: theme.spacing(16),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
      paddingBottom: theme.spacing(16),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 60,
      paddingTop: theme.spacing(16),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  reverseDescriptionCompanyContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(31),
    paddingRight: theme.spacing(31),
    paddingBottom: theme.spacing(16),
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    gap: "40px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: theme.spacing(16),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
      paddingBottom: theme.spacing(16),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 60,
      paddingTop: theme.spacing(16),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  descriptionTextContainer: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 32,
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
    },
  },

  imageDescriptionCompany: {
    width: "50%",
    height: "auto",
    borderRadius: "24px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))
