import React from "react"
import { styled } from "@mui/material/styles"
import DoneIcon from "@mui/icons-material/Done"
import { Typography } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

export const AdaptiveTestFeedback = ({ submission }: { submission: { score: number; text: string } }) => {
  return (
    <StyledSubmissionFeedback>
      <InfoOutlinedIcon sx={{ width: "48px", height: "48px", color: "#0444A3", marginTop: "8px" }} />
      <Typography
        variant={"body1"}
        fontSize={"medium"}
        fontWeight={"medium"}
        sx={{ marginTop: "6px", color: "#313439" }}
      >
        Ответы отправлены на проверку.
        <p> Вы завершили прохождение всех разделов тестирования.</p>
      </Typography>
    </StyledSubmissionFeedback>
  )
}

const StyledSubmissionFeedback = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  borderRadius: theme.spacing(4),
  border: "1px solid #0444A3",
  backgroundColor: "#DEF3FC",
  gap: theme.spacing(6),
  width: "100%",
  padding: theme.spacing(10),
}))
