import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { Autocomplete, Button, TextField } from "shared/ui"
import { CircularProgress, Typography } from "@mui/material"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { courseActions } from "shared/store"
import { getOrganizationParticipants } from "entities/Organization/api"
import { getCourse } from "entities/Course/api/course"
import { IEvent } from "entities/Organization/model/Organization.types"
import { ICourse } from "entities/Course/api/course.types"

const SelectCourse = () => {
  // @ts-expect-error
  const userStatus = useAppSelector(state => state.user.user.status)
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const participantsAndRequestsCourse = useAppSelector(state => state.course.participantsAndRequestsCourse)

  const [course, setCourse] = useState<ICourse | null>(participantsAndRequestsCourse)
  const [coursesList, setCoursesList] = useState<ICourse[]>([])
  const [fieldValue, setFieldValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const requestCourses = async () => {
      if (!organization?.id) return
      try {
        const response = await getOrganizationParticipants(organization?.id)
        const courses = response.map((course: IEvent) => ({ id: course.id, label: course.title }))
        const options = courses.length ? [{ id: null, label: "Не выбрано" }, ...courses] : []
        setCoursesList(options)
      } catch {
      } finally {
        setIsLoading(false)
      }
    }
    requestCourses().catch(e => console.log(e))
  }, [dispatch, organization])

  const handleChooseCourse = (event, newValue) => {
    setCourse(newValue)
    setFieldValue(newValue?.id || "")
  }

  const handleChangeFieldValue = e => {
    const inputValue = e.target.value
    let variant = coursesList.find(v => v.id == inputValue) as ICourse
    setFieldValue(inputValue)
    if (variant !== undefined) {
      setCourse(variant)
    } else {
      setCourse(null)
    }
  }

  async function onSelectCourse() {
    if (!organization?.id || !course?.id) return
    clearCourse()
    const payload = {
      restricted_stages: true,
    }
    const courseInfo = await getCourse(course?.id, payload)
    dispatch(courseActions.setParticipantsAndRequestsCourse(courseInfo))
  }

  const clearCourse = () => {
    dispatch(courseActions.setParticipantsAndRequestsCourse(null))
  }

  return (
    <Section>
      <Title variant={"h4"}>Выберите курс</Title>
      <SelectWrapper>
        <CourseWrapper>
          <Autocomplete
            id={"AutoComplete_course_id_value"}
            label={"Название курса"}
            //@ts-ignore
            options={coursesList}
            size={"large"}
            value={participantsAndRequestsCourse?.title}
            onChange={handleChooseCourse}
            //@ts-ignore
            onReset={handleChooseCourse}
          />
        </CourseWrapper>
        {(userStatus === "admin" || userStatus === "moderator") && (
          <>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
              или
            </Typography>
            <TextField
              id={"TextField_course_id_value"}
              label={"ID курса"}
              variant={"outlined"}
              value={fieldValue}
              size={"large"}
              onChange={e => handleChangeFieldValue(e)}
            />
          </>
        )}
        <ButtonWrapper>
          <Button variant={"contained"} color={"primary"} onClick={onSelectCourse} disabled={!course || isLoading}>
            Применить
          </Button>
        </ButtonWrapper>
        {isLoading && <CircularProgress />}
      </SelectWrapper>
    </Section>
  )
}

const Section = styled.section`
  margin-bottom: 40px;
`

const Title = styled(Typography)`
  letter-spacing: 0.2px;
  margin-bottom: 55px;
`

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const CourseWrapper = styled.div`
  width: 383px;
`

const ButtonWrapper = styled.div`
  margin-left: 8px;
`

export { SelectCourse }
