const ReviewAssignmentIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 11.85C11 11.0215 11.6716 10.35 12.5 10.35H24.5C25.3284 10.35 26 11.0215 26 11.85V18.1893C25.947 18.2649 25.8887 18.3366 25.8234 18.4019L18.7917 25.434C18.7917 25.434 18.6837 26.078 18.6436 26.4656C18.641 26.4906 18.6392 26.5165 18.6373 26.5426C18.6294 26.6544 18.6211 26.7708 18.5519 26.85H12.5C11.6716 26.85 11 26.1784 11 25.35V11.85Z"
        fill="white"
      />
      <path
        d="M19.3817 16.301L19.3814 16.3012L18.5784 16.8168L18.5783 16.8169C18.3214 16.982 18.1101 17.209 17.9638 17.4771C17.8174 17.7451 17.7407 18.0456 17.7407 18.351V18.351V18.3796C17.7407 18.7992 18.08 19.1384 18.4996 19.1384C18.9193 19.1384 19.2585 18.7992 19.2585 18.3796V18.3529C19.2585 18.2484 19.3122 18.151 19.3991 18.0947C19.3992 18.0946 19.3993 18.0946 19.3993 18.0945L20.2019 17.5792L20.2022 17.579C20.9412 17.1025 21.3898 16.2846 21.3898 15.4038V15.3353C21.3898 13.9072 20.2323 12.7498 18.8041 12.7498H18.1952C16.767 12.7498 15.6094 13.9072 15.6094 15.3353C15.6094 15.7549 15.9487 16.0941 16.3684 16.0941C16.788 16.0941 17.1273 15.7549 17.1273 15.3353C17.1273 14.7465 17.6063 14.2676 18.1952 14.2676H18.8041C19.393 14.2676 19.8719 14.7465 19.8719 15.3353V15.4038C19.8719 15.7675 19.6867 16.1061 19.3817 16.301Z"
        fill="#1565C0"
        stroke="#1565C0"
        stroke-width="0.3"
      />
      <path
        d="M18.498 22.1806C18.7396 22.1806 18.9714 22.0846 19.1423 21.9137C19.3131 21.7429 19.4091 21.5111 19.4091 21.2695C19.4091 21.0278 19.3131 20.7961 19.1423 20.6253C18.9714 20.4544 18.7396 20.3584 18.498 20.3584C18.2563 20.3584 18.0246 20.4544 17.8537 20.6253C17.6828 20.7961 17.5868 21.0278 17.5868 21.2695C17.5868 21.5111 17.6828 21.7429 17.8537 21.9137C18.0246 22.0846 18.2563 22.1806 18.498 22.1806Z"
        fill="#1565C0"
        stroke="#1565C0"
        stroke-width="0.3"
      />
      <path
        d="M30.9477 14.6789C31.2706 14.3559 31.7942 14.3559 32.1171 14.6789L34.0467 16.6085C34.3696 16.9315 34.3696 17.4551 34.0467 17.7781L32.5265 19.2984L29.4275 16.1993L30.9477 14.6789Z"
        fill="white"
      />
      <path
        d="M28.6057 17.0211L31.7046 20.1202L23.1284 28.6974C23.0036 28.8223 22.8421 28.9039 22.6676 28.9303L20.3934 29.275C19.8425 29.3585 19.3684 28.8844 19.4519 28.3335L19.7966 26.0591C19.8231 25.8846 19.9046 25.7231 20.0295 25.5983L28.6057 17.0211Z"
        fill="white"
      />
    </svg>
  )
}

export default ReviewAssignmentIcon
