import * as Styled from "entities/Organization/ui/styles/EventDateSelector.styles"
import DisplayedDate from "entities/Organization/ui/DisplayedDate"
import dayjs, { Dayjs } from "dayjs"
import {useEffect, useRef, useState} from "react"
import { DateRangePicker } from "entities/Organization/ui/DateRangePicker"
import { Selector } from "shared/ui/Selector/Selector"
import { IEvent } from "entities/Organization/model/Organization.types"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { RangesType } from "entities/Organization/model/StatisticsContext.types"
import { ranges } from "entities/Organization/static/statisticsInitial"

export interface DateRange {
  start?: string
  finish?: string
}

export interface InitDateRange {
  start?: Dayjs
  finish?: Dayjs
}

interface IProps {
  event: IEvent | undefined
  isLoading: boolean
  onReload?: (x: { date_range: RangesType; start?: string; finish?: string }) => void
  showRangeType?: (rangeType: RangesType) => void
  getDisplayedDate?: (displayedDate: DateRange) => void
  enableEventDateSelector: boolean
}

export const dateFormat = "DD.MM.YYYY"
export const parsedDateFormat = "YYYY-MM-DD"
const currentDate = dayjs().format(parsedDateFormat)
const millenniumDate = dayjs("01.01.2022").format(parsedDateFormat)

const EventDateSelector = ({
                             event,
                             isLoading,
                             onReload,
                             showRangeType,
                             getDisplayedDate,
                             enableEventDateSelector,
                           }: IProps) => {
  const { dateRange, setDateRange } = useStatistics()
  const [displayedDate, setDisplayedDate] = useState<DateRange>()
  const [rangeType, setRangeType] = useState<RangesType>(dateRange.type)
  const [initRange, setInitRange] = useState<InitDateRange>()
  const [isCustomRange, setIsCustomRange] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const selectorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!event) return
    handleSelectedRange()
  }, [event])

  useEffect(() => {
    rangeType !== "custom" && handleReload()
  }, [rangeType])
  useEffect(() => {
    if (!enableEventDateSelector){
      setRangeType("all")
    }
  }, [enableEventDateSelector])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setIsActive(false)
      }
    }

    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isActive])

const handleSelectedRange = (range?: RangesType) => {
  const currentRange = range || rangeType;

  if (currentRange === rangeType) {
    setIsCustomRange(false);
    setIsActive(false);
    setTimeout(() => {
      setRangeType(currentRange);
      if (currentRange === "custom") {
        setIsCustomRange(true);
        setIsActive(true);
      }
    }, 0);
  } else {
    setRangeType(currentRange);
    if (currentRange === "all") {
      if (event) {
        handleAllDateRange(event, currentRange);
      }
    } else if (currentRange === "custom") {
      setIsCustomRange(true);
      setIsActive(true);
    } else {
      rangeSetter(currentRange);
    }
  }

  if (showRangeType) {
    showRangeType(currentRange);
  }
};

  const handleAllDateRange = (event: IEvent | undefined, range: any) => {
    const { start: eventStart, finish: eventFinish } = event!
    const start = eventStart || millenniumDate
    const finish = eventFinish || currentDate
    const initDisplayedStart = dayjs(start).format(dateFormat)
    const initDisplayedFinish = dayjs(finish).format(dateFormat)
    const parsedStart = range === "custom" ? dayjs(start).format(parsedDateFormat) : ""
    const parsedFinish = range === "custom" ? dayjs(finish).format(parsedDateFormat) : ""
    setDisplayedDate({ start: initDisplayedStart, finish: initDisplayedFinish })
    if (getDisplayedDate) {
      getDisplayedDate({ start: initDisplayedStart, finish: initDisplayedFinish })
    }
    setInitRange({ start: dayjs(initDisplayedStart, dateFormat), finish: dayjs(initDisplayedFinish, dateFormat) })
    setDateRange({ type: range, start: parsedStart, finish: parsedFinish })
    setIsActive(false)
  }

  const rangeSetter = (range: any) => {
    setIsActive(false)
    setDateRange({ type: range, start: "", finish: "" })
    const newStart = dayjs(currentDate).subtract(1, range).format(dateFormat)
    const newFinish = dayjs(currentDate).format(dateFormat)
    setDisplayedDate({ start: newStart, finish: newFinish })
    if (getDisplayedDate) {
      getDisplayedDate({ start: newStart, finish: newFinish })
    }
  }

  const handleReload = (parsedStart?: string, parsedFinish?: string) => {
    if(rangeType !== "custom" || (displayedDate?.start !== "Invalid Date" && displayedDate?.finish !== "Invalid Date")){
      setIsActive(false)
    }
    const start = parsedStart || dayjs(displayedDate?.start, dateFormat).format(parsedDateFormat)
    const finish = parsedFinish || dayjs(displayedDate?.finish, dateFormat).format(parsedDateFormat)
    onReload &&
    onReload({
      date_range: rangeType,
      start: rangeType === "custom" ? start : undefined,
      finish: rangeType === "custom" ? finish : undefined,
    })
  }

  const handleCustomChange = (start: Dayjs | null, finish: Dayjs | null) => {
    setDisplayedDate({ start: start?.format(dateFormat), finish: finish?.format(dateFormat) })
    if (getDisplayedDate) {
      getDisplayedDate({ start: start?.format(dateFormat), finish: finish?.format(dateFormat) })
    }
    setDateRange({
      type: "custom",
      start: start?.format(parsedDateFormat),
      finish: finish?.format(parsedDateFormat),
    })
  }

  const resetSelector = () => {
    setIsCustomRange(false)
  }
  return (
    <Styled.SelectorWrapper ref={selectorRef}>
      {!isCustomRange ? (
        <Selector
          isActive={enableEventDateSelector && isActive}
          label={ranges.find(range => range.value === rangeType)?.label || ""}
          type={"default"}
          setIsActive={setIsActive}
          enableEventDateSelector={enableEventDateSelector}
        >
          <Styled.IntervalList>
            {ranges.map(range => (
              <Styled.IntervalItem
                key={range.value}
                isActive={range.value === rangeType}
                onClick={() => handleSelectedRange(range.value)}
              >
                <Styled.Chip
                  type={range.value === "custom" ? "outlined" : "default"}
                  label={<Styled.ChipTypography>{range.label}</Styled.ChipTypography>}
                  onClick={() => {
                  }}
                />
              </Styled.IntervalItem>
            ))}
          </Styled.IntervalList>
        </Selector>
      ) : (
        <Selector
          isActive={enableEventDateSelector && isActive}
          label={ranges.find(range => range.value === rangeType)?.label || ""}
          type={"rounded"}
          resetSelector={resetSelector}
          setIsActive={setIsActive}
          onClose={handleReload}
          enableEventDateSelector={enableEventDateSelector}
        >
          <DateRangePicker
            labelStart={"Дата начала"}
            labelFinish={"Дата завершения"}
            onChange={handleCustomChange}
            initRange={initRange}
            displayedDate={displayedDate}
            onReload={handleReload}
          />
        </Selector>
      )}
      <DisplayedDate displayedDate={displayedDate} isLoading={isLoading} />
    </Styled.SelectorWrapper>
  )
}

export { EventDateSelector }
