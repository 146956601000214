import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { Autocomplete, Button } from "shared/ui"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import { Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { addTeamInTeamsModule, deleteTeamInTeamsModule } from "../../entities/Module/api/module"

export const EditModuleTeams = ({
  teams,
  teams_titles,
  module_id,
}: {
  teams_titles: string[]
  teams: number[]
  module_id: number
}) => {
  const [currentTeam, setCurrentTeam] = useState<
    | {
        label: string
        value: number
      }
    | undefined
  >(undefined)
  const courseTeams = useAppSelector(state => state.trajectory.teams)
  const [moduleTeams, setModuleTeams] = useState<
    {
      id: number
      title: string
    }[]
  >([])
  const [textRequest, setTextRequest] = useState("")
  const [error, setError] = useState(false)

  useEffect(() => {
    let module_teams: {
      id: number
      title: string
    }[] = []
    teams.forEach((item, index) => {
      module_teams.push({ id: item, title: teams_titles[index] })
    })
    setModuleTeams([...module_teams])
  }, [])

  function addParticipantInModule() {
    if (!!currentTeam) {
      if (moduleTeams.filter(e => e.id === currentTeam.value).length > 0) {
        setTextRequest("Команда уже добавлена")
        setError(true)
      } else {
        addTeamInTeamsModule([currentTeam.value], module_id)
          .then(res => {
            setModuleTeams(prev => [...prev, { id: currentTeam.value, title: currentTeam.label }])
            setTextRequest("Команда добавлена")
            setError(false)
            setTimeout(() => {
              setTextRequest("")
            }, 10000)
          })
          .catch(err => {
            setTextRequest("Ошибка")
            setError(true)
            setTimeout(() => {
              setTextRequest("")
            }, 10000)
          })
      }
    }
  }

  function deleteParticipant(id: number) {
    deleteTeamInTeamsModule([id], module_id)
      .then(res => {
        setModuleTeams(prev => [...prev.filter(e => e.id !== id)])
      })
      .catch(err => {})
  }

  return (
    <MainDiv>
      <TeamsDiv>
        {moduleTeams.map(e => (
          <TeamDiv>
            <Typography variant={"body1"} fontSize={"small"} fontWeight={"medium"}>
              {e.title}
            </Typography>
            <CloseIcon sx={{ color: "#D82E2E", cursor: "pointer" }} onClick={() => deleteParticipant(e.id)} />
          </TeamDiv>
        ))}
      </TeamsDiv>
      <AddTeamDiv>
        <Autocomplete
          //@ts-ignore
          onChange={(e, newValue) => setCurrentTeam(newValue)}
          style={{ width: "300px" }}
          value={currentTeam?.label}
          options={courseTeams.map(e => ({ label: e.title, value: e.id }))}
          label={"Выберите команду"}
        />
        <Button onClick={addParticipantInModule} variant={"outlined"} color={"primary"}>
          Добавить команду
        </Button>
        {textRequest.length > 0 && <RequestMessage error={error} text={textRequest} />}
      </AddTeamDiv>
    </MainDiv>
  )
}

const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
}))

const AddTeamDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(4),
}))

const TeamsDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  border: "1px solid #E0E0E0",
  gap: theme.spacing(4),
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
  minHeight: theme.spacing(10),
  maxHeight: theme.spacing(24),
  overflowY: "auto",
}))

const TeamDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
}))
