import React from 'react';
import {useTheme} from "../../../shared/context";
import {styled} from "@mui/material/styles";
import {IconButton} from "shared/ui"
import AddIcon from '@mui/icons-material/Add';

export const IconButtons = () => {

    const theme = useTheme()

    const MainDiv = styled("div")({
        display:"flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper
    })

    const StyledDiv = styled("div")({
        display: "flex",
        flexDirection: "row",
        marginLeft: theme.spacing(31),
        marginRight: theme.spacing(31),
        alignItems: "center",
        marginTop: theme.spacing(8),
        justifyContent: "flex-start",
        gap: theme.spacing(8),
    })

    return (
        <MainDiv>
            <StyledDiv>
                <IconButton color={"primary"} size={"large_x"}>
                    <AddIcon sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
                <IconButton color={"primary"} size={"large"}>
                    <AddIcon sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
                <IconButton color={"primary"} size={"medium"}>
                    <AddIcon sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
                <IconButton color={"primary"} size={"small"}>
                    <AddIcon sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
                <IconButton color={"secondary"} size={"large_x"}>
                    <AddIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
                <IconButton color={"secondary"} size={"large"}>
                    <AddIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
                <IconButton color={"secondary"} size={"medium"}>
                    <AddIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
                <IconButton color={"secondary"} size={"small"}>
                    <AddIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
            </StyledDiv>
        </MainDiv>
    );
};

