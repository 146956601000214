import { CircularProgress, Typography } from "@mui/material"
import { ProgressDiv } from "./TableLoader.style"

export const TableLoader = ({ children, isLoading }) => {
  if (!isLoading) return

  return (
    <ProgressDiv>
      <CircularProgress size={26} />
      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
        {children}
      </Typography>
    </ProgressDiv>
  )
}
