import React from "react"
import { Chip, Typography } from "@mui/material"


const colors = {
  moderator: "#D03FE8",
  observer: "#3EBD44",
  owner: "#FFA000"
}

const translates = {
  moderator: "Модератор",
  owner: "Владелец",
  observer: "Наблюдатель"
}
export const UserChip = ({ label }: { label: "moderator" | "owner" | "observer" }) => {
  return (
    <Chip
      sx={{ backgroundColor: colors[label] }}
      label={
        <Typography variant={"caption"} fontWeight={"small"} sx={{ color: "#FFF" }}>
          {translates[label]}
        </Typography>
      }
    />
  )
}