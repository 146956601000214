import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosInstance, axiosInstance as axios } from "shared/api/axiosInstance"
// @ts-ignore
import Cookies from "js-cookie"
import { IOrganization } from "./Organization.types"

export const fetchOrganization = createAsyncThunk("organization/fetchOrganization", async id => {
  const response = await axios.get(`/organizations/${id}/`)
  return response.data
})

export const updateOrganization = createAsyncThunk("organization/updateOrganization", async (params: IOrganization) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  const response = await axios.patch(`/organizations/${params.id}/update_organization/`, params)
  return response.data
})

export const fetchOrganizations = createAsyncThunk("organization/fetchOrganizations", async () => {
  const response = await axios.get("/organizations/my_organizations/?role=moderator")
  return response.data
})

export const fetchOrganizationsAsAdmin = createAsyncThunk("organization/fetchOrganizationOfAdmin", async () => {
  const response = await axios.get("/organizations/")
  return response.data
})
