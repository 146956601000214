import React, { useEffect, useState } from "react"
import * as Styled from "./ui/Teams.style.js"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import { Autocomplete, Button } from "shared/ui"
import { Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useTheme } from "shared/context"

const options = [
  { label: "Администратор", value: "moderator" },
  { label: "Главный Администратор", value: "chief_moderator" },
]

export const SetTeamStatus = ({ course }) => {
  const theme = useTheme()
  const [teamVariants, setTeamVariants] = useState([])
  const [team, setTeam] = useState(null)
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState("")

  useEffect(() => {
    axios.get("content/actions/teams/my_teams/", { params: { course: course.id } }).then(res => {
      setTeamVariants(res.data.map(e => ({ id: e.id, label: e.title })))
    })
  }, [])

  function requestMessageMapper(res) {
    switch (res) {
      case "ok":
        return (
          <Styled.MessageMapper>
            <DoneIcon sx={{ color: theme.palette.success.main }} />
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"}>
              Статус успешно изменен
            </Typography>
          </Styled.MessageMapper>
        )
      case "err":
        return (
          <Styled.MessageMapper>
            <CloseIcon sx={{ color: theme.palette.error.main }} />
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"}>
              Ошибка
            </Typography>
          </Styled.MessageMapper>
        )
    }
  }

  function handleClickButton() {
    axios.get("/profiles/my_profile").then(res => {
      axios
        .put(`/content/teams/${team.id}/promote/`, {
          member_category: status.value,
          title: team.label,
          user_id: res.data.id,
        })
        .then(res => {
          setMessage(requestMessageMapper("ok"))
        })
        .catch(err => {
          setMessage(requestMessageMapper("err"))
        })
    })
  }

  function handleTeamValue(event, newValue) {
    setTeam(newValue)
  }

  function handleStatusValue(event, newValue) {
    setStatus(newValue)
  }

  return (
    <Styled.MainTeamStatusDiv>
      <Typography variant={"h4"}>Изменить собственный статус в команде</Typography>
      <Styled.TeamStatusDiv>
        <Autocomplete
          options={teamVariants}
          label={"Название команды"}
          value={team}
          onChange={handleTeamValue}
          width={225}
        />
        <Autocomplete
          options={options}
          value={status}
          onChange={handleStatusValue}
          label={"Выберите статус"}
          width={225}
        />
        <Button variant={"outlined"} color={"primary"} onClick={handleClickButton}>
          Применить
        </Button>
      </Styled.TeamStatusDiv>
      <>{message}</>
    </Styled.MainTeamStatusDiv>
  )
}
