import React, { useState } from "react"
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { AwaitingDialog } from "shared/assets"
import { wordMapper } from "shared/local"
import { courseStatuses } from "entities/Team/model/teamWordMapper"

export const ParticipantStatusSetter = ({ user }) => {
  const [courseId, setCourseId] = useState("1")
  const [helperText, setHelperText] = React.useState("")
  const [courseStatus, setCourseStatus] = useState(null)
  const [updateStatus, setUpdateStatus] = useState(false)

  function setParticipantStatus(current_user, status) {
    setUpdateStatus(true)
    axios.get("/profiles/my_profile/").then(() => {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios
        .put(`/content/participants/set_status/`, { user: current_user, course: courseId, status: status })
        .then(res => {
          setHelperText("Статус доступа изменён")
          setUpdateStatus(false)
        })
    })
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="h5" style={{ textAlign: "center", color: "red", marginTop: 24 }}>
        {helperText}
      </Typography>
      <TextField
        id="courseId"
        variant="outlined"
        value={courseId}
        onChange={e => setCourseId(e.target.value)}
        helperText="ID курса"
        type="email"
        fullWidth
        style={{ margin: 24 }}
      />
      <FormControl sx={{ minWidth: 240, marginRight: 24 }}>
        <InputLabel>{"Статус на курсе"}</InputLabel>
        <Select
          id="participantStatus"
          value={courseStatus}
          input={<OutlinedInput label={"Статус на курсе"} />}
          onChange={e => setCourseStatus(e.target.value)}
        >
          {courseStatuses.map(e => (
            <MenuItem value={e}>{`${wordMapper(e)}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={() => setParticipantStatus(user.id, courseStatus)}
        variant="contained"
        color="primary"
        style={{ width: 260 }}
      >
        Задать статус
      </Button>
      <AwaitingDialog open={updateStatus} />
    </div>
  )
}
