export const TrashIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
      <path
        d="M11.2083 3V3.04167H11.25H13.5C13.6879 3.04167 13.868 3.11629 14.0009 3.24913C14.1337 3.38197 14.2083 3.56214 14.2083 3.75C14.2083 3.93786 14.1337 4.11803 14.0009 4.25087C13.868 4.38371 13.6879 4.45833 13.5 4.45833H13.25H13.2083V4.5V13C13.2083 13.3205 13.081 13.6278 12.8544 13.8544C12.6278 14.081 12.3205 14.2083 12 14.2083H4C3.67953 14.2083 3.37219 14.081 3.14558 13.8544C2.91897 13.6278 2.79167 13.3205 2.79167 13V4.5V4.45833H2.75H2.5C2.31214 4.45833 2.13197 4.38371 1.99913 4.25087C1.86629 4.11803 1.79167 3.93786 1.79167 3.75C1.79167 3.56214 1.86629 3.38197 1.99913 3.24913C2.13197 3.11629 2.31214 3.04167 2.5 3.04167H4.75H4.79167V3V2.25C4.79167 1.79692 4.97165 1.3624 5.29203 1.04203C5.6124 0.721651 6.04692 0.541667 6.5 0.541667H9.5C9.95308 0.541667 10.3876 0.721651 10.708 1.04203C11.0283 1.3624 11.2083 1.79692 11.2083 2.25V3ZM9.75 3.04167H9.79167V3V2.25C9.79167 2.17265 9.76094 2.09846 9.70624 2.04376C9.65154 1.98906 9.57735 1.95833 9.5 1.95833H6.5C6.42265 1.95833 6.34846 1.98906 6.29376 2.04376C6.23906 2.09846 6.20833 2.17265 6.20833 2.25V3V3.04167H6.25H9.75ZM11.75 12.7917H11.7917V12.75V4.5V4.45833H11.75H4.25H4.20833V4.5V12.75V12.7917H4.25H11.75ZM7.20833 6.5V10.5C7.20833 10.6879 7.13371 10.868 7.00087 11.0009C6.86803 11.1337 6.68786 11.2083 6.5 11.2083C6.31214 11.2083 6.13197 11.1337 5.99913 11.0009C5.86629 10.868 5.79167 10.6879 5.79167 10.5V6.5C5.79167 6.31214 5.86629 6.13197 5.99913 5.99913C6.13197 5.86629 6.31214 5.79167 6.5 5.79167C6.68786 5.79167 6.86803 5.86629 7.00087 5.99913C7.13371 6.13197 7.20833 6.31214 7.20833 6.5ZM10.2083 6.5V10.5C10.2083 10.6879 10.1337 10.868 10.0009 11.0009C9.86803 11.1337 9.68786 11.2083 9.5 11.2083C9.31214 11.2083 9.13197 11.1337 8.99913 11.0009C8.86629 10.868 8.79167 10.6879 8.79167 10.5V6.5C8.79167 6.31214 8.86629 6.13197 8.99913 5.99913C9.13197 5.86629 9.31214 5.79167 9.5 5.79167C9.68786 5.79167 9.86803 5.86629 10.0009 5.99913C10.1337 6.13197 10.2083 6.31214 10.2083 6.5Z"
        fill={color || "currentColor"}
        stroke={color || "currentColor"}
        stroke-width="0.0833333"
      />
    </svg>
  )
}
