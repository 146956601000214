import { useRef } from "react"
import { ButtonWrapper, ScrollButtonLeft, ScrollButtonRight } from "./ScrollButtons.style.ts"
import { TableArrowLeftIcon } from "images/icons/ArrowLeft"
import { TableArrowRightIcon } from "images/icons/ArrowRight"

export const ScrollButtons = ({ tableRef }) => {
  const time = 10
  const deltaScroll = 10
  const scrollIntervalRef = useRef(null)

  const scrollLeft = () => {
    if (tableRef?.current) {
      tableRef.current.scrollLeft -= deltaScroll
    }
  }

  const scrollRight = () => {
    if (tableRef?.current) {
      tableRef.current.scrollLeft += deltaScroll
    }
  }

  const startScrollLeft = () => {
    if (scrollIntervalRef.current) return
    scrollIntervalRef.current = setInterval(scrollLeft, time)
  }

  const stopScrollLeft = () => {
    clearInterval(scrollIntervalRef.current)
    scrollIntervalRef.current = null
  }

  const startScrollRight = () => {
    if (scrollIntervalRef.current) return
    scrollIntervalRef.current = setInterval(scrollRight, time)
  }

  const stopScrollRight = () => {
    clearInterval(scrollIntervalRef.current)
    scrollIntervalRef.current = null
  }

  return (
    <>
      <ButtonWrapper onMouseLeave={stopScrollLeft} onMouseEnter={startScrollLeft} sx={{ left: 0 }}>
        <ScrollButtonLeft variant="contained">
          <TableArrowLeftIcon />
        </ScrollButtonLeft>
      </ButtonWrapper>
      <ButtonWrapper onMouseLeave={stopScrollRight} onMouseEnter={startScrollRight} sx={{ right: 0 }}>
        <ScrollButtonRight variant="contained">
          <TableArrowRightIcon />
        </ScrollButtonRight>
      </ButtonWrapper>
    </>
  )
}
