import React, { useEffect, useState } from "react"
import { Autocomplete, Button, ShadowedSection, TextField } from "shared/ui"
import { CircularProgress, Typography } from "@mui/material"
import * as Styled from "./AdminTeams.style.ts"
import { axiosInstance as axios } from "../../../shared/api/axiosInstance"
import Cookies from "js-cookie"

export const ChoiseCourse = ({ loadRequests, setCourse, setIsModerated, title, status, isLoading }) => {
  const [variantValue, setVariantValue] = useState(null)
  const [variants, setVariants] = useState([])
  const [fieldValue, setFieldValue] = useState("")

  useEffect(() => {
    axios.get("content/actions/participants/my_courses/?status=moderator", {}).then(res => {
      setVariants(res.data.map(e => ({ id: e.course, label: e.course_title })))
    })
  }, [])

  const handleChooseCourse = (event, newValue) => {
    clearCourse()
    setVariantValue(newValue)
    setFieldValue(newValue?.id || "")
  }

  const handleChangeFieldValue = e => {
    clearCourse()
    const inputValue = e.target.value
    let variant = variants.find(v => v.id == inputValue)
    setFieldValue(inputValue)
    if (variant !== undefined) {
      setVariantValue(variant)
    } else {
      setVariantValue(undefined)
    }
  }

  function fetchCourse() {
    try {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios.get(`/content/courses/${fieldValue}/`).then(res => {
        setCourse(res.data)
        if (!!loadRequests) loadRequests(res.data.id, null)
      })
    } catch {
      setCourse(null)
    }
  }

  const clearCourse = () => {
    setCourse(null)
    setIsModerated && setIsModerated(false)
  }

  return (
    <ShadowedSection>
      <Styled.ChoiseCourseMainDiv>
        <Typography variant={"h4"}>{title}</Typography>
        <Styled.ChoiseCourseDiv>
          <Autocomplete
            id={"AutoComplete_course_id_value"}
            label={"Название курса"}
            options={variants}
            value={variantValue}
            onChange={handleChooseCourse}
            width={225}
          />
          {(status === "admin" || status === "moderator") && (
            <>
              <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
                или
              </Typography>
              <TextField
                id={"TextField_course_id_value"}
                label={"ID курса"}
                variant={"outlined"}
                value={fieldValue}
                onChange={e => handleChangeFieldValue(e)}
              />
            </>
          )}
          <Button variant={"contained"} color={"primary"} onClick={fetchCourse} disabled={isLoading}>
            Применить
          </Button>
          {isLoading && <CircularProgress />}
        </Styled.ChoiseCourseDiv>
      </Styled.ChoiseCourseMainDiv>
    </ShadowedSection>
  )
}
