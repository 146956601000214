import React, { useEffect, useState } from "react"
import { Button, Modal } from "../../shared/ui"
import { Typography } from "@mui/material"
import { Questionnaire } from "../Questionnaire/Questionnaire"
import { StepperState } from "./ui/StepperState"
import { axiosInstance } from "../../shared/api/axiosInstance"
import { PaidRequest } from "./ui/PaidRequest"
import { AccessPayment } from "./ui/AccessPayment"

export const RequestOnCourse = ({
  open,
  setOpen,
  requestAccess,
  setFormValues,
  formValues,
  content,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  requestAccess: () => void
  setFormValues: React.Dispatch<React.SetStateAction<any>>
  formValues: any
  content: any
}) => {
  useEffect(() => {
    axiosInstance
      .get("content/actions/courses/my_status", { params: { course_id: content.id } })
      .then(res => {
        if (res.data.status === "in_progress") {
        }
      })
      .catch(err => {})
  }, [])

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      title={content.payment_status === "free" ? "Заявка на участие в мероприятии" : ""}
    >
      <div style={{ padding: 36 }}>
        <Typography style={{ fontWeight: 400 }}>
          Для подачи заявки на участие в программе заполните информацию
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: content.info_description }} />
        {content.info_fields && <Questionnaire fields={content.info_fields} setValues={setFormValues} />}
        <Button
          onClick={requestAccess}
          disabled={
            !formValues
              //@ts-ignore
              .filter(e => !!e.is_required)
              //@ts-ignore
              .reduce((totalCorrect, field) => totalCorrect && !!field.is_required && !!field.value, true)
          }
          variant="contained"
          color="primary"
          size="large"
          sx={{ width: 240, height: 50, textTransform: "uppercase", marginTop: 32 }}
        >
          Подать заявку
        </Button>
      </div>
    </Modal>
  )
}
