import React, { useState } from "react"
import useStyle from "./styles"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import EmailOk from "../../../images/mail_ok.gif"
import EmailLate from "../../../images/mail_late.gif"
import { Button, TextField } from "../../../shared/ui"

const FeedbackForm = ({ e }) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [question, setQuestion] = useState("")
  const [send, setSend] = useState(false)
  const [emailResultImage, setEmailResultImage] = useState(null)
  const [textSend, setTextSend] = useState()
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const classes = useStyle()

  function handleClick() {
    const ref_token = localStorage.getItem("token_ref")
    if (!ref_token) {
      delete axios.defaults.headers.common["Authorization"]
    }
    axios
      .post("/send-email/", { name: name, email: email, text: question })
      .then(res => {
        setEmailResultImage(EmailOk)
        setSend(true)
        setTextSend("Ваше письмо успешно отправлено! \n Новую форму вы можете отправить через 1 минуту.")
        setName("")
        setEmail("")
        setQuestion("")
      })
      .catch(err => {
        setEmailResultImage(EmailLate)
        setSend(true)
        let second = /\d+/.exec(err.response.data.detail)
        setTextSend(`Вы можете отправить новое письмо через ${second} секунд(ы), пожалуйста подождите.`)
      })
  }

  return (
    <div className={classes.feedbackFormContainer}>
      <TextField
        style={{ width: "100%" }}
        label={"Имя"}
        color={e?.textFieldColor}
        value={name}
        onChange={e => setName(e.target.value)}
        borderBottomColor={e?.borderBottomColor}
      />
      <TextField
        style={{ width: "100%" }}
        label={"Email"}
        value={email}
        color={e?.textFieldColor}
        onChange={e => setEmail(e.target.value)}
        borderBottomColor={e?.borderBottomColor}
      />
      <TextField
        style={{ width: "100%" }}
        label={"Введите вопрос"}
        value={question}
        color={e?.textFieldColor}
        onChange={e => setQuestion(e.target.value)}
        borderBottomColor={e?.borderBottomColor}
      />
      {!!e.button && (
        <Button
          sx={{
            backgroundColor: e?.button?.backgroundColor,
            color: e?.button?.color,
            "&:hover": { backgroundColor: e?.button?.hoverBackgroundColor },
            "&:disabled": { color: e?.button?.color, opacity: "0.5" },
          }}
          size={"medium"}
          disabled={!(email.length > 0 && name.length > 0 && question.length > 0 && !!emailReg.test(email))}
          onClick={handleClick}
        >
          Отправить
        </Button>
      )}
      <Dialog
        aria-labelledby="DialogTitle"
        aria-describedby="DialogDescription"
        open={send}
        onClose={() => setSend(false)}
      >
        <DialogTitle id="DialogTitle" className={classes.dialogEmail}></DialogTitle>
        <DialogContent className={classes.dialogEmail}>
          <img style={{ width: "100%", height: "100%" }} src={emailResultImage} />
          <DialogContentText style={{ textAlign: "center" }} id="DialogDescription">
            {textSend}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogEmail}>
          <Button onClick={() => setSend(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FeedbackForm
