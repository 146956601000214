import { axiosInstance as axios } from "shared/api/axiosInstance"
// @ts-ignore
import { useHistory } from "react-router-dom"
import { useContentState } from "shared/context"

export function useBackCourseRedirection(courseSlug: string) {
  const history = useHistory()

  const redirect = (exitLink?: string) => {
    if (exitLink) {
      return history.push(exitLink)
    }
    axios.get("/content/courses/", { params: { slug: courseSlug } }).then(res => {
      let data = res.data[0]
      if (data.type === "event" || data.type === "kruzhok") history.push(`/event/${courseSlug}/stages`)
      else history.push(`/catalog/course/${courseSlug}`)
    })
  }

  return {
    redirect,
  }
}

export function useBackModalRedirection(courseSlug: string) {
  const history = useHistory()
  // @ts-ignore
  const { current, setCurrent } = useContentState()

  const redirect = (exitLink?: string) => {
    if (exitLink) {
      return history.push(exitLink)
    }
    if (current.subsection > 1) {
      return setCurrent({ ...current, subsection: current.subsection - 1 })
    }
    if (current.section > 1) {
      return setCurrent({ section: current.section - 1, subsection: 1 })
    }
    axios.get("/content/courses/", { params: { slug: courseSlug } }).then(res => {
      let data = res.data[0]
      if (data.type === "event") history.push(`/event/${courseSlug}/stages`)
      else history.push(`/catalog/course/${courseSlug}`)
    })
  }

  return {
    redirect,
  }
}
