import { createSlice } from "@reduxjs/toolkit"
import { IStage } from "entities/Course/api/course.types"
import { sortStages } from "../../../entities/Course"
import { ModuleType } from "../../../entities/Module/api/module.types"
import { removeModule, saveTrajectory, updateModuleContent } from "../../../entities/Module/api/module"
import { bool } from "yup"

type CourseType = "event" | "course" | "webinar" | null

type ListOfCourseType = {
  label: string
  value: number
}[]

type BadgeType = "online" | "offline" | "hybrid" | null

interface IState {
  course_type: CourseType
  course_badge: BadgeType
  stages: IStage[]
  courses: ListOfCourseType
  course_id: number | null
  save_trigger: boolean
  saving_status: boolean[]
  is_changed: boolean
  participants: {
    id: number
    email: string
  }[]
  teams: {
    id: number
    title: string
  }[]
}

const initialState: IState = {
  course_badge: null,
  stages: [],
  course_type: null,
  courses: [],
  course_id: null,
  save_trigger: false,
  saving_status: [],
  participants: [],
  is_changed: false,
  teams: [],
}

const trajectorySlice = createSlice({
  name: "trajectory_slice",
  initialState: initialState,
  reducers: {
    setInitState: state => {
      state.stages = []
      state.participants = []
      state.course_id = null
    },
    setIsChanged: (state, action: { payload: boolean }) => {
      state.is_changed = action.payload
    },
    initSaveStatus: state => {
      state.saving_status = []
    },
    pushSaveStatus: (
      state,
      action: {
        payload: boolean
      }
    ) => {
      state.saving_status = [...state.saving_status, action.payload]
    },
    setCourseId: (
      state,
      action: {
        payload: number
      }
    ) => {
      state.course_id = action.payload
    },
    setSaveTrigger: state => {
      state.save_trigger = !state.save_trigger
    },
    setParticipants: (
      state,
      action: {
        payload: {
          id: number
          email: string
        }[]
      }
    ) => {
      state.participants = [...action.payload]
    },
    setTeams: (
      state,
      action: {
        payload: {
          id: number
          title: string
        }[]
      }
    ) => {
      state.teams = [...action.payload]
    },
    setCourseType: (
      state,
      action: {
        payload: CourseType
      }
    ) => {
      state.course_type = action.payload
    },
    setCourseBadge: (
      state,
      action: {
        payload: BadgeType
      }
    ) => {
      state.course_badge = action.payload
    },
    setStages: (
      state,
      action: {
        payload: {
          stages: IStage[]
          modules: ModuleType[]
        }
      }
    ) => {
      let new_stages = [...action.payload.stages.filter(e => !e.is_empty).sort(sortStages)]
      new_stages.forEach((item, index) => {
        new_stages[index]["modules"] = [...action.payload.modules.filter(e => e.stage === item.id)].sort((a, b) =>
          a.index > b.index ? 1 : -1
        )
      })
      state.stages = new_stages
    },
    setCourses: (
      state,
      action: {
        payload: ListOfCourseType
      }
    ) => {
      state.courses = action.payload
    },
    pushNewModule: (
      state,
      action: {
        payload: ModuleType
      }
    ) => {
      let new_stages = [...state.stages]
      new_stages.forEach((item, index) => {
        if (action.payload.stage === item.id) {
          new_stages[index].modules?.push({ ...action.payload })
        }
      })
      state.stages = [...new_stages]
    },
  },
  extraReducers: builder => {
    builder
      .addCase(removeModule.fulfilled, (state, action) => {
        let new_stages: IStage[] = state.stages
        let stage_id = action.payload.stage
        let module_id = action.payload.module
        for (let i = 0; i < new_stages.length; i++) {
          if (new_stages[i].id === stage_id) {
            if (!!new_stages[i].modules) {
              new_stages[i].modules = new_stages[i].modules
                ?.filter(e => e.id !== module_id)
                .sort((a, b) => (a.index > b.index ? 1 : -1))
            }
            break
          }
        }
        state.stages = [...new_stages]
      })
      .addCase(updateModuleContent.fulfilled, (state, action) => {
        state.stages.forEach((item, index) => {
          if (item.id === action.payload.stage && !!item.modules) {
            state.stages[index].modules = [
              ...item.modules.filter(e => e.id !== action.payload.id),
              { ...action.payload },
            ].sort((a, b) => (a.index > b.index ? 1 : -1))
          }
        })
      })
  },
})

export const { actions, reducer } = trajectorySlice
