import { createContext, ReactNode, useContext, useState } from "react"
import { initEventFilters, initialStatistics, initTable } from "../static/statisticsInitial"
import {
  StatisticsType,
  StatisticsVariants as Variants,
  IStatisticsContext,
  IChartData,
  TableDataType,
  DateRangeType,
  IEventFilters,
  IGeneral,
} from "./StatisticsContext.types"
import { IAnswer, IQuestion } from "./useStatisticsDataList"

const StatisticsContext = createContext<IStatisticsContext>(null!)

const StatisticsProvider = ({ children }: { children: ReactNode }) => {
  const [statistics, setStatistics] = useState<StatisticsType>(initialStatistics)
  const [eventFilters, setEventFilters] = useState<IEventFilters>(initEventFilters)
  const [dateRange, setDateRange] = useState<DateRangeType>({ type: "all", start: "", finish: "" })

  const setChart = (type: Variants, data: IChartData) => {
    setStatistics(prev => ({ ...prev, [type]: { ...prev[type], ...data } }))
  }

  const setTableData = (type: Variants, data: TableDataType) => {
    setStatistics(prev => ({ ...prev, [type]: { ...prev[type], data: { ...prev[type].data, ...data } } }))
  }

  const setAnswers = (answers: IAnswer[]) => {
    setEventFilters(prev => ({ ...prev, answers }))
  }

  const setQuestions = (questions: IQuestion[]) => {
    setEventFilters(prev => ({ ...prev, questions }))
  }

  const setGeneral = (type: Variants, general: IGeneral) => {
    setStatistics(prev => ({ ...prev, [type]: { ...prev[type], general } }))
  }

  const resetStatistics = (type: Variants) => {
    setStatistics(prev => ({ ...prev, [type]: initialStatistics[type] }))
  }

  const resetStatisticsTable = (type: Variants) => {
    setStatistics(prev => ({
      ...prev,
      [type]: { ...prev[type], data: { ...initTable, isLoaded: prev[type].data.isLoaded } },
    }))
  }

  return (
    <StatisticsContext.Provider
      value={{
        statistics,
        dateRange,
        eventFilters,
        setDateRange,
        setChart,
        setTableData,
        setGeneral,
        resetStatistics,
        resetStatisticsTable,
        setAnswers,
        setQuestions,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  )
}

export default StatisticsProvider

export const useStatistics = () => useContext(StatisticsContext)
