import { ModuleType } from "entities/Module/api/module.types"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import { Button } from "shared/ui"
import { Variant } from "shared/ui/styles.const"
import { saveProgress } from "entities/Participant/api/participant"

interface IProps {
  progress: IParticipantProgress
  currentModule?: ModuleType
  score?: string
  setIsLoading: (x: boolean) => void
  setMessage: (x: { success: string; fail: string }) => void
  setProgress: any
}

const SaveParticipantProgress = ({ progress, currentModule, score, setIsLoading, setMessage, setProgress }: IProps) => {
  const onSave = async () => {
    if (!currentModule) return
    try {
      setIsLoading(true)
      const response = await saveProgress({ module: currentModule.id, progress: progress.id, score: score })
      setProgress(response)
      setMessage({ success: "Изменения сохранены.", fail: "" })
    } catch {
      setMessage({ success: "", fail: "Произошла ошибка." })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button onClick={onSave} variant={Variant.outlined} disabled={score === "" || score === undefined}>
      Сохранить результаты
    </Button>
  )
}

export default SaveParticipantProgress
