import React from "react"
import { CardMedia, Grid, Typography } from "@mui/material"
import emptyAvatar from "../../../images/empty-avatar.png"

import { getStatusChip } from "../util/functions"

import { Card } from "shared/ui"

export const UserProfile = ({ user, avatarAction = null, userActions = null, rightContainerContent = null }) => {
  const hasName = () => {
    return user.first_name !== ""
  }

  return (
    <Card>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: 520, padding: 84, paddingLeft: 0 }}
      >
        <Grid item container xs={5} flexDirection="column" alignItems="center">
          <CardMedia image={user.avatar || emptyAvatar} component="img" style={{ width: "50%", marginBottom: "10%" }} />
          {avatarAction}
        </Grid>
        <Grid
          item
          container
          xs={4}
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{ height: "100%" }}
        >
          <Typography
            style={{
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 10,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {hasName() ? (
              `${user.last_name} ${user.first_name} ${user.middle_name || ""}`
            ) : (
              <p style={{ color: "red", margin: 0 }}>Имя не указано</p>
            )}
            <div style={{ width: 120, marginRight: 20 }}>{userActions}</div>
          </Typography>
          {getStatusChip(user.status)}
          <Typography style={{ fontSize: 18 }}>{`${user.location || "Не задано"} ${
            user.organization || "Без организации"
          }`}</Typography>
          <Typography style={{ fontSize: 18 }}>
            {`${new Date(user.birthday).toLocaleDateString("ru-RU") || "Дата рождения не указана"} пол: ${
              user.sex || "Пол неизвестен"
            }`}
          </Typography>
          <div>
            <Typography style={{ fontSize: 12 }}>Логин/Почта</Typography>
            <Typography style={{ fontSize: 18 }}>{user.email}</Typography>
          </div>
          <div>
            <Typography style={{ fontSize: 12 }}>Телефон</Typography>
            <Typography style={{ fontSize: 18 }}>{user.phone}</Typography>
          </div>
          <div>
            <Typography style={{ fontSize: 12 }}>ID профиля</Typography>
            <Typography style={{ fontSize: 18 }}>{user.id}</Typography>
          </div>
        </Grid>
        <Grid
          item
          container
          xs={3}
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          {rightContainerContent}
        </Grid>
      </Grid>
    </Card>
  )
}
