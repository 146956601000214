import React, { createRef, useEffect, useRef, useState } from "react"
import { Box, CircularProgress, styled, Typography } from "@mui/material"
import { ErrorMapType, ModuleType, SectionType } from "entities/Module/api/module.types"
import { Button, ProgressBar } from "shared/ui"
import { fetchModule } from "entities/Module"
import { ModuleSettingsMapper } from "entities/Module/ui/ModuleSettingsMapper"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  createSection,
  createSubsection,
  deleteSection,
  deleteSubsection,
  updateModuleContent,
  updateSection,
  updateSubsection,
} from "entities/Module/api/module"
import { validationModuleSchema } from "entities/Module/static/resolverSchema"
import { Sections } from "entities/Module/ui/Sections"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { DeleteModule } from "./DeleteModule"
import { useAppSelector } from "../../shared/hooks/useAppSelector"
import { trajectoryActions } from "../../shared/store"
import { EditModuleParticipants } from "./EditModuleParticipants"
import { EditModuleTeams } from "./EditModuleTeams"

export const EditModule = ({
  openModal,
  setOpenModal,
}: {
  openModal: number | null
  setOpenModal: (value: null) => void
}) => {
  const save_trigger = useAppSelector(state => state.trajectory.save_trigger)
  const dispatch = useAppDispatch()
  const [openDeleteModule, setOpenDeleteModule] = useState<{
    module_id: number
    stage_id: number
  } | null>(null)
  const refModule = createRef<HTMLDivElement>()
  const [saving, setSaving] = useState(false)
  const [module, setModule] = useState<ModuleType | null>(null)
  const [errors, setErrors] = useState<ErrorMapType>(null)
  const [deletedSections, setDeletedSections] = useState<Array<number | null>>([])
  const [deletedSubsections, setDeletedSubsections] = useState<Array<null | number>>([])
  const [textError, setTextError] = useState("")

  useEffect(() => {
    if (!!openModal) {
      fetchModule(openModal).then(res => {
        delete res.data?.image
        setModule({ ...res.data })
        if (!!refModule) return refModule?.current?.scrollIntoView({})
      })
    }
  }, [openModal])

  useEffect(() => {
    if (save_trigger) {
      handleClickUpdate()
    }
  }, [save_trigger])

  async function handleClickUpdate() {
    let new_module = module
    validationModuleSchema
      .validate(module, { abortEarly: false })
      .then(res => {
        setErrors(null)
        if (!!new_module) {
          delete new_module.team_titles
          delete new_module.teams
          delete new_module.participants
          delete new_module.participant_emails
          try {
            setSaving(true)
            dispatch(updateModuleContent(new_module))
            Promise.all(
              deletedSections.map(deletedSection => {
                if (!!deletedSection) deleteSection(deletedSection)
              })
            )
            Promise.all(
              deletedSubsections.map(deletedSubsection => {
                if (!!deletedSubsection) deleteSubsection(deletedSubsection)
              })
            )
            Promise.all(
              new_module.sections.map(section => {
                if (!section.id && !!module?.id)
                  createSection({ ...section, module: module?.id }).then(res => {
                    Promise.all(
                      section.subsections.map(e => {
                        createSubsection({ ...e, section: res.data.id })
                      })
                    )
                  })
                else updateSection({ ...section })
                Promise.all(
                  section.subsections.map(subsection => {
                    if (!subsection.id && !!section.id)
                      createSubsection({
                        ...subsection,
                        section: section.id,
                      })
                    if (!!subsection.id && !!section.id) updateSubsection({ ...subsection })
                  })
                )
              })
            ).then(res => {
              if (!!openModal) {
                setTimeout(() => {
                  fetchModule(openModal).then(res => {
                    setDeletedSections([])
                    setDeletedSubsections([])
                    setSaving(false)
                    showAccessSaving()
                    dispatch(trajectoryActions.pushSaveStatus(true))
                    delete res.data?.image
                    setModule({ ...res.data })
                  })
                }, 10000)
              }
            })
          } catch (err) {
            setSaving(false)
            setTextError("Ошибка")
            dispatch(trajectoryActions.pushSaveStatus(false))
          }
        }
      })
      .catch(err => {
        const errors = err.inner.reduce(
          (
            acc: [],
            error: {
              path: keyof ModuleType
              errors: string[]
            }
          ) => {
            return {
              ...acc,
              [error.path]: error.errors[0],
            }
          },
          {}
        )
        setTextError("Проверьте правильность заполнения полей.")
        setErrors({ ...errors })
        setSaving(false)
        dispatch(trajectoryActions.pushSaveStatus(false))
      })
  }

  function showAccessSaving() {
    setTextError("Модуль успешно сохранен.")
    setTimeout(() => {
      setTextError("")
    }, 10000)
  }

  function handleChangeSections(sections: SectionType[]) {
    if (!!module && !!module.sections) {
      dispatch(trajectoryActions.setIsChanged(true))
      module.sections = [...sections]
      setModule({ ...module })
    }
  }

  function handleCloseModule() {
    setOpenModal(null)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModule(null)
    //setOpenModal(null)
  }

  return (
    <>
      <DeleteModule openModal={openDeleteModule} closeModal={handleCloseDeleteModal} />
      {!!openModal && !!module && (
        <div ref={refModule}>
          <Box sx={{ marginTop: "48px", width: "100%", padding: "0px 8px" }}>
            <ContentWrapper>
              <HeaderWrapper>
                {/*@ts-ignore*/}
                <Typography variant={"h4"}>Параметры модуля</Typography>
                <Button
                  //@ts-ignore
                  color={"accent"}
                  variant={"outlined"}
                  onClick={() => setOpenDeleteModule({ module_id: module.id, stage_id: module.stage })}
                >
                  <DeleteIcon sx={{ fontSize: "32px", marginRight: "8px" }} />
                  Удалить модуль
                </Button>
              </HeaderWrapper>
              <FormWrapper>
                {!!module && <ModuleSettingsMapper errors={errors} module={module} setModule={setModule} />}
              </FormWrapper>
              {!!module && module.content_type === "content" && (
                <Sections
                  errors={errors}
                  deletedSections={deletedSections}
                  deletedSubsections={deletedSubsections}
                  setDeletedSections={setDeletedSections}
                  setDeletedSubsections={setDeletedSubsections}
                  setModule={handleChangeSections}
                  sections={module.sections || []}
                />
              )}
              {/*
                <SaveModuleWrapper>
                  <Button variant={"contained"} color={"primary"} onClick={handleClickUpdate}>
                    Сохранить
                  </Button>
                  {saving && <CircularProgress size={24} />}
                  {textError.length > 0 && !saving && <RequestMessage error={!!errors} text={textError} />}
                </SaveModuleWrapper>
              */}
              {module.type === "personal" && (
                <EditModuleParticipants
                  module_id={module.id}
                  users={module.participants || []}
                  emails={module.participant_emails || []}
                />
              )}
              {module.type === "team" && (
                <EditModuleTeams
                  teams_titles={module.team_titles || []}
                  teams={module.teams || []}
                  module_id={module.id}
                />
              )}
            </ContentWrapper>
          </Box>
        </div>
      )}
    </>
  )
}

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(12),
  width: "100%",
  marginBottom: theme.spacing(6),
}))

const FormWrapper = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
}))

const HeaderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}))

const SaveModuleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
}))
