import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import { useScript } from "shared/hooks";
import { CodeEditor } from "widgets";

export function CodeEditorBlock(props) {

    const [presets, setPresets] = useState(null);
    const [width, setWidth] = useState("65vw");

    useEffect(() => {
        let preset = JSON.parse(props.block.props)
        setPresets(preset)
        if (!!preset.width) {
            setWidth(parseInt(preset.width, 10))
        } else {
            setWidth("65vw")
        }
    }, [props.block.id]);

    if (!presets) return <></>

    return (
        <div style={{
            marginLeft: "5vw",
            marginRight: "5vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Typography style={{width: width}} align='left'>
                <CodeEditor idx={props.block.id} workFile={presets.workFile} value={presets.value || ""}
                            libs={!!presets.libs ? (presets.libs.split(" ")) : ([])} isChecking={presets.isChecking}/>
            </Typography>
        </div>
    )
}
