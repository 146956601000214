import { IEvent } from "../../model/Organization.types"
import { SummaryStatistics } from "features/Organization/SummaryStatistics"
import { StartPage, Loader, EmptyPage } from "../../static/statistics"

interface IProps {
  event?: IEvent
  isLoaded?: boolean
  isLoading: boolean
  isEmpty?: boolean
}

const tabOptions = [
  { title: "Общее", value: 0, enable: true },
  { title: "Легенда", value: 1, enable: true },
]

const PersonalTemplate = ({ isLoaded, isLoading, isEmpty }: IProps) => {
  if (isLoading) return <Loader />
  else if (isEmpty) return <EmptyPage />
  else if (!isLoaded) return <StartPage page={"activity"} />

  return <SummaryStatistics type={"personal"} tabOptions={tabOptions} />
}

export default PersonalTemplate
