import React, { useState, useCallback, useEffect } from "react"
import { Button, Typography, CircularProgress, IconButton, TextField as Input, Dialog } from "@mui/material"

import useStyles from "./styles"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useUserState, receiveToken } from "shared/context"
import ForgotPassword from "./ForgotPassword"

import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

function Login() {
  let classes = useStyles()

  const { login, registration, user } = useUserState()

  const [isForgot, setIsForgot] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [helperText, setHelperText] = useState("")
  const [loginValue, setLoginValue] = useState("")
  const [passwordValue, setPasswordValue] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const loginUser = () => {
    setHelperText("")
    setIsLoading(true)
    if (loginValue.length > 0 && passwordValue.length > 0) {
      axios
        .post("/token/", { username: loginValue, password: passwordValue })
        .then(res => {
          const token = res.data
          setTimeout(() => {
            user.setState(true)
            setIsLoading(false)
            receiveToken(token)

            localStorage.setItem("loggedIn", JSON.stringify({ token: token, timestamp: Date.now() }))

            login.setOpen(false)
          }, 0)
        })
        .catch(() => {
          setHelperText("Пароль или почта неверны")
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "loggedIn") {
        const data = JSON.parse(event.newValue)
        if (data) {
          receiveToken(data.token)
          user.setState(true)
        }
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [user])

  const handleKeyDown = useCallback(e => {
    if (e.nativeEvent.isComposing) {
      return
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      loginUser()
    }
  })

  return (
    <Dialog open={login.open} onClose={() => login.setOpen(false)} fullWidth maxWidth="sm">
      <div style={{ padding: 24 }}>
        {!isForgot && (
          <div className={classes.form}>
            <Typography variant="h2" className={classes.greeting}>
              Вход
            </Typography>
            <Input
              id="email"
              name="username"
              variant="outlined"
              value={loginValue}
              onChange={e => setLoginValue(e.target.value.toLowerCase())}
              margin="normal"
              placeholder="Почта"
              type="email"
              fullWidth
            />
            <Input
              id="password"
              name="current-password"
              variant="outlined"
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Пароль"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={event => handleClickShowPassword(event)}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeyDown}
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={loginValue.length === 0 || passwordValue.length === 0}
                  onClick={loginUser}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ width: 90, height: 50, textTransform: "uppercase" }}
                >
                  Войти
                </Button>
              )}
              <Typography color="secondary" className={classes.errorMessage}>
                {helperText}
              </Typography>
              <div>
                <Button
                  onClick={() => {
                    login.setOpen(false)
                    registration.setOpen(true)
                  }}
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Регистрация
                </Button>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => {
                    setHelperText("")
                    setIsForgot(true)
                  }}
                  className={classes.forgetButton}
                >
                  Забыли пароль?
                </Button>
              </div>
            </div>
          </div>
        )}
        {isForgot && <ForgotPassword setIsForgot={setIsForgot} />}
      </div>
    </Dialog>
  )
}

export default Login
