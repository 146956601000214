import { Avatar, Card, styled, Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"

export const KruzhokCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  height: "100%",
  backgroundColor: "#FFF",
  borderRadius: theme.spacing(3),
  boxShadow: "0px 0px 10px 0px #3636361A",
}))

export const ImageOfWebinar = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  padding: theme.spacing(5),
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItem: "flex-start",
  gap: theme.spacing(2),
  justifyContent: "flex-start",
  alignContent: "flex-start",
}))

export const CardContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
  height: "auto",
  width: "100%",
  padding: `${theme.spacing(5)} ${theme.spacing(5)}`,
}))

export const FooterWrapper = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  width: "100%",
  paddingRight: theme.spacing(5),
  marginTop: "auto",
}))

export const CardFooter = styled("div")(({ theme }) => ({
  borderTop: "1px solid #E0E0E0",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "flex-start",
  padding: `${theme.spacing(3)} 0`,
  height: "auto",
}))

export const AffiliationsContainer = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
}))

export const FooterItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
}))

export const CompanyAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  height: theme.spacing(10),
  "&:hover": {
    opacity: 0.5,
  },
}))

export const FooterTypography = styled(Typography)(({ theme }) => ({
  color: "#858585",
}))

export const FormatChipDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
}))

export const WebinarRequestedMessage = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "auto",
  justifyContent: "space-between",
  gap: theme.spacing(6),
  backgroundColor: "#DEF3FC",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(3),
  border: "1px solid #EBEBEB",
}))

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: "#3483F6",
}))

export const RequestedUserInfo = styled(Typography)(({ theme }) => ({
  color: "#0444A3",
}))

export const FinishedWebinarOfflineDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#FFDEDE",
  borderRadius: theme.spacing(3),
  border: "1px solid #EBEBEB",
  textAlign: "center",
  width: "100%",
  height: "auto",
}))

export const ParticipantWebinarMainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  height: theme.spacing(32),
  gap: theme.spacing(2),
}))

export const LinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: theme.spacing(2),
  justifyContent: "flex-start",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: "0.5",
  },
}))

export const LinkTypography = styled(Typography)(({ theme }) => ({
  color: "#1565C0",
  textDecoration: "underline",
}))

export const IFramePlace = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "400px",
  border: "none",
}))

export const DescriptionAndMessageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(5),
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "auto",
}))
