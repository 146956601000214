import * as Styled from "shared/ui/Filters/Filter.styles"
import useGetOptions from "entities/Assignment/model/useGetOptions"
import { useState } from "react"
import { CircularProgress } from "@mui/material"
import { Button, Autocomplete } from "shared/ui"
import { AutocompleteEvent, OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { IFilters, useFiltersForm } from "shared/hooks/useFiltersForm"
import { Size, Variant } from "shared/ui/styles.const"
import { AssignmentFilters } from "entities/Assignment/ui/AssignmentFilters"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { assignmentsActions, fetchAssignments } from "shared/store"
import { assignmentFiltersSchema, formingFilters } from "entities/Assignment/lib/assignmentFilters"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const AssignmentFiltersSection = () => {
  const methods = useForm({ resolver: yupResolver(assignmentFiltersSchema) })
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods
  const { filters, handleFilters, resetFilters } = useFiltersForm({ criterion: "score" })
  const {
    stages,
    modules,
    teams,
    scores,
    criteria,
    filterModules,
    isLoading: isLoadingOptions,
    setTeams,
    setIsLoading,
    handleLoadingOptions,
  } = useGetOptions()
  const [isTriggerFilter, setIsTriggerFilter] = useState(false)
  const courses = useAppSelector(state => state.course.courses)
  const isLoadingAssignments = useAppSelector(state => state.assignment.assignments.isLoading)
  const dispatch = useAppDispatch()
  const isLoading = isLoadingOptions || isLoadingAssignments
  const isErrors = Boolean(Object.keys(errors).length)

  async function getAssignments(data: IFilters) {
    setIsTriggerFilter(false)
    dispatch(assignmentsActions.resetAssignments())
    setIsLoading(true)
    try {
      dispatch(assignmentsActions.setDisableCheckbox(Boolean(filters.status) || Boolean(filters.mark_value)))
      const unitedFilters = { ...filters, ...data }
      const formedFilters = formingFilters(unitedFilters)
      const initFilters = { ...formedFilters, page: 1 }
      dispatch(assignmentsActions.setFilters(initFilters))
      dispatch(fetchAssignments(initFilters))
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const handleChooseCourse = async (event: AutocompleteEvent, course: OptionType | null) => {
    handleReset()
    if (!course || course === null) return
    handleFilters({ target: { value: course.id, name: "course" } })
    dispatch(assignmentsActions.setCourse(course))
    handleLoadingOptions(course)
  }

  const handleReset = () => {
    resetFilters()
    setIsTriggerFilter(false)
    dispatch(assignmentsActions.resetAssignments())
    setTeams(null)
    reset()
  }

  const handleChangeFilters = (event: { target: { value: string | number; name: string } }) => {
    if (event.target.name === "module" && event.target.value === filters.module) return
    const exceptionFields = ["criterion", "rule"]
    const isException = exceptionFields.includes(event.target.name)
    setIsTriggerFilter(prev => prev || !isException)
    handleFilters(event)
    if (["stage", "module"].includes(event.target.name)) {
      const currentFilters = { ...filters, [event.target.name]: event.target.value }
      dispatch(assignmentsActions.setFilteredTasks(currentFilters))
      handleFilters({ target: { value: "", name: "content_block" } })
    }
    if (event.target.name === "stage") {
      handleFilters({ target: { value: "", name: "module" } })
    }
  }

  const getVariant = isTriggerFilter ? Variant.outlined : Variant.contained
  const buttonLabel = isTriggerFilter ? "ПРИМЕНИТЬ ФИЛЬТР" : "ПОСМОТРЕТЬ ЗАДАНИЯ"

  return (
    <Styled.ShadowedSection>
      <Styled.Header>
        <Styled.Title variant="h4">Таблица заданий курса</Styled.Title>
      </Styled.Header>
      <Styled.ActionWrapper>
        <Styled.ActionLeftWrapper>
          <Autocomplete
            id="assignment__choise"
            size={Size.large}
            name={"course"}
            value={filters?.course}
            onChange={handleChooseCourse}
            onReset={handleReset}
            options={courses}
            label="Название курса"
            disabled={isLoading}
          />
          <Autocomplete
            id="assignment__stage"
            key={filters.course}
            size={Size.large}
            name={"stage"}
            value={filters?.stage}
            filterChange={handleChangeFilters}
            onReset={handleChangeFilters}
            // @ts-ignore
            options={stages}
            label="Этап"
            disabled={!filters?.course || isLoading}
          />
        </Styled.ActionLeftWrapper>
        <Styled.ActionRightWrapper>
          <Autocomplete
            id="assignment__module"
            key={`${filters.course}-${filters.stage}`}
            size={Size.large}
            name={"module"}
            value={filters?.module}
            filterChange={handleChangeFilters}
            // @ts-ignore
            options={filterModules(modules, Number(filters?.stage))}
            onReset={handleChangeFilters}
            label="Модуль"
            disabled={!filters?.stage || isLoading}
          />
          <Button
            variant={getVariant}
            disabled={!filters?.course || isLoading || isErrors}
            onClick={handleSubmit(getAssignments)}
          >
            {buttonLabel}
          </Button>
          <Styled.LoaderWrapper>{isLoading ? <CircularProgress size={26} /> : <span />}</Styled.LoaderWrapper>
        </Styled.ActionRightWrapper>
      </Styled.ActionWrapper>
      <FormProvider {...methods}>
        <AssignmentFilters
          handleFilters={handleChangeFilters}
          filters={filters}
          teams={teams}
          scores={scores}
          criteria={criteria}
          disable={!filters?.course || isLoading}
          setIsTriggerFilter={setIsTriggerFilter}
        />
      </FormProvider>
      <Styled.FormHelper variant="body1" fontWeight="small">
        {isTriggerFilter && "Чтобы отобразить задания по заданным параметрам, нажмите на кнопку «Применить фильтр»"}
      </Styled.FormHelper>
    </Styled.ShadowedSection>
  )
}

export { AssignmentFiltersSection }
