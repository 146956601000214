import React from "react"
import { Chip, Typography } from "@mui/material"

export const MemberChip = ({ label }: { label: string }) => {
  return (
    <Chip
      sx={{ backgroundColor: label === "moderator" ? "#FFA000" : "#3EBD44" }}
      label={
        <Typography variant={"caption"} fontWeight={"small"} sx={{ color: "#FFF" }}>
          {label === "moderator" ? "Модератор" : "Подписчик"}
        </Typography>
      }
    />
  )
}
