import { SendNotification } from "../../entities/Notification/ui/SendNotification"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
import Cookies from "js-cookie"

const SendGroupNotification = ({ users, title, courseId, isOpen, onClose }) => {
  async function createNotification(content) {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.post(`/notifications/notify_group/`, {
      course_id: courseId,
      users: users,
      title: title,
      content: content,
    })
  }

  return <SendNotification isOpen={isOpen} onClose={onClose} createNotification={createNotification} />
}

export default SendGroupNotification
