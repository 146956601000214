import React from "react"
import * as Styled from "./Landing.styles"
import { Grid } from "@mui/material"
import { useTheme } from "shared/context"
import ExperimentImage from "images/experiment.svg"
import EducationSpaceImage from "images/educational_space.svg"
import SimulatorImage from "images/simulators.svg"
import TeamImage from "images/team.svg"
import Labs from "images/lab_complexes.svg"
import ImageTechnopark from "images/technopark.svg"
import LowImageTechnopark from "images/technopark_low.svg"
import LowExperimentImage from "images/experiment_low.svg"
import LowSimulatorImage from "images/simulators_low.svg"
import LowTeamImage from "images/team_low.svg"
import LowEducationSpaceImage from "images/educational_space_low.svg"
import LowLabsImage from "images/lab_complexes_low.svg"
//@ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component"

const items = [
  { image: ImageTechnopark, text: "Спроектируйте лучшую модель цифрового технопарка", lowImage: LowImageTechnopark },
  { image: EducationSpaceImage, text: "Структурируйте образовательное пространство", lowImage: LowEducationSpaceImage },
  { image: ExperimentImage, text: "Добавьте эксперимент и работу <br/> с оборудованием", lowImage: LowExperimentImage },
  { image: SimulatorImage, text: "Симуляторы – <br/> массово и полезно", lowImage: LowSimulatorImage },
  { image: TeamImage, text: "Обеспечим командную работу <br/> над проектом!", lowImage: LowTeamImage },
  { image: Labs, text: "Лабораторные комплексы <br/> нового поколения", lowImage: LowLabsImage },
]

export const FunctionalOfPlatform = ({}) => {
  const theme = useTheme()
  return (
    <Styled.FunctionalOfPlatformMainDiv>
      <Styled.StyledTitle variant={"h2"} sx={{ color: "#313439" }}>
        Оцените удобство и возможности платформы <br /> для решения Ваших задач
      </Styled.StyledTitle>
      <Styled.MiddleTypography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
        Киберфизическая платформа Syncwoia обеспечивает интеграцию современных программ, содержания, форматов и
        лабораторных комплексов для реализации эффективных образовательных форматов погружения и развития в области
        современных технологий, перспективных отраслей промышленности.
      </Styled.MiddleTypography>
      <Grid
        container
        sx={{ [theme.breakpoints.down("sm")]: { alignItems: "center", justifyContent: "center" } }}
        display={"flex"}
        justifyContent={"space-between"}
        width={"auto"}
        spacing={8}
      >
        {items.map(e => (
          <Styled.StyledGrid item justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Styled.StyledSubtitle
              variant={"h3"}
              sx={{
                textAlign: "start",
                color: "#313439",
                height: "64px",
                marginBottom: theme.spacing(6),
                [theme.breakpoints.down("lg")]: { height: theme.spacing(30) },
                [theme.breakpoints.down("sm")]: { height: theme.spacing(11) },
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: e.text }}></div>
            </Styled.StyledSubtitle>
            {/*@ts-ignore*/}
            <Styled.FunctionalImage>
              <LazyLoadImage width={"100%"} src={e.image} placeholderSrc={e.lowImage} effect="blur" />
            </Styled.FunctionalImage>
          </Styled.StyledGrid>
        ))}
      </Grid>
    </Styled.FunctionalOfPlatformMainDiv>
  )
}
