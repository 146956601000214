import React from "react"
import { Route, Switch, Redirect, withRouter } from "react-router-dom"

// styles
import useStyles from "./styles"

// components
import { Header, Footer } from "widgets"

// pages
import Catalog from "../catalog/Catalog"
import CourseMainPage from "../course-main-page/CourseMainPage"
import Profile from "../profile/Profile"

// context
import { useUserState } from "shared/context"

function Layout(props) {
  const { isAuthenticated } = useUserState()
  const isAuth = isAuthenticated
  const classes = useStyles()

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route {...rest} render={props => (isAuth ? React.createElement(component, props) : <Redirect to={"/"} />)} />
    )
  }

  return (
    <div className={classes.root}>
      <Header variant={"shadow"} />
      <div className={classes.content}>
        <div className={classes.fakeToolbar} />
        <Switch>
          <PrivateRoute path="/app/profile" component={Profile} />
        </Switch>
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(Layout)
