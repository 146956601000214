import React, { useEffect, useRef } from "react"
import * as Styled from "./ui/Contacts.styles"
import { Typography } from "@mui/material"
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import RouteIcon from "@mui/icons-material/Route"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  props: {
    index: number
    location_url?: string
    phone?: string
    email?: string
    location?: string
    location_description?: string
  }
}

export const Contacts = ({ handleChangeRef, props }: IProps) => {
  const ref = useRef<any>(null)

  useEffect(() => {
    handleChangeRef(ref, props.index)
  }, [ref])

  return (
    <Styled.MainContactsDiv ref={ref}>
      {!!props.location_url && (
        <>
          <Styled.ContactsContainer>
            <Typography variant={"h1"}>Наши контакты</Typography>
            <Styled.ContactItemsContainer>
              {!!props.phone && (
                <Styled.ContactItem>
                  <PermPhoneMsgIcon />
                  <Styled.ContactContainer>
                    <Typography variant={"h3"}>Телефон</Typography>
                    <Typography variant={"body1"} fontSize={"large"} fontWeight={"medium"}>
                      <div dangerouslySetInnerHTML={{ __html: props.phone }}></div>
                    </Typography>
                  </Styled.ContactContainer>
                </Styled.ContactItem>
              )}
              {!!props.email && (
                <Styled.ContactItem>
                  <MailOutlineIcon />
                  <Styled.ContactContainer>
                    <Typography variant={"h3"}>Почта</Typography>
                    <Typography variant={"body1"} fontSize={"large"} fontWeight={"medium"}>
                      <div dangerouslySetInnerHTML={{ __html: props.email }}></div>
                    </Typography>
                  </Styled.ContactContainer>
                </Styled.ContactItem>
              )}
              {!!props.location && (
                <Styled.ContactItem>
                  <LocationOnOutlinedIcon />
                  <Styled.ContactContainer>
                    <Typography variant={"h3"}>Адрес</Typography>
                    <Typography variant={"body1"} fontSize={"large"} fontWeight={"medium"}>
                      <div dangerouslySetInnerHTML={{ __html: props.location }}></div>
                    </Typography>
                  </Styled.ContactContainer>
                </Styled.ContactItem>
              )}
              {!!props.location_description && (
                <Styled.ContactItem>
                  <RouteIcon />
                  <Styled.ContactContainer>
                    <Typography variant={"h3"}>Как добраться</Typography>
                    <Typography variant={"body1"} fontSize={"large"} fontWeight={"medium"}>
                      <div dangerouslySetInnerHTML={{ __html: props.location_description }}></div>
                    </Typography>
                  </Styled.ContactContainer>
                </Styled.ContactItem>
              )}
            </Styled.ContactItemsContainer>
          </Styled.ContactsContainer>
          <Styled.StyledIframe src={props.location_url} />
        </>
      )}
    </Styled.MainContactsDiv>
  )
}
