import styled from "@emotion/styled"
import ArrowReturnIcon from "images/icons/ArrowReturn"
import { ShadowedSection } from "shared/ui"
import { ReactNode } from "react"

interface IProps {
  title: string
  selector: ReactNode
  download?: ReactNode
  children?: ReactNode
  windowTab?: number | null | undefined
  setWindowTab?: (x: string, y: number) => void
  isBackButtonProp?: boolean
}

const OrganizationStatisticsSection = ({
  title,
  selector,
  download,
  children,
  windowTab,
  setWindowTab,
  isBackButtonProp = true,
}: IProps) => {
  const isBackButton = Boolean(windowTab === 1 && isBackButtonProp)
  return (
    <>
      {isBackButton && (
        <BackButton onClick={() => setWindowTab && setWindowTab("", 0)}>
          <ArrowReturnIcon />
          Вернуться к выбору вопросов
        </BackButton>
      )}
      <Section isBackButton={isBackButton}>
        <Header>
          <Title>{title}</Title>
          {selector}
          {download}
        </Header>
        <Content>{children}</Content>
      </Section>
    </>
  )
}

const Section = styled(ShadowedSection)<{ isBackButton: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${props => (props.isBackButton ? "16px" : "128px")};
  padding-bottom: 0;
  border-radius: 12px;  
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 32px;
`

const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 20.7px;
  letter-spacing: 0.2px;
  max-width: 327px;
  padding-block: 3px 17px;
  margin: unset;
`

const Content = styled.div`
  flex: 1;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 64px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  width: fit-content;
  color: #1565c0;
  transition: opacity 0.2s;
  cursor: pointer;

  & :first-child {
    margin-right: 16px;
  }
  &:hover {
    opacity: 0.7;
  }
`

export { OrganizationStatisticsSection }
