import * as Styled from "./Team.style.ts"
import { Button, IconButton } from "shared/ui"
import { NewUserIcon } from "images/icons/new_user.js"
import { EditIcon } from "images/icons/edit.js"
import { TrashIcon } from "images/icons/trash.js"
import { ExitIcon } from "images/icons/exit.js"

const Action = ({ children, onClick, color }) => {
  return (
    <IconButton color={color} onClick={onClick}>
      {children}
    </IconButton>
  )
}

const TeamActions = ({ setAction, isModerator }) => {
  const handleClick = value => {
    setAction(value)
  }

  return (
    <Styled.ActionWrapper>
      {isModerator && (
        <>
          <Button variant={"outlined"} sx={{ width: 234 }} onClick={() => handleClick("edit_roles")}>
            Изменить роли
          </Button>
          <Action onClick={() => handleClick("invite_user")} color={"inherit"}>
            <NewUserIcon />
          </Action>
          <Action onClick={() => handleClick("edit_team")} color={"inherit"}>
            <EditIcon />
          </Action>
          <Action onClick={() => handleClick("delete_team")} color={"accent"}>
            <TrashIcon />
          </Action>
        </>
      )}
      <Action onClick={() => handleClick("leave_team")} color={"inherit"}>
        <ExitIcon />
      </Action>
    </Styled.ActionWrapper>
  )
}

export default TeamActions
