import React, { useEffect, useRef, useState } from "react"
import useStyles from "./styles"
import chunkArray from "../Utils"
import Carousel from "react-material-ui-carousel"
import { Line } from "shared/ui"
import VideoGalleryItem from "./VideoGalleryItem"
import { Grid, Typography } from "@mui/material"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"

const VideoGallery = ({ e, handleChangeRef }) => {
  const classes = useStyles()
  const [chunkSize, setChunkSize] = useState(3) // Изначальное значение количества элементов в chunkArray
  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setChunkSize(2)
      } else if (window.innerWidth >= 960) {
        setChunkSize(1)
      } else if (window.innerWidth >= 0) {
        setChunkSize(1)
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const chunkedOptions = chunkArray(e.options, chunkSize)

  return (
    <div ref={ref} style={{ backgroundColor: e.backgroundColor }} className={classes.videoGalleryContainer}>
      {!!e.H2 && (
        <Typography variant={"h2"} sx={{ color: e.H2?.color }} className={classes.titleContainer}>
          {e.H2?.text}
        </Typography>
      )}
      <Carousel
        indicators={false}
        navButtonsProps={{ className: classes.navButtons, style: { backgroundColor: "transparent" } }}
        interval={8000}
        PrevIcon={<ArrowLeft style={{ color: e.arrowColor }} />}
        NextIcon={<ArrowRight style={{ color: e.arrowColor }} />}
        navButtonsAlwaysVisible={true}
      >
        {chunkedOptions.map((chunk, index) => (
          <Grid container key={index} className={classes.videoGalleryContentContainer}>
            {chunk.map((item, itemIndex) => (
              <VideoGalleryItem key={itemIndex} e={item} />
            ))}
          </Grid>
        ))}
      </Carousel>
    </div>
  )
}

export default VideoGallery
