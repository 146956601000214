export const ArrowDown = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ph:arrow-up-bold">
        <path
          id="Vector"
          d="M2.96843 8.46691C3.0381 8.39699 3.1209 8.34151 3.21206 8.30366C3.30322 8.2658 3.40097 8.24632 3.49968 8.24632C3.59839 8.24632 3.69612 8.2658 3.78729 8.30366C3.87845 8.34151 3.96125 8.39699 4.03093 8.46691L7.24905 11.685L7.24905 2.49753C7.24905 2.29862 7.32807 2.10786 7.46872 1.9672C7.60937 1.82655 7.80014 1.74753 7.99905 1.74753C8.19796 1.74753 8.38873 1.82655 8.52938 1.9672C8.67003 2.10786 8.74905 2.29862 8.74905 2.49753L8.74905 11.685L11.9684 8.46691C12.1093 8.32601 12.3004 8.24686 12.4997 8.24686C12.6989 8.24686 12.89 8.32601 13.0309 8.46691C13.1718 8.6078 13.251 8.7989 13.251 8.99816C13.251 9.19742 13.1718 9.38851 13.0309 9.52941L8.53093 14.0294C8.46125 14.0993 8.37845 14.1548 8.28729 14.1927C8.19613 14.2305 8.09839 14.25 7.99968 14.25C7.90097 14.25 7.80323 14.2305 7.71206 14.1927C7.6209 14.1548 7.5381 14.0993 7.46843 14.0294L2.96843 9.52941C2.89851 9.45973 2.84303 9.37694 2.80517 9.28577C2.76732 9.19461 2.74783 9.09687 2.74783 8.99816C2.74783 8.89945 2.76732 8.80171 2.80517 8.71055C2.84303 8.61938 2.89851 8.53659 2.96843 8.46691Z"
          fill="#1565C0"
        />
      </g>
    </svg>
  )
}
