import { useCallback, useMemo } from "react"
import { useHistory } from "react-router-dom"
import useStyles from "./styles"
import defaultBackground from "../../../images/Event_SPb_default.gif"
import { Chip } from "@mui/material"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import calendarIcon from "../../../images/icons/calendar.svg"

export const EventItem = props => {
  var classes = useStyles()

  const history = useHistory()
  const goToEvents = useCallback(
    () =>
      history.push(
        props.org_slug ? `/organization/${props.org_slug}/event/${props.post.slug}` : `/event/${props.post.slug}`
      ),
    [history]
  )

  const image = useMemo(() => {
    return JSON.parse(props.post.template_props)
  }, [props])

  const n_users = props.post.participants_num
  const bottomColor = "#FFFFFF"

  function handleOnClick() {
    if (props.post.visibility === "visible") goToEvents()
  }

  function stringifyDates(start, finish) {
    let startDate = new Date(start)
    let finishDate = new Date(finish)
    let format = new Intl.DateTimeFormat("ru-RU", { month: "long", day: "numeric" })
    if (start === finish) {
      return format.format(startDate)
    }
    return `${format.format(startDate)} - ${format.format(finishDate)}`
  }

  const displayingScore = score => (score % 1 ? score.toFixed(2) : score)

  return (
    <div
      style={{
        backgroundImage: `url(${image.header_image || defaultBackground})`,
      }}
      className={props.post.visibility === "visible" ? classes.container : classes.inactiveContainer}
      onClick={handleOnClick}
    >
      <div className={classes.containerWrapper}>
        <Chip label={"Событие"} className={classes.typeContainer} />
        {!!props.post.progress ? (
          <p style={{ color: bottomColor }}>{`Пройдено: ${displayingScore(props.post.progress.score)}/${
            props.post.max_score
          }`}</p>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 8 }}>
            <AccountCircleRoundedIcon style={{ color: bottomColor }} />
            <p style={{ color: bottomColor }}>{n_users}</p>
            <img
              src={calendarIcon}
              alt={calendarIcon}
              style={{ color: "#FFFFFF", marginLeft: "20px", height: "20px", width: "20px" }}
            />
            <p style={{ color: bottomColor }}>{stringifyDates(props.post.start, props.post.finish)}</p>
          </div>
        )}
      </div>
    </div>
  )
}
