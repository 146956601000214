import { TableCell, Typography } from "@mui/material"
import { ISurveyParticipant } from "entities/Organization/model/StatisticsContext.types"
import { IQuestion } from "entities/Organization/model/useStatisticsDataList"
import { wordMapper } from "shared/local"

const SurveyRow = ({ participant, questions }: { participant: ISurveyParticipant; questions: IQuestion[] }) => {
  const { submitter, answers } = participant
  const getValue = (question: IQuestion) => {
    const participantInfo = answers.find(
      (answer: { answer: string; question: number }) => answer.question === question.id
    )
    return participantInfo?.answer || "-"
  }

  return (
    <>
      <TransparentCell value={submitter?.full_name} />
      <TransparentCell value={submitter?.email} />
      <TransparentCell value={wordMapper(submitter?.status)} />
      <TransparentCell value={submitter?.category} />
      <TransparentCell value={submitter?.is_active ? "Активен" : "Не активен"} />
      {questions.map((question: IQuestion) => (
        <TransparentCell value={getValue(question)} />
      ))}
    </>
  )
}

const TransparentCell = ({ value }: { value: string }) => (
  <TableCell align="center" sx={{ maxWidth: 200, overflowWrap: "break-word" }}>
    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
      {value || "-"}
    </Typography>
  </TableCell>
)
export { SurveyRow }
