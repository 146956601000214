import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance as axios} from "shared/api/axiosInstance";


export const fetchCertificates = createAsyncThunk(
    'certificate/fetchCertificates',
    async () => {
        const response = await axios.get("/content/certificates/get-certificates/")
        return response.data
    }
)
