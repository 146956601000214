import React, { useState } from "react"
import * as Styled from "./Landing.styles"
import { Typography } from "@mui/material"
import Image from "images/add.svg"
import { useTheme } from "shared/context"
import { Button } from "shared/ui"
import { RequestsModal } from "widgets"

// @ts-nocheck
export const RequestForUsing = () => {
  const theme = useTheme()
  const [openModal, setIsOpenModal] = useState(false)

  return (
    <Styled.RequestForUsingMainDiv>
      <RequestsModal isOpenModal={openModal} setIsOpenModal={(state: boolean) => setIsOpenModal(state)} />
      <Styled.DescriptionForUsingDiv>
        <Styled.StyledTitle variant={"h2"} sx={{ color: theme.palette.common.white }}>
          Свяжитесь с нами, чтобы попробовать!
        </Styled.StyledTitle>
        <Typography
          sx={{
            color: theme.palette.common.white,
            [theme.breakpoints.down("xs")]: { fontSize: theme.spacing(4) },
          }}
          variant={"body1"}
          fontWeight={"medium"}
          fontSize={"medium"}
        >
          Начните использовать современные инструменты формирования, продвижения и вовлечения школьников и студентов.
          <p>Присоединяйтесь к существующим программам партнеров или создавайте свои.</p>
        </Typography>
        {/*@ts-ignore*/}
        <Button
          onClick={() => setIsOpenModal(true)}
          variant={"outlined"}
          color={"additional"}
          sx={{ border: "none", [theme.breakpoints.down("xs")]: { width: "100%" } }}
        >
          Отправить заявку
        </Button>
      </Styled.DescriptionForUsingDiv>
      {/*@ts-ignore*/}
      <Styled.ImageRequestForUsing src={Image} alt="Description" />
    </Styled.RequestForUsingMainDiv>
  )
}
