import React, { useEffect, useState } from "react"
import { AdminQuestionItem, fetchAllQuestions } from "../../entities/AdaptiveTest"
import { JsonEditor } from "../../shared/assets"
import { Button } from "../../shared/ui"
import { CreateQuestion } from "../../features/AdaptiveTest/CreateQuestion"

export const AdaptiveTestEditor = () => {
  const [questions, setQuestions] = useState<adminQuestions[]>([])

  const loadQuestions = () => {
    fetchAllQuestions().then(res => {
      setQuestions([...res.data])
    })
  }

  useEffect(() => {
    loadQuestions()
  }, [])

  function deleteQuestionItem(id: number) {
    questions.filter(e => e.id != id)
    setQuestions([...questions])
  }

  return (
    <div style={{ width: "100%" }}>
      {questions.map((e, index) => (
        <AdminQuestionItem deleteQuestionItem={deleteQuestionItem} question={e} key={e.id as number} />
      ))}
      <CreateQuestion reload={loadQuestions} />
    </div>
  )
}
