import React, { useState, useEffect, useRef, useCallback } from "react"
import { Grid, Typography, Tabs, Tab as MuiTab, IconButton, Dialog } from "@mui/material"
import Carousel from "react-material-ui-carousel"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import buttonUpDefault from "../../images/icons/button_up_default.png"
import buttonUpHover from "../../images/icons/button_up_hover.png"
import buttonUpPressed from "../../images/icons/button_up_pressed.png"

import { styled } from "@mui/material/styles"
import useStyles from "./styles"
import { axiosInstance, axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

import { Footer, Questionnaire, MainScreen, Header, LocationBlock } from "widgets"
import { Review } from "entities/Review/Review"
import "./GroteskPro.css"
import emptyAvatar from "../../images/empty-avatar.png"

import { useUserState } from "shared/context"
import ReactVisibilitySensor from "react-visibility-sensor"
import { zeroPad, fyShuffle } from "shared/util"

import { sortStages } from "entities/Course"
import { Button } from "../../shared/ui"
import { RequestOnCourse } from "../../widgets/RequestOnCourse/RequestOnCourse"

const Tab = styled(MuiTab)({
  fontSize: 20,
  fontWeight: 400,
  color: "white",
  margin: 25,
  "&.Mui-selected": { color: "#FA5D55" },
})

function stringifyDates(start, finish) {
  let startDate = new Date(start)
  let finishDate = new Date(finish)
  let format = new Intl.DateTimeFormat("ru-RU", { month: "long", day: "numeric", year: "numeric" })
  if (startDate.getFullYear() === finishDate.getFullYear()) {
    let formatSecond = new Intl.DateTimeFormat("ru-RU", { month: "long", day: "numeric" })
    return `${formatSecond.format(startDate)} - ${format.format(finishDate)}`
  }
  return `${format.format(startDate)} - ${format.format(finishDate)}`
}

const EventMainPage = props => {
  const classes = useStyles()
  const [tab, setTab] = useState(0)
  const [content, setContent] = useState(null)
  const [helperText, setHelperText] = useState("")
  const [linkText, setLinkText] = useState("")
  const [permission, setPermission] = useState(null)
  const [openEnroll, setOpenEnroll] = useState(false)
  const [formValues, setFormValues] = useState([])
  const [reviews, setReviews] = useState([])
  const [teachers, setTeachers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [upButtonOpacity, setUpButtonOpacity] = useState(0)
  const [organization, setOrganization] = useState(null)
  const { isAuthenticated, user, login } = useUserState()
  const [isTryedEnroll, setIsTryedEnroll] = useState(false)
  const ref_token = useRef(localStorage.getItem("token_ref"))

  const name = props.match.params.name
  const aboutRef = useRef()
  const stagesRef = useRef()
  const partnersRef = useRef()
  const teachersRef = useRef()
  const reviewsRef = useRef()

  useEffect(() => {
    window.addEventListener("scroll", () => setUpButtonOpacity(window.scrollY === 0 ? 0 : 1))
  }, [])

  useEffect(() => {
    if (permission && isTryedEnroll) {
      return handleEnrollButton(permission)
    }
    if (permission === false && isTryedEnroll) {
      return handleEnrollButton(permission)
    }
  }, [ref_token.current, permission, user.state, isAuthenticated])

  useEffect(() => {
    setTimeout(() => {
      setHelperText("")
      ref_token.current = localStorage.getItem("token_ref")
      if (ref_token.current) {
        setIsLoading(true)
        getLogo()
        axios
          .get(`/content/actions/courses/my_status/`, {
            params: {
              course__slug: name,
            },
          })
          .then(r => {
            axios
              .get(`/content/courses/`, {
                params: {
                  slug: name,
                },
              })
              .catch(err => {
                if (err.response.status === 404) {
                  window.location.href = "/not-found"
                }
              })
              .then(q => {
                axios.get(`/content/courses/${q.data[0].id}/`).then(res => {
                  let data = res.data
                  let status = r.data.status
                  data.presets = JSON.parse(data.template_props)
                  data.info_fields = data.info_fields.filter(e => !!e.visible)
                  setContent(data)
                  if (!data.is_registration_opened && data.registration_type === "manual") {
                    setHelperText("В данный момент заявки на участие не принимаются")
                  }
                  if (
                    data.registration_type === "auto" &&
                    (new Date().getTime() < new Date(data?.registration_start).getTime() ||
                      new Date().getTime() > new Date(data?.registration_finish).getTime())
                  ) {
                    setHelperText("В данный момент заявки на участие не принимаются")
                  }
                  setPermission(checkPermission(status))
                  setIsLoading(false)
                  if (status === "requested") {
                    if (res.data.type === "webinar") {
                      setHelperText(
                        "Вы подали заявку на эту встречу. Результаты рассмотрения заявки вы можете увидеть во вкладке "
                      )
                      setLinkText("Мои встречи")
                    } else setHelperText("Заявка рассматривается")
                    setPermission(status)
                  }
                  if (data.presets?.teachers?.length > 0) {
                    setTeachers([...data.presets.teachers])
                  } else setTeachers(data.teachers)
                })
              })
          })
          .catch(() => {
            setHelperText("Упс... что-то пошло не так. Пожалуйста, свяжитесь с администрацией.")
            setIsLoading(false)
          })
        axios
          .get("/content/reviews/", {
            params: {
              course__slug: name,
              is_moderated: "True",
            },
          })
          .then(r => {
            axios
              .get("/content/free_reviews/", {
                params: {
                  course__slug: name,
                },
              })
              .then(p => {
                setReviews(fyShuffle([...r.data, ...p.data].filter(e => !!e.text)))
              })
          })
      } else {
        delete axios.defaults.headers.common["Authorization"]
        getLogo()
        axios
          .get(`/content/courses/`, {
            params: {
              slug: name,
            },
          })
          .then(q => {
            axios.get(`/content/courses/${q.data[0].id}/`).then(res => {
              let data = res.data
              data.presets = JSON.parse(data.template_props)
              setContent(data)
              if (!data.is_registration_opened && data.registration_type === "manual") {
                setHelperText("В данный момент заявки на участие не принимаются")
              }
              console.log(new Date(data.registration_start))
              if (
                data.registration_type === "auto" &&
                (new Date().getUTCDate() < new Date(data?.registration_start) ||
                  new Date().getUTCDate() > new Date(data?.registration_finish))
              ) {
                setHelperText("В данный момент заявки на участие не принимаются")
              }
              if (data.presets?.teachers?.length > 0) {
                setTeachers([...data.presets.teachers])
              } else setTeachers(data.teachers)
            })
          })
        axios
          .get("/content/reviews/", {
            params: {
              course__slug: name,
              is_moderated: "True",
            },
          })
          .then(r => {
            axios
              .get("/content/free_reviews/", {
                params: {
                  course__slug: name,
                },
              })
              .then(p => {
                setReviews(fyShuffle([...r.data, ...p.data].filter(e => !!e.text)))
              })
          })
      }
    }, 1000)
  }, [user.state])

  function getLogo() {
    if (!!props.match.params.organization_slug) {
      axios.get(`/organizations/`, { params: { slug: props.match.params.organization_slug } }).then(res => {
        setOrganization(res.data[0])
      })
    }
  }

  const goToPayments = () => {
    axiosInstance
      .post("/content/actions/participants/process_payment/", { course: content.id })
      .then(res => {
        window.open(res.data.payment_url, "_blank")
        const interval = setInterval(() => {
          axiosInstance
            .get(`/profiles/my_payments/?payment_id=${Number(res.data.payment_id)}`)
            .then(p => {
              if (p.data[0].status === "CONFIRMED" || p.data[0].status === "AUTHORIZED") {
                axios
                  .get(`/content/actions/courses/my_status/`, {
                    params: {
                      course__slug: name,
                    },
                  })
                  .then(res => {
                    setPermission(res.data.status)
                  })
                clearInterval(interval)
              }
            })
            .catch(err => {})
        }, 5000)
      })
      .catch(err => {})
  }

  const scrollToStart = useCallback(() => {
    setTab(0)
    requestAnimationFrame(() => {
      window.scrollTo(0, 0)
    })
  }, [])

  const handleEnrollButton = isRedirect => {
    if (permission === "requested") return
    if (isRedirect === true) return redirectTrajectory()
    if ((isAuthenticated || user.state) && content.payment_status === "paid") {
      goToPayments()
    }
    if (!isAuthenticated && content.payment_status === "paid") {
      login.setOpen(true)
      setIsTryedEnroll(true)
    }
    if (content.payment_status !== "paid") {
      if (isAuthenticated || user.state) {
        content.use_info ? !isRedirect && setOpenEnroll(true) : requestAccess()
      } else {
        login.setOpen(true)
        setIsTryedEnroll(true)
      }
    }
  }

  function redirectTrajectory() {
    window.location.href = `/event/${name}/stages`
  }

  function checkPermission(status) {
    return status === "in_progress" || status == "mentor" || status == "teacher" || status == "moderator"
  }

  function requestAccess() {
    if (!ref_token.current) {
      setHelperText("Пожалуйста, зарегистрируйтесь или войдите в аккаунт.")
      return
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    let payload = { course: content.id }
    if (!!content.use_info) {
      payload.info = JSON.stringify(formValues)
    }
    axios
      .put(`/content/actions/participants/request_access/`, payload)
      .then(res => {
        let data = { course: content.id, info: [...formValues.map(e => ({ question: e.id, answer: e.value }))] }
        axios.post("/content/actions/participants/submit_info/", data).then(res => {
          if (content.access_type === "public") {
            axios
              .get(`/content/actions/courses/my_status/`, {
                params: {
                  course__slug: name,
                },
              })
              .then(res => {
                setPermission(res.data.status)
              })
          }
          if (content.type === "webinar") {
            setHelperText(
              "Вы подали заявку на эту встречу. Результаты рассмотрения заявки вы можете увидеть во вкладке "
            )
            setLinkText("Мои встречи")
          } else setHelperText("Ваша заявка отправлена. Спасибо!")
        })
      })
      .catch(err => {
        setHelperText("Упс... что-то пошло не так. Пожалуйста, свяжитесь с администрацией.")
      })
    setOpenEnroll(false)
  }

  function visibleTab() {
    setTab(0)
  }

  const scrollWithOffset = ref => {
    const yOffset = -100
    const yPosition = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: yPosition, behavior: "smooth" })
  }

  const changeTab = (event, newValue) => {
    setTab(newValue)
    switch (newValue) {
      case 0:
        return scrollWithOffset(aboutRef)
      case 1:
        return scrollWithOffset(stagesRef)
      case 2:
        return scrollWithOffset(partnersRef)
      case 3:
        return scrollWithOffset(teachersRef)
      case 4:
        return scrollWithOffset(reviewsRef)
      default:
        return
    }
  }

  if (!content) return null

  return (
    <div style={{ backgroundColor: "#F9F9F9", display: "flex", flexDirection: "column" }}>
      <Header logo={organization?.logo} slug={organization?.slug} history={props.history} />
      <MainScreen
        trajectoryButtonText={content.presets.trajectoryButtonText || undefined}
        registrationButtonText={content.presets.registrationButtonText || undefined}
        course_id={content.id}
        paid={content.payment_status === "paid"}
        price={content.price}
        type={content?.type}
        e={content.presets.main_screen}
        linkText={linkText}
        isLoading={isLoading}
        badge={content?.badge}
        handleEnrollButton={() => handleEnrollButton(false)}
        helperText={helperText}
        permission={permission === "requested" ? false : permission}
        name={name}
      />
      <IconButton
        disableRipple
        style={{
          position: "fixed",
          right: "2%",
          bottom: "10%",
          zIndex: 10,
          cursor: "pointer",
          opacity: upButtonOpacity,
        }}
      >
        <img
          src={buttonUpDefault}
          onMouseOver={e => (e.currentTarget.src = buttonUpHover)}
          onMouseOut={e => (e.currentTarget.src = buttonUpDefault)}
          onMouseDown={e => (e.currentTarget.src = buttonUpPressed)}
          onMouseUp={e => (e.currentTarget.src = buttonUpHover)}
          onClick={() => {
            setTab(0)
            window.scrollTo(0, 0)
          }}
        />
      </IconButton>
      <ReactVisibilitySensor onChange={visibleTab}>
        <Tabs
          value={tab}
          onChange={changeTab}
          aria-label="event-tabs"
          textColor="primary"
          style={{ backgroundColor: "#313439" }}
          TabIndicatorProps={{ style: { height: 10, backgroundColor: "#FA5D55" } }}
          className={classes.EventPageMenuConteiner}
        >
          {!!content.presets?.description_block?.visibility && (
            <Tab label={content.presets?.description_block?.title || "О проекте"} value={0} />
          )}
          {!!content.presets?.calendar_block?.visibility && (
            <Tab label={content.presets?.calendar_block?.title || "КАЛЕНДАРЬ"} value={1} />
          )}
          {!!content.presets?.partners_block?.visibility && (
            <Tab label={content.presets?.partners_block?.title || "ПАРТНЕРЫ"} value={2} />
          )}
          {!!content.presets?.team_of_project_block?.visibility && (
            <Tab label={content.presets?.team_of_project_block.title || "КОМАНДА"} value={3} />
          )}
          {!!content.presets?.review_block?.visibility && (
            <Tab label={content.presets?.review_block.title || "ОТЗЫВЫ"} value={4} />
          )}
        </Tabs>
      </ReactVisibilitySensor>
      <div className={classes.EventPageContentConteiner}>
        {!!content.presets?.description_block?.visibility && (
          <Grid container spacing={0} className={classes.EventPageAboutConteiner}>
            <Grid item xs={3} className={classes.EventPageAboutTitle}>
              <Typography variant="h2" ref={aboutRef}>
                {content.presets?.description_block.title || "О проекте"}
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.EventPageAboutText}>
              <div dangerouslySetInnerHTML={{ __html: content.presets?.description_block.text || "" }} />
            </Grid>
            <Grid item xs={12} className={classes.EventPageAboutImage}>
              <LazyLoadImage
                src={content.presets?.description_block.image}
                placeholderSrc={content.presets?.description_block.image}
                effect="blur"
                className={classes.EventPageAboutImg}
              />
            </Grid>
          </Grid>
        )}
        {!!content.presets?.calendar_block?.visibility && (
          <>
            <Typography variant="h2" ref={stagesRef} className={classes.EventPageH2}>
              {content.presets?.calendar_block?.title || "Календарь проекта"}
            </Typography>
            <div className={classes.EventPageCalendar}>
              <Grid
                container
                spacing={4}
                style={{ borderBottom: "1px solid #FA5D55" }}
                className={classes.EventPageCalendarGrid}
              >
                {content.stages &&
                  content.stages.sort(sortStages).map(stage => (
                    <>
                      <Grid
                        item
                        container
                        xs={3}
                        alignItems="center"
                        style={{
                          borderTop: "1px solid #FA5D55",
                          paddingTop: 30,
                          paddingBottom: 30,
                        }}
                      >
                        <Typography style={{ fontFamily: "GroteskPro", color: "#FA5D55", fontSize: 54 }}>
                          {zeroPad(content.stages.indexOf(stage) + 1)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={5}
                        alignItems="center"
                        style={{
                          borderTop: "1px solid #FA5D55",
                          paddingTop: 30,
                          paddingBottom: 30,
                        }}
                      >
                        <Typography style={{ fontSize: "1em" }}>{stage.description}</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={4}
                        alignItems="center"
                        style={{
                          borderTop: "1px solid #FA5D55",
                          paddingTop: 30,
                          paddingBottom: 30,
                        }}
                      >
                        <Typography style={{ fontSize: "1em", fontWeight: 700 }}>
                          {stringifyDates(stage.start, stage.finish)}
                        </Typography>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </div>
          </>
        )}
        {content.type === "webinar" && !!content?.presets?.location_block?.visibility && (
          <LocationBlock item={content.presets.location_block} />
        )}
        {!!content.presets?.partners_block?.visibility && (
          <Typography variant="h2" ref={partnersRef} className={classes.EventPageH2}>
            {content.presets?.partners_block?.title || "Партнеры"}
          </Typography>
        )}
      </div>
      {!!content.presets?.partners_block?.visibility && (
        <div className={classes.EventPagePartners}>
          {content.affiliations_info &&
            content.affiliations_info
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(e => (
                <div className={classes.EventPageLogoPartner}>
                  <a href={e.website} target={"_blank"}>
                    <img
                      src={e.logo_negative}
                      onMouseOver={event => (event.currentTarget.src = e.logo)}
                      onMouseOut={event => (event.currentTarget.src = e.logo_negative)}
                      style={{ height: "100%", display: "block", margin: "auto" }}
                    />
                  </a>
                </div>
              ))}
        </div>
      )}
      <div className={classes.EventPageContentConteiner}>
        <Grid container spacing={0} className={classes.EventPageTeamConteiner}>
          {!!content.presets?.team_of_project_block?.visibility && (
            <>
              <Grid item xs={3} className={classes.EventPageTeamTitle}>
                <Typography variant="h2" ref={teachersRef}>
                  {content.presets?.team_of_project_block.title || "Команда проекта"}
                </Typography>
              </Grid>
              <Grid item container spacing={16} xs={9} className={classes.EventPageTeam}>
                {teachers &&
                  teachers
                    .sort((a, b) => a.last_name.localeCompare(b.last_name))
                    .map(t => (
                      <Grid item container xs={4} gap={6}>
                        <img
                          src={t.avatar || emptyAvatar}
                          width="100%"
                          style={{
                            borderRadius: 4,
                            objectFit: "contain",
                            objectPosition: "top",
                          }}
                        />
                        <Typography variant="h3" fontWeight={700}>{`${t.last_name} ${t.first_name}`}</Typography>
                        <Typography variant="body1">{t.about}</Typography>
                      </Grid>
                    ))}
              </Grid>
            </>
          )}
          {!!content.presets?.review_block?.visibility && (
            <>
              <Grid item xs={3} className={classes.EventPageAboutTitle}>
                <Typography variant="h2" ref={reviewsRef}>
                  {content.presets?.review_block.title || "Отзывы"}
                </Typography>
              </Grid>
              <Grid item xs={9} className={classes.EventPageReview}>
                <Carousel
                  autoPlay={false}
                  animation="slide"
                  height={480}
                  duration={800}
                  navButtonsAlwaysInvisible
                  indicatorIconButtonProps={{
                    style: {
                      margin: 5,
                      padding: 1,
                      color: "transparent",
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    },
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {
                      color: "#FA5D55",
                    },
                  }}
                  indicatorContainerProps={{
                    style: {
                      marginTop: 24,
                      textAlign: "left",
                    },
                  }}
                >
                  {reviews.map(rev => (
                    <Review review={rev} />
                  ))}
                </Carousel>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      {!!content.presets?.footer_block.visibility && (
        <div
          style={{ backgroundImage: `url(${content.presets.footer_block.image})` }}
          className={classes.EventPageBottomConteiner}
        >
          <div style={{ backgroundColor: "#FA5D55" }} className={classes.EventPageBottomBlock}>
            <div className={classes.EventPageBottomGrid}>
              <div dangerouslySetInnerHTML={{ __html: content.presets.footer_block.text || "" }} />
              {!permission && helperText === "" && (
                <Button variant="outlined" onClick={() => handleEnrollButton()} color={"accent"} height={"large"}>
                  ПРИСОЕДИНИТЬСЯ
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
      {content.use_info && (
        <RequestOnCourse
          open={openEnroll}
          setOpen={setOpenEnroll}
          requestAccess={requestAccess}
          setFormValues={setFormValues}
          formValues={formValues}
          content={content}
        />
      )}
    </div>
  )
}

export default EventMainPage
