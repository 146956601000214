import React from "react"
import { Tooltip } from "shared/ui"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import HelpIcon from "@mui/icons-material/Help"
import { TooltipTheme } from "./Tooltip/Tooltip"
interface IProps {
  text: string | React.ReactNode
  theme?: TooltipTheme
  size?: number
}

const InfoTooltip = ({ text, theme = "default", size = 20 }: IProps) => {
  return (
    <Tooltip text={text} theme={theme}>
      {theme === "default" && <HelpOutlineIcon sx={{ opacity: "0.7" }} />}
      {theme === "dark" && <HelpIcon sx={{ width: size, height: size }} />}
    </Tooltip>
  )
}

export default InfoTooltip
