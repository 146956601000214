import styled from "@emotion/styled"
import { useState } from "react"
import { Modal, TextField, Checkbox, Button } from "shared/ui"
import { Typography } from "@mui/material"
import { Variant } from "shared/ui/styles.const"

interface IProps {
  isOpen: boolean
  onClose: () => void
  onReject: (x: "failed" | "banned", y?: string) => void
}

const RejectParticipantModal = ({ isOpen, onClose, onReject }: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [feedback, setFeedback] = useState<string>("")
  const [isChecked, setIsChecked] = useState(false)
  const toggleChecked = () => setIsChecked(prev => !prev)
  const status = isChecked ? "banned" : "failed"

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      onReject(status, feedback ? feedback : undefined)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={"Причина отклонения заявки"} width={876} height={600}>
      <Container>
        <Typography>Пожалуйста, укажите информацию, чтобы пользователь смог правильно переподать заявку.</Typography>
        <TextWrapper>
          <TextField
            label="Текст уведомления"
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
            variant={Variant.outlined}
            width={800}
            height={68}
            disabled={isLoading}
            multiLine
            disableTooltip
          />
        </TextWrapper>
        <CheckboxWrapper>
          <Checkbox
            isChecked={isChecked}
            isDisabled={isLoading}
            onChange={toggleChecked}
            label="Заблокировать пользователя на этом мероприятии"
          />
        </CheckboxWrapper>
        {isChecked && <Warrning>Пользователь не сможет переподать заявку на данное мероприятие.</Warrning>}
      </Container>
      <ButtonWrapper>
        <div></div>
        <Button onClick={onSubmit} variant={"contained"} disabled={isLoading}>
          Подтвердить
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const Container = styled("div")(() => ({
  minHeight: "450px",
  display: "flex",
  flexDirection: "column",
}))

const TextWrapper = styled.div`
  margin-top: 52px;
`

const CheckboxWrapper = styled.div`
  margin-top: 18px;
`

const ButtonWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "auto",
}))

const Warrning = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  color: #d82e2e;
`

export { RejectParticipantModal }
