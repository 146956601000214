import React, { useState } from "react"
import { Button, Modal } from "../../shared/ui"
import { styled, Typography } from "@mui/material"
import { removeModule } from "../../entities/Module/api/module"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch"

export const DeleteModule = ({
  openModal,
  closeModal,
}: {
  openModal: { module_id: number; stage_id: number } | null
  closeModal: () => void
}) => {
  const dispatch = useAppDispatch()

  function handleClickDelete() {
    if (!!openModal) {
      dispatch(removeModule({ module_id: openModal.module_id, stage_id: openModal.stage_id })).then(() => {
        closeModal()
      })
    }
  }

  function handleClickCancel() {
    closeModal()
  }

  return (
    <Modal size={"small"} title={"Удаление модуля"} isOpen={!!openModal} onClose={handleClickCancel}>
      <ContentWrapper>
        <Typography>Вы действительно хотите удалить модуль? Отменить это действие будет невозможно.</Typography>
        <ButtonsContainer>
          <Button onClick={handleClickCancel}>Отменить</Button>
          {/*@ts-ignore*/}
          <Button onClick={handleClickDelete} color={"accent"} variant={"contained"}>
            Подтвердить
          </Button>
        </ButtonsContainer>
      </ContentWrapper>
    </Modal>
  )
}

const ContentWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%",
}))

const ButtonsContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(20),
  marginLeft: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
}))
