import styled from "@emotion/styled"
import SendSingleNotification from "features/Notifications/SendSingleNotification"
import { useState } from "react"
import { IconButton, Tooltip } from "shared/ui"
import { NotificationIcon } from "entities/Organization/ui/styles/PaticipantsTable.styles"
import { useAppSelector } from "shared/hooks/useAppSelector"
import {
  IPersonal, IRequestsAndStatusesParticipant,
  ISurveyParticipant,
  StatisticsVariants,
} from "entities/Organization/model/StatisticsContext.types"
import { badgeInfo, getBadgeIcon } from "entities/Organization/lib/getBadgeIcon"

interface IProps {
  participant: ISurveyParticipant | IPersonal | IRequestsAndStatusesParticipant
  onChangeTab?: (e: string) => void
  isBadges: boolean
  type: StatisticsVariants
}
const OrganizationStatisticsActions = ({ participant, onChangeTab, isBadges, type }: IProps) => {
  const course = useAppSelector(state => state.course.course)
  const [openDialog, setOpenDialog] = useState<false | { user: number; title: string }>(false)

  const userId = (type === "requests" || type === "statuses") ? (participant as IRequestsAndStatusesParticipant).user_id :
    type === "survey" ? (participant as ISurveyParticipant).submitter.user_id : (participant as IPersonal).user_id;

  function handleDialog() {
    if (!openDialog) {
      const params = {
        title: course?.title || "",
        user: userId,
      }
      setOpenDialog(params)
    } else {
      setOpenDialog(false)
    }
  }

  const handleClick = () => {
    const email = (type === "requests" || type === "statuses") ?
      (participant as IRequestsAndStatusesParticipant).email :
        (type === "survey") ?
      (participant as ISurveyParticipant).submitter?.email :
      (participant as IPersonal).submitter?.email;
    onChangeTab && onChangeTab(email);
  }
  const getParticipantId = () => {
    return (type === "requests" || type === "statuses")
      ? (participant as IRequestsAndStatusesParticipant)?.user_id
      : type === "survey"
      ? (participant as ISurveyParticipant).submitter?.user_id
      : (participant as IPersonal).submitter?.user_id;
  }
  const getParticipantStatusBadge = () => {
    return (type === "requests" || type === "statuses")
      ? (participant as IRequestsAndStatusesParticipant)?.status_badge
      : type === "survey"
      ? (participant as ISurveyParticipant).submitter?.status_badge
      : (participant as IPersonal).submitter?.status_badge
  }
  return (
    <>
      <SendSingleNotification
        user={openDialog && openDialog?.user}
        title={openDialog && openDialog?.title}
        isOpen={openDialog}
        onClose={handleDialog}
      />
      <ActionWrapper>
        <IconButton
          id={`notification-${getParticipantId()}`}
          color={"inherit"}
          size={"medium"}
          onClick={handleDialog}
          disabled={false}
        >
          <NotificationIcon />
        </IconButton>
        {isBadges && <Badge status={getParticipantStatusBadge()} onClick={handleClick} />}
      </ActionWrapper>
    </>
  )
}

const ActionWrapper = styled.div`
  padding-left: 8px;
  margin-right: -36px;
  max-width: 98px;
  display: flex;
  align-items: center;
  gap: 16px;
`

const Badge = ({ status, onClick }: { status: 1 | 2 | 3; onClick: () => void }) => {
  const isActive = status === 2

  return (
    <Tooltip text={badgeInfo[status]} theme={"dark"}>
      <BadgeWrapper isActive={isActive} onClick={() => isActive && onClick()}>
        {getBadgeIcon(status)}
      </BadgeWrapper>
    </Tooltip>
  )
}

const BadgeWrapper = styled.div<{ isActive: boolean }>`
  height: 32px;
  transition: opacity 0.2s;
  cursor: ${props => props.isActive && "pointer"};

  &:hover {
    opacity: ${props => props.isActive && "0.7"};
  }
`

export { OrganizationStatisticsActions }
