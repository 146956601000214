import React, {useEffect, useState} from 'react';
import image from "../../images/icons/infoTestType.svg"
import {Tooltip} from "@mui/material";
import useStyles from "./styles";

export const TestTooltip = (props) => {

    const classes = useStyles();

    const [title, setTitle] = useState("")

    useEffect(()=>{
        switch(props.type){
            case "choice":
                return setTitle(
                    "Выберите все подходящие варианты из списка")
            case "single-choice":
                return setTitle("Выберите один правильный вариант из списка ")
            case "matrix":
                return setTitle("Расставьте галочки во всех нужных ячейках")
            case "detailed":
                return setTitle("Впишите свой ответ в текстовое поле")
            case "numeric":
                return setTitle("В качестве ответа введите численный ответ, без пробелов и каких-либо дополнительных символов. Дробную часть отделяйте от целой запятой или точкой. Единицы измерений писать не нужно.")
            case "attach":
                return setTitle("Выберите нужный файл на компьютере и нажмите \"Отправить\".\n" +
                    "Баллы за задание будут начислены только после того, как преподаватель проверит его. При этом статус вашего задания изменится на \"Проверено\"")
            case "match":
                return setTitle("Сопоставьте значения из двух колонок, перетаскивая блоки второй колонки")
            case "sort":
                return setTitle("Расположите блоки в правильном порядке. Блоки можно перетаскивать мышкой, чтобы выстроить в правильном порядке сверху вниз")
            default:
                return
        }

    }, [props.type])

    return (
        <div style={{cursor:"pointer", marginLeft:"10px", display: "inline", verticalAlign:"middle"}}>
            <Tooltip classes={{tooltip: classes.tooltip, arrow:classes.tooltipArrow}} style={{width:24, height:24}} title={title} arrow placement={"top"} color={"#797979"}>
                    <img style={{width:16, height:16}} src={image}  alt={image}/>
            </Tooltip>
        </div>
    );
};
