import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

export const TypographyReadAll = styled(Typography)(({ theme }) => ({
  color: "#3483F6",
  textDecorationLine: "underline",
  cursor: "pointer",
  whiteSpace: "pre-line",
  width: "100%",
  textWrap: "nowrap",
  "&:hover": {
    opacity: 0.5,
  },
}))
