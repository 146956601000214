import * as Styled from "entities/Organization/ui/Participants.styles"
import { ReactNode, useState } from "react"
import { Button } from "shared/ui"
import FileDownloadIcon from "@mui/icons-material/FileDownload"

interface IProps {
  children: ReactNode
  title: string
  sectionBorderRadius?: number
  download?: () => void
  disableDownload?: boolean
  disableHidding?: boolean
}

const ParticipantsCard = ({
  title,
  children,
  sectionBorderRadius,
  disableDownload,
  disableHidding,
  download,
}: IProps) => {
  const [revealed, setRevealed] = useState(disableHidding)

  const handleDownload = () => {
    download && download()
  }

  return (
    <Styled.Section borderRadius={sectionBorderRadius}>
      <Styled.Header
        onClick={() => !disableHidding && setRevealed(prev => !prev)}
        style={{ paddingBottom: revealed ? 16 : "auto" }}
        disableHidding={disableHidding}
      >
        <Styled.Title variant={"h4"}>{title}</Styled.Title>
        {download && (
          <Button
            variant={"outlined"}
            endIcon={<FileDownloadIcon />}
            onClick={handleDownload}
            disabled={disableDownload}
          >
            Скачать таблицу заявок
          </Button>
        )}
        {!disableHidding && !revealed && <Styled.DropDownIcon />}
        {!disableHidding && revealed && <Styled.DropUpIcon />}
      </Styled.Header>
      {revealed && <Styled.Content>{children}</Styled.Content>}
    </Styled.Section>
  )
}

export { ParticipantsCard }
