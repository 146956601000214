import React, {useEffect, useState} from "react";
import {
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import { axiosInstance as axios } from "shared/api/axiosInstance";
import Cookies from 'js-cookie';

import { AdminRequest } from "entities/Request";

export const AdminRequests = () => {
  
  const [requests, setRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    axios.get("admin-requests/", { params: { page: 1, type: "change_profile_data" }}).then(res => {
      if (!!res.data.results) {
        setRequests(res.data.results.map(e => ({ ...e, props: JSON.parse(e.props) })))
      }
    })
  }, [])

  function resolveRequest(request) {
    setIsLoading(true)
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    axios.delete(`admin-requests/${request.id}/`).then(() => {
      let updatedRequests = [ ...requests ]
      updatedRequests.splice(requests.indexOf(request), 1)
      setRequests(updatedRequests)
      setIsLoading(false)
    })
  }

  if (requests.length === 0) return null

  return (
    <>
      <Typography variant="h5">Запросы на изменение ФИО</Typography>
      {requests.map(e => (
        <AdminRequest request={e} actions={
          isLoading ? (
            <CircularProgress size={26} />
          ) : (
            <Button
              onClick={() => resolveRequest(e)}
              variant="contained"
              color="primary"
              size="large"
              style={{ width: 120, height: 35, marginBottom: 6 }}
            >
              Удалить
            </Button>
          )
        }/>
      ))}
    </>
  )
}
