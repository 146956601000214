import { ChartType, ILegendItem, RangesType, StatisticsVariants } from "../model/StatisticsContext.types"
import { IAnswer } from "../model/useStatisticsDataList"
import {
  generalInfoLegendLabels,
  generalLegendColors,
  generalLegendLabels,
  infoLegendLabels,
  newSunBurstColors,
} from "entities/Organization/static/statistics"

export function convertAnswersToCharts(answers: IAnswer[]) {
  const charts = Object.groupBy(answers, answer => answer.question.title) as ChartType
  const chartLabels = Object.keys(charts)
  return { charts: { general: charts }, chartLabels }
}

export function convertStatisticsToChart(legendItems?: ILegendItem[]) {
  if (!legendItems) return {}
  const { values, colors, labels } = legendItems.reduce(
    (acc, item) => {
      acc.values.push(item.data !== 0 ? item.data : null)
      acc.colors.push(item.color)
      acc.labels.push(item.title)
      return acc
    },
    { values: [] as unknown[], colors: [] as string[], labels: [] as string[] }
  )
  const plotData = {
    type: "pie",
    hole: 0.4,
    values: values,
    labels: labels,
    marker: {
      colors: colors,
    },
  }
  return plotData
}

export const getBasePayload = (
  type: StatisticsVariants,
  questionId?: string,
  answersString?: string,
  sortBy?: { type: string; direction: number },
  email?: string,
  dateRangeType?: RangesType
) => {
  if (type === "survey")
    return {
      type: `${type}_table`,
      infofield_id: questionId,
      variants: answersString,
      date_range: dateRangeType,
      sort: sortBy?.type,
      direction: sortBy?.direction === 1 ? "asc" : "desc",
    }
  if (type === "personal")
    return {
      type: `${type}`,
      email: email,
      sort: sortBy?.type,
      direction: sortBy?.direction === 1 ? "asc" : "desc",
    }
}

export const getDisplayedValue = (index: number, value: number) => {
  let result = ""
  const initCount = index === 0 ? "0" : "0"
  const cutted = Array.from(initCount).slice(0, initCount.length - value.toString().length)
  result = cutted.join("") + value.toString()
  return result
}

export const getDisplayedLegend = ({ legend, isCompany }: { legend?: ILegendItem[]; isCompany: boolean }) => {
  return isCompany
    ? [
        {
          data: legend?.reduce((acc, item) => acc + item.data, 0) || 0,
          title: "Всего",
          type: "total",
          color: "#313439"
        },
        ...(legend || []),
      ]
    : legend
}

export const formTableResult = (type: StatisticsVariants, response: any) => {
  if (type !== "personal") {
    return {
      count: response.count,
      next: response.next,
      results: response.results.data,
    }
  }
  if (type === "personal") {
    return {
      count: response.length,
      next: false,
      results: response,
    }
  }
}

// @ts-ignore
export function formGeneralInfo(general) {
  const result = {} as ChartType
  const generalKeys = ["total", "organization", "other"]
  // @ts-ignore
  result[generalInfoLegendLabels.activity] = generalKeys.map((key: StatisticsVariants) => {
    return {
      color: generalLegendColors[key as "total" | "organization" | "other"],
      data: general[key],
      title: generalLegendLabels[key as "total" | "organization" | "other"],
      type: key,
    }
  })

  return result
}

export function formActivityChart(data: any) {
  const result = {} as ChartType
  const activityKeys = Object.keys(data)
  activityKeys.map((key) => {
    const chartData = data[key]
    const chartLabels = Object.keys(chartData)
    const infoLegendLabel = infoLegendLabels[key as "organization" | "other"]
    result[infoLegendLabel] = chartLabels.map((label: string) => {
      return {
        color: newSunBurstColors[label],
        data: chartData[label],
        title: label,
      }
    })
  })
  return result
}
