let commands = []
let i
var socket

var robot = {

    init:  function (channel) {
        i = 0;
        socket = new WebSocket("wss://syncwoia.com/venv/" + channel + "/");
        socket.onopen = function() {
            console.log("Соединение установлено.");
            let startJson = JSON.stringify({"role" : "editor"})
            socket.send(startJson)
        };

        socket.onclose = function(event) {
            if (event.wasClean) {
                console.log('Соединение закрыто чисто');
            } else {
                console.log('Обрыв соединения'); // например, "убит" процесс сервера
            }
            console.log('Код: ' + event.code + ' причина: ' + event.reason);
        };

        socket.onmessage = function(event) {
            console.log("Получены данные " + event.data);
        };

        socket.onerror = function(error) {
            console.log("Ошибка " + error.message);
        };
    },

    addCommand : function (command){
        commands[i] = command
        i += 1
    },

    checkConnection: function(){
        setTimeout(()=>{
            if(!socket.readyState){
                robot.checkConnection()
            }
        }, 4000)
    },

    robotExecute: function () {
        setTimeout(()=>{
            for (let j = 0; j <= commands.length; j++){
                let json
                setTimeout(()=>{
                    switch (commands[j]){
                        case "moveForward":
                            json = JSON.stringify({"controls": [{"id": 0, "method": "Drive", "inputs": [1, 0]}]})
                            console.log(json)
                            socket.send(json)
                            break;

                        case "reset":
                            json = JSON.stringify({"controls": [{"id": 0, "method": "Reset", "inputs": []}]})
                            console.log(json)
                            socket.send(json)
                            break;

                        case "rotateRight":
                            json = JSON.stringify({"controls": [{"id": 0, "method": "Drive", "inputs": [0, 90]}]})
                            console.log(json)
                            socket.send(json)
                            break;

                        case "rotateLeft":
                            json = JSON.stringify({"controls": [{"id": 0, "method": "Drive", "inputs": [0, -90]}]})
                            console.log(json)
                            socket.send(json)
                            break;

                        default:

                    }
                }, 2500 * j+1)

            }
        }, 1500)
    }
}

export default robot
