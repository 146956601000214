import React from "react"
import * as Styled from "./Team.style.ts"
import { CoursePannel } from "./CoursePannel.js"
import { SectionWrapper } from "shared/ui"
import { Typography } from "@mui/material"
import { TeamCard } from "./TeamCard.js"
import { useSelector } from "react-redux"

export const UserTeams = () => {
  const course = useSelector(state => state.course.course)
  const teams = useSelector(state => state.team.teams)

  return (
    <Styled.BaseDiv>
      <CoursePannel />
      {course && (
        <SectionWrapper>
          <Typography variant={"h4"}>{`Список команд по курсу: "${course?.label}"`}</Typography>
          <Typography
            variant={"body1"}
            fontWeight={"small"}
            fontSize={"medium"}
          >{`ID курса: ${course?.id}`}</Typography>
        </SectionWrapper>
      )}
      <Styled.TeamsMainDiv>{course && teams.map(team => <TeamCard team={team} key={team.id} />)}</Styled.TeamsMainDiv>
    </Styled.BaseDiv>
  )
}
