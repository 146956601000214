import { styled } from "@mui/material/styles"

export const ProgressDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  gap: theme.spacing(6),
  position: "sticky",
  top: 0,
  left: 0,
  zIndex: 1,
  backgroundColor: "rgba(0,0,0,0.0)",
}))
