import { ReactNode, useState } from "react"
import { styled } from "@mui/material"
import { Modal, Checkbox, Button, Loader } from "shared/ui"
import { ThemeType } from "shared/themes/default.types"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import img1 from "shared/assets/Statistics/WarningModalImg1.png"
import img2 from "shared/assets/Statistics/WarningModalImg2.png"

interface IProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  title: string
  label: string
  isLoading?: boolean
  isCheckbox?: boolean
  isDownload?: boolean
  isNotify?: boolean
  isAccess?: boolean
}

const WarningModal = ({
  children,
  isOpen,
  onClose,
  onSubmit,
  title,
  label,
  isLoading,
  isCheckbox = false,
  isDownload = false,
  isNotify = false,
  isAccess = false,
}: IProps) => {
  const [isReveal, setIsReveal] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const toggleReveal = () => setIsReveal(prev => !prev)
  const toggleChecked = () => setIsChecked(prev => !prev)

  const handleOnClick = () => {
    const key = isDownload ? "download" : isAccess ? "access" : "notify"
    isChecked && localStorage.setItem(`${key}_warning_disable`, String(isChecked))
    onSubmit()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <Container>
        <div>
          {children}
          <HelperWrapper onClick={toggleReveal}>
            <Helper>Почему количество пользователей не совпадает?</Helper>
            <IconWrapper isReveal={isReveal}>
              <ArrowDropDownIcon style={{ scale: "1.4" }} />
            </IconWrapper>
          </HelperWrapper>
          {isReveal && <ExtraContent isDownload={isDownload} isNotify={isNotify} isAccess={isAccess} />}
          {isLoading && <Loader />}
        </div>
      </Container>
      {!isLoading && (
        <ButtonWrapper>
          {isCheckbox ? (
            <Checkbox isChecked={isChecked} onChange={toggleChecked} label="Больше не показывать это сообщение" />
          ) : (
            <div />
          )}
          <Button onClick={handleOnClick} variant={"contained"}>
            {label}
          </Button>
        </ButtonWrapper>
      )}
    </Modal>
  )
}

const Container = styled("div")(() => ({
  minHeight: "450px",
  display: "flex",
  flexDirection: "column",
}))

const HelperWrapper = styled("div")(() => ({
  marginTop: "16px",
  display: "flex",
  gap: "12px",
  cursor: "pointer",

  "&:hover": {
    opacity: 0.7,
  },
}))

const Helper = styled("span")(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "155%",
  textDecoration: `underline dashed ${theme.palette.info.main}`,
  textUnderlineOffset: 7,
}))

const IconWrapper = styled("div")(({ theme, isReveal }: { isReveal: boolean; theme?: ThemeType }) => ({
  color: theme?.palette.info.main,
  height: "24px",
  rotate: isReveal ? "180deg" : "0deg",
}))

const ButtonWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "auto",
}))

const ExtraContent = ({
  isDownload,
  isNotify,
  isAccess,
}: {
  isDownload?: boolean
  isNotify?: boolean
  isAccess?: boolean
}) => {
  const textDownload = `Чтобы загрузить всю статистику по выборке, количество прогруженных участников в таблице должно совпадать с общим
        количеством участников по выборке. Эта информация указана над заголовком таблицы статистики. (Рис. 1.)`
  const textNotify = `Чтобы отправить уведомление всем выбранным участникам , количество прогруженных участников должно совпадать с количеством участников по выборке. Эта информация указана над заголовком таблицы статистики. (Рис. 1.)`
  const textAccess = `Чтобы совершить действие из блока «Выдача доступов» по всем выбранным участникам, количество прогруженных участников в таблице должно совпадать с общим количеством участников по выборке. Эта информация указана над заголовком таблицы статистики. (Рис. 1.)`

  return (
    <ExtraWrapper>
      {isDownload && <Typography>{textDownload}</Typography>}
      {isNotify && <Typography>{textNotify}</Typography>}
      {isAccess && <Typography>{textAccess}</Typography>}
      <br />
      <Image src={img1} />
      <br />
      <Caption>Рис. 1. Информация над заголовком таблицы Статистики.</Caption>
      <Space indent={40} />
      <Typography>
        После нажатия на кнопку «Посмотреть статистику» , в таблицу прогружается статистика по первым 100 участникам
        курса. Чтобы прогрузить в таблицу ещё 100 участников, необходимо прокрутить таблицу вниз до появления значка
        прогрузки. (Рис. 2.)
      </Typography>
      <Image src={img2} />
      <br />
      <Caption>Рис. 2. Как выглядит значок прогрузки таблицы.</Caption>
      <Space indent={32} />
      <Typography>
        Если участников больше, повторяйте это действие, пока числа на Рис. 1 не станут равны друг другу.
      </Typography>
      <Space indent={73} />
    </ExtraWrapper>
  )
}

const ExtraWrapper = styled("div")(() => ({
  marginTop: 24,
  maxHeight: 300,
  overflowY: "scroll",
  width: 965,
}))

const Typography = styled("span")(() => ({
  color: "#000",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "155%",
}))

const Image = styled("img")(() => ({
  marginTop: 24,
}))

const Caption = styled("span")(() => ({
  marginTop: 12,
  color: "#52555C",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "125%",
}))

const Space = styled("div")(({ indent }: { indent: number }) => ({
  marginTop: indent,
}))

export default WarningModal
