import styled from "@emotion/styled"
import TableTemplate from "shared/ui/TableTemplate/TableTemplate"
import { useEffect, useMemo, useState } from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { Checkbox } from "shared/ui"
import { AssignmentTableRow } from "entities/Assignment/ui/AssignmentTableRow"
import { assignmentsActions, fetchAssignments } from "shared/store"
import { AssignmentStatus, IAssingment } from "entities/Assignment/model/assignment.types"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { IFilters } from "shared/hooks/useFiltersForm"
import { removeObjectProperty } from "shared/util"
import { assignmentHeaders as headers } from "entities/Assignment/static/table"
import { handleFetchAssignments } from "entities/Assignment/lib/assignmentFilters"

const AssignmentTable = () => {
  const { filters: filtersState, assignments, disableCheckbox } = useAppSelector(state => state.assignment)
  const filters = filtersState as IFilters
  const { data, page, isLoading, hasNextPage } = assignments
  const [isShowReviewed, setIsShowReviewed] = useState(false)
  const [isSubmitTrigger, setIsSubmitTrigger] = useState(false)
  const dispatch = useAppDispatch()
  const isCheckboxActive =
    filters?.status === AssignmentStatus.accepted || filters?.status === AssignmentStatus.rejected

  useEffect(() => {
    if (filters?.status && disableCheckbox) {
      setIsShowReviewed(isCheckboxActive)
    }
  }, [filters?.status, disableCheckbox])

  const memoizedAssignments = useMemo(
    () =>
      data.map((assignment: IAssingment, index) => (
        <AssignmentTableRow
          key={assignment.id}
          assignment={assignment}
          assignmentIndex={index}
          setIsSubmitTrigger={setIsSubmitTrigger}
        />
      )),
    [data]
  )

  const toggleShowing = async () => {
    if (isLoading || disableCheckbox) return
    const newFilters = isShowReviewed
      ? { ...filters, page: 1, status: "submitted" }
      : { ...removeObjectProperty("status", filters), page: 1 }
    setIsShowReviewed(prev => !prev)
    dispatch(assignmentsActions.setAssignments({ data: [] }))
    await handleFetchAssignments(newFilters, 1, dispatch)
  }

  const handleSort = async ({ type, direction }: { type: string; direction: number }) => {
    if (isLoading) return
    const newFilters = {
      ...filters,
      page: 1,
      sort: type,
      direction: direction === 1 ? "asc" : "desc",
      status: filters?.status,
    }
    dispatch(assignmentsActions.setAssignments({ data: [] }))
    await handleFetchAssignments(newFilters, 1, dispatch)
  }

  const loadMore = async () => {
    if (isSubmitTrigger) {
      const newFilters = {
        ...filters,
        page: page + 1,
      }
      await handleFetchAssignments(newFilters, page, dispatch)
      setIsSubmitTrigger(false)
      dispatch(assignmentsActions.increasePage())
    } else {
      dispatch(fetchAssignments({ ...filters, page }))
    }
  }

  return (
    <Container>
      <CheckboxWrapper>
        <Checkbox
          isChecked={isShowReviewed || isCheckboxActive}
          onChange={toggleShowing}
          label={"Отображать уже проверенные задания"}
          isDisabled={isLoading || disableCheckbox}
        />
      </CheckboxWrapper>

      <TableTemplate
        hasNextPage={hasNextPage}
        headers={headers}
        isLoading={isLoading}
        loadMore={loadMore}
        setSortBy={handleSort}
        height="clamp(466px, 56vh, 706px)"
      >
        {data.length ? memoizedAssignments : null}
        {!data.length && !isLoading && (
          <EmptyRow>Нет результатов, соответствующих фильтру. Проверьте правильность введённых данных.</EmptyRow>
        )}
      </TableTemplate>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 64px;
`

const CheckboxWrapper = styled.div`
  margin-bottom: 24px;
`

const EmptyRow = styled.div`
  position: absolute;
  padding: 40px;
  font-weight: 400;
  size: 18px;
  line-height: 27.9px;
  left: 50%;
  translate: -50% 0;
  width: max-content;
`

export { AssignmentTable }
