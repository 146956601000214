import React, { useEffect, useState } from "react"
import * as Styled from "./ui/UserWebinars.styles"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { WebinarCard, WebinarType } from "../../entities/Webinar"
import { Grid } from "@mui/material"

export const UserWebinars = () => {
  const [webinars, setWebinars] = useState<Array<WebinarType>>([])

  useEffect(() => {
    const fetchWebinars = async () => {
      const coursesResponse = await axios.get(
        "content/actions/participants/my_courses/?course__type=webinar&include_requested=true"
      )
      const coursesData: Array<{ course: number; status: string }> = coursesResponse.data
      const coursePromises = coursesData.map(async item => {
        const courseResponse = await axios.get(`content/courses/${item.course}`)
        const courseData = courseResponse.data
        courseData.template_props = JSON.parse(courseData.template_props)
        return {
          ...courseData,
          participant: {
            status: item.status,
            //@ts-ignore
            is_completed: item.is_completed,
            //@ts-ignore
            has_certificate: item.has_certificate,
          },
        }
      })
      const resolvedCourses = await Promise.all(coursePromises)
      return resolvedCourses
    }
    const fetchData = async () => {
      const webinars = await fetchWebinars()
      setWebinars(webinars)
    }
    fetchData()
  }, [])

  return (
    <Styled.UserWebinarsMainDiv>
      <Grid container justifyContent={"space-between"} spacing={6}>
        {webinars.map(e => (
          <WebinarCard webinar={e} />
        ))}
      </Grid>
    </Styled.UserWebinarsMainDiv>
  )
}
