import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    error_block: {
        width: '100%',
        height: '100vh',
        margin: 'auto',
        paddingTop: "20vh",
        backgroundColor: '#F7F9FC',
        textAlign: 'center',
        //alignItems: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',

        '& h3, p': {
            marginLeft: 'auto',
            marginRight: 'auto',
            letterSpacing: '0.3px',
            color: '#313439',
        },
        
        '& h3': {
            marginTop: '0px',
            marginBottom: '15px',
            fontWeight: '500',
            fontSize: '24px', // or 37px
            lineHeight: '155%',
        },
        
        '& p': {
            marginBottom: '15px',
            fontWeight: '400',
            fontSize: '18px', // or 28px
            lineHeight: '90%',
        },
    },

    buttonError: {
        '&#error-button': {
            width: '200px',
            height: '44px',
            borderRadius: '4px',
            backgroundColor: '#FA5D55', // default 
            border: '1px solid #FFFFFF', // default
            color: '#FFFFFF', // default
            margin: '15px 0px 35px 0px',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            //alignItems: 'center',
            //textAlign: 'center',
            letterSpacing: '0.3px',
            '&:hover': {
                backgroundColor: '#FFFFFF', // hover
                border: '1px solid #FA5D55', // hover
                color: '#FA5D55', // hover
            },
            '&:press': {
                backgroundColor: '#FA5D55', // press
                border: '1px solid #FFFFFF', //press 
                color: '#FFFFFF', // press
            },
            '&:disabled': {
                backgroundColor: '#EBEBEB', // disabled
                border: '1px solid #98999C', // disabled
                color: '#98999C', // disabled
            },
        },
    },
    
    img_404: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: 'calc(100% - 220px)',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))