import React, { useCallback, useState } from "react"
import {
  Grid,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
} from "@mui/material"
import useStyles from "./styles"

import iconEmail from "../../images/icons/icon_email.svg"
import iconTelegram from "../../images/icons/icon_telegram.svg"
import iconPolicy from "../../images/icons/icon_policy.svg"

export const Footer = props => {
  var classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)

  return (
    <div className={classes.footer}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={{ xl: "row", xs: "column-reverse" }}
        spacing={{ xs: 0, xl: 0 }}
        className={classes.footerWrapper}
        columns={{ xs: 2, xl: 2 }}
      >
        <Grid item xs={1}>
          <div className={classes.footerContainer} style={{ justifyContent: "center" }}>
            <Typography className={classes.descriptionPlatform} sx={{ color: "#FFF" }}>
              {"Syncwoia. Киберфизическая платформа навыков будущего."}
              <br />
              {`\u00A9 ООО "ДАТА ЛАБ" 2021-${new Date().getFullYear()}. Все права защищены.`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={classes.footerLeftContainer}>
            <div className={classes.elem}>
              <IconButton disabled>
                <img src={iconEmail} alt={iconEmail} />
              </IconButton>
              support@syncwoia.com
            </div>
            {/*<div className={classes.elem}><IconButton
                            disabled><img src={iconTelegram} alt={iconTelegram}/></IconButton>t.me/syncwoiacom
                        </div>*/}
            <div className={classes.elem}>
              <IconButton disabled>
                <img src={iconTelegram} alt={iconTelegram} />
              </IconButton>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://t.me/syncwoiacom"}
                className={classes.elemLink}
              >
                t.me/syncwoiacom
              </a>
            </div>
            <div className={classes.elem}>
              <IconButton disabled>
                <img src={iconPolicy} alt={iconPolicy} />
              </IconButton>
              <a onClick={() => setOpenDialog(true)} className={classes.elemLink}>
                Политика конфиденциальности
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        aria-labelledby="DialogTitle"
        aria-describedby="DialogDescription"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ height: 600 }}>
          <DialogContentText id="DialogDescription" style={{ height: "100%" }}>
            <iframe
              src="https://syncwoia.com/static/media/extra/policy.html"
              width={900}
              height={"100%"}
              style={{ border: "none" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
