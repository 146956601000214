import { axiosInstance as axios } from "../../../shared/api/axiosInstance"

export const resetTest = async (id: number) => await axios.post(`/${id}/adaptive_test/`, { action: "erase" })

export const startTest = async (id: number) => await axios.post(`/${id}/adaptive_test/`, { action: "start" })

export const sendAnswers = async (id: number, answers: { question_id: number; answer: string | string[] }[]) =>
  await axios.post(`${id}/adaptive_test/`, { action: "submit", answers: answers })

export const fetchState = async (id: number) => await axios.get(`${id}/adaptive_test`)

export const checkTestDeadline = async (id: number, predeadline: boolean) =>
  await axios.get("content/actions/timer/check_deadline/", { params: { content_block: id, predeadline: predeadline } })

export const startTestTimer = async (id: number) => await axios.post("content/actions/timer/", { content_block: id })

export const getPredeadlineDate = async (id: number) =>
  await axios.get("/content/actions/timer/get_predeadline_date/", { params: { content_block: id } })

export const fetchTimer = async (id: number) =>
  await axios.get("/content/actions/timer/get_timer/", { params: { content_block: id } })

export const fetchAllQuestions = async () => await axios.get("/questions/")

export const deleteAdminQuestion = async (id: number) => await axios.delete(`/questions/${id}/`)

export const editAdminQuestion = async (id: number, data: any) => await axios.put(`/questions/${id}/`, data)

export const createAnswer = async (data: any) => await axios.post("/questions/", data)
