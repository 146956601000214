import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"

// pages
import Layout from "../pages/layout/Layout"
import Error from "../pages/error/Error"
import Login from "../pages/login/Login"
import ForgotPassword from "../pages/login/ForgotPassword"
import Landing from "../pages/landing/Landing"
import Registration from "../pages/registration/Registration"
import Admin from "../pages/admin/Admin"
import Statistics from "../pages/statistics/Statistics"
import Module from "../pages/modules/Modules"
import DriveRobot from "../pages/drive-robot/DriveRobot"
import InternetOfThings from "../pages/iot/InternetOfThings"
import TeamLinkInvitation from "../pages/invitation/TeamLinkInvitation"
import FormatLinkInvitation from "../pages/invitation/FormatLinkInvitation"
import EventMainPage from "../pages/event-main-page/EventMainPage"
import Stages from "../pages/stages/Stages"
import LeaderboardPage from "pages/statistics/Leaderboard"
import Certificate from "../pages/certificate/Certificate"
import Organization from "../pages/organization/Organization"

import { StyledEngineProvider } from "@mui/material/styles"
import { ContentProvider, ThemeProvider, useUserState } from "shared/context"
import { CssBaseline } from "@mui/material"
import UiComponents from "../pages/ui/UiComponents"
import { Provider } from "react-redux"
import { store } from "shared/store"
import OrganizationsControl from "../pages/OrganizationsControl/OrganizationsControl"
import config from "../shared/config"
import CourseMainPage from "../pages/course-main-page/CourseMainPage"
import Catalog from "../pages/catalog/Catalog"

export default function App() {
  // global
  const { isAuthenticated } = useUserState()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <Provider store={store}>
          <CssBaseline />
          <Login />
          <Registration />
          <ContentProvider>
            <BrowserRouter>
              <Switch>
                <PrivateRoute path="/event/:name/certificate" component={Certificate} />
                <Route path="/event/:name/stages" component={Stages} />
                <Route path="/event/:name/leaderboard/:course" component={LeaderboardPage} />
                <Route path="/event/:name" component={EventMainPage} />
                <Route path="/organization/:organization_slug/event/:name" component={EventMainPage} />
                <Route exact path="/app" render={() => <Redirect to="/app/cabinet" />} />
                {/*<PrivateRoute path="/app" component={Layout} />*/}
                <Route path="/catalog/course/:name" component={CourseMainPage} />
                <PrivateRoute path="/catalog/course" component={CourseMainPage} />
                <Route path="/catalog/organization/:organization" component={Catalog} />
                <Route path="/catalog/:tag" component={Catalog} />
                <Route path="/catalog" component={Catalog} />
                <Route path="/app" component={Layout} />
                <Route
                  path="/"
                  exact
                  component={
                    window.location.origin.split("//")[1].includes(config.hostApi.split("//")[1])
                      ? Landing
                      : Organization
                  }
                />
                <PublicRoute path="/forgot" component={ForgotPassword} />
                <PrivateRoute path="/admin/scores/:course" component={Admin} />
                <PrivateRoute path="/admin/courses/:course" component={Admin} />
                <PrivateRoute path="/admin" component={Admin} />
                <PrivateRoute path="/statistics" component={Statistics} />
                <PrivateRoute path="/drive-robot" component={DriveRobot} />
                <PrivateRoute path="/iot-page" component={InternetOfThings} />
                <PrivateRoute path="/ui" component={UiComponents} />
                <Route path="/invitation" component={TeamLinkInvitation} />
                <Route path="/enroll" component={FormatLinkInvitation} />
                <PrivateRoute path={"/control/organizations"} component={OrganizationsControl} />
                <Route path="/organization/:slug" component={Organization} />
                <PrivateRoute path="/module/:id/:team" component={Module} />
                <PrivateRoute path="/module/:id" component={Module} />
                <Route component={Error} />
              </Switch>
            </BrowserRouter>
          </ContentProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => (isAuthenticated ? React.createElement(component, props) : <Redirect to={"/"} />)}
      />
    )
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/catalog",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    )
  }
}
