export const organization_types = [
  "Образовательное учреждение",
  "Организатор и разработчик в области образования",
  "Некоммерческая организации",
  "Коммерческая организация",
  "Система государственного управления",
  "Другое (указать)",
]

export const request_variants = [
  "Дополнительное образование",
  "Развитие компетенций",
  "Проектно-исследовательская деятельность",
  "Создание цифровой экосистемы",
  "Организация событий в образовании",
  "В поисках платформы для образования",
  "Сетевые модели и формы образования",
  "Инструменты автоматизации",
  "Цифровой след, управление на основе данных",
  "Другое (указать)",
]
