import React, { ChangeEvent, useState } from "react"
import { Button, TextField } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import * as Styled from "./ui/Tags.styles"

export const CreateTag = ({ setTags }: { setTags: (value: Array<{ value: number; label: string }>) => void }) => {
  const [newTag, setNewTag] = useState("")
  const [error, setError] = useState<boolean | null>(null)
  const [text, setText] = useState("")

  function createTag() {
    axios
      .post("content/tags/", { value: newTag })
      .then(res => {
        setError(false)
        setText("Тег успешно добавлен")
        let req_res: { value: string; id: number } = res.data
        //@ts-ignore
        setTags((prev: { value: number; label: string }[]) => [
          ...prev,
          {
            value: req_res.id,
            label: req_res.value,
          },
        ])
      })
      .catch(err => {
        setError(true)
        if (err.response.status === 400) {
          setText("Данный тег уже существует")
        } else setText("Неизвестная ошибка")
      })
  }

  return (
    <Styled.TagWrapper sx={{ width: "100%" }}>
      <Styled.CreateTagMainDiv>
        <Styled.CreateTagContentDiv>
          <TextField
            /*@ts-ignore*/
            label={"Название тега"}
            value={newTag}
            variant={"outlined"}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setNewTag(event.target.value)}
          />
          {/*@ts-ignore*/}
          <Button variant={"contained"} color={"primary"} onClick={createTag}>
            Создать тэг
          </Button>
        </Styled.CreateTagContentDiv>
        {text.length > 0 && <RequestMessage error={!!error} text={text} />}
      </Styled.CreateTagMainDiv>
    </Styled.TagWrapper>
  )
}
