import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
} from "@mui/material";

export const NumericTypeEditor = (props) => {
  const [editorOnIndex, setEditorOnIndex] = useState(null)
  const [text, setText] = useState("")
  const [question, setQuestion] = useState(props.question)

  function editAnswer(value, field) {
    if (question.variants.length === 0) {
      question.variants.push({ value: "", precision: "" })
    }
    question.variants[0][field] = value
    setQuestion({...question})
  }

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
      <TextField
        id="answer-text-editor"
        variant="outlined"
        value={!!question.variants[0] ? question.variants[0].value : ""}
        onChange={e => editAnswer(e.target.value, "value")}
        placeholder="Значение"
        fullWidth
        multiline
        style={{ margin: 12, minWidth: 360 }}
      />
      <TextField
        id="answer-text-editor"
        variant="outlined"
        value={!!question.variants[0] ? question.variants[0].precision : ""}
        onChange={e => editAnswer(e.target.value, "precision")}
        placeholder="Точность"
        fullWidth
        multiline
        style={{ margin: 12, minWidth: 360 }}
      />
    </div>
  )
}
