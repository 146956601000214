import React from "react"
import * as Styled from "./Iot.styles"
import { FormControlLabel, Switch, Typography } from "@mui/material"
import { useAppSelector } from "../../../shared/hooks/useAppSelector"

interface IProps {
  title: string
  id: number
  changeValue: (id: number, port: number, value: boolean) => void
  outps_val: Array<number>
  inps_val: Array<number>
}

export const SwitchesCard = ({ title, id, changeValue, outps_val, inps_val }: IProps) => {
  const status = useAppSelector(state => state.iot.userStatus)

  return (
    <>
      <Styled.SwitchesCard sx={status === "observer" ? { border: "4px solid #EBEBEB" } : {}}>
        <Typography variant={"h4"} sx={{ alignSelf: "flex-start" }}>
          Цифровой выход
        </Typography>
        <Styled.SwitchesDiv>
          {inps_val.map((e, index) => (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Switch
                checked={!!e}
                sx={{ transform: "rotate(-90deg)" }}
                color={"success"}
                disabled={status === "observer"}
                onChange={event => changeValue(id, index, event.target.checked)}
              />
              <Typography variant={"body1"} fontSize={"small"} fontWeight={"medium"}>
                {index + 1}
              </Typography>
            </div>
          ))}
        </Styled.SwitchesDiv>
      </Styled.SwitchesCard>
      <Styled.SwitchesCard>
        <Typography variant={"h4"} sx={{ alignSelf: "flex-start" }}>
          Цифровой вход
        </Typography>
        <Styled.SwitchesDiv>
          {outps_val.slice(0, 6).map((e, index) => (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div
                style={{ height: 48, width: 12, borderRadius: 12, backgroundColor: e ? "#2EAD58" : "#FF564E" }}
              ></div>
              <Typography variant={"body1"} fontSize={"small"} fontWeight={"medium"}>
                {index + 1}
              </Typography>
            </div>
          ))}
        </Styled.SwitchesDiv>
      </Styled.SwitchesCard>
    </>
  )
}
