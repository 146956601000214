import React from "react"
import * as Styled from "./Landing.styles"
import { Grid, Typography, useTheme } from "@mui/material"
import ImageTechnopark from "images/img_technopark.svg"
import LowImageTechnopark from "images/img_technopark_low.svg"
import image1 from "images/icons/icon_automation.svg"
import image2 from "images/icons/icon_digital space.svg"
import image3 from "images/icons/icon_iot.svg"
import image4 from "images/icons/icon_team.svg"
import { InfoTechnoparkItem } from "./InfoTechnoparkItem"
//@ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component"

const items = [
  {
    image: image1,
    title: "Автоматизация процессов",
    description: "Эффективная автоматизация и управление Вашими программами и событиями",
  },
  { image: image2, description: "Ваш собственный сайт и платформа за несколько шагов" },
  {
    image: image3,
    description: "«Оцифруйте» Ваше оборудование и работайте с ним удаленно",
  },
  {
    image: image4,
    description: "Командная работа, реализация проектно-исследовательской деятельности с инструментами Syncwoia",
  },
]

export const DigitalTechnopark = () => {
  const theme = useTheme()
  return (
    <Styled.DigitalTechnoparkMainDiv>
      <Styled.StyledTitle variant={"h2"} sx={{ color: "#313439", width: "100%" }}>
        Цифровой технопарк будущего
      </Styled.StyledTitle>
      <div>
        <Styled.MiddleTypography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
          Обеспечиваем современными инструментами и методиками команды ведущих образовательных учреждений, фондов и
          объединений, для создания эффективных прикладных форматов вовлечения и погружения школьников, студентов и
          взрослых.
        </Styled.MiddleTypography>
      </div>
      <Styled.DigitalTechnoparkDescriptionDiv>
        {/*@ts-ignore*/}
        <Styled.ImageTechnopark>
          <LazyLoadImage width={"100%"} src={ImageTechnopark} placeholderSrc={LowImageTechnopark} effect={"blur"} />
        </Styled.ImageTechnopark>
        <Styled.InfoTechnoparkDiv>
          <Styled.StyledText variant={"body1"} fontSize={"medium"} sx={{ color: "#313439", fontWeight: 500 }}>
            Возможности платформы Syncwoia на Вашей стороне:
          </Styled.StyledText>
          <Grid container={true} sx={{ width: "100%" }} justifyContent={"space-between"}>
            {items.map(e => (
              <>
                {/*@ts-ignore*/}
                <InfoTechnoparkItem description={e.description} image={e.image} />
              </>
            ))}
          </Grid>
        </Styled.InfoTechnoparkDiv>
      </Styled.DigitalTechnoparkDescriptionDiv>
    </Styled.DigitalTechnoparkMainDiv>
  )
}
