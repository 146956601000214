import * as Styled from "entities/Organization/ui/styles/SummaryTemplate.styles"
import SummaryTemplateTab from "entities/Organization/ui/styles/SummaryTemplateTab"
import StatisticsDataList from "entities/Organization/ui/StatisticsDataList"
import { Button } from "shared/ui"
import { Size, Variant } from "shared/ui/styles.const"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import styled from "@emotion/styled"

const Actions = ({ onSubmit }: { onSubmit: () => void }) => {
  const { eventFilters } = useStatistics()

  return (
    <Styled.Actions>
      <div>
        <Button size={Size.small} variant={Variant.outlined} onClick={() => {}} disabled>
          Выбрать из шаблонов
        </Button>
        <Styled.CreateTemplateButton>создать новый шаблон</Styled.CreateTemplateButton>
      </div>
      <Button
        size={Size.small}
        variant={Variant.contained}
        onClick={() => onSubmit()}
        width={205}
        disabled={!eventFilters.answers.length}
      >
        Увидеть сводку
      </Button>
    </Styled.Actions>
  )
}
const ManageStatistics = ({
  tab,
  changeTab,
  tabOptions,
  onSubmit,
}: {
  tab: number | null
  changeTab: (event: React.SyntheticEvent, newValue: number) => void
  tabOptions: { title: string; value: number; enable: boolean }[]
  onSubmit: () => void
}) => {
  return (
    <Container>
      <SummaryTemplateTab tab={tab} changeTab={changeTab} tabOptions={tabOptions} />
        {tab === 0 && (
          <FlexibleRow>
            <StatisticsDataList />
          </FlexibleRow>
        )}
      <Actions onSubmit={onSubmit} />
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const FlexibleRow = styled.div`
    flex: 1;
    display: flex;
`

export { ManageStatistics }
