import Subsection from "./SidebarNavSection"
import vectorBack from "images/icons/VectorBack.svg"
import { Button, List } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useBackCourseRedirection } from "entities/Test/model/backCourseRedirection"
import { useSelector, useDispatch } from "react-redux"
import { timerActions } from "shared/store"

const BaseContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.sidebar,
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
}))

const Subsections = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}))

const SidebarNav = ({ subsections, setCurrent, courseSlug, hasAdaptiveTest }) => {
  const isRunningTimerTest = useSelector(state => state.timer.isRunning)
  const { redirect } = useBackCourseRedirection(courseSlug)
  const dispatch = useDispatch()

  const handleBack = () => {
    if (isRunningTimerTest) {
      return dispatch(timerActions.setIsExitModal(true))
    }
    redirect()
  }

  return (
    <BaseContainer>
      <List disablePadding>
        <Subsections>
          {subsections &&
            subsections.map(subsection => (
              <Subsection
                hasAdaptiveTest={hasAdaptiveTest}
                component="div"
                key={subsection.title}
                pages={subsection.subsections}
                title={subsection.title}
                currentPrefix={{ section: subsection.index }}
                setCurrent={setCurrent}
              />
            ))}
        </Subsections>
      </List>
      <Button
        onClick={handleBack}
        variant={"contained"}
        style={{ marginLeft: "8%", backgroundColor: "#1565C0", width: "30%", marginBlock: "4vh" }}
      >
        <img style={{ marginRight: "6%", height: "12px", width: "14px" }} src={vectorBack} />
        <div
          style={{
            fontStyle: "normal",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "0.3",
          }}
        >
          НАЗАД
        </div>
      </Button>
    </BaseContainer>
  )
}

export default SidebarNav
