import { styled } from "@mui/material"
import { ShadowedSection } from "shared/ui"

export const TagsCourseMainDiv = styled(ShadowedSection)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
}))

export const TagsList = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  gap: theme.spacing(4),
}))
