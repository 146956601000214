import styled from "@emotion/styled"
import { TableCell as MuiTableCall, Typography } from "@mui/material"
import { ReactNode } from "react"

export const TableCell = ({
  children,
  value,
  width = 160,
}: {
  children?: ReactNode
  value?: string | number
  width?: number
}) => (
  <Cell align="center" width={width}>
    <CellContent>
      {children || (
        <Text variant={"caption"} fontWeight={"medium"}>
          {value || "-"}
        </Text>
      )}
    </CellContent>
  </Cell>
)

const Cell = styled(MuiTableCall)`
  position: relative;
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
`

const CellContent = styled.div`
  max-width: fit-content;
  margin-inline: auto;
`

const Text = styled(Typography)`
  color: var(--text-primary, #313439);
  font-size: 14px;
  line-height: 155%;
  overflow-wrap: break-word;
`
