import { styled } from "@mui/styles"

interface IProps {
  isHiddenContent: boolean
  isStartContent: boolean
}

export const HiddenContent = ({ isHiddenContent, isStartContent }: IProps) => {
  if (isHiddenContent) return <Text>Контент скрыт.</Text>
  if (isStartContent)
    return (
      <Text>
        <span>Контент скрыт.</span>
        <p>Чтобы перейти к выполнению теста или просмотру результатов, пожалуйста, обновите страницу.</p>
      </Text>
    )
}

const Text = styled("span")(() => ({
  fontSize: "18px",
}))
