import React, { useRef } from "react"
import * as Styled from "./Team.style.ts"
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { SectionWrapper } from "shared/ui"
import { InvitationActions } from "./InvitationActions.js"
import { MemberStatus } from "../../Member/ui/MemberStatus.js"

const TextCell = ({ children }) => (
  <TableCell align={"center"}>
    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
      {children}
    </Typography>
  </TableCell>
)

const InvitationTable = ({ id, title, headers, data, isActions }) => {
  const theme = useTheme()
  const tableContainerRef = useRef()

  return (
    <SectionWrapper id={id}>
      <Typography variant="h4" sx={{ marginBottom: "24px" }}>
        {title}
      </Typography>
      <Styled.TableContainer>
        <Styled.TableContainer ref={tableContainerRef}>
          <Table stickyHeader sx={{ width: "100%", overflowX: "auto" }} size="small">
            <TableHead>
              <TableRow sx={{ height: theme.spacing(25) }}>
                {headers.map(e => (
                  <Styled.HeaderCell align={"center"} key={e}>
                    <Typography variant={"caption"} fontWeight={"small"}>
                      {e}
                    </Typography>{" "}
                  </Styled.HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((member, index) => (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={index}>
                  <TextCell>
                    {member?.user_name}
                    <Typography fontWeight={"small"}>{member?.user_email}</Typography>
                  </TextCell>
                  <TextCell>{member?.course_title}</TextCell>
                  <TextCell>{member?.team_title}</TextCell>
                  <TextCell>{member?.team_id}</TextCell>
                  {member?.category && (
                    <TableCell align={"center"}>
                      <MemberStatus category={member?.category} />
                    </TableCell>
                  )}
                  <TableCell>
                    <InvitationActions member={member} isActions={isActions} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Styled.TableContainer>
      </Styled.TableContainer>
    </SectionWrapper>
  )
}

export default InvitationTable
