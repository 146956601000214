const FailIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 3.62571L32.3743 0L18 14.3743L3.62571 0L0 3.62571L14.3743 18L0 32.3743L3.62571 36L18 21.6257L32.3743 36L36 32.3743L21.6257 18L36 3.62571Z"
        fill="#D82E2E"
      />
    </svg>
  )
}

export default FailIcon
