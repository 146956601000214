import { styled } from "@mui/material/styles"
import { CircularProgress } from "@mui/material"

export const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress size={26} />
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled("div")(({ theme }) => ({
  width: "26px",
  padding: theme.spacing(6),
  marginInline: "auto",
}))
