import { createSlice } from "@reduxjs/toolkit"

const testSlice = createSlice({
  name: "test",
  initialState: {
    isUploadingFile: false,
  },
  reducers: {
    setIsUploadingFile: (state, action) => {
      state.isUploadingFile = action.payload
    },
  },
})

export const { reducer, actions } = testSlice
