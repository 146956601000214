import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import { styled } from "@mui/material/styles"
import { TestTooltip } from "features"
import { Typography } from "@mui/material"
import { ThemeType } from "shared/themes/default.types"

export const Info = ({ onActivateModal }: { onActivateModal: () => void }) => {
  return <InfoIcon onClick={onActivateModal}>{<QuestionMarkIcon />}</InfoIcon>
}

export const InfoIcon = styled("span")(({ theme }: { theme?: ThemeType }) => ({
  position: "relative",
  display: "inline-block",
  width: 32,
  height: 32,
  borderRadius: "50%",
  backgroundColor: theme?.palette.primary.main,
  cursor: "pointer",

  "&:hover": {
    opacity: 0.7,
  },

  "& > svg": {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    translate: "-50% -50%",
  },
}))

export function parseDescription(text: string) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>
  }
  return <Typography>{text}</Typography>
}

export function parseTitle(text: string, index: number, type: string | null = null) {
  if (!text) return
  if (text.startsWith("<")) {
    return (
      <div style={{ display: "flex", gap: 20 }}>
        <p>{index + 1}.</p>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        <TestTooltip type={type} />
      </div>
    )
  }
  return (
    <Typography>
      {`${index + 1}.  ${text}`} <TestTooltip type={type} />
    </Typography>
  )
}
