import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { StatisticsVariants } from "../../../entities/Organization/model/StatisticsContext.types"

interface IProps {
  totalLabel?: string
  totalCount?: number
  filterCount?: number
  currentLabel?: string
  type?: StatisticsVariants
}

export const TableTotalCount = ({
                                  totalCount,
                                  filterCount,
                                  totalLabel = "Всего участников в выборке, соответствующих фильтру:",
                                  currentLabel = "Прогружено участников в таблице:",
                                  type,
                                }: IProps) => {

  return type === "personal"
    ? (
      <MainDiv>
        <TypographyDiv>
          <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
            {totalLabel}
          </Typography>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            {totalCount}
          </Typography>
        </TypographyDiv>
      </MainDiv>
    )
    : (
      <MainDiv>
        <TypographyDiv>
          <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
            {totalLabel}
          </Typography>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            {totalCount}
          </Typography>
        </TypographyDiv>
        <TypographyDiv>
          <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
            {currentLabel}
          </Typography>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            {filterCount}
          </Typography>
        </TypographyDiv>
      </MainDiv>
    )
}

const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(4),
}))

const TypographyDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing(2),
}))
