import React, {useCallback, useEffect, useRef, useState} from 'react';
import {EditorView, keymap} from "@codemirror/view";
import {python} from "@codemirror/lang-python";
import {basicSetup} from "codemirror";
import {defaultKeymap, history, historyKeymap, indentWithTab} from "@codemirror/commands"
import useStyles from "./styles";
import "./progress.css";
import { FileInput } from 'shared/assets';
import robot from "./Robot";
import iconOk from "../../images/icons/icon_okCode.png"
import iconError from "../../images/icons/icon_errorCode.png"
import {loadPyodide} from "pyodide";

export const CodeEditor = (props) => {

    var classes = useStyles();

    const editor = useRef()

    const [type, setType] = useState(false)

    const [download, setDownload] = useState(true)

    const [view, setView] = useState()

    const [result, setResult] = useState("")

    const [interpretator, setInterpretator] = useState()

    const [progress, setProgress] = useState(false)

    const [statusVisible, setStatusVisible] = useState(false)

    const [codeRunResult, setRunResult] = useState()

    const loadPython = async () => {
        setStatusVisible(false)
        const pyodide = await loadPyodide({
            indexURL: "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/",
            stdin: window.prompt,
            stdout: (text) => {
                if (text !== "Python initialization complete") {
                    setResult(prevState => prevState + text + "\n")
                }
            },
            stderr: (text) => {
                setResult(prevState => prevState + text + "\n")
            }
        });

        setDownload((prevState) => {
            return false
        })
        return pyodide
    }

    useEffect(()=>{
        if (progress) {
            setStatusVisible(false)
            clearState()
            document.getElementById(`editor__plot-${props.idx}`).innerHTML = ""
            evaluatePython()
        }
    }, [progress])

    const startCode = useCallback(()=>{
        setProgress(true)
    })

    const startSim = () => {
        if (!props.simulator.current) return
        props.simulator.current.runCode(view.state.doc.toString())
    }

    const stopSim = () => {
        if (!props.simulator.current) return
        props.simulator.current.stopCode()
    }

    function setImage(){
        let elem = document.querySelector('body > [id^="matplotlib_"]');
        if(!!elem) document.getElementById(`editor__plot-${props.idx}`).appendChild(elem)
    }

    function checkCode(){
        alert("Код отправлен на проверку")
    }


    async function evaluatePython() {
        try {
            await interpretator.loadPackage("matplotlib");
            await interpretator.loadPackage("micropip");
            interpretator.registerJsModule("robot", robot);
            const micropip = interpretator.pyimport("micropip");
            await micropip.install(props.libs);
            interpretator.runPython("import matplotlib\nimport matplotlib.pyplot as plt\n" +
                "matplotlib.use(\"module://matplotlib.backends.html5_canvas_backend\")\n"+
            "plt.clf()")
            interpretator.runPython(view.state.doc.toString());
            setImage()
            setStatusVisible(true)
            setRunResult(true)
            setProgress(false)
        } catch (err) {
            setStatusVisible(true)
            setRunResult(false)
            setResult(prevState => prevState + err + "\n")
            setProgress(false)
        }
    }

    useEffect(() => {
        setDownload(true)
        document.getElementById(`editor__plot-${props.idx}`).innerHTML = ""
        clearState()
        let view = new EditorView({
            extensions: [
                python(),
                basicSetup,
                history(),
                keymap.of([defaultKeymap, indentWithTab, historyKeymap]),
            ],
            parent: editor.current,
            doc: props.value,
        })
        setView(view)
        setType(props.isChecking)
        const run = async () => {
            setInterpretator(await loadPython())
        }
        run();
        setProgress(false)
        return () => {
            view.destroy();
        };
    }, [props.value, props.idx])

    const clearState = (value) => {
        setResult((prevState) => {
            return ""
        })
    };

    return (
        <div>
            <div style={props.workFile ?  {visibility: 'visible'} : {display: "none"}}>
                <FileInput />
            </div>
            <br/>
            <br/>
            <div id={`editor-${props.idx}`} className={classes.editor} ref={editor}
                 style={download ? {} : {visibility: 'visible'}}></div>
            {props.mode !== "simulation" ? (
                <button className={classes.runButton} onClick={startCode} disabled={download}
                        style={download ? {background:"#98999C"} : {background: "#1565C0",}}>Выполнить код
                </button>
            ) : (
                <>
                <button className={classes.runButton} onClick={startSim} disabled={download}
                        style={download ? {background:"#98999C"} : {background: "#1565C0",}}>Запустить
                </button>
                <button className={classes.runButton} onClick={stopSim} disabled={download}
                        style={download ? {background:"#98999C"} : {background: "#1565C0",}}>Остановить
                </button>
                </>
            )}
            <div className="lds-ring" style={download ? {visibility:"visible", float: "left", margin: "2px 2px",} : {display:'none', float: "left", margin: "2px 2px",}}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <img src={codeRunResult ?  iconOk : iconError} style={statusVisible ? {visibility: 'visible', float: "left", margin: "10px 2px"} : {display: "none",float: "left", margin: "2px 2px",} }/>
            <div className="lds-ring" style={progress ? {visibility:"visible", float: "left", margin: "2px 2px",} : {display:'none', float: "left", margin: "2px 2px",}}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <button className={classes.checkButton} onClick={checkCode}
                    style={download ? {display:"none"} : {visibility: 'visible'} && type ? {} : {display:'none'}}>Отправить на проверку
            </button>
            <textarea rows={8} className={classes.textArea} disabled={true} value={result}
                      style={download ? {} : {visibility: 'visible'}}></textarea>
            <div id={`editor__plot-${props.idx}`}></div>
        </div>
    );
};
