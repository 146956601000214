import styled from "@emotion/styled"

export const EmptyStatisticsTable = styled.div`
  width: 100%;
  height: 400px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding-top: 146px;
`

export const EmptyLabel = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 27.9px;
  text-align: center;
  color: #52555c;
`
