import { createSlice } from "@reduxjs/toolkit"
import { fetchOrganization, fetchOrganizations, fetchOrganizationsAsAdmin } from "entities/Organization"
import { updateOrganization } from "entities/Organization"
import { Organization, OrganizationTypes } from "./OrganizationSlice.types"
import { IEvent } from "../../../entities/Organization/model/Organization.types"

const initialState: OrganizationTypes = {
  organizations: [],
  currentOrganization: null,
  editOrganization: false,
  organizationsMembers: [],
  event: undefined,
  email: "",
  isEmpty: false,
  changeTab: () => {},
}

const organizationSlice = createSlice({
  name: "organizations",
  initialState: initialState,
  reducers: {
    setChangeTab: (state, action) => {
      state.changeTab = action.payload
    },
    setEditOrganization: (state, action) => {
      state.editOrganization = action.payload
    },
    setCurrentOrganization: (state, action) => {
      state.currentOrganization = action.payload
    },
    setEvent: (state, action: { payload: IEvent | undefined }) => {
      state.event = action.payload
    },
    setEmail: (state, action: { payload: string | undefined }) => {
      state.email = action.payload
    },
    setIsEmpty: (state, action: { payload: boolean }) => {
      state.isEmpty = action.payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.currentOrganization = action.payload
        localStorage.setItem("current_organization", action.payload.id)
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.currentOrganization = action.payload
        state.editOrganization = false
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload.map((e: Organization) => ({ value: e.id, label: e.title }))
        if (state.organizations.length === 1) state.currentOrganization = action.payload[0]
      })
      .addCase(fetchOrganizationsAsAdmin.fulfilled, (state, action) => {
        state.organizations = action.payload.map((e: Organization) => ({ value: e.id, label: e.title }))
      })
  },
})

export const { actions, reducer } = organizationSlice
