import React, { useState, useMemo, useEffect, useRef } from "react"
import { Choice, SingleChoice, Matrix, Match, Sort, Detailed, Attach } from "entities/Test/ui/questionTypes"
import { fyShuffle } from "shared/util"
import { changeByIndex } from "entities/Test/lib/test"
import { getDeadline, putAssignmentAttach } from "entities/Assignment/api/assignment"
import { getTeam } from "entities/Team/api/team"
import { useSelector } from "react-redux"
import { ObjectValueType } from "./test.types"

interface IProps {
  isTimer: boolean
  isManual: boolean
  team: number
  block: { id: number; components: Array<{ props: string }>; props: string; deadline: string }
  isReachDeadline: boolean
  setIsReachPredeadline: (x: boolean) => void
  setIsReachDeadline: (x: boolean) => void
  setHelperText: (x: string) => void
  setShowHeader: (x: boolean) => void
  setIsLoadedDeadline: (x: boolean) => void
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

const useTestBlock = (props: IProps) => {
  const {
    isTimer,
    isManual,
    block,
    team,
    isReachDeadline,
    setIsReachDeadline,
    setIsReachPredeadline,
    setHelperText,
    setShowHeader,
    setIsLoadedDeadline,
  } = props
  const tests = useMemo(() => block.components.map(c => ({ ...c, ...JSON.parse(c.props) })), [block.id])
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(tests.length).fill({}))
  const [results, setResults] = useState(new Array(tests.length).fill(null))
  const [ready, setReady] = useState(false)
  const [currentTeam, setCurrentTeam] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const fileInput = useRef<React.RefObject<File>[]>([])
  const userEmail = localStorage.getItem("email")
  const localStorageKey = `${userEmail}_test_${block.id}`
  const showResult = !isTimer || isReachDeadline
  usePreventLeavingOnLoading(isManual, isTimer)

  useEffect(() => {
    restartTest()
    block.deadline && checkDeadline()
    isTimer && getStoredAnswers()
    team && fetchTeam()
  }, [block.id])

  function restartTest() {
    fileInput.current = []
    setResults(results.map(e => null))
    setIsReachDeadline(!block.deadline)
    setIsReachPredeadline(!block.deadline)
    setSelectedAnswers(new Array(tests.length).fill({}))
  }

  async function checkDeadline() {
    const predeadline = await getDeadline(block.id, true)
    const dealine = await getDeadline(block.id, false)
    setIsReachPredeadline(predeadline.is_deadline)
    setIsReachDeadline(dealine.is_deadline)
    setIsLoadedDeadline(true)
  }

  async function fetchTeam() {
    try {
      const responseTeam = await getTeam(team)
      setCurrentTeam(responseTeam)
    } catch {}
  }

  function syncAnswers(value?: ObjectValueType[]) {
    // без задержки задачи из разных тестов сохраняются в один локалстор
    setTimeout(() => {
      localStorage.setItem(localStorageKey, JSON.stringify(value))
    })
  }

  function getStoredAnswers() {
    const storedAnswers = localStorage.getItem(localStorageKey) || ""
    const parsedAnswers = storedAnswers ? JSON.parse(storedAnswers) : []
    setSelectedAnswers(parsedAnswers)
  }

  function clearLocalStore() {
    localStorage.removeItem(localStorageKey)
  }

  function handleResultModal() {
    localStorage.setItem(localStorageKey + "_result_modal", "viewed")
  }

  const handleChangeChoice = (event: ChangeEvent) => {
    event.persist()
    for (let index = 0; index < tests.length; index++) {
      if (event.target.name == tests[index].index) {
        setSelectedAnswers(c => {
          const newValue = changeByIndex(c, index, { ...c[index], [event.target.value]: event.target.checked })
          isTimer && syncAnswers(newValue)
          return newValue
        })
        setHelperText(" ")
        setResults(c => changeByIndex(c, index, null))
      }
    }
  }

  const handleChangeSingleChoice = (event: ChangeEvent, value: number) => {
    event.persist()
    for (let index = 0; index < tests.length; index++) {
      if (event.target.name == tests[index].index) {
        setSelectedAnswers(c => {
          const newValue = changeByIndex(c, index, [value])
          isTimer && syncAnswers(newValue)
          return newValue
        })
        setHelperText(" ")
        setResults(c => changeByIndex(c, index, null))
      }
    }
  }

  const handleChangeDetailed = (event: ChangeEvent) => {
    event.persist()
    for (let index = 0; index < tests.length; index++) {
      if (event.target.name == tests[index].index) {
        setSelectedAnswers(c => {
          const newValue = changeByIndex(c, index, { [event.target.name]: event.target.value })
          isTimer && syncAnswers(newValue)
          return newValue
        })
        setHelperText(" ")
        setResults(c => changeByIndex(c, index, null))
      }
    }
  }

  const handleChangeDragAndDrop = (index: number, value: number, isPreventOverall: boolean) => {
    setSelectedAnswers(c => {
      const newValue = changeByIndex(c, index, value)
      isTimer && syncAnswers(newValue)
      return newValue
    })
    setHelperText(" ")
    setResults(prev => (isPreventOverall ? prev : changeByIndex(prev, index, null)))
  }

  async function sendFile(file: File, question_index: number, submission_id: number) {
    let filename_split = file.name?.split(".")
    try {
      const payload = {
        submission_id,
        question_index,
        blockId: block.id,
        filename_split,
        file,
      }
      await putAssignmentAttach(payload)
      setIsLoading(false)
      setHelperText("Ответы отправлены")
      setShowHeader(!isTimer)
    } catch {
      setIsLoading(false)
      setHelperText("Файл не отправлен, так как превышен допустимый размер (25 мб)")
    }
  }

  const handleErrors = (status: number) => {
    if (status === 403) {
      setHelperText("Отказано в доступе")
    } else if (status === 406) {
      setHelperText("Превышено число попыток")
    } else if (status === 412) {
      setHelperText("Период сдачи завершён")
    } else if (status === 417) {
      setHelperText("Ваша работа уже отправлена и передана на проверку")
    } else if (status === 423) {
      setHelperText("Ваша работа уже принята")
    } else {
      setHelperText("Неизвестная ошибка")
    }
  }

  function questionMapper(question: { type: string; id: number }, index: number) {
    switch (question.type) {
      case "choice":
        return (
          <Choice
            question={question}
            value={selectedAnswers[index]}
            handleChange={handleChangeChoice}
            result={showResult && results[index]}
            shuffle={fyShuffle}
          />
        )
      case "single-choice":
        return (
          <SingleChoice
            question={question}
            value={selectedAnswers[index]}
            handleChange={handleChangeSingleChoice}
            result={showResult && results[index]}
            shuffle={fyShuffle}
          />
        )
      case "matrix":
        return (
          <Matrix
            question={question}
            value={selectedAnswers[index]}
            handleChange={handleChangeChoice}
            result={showResult && results[index]}
            shuffle={fyShuffle}
          />
        )
      case "detailed":
        return (
          <Detailed
            question={question}
            value={selectedAnswers[index]}
            handleChange={handleChangeDetailed}
            result={showResult && results[index]}
            inputType="text"
          />
        )
      case "numeric":
        return (
          <Detailed
            question={question}
            value={selectedAnswers[index]}
            handleChange={handleChangeDetailed}
            result={showResult && results[index]}
            inputType="number"
          />
        )
      case "attach":
        // @ts-ignore
        return <Attach fileInput={fileInput} index={index} id={question.id} question={question} />
      case "match":
        return (
          <Match
            question={question}
            value={selectedAnswers[index]}
            index={index}
            handleChange={handleChangeDragAndDrop}
            result={showResult && results[index]}
            shuffle={fyShuffle}
            ready={ready}
          />
        )
      case "sort":
        return (
          <Sort
            question={question}
            value={selectedAnswers[index]}
            index={index}
            handleChange={handleChangeDragAndDrop}
            result={showResult && results[index]}
            shuffle={fyShuffle}
            ready={ready}
          />
        )
      default:
        return
    }
  }

  return {
    results,
    isReachDeadline,
    isLoading,
    setIsLoading,
    selectedAnswers,
    setSelectedAnswers,
    setResults,
    setReady,
    currentTeam,
    tests,
    questionMapper,
    sendFile,
    handleErrors,
    fileInput,
    clearLocalStore,
    handleResultModal,
  }
}

const usePreventLeavingOnLoading = (isManual: boolean, isTimer: boolean) => {
  // @ts-ignore
  const isUploadingFile = useSelector(state => state.test.isUploadingFile)

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isUploadingFile && isManual && !isTimer) {
        const message = "Вы уверены, что хотите покинуть страницу?"
        event.returnValue = message
        return message
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [isUploadingFile])
}

export default useTestBlock
