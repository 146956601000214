import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useStyles from "../../pages/admin/styles";

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }} ></div>
  } else {
    return text
  }
}

export const ChoiceTypeEditor = (props) => {
  const classes = useStyles()

  const [editorOnIndex, setEditorOnIndex] = useState(null)
  const [text, setText] = useState("")
  const [question, setQuestion] = useState(props.question)

  const handleChangeChoice = (index) => {
    question.variants[index].checked = !question.variants[index].checked
    setQuestion({...question})
  }

  function addVariant() {
    question.variants.push({ value: question.variants.length + 1, label: text, checked: false })
    setQuestion({...question})
  }

  function changeVariant(value) {
    question.variants[editorOnIndex].label = value
    setQuestion({...question})
  }

  function removeVariant(index) {
    question.variants.splice(index, 1)
    setQuestion({...question})
  }

  return (
    <>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
      <TextField
        id="answer-text-editor"
        variant="outlined"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder="Текст ответа"
        fullWidth
        multiline
        style={{ margin: 12, minWidth: 360 }}
      />
      <Button onClick={addVariant} style={{ margin: 12, width: 180 }}>Добавить вариант ответа</Button>
    </div>
    <FormGroup style={{ maxWidth: 800, margin: 12 }}>
      {
        question.variants.map((c, index) => (
          <FormControlLabel
            className={classes.testCheckbox}
            classes={{ label: classes.testCheckboxLabel }}
            control={<Checkbox color="primary" checked={c.checked} onChange={() => handleChangeChoice(index)} value={c.value} />}
            label={editorOnIndex === index ? (
              <TextField
                id="label-editor"
                variant="outlined"
                value={c.label}
                onChange={e => changeVariant(e.target.value)}
                onKeyDown={e => {if (e.key == "Enter") { setEditorOnIndex(null) }}}
                fullWidth
              />
            ) : (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <Typography onDoubleClick={() => setEditorOnIndex(index)}>{parseText(c.label)}</Typography>
                <Button onClick={() => removeVariant(index)}>Удалить</Button>
              </div>
            )}
          />
        ))
      }
    </FormGroup>
    </>
  )
}
