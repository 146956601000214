import { useCallback, useState } from "react"

export interface IExtendFilters {
  course?: IExtendFilter
  stage?: IExtendFilter
  module?: IExtendFilter
  email?: IExtendFilter
}

export interface IExtendFilter {
  value: string | number
  name: string
  label?: string
  id?: number | string
}

interface IInitialFilters {
  [x: string]: IExtendFilter
}

export const useExtendFiltersForm = (initialFilters?: IInitialFilters) => {
  const [extendFilters, setExtendFilters] = useState<IExtendFilters>(initialFilters || {})

  const handleExtendFilters = useCallback((filter: { target: IExtendFilter }) => {
    if (filter.target.value === "" || filter.target.value === null) {
      setExtendFilters(prev => removeFilter(filter.target.name, prev))
    } else {
      const newFilter = {
        [filter.target.name]: { value: filter.target.value, label: filter.target.label, id: filter.target.id },
      }

      setExtendFilters(prev => {
        return { ...prev, ...newFilter }
      })
    }
  }, [])

  const resetFilters = () => {
    setExtendFilters(initialFilters || {})
  }

  return {
    extendFilters,
    handleExtendFilters,
    setExtendFilters,
    resetFilters,
  }
}

const removeFilter = (filter: string, prevFilters: IExtendFilters) => {
  const newObj = Object.keys(prevFilters).reduce((result: IExtendFilters, key) => {
    if (key !== filter) {
      // @ts-ignore
      result[key] = prevFilters[key]
    }
    return result
  }, {})

  return newObj
}
