import { useEffect, useState } from "react"
import * as Styled from "./ui/Teams.style.js"
import { Autocomplete, Button, ShadowedSection } from "shared/ui"
import { CircularProgress, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { FailResponse, OpenResponse, SuccessResponse } from "entities/Team/static/Responses.js"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import SuccessIcon from "images/icons/Success.js"
import FailIcon from "images/icons/Fail.js"
import Cookies from "js-cookie"

const SendRequest = () => {
  const theme = useTheme()
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState(null)
  const [teamOptions, setTeamOptions] = useState([])
  const [team, setTeam] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [alert, setAlert] = useState()
  const [helperText, setHelperTest] = useState("")
  const isOpenTeam = team?.access_type === "open"
  const buttonLabel = isOpenTeam ? "Вступить в команду" : "Запросить приглашение"

  useEffect(() => {
    setIsLoading(true)
    getMyCourses()
  }, [])

  useEffect(() => {
    setAlert(null)
    getHelperText()
  }, [course, team])

  const getHelperText = () => {
    switch (team?.access_type) {
      case "open":
        setHelperTest("Любой пользователь может вступить в данную команду без одобрения Администратора")
        break
      default:
        setHelperTest("")
    }
  }

  const handleCourse = (e, course) => {
    if (!course) {
      setCourse(null)
      setTeam("")
      return
    }
    setCourse(prev => {
      if (prev !== course) {
        setTeam("")
        return course
      }
    })
    axios
      .get("content/actions/teams/open_teams/", { params: { course: course.id } })
      .then(res => {
        setTeamOptions(res.data.map(team => ({ id: team.id, label: team.title, access_type: team.access_type })))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleTeam = (e, team) => {
    setTeam(team)
  }

  const sendRequest = () => {
    setIsLoading(true)
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`content/actions/teams/${team.id}/request_access/`)
      .then(res => {
        if (res.status === 201) {
          setAlert({ status: "success", msg: isOpenTeam ? OpenResponse : SuccessResponse })
        }
      })
      .catch(res => {
        if (res.response.status === 412) {
          setAlert({ status: "success", msg: SuccessResponse })
        } else if (res.response.status === 409) {
          setAlert({ status: "success", msg: "Вы уже состоите в этой команде" })
        } else if (res.response.status === 416) {
          setAlert({
            status: "fail",
            msg: 'Вы достигли предела по участию в командах с категорией "Без роли" на курсе. Покиньте одну из ваших команд с категорией "Без роли"',
          })
        } else {
          setAlert({ status: "fail", msg: FailResponse })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getMyCourses() {
    axios
      .get("content/actions/participants/my_courses/?for_teams=true")
      .then(res => {
        setCourses(res.data.map(course => ({ id: course.course, label: course.course_title })))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ShadowedSection>
      <Styled.SendRequest>
        <Typography variant={"h4"}>Отправить запрос на приглашение в команду</Typography>
        <Styled.ChooseCourse>
          <Autocomplete
            style={{ width: theme.spacing(108) }}
            label={"Название курса"}
            value={course}
            onChange={handleCourse}
            options={courses}
          />
          <Button
            variant={"outlined"}
            color={"primary"}
            height={"medium"}
            disabled={!team || isLoading}
            onClick={sendRequest}
          >
            {buttonLabel}
          </Button>
          {isLoading && <CircularProgress color={"primary"} />}
        </Styled.ChooseCourse>
        <Styled.ChooseTeam>
          <Styled.TeamButtonWrapper>
            <Autocomplete
              label={"Название команды"}
              value={team}
              options={teamOptions}
              onChange={handleTeam}
              disabled={!course}
            />
          </Styled.TeamButtonWrapper>
          <Styled.HelperText>{helperText}</Styled.HelperText>
        </Styled.ChooseTeam>
      </Styled.SendRequest>
      {alert && (
        <Styled.AlertWrapper sx={{ marginTop: "36px" }}>
          <Styled.IconWrapper>{alert.status === "success" ? <SuccessIcon /> : <FailIcon />}</Styled.IconWrapper>
          <Typography variant={"caption"} fontSize={"small"} fontWeight={"small"}>
            {alert.msg}
          </Typography>
        </Styled.AlertWrapper>
      )}
    </ShadowedSection>
  )
}

export default SendRequest
