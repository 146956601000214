//@ts-nocheck
import React, { useEffect, useState } from "react"
import * as Styled from "./ui/Iot.styles"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { Autocomplete, Button, Card, Modal } from "shared/ui"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { iotActions } from "shared/store"
import { fetchUsers, Hardware as HardwareType, HardwareConnectionStatus, UserType } from "entities/Iot"
import SettingsIcon from "@mui/icons-material/Settings"
import AddIcon from "@mui/icons-material/Add"
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
import { useTheme } from "shared/context"
import { AddHardware, EditHardware, AddUser } from "features"
import { TableOfSensors } from "./ui/TableOfSensors"
import { UsersTable } from "./ui/UsersTable"

export const Hardware = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const hardware = useAppSelector(state => state.iot.hardwareList)
  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const connectionStatus = useAppSelector(state => state.iot.connectionStatus)
  const users = useAppSelector(state => state.iot.users)
  const [openAddHardware, setOpenAddHardware] = useState(false)
  const [openEditHardware, setOpenEditHardware] = useState(false)
  const [openAddUser, setOpenAddUser] = useState(false)
  const [openPanel, setOpenPanel] = useState(false)
  const [openSensorsModal, setOpenSensorsModal] = useState(false)
  const [openUsersModal, setOpenUsersModal] = useState(false)

  useEffect(() => {
    if (!!currentHardware) {
      dispatch(fetchUsers(currentHardware?.serial))
    }
  }, [currentHardware])

  function handleClickOpenPanel() {
    setOpenPanel(prevState => !prevState)
  }

  function handleChangeAutocomplete(event: React.ChangeEvent<{}>, newValue: { label: string; value: number }) {
    dispatch(iotActions.setCurrentHardware(newValue))
    dispatch(iotActions.resetChartSettings())
  }

  return (
    <Styled.HardwareContainer>
      <AddHardware isOpen={openAddHardware} onClose={() => setOpenAddHardware(false)} />
      <EditHardware isOpen={openEditHardware} onClose={() => setOpenEditHardware(false)} />
      <AddUser isOpen={openAddUser} onClose={() => setOpenAddUser(false)} />
      <Modal
        isOpen={openSensorsModal}
        onClose={() => setOpenSensorsModal(false)}
        title={`Список датчиков устройства ${currentHardware?.name}`}
      >
        <TableOfSensors />
      </Modal>
      <Modal
        title={`Пользователи устройства ${currentHardware?.name}`}
        isOpen={openUsersModal}
        onClose={() => setOpenUsersModal(false)}
      >
        <UsersTable />
      </Modal>
      <Styled.PanelContainer>
        <div style={{ height: "40px" }}>
          {/*@ts-ignore*/}
          <Autocomplete
            value={!!currentHardware ? { label: currentHardware?.name, value: currentHardware?.id } : null}
            style={{ [theme.breakpoints.down("xs")]: { width: "200px" } }}
            options={hardware?.map((e: HardwareType) => ({ label: e.name, value: e.id }))}
            onChange={handleChangeAutocomplete}
            label={"Выберите устройство"}
          />
        </div>
        {!!currentHardware && <HardwareConnectionStatus status={connectionStatus} />}
        {!openPanel && <Styled.ArrowDown onClick={handleClickOpenPanel} />}
        {openPanel && <Styled.ArrowUp onClick={handleClickOpenPanel} />}
      </Styled.PanelContainer>
      {openPanel && (
        <>
          {!!currentHardware && (
            <>
              <Button variant={"outlined"} color={"primary"} onClick={() => setOpenEditHardware(true)}>
                Параметры
                <SettingsIcon sx={{ marginLeft: theme.spacing(2) }} color={"primary"} />
              </Button>
              {users.length > 0 && (
                <Styled.UsersButtonContainer>
                  <Button variant={"outlined"} color={"primary"} onClick={() => setOpenUsersModal(true)}>
                    Пользователи
                  </Button>
                  <Button variant={"outlined"} color={"primary"} onClick={() => setOpenAddUser(true)}>
                    <PersonAddAlt1Icon color={"primary"} />
                  </Button>
                </Styled.UsersButtonContainer>
              )}
              <Button variant={"outlined"} color={"primary"} onClick={() => setOpenSensorsModal(true)}>
                Список датчиков
              </Button>
            </>
          )}
          <Button variant={"outlined"} color={"primary"} onClick={() => setOpenAddHardware(true)}>
            Новое устройство
            <AddIcon sx={{ marginLeft: theme.spacing(2) }} color={"primary"} />
          </Button>
        </>
      )}
    </Styled.HardwareContainer>
  )
}
