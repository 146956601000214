import styled from "@emotion/styled"

export const Card = styled.div`
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px 16px;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  margin-bottom: 19px;
`

export const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0001em;
  color: #313439;
  margin: unset;
`

export const Options = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 24px;
  width: calc(100% + 32px);
  margin-inline: -16px;
  max-height: 200px;
  overflow-y: auto;
`

export const Option = styled.li<{ isSelectable?: boolean; isActive?: boolean }>`
  padding-left: 12px;
  padding-block: 8px;
  transition: background-color 0.3s;
  background-color: ${props => props.isActive && "#dee7f2"};
  cursor: ${props => props.isSelectable && "pointer"};

  &:hover {
    background-color: ${props => props.isSelectable && !props.isActive && "rgba(0, 0, 0, 0.2)"};
  }
`
