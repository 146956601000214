import React, { useEffect, useRef, useState } from "react"
import { Button, Line } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { EventItem } from "entities/Course"
import useStyles from "./styles"
import { Grid, Typography } from "@mui/material"
import { WebinarCard } from "entities/Webinar"
import { object } from "yup"

const ProjectList = ({ e, handleChangeRef, organization, org_slug }) => {
  const [events, setEvents] = useState([])
  const [webinars, setWebinars] = useState([])
  const classes = useStyles()

  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  function handleClick() {
    window.location.href = `/catalog/organization/${organization}`
  }

  useEffect(() => {
    const ref_token = localStorage.getItem("token_ref")
    if (!ref_token) {
      delete axios.defaults.headers.common["Authorization"]
    }
    axios.get(`/content/courses/`, { params: { type: "event", owner: organization } }).then(res => {
      setEvents(
        res.data.sort((a, b) => (a.rating > b.rating ? 1 : -1)).sort((a, b) => (a.is_active < b.is_active ? 1 : -1))
      )
    })
    axios.get(`/content/courses/?type=webinar&owner=${organization}`).then(res => {
      setWebinars([...res.data.slice(-3)])
    })
  }, [])

  return (
    <div ref={ref} className={classes.projectContainer} style={{ background: e.backgroundColor }}>
      {!!e.H2 && (
        <Typography variant={"h2"} sx={{ color: e.H2.color }}>
          {e.H2.text}
        </Typography>
      )}
      <div className={classes.eventsContainer}>
        <>
          {events.slice(-3).map(event => (
            <EventItem key={event.id} post={event} org_slug={org_slug} />
          ))}
          <Grid container spacing={6}>
            {webinars.map(e => (
              <WebinarCard webinar={e} />
            ))}
          </Grid>
        </>
      </div>
      {!!e.button && (
        <div className={classes.btnEventsAll}>
          <Button
            sx={{
              backgroundColor: e?.button?.backgroundColor,
              color: e?.button?.color,
              "&:hover": { backgroundColor: e?.button?.hoverBackgroundColor },
            }}
            height={"large"}
            onClick={handleClick}
          >
            {e.button.text}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProjectList
