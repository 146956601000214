import { IconButton as MuiIconButton } from "@mui/material"
import { styled } from "@mui/material/styles"

export const IconButton = ({ id, color, size, disabled, children, onClick }) => {
  const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
    "&.MuiIconButton-colorPrimary": {
      borderRadius: theme.spacing(2),
      background: theme.palette.primary.main,
      "&:hover": {
        background: theme.palette.primary.dark,
      },
      "&:disabled": {
        opacity: 0.6,
      },
    },
    "&.MuiIconButton-colorInherit": {
      borderRadius: theme.spacing(2),
      border: `1px solid ${theme.palette.primary.dark}`,
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      "&:disabled": {
        opacity: 0.6,
      },
    },
    "&.MuiIconButton-colorSecondary": {
      borderRadius: theme.spacing(2),
      background: theme.palette.secondary.main,
      "&:hover": {
        background: theme.palette.secondary.dark,
      },
      "&:disabled": {
        opacity: 0.6,
      },
    },
    "&.MuiIconButton-colorAccent": {
      borderRadius: theme.spacing(2),
      color: theme.palette.accent.contrastText,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.accent.main}`,
      "& svg": {
        color: theme.palette.accent.main,
      },
      "&:hover": {
        color: theme.palette.accent.main,
        backgroundColor: theme.palette.accent.main,
        "& svg": {
          color: "white",
        },
      },
      "&:disabled": {
        background: theme.palette.accent.light,
      },
    },
    "&.MuiIconButton-sizeLarge_x": {
      borderRadius: theme.spacing(3),
      height: theme.spacing(16),
      width: theme.spacing(16),
    },
    "&.MuiIconButton-sizeLarge": {
      borderRadius: theme.spacing(3),
      height: theme.spacing(12),
      width: theme.spacing(12),
    },
    "&.MuiIconButton-sizeMedium": {
      borderRadius: theme.spacing(2),
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
    "&.MuiIconButton-sizeSmall": {
      borderRadius: theme.spacing(2),
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
    "&.MuiIconButton-sizeSmall_x": {
      borderRadius: theme.spacing(2),
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  }))

  return (
    <StyledIconButton id={id} color={color} size={size} onClick={onClick} disabled={disabled}>
      {children}
    </StyledIconButton>
  )
}
