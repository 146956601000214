import {makeStyles} from "@mui/styles";
import { width } from "@mui/system";

export default makeStyles(theme => ({

    tabsWrapper: {
        position:"relative",
       /* [theme.breakpoints.down("md")] : {
            position:"fixed",
            width:48,
            height:38,
            backgroundColor:"#e8e8e8",
            top:12,
            right:118,
            overflow:"hidden",
            zIndex:1
        },*/
    
    },

    mainTabs: {
        paddingLeft: 124,
        paddingRight: 124,
        
        [theme.breakpoints.down("md")] : {
            paddingLeft: 100,
            paddingRight: 100,
            /*padding:0,
            display:"none",*/
        },
        [theme.breakpoints.down("sm")] : {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },

}))
