import {createSlice} from "@reduxjs/toolkit";
import {fetchCertificates} from "./certificateActions";


const certificateSlice = createSlice({
    name: "certificate",
    initialState: {
      certificates: []
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCertificates.fulfilled, (state, action) => {
            state.certificates = action.payload.map(e => ({ ...e, extra_data: JSON.parse(e.extra_data)}))
        })
    }
})

export const {reducer, actions} = certificateSlice
