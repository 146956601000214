import * as Styled from "./Organizations.styles"
import { useState } from "react"
import { SendInvitationForModerator } from "features/Organization/SendInvitationForModerator"
import { Typography } from "@mui/material"
import { MembersTable } from "./MembersTable"
import { SelectCourse } from "./SelectCourse"
import NotificationCreate from "./NotificationCreate"
import { ParticipantsRequested, ParticipantsOther, ParticipantsInProgress } from "features"
import { OrganizationMember } from "entities/Organization/model/Organization.types"

export const ParticipantManagement = () => {
  const [isTriggered, setIsTriggered] = useState(false)
  const [members, setMembers] = useState<Array<OrganizationMember>>([])

  const triggerReloading = () => {
    setIsTriggered(true)
  }

  return (
    <Styled.ParticipantManagementMainDiv>
      <SelectCourse />
      <ParticipantsRequested
        triggerReloading={triggerReloading}
        pageLocation={"organizations"}
        courseKey={'participantsAndRequestsCourse'}
      />
      <ParticipantsInProgress
        isTriggered={isTriggered}
        setIsTriggered={setIsTriggered}
        pageLocation={"organizations"}
        courseKey={'participantsAndRequestsCourse'}
      />
      <ParticipantsOther
        isTriggered={isTriggered}
        setIsTriggered={setIsTriggered}
        pageLocation={"organizations"}
        courseKey={'participantsAndRequestsCourse'}
      />
      <Typography variant={"h3"}>Таблица участников организации</Typography>
      <SendInvitationForModerator setMembers={setMembers} />
      <NotificationCreate />
      <MembersTable members={members} setMembers={setMembers} />
    </Styled.ParticipantManagementMainDiv>
  )
}
