import React, { useCallback, useEffect, useState } from "react"
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material"
import { axiosInstance as axios, axiosInstance } from "../../shared/api/axiosInstance"
import { useTheme } from "../../shared/context"

export const Questionnaire = props => {
  const theme = useTheme()
  const [fields, setFields] = useState(
    props.fields
      .sort((a, b) => a.index - b.index)
      .map(e => ({ id: e.id, name: e.title, value: "", is_required: e.is_required }))
  )
  const [subjectOptions, setSubjectOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  var abortController = new AbortController()

  const notEmptyValidator = useCallback(value => {
    return value !== ""
  }, [])

  useEffect(() => {
    props.setValues(
      props.fields
        .sort((a, b) => a.index - b.index)
        .map(e => ({ id: e.id, name: e.title, value: "", is_required: e.is_required }))
    )
  }, [props.fields])

  useEffect(() => {
    console.log(fields)
  }, [fields])

  const lengthValidator = useCallback(value => {
    return value.length === 1000
  })

  function fetchSubjects(startsWith, type) {
    abortController.abort() // прерываем предыдущий запрос
    let newAbortController = new AbortController()
    axios
      .get("/geodata/", {
        params: { page: 1, type: type, name: startsWith },
        signal: newAbortController.signal,
      })
      .then(res => {
        setSubjectOptions(res.data.map(e => e.name))
      })
      .catch(error => {})
    abortController = newAbortController
  }

  function fetchCity(idx, startsWith) {
    if (startsWith === null) startsWith = ""
    if (startsWith.length <= 1000) {
      fields[idx].value = startsWith
      setFields([...fields])
      props.setValues([...fields])
    }
    abortController.abort() // прерываем предыдущий запрос
    let newAbortController = new AbortController()
    axios
      .get("/geodata/", {
        params: { page: 1, city: startsWith },
        signal: newAbortController.signal,
      })
      .then(res => {
        setCityOptions([...res.data.results.map(e => e.name)])
      })
      .catch(error => {})
    abortController = newAbortController
  }

  function handleChangeField(idx, newValue) {
    if (newValue === null) newValue = ""
    if (newValue.length <= 1000) {
      fields[idx].value = newValue
      setFields([...fields])
      props.setValues([...fields])
    }
  }

  function handleChangeCheckBox(event, option, idx, index) {
    let arr = fields[idx].value.split(",")
    let value = option[index]
    if (event.target.checked === true) arr.push(value)
    else {
      let indexToRemove = arr.indexOf(value)
      if (indexToRemove !== -1) {
        arr.splice(indexToRemove, 1)
      }
    }
    if (arr.length > 0 && arr[0].trim() === "") {
      arr.splice(0, 1)
    }
    handleChangeField(idx, arr.toString())
  }

  function questionsMapper(e, idx) {
    let props = JSON.parse(e.props)
    let options = null
    if (props.options) options = props.options
    switch (e.type) {
      case "RadioButton":
        return (
          <FormControl>
            <RadioGroup
              row
              aria-label={"Label"}
              name={e.title}
              value={fields[idx].value}
              onChange={event => handleChangeField(idx, event.target.value)}
            >
              {!!options &&
                options.map(option => <FormControlLabel value={option} control={<Radio />} label={option} />)}
            </RadioGroup>
          </FormControl>
        )
      case "Checkbox":
        return (
          <FormControl>
            {!!options &&
              options.map((option, index) => (
                <FormControlLabel
                  control={<Checkbox onChange={event => handleChangeCheckBox(event, options, idx, index)} />}
                  label={option}
                />
              ))}
          </FormControl>
        )
      case "Select":
        return (
          <div style={{ width: "100%" }}>
            <FormControl sx={{ m: 1, minWidth: "50%", [theme.breakpoints.down("sm")]: { width: "100%" } }}>
              <InputLabel>{"Выберите из списка"}</InputLabel>
              <Select
                sx={{ [theme.breakpoints.down("sm")]: { width: "100%" } }}
                variant={"outlined"}
                value={fields[idx].value}
                input={<OutlinedInput label={"Выберите из списка"} />}
                onChange={event => handleChangeField(idx, event.target.value)}
              >
                {!!options && options.map(option => <MenuItem value={option}>{option}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
        )
      case "AutoComplete":
        return (
          <Autocomplete
            freeSolo={true}
            sx={{ width: "50%", [theme.breakpoints.down("sm")]: { width: "100%" } }}
            value={fields[idx].value}
            options={!!options ? options : []}
            onChange={(event, newValue) => handleChangeField(idx, newValue)}
            renderInput={params => <TextField {...params} label={"Выберите из списка"} />}
          />
        )
      case "TextField":
        return (
          <TextField
            fullWidth={props.fullWidth}
            sx={{ minWidth: "50%", [theme.breakpoints.down("sm")]: { minWidth: "100%" } }}
            label={"Введите текст"}
            value={fields[idx].value}
            onChange={event => handleChangeField(idx, event.target.value)}
          />
        )
      case "Geo_City":
        return (
          <Autocomplete
            sx={{ width: "50%", [theme.breakpoints.down("sm")]: { width: "100%" } }}
            noOptionsText={
              "Город не найден, проверьте правильность введённых данных. Если всё заполнено верно, можете перейти к следующему вопросу."
            }
            helperText={
              cityOptions.length > 0
                ? "Город не найден, проверьте правильность введённых данных. Если всё заполнено верно, можете перейти к следующему вопросу."
                : ""
            }
            value={fields[idx].value}
            onInputChange={(e, value) => fetchCity(idx, value)}
            options={!!cityOptions ? cityOptions : []}
            onChange={(event, newValue) => handleChangeField(idx, newValue)}
            renderInput={params => <TextField {...params} label={"Выберите город из списка"} />}
          />
        )
      case "Geo_Subject":
        return (
          <Autocomplete
            sx={{ width: "50%", [theme.breakpoints.down("sm")]: { width: "100%" } }}
            noOptionsText={"Субъект не найден."}
            value={fields[idx].value}
            onInputChange={(e, value) => fetchSubjects(value, "subjects")}
            options={!!subjectOptions ? subjectOptions : []}
            onChange={(event, newValue) => handleChangeField(idx, newValue)}
            renderInput={params => <TextField {...params} label={"Выберите субъект из списка"} />}
          />
        )
      default:
        return <div></div>
    }
  }

  return (
    <div>
      <div>
        {props.fields
          .sort((a, b) => a.index - b.index)
          .map((field, index) => (
            <div key={index}>
              <h3>{field.title}</h3>
              {questionsMapper(field, index)}
              {field.is_required &&
                (!notEmptyValidator(fields[index].value) || lengthValidator(fields[index].value)) && (
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {fields[index].value.length === 1000
                      ? `Превышено максимальное количество символов (1000)`
                      : `Пожалуйста, заполните поле`}
                  </div>
                )}
            </div>
          ))}
      </div>
    </div>
  )
}
