import React from "react"
import { Avatar, Typography } from "@mui/material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { useTheme } from "shared/context"

export const UserIcon = ({ user }) => {
  const theme = useTheme()

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      {user.avatar ? (
        <Avatar style={{ height: "30px", width: "30px" }} src={user.avatar}></Avatar>
      ) : (
        <AccountCircleIcon fontSize="large" sx={{ color: "#000" }} />
      )}
      {user.first_name && (
        <Typography
          fontSize={"small"}
          fontWeight={"small"}
          variant={"body2"}
          sx={{ marginLeft: theme.spacing(1), color: "#000" }}
        >
          {user.first_name[0] + user.last_name[0]}
        </Typography>
      )}
    </div>
  )
}
