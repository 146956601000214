import { useEffect, useState } from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { downloadOrganizationParticipants } from "entities/Organization/api"
import { sortStages } from "entities/Course"
import { downloadZipFile } from "shared/util/downloadZipFile"
import { IParticipant } from "entities/Participant/model/participant.types"
import { downloadCourseParticipants } from "entities/Course/api/course"
import { PageLocationType } from "../ui/ParticipantsInProgress"
import { formCategories, recieveParticipant } from "entities/Participant/lib"
import { inviteParticipantsHeaders } from "entities/Organization/static/ParticipantsTablesHeaders"
import { CourseKeyType, courseKeyDefault } from "shared/store/course/CourseSlice"

type UseParticipantsType = {
  pageLocation: PageLocationType,
  status: "requested" | "in_progress" | "other",
  isTriggered?: boolean,
  setIsTriggered?: (x: boolean) => void,
  courseKey?: CourseKeyType
}
const useParticipants = ({
  pageLocation,
  status,
  isTriggered,
  setIsTriggered,
  courseKey = courseKeyDefault
}: UseParticipantsType) => {
  const course = useAppSelector(state => state.course[courseKey])
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const [isLoading, setIsLoading] = useState(false)
  const [participants, setParticipants] = useState<IParticipant[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState<
    | {
        type: string
        direction: number
      }
    | undefined
  >()
  const [hasNextPage, setHasNextPage] = useState(false)
  const [allParticipants, setAllParticipants] = useState<number[]>([])
  const [allUsers, setAllUsers] = useState<number[]>([])
  const [filters, setFilters] = useState({})
  const [stages, setStages] = useState([])
  // @ts-ignore
  const categories = course
    ? formCategories(
        //@ts-ignore
        JSON.parse(course?.template_props)
          ?.categories?.split(", ")
          //@ts-ignore
          .filter(category => category !== "")
      ) || []
    : []
  const headers = getHeaders()

  useEffect(() => {
    if (course) fetchParticipants(true)
    if (course && status === "in_progress") fetchAllParticipants()
    if (course && status === "in_progress") fetchStages()
    setFilters({})
  }, [course])

  useEffect(() => {
    if (course && (status === "in_progress" || status === "other")) fetchParticipants(true)
    if (course && (status === "in_progress" || status === "other")) fetchAllParticipants()
    if (isTriggered && setIsTriggered) setIsTriggered(false)
  }, [isTriggered])

  async function fetchParticipants(isInit?: boolean) {
    if (!course?.id) return
    if (isInit) resetParticipants()
    try {
      setIsLoading(true)
      const payload = {
        stats: true,
        course: course.id,
        status: status,
        sort: sortBy?.type,
        direction: sortBy?.direction === 1 ? "asc" : "desc",
        page: isInit ? 1 : page,
        ...filters,
      }
      const response = await recieveParticipant(pageLocation, payload, organization?.id)
      if (!response) return
      setTotal(prev => response?.count ?? prev)
      setParticipants(prev => (isInit ? response.results : [...prev, ...response.results]))
      setPage(prev => prev + 1)
      setHasNextPage(prev => Boolean(response?.next !== null || prev))
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchAllParticipants(newFilters?: any) {
    if (!course?.id) return
    try {
      setIsLoading(true)
      const payload = {
        stats: true,
        course: course.id,
        status: status,
        no_pagination: true,
        ...newFilters,
      }
      const response = await recieveParticipant(pageLocation, payload, organization?.id)
      if (!response) return
      setAllParticipants(response.results.map((participant: any) => participant.id))
      setAllUsers(response.results.map((participant: any) => participant.user))
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchIncludingParticipants(sortBy: any) {
    if (!course?.id) return
    try {
      setIsLoading(true)
      const payload = {
        stats: true,
        course: course.id,
        status: status,
        sort: sortBy?.type,
        direction: sortBy?.direction === 1 ? "asc" : "desc",
        page: 1,
        ...filters,
      }
      const response = await recieveParticipant(pageLocation, payload, organization?.id)
      if (!response) return
      setTotal(prev => response.count ?? prev)
      setPage(2)
      setParticipants(response.results)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchFiltersParticipants(newFilters: any) {
    if (!course?.id) return
    resetParticipants()
    try {
      setIsLoading(true)
      const payload = {
        stats: true,
        course: course.id,
        status: status,
        sort: sortBy?.type,
        direction: sortBy?.direction === 1 ? "asc" : "desc",
        page: 1,
        ...newFilters,
      }
      const response = await recieveParticipant(pageLocation, payload, organization?.id)
      if (!response) return
      setTotal(prev => response.count ?? prev)
      setParticipants(response.results)
      setPage(2)
      setHasNextPage(prev => Boolean(response.next !== null || prev))
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const fetchStages = () => {
    // @ts-ignore
    setStages(course?.stages.filter(e => !e.is_empty).sort(sortStages))
  }

  async function fetchDownloadParticipants() {
    if (!course?.id) return
    try {
      setIsLoading(true)
      let response
      const payload = {
        stats: true,
        course: course.id,
        status: status,
        sort: sortBy?.type,
        direction: sortBy?.direction === 1 ? "asc" : "desc",
        download: "xlsx",
        ...filters,
      }
      if (pageLocation === "organizations" && organization?.id) {
        response = await downloadOrganizationParticipants(organization?.id, payload)
      } else if (pageLocation === "admin_courses") {
        response = await downloadCourseParticipants(payload)
      }
      downloadZipFile(response.data, `Файл заявок_${course?.id}.xlsx`)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const handleSort = (value: any) => {
    setSortBy(value)
    setParticipants([])
    fetchIncludingParticipants(value)
  }

  const handleFilters = (filters: any) => {
    const newFilters = Object.keys(filters).reduce((acc, key) => ({ ...acc, [key]: filters[key] }), {})
    setFilters(newFilters)
    fetchFiltersParticipants(newFilters)
    if (status === "in_progress") fetchAllParticipants(newFilters)
  }

  const resetParticipants = () => {
    setTotal(0)
    setParticipants([])
    setPage(1)
    setHasNextPage(false)
  }

  function getHeaders() {
    if (!course) return
    if (course?.type === "webinar") {
      return inviteParticipantsHeaders.filter(
        header => header.label !== "Команда" && header.label !== "Этап с огр. доступом"
      )
    }
    return inviteParticipantsHeaders
  }

  return {
    isLoading,
    participants,
    allParticipants,
    allUsers,
    total,
    hasNextPage,
    stages,
    categories,
    headers,
    handleSort,
    handleFilters,
    fetchParticipants,
    fetchDownloadParticipants,
    setParticipants,
    setTotal,
  }
}

export default useParticipants
