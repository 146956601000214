import React, { useMemo } from "react";
import useStyles from "./styles";

const Wire = ({ wire }) => {

  const classes = useStyles()

  const points = useMemo(() => {
    let segValues = [ ...wire[3] ]
    let currentEndpoints = [ wire[0], wire[1] ]
    let segments = `${currentEndpoints[0].x},${currentEndpoints[0].y} `
    let foundDelimiter = 0
    while (segValues.length > 0) {
      let segment
      if (foundDelimiter === 0) {
        segment = segValues.shift()
        if (segment === "*") {
          foundDelimiter = 1
          continue
        }
      } else {
        segment = segValues.pop()
      }
      let offset = parseFloat(segment.slice(1))
      segments += `
        ${currentEndpoints[foundDelimiter].x + (segment[0] === "h" ? offset : 0)},
        ${currentEndpoints[foundDelimiter].y + (segment[0] === "v" ? offset : 0)} 
      `;
      currentEndpoints[foundDelimiter].x += segment[0] === "h" ? offset : 0
      currentEndpoints[foundDelimiter].y += segment[0] === "v" ? offset : 0
    }
    segments += `
      ${currentEndpoints[1].x},
      ${currentEndpoints[0].y} 
    `;
    segments += `
      ${currentEndpoints[1].x},
      ${currentEndpoints[1].y}
    `;
    return segments
  }, [wire])

  return (
    <polyline className={classes.wire} points={points} fill="none" stroke={wire[2]} />
  )
}

export default Wire;
