import { styled } from "@mui/material/styles"
import { Theme } from "@mui/material"

export const FeatureListMainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(12),
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(16),
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const FeatureItemMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    gap: theme?.spacing(6),
    justifyContent: "column",
  },
}))

export const ImageItem = styled("img")(({ theme }) => ({
  width: "60%",
  padding: `${theme.spacing(12)} ${theme.spacing(12)}`,
  borderRadius: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}))

export const ContentItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}))
