import * as yup from "yup"

export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Заполните это поле.")
    .min(2, "Поле должно содержать не менее 2 символов.")
    .max(32, "Превышен лимит символов для ввода текста. Максимальное количество символов - 32."),
  email: yup
    .string()
    .email("Почты с таким названием не существует. Проверьте правильность введённых данных.")
    .required("Заполните это поле."),
  text: yup
    .string()
    .required("Заполните это поле.")
    .max(1000, "Превышен лимит символов для ввода текста. Максимальное количество символов - 1000."),
})
