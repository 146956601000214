//import React, {useCallback} from 'react';
import React from 'react';
import { Button } from '@mui/material';
import logo from '../../images/404.gif';
import useStyles from './styles';
// import './style.css';


const ErrorBlock = () => {

  const classes = useStyles();

  return (
    <div className={classes.error_block}>
      <h3>{'Страница не найдена'}</h3>
      <p>{'Запрашиваемой страницы не существует.'}</p>
      <p>{'Возможно, она была удалена, либо вы зашли по неверному адресу.'}</p>
      <Button id='error-button' className={classes.buttonError} variant="contained" href="/">{'НА ГЛАВНУЮ'}</Button>
      <img className={classes.img_404} src={logo} alt="error_image"/>
    </div>
  );
}

export default ErrorBlock;
