import WarningResetModal from "entities/Assignment/ui/WarningResetModal"
import { IModuleProgress } from "entities/Module/model/Module.types"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import { useState } from "react"
import { IExtendFilters } from "shared/hooks/useExtendFiltersForm"
import { Button } from "shared/ui"
import { Color, Variant } from "shared/ui/styles.const"
import { deleteCourseProgress, deleteModuleProgress } from "entities/Participant/api/participant"
import { ModuleType } from "../../entities/Module/api/module.types"

interface IProps {
  filters: IExtendFilters
  progress: IParticipantProgress
  setIsLoading: (x: boolean) => void
  setMessage: (x: { success: string; fail: string }) => void
  setProgress: any
  currentModule?: ModuleType
}

const ResetParticipantProgress = ({
  filters,
  progress,
  setIsLoading,
  setMessage,
  setProgress,
  currentModule,
}: IProps) => {
  const [isWarning, setIsWarning] = useState(false)
  const isDisabled = Boolean(filters.stage) && !filters.module

  const onResetProgress = async (setIsWarning: (x: boolean) => void) => {
    try {
      setIsLoading(true)
      setIsWarning(false)
      if (filters.email && !filters.stage && !filters.module) {
        await deleteCourseProgress(progress.id)
        setProgress((prev: IParticipantProgress) => ({ ...prev, module_progress: [], score: 0, stage_scores: {} }))
      } else if (filters.module) {
        const response = await deleteModuleProgress({ module: currentModule?.id, progress: progress.id })
        setProgress(response)
      }
      setMessage({ success: "Изменения сохранены.", fail: "" })
    } catch {
      setMessage({ success: "", fail: "Произошла ошибка." })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <WarningResetModal
        isOpen={isWarning}
        onClose={() => setIsWarning(false)}
        onClick={() => onResetProgress(setIsWarning)}
        filters={filters}
      />
      <Button onClick={() => setIsWarning(true)} variant={Variant.outlined} color={Color.error} disabled={isDisabled}>
        обнулить прогресс
      </Button>
    </>
  )
}

export default ResetParticipantProgress
