import React, { useState } from "react"
import { ErrorMapType, SectionType, SubsectionType } from "../api/module.types"
import * as Styled from "./Module.styles"
import { Button, TextField } from "../../../shared/ui"
import { Checkbox, FormControl, FormControlLabel, IconButton, Typography } from "@mui/material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Subsections } from "./Subsections"
import DeleteIcon from "@mui/icons-material/Delete"

export const SectionItem = ({
  section,
  provided,
  handleChangeSection,
  index,
  deleteSection,
  deletedSubsections,
  setDeletedSubsections,
  errors,
  disableDelete,
}: {
  disableDelete: boolean
  errors: ErrorMapType
  deletedSubsections: Array<number | null>
  setDeletedSubsections: React.Dispatch<React.SetStateAction<(number | null)[]>>
  section: SectionType
  index: number
  deleteSection: (section: SectionType) => void
  handleChangeSection: (index: number, field: keyof SectionType, value: boolean | string | SubsectionType[]) => void
  provided: {
    innerRef: any
    draggableProps: any
    dragHandleProps: any
  }
}) => {
  const [open, setOpen] = useState(false)

  function handleChangeSubsections(subsections: SubsectionType[]) {
    handleChangeSection(index, "subsections", [...subsections])
  }

  return (
    <Styled.CardTrajectoryContentItem
      sx={{ backgroundColor: open ? "#F7F9FE" : "#F9F9F9", zIndex: 500 }}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      <Styled.TrajectoryContentItem>
        <Styled.ContentItemHeader onClick={() => setOpen(!open)}>
          <Typography variant={"h4"}>{`${section.title}`}</Typography>
          <Styled.ButtonsContentItemWrapper>
            {open && (
              <Button
                disabled={disableDelete}
                variant={"outlined"}
                //@ts-ignore
                color={"accent"}
                onClick={() => deleteSection(section)}
              >
                <DeleteIcon sx={{ fontSize: "32px", marginRight: "8px" }} />
                Удалить раздел
              </Button>
            )}
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <KeyboardArrowUpIcon sx={{ color: "#000", fontSize: 32 }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#000", fontSize: 32 }} />
              )}
            </IconButton>
          </Styled.ButtonsContentItemWrapper>
        </Styled.ContentItemHeader>
        {open && (
          <>
            <TextField
              variant={"outlined"}
              value={section.title}
              style={{ width: "100%", marginTop: "24px" }}
              label={"Название раздела"}
              error={!!errors && !!errors[`sections[${index}].title`]}
              helperText={!!errors ? errors[`sections[${index}].title`] || "" : ""}
              onChange={e => handleChangeSection(index, "title", e.target.value)}
            />
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={section.is_active}
                    onChange={e => handleChangeSection(index, "is_active", e.target.checked)}
                  />
                }
                label="Раздел активен"
              />
            </FormControl>
            <Subsections
              section_index={index}
              errors={errors}
              section_id={section.id || -1}
              deletedSubsections={deletedSubsections}
              setDeletedSubsections={setDeletedSubsections}
              handleChangeSubsections={handleChangeSubsections}
              subsections={section.subsections}
            />
          </>
        )}
      </Styled.TrajectoryContentItem>
    </Styled.CardTrajectoryContentItem>
  )
}
