//@ts-nocheck
import React, { useEffect, useState } from "react"
import { fetchAllQuestions, QuestionsPull } from "../../entities/AdaptiveTest"
import { Typography } from "@mui/material"

export const QuestionsPreview = () => {
  const [questions, setQuestions] = useState<adminQuestions[]>([])

  function questionDiffMapper(lvl: number) {
    if (lvl === 1) return "A2"
    else if (lvl === 2) return "B1"
    else if (lvl == 3) return "B2"
    return ""
  }

  const loadQuestions = () => {
    fetchAllQuestions().then(res => {
      const sortedQuestions = res.data.sort((a, b) => {
        if (a.content_type < b.content_type) return -1
        if (a.content_type > b.content_type) return 1
        if (a.type < b.type) return -1
        if (a.type > b.type) return 1
        return a.difficulty_level - b.difficulty_level
      })
      setQuestions([...sortedQuestions])
    })
  }

  useEffect(() => {
    loadQuestions()
  }, [])

  return (
    <div>
      {questions.map(e => (
        <div style={{ marginTop: "44px", width: "70%" }}>
          <div style={{ width: "100%", height: "2px", backgroundColor: "#000" }}></div>
          <Typography>ID: {e.id}</Typography>
          <Typography>Правильные ответы: {e.correct_answer}</Typography>
          <Typography>Сложность: {questionDiffMapper(e.difficulty_level)}</Typography>
          <Typography>Сет: {e.type}</Typography>
          <Typography sx={{ marginBottom: "24px" }}>Тип вопроса: {e.content_type}</Typography>
          <QuestionsPull preview={true} questions={[e]} answers={[]} setAnswers={() => {}} />
          <br />
        </div>
      ))}
    </div>
  )
}
