import styled from "@emotion/styled"
import { Button } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useState } from "react"
// @ts-ignore
import Cookies from "js-cookie"
import { Typography } from "@mui/material"
import { invitationErrorHandler } from "../lib/invitationErrorHandler"
axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

interface IProps {
  additional_info: string
}

const ActionButtons = ({ additional_info }: IProps) => {
  const [reaction, setReaction] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const parsedInfo = JSON.parse(additional_info)
  const { accept_label, accept_url, reject_label, reject_url, restore_url, title, email } = parsedInfo
  const isInvitationReply = accept_url.includes("invitation_reply")

  const onAccept = async (e: React.MouseEvent<HTMLElement>) => {
    handleRequest(
      e,
      () => axios.put(accept_url, { title, user_email: email }),
      isInvitationReply
        ? "Вы приняли приглашение в команду"
        : "Вы приняли запрос пользователя на присоединение к команде",
      "accept"
    )
  }

  const onReject = async (e: React.MouseEvent<HTMLElement>) => {
    handleRequest(
      e,
      () => axios.patch(reject_url, { title, user_email: email }),
      isInvitationReply
        ? "Вы отклонили приглашение в команду"
        : "Вы отклонили запрос пользователя на присоединение к команде",
      "reject"
    )
  }

  const onRevert = async (e: React.MouseEvent<HTMLElement>) => {
    handleRequest(e, () => axios.put(restore_url, { title, user_email: email }), "")
  }

  const handleRequest = async (
    e: React.MouseEvent<HTMLElement>,
    func: () => Promise<void>,
    reaction: string,
    action?: "accept" | "reject"
  ) => {
    e.stopPropagation()
    setIsLoading(true)
    try {
      await func()
      setReaction(reaction)
    } catch (err: any) {
      const errResponse = invitationErrorHandler(isInvitationReply, err.response.status, action)
      setError(errResponse)
    } finally {
      setIsLoading(false)
    }
  }

  if (reaction)
    return (
      <>
        <ButtonsWrapper>
          <Button variant="outlined" size={"small"} sx={{ minWidth: "96px" }} onClick={onRevert} disabled={isLoading}>
            Вернуть
          </Button>
          <Typography variant={"caption"} fontWeight={"medium"}>
            {reaction}
          </Typography>
        </ButtonsWrapper>
        {error && <Error>{error}</Error>}
      </>
    )

  return (
    <>
      <ButtonsWrapper>
        <Button
          onClick={onAccept}
          color={"primary"}
          variant={"contained"}
          size={"small"}
          sx={{ minWidth: "100px" }}
          disabled={isLoading}
        >
          {accept_label}
        </Button>
        <Button onClick={onReject} variant={"outlined"} size={"small"} sx={{ minWidth: "118px" }} disabled={isLoading}>
          {reject_label}
        </Button>
      </ButtonsWrapper>
      {error && <Error>{error}</Error>}
    </>
  )
}

const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
`

const Error = styled.p`
  color: red;
  font-size: 14px;
  font-weight: 300;
`

export { ActionButtons }
