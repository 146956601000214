import React, { useState, useEffect, useRef } from "react"
import { Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useHistory } from "react-router-dom"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import * as htmlToImage from "html-to-image"
import jsPDF from "jspdf"

import { Header, Footer } from "widgets"
import NoCertificate from "./NoCertificate"

const Certificate = props => {
  const courseName = props.match.params.name

  const [courseId, setCourseId] = useState(null)
  const [downloadType, setDownloadType] = useState("pdf")
  const [html, setHtml] = useState(null)
  const [tg, setTg] = useState(null)
  const [orientation, setOrientation] = useState(null)

  const cert = useRef()
  const history = useHistory()

  useEffect(() => {
    axios.get(`/content/courses/`, { params: { slug: courseName } }).then(r => {
      let courseId = r.data[0].id
      let props = r.data[0].template_props
      if (!!props) {
        props = JSON.parse(props)
        setTg(props.telegram)
        setOrientation(props.certificate_orientation || "p")
      }
      setCourseId(courseId)
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios.post(`/content/certificates/${courseId}/create-certificate/`).then(res => {
        setHtml(res?.data.html)
      })
    })
  }, [courseName])

  const takeScreenShot = async node => {
    const dataURI = await htmlToImage.toPng(node)
    return dataURI
  }

  const createPreview = async node => {
    const file = await htmlToImage.toBlob(node)
    return file
  }

  const download = image => {
    if (downloadType === "png") {
      const a = document.createElement("a")
      a.href = image
      a.download = `certificate.png`
      a.click()
    } else if (downloadType === "pdf") {
      const doc = new jsPDF(orientation)
      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()
      doc.addImage(image, "PNG", 0, 0, width, height)
      doc.save(`certificate.pdf`)
    }
  }

  const downloadCertificate = () => takeScreenShot(cert.current).then(download)

  useEffect(() => {
    if (!cert.current) return
    takeScreenShot(cert.current).then(image => {
      const doc = new jsPDF(orientation)
      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()
      doc.addImage(image, "PNG", 0, 0, width, height)
      let file = new Blob([doc.output("blob")], { type: "application/pdf" })
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios
        .put(`/content/certificates/${courseId}/save-certificate/`, file, {
          headers: {
            "Content-Type": "application/pdf",
            "Content-Disposition": `attachment; filename=certificate.pdf`,
          },
        })
        .then(() => {
          createPreview(cert.current).then(previewImage => {
            axios.patch(`/content/certificates/${courseId}/save-certificate/`, previewImage, {
              headers: {
                "Content-Type": "image/png",
                "Content-Disposition": `attachment; filename=certificate.png`,
              },
            })
          })
        })
    })
  }, [html])

  if (!html) return <NoCertificate tg={tg} />

  return (
    <div>
      <Header variant={"shadow"} />
      <div
        style={{
          display: "flex",
          gap: 20,
          flexDirection: "column",
          alignItems: "center",
          marginTop: 100,
          marginBottom: 100,
        }}
      >
        <div style={{ display: "flex", gap: 20 }}>
          <FormControl sx={{ minWidth: 120, margin: 6 }}>
            <InputLabel id="download-type-select-label">Формат</InputLabel>
            <Select
              labelId="download-type-select-label"
              id="download-type-select"
              value={downloadType}
              label="Формат"
              onChange={e => setDownloadType(e.target.value)}
            >
              <MenuItem value={"pdf"}>.pdf</MenuItem>
              <MenuItem value={"png"}>.png</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            style={{ height: 50, margin: 24 }}
            onClick={() => downloadCertificate(html)}
          >
            Скачать
          </Button>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          ref={cert}
          style={{ boxShadow: "0px 4px 10px 0px rgba(49, 52, 57, 0.20)" }}
        ></div>
      </div>
      <Footer />
    </div>
  )
}

export default Certificate
