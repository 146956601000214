import { styled } from "@mui/material/styles"

export const ContactsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(12),
  borderRadius: theme.spacing(6),
  backgroundColor: "#FFF",
  zIndex: "100",
  width: "50%",
  padding: theme.spacing(12),
  marginTop: theme.spacing(24),
  marginLeft: theme.spacing(12),
  marginBottom: theme.spacing(24),
  [theme.breakpoints.down("sm")]: {
    position: "static",
    marginLeft: 0,
    borderRadius: 0,
    boxShadow: "none",
    gap: theme.spacing(6),
    width: "100%",
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(16),
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  boxShadow:
    "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0.00)",
}))

export const ContactItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
  },
}))

export const ContactItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
}))

export const ContactContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}))

export const StyledIframe = styled("iframe")(({ theme }) => ({
  border: "none",
  width: "100%",
  height: "100vh",
  zIndex: "1",
  [theme.breakpoints.down("sm")]: {
    height: "50vh",
  },
}))

export const MainContactsDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
}))
