import React from "react"
import { Typography } from "@mui/material"

import { AdminProfileEditor, AdminRequests, UserStatusSetter, ParticipantStatusSetter } from "widgets"
import { ProgressData } from "entities/Participant/ProgressData"
import { UserData } from "entities/User"
import { useUserData, UserAutocompleteChoice } from "features"

export const UserPannel = () => {
  const { user } = useUserData()

  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "column", marginBottom: "40px" }}
    >
      <UserAutocompleteChoice />
      <AdminRequests />
      {user && (
        <>
          <ParticipantStatusSetter user={user} />
          <UserData user={user} />
          <AdminProfileEditor user={user} />
          <UserStatusSetter user={user} />
        </>
      )}
      {user?.progress && (
        <>
          <Typography variant="h4">Курсы:</Typography>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {user.progress.map(c => (
              <ProgressData progress={c} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
