import React from "react";
import { Card, Typography } from "@mui/material";

export const AdminRequest = ({ request, actions }) => {
  return (
    <Card style={{ display: "flex", gap: 20, padding: 20, alignItems: "center", marginTop: 20 }}>
      <Typography variant="body1">
        {`Пользователь ${request.sender} просит заменить ФИО на ${request.props.last_name} ${request.props.first_name} ${request.props.middle_name}. Причина: ${request.props.about}`}
      </Typography>
      {actions}
    </Card>
  )
}
