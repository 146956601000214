import { styled } from "@mui/material"

export const LocationBlockMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: 48,
  width: "100%",
  paddingBottom: 48,
  paddingRight: 124,
  paddingLeft: 124,
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 100,
    paddingRight: 100,
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}))

export const LocationBlockContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: theme.spacing(12),
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(6),
  },
}))

export const IframeBlock = styled("iframe")(({ theme }) => ({
  width: "50%",
  height: "auto",
  border: "none",
  minHeight: "400px",
  borderRadius: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))
