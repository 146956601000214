import React from "react";
import useStyles from "./styles";

import {
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export function ControlledAccordion(props) {
  const content = props.content
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  console.log(props.permission)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Card>
      <CardContent>
        <div>
          {content && content.map(e => (
            <Accordion
              expanded={expanded === `panel${content.indexOf(e)}`}
              onChange={handleChange(`panel${content.indexOf(e)}`)}
              disabled={!props.permission && !e.public}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: "bold" }}>{e.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className={classes.contentText} dangerouslySetInnerHTML={{ __html: e.text }} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>{" "}
      </CardContent>
    </Card>
  );
}
