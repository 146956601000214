import * as Styled from "./ExaminationModal.styles"
import { IAssingment, IAssingmentReview } from "../../model/assignment.types"
import styled from "@emotion/styled"

export const ExaminationHeader = ({
  assignment,
  review,
}: {
  assignment?: IAssingment
  review?: IAssingmentReview | null
}) => {
  return (
    <Styled.HeaderWrapper>
      <RowWrapper>
        <HeaderLable>Ученик:</HeaderLable> <HeaderText>{assignment?.participant.email}</HeaderText>
      </RowWrapper>
      <RowWrapper>
        <HeaderLable>Задание:</HeaderLable> <HeaderText>{assignment?.block_title}</HeaderText>
      </RowWrapper>
      <RowWrapper>
        <HeaderLable>Мин./Макс. Балл:</HeaderLable>{" "}
        <HeaderText>
          {review?.scores.min}/{review?.scores.max}
        </HeaderText>
      </RowWrapper>
    </Styled.HeaderWrapper>
  )
}

const RowWrapper = styled.div`
  height: 18px;
`

const HeaderLable = styled.span`
  font-size: 14px;
  line-height: 125%;
`

const HeaderText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
`
