import { InvitesParticipantFilters } from "entities/Organization/ui/InvitesParticipantFilters"
import { ParticipantsCard } from "entities/Organization/ui/ParticipantsCard"
import { TableTotalCount as ParticipantsCount } from "shared/assets"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { PageLocationType } from "./ParticipantsInProgress"
import ParticipantsTable from "entities/Organization/ui/ParticipantsTable"
import useParticipants from "../model/useParticipants"
import { courseKeyDefault, CourseKeyType } from "../../../shared/store/course/CourseSlice"

interface IProps {
  triggerReloading: () => void
  pageLocation: PageLocationType
  courseKey: CourseKeyType
}

const ParticipantsRequested = ({ triggerReloading, pageLocation, courseKey = courseKeyDefault }: IProps) => {
  const status = "requested"
  const {
    isLoading,
    participants,
    total,
    hasNextPage,
    categories,
    headers,
    handleSort,
    handleFilters,
    fetchParticipants,
    fetchDownloadParticipants,
    setParticipants,
    setTotal,
  } = useParticipants({pageLocation, status, courseKey})
  const course = useAppSelector(state => state.course[courseKey])

  if (!course?.id) return

  return (
    <ParticipantsCard
      title="Заявки на участие в курсе"
      sectionBorderRadius={12}
      download={fetchDownloadParticipants}
      disableDownload={!participants.length}
      disableHidding
    >
      <div>
        <InvitesParticipantFilters handleFilters={handleFilters} />
        <ParticipantsCount
          totalCount={total || 0}
          filterCount={participants.length}
          totalLabel={"Всего участников, соответствующих фильтру:"}
        />
        <ParticipantsTable
          status={status}
          headers={headers}
          participants={participants}
          categories={categories}
          setSortBy={handleSort}
          isLoading={isLoading}
          loadMore={fetchParticipants}
          hasNextPage={hasNextPage}
          setParticipants={setParticipants}
          setTotal={setTotal}
          triggerReloading={triggerReloading}
          courseKey={courseKey}
        />
      </div>
    </ParticipantsCard>
  )
}

export { ParticipantsRequested }
