import {makeStyles} from "@mui/styles";

export default makeStyles(theme => (
    {
        editor: {
            backgroundColor: "#F0F2F9",
            textAlign: "left",
        },

        runButton: {
            cursor: "pointer",
            appearance: "none",
            borderRadius: "5px",
            border: "0",
            background: "#1565C0",
            color: "#fff",
            width: "164px",
            float: "left",
            height: "34px",
            margin: "10px 4px",
            fontSize: "18px",
        },

        checkButton: {
            cursor: "pointer",
            appearance: "none",
            borderRadius: "5px",
            background: "#ffffff",
            float: "left",
            color: "#1565C0",
            width: "214px",
            height: "34px",
            margin: "10px 20px",
            fontSize: "18px",
            border: "1px solid #1565C0",
        },

        textArea: {
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #E0E0E0",
            borderRadius: "6px",
        },
    })
)
