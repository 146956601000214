import React from "react"
import * as Styled from "./Landing.styles"
import { Grid, Typography, useTheme } from "@mui/material"
import { mentorItems } from "./mock"
import CommunityImage from "images/community.png"
import { Button } from "../../shared/ui"

export const Communities = () => {
  const theme = useTheme()
  return (
    <Styled.CommunitesMainDiv>
      <Styled.StyledTitle sx={{ color: "#FFF", width: "100%" }} variant={"h2"}>
        Сообщество единомышленников и профессионалов
      </Styled.StyledTitle>
      <img
        src={CommunityImage}
        alt={"Image of mentors on event"}
        style={{ width: "100%", borderRadius: theme.spacing(3) }}
      />
      <Grid
        container
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: "row",
            wlexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(6),
            [theme.breakpoints.down("sm")]: { gap: theme.spacing(4), justifyContent: "space-between" },
          },
        }}
      >
        <Styled.MentorDescriptionDiv item>
          <Styled.StyledSubtitle
            variant={"h3"}
            sx={{
              color: theme.palette.common.white,
            }}
          >
            Наставничество
          </Styled.StyledSubtitle>
          <Typography
            sx={{
              color: theme.palette.common.white,
              marginTop: theme.spacing(8),
              [theme.breakpoints.down("xs")]: { marginTop: theme.spacing(6) },
            }}
            variant={"body1"}
            fontWeight={"medium"}
            fontSize={"medium"}
          >
            Формируйте устойчивое сообщество экспертов, профессионалов и наставников.
            <p>
              Сообщество наставников оказывает комплексную поддержку учителей и учащихся, передает опыт, знания,
              развивает навыки и компетенции.
            </p>
          </Typography>
          {/*@ts-ignore*/}
          <Button
            variant={"contained"}
            color={"additional"}
            size={"medium"}
            disabled={true}
            sx={{ marginTop: "auto", [theme.breakpoints.down("sm")]: { width: "100%" } }}
          >
            Материалы
          </Button>
        </Styled.MentorDescriptionDiv>
        {mentorItems.map(e => (
          <Grid
            item
            sx={{
              width: "22%",
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.spacing(3),
              padding: theme.spacing(6),
              [theme.breakpoints.down("lg")]: {
                width: "31%",
              },
              [theme.breakpoints.down("sm")]: {
                width: "31%",
              },
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
            }}
          >
            <img src={e.image} style={{ width: "100%" }} alt={"Image of mentor"} />
            <Styled.StyledSubtitle
              variant={"h3"}
              fontSize={"medium"}
              sx={{
                fontWeight: 500,
                color: "#313439",
                marginBottom: theme.spacing(3),
                marginTop: theme.spacing(3),
              }}
            >
              {e.full_name}
            </Styled.StyledSubtitle>
            <Typography
              variant={"body1"}
              fontWeight={"medium"}
              fontSize={"medium"}
              sx={{ color: "#313439", fontSize: theme.spacing(4) }}
            >
              {e.status}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Styled.CommunitesMainDiv>
  )
}
