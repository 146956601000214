import { getCourse } from "entities/Course/api/course"
import { getOrganizationRequestsAndStatusesEventStatistics } from "entities/Organization/api"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { RangesType, StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { useEffect, useState } from "react"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useTab } from "shared/hooks/useTab"
import { courseActions, organizationActions } from "shared/store"
import { useAppDispatch } from "../../../shared/hooks/useAppDispatch"

export const useRequestsAndStatusesStatistics = () => {
  const type = ("requests" || "statuses") as StatisticsVariants
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)
  const { course } = useAppSelector(state => state.course)
  const { dateRange, setTableData, resetStatistics } = useStatistics()
  const event = useAppSelector(state => state.organization.event)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [submitTrigger, setSubmitTrigger] = useState(false)
  const { tab: windowTab, changeTab: setWindowTab } = useTab("statistics_requests")
  const dispatch = useAppDispatch()

  const dateRangePayload = {
    date_range: dateRange.type,
    start: dateRange.type === "custom" ? dateRange.start : undefined,
    finish: dateRange.type === "custom" ? dateRange.finish : undefined,
  }

  useEffect(() => {
    onSelectedCourse()
  }, [course])

  const onSelectedCourse = () => {
    if (!course) return setWindowTab("", 0)
  }

  const handleChangeCourse = async (_: any, newValue: any) => {
    resetStatistics(type)
    if (newValue && organization?.id) {
      try {
        setIsLoadingTable(true)
        const response = await getCourse(newValue.id)
        dispatch(organizationActions.setEvent(response))
        dispatch(courseActions.setCourse(response))
      } catch {
        dispatch(organizationActions.setEvent(undefined))
      } finally {
        setIsLoadingTable(false)
      }
    } else {
      dispatch(courseActions.setCourse(undefined))
      dispatch(organizationActions.setEvent(undefined))
    }
  }
  const onSubmit = async (dateRangePayloadProp?: { date_range: RangesType; start?: string; finish?: string }) => {
    const currentDateRangePayload = dateRangePayloadProp ?? dateRangePayload
    try {
      setSubmitTrigger(true)
      resetStatistics(type)
      await fetchTable(currentDateRangePayload)
    } catch {
    } finally {
      setSubmitTrigger(false)
    }
  }
  const fetchTable = async (dateRange: { date_range: RangesType; start?: string; finish?: string }) => {
    if (!organization?.id) return
    try {
      const { date_range, start, finish } = dateRange
      const isDateValid = date_range !== "custom" || (start !== "Invalid Date" && finish !== "Invalid Date")
      if (event && isDateValid) {
        setIsLoadingTable(true)
        const tablePayload = {
          course: currentCourse?.id,
          type: type === "requests" ? "applications_table" : "statuses_table",
          page: 1,
          ...dateRange,
        }
        const tableResponse = await getOrganizationRequestsAndStatusesEventStatistics(organization?.id, tablePayload)
        setTableData(type, {
          count: tableResponse.count,
          next: tableResponse.next,
          results: tableResponse.results,
          isLoaded: true,
        })
      }
    } catch {
    } finally {
      setIsLoadingTable(false)
    }
  }

  return {
    event,
    onSubmit,
    isLoadingTable,
    submitTrigger,
    windowTab,
    setWindowTab,
    handleChangeCourse,
  }
}
