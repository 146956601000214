import React, { useState, useEffect } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useStyles from "./styles";
import { backgroundStyle } from "./styles";

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }} ></div>
  } else {
    return text
  }
}

export const Choice = ({ question, value, handleChange, result, shuffle }) => {
  const classes = useStyles()
  const [variants, setVariants] = useState(question.variants);

  useEffect(() => {
    if (!question.disableShuffle) {
      setVariants([...shuffle(question.variants)])
    }
  }, [question])

  return (
    <FormGroup className={classes.testButtons} style={{ maxWidth: 800, ...backgroundStyle(result)}}>
      {
        variants.map(c => (
          <FormControlLabel
            className={classes.testCheckbox}
            classes={{ label: classes.testCheckboxLabel }}
            control={
              <Checkbox
                color="primary"
                checked={!!value ? value[c.value] || false : false}
                onChange={handleChange}
                value={c.value}
                name={question.index}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={parseText(c.label)}
          />
        ))
      }
    </FormGroup>
  )
}
