import React, { useEffect, useState } from "react"
import * as Styled from "./ui/Course.style.ts"
import { Button, Autocomplete } from "shared/ui"
import { CircularProgress, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useCourseForm } from "entities/Course/model/useCourseForm.js"
import { TableTotalCount as ParticipantsCount } from "shared/assets"
import { sortStages } from "entities/Course"
import { getTeamProgress, getTeamsOnCourse } from "entities/Team/api/team"
import { trimFilterValue } from "entities/Statistic/lib/filter"
import { useSelector } from "react-redux"
import { formCategories, formMembers } from "entities/Statistic/lib/teamStatistic"
import TeamFilters from "./ui/TeamFilters.js"
import ManageStats from "./ui/ManageStats.js"
import CourseTable from "./ui/CourseTable.js"
import formTeamStatistic from "entities/Statistic/lib/formTeamStatistic.ts"

export const TeamStatistics = () => {
  const { fieldValue: filters, handleFieldValue, setFieldValue: setFilters } = useCourseForm()
  const [teamProgress, setTeamProgress] = useState(null)
  const [displayedProgress, setDisplayedProgress] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [modules, setModules] = useState({ "": "" })
  const [teams, setTeams] = useState([])
  const [headers, setHeaders] = useState([])
  const [categories, setCategories] = useState({})
  const [totalCount, setTotalCount] = useState(null)
  const [courseVariants, setCourseVariants] = useState([])
  const [courseVariantValue, setCourseVariantValue] = useState(null)
  const [nextPage, setNextPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [sortBy, setSortBy] = useState({ type: "score", direction: -1 })
  const [isShowMembers, setIsShowMembers] = useState(false)
  const [formedStatistics, setFormedStatistics] = useState(null)
  const [stages, setStages] = useState(null)
  const [isTriggerFilter, setIsTriggerFilter] = useState(false)
  const user = useSelector(state => state.user.user)

  const isModerator = user.status.includes("moderator") || user.status.includes("admin")

  useEffect(() => {
    getCourses()
  }, [])

  useEffect(() => {
    if (!displayedProgress || !headers || !categories) return
    const statistics = formTeamStatistic(displayedProgress, isShowMembers, categories, headers)
    setFormedStatistics(statistics)
  }, [displayedProgress, isShowMembers, headers, categories])

  async function getCourses() {
    const response = await axios
      .get("content/actions/participants/my_courses/?for_teams=true&for_statistics=true")
      .then(res => res.data)
    setCourseVariants(
      response
        .filter(participant => participant.status === "teacher" || participant.status === "moderator")
        .map(participant => ({
          id: participant.course,
          label: participant.course_title,
          stages: participant.stages,
          status: participant.status,
        }))
    )
  }

  async function onSubmit() {
    onReset()
    setIsTriggerFilter(false)
    await loadMore(true)
  }

  async function loadMore(isFirstPage) {
    setIsLoading(true)
    try {
      const params = {
        page: isFirstPage ? 1 : nextPage,
        course: courseVariantValue.id,
        ...trimFilterValue(filters),
      }
      const response = await getTeamProgress(params)
      const currentProgress = isFirstPage ? response.results : [...teamProgress, ...response.results]
      setTeamProgress(currentProgress)
      const progress = formProgress(currentProgress)
      const formedCategories = formCategories(progress)
      const newCategories = Object.assign(categories, formedCategories)
      setCategories(newCategories)
      const formedMembersProgress = formMembers(progress, newCategories)
      setDisplayedProgress(formedMembersProgress)
      setTotalCount(response.count)
      setHasNextPage(!!response.next)
      setNextPage(prev => prev + 1)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  function formProgress(results) {
    return results.map(e => {
      const chiefModerator = e.team.members.find(member => member.category === "chief_moderator")
      return {
        id: e.team.id,
        title: e.team.title,
        full_name: chiefModerator?.participant.full_name || "",
        email: chiefModerator?.participant.email || "",
        members: e.team.members,
        score: e.score || 0,
        modules: e.module_progress,
      }
    })
  }

  async function handleChooseCourse(e, newValue) {
    onReset()
    setFilters({})
    setCourseVariantValue(newValue)
    setIsTriggerFilter(false)
    if (newValue) {
      try {
        setIsLoading(true)
        await getModules(newValue.id)
        await getStages(newValue.id)
        if (isModerator) {
          await getTeams(newValue.id)
        }
      } catch {
      } finally {
        setIsLoading(false)
      }
    }
  }

  async function getModules(courseId) {
    const response = await axios.get(`/content/modules/?course=${courseId}&for_statistics=true`).then(res => res.data)
    let modules = {}
    const sortedModules = response.toSorted((a, b) => a.stage - b.stage)
    sortedModules.forEach(module => {
      modules = { ...modules, [module.title]: module.id }
    })
    setModules({ "": "", "Общий балл": "-1", ...modules })
    const headers = response.map(module => ({ title: module.title, id: module.id }))
    setHeaders(headers)
  }

  async function getStages(courseId) {
    const response = await axios.get(`/content/courses/${courseId}/`).then(res => res.data)
    setStages(response.stages.filter(e => !e.is_empty && e.access_type === "restricted").sort(sortStages))
  }

  async function getTeams(courseId) {
    const response = await getTeamsOnCourse({ course: courseId })
    const formedTeams = formTeams(response)
    setTeams(formedTeams)
  }

  function formTeams(progress) {
    const result = []
    progress.forEach(team => result.push(team.title))
    return result
  }

  function onReset() {
    setTeams([])
    setTotalCount(null)
    setTeamProgress(null)
    setCategories([])
    setHasNextPage(false)
    setNextPage(1)
  }

  const handleChangeFilters = event => {
    const exceptionFields = ["module", "rule"]
    const isException = exceptionFields.includes(event.target.name)
    setIsTriggerFilter(prev => prev || !isException)
    handleFieldValue(event)
  }

  const getVariant = isTriggerFilter ? "outlined" : "contained"
  const buttonLabel = isTriggerFilter ? "ПРИМЕНИТЬ ФИЛЬТР" : "ПОСМОТРЕТЬ СТАТИСТИКУ"

  return (
    <>
      <Styled.ShadowedSection>
        <Styled.Header>
          <Styled.Title variant="h4">Курс</Styled.Title>
        </Styled.Header>
        <Styled.ActionWrapper>
          <Autocomplete
            size="large"
            id="course__choise"
            value={courseVariantValue}
            onChange={handleChooseCourse}
            onReset={handleChooseCourse}
            options={courseVariants}
            label="Название курса"
            style={{ width: 384 }}
          />
          <Button variant={getVariant} disabled={!courseVariantValue || isLoading} onClick={onSubmit} width={266}>
            {buttonLabel}
          </Button>
          {isLoading && (
            <Styled.LoaderWrapper>
              <CircularProgress size={26} />
              <Typography style={{ fontSize: "18px" }}>Пожалуйста, подождите</Typography>
            </Styled.LoaderWrapper>
          )}
        </Styled.ActionWrapper>
        <TeamFilters
          disabled={!courseVariantValue?.id || isLoading}
          fieldValue={filters}
          handleFieldValue={handleChangeFilters}
          modules={modules}
          teams={teams}
        />
        <Styled.FormHelper variant="body2">
          Чтобы применить фильтр, нажмите на кнопку “Посмотреть статистику”
        </Styled.FormHelper>
      </Styled.ShadowedSection>
      {window.innerWidth > 1200 && teamProgress && (
        <>
          <Styled.SectionWrapper sx={{ marginBottom: 10 }}>
            <Styled.SubTitle
              variant="h4"
              sx={{
                marginBottom: 4,
              }}
            >{`Статистика команд по Курсу «${courseVariantValue?.label}»`}</Styled.SubTitle>
            <Styled.LightText variant="body1">{`ID: ${courseVariantValue?.id}`}</Styled.LightText>
          </Styled.SectionWrapper>
          <ManageStats
            stages={stages}
            scores={formedStatistics}
            progress={displayedProgress}
            isLoading={isLoading}
            selectedCourse={courseVariantValue}
            sortBy={sortBy}
            filterCount={totalCount}
          />
          {totalCount && (
            <Styled.FlexRow sx={{ marginTop: 16 }}>
              <ParticipantsCount totalCount={totalCount} filterCount={teamProgress?.length} />
            </Styled.FlexRow>
          )}
          {teamProgress && (
            <Styled.TableWrapper>
              <CourseTable
                course={courseVariantValue}
                progress={displayedProgress}
                headers={headers}
                categories={categories}
                loadMore={loadMore}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                sortBy={sortBy}
                setSortBy={setSortBy}
                setIsShowMembers={setIsShowMembers}
                isShowMembers={isShowMembers}
              />
            </Styled.TableWrapper>
          )}
        </>
      )}
    </>
  )
}
