import { styled } from "@mui/material/styles"
// @ts-ignore
import { boxShadow } from "shared/ui"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { Card, Typography } from "@mui/material"
import { ThemeType } from "shared/themes/default.types"

export const DescriptionSettingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "50%",
  gap: theme.spacing(6),
}))

const Wrapper = styled(Card)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(8),
  boxShadow: "unset",
  border: "unset",
  backgroundColor: theme.palette.common.white,
}))

export const Section = styled(Wrapper)(({ theme, borderRadius }: { theme?: ThemeType; borderRadius?: number }) => ({
  boxShadow: boxShadow,
  backgroundColor: theme?.palette.common.white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  borderRadius: borderRadius || 0,
}))

export const Header = styled("div")(({ theme, disableHidding }: { theme?: ThemeType; disableHidding?: boolean }) => ({
  // @ts-ignore
  padding: `${theme?.spacing(5)} ${theme?.spacing(8)}`,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: disableHidding ? "auto" : "pointer",
}))

export const Title = styled(Typography)`
  font-size: 24px;
  letter-spacing: 0.2px;
`

export const Content = styled("div")(({ theme }) => ({
  width: "100%",
  paddingInline: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}))

export const DropDownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  cursor: "pointer",
}))

export const DropUpIcon = styled(ArrowDropUpIcon)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  cursor: "pointer",
}))
