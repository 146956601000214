import { useState, useEffect } from "react"
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Paper } from "@mui/material"
import useStyles from "./styles"
import withStyles from "@mui/styles/withStyles"
import { backgroundStyle } from "./styles"

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function parseText(text) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>
  } else {
    return text
  }
}

export const Matrix = ({ question, value, handleChange, result, shuffle }) => {
  const classes = useStyles()
  const [variantsX, setVariantsX] = useState(question.variants_x)
  const [variantsY, setVariantsY] = useState(question.variants_y)

  useEffect(() => {
    if (!question.disableShuffle) {
      setVariantsX([...shuffle(question.variants_x)])
      setVariantsY([...shuffle(question.variants_y)])
    }
  }, [question])

  return (
    <TableContainer
      component={Paper}
      style={{ flexGrow: 1, maxWidth: 800, boxShadow: "none", borderRadius: 0, ...backgroundStyle(result) }}
    >
      <Table aria-label="customized table" size="small" style={{ borderRadius: 6 }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {variantsX.map(c => (
              <TableCell align="center" className={classes.testMatrixTh}>
                {parseText(c.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {variantsY.map(y => (
            <StyledTableRow key={y.label}>
              <TableCell component="th" scope="row" className={classes.testMatrixTd}>
                {parseText(y.label)}
              </TableCell>
              {variantsX.map(x => (
                <Cell value={value} x={x} y={y} handleChange={handleChange} question={question} />
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Cell = ({ value, x, y, handleChange, question }) => {
  const classes = useStyles()
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(getIsChecked(value, x, y))
  }, [value])

  const getIsChecked = (value, x, y) => {
    if (!value) return false
    return Array.isArray(value) ? value.includes(`${x.value},${y.value}`) : value[x.value + "," + y.value]
  }

  return (
    <TableCell align="center" className={classes.testMatrixTd}>
      <Checkbox
        color="primary"
        checked={isChecked}
        onChange={handleChange}
        value={x.value + "," + y.value}
        name={question.index}
        inputProps={{ "aria-label": "controlled" }}
      />
    </TableCell>
  )
}
