import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  testCheckbox: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  testCheckboxLabel: {
      lineHeight: 1.3
  },
}));
