import React, { useState, useEffect } from "react"
import { Button, Typography, CircularProgress, TextField as Input, FormControlLabel, Checkbox } from "@mui/material"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

import useStyles from "./styles"
import { useUserState } from "shared/context"

const Confirm = props => {
  var classes = useStyles()
  const { login, registration } = useUserState()

  const [isLoading, setIsLoading] = useState(false)
  const [emailKey, setEmailKey] = useState("")
  const [helperText, setHelperText] = useState("")
  const [checked, setChecked] = useState(false)

  var email = props.email
  var password = props.password

  function confirmUser() {
    if (email.length > 0 && password.length > 0 && emailKey.length > 0) {
      setIsLoading(true)
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios
        .post("/register/", {
          username: email,
          email: email,
          password: password,
          hash: props.registerHash,
          key: emailKey,
          first_name: props.first_name,
          last_name: props.last_name,
          middle_name: props.middle_name,
          phone: props.phone,
          is_subscribed: checked,
        })
        .then(res => {
          setIsLoading(false)
          props.success()
        })
        .catch(err => {
          if (err.response.status === 400) {
            setHelperText("Введён неверный код")
          }
          setIsLoading(false)
        })
    } else {
      setHelperText("Отсутствуют данные")
    }
  }

  return (
    <div className={classes.form}>
      <Typography variant="h5" className={classes.greeting}>
        Введите 6-значный код
      </Typography>
      <Input
        id="key"
        name="one-time-code"
        variant="outlined"
        value={emailKey}
        onChange={e => setEmailKey(e.target.value)}
        margin="normal"
        placeholder="Код"
        type="key"
        helperText={`Письмо с кодом подтверждения отправлено на почту, указанную при регистрации (${email}).`}
        fullWidth
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            size="medium"
            sx={{
              color: "#313439",
              "&.Mui-checked": {
                color: "#313439",
              },
            }}
          />
        }
        label={
          <Typography style={{ color: "black", fontSize: 14, width: "100%" }}>
            Я хочу получать рассылку о новостях платформы
          </Typography>
        }
      />
      <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            onClick={confirmUser}
            disabled={emailKey.length === 0}
            variant="contained"
            color="primary"
            size="large"
            style={{ width: 240, height: 50, textTransform: "uppercase" }}
          >
            Зарегистрироваться
          </Button>
        )}
        {!isLoading && <Typography className={classes.errorMessage}>{helperText}</Typography>}
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              props.setStep(1)
            }}
            color="primary"
            size="large"
            className={classes.forgetButton}
          >
            Назад
          </Button>
          <Button
            onClick={() => {
              registration.setOpen(false)
              login.setOpen(true)
            }}
            color="primary"
            size="large"
            className={classes.forgetButton}
          >
            Вход
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Confirm
