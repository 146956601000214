import React from "react"
import { Grid, TableCell, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { wordMapper } from "shared/local"

export const ParticipantRow = ({ participant, score, completed, module_progress, tasks }) => {
  const theme = useTheme()

  const getProgress = task => {
    //БЭК: получение прогресса такое, потому что с бэка приходят иногда объекты, а иногда массивы
    if (!module_progress) return
    if (!Array.isArray(module_progress)) return module_progress[task]
    let result = module_progress?.filter(progress => progress.module_info.title === task)
    return result[0]
  }

  return (
    <>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {participant.full_name}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {participant.email}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {wordMapper(participant.status)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {participant.category || "\u2014"}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Grid container sx={{ width: theme.spacing(30) }} spacing={1} justifyContent={"center"}>
          {participant?.stages.sort().map((e, index, array) => (
            <Grid item key={e + index}>
              <Typography sx={{ whiteSpace: "no-wrap", fontSize: "14px" }} variant={"caption"} fontWeight={"medium"}>
                {`${e}${index !== array.length - 1 ? "," : ""}`}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {Math.floor(score) || 0}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>{`${
          completed ? "Пройдено" : "Не пройдено"
        }`}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>{`${
          participant.is_active ? wordMapper("active") : "-"
        }`}</Typography>
      </TableCell>
      {tasks &&
        tasks.map(task => (
          <TableCell
            align="center"
            style={{
              backgroundColor: getProgress(task)?.completed ? "#81FF81" : "#FF8181",
            }}
          >
            <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>{`${
              getProgress(task)?.score || 0
            }`}</Typography>
          </TableCell>
        ))}
    </>
  )
}
