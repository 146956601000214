import React, { useEffect, useRef } from "react"
import { Line } from "shared/ui"
import useStyle from "./styles"
import FeedbackForm from "./FeedbackForm"
import { Typography } from "@mui/material"

const Feedback = ({ e, handleChangeRef }) => {
  const classes = useStyle()
  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  return (
    <div ref={ref} style={{ backgroundColor: e.backgroundColor }}>
      <div className={classes.feedbackContentContainer}>
        <div className={classes.feedbackDescriptionContainer}>
          {!!e.H2 && (
            <Typography variant={"h2"} sx={{ color: e.H2.color }}>
              {e.H2.text}
            </Typography>
          )}
          {!!e.BaseText && (
            <Typography variant={"body1"} sx={{ color: e.BaseText.color }}>
              {e.BaseText.text}
            </Typography>
          )}
        </div>
        <div>
          <FeedbackForm e={e} />
        </div>
      </div>
    </div>
  )
}

export default Feedback
