import React from "react"
import * as Styled from "./Webinar.styles"
import { RequestedUserInfo } from "./Webinar.styles"
export const WebinarRequestedMessage = () => {
  return (
    <Styled.WebinarRequestedMessage>
      <Styled.StyledInfoIcon />
      <Styled.RequestedUserInfo variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
        Дождитесь одобрения заявки на участие. Модератор встречи рассмотрит её в ближайшее время.
      </Styled.RequestedUserInfo>
    </Styled.WebinarRequestedMessage>
  )
}
