import { useRef, useState } from "react"
import { styled } from "@mui/styles"
import { MemberStatus } from "../../Member/ui/MemberStatus"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

const SelectorContainer = styled("div")(() => ({
  position: "relative",
}))

const SelectWrapper = styled("div")(() => ({
  position: "relative",
  width: "fit-content",
  marginLeft: "auto",
  display: "flex",
  gap: 8,
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
}))

const IconWrapper = styled("div")(() => ({
  height: "24px",
  rotate: "180deg",
}))

const MenuWrapper = styled("div")(({ isModerator, wrapperRef }) => ({
  zIndex: 900,
  width: "220px",
  position: "fixed",
  top: wrapperRef.current.getBoundingClientRect().bottom,
  translate: isModerator ? "15% 0%" : "50% 0%",

  "&::before": {
    content: '""',
    display: "block",
    height: "2px",
  },
}))

const Menu = styled("div")(({ theme, heightLimit }) => ({
  paddingBlock: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow:
    "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0.00);",
  backgroundColor: theme.palette.common.white,
  maxHeight: heightLimit,
  overflowY: "scroll",
}))

const MenuItem = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#DEE7F2",
  },
}))

const SelectRole = ({ category, categories, onChange, isModerator }) => {
  const [isActive, setIsActive] = useState(false)
  const wrapperRef = useRef()
  const displayLimit = window.innerHeight - wrapperRef.current?.getBoundingClientRect().bottom
  const heightLimit = displayLimit < 270 ? displayLimit + "px" : "260px"

  const handleClick = status => {
    onChange(status)
    toggleActive()
  }

  const toggleActive = () => {
    setIsActive(prev => !prev)
  }

  const handleEnter = () => {
    if (isActive) setIsActive(true)
  }

  return (
    <SelectorContainer>
      <SelectWrapper
        onClick={toggleActive}
        onMouseEnter={handleEnter}
        onMouseLeave={() => setIsActive(false)}
        ref={wrapperRef}
      >
        <MemberStatus category={category} />
        <IconWrapper style={{ rotate: isActive && "0deg" }}>
          <ArrowDropDownIcon style={{ scale: "1.4" }} />
        </IconWrapper>
      </SelectWrapper>
      {isActive && (
        <MenuWrapper
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={toggleActive}
          isModerator={isModerator}
          wrapperRef={wrapperRef}
        >
          <Menu heightLimit={heightLimit}>
            {Object.keys(categories).map(
              (category, index) =>
                category !== "null" && (
                  <MenuItem onClick={() => handleClick(category)} key={index}>
                    <MemberStatus category={category} />
                  </MenuItem>
                )
            )}
          </Menu>
        </MenuWrapper>
      )}
    </SelectorContainer>
  )
}

export { SelectRole }
