import styled from "@emotion/styled"
import { Chip as MuiChip } from "@mui/material"

export const SelectorWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-left: 24px;
`

export const IntervalList = styled.ul`
  background-color: #fff;
  padding: unset;
  margin: unset;
  margin-block: 12px;
  max-height: 224px;
  width: 240px;
  list-style: none;
  text-align: right;
`

export const IntervalItem = styled.li<{ isActive?: boolean }>`
  padding: 8px 40px;
  width: 100%;
  transition: background-color 0.2s ease;
  background-color: ${({ isActive }) => (isActive ? "#EFEFEF" : "transparent")};
  cursor: pointer;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "#EFEFEF" : " #dee7f2")};
  }
`
export const Chip = styled(MuiChip)<{ type: "default" | "outlined" }>`
  border-radius: 20px;
  width: fit-content;
  height: 24px;
  background-color: ${props => (props.type === "outlined" ? "#fff" : "#00a355")};
  border: 1px solid ${props => (props.type === "outlined" ? "#00A355" : "transparent")};
  color: ${props => (props.type === "outlined" ? "#313439" : "#fff")};
  &:hover {
    background-color: ${props => (props.type === "outlined" ? "#fff" : "#00a355")};
  }
`

export const ChipTypography = styled.span`
  padding: 6px 12px;
`
