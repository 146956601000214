// @ts-ignore
import Cookies from "js-cookie"
import { GenericAbortSignal } from "axios"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { ModuleType, SubsectionType } from "./module.types"
import { createAsyncThunk } from "@reduxjs/toolkit"

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

interface ModuleParams {
  for_statistics?: boolean
  for_score_page?: boolean
  for_progress_reset?: boolean
}

export const getModules = async (
  courseId: number,
  params?: ModuleParams,
  signal?: GenericAbortSignal
): Promise<ModuleType[]> =>
  await axios.get(`/content/modules/?course=${courseId}`, { params, signal }).then(res => res.data)

export const giveModuleAccess = async (stage: number, payload: number[]) =>
  await axios.put(`/content/modules/${stage}/add_participants/`, payload)

export const removeModuleAccess = async (stage: number, payload: number[]) =>
  await axios.patch(`/content/modules/${stage}/add_participants/`, payload)

export const createModule = async (title: string, stage: number, index: number, content_type: string) =>
  await axios.post("/content/modules/", { title: title, index: index, stage: stage, content_type: content_type })

export const fetchModule = async (module_id: number) => await axios.get(`content/modules/${module_id}`)

export const fetchModuleLogo = async (module_id: number) => await axios.get(`content/modules/${module_id}/get_logo`)

export const copyModule = async (module_id: number, stage_id: number) =>
  await axios.post(`/content/modules/${module_id}/copy/`, { stage: stage_id })

export const updateModuleContent = createAsyncThunk(`trajectory/update_module`, async (module: ModuleType) => {
  const response = await axios.put(`content/modules/${module.id}/`, module)
  return response.data as ModuleType
})

export const saveTrajectory = createAsyncThunk("trajectory/save", async (params: Promise<void>[]) => {
  try {
    await Promise.all(params)
  } catch (err) {}
})

export const removeModule = createAsyncThunk(
  "trajectory/remove_module",
  async (params: { module_id: number; stage_id: number }) => {
    const response = await axios.delete(`content/modules/${params.module_id}`)
    return { module: params.module_id, stage: params.stage_id }
  }
)

export const deleteSection = async (section_id: number) => await axios.delete(`/content/sections/${section_id}/`)

export const createSection = async (params: { [key: string]: string | number | boolean | null | SubsectionType[] }) =>
  await axios.post("/content/sections/", params)

export const updateSection = async (params: { [key: string]: string | number | boolean | null | SubsectionType[] }) =>
  await axios.patch(`/content/sections/${params.id}/`, params)

export const deleteSubsection = async (subsection_id: number) =>
  await axios.delete(`/content/subsections/${subsection_id}/`)

export const createSubsection = async (params: { [key: string]: string | number | boolean | null }) =>
  await axios.post("/content/subsections/", params)

export const updateSubsection = async (params: { [key: string]: string | number | boolean | null }) =>
  await axios.patch(`/content/subsections/${params.id}/`, params)

export const addParticipantInPersonalModule = async (params: number[], module_id: number) =>
  await axios.put(`/content/modules/${module_id}/add_participants/`, { participants: params })

export const deleteParticipantInPersonalModule = async (params: number[], module_id: number) =>
  await axios.patch(`/content/modules/${module_id}/add_participants/`, { participants: params })

export const addTeamInTeamsModule = async (params: number[], module_id: number) =>
  await axios.put(`/content/modules/${module_id}/add_teams/`, { teams: params })

export const deleteTeamInTeamsModule = async (params: number[], module_id: number) =>
  await axios.patch(`/content/modules/${module_id}/add_teams/`, { teams: params })
