import { IEvent } from "../../model/Organization.types"
import { StartPage, Loader } from "../../static/statistics"
import { SummaryStatistics } from "../../../../features/Organization/SummaryStatistics"
import { RangesType } from "../../model/StatisticsContext.types"
import { DateRange } from "../../../../features/Organization/ui/EventDateSelector"

interface IProps {
  isLoading: boolean
  event?: IEvent
  rangeType: RangesType
  statisticsDisplayedDate: DateRange

}

const tabOptions = [
  { title: "Общее", value: 0, enable: true},
  { title: "Детали", value: 1, enable: true},
]

export const RequestsTemplate = ({ event, isLoading, rangeType, statisticsDisplayedDate}: IProps) => {
  if (isLoading) return <Loader />
  else if (!event) return <StartPage page={"requests"} />
  return <SummaryStatistics type={"requests"} tabOptions={tabOptions} isLoading={isLoading} event={event} rangeType={rangeType} statisticsDisplayedDate={statisticsDisplayedDate}/>
}