import { AxiosResponse } from "axios"

export function downloadFile(response: AxiosResponse<ArrayBuffer>, fileName: string) {
  const arrayBuffer = response.data
  const blob = new Blob([arrayBuffer], { type: "application/content" })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = fileName

  link.click()
  URL.revokeObjectURL(link.href)
}
