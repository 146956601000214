import { createFeedbackNotification } from "entities/Notification/api/notifications"
import { SendNotification } from "entities/Notification/ui/SendNotification"

interface IProps {
  assignmentId: number
  courseId: number
  isOpen: boolean
  onClose: () => void
}

const SendAssignmentNotification = ({ assignmentId, courseId, isOpen, onClose }: IProps) => {
  async function createNotification(content: string) {
    await createFeedbackNotification(content, assignmentId, courseId)
  }

  return <SendNotification isOpen={isOpen} onClose={onClose} createNotification={createNotification} />
}

export default SendAssignmentNotification
