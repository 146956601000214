import { makeStyles } from "@mui/styles"
import { styled } from "@mui/material/styles"

export default makeStyles(theme => ({
  mainScreenContainer: {
    minHeight: "40.104vw",
    /*height: "48vw",*/
    width: "100%",
    /*marginBottom:"96px"*/
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },

  mainScreenContentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginLeft: 124,
    marginRight: 124,
    paddingTop: 96,
    paddingBottom: 96,

    baseText: {
      fontSize: 20,
    },
  },

  mainScreenTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "30vw",
    zIndex: 4,
    gap: 75,
    "& > div:last-of-type": {
      fontSize: 20,
    },
  },

  imageMain: {
    width: "48vw",
  },

  flexContainer: {},

  mainTabs: {
    paddingLeft: 124,
    paddingRight: 124,
    "& > div > div": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
    },
    "& button": {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 0,
      paddingRight: 0,
      lineHeight: "100%",
      borderBottom: "8px solid rgb(37, 82, 190)",
    },
    "& button  > div": {
      textTransform: "uppercase",
      letterSpacing: 0.08,
    },
    "& button  > div:hover": {
      color: "#01D4FE !important",
    },
    "& button:hover": {
      borderBottom: "8px solid #01D4FE",
    },
  },

  navGalleryButtons: {
    background: "transparent",
    boxSizing: "content-box",
    padding: 10,
    margin: "0 20px",
    width: 32,
    height: 32,
    backgroundColor: "transparent",
    "& svg": {
      width: "240%",
      height: "240%",
    },
  },

  partnersContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: 124,
    marginRight: 124,
    paddingBottom: 96,
    gap: 124,
    "& .css-ohwg9z": {
      overflow: "unset",
    },
    "& .css-1abc02a": {
      right: "-124px",
    },
    "& .css-hn784z": {
      left: "-124px",
    },
  },

  partnersContentContainer: {
    /*marginTop: 76,*/
    alignItems: "center",
    height: 120,
  },

  iconPartner: {
    maxHeight: "120px",
    cursor: "pointer",
  },

  faqContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 100,
    paddingBottom: 100,
    paddingLeft: 124,
    paddingRight: 124,
  },
  questionContainer: {
    "& > div": {
      borderBottom: "1px solid #01D4FE",
      margin: "0 !important",
    },
    "& > div:first-child": {
      borderTop: "1px solid #01D4FE",
      borderRadius: 0,
    },
    "& > div:last-of-type": {
      borderRadius: 0,
    },
    "& > div > div > div": {
      marginBottom: 26,
    },
    "& > div > div:first-of-type > div": {
      marginTop: 26,
    },
    "& > div > div:first-of-type > div:last-of-type": {
      margin: 0,
    },
    "& > div > div > div svg": {
      width: 36,
      height: 36,
    },
    "& > div > div > div > div > div > div > div": {
      fontWeight: 100,
    },
    "& > div:before": {
      display: "none",
    },
  },

  feedbackContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 100,
    paddingBottom: 160,
    paddingLeft: 124,
    paddingRight: 124,
  },

  feedbackDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 78,
    "& > div": {
      width: 200,
    },
    "& > div:first-of-type": {
      width: "auto",
    },
  },

  feedbackFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 24,
    width: "50vw",
    "& > button": {
      marginTop: 60,
    },
  },
}))

export const CatalogWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(16),
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))
