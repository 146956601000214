import { useEffect, useState } from "react"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useLocation } from "react-router-dom"

const useInvitations = () => {
  const [incoming, setIncoming] = useState([])
  const [requests, setRequests] = useState([])
  const [sents, setSents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const isScrollToSent = location.pathname.split("/").at(-1) === "sent"

  useEffect(() => {
    if (!isLoading && isScrollToSent) {
      const element = document.getElementById("sent_notification")
      window.scrollTo({ top: element.offsetTop })
    }
  }, [isLoading])

  useEffect(() => {
    loadInvites()
  }, [])

  async function loadInvites() {
    try {
      await getMyInvitations()
      await getRequests()
      await getInvitations()
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function getMyInvitations() {
    const invites = await axios.get(`content/actions/teams/my_invitations/`).then(res => res.data)
    if (invites.length) {
      const formedInvites = invites.map(invite => ({
        user_name: `${invite?.invited_by_full_name || invite?.invited_by?.first_name || ""}`,
        user_email: invite.invited_by?.email,
        course_title: invite.participant.course_title,
        course_id: invite.participant.course,
        team_title: invite.team.title,
        team_id: invite.team.id,
        category: invite.invitation_category || "null",
        participant: invite.participant,
      }))
      if (formedInvites.length) return setIncoming(prev => [...prev, ...formedInvites])
    }
  }

  async function getRequests() {
    const response = await axios.get(`content/teams/requests/`).then(res => res.data)
    response.map(course => {
      if (course?.members.length) {
        const formedInvites = course.members.map(invite => ({
          user_name: invite.participant.full_name,
          user_email: invite.invited_by?.email || "",
          course_title: invite.participant.course_title,
          course_id: course.course,
          team_title: invite.team.title,
          team_id: course.id,
          participant: invite.participant,
        }))
        if (formedInvites.length) setRequests(prev => [...prev, ...formedInvites])
      }
    })
  }

  async function getInvitations() {
    const response = await axios.get(`content/teams/invitations/`).then(res => res.data)
    response.map(course => {
      if (course?.members.length) {
        const formedInvites = course.members.map(invite => ({
          user_name: invite.participant.full_name,
          user_email: invite.participant?.email,
          course_title: invite.participant.course_title,
          team_title: invite.team.title,
          team_id: course.id,
          category: invite.invitation_category || "null",
          participant: invite.participant,
          reaction: invite.status === "invited" && "Приглашение отправлено",
        }))
        if (formedInvites.length) setSents(prev => [...prev, ...formedInvites])
      }
    })
  }

  return { incoming, requests, sents, isLoading }
}

export default useInvitations
