import React, { useEffect, useRef } from "react"
import Carousel from "react-material-ui-carousel"
import GalleryItem from "./GalleryItem"
import { Grid, Typography } from "@mui/material"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"
import { useTheme } from "../../../shared/context"
import * as Styled from "./styles"
import { SliderDiv } from "../../../shared/ui/Slider"
//@ts-ignore
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface IProps {
  e: {
    index: number
    backgroundColor: string
    arrowColor: string
    options: { url: string }[]
    H2: {
      text: string
      color: string
    }
  }
  handleChangeRef: (ref: any, index: number) => void
}

const Gallery = ({ e, handleChangeRef }: IProps) => {
  const ref = useRef<any>(null)
  const theme = useTheme()

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.xs,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  return (
    <Styled.GalleryMainDiv ref={ref} style={{ background: e.backgroundColor }}>
      {!!e.H2 && (
        <Typography variant={"h2"} sx={{ color: e.H2.color || "#FFF" }}>
          {e.H2.text || ""}
        </Typography>
      )}
      <SliderDiv className="slick-slider">
        <Slider {...settings}>
          {!!e.options && !!e.options.length && e.options.map((e, index) => <GalleryItem e={e} key={index} />)}
        </Slider>
      </SliderDiv>
    </Styled.GalleryMainDiv>
  )
}

export default Gallery
