import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

export const FeedbackFormMainDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(31),
  paddingTop: theme.spacing(31),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    gap: theme.spacing(6),
  },
}))

export const FeedbackFormDiv = styled("div")(({ theme }) => ({
  alignSelf: "flex-start",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: theme.spacing(6),
}))

export const FieldsDiv = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(6),
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  justifyContent: "center",
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(6),
  },
}))

export const StyledImage = styled("img")(({ theme }) => ({
  width: "35%",
  alignSelf: "flex-end",
  [theme.breakpoints.down("lg")]: {
    width: "40%",
    alignSelf: "center",
  },
  [theme.breakpoints.down("md")]: {
    width: "40%",
    alignSelf: "center",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}))

export const StyledResultImage = styled("img")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))

export const RequestModalMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  height: "auto",
}))

export const RequestFormDiv = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: theme.spacing(11),
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  marginBottom: theme.spacing(16),
  gap: theme.spacing(8),
}))

export const SendFormContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
  },
}))

export const FeedbackSendMailForm = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}))
