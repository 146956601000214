import React from "react"
import * as Styled from "./Organization.styles"
import { Typography } from "@mui/material"
export const CategoryChip = ({ label }) => {
  return (
    <Styled.CategoryChip
      label={
        <Typography variant={"caption"} fontWeight={"small"}>
          {label}
        </Typography>
      }
      variant={"outlined"}
    />
  )
}
