import useStyles from "./styles"
import { useEffect } from "react"
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"

export function InteractiveTable(props) {
  const rows = props.rows
  const columns = props.columns
  const content = props.content
  const setContent = props.setContent
  const classes = useStyles()

  useEffect(() => {
    resetTable()
  }, [])

  const resetTable = () => {
    const isContentful = content.find(row => Object.keys(row).length)
    if (isContentful) return
    rows.forEach(row => {
      columns.forEach(col => {
        handleContentUpdate("", row, col)
      })
    })
  }

  function handleContentUpdate(value, targetRow, targetCol) {
    content[rows.indexOf(targetRow)][targetCol] = value
    setContent([...content])
  }

  if (content == null) return <></>

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" className={classes.interactTable}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.interactTableTh}></TableCell>
            {columns &&
              columns.map(col => <TableCell align="center" className={classes.interactTableTh}>{`${col}`}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map(row => (
              <TableRow
                key={row}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.interactTableTr}
              >
                <TableCell align="center" className={classes.interactTableTd}>{`${row}`}</TableCell>
                {columns &&
                  columns.map(col => (
                    <TableCell align="center" className={classes.interactTableTd}>
                      <TextField
                        variant="outlined"
                        value={content[rows.indexOf(row)][col]}
                        onChange={e => handleContentUpdate(e.target.value, row, col)}
                        className={classes.interactTableInput}
                      />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
