interface IFilter {
  [x: string]: string | number | null
}

export const trimFilterValue = (fieldValue: IFilter) => {
  let result = {}
  Object.keys(fieldValue).forEach(filter => {
    if (fieldValue[filter]) result = { ...result, [filter]: String(fieldValue[filter]).trim() }
  })
  return result
}
