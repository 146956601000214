import React, { useEffect, useState } from "react"
import { Button, Typography } from "../../ui"
import * as Styled from "./FileUploader.style"

export const FileUploader = ({ image, setImage, title }) => {
  const [file, setFile] = useState(null)

  function handleChangeFile(e) {
    const selectedFile = e.target.files[0]
    const reader = new FileReader()

    reader.onload = function (event) {
      const base64Result = event.target.result
      setImage(base64Result)
    }
    reader.readAsDataURL(selectedFile)
    setFile(selectedFile)
  }

  return (
    <>
      {!file && (
        <Styled.FileButton component={"label"}>
          {title || "Нажмите, чтобы выбрать файл"}
          <input type="file" hidden onChange={handleChangeFile} />
        </Styled.FileButton>
      )}
      {!!file && (
        <Styled.FileUploadedContainer>
          <Typography>{file.name}</Typography>
          <Styled.FileUploadedButton component={"label"}>
            Загрузить новый файл
            <input type="file" hidden onChange={handleChangeFile} />
          </Styled.FileUploadedButton>
        </Styled.FileUploadedContainer>
      )}
    </>
  )
}
