import React, { useEffect, useState } from "react"
import { Button, Modal, TextField } from "shared/ui"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { JsonEditor } from "shared/assets"
import { updateOrganization } from "entities/Organization"
import { IOrganization } from "entities/Organization/model/Organization.types"
import * as Styled from "./ui/Organizations.styles"

interface IProps {
  isOpen: boolean
  setIsOpen: (state: boolean) => void
}

export const EditOrganizationLanding = ({ isOpen, setIsOpen }: IProps) => {
  const dispatch = useAppDispatch()
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const [editData, setEditData] = useState<{ props?: Array<{ [key: string | "index"]: object | string | number }> }>({})
  const [copyBlock, setCopyBlock] = useState(null)

  function handleClose() {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!!organization?.template_props) setEditData(JSON.parse(organization?.template_props))
    if (isOpen) setIsOpen(false)
  }, [organization])

  function handleClick() {
    let dt = editData
    if (!!dt.props && !!dt.props.length) {
      dt.props.sort((a, b) => (Number(a.index) > Number(b.index) ? 1 : -1))
    }
    let template_props = JSON.stringify({ ...dt })
    let data = { ...organization, template_props: template_props }
    delete data["icon_logo"]
    delete data["logo"]
    delete data["logo_negative"]
    dispatch(updateOrganization(data as IOrganization))
  }

  function pushNewBlock() {
    let block = { ...editData.props?.filter(e => Number(e?.index) === Number(copyBlock))[0] }
    if (!!block) {
      block["index"] = -1
      let newData = { ...editData }
      if (!!block && !!newData.props) newData.props.push(block)
      setEditData({ ...newData })
    }
  }

  return (
    <Modal title={"Редактирование шаблона"} isOpen={isOpen} onClose={handleClose}>
      <JsonEditor data={editData} setter={setEditData} />
      <Styled.CopyBlockDiv>
        <TextField
          //@ts-ignore
          label={"Индекс блока"}
          variant={"outlined"}
          value={copyBlock}
          //@ts-ignore
          onChange={e => setCopyBlock(e.target.value)}
        />
        {/* @ts-ignore */}
        <Button
          color={"primary"}
          variant={"outlined"}
          onClick={pushNewBlock}
          disabled={!copyBlock && !Number(copyBlock)}
        >
          Копировать блок
        </Button>
      </Styled.CopyBlockDiv>
      {/* @ts-ignore */}
      <Button color={"primary"} variant={"contained"} onClick={handleClick}>
        Сохранить
      </Button>
    </Modal>
  )
}
