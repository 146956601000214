import React, { useEffect, useState } from "react"
import * as Styled from "./TrajectoryConstructor.styles"
import { Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { tableHeader } from "../static/tableFields"
import { TrajectoryModuleRow } from "entities/Module/ui/TrajectoryModuleRow"
import { EditModule } from "features/Module/EditModule"
import { ModuleType } from "entities/Module/api/module.types"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

export const TableOfModules = ({ modules }: { modules: ModuleType[] }) => {
  const [openModules, setOpenModules] = useState<{ [key: number]: boolean }>({})

  function openModule(module_id: number) {
    openModules[module_id] = !openModules[module_id]
    setOpenModules({ ...openModules })
  }

  return (
    <Styled.ModulesMainTableContainer>
      <Styled.ModulesTableContainer>
        <Table stickyHeader={true} sx={{ width: "100%", overflowX: "auto", overflowY: "auto" }} size={"small"}>
          <TableHead>
            <TableRow sx={{ height: 25 }}>
              {tableHeader.map(e => (
                <Styled.HeaderCell align={"center"}>
                  <Typography variant={"caption"} fontWeight={"small"}>
                    {e}
                  </Typography>{" "}
                </Styled.HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key={modules.length}>
            {modules.map(e => (
              <ModuleRow openModules={openModules} openModule={openModule} module={e} />
            ))}
          </TableBody>
        </Table>
      </Styled.ModulesTableContainer>
    </Styled.ModulesMainTableContainer>
  )
}

const ModuleRow = ({
  module,
  openModule,
  openModules,
}: {
  module: ModuleType
  openModule: (v: number) => void
  openModules: { [key: number]: boolean }
}) => {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, background: openModules[module.id] ? "#FFFF" : "#F9F9F9" }}>
        <TrajectoryModuleRow module={module} />
        <TableCell sx={{ width: "16px" }}>
          {/*@ts-ignore*/}
          <IconButton size="small" onClick={() => openModule(module.id)}>
            {openModules[module.id] ? (
              <KeyboardArrowUpIcon sx={{ color: "#000", fontSize: 16 }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "#000", fontSize: 16 }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: "#FFF" }}>
        <TableCell colSpan={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={openModules[module.id]}>
            {openModules[module.id] && (
              <EditModule openModal={module.id} setOpenModal={(value: null) => openModule(module.id)} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
