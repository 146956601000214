import { Dropdown, TextField } from "shared/ui"
import { FlexRow, FormWrapper } from "./Course.style.ts"
import { ruleEnum } from "entities/Course/lib/CourseStatic.js"
import { Autocomplete } from "shared/ui"

const TeamFilters = ({ fieldValue, handleFieldValue, modules, teams, disabled }) => {
  const handleAutocomplete = (e, value) => {
    handleFieldValue({ target: { name: "team__title__icontains", value: value } })
  }

  return (
    <>
      <FormWrapper>
        <TextField
          id="statistics__field-value-name"
          variant="outlined"
          name={"last_name"}
          value={fieldValue.last_name}
          onChange={handleFieldValue}
          disabled={disabled}
          label="Фамилия Администратора"
        />
        <TextField
          id="statistics__field-value-email"
          variant="outlined"
          name={"email"}
          value={fieldValue.email}
          onChange={handleFieldValue}
          disabled={disabled}
          label="Почта Администратора"
        />
        {teams.length ? (
          <Autocomplete
            id="statistics__field-value-team"
            variant="outlined"
            name={"team__title__icontains"}
            value={fieldValue.team__title__icontains}
            onChange={handleAutocomplete}
            options={teams}
            disabled={disabled}
            label="Название команды"
          />
        ) : (
          <TextField
            id="statistics__field-value-team"
            variant="outlined"
            name={"team__title__icontains"}
            value={fieldValue.team__title__icontains}
            onChange={handleFieldValue}
            disabled={disabled}
            label="Название команды"
          />
        )}
        <TextField
          id="statistics__field-value-team-id"
          variant="outlined"
          name={"team__id"}
          value={fieldValue.team__id}
          onChange={handleFieldValue}
          disabled={disabled}
          label="ID команды"
        />
      </FormWrapper>
      <FlexRow>
        <Dropdown
          id={"statistics__field-value-module"}
          name={"module"}
          value={fieldValue.module}
          onChange={handleFieldValue}
          disabled={disabled}
          options={modules}
          label={"Критерий"}
          width={220}
        />
        <Dropdown
          id={"statistics__field-value-rule"}
          name={"rule"}
          value={fieldValue.rule}
          onChange={handleFieldValue}
          disabled={disabled || !fieldValue.module}
          options={ruleEnum}
          label={"Правило"}
          width={115}
        />
        <TextField
          id="statistics__field-value-mark_value"
          variant="outlined"
          name={"mark_value"}
          value={fieldValue.mark_value}
          onChange={handleFieldValue}
          label="Значение"
          disabled={disabled || !fieldValue.module || !fieldValue.rule}
          width={115}
        />
      </FlexRow>
    </>
  )
}
export default TeamFilters
