import { styled } from "@mui/material"
import { ShadowedSection } from "shared/ui"

export const TagWrapper = styled(ShadowedSection)(({ theme }) => ({
  width: "100%",
}))

export const AddTagToCurseWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
}))

export const CreateTagMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
}))

export const CreateTagContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
}))
