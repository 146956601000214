import * as Styled from "../styles/OrganizationStatisticsTable.styled"
import OrganizationStatisticsTable from "./OrganizationStatisticsTable"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { OrganizationStatisticsFilters } from "./OrganizationStatisticsFilters"
import { useEffect, useState } from "react"
import { downloadOrganizationEventStatistics, getOrganizationEventStatistics } from "../../api"
import { IAnswer } from "../../model/useStatisticsDataList"
import { TableTotalCount } from "shared/assets"
import { downloadZipFile } from "shared/util/downloadZipFile"
import { useStatistics } from "../../model/StatisticsContext"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import {
  getStatisticsHeaders,
  pickCurrentCounterLabel,
  pickTotalCounterLabel,
} from "entities/Organization/static/statistics"
import { formTableResult, getBasePayload } from "entities/Organization/lib/statistics"

interface IProps {
  type: StatisticsVariants
  isLoadingProp: boolean
  submitTrigger: boolean
  onChangeTab?: (e: string) => void
  isShowEmptyTable?: boolean
}

const INIT_PAGE = 2

const OrganizationStatisticsTemplate = ({
  type,
  isLoadingProp,
  submitTrigger,
  onChangeTab,
  isShowEmptyTable,
}: IProps) => {
  const { statistics, setTableData, resetStatisticsTable, eventFilters, dateRange } = useStatistics()
  const { data, general } = statistics[type]
  const { count, results, next, isLoaded } = data
  const [filters, setFilters] = useState({})
  const [sortBy, setSortBy] = useState<{ type: string; direction: number } | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [page, setPage] = useState(INIT_PAGE)
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)
  const parsedAnswers = Object.groupBy(eventFilters.answers, answer => answer.question.id) as Record<number, IAnswer[]>
  const questionId = Object.keys(parsedAnswers)[0]
  const answersString = Object.values(parsedAnswers)
    .flat(1)
    .map((answer: IAnswer) => answer.title)
    .join(",")
  const basePayload = getBasePayload(type, questionId, answersString, sortBy, general?.submitter.email, dateRange.type)

  useEffect(() => {
    if (isLoadingProp) {
      setPage(INIT_PAGE)
    }
  }, [isLoadingProp])

  async function fetchParticipants() {
    if (!organization?.id) return
    try {
      setIsLoading(true)
      const payload = {
        page: type === "survey" ? page : undefined,
        ...basePayload,
        ...filters,
      }
      const response = await getOrganizationEventStatistics(organization?.id, payload)
      const result = {
        count: response.count,
        next: response.next,
        results: [...results, ...response.results.data],
      } as any
      setTableData(type, result)
      setPage(prev => prev + 1)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchFiltersParticipants(newFilters: any) {
    if (!organization?.id) return
    resetStatisticsTable(type)
    try {
      setPage(2)
      setIsLoading(true)
      const payload = {
        page: type === "survey" ? 1 : undefined,
        ...basePayload,
        ...newFilters,
      }
      const response = await getOrganizationEventStatistics(organization?.id, payload)
      const result = formTableResult(type, response) as any
      setTableData(type, result)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchSortParticipants(sortBy: { type: string; direction: number }) {
    if (!organization?.id) return
    try {
      setPage(2)
      setIsLoading(true)
      const sort = sortBy.type
      const direction = sortBy?.direction === 1 ? "asc" : "desc"
      const paylaod = {
        page: type === "survey" ? 1 : undefined,
        ...basePayload,
        ...filters,
        sort,
        direction,
      }
      const response = await getOrganizationEventStatistics(organization?.id, paylaod)
      const result = formTableResult(type, response) as any
      setTableData(type, result)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchDownloadParticipants(downloadType: number | "all" | "total") {
    if (!organization?.id) return
    const infoFieldId = downloadType === "all" ? Object.keys(parsedAnswers).join(",") : downloadType
    try {
      setIsDownloading(true)
      const response = await downloadOrganizationEventStatistics(organization?.id, {
        ...basePayload,
        ...filters,
        page: type === "survey" ? page : undefined,
        download: "xlsx",
        infofield_id: type === "survey" ? infoFieldId : undefined,
      })
      const surveyFileName = `Таблица сводки по данным анкеты_${currentCourse?.id}_${organization.title}.xlsx`
      const personalFileName = `Таблица активности ученика_${organization.title}.xlsx`

      type === "survey"
        ? await downloadZipFile(response.data, surveyFileName)
        : await downloadZipFile(response.data, personalFileName)
    } catch {
    } finally {
      setIsDownloading(false)
    }
  }

  const handleFilters = (filters: any) => {
    const newFilters = Object.keys(filters).reduce((acc, key) => ({ ...acc, [key]: filters[key] }), {})
    setFilters(newFilters)
    fetchFiltersParticipants(newFilters)
  }

  const handleSort = (value: any) => {
    setSortBy(value)
    resetStatisticsTable(type)
    fetchSortParticipants(value)
  }


  const sortByCourse = (a, b) => {
    if (basePayload.sort === "course") {
      if (basePayload.direction === "asc") {
        return a.course.localeCompare(b.course)
      } else if (basePayload.direction === "desc") {
        return b.course.localeCompare(a.course)
      }
    } else return
  }

  if (isShowEmptyTable || (!isLoaded && !isLoadingProp)) return <EmptyTable type={type} />

  return (
    <div>
      <OrganizationStatisticsFilters
        type={type}
        submitTrigger={submitTrigger}
        handleFilters={handleFilters}
        onDownload={fetchDownloadParticipants}
        isDownloading={isDownloading || isLoadingProp || isLoading}
      />
      {type === "survey" && (
        <TableTotalCount
          totalCount={count || 0}
          filterCount={results?.length}
          totalLabel={pickTotalCounterLabel(type)}
          currentLabel={pickCurrentCounterLabel(type)}
        />
      )}
      {type === "personal" && (
        <TableTotalCount
          totalCount={count || 0}
          totalLabel={pickTotalCounterLabel(type)}
          type={"personal"}
        />
      )}
      <OrganizationStatisticsTable
        type={type}
        headers={getStatisticsHeaders(type)}
        participants={
          type === "survey"
            ? results
            : results
                .filter(e => (!!filters.course ? e.course.includes(filters.course) : e))
                .sort((a, b) => sortByCourse(a, b))
        }
        setSortBy={handleSort}
        isLoading={isLoading || isLoadingProp}
        loadMore={type === "survey" ? fetchParticipants : () => {}}
        hasNextPage={next}
        onChangeTab={onChangeTab}
        isBadges={type === "survey"}
      />
    </div>
  )
}

const EmptyTable = ({ type }: { type: StatisticsVariants }) => {
  const pickLabel = () => {
    if (type === "survey") {
      return "Здесь будет отображена таблица вкладки “Сводка по данным анкеты мероприятия”"
    }
    if (type === "personal") {
      return "Здесь будет отображена таблица вкладки “Активность ученика на мероприятиях платформы”"
    }
  }

  return (
    <Styled.EmptyStatisticsTable>
      <Styled.EmptyLabel>{pickLabel()}</Styled.EmptyLabel>
      <Styled.EmptyLabel>Но сначала необходимо выбрать данные для отображения</Styled.EmptyLabel>
    </Styled.EmptyStatisticsTable>
  )
}

export { OrganizationStatisticsTemplate }
