import React, { useEffect, useState } from "react"
import { checkTestDeadline, fetchState, fetchTimer, sendAnswers, startTest, startTestTimer } from "../api/api"
import { parseTime, processAnswers, timerRestart } from "../lib/lib"
import { useTimer } from "react-timer-hook"

export const useAdaptiveTest = ({
  content_block,
  testProps,
  changeSubsection,
}: {
  changeSubsection: () => void
  content_block: number
  testProps: { timer: string; steps: number; finish: boolean; autostart: boolean }
}) => {
  const [time, setTime] = useState<string>(testProps.timer || "00:00:00")
  const [state, setState] = useState<any>()
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [finishStep, setFinishStep] = useState(false)
  const [submission, setSubmission] = useState<{ score: number }>()
  const [questionsPull, setQuestionsPull] = useState<questionPullType | null>(null)
  const [answers, setAnswers] = useState<{ [key: number]: string | string[] }>({})
  const [loadingAnswers, setLoadingAnswers] = useState(false)

  function timerExpire() {
    setSubmission({ score: 10 })
    setQuestionsPull(null)
    setCurrentStep(0)
    if (!testProps.finish) {
      changeSubsection()
    }
  }

  useEffect(() => {
    checkTestDeadline(content_block, true).then((q: { data: { is_deadline: boolean } }) => {
      if (!q.data.is_deadline) {
        fetchState(content_block)
          .then(
            (res: {
              data: {
                steps_taken: number
                current_questions: adaptiveQuestion[]
                finished: boolean
                state_level: number
              }
            }) => {
              fetchTimer(content_block).then((timer: { data: { residual_time: string } }) => {
                setTime(timer.data.residual_time)
                if (!!res.data.current_questions) {
                  setCurrentStep(res.data.steps_taken)
                  setQuestionsPull({ state_id: 0, questions: [...res.data.current_questions] })
                }
                if (res.data.finished) {
                  setSubmission({ score: 10 })
                  if (!testProps.finish) changeSubsection()
                }
              })
            }
          )
          .catch(err => {
            if (testProps.autostart) {
              startAdaptiveTest()
            }
          })
      }
    })
  }, [content_block])

  function startAdaptiveTest() {
    setLoadingAnswers(true)
    checkTestDeadline(content_block, true).then((res: { data: { is_deadline: boolean } }) => {
      if (!res.data.is_deadline) {
        startTestTimer(content_block).then(() => {
          setTime(testProps.timer)
          startTest(content_block).then((res: { data: { state_id: number; questions: adaptiveQuestion[] } }) => {
            setCurrentStep(prev => prev + 1)
            setQuestionsPull({ state_id: res.data.state_id, questions: [...res.data.questions] })
            setLoadingAnswers(false)
          })
        })
      } else {
        setLoadingAnswers(false)
        console.error("deadline error")
      }
    })
  }

  function goToNextStep() {
    setLoadingAnswers(true)
    sendAnswers(content_block, processAnswers(answers)).then(res => {
      setLoadingAnswers(false)
      setFinishStep(res.data.finished)
      setCurrentStep(prev => prev + 1)
      setAnswers({})
      window.scrollTo(0, 0)
      if (res.data.finished) {
        setCurrentStep(0)
        if (!testProps.finish) {
          changeSubsection()
        } else {
          setSubmission({ score: 10 })
        }
      }
      if (!res.data.finished) {
        setQuestionsPull({ state_id: -1, questions: [...res.data.questions] })
      }
    })
  }

  return {
    loadingAnswers,
    state,
    setState,
    startAdaptiveTest,
    currentStep,
    finishStep,
    submission,
    questionsPull,
    answers,
    goToNextStep,
    setAnswers,
    time,
    timerExpire,
  }
}
