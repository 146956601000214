import { OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { PickedCourse, PickedOption } from "../model/useGetOptions"
import { ModuleType } from "entities/Module/api/module.types"

export function formScoreOptions(min = 0, max = 0, isNullable = true) {
  let result: any = isNullable ? [{ id: null, label: "Не выбрано" }] : []
  for (let i = min; i <= max; i++) {
    result.push({ id: i, label: i })
  }
  return result
}

export function convertToPickedCourse(course: OptionType): PickedCourse {
  if (typeof course.id === "number" && typeof course.label === "string" && typeof course.status === "string") {
    return {
      id: course.id,
      label: course.label,
      status: course.status,
    }
  } else {
    return {
      id: NaN,
      label: "",
      status: "",
    }
  }
}

export function filterModules(modules: ModuleType[], stage?: number) {
  const filteredModules = modules.filter(module => module.stage === stage)
  if (!filteredModules.length) return []
  const sortedModules = filteredModules.toSorted((a, b) => a.index - b.index)
  const formedModules = sortedModules.reduce(
    (acc: PickedOption[], module: ModuleType) => [...acc, { id: module.id, label: module.title }],
    [{ id: null, label: "Не выбрано" }]
  )
  return formedModules
}
