import { IEvent } from "../../model/Organization.types"
import { useTab } from "shared/hooks/useTab"
import { ManageStatistics } from "features/Organization/ManageStatistics"
import { SummaryStatistics } from "features/Organization/SummaryStatistics"
import { StartPage, Loader, summaryTemplateTabs } from "../../static/statistics"

interface IProps {
  event?: IEvent
  isLoading: boolean
  windowTab: number | null
  onSubmit: () => void
}

const firstEnableTab = summaryTemplateTabs.find(tab => tab.enable)
const tabOptions = [
  { title: "Общее", value: 0, enable: true, hidden: true },
  { title: "Легенда", value: 1, enable: true },
]

const SurveyTemplate = ({ event, isLoading, windowTab, onSubmit }: IProps) => {
  const { tab, changeTab } = useTab(firstEnableTab?.value || 0)

  if (isLoading) return <Loader />
  else if (!event) return <StartPage page={"survey"} />

  return (
    <>
      {windowTab === 0 ? (
        <ManageStatistics tab={tab} changeTab={changeTab} tabOptions={summaryTemplateTabs} onSubmit={onSubmit} />
      ) : windowTab === 1 && (
        <SummaryStatistics type={"survey"} tabOptions={tabOptions} />
      )}
    </>
  )
}

export default SurveyTemplate
