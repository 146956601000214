import { useEffect, useRef, useState } from "react"
import { FormControl } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { dateFormat, DateRange, InitDateRange, parsedDateFormat } from "features/Organization/ui/EventDateSelector"
import { StyledDateRangePicker as Picker } from "entities/Organization/ui/Statistics/StyledDateRangePicker"
import styled from "@emotion/styled"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/ru"

interface IProps {
  labelStart: string
  labelFinish: string
  displayedDate?: DateRange
  initRange?: InitDateRange
  onChange: (start: Dayjs | null, finish: Dayjs | null) => void
  onReload?: (x?: string, y?: string) => void
}

const DateRangePicker = ({ initRange, displayedDate, labelStart, labelFinish, onChange, onReload }: IProps) => {
  const initStart = initRange?.start || null
  const initFinish = initRange?.finish || null
  const [startValue, setStartValue] = useState<Dayjs | null>(dayjs(displayedDate?.start, dateFormat))
  const [finishValue, setFinishValue] = useState<Dayjs | null>(dayjs(displayedDate?.finish, dateFormat))
  const [isEndOfSequense, setIsEndOfSequence] = useState(false)
  const endRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!startValue) setStartValue(initStart)
    if (!finishValue) setFinishValue(initFinish)
  }, [initRange])

  useEffect(() => {
    if (
      (startValue || finishValue) &&
      startValue?.format(dateFormat)[6] !== "0" &&
      finishValue?.format(dateFormat)[6] !== "0"
    ) {
      onChange(startValue, finishValue)
    }
  }, [startValue, finishValue])

  const handleStartValue = (value: Dayjs | null) => {
    setStartValue(value)
    onEndFocus(value)
    setIsEndOfSequence(true)
  }

  const handleFinishValue = (value: Dayjs | null) => {
    setFinishValue(value)
    if (isEndOfSequense) {
      setIsEndOfSequence(false)
      onReload && onReload(startValue?.format(parsedDateFormat), value?.format(parsedDateFormat))
    }
  }

  function onEndFocus(value: Dayjs | null) {
    if (value?.format(dateFormat) !== "Invalid Date" && value?.format(dateFormat)[6] !== "0" && endRef.current) {
      const button = endRef.current?.querySelector("button")
      const click_event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
      button?.dispatchEvent(click_event)
    }
  }

  const disableDate = (date: Dayjs) => {
    return date.isBefore(initStart) || initFinish?.isBefore(date) || false
  }

  return (
    <Container>
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <PickersWrapper>
            <Picker label={labelStart} date={startValue} onChangeDate={handleStartValue} disableDate={disableDate} />
            <Picker
              ref={endRef}
              label={labelFinish}
              date={finishValue}
              onChangeDate={handleFinishValue}
              disableDate={disableDate}
            />
          </PickersWrapper>
        </LocalizationProvider>
      </FormControl>
    </Container>
  )
}

const Container = styled.div`
  width: 400px;
  height: 118px;
  padding: 24px 32px 32px;
`

const PickersWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export { DateRangePicker }
