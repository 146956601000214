import React from "react"
import { IStage } from "entities/Course/api/course.types"
import { ShadowedSection } from "shared/ui"
import * as Styled from "./TrajectoryConstructor.styles"
import { Typography } from "@mui/material"
import { TableOfModules } from "./TableOfModules"
import { CreateModule } from "../../../features/Module/CreateModule"
import { ModuleType } from "../../../entities/Module/api/module.types"
import { CopyModule } from "../../../features/Module/CopyModule"

export const StageBlock = ({ stage }: { stage: IStage }) => {
  const maxScore = stage?.modules?.reduce((sum, current) => sum + current?.max_score || 0, 0)

  return (
    <ShadowedSection sx={{ padding: "24px 0px" }}>
      <Styled.StageBlockMainDiv>
        <Typography sx={{ padding: "0px 24px" }} variant={"h3"}>
          {stage.title}
        </Typography>
        <Styled.StageParamsContainer>
          <Styled.ParamsWrapper>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"}>
              Начало этапа:
            </Typography>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"}>
              Конец этапа:
            </Typography>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"small"}>
              Максимальный балл за этап:
            </Typography>
          </Styled.ParamsWrapper>
          <Styled.ParamsWrapper>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
              {stage.start}
            </Typography>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
              {stage.finish}
            </Typography>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
              {maxScore}
            </Typography>
          </Styled.ParamsWrapper>
        </Styled.StageParamsContainer>
        <TableOfModules modules={stage?.modules || []} />
        <CreateModule
          last_index={!!stage.modules ? stage.modules[stage.modules.length - 1]?.index + 1 : 1}
          stage_id={stage.id}
        />
        <CopyModule stage_id={stage.id} />
      </Styled.StageBlockMainDiv>
    </ShadowedSection>
  )
}
