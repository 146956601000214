import React, { useEffect, useState } from "react"
import * as Styled from "./Iot.styles"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { fetchUsers, UserChip, UserRow, UserType } from "entities/Iot"
import { ChangeUserRole } from "features/Iot/ChangeUserRole"
import { DeleteUser } from "features/Iot/DeleteUser"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { axiosInstance as axios } from "shared/api/axiosInstance"

const fields = ["ФИО", "Почта", "Статус/сменить статус", "Удалить участника"]

export const UsersTable = () => {

  const users = useAppSelector(state => state.iot.users)
  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const [profile, setProfile] = useState(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!!currentHardware) {
      dispatch(fetchUsers(currentHardware?.serial))
    }
  }, [])

  return (
    <Styled.TableMainContainer>
      <Styled.CustomTableContainer>
        <Table>
          {/*@ts-ignore*/}
          <TableHead stickyHeader={true} sx={{ width: "100%", overflowX: "auto" }}>
            <TableRow>
              {fields.map(e =>
                <TableCell align={"center"}>
                  <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: "14px" }}>
                    {e}
                  </Typography>
                </TableCell>,
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!users && users.map((e: UserType) =>
              <TableRow>
                <UserRow user={e} />
                <TableCell align={"center"}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <UserChip label={e.user_role} />
                    {e.user_role !== "owner" && <ChangeUserRole user={{ email: e.email, role: e.user_role }} />}
                  </div>
                </TableCell>
                {e.user_role !== "owner" && <DeleteUser email={e.email} />}
                {e.user_role == "owner" && <TableCell align={"center"}></TableCell>}
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </Styled.CustomTableContainer>
    </Styled.TableMainContainer>
  )
}