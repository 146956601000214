import styled from "@emotion/styled"

export const HeaderCell = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const SortIcons = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}))

export const ParticipantTableContainer = styled("div")(({ theme }) => ({
  position: "relative",
  marginTop: "16px",
  marginBottom: "64px",
}))
