import styled from "@emotion/styled"

const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const HeaderWrapper = styled(Wrapper)`
  padding-bottom: 16px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`

export const AnswersWrapper = styled(Wrapper)`
  padding-block: 16px;
  min-height: 57px;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const ScoreWrapper = styled.div`
  padding-block: 48px 24px;
`

export const SliderWrapper = styled.div`
  z-index: 500;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 6px 0px rgba(0, 0, 0, 0.09),
    0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  background-color: white;
`

export const HistoryWrapper = styled.div`
  margin-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`
