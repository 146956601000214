import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles";

import { useScript } from "shared/hooks";
import { CodeEditor, Wokwi } from "widgets";

export function WokwiBlock(props) {
  useScript("https://cdn.jsdelivr.net/pyodide/v0.21.2/full/pyodide.js")

  const [presets, setPresets] = useState(null);
  const [width, setWidth] = useState("65vw");

  const simulatorRef = useRef();

  useEffect(() => {
    let preset = JSON.parse(props.block.props)
    setPresets(preset)
    if (!!preset.width) {
      setWidth(parseInt(preset.width, 10))
    } else {
      setWidth("65vw")
    }
  }, [props.block.id]);

  if (!presets) return <></>

  return (
    <div style={{ marginLeft: "5vw", marginRight: "5vw", display: "flex", alignItems: "center", flexDirection: "column" }}>
      <div style={{ width: width, display: "flex", minHeight: 450 }} align='left'>
        <CodeEditor idx={props.block.id} value={presets.value || ""} libs={[]} simulator={simulatorRef} mode="simulation" />
        <Wokwi config={presets.config} ref={simulatorRef} />
      </div>
    </div>
  )
}
