import { Typography as MuiTypography, styled } from "@mui/material"
import { TrophyIcon } from "images/icons/Trophy"
import { Button } from "shared/ui"
import { useEffect, useRef, useState } from "react"
// @ts-ignore
import { useParams } from "react-router-dom"
import { TrophyCircle } from "images/TrophyCircle"

const LeaderboardBanner = ({ courseId }: { courseId: number }) => {
  const [isHovered, setIsHovered] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const params = useParams()

  const toggleHover = () => {
    setIsHovered(prev => !prev)
  }

  const handleClick = () => {
    window.location.href = `/event/${params.name}/leaderboard/${courseId}`
  }

  return (
    <Container ref={containerRef} onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={handleClick}>
      <Wrapper>
        <Typography>
          Cравните прогресс своей команды <br />с другими командами курса
        </Typography>
        <Trophy />
        {/* @ts-ignore */}
        <Button variant={"contained"} size={"small"} sx={{ width: 228 }} isHovered={isHovered}>
          Рейтинг команд
        </Button>
      </Wrapper>
    </Container>
  )
}

const Trophy = () => {
  const [isSecondElement, setIsSecondElement] = useState(false)
  const [isThirdElement, setIsThirdElement] = useState(false)

  const interval = 1

  useEffect(() => {
    setTimeout(() => {
      setIsSecondElement(true)
    }, interval * 1000)
    setTimeout(() => {
      setIsThirdElement(true)
    }, interval * 2000)
  }, [])

  return (
    <>
      <TrophyWrapper>
        <IconWrapper>
          <TrophyIcon />
        </IconWrapper>
        <CircleWrapper>
          <TrophyCircle />
        </CircleWrapper>
        <CircleWrapper style={{ animationDelay: `${interval}s`, visibility: !isSecondElement ? "hidden" : "visible" }}>
          <TrophyCircle />
        </CircleWrapper>
        <CircleWrapper sx={{ animationDelay: `${interval * 2}s`, visibility: !isThirdElement ? "hidden" : "visible" }}>
          <TrophyCircle />
        </CircleWrapper>
      </TrophyWrapper>
    </>
  )
}

const Container = styled("div")(({ theme }) => ({
  zIndex: "1000",
  position: "sticky",
  bottom: 0,
  maxWidth: "100vw",
  height: 60,
  backgroundColor: "#20242A",
  cursor: "pointer",

  ":hover": {
    backgroundColor: "#1E2228",
  },
}))

const Wrapper = styled("div")(() => ({
  maxWidth: "100vw",
  height: "100%",
  paddingInline: "5%",
  marginInline: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

const Typography = styled(MuiTypography)(() => ({
  color: "#FFF",
  fontSize: 14,
  lineHeight: "125%",
}))

const TrophyWrapper = styled("div")(() => ({
  position: "relative",
  width: 350,
  height: "100%",
  overflow: "hidden",
}))

const IconWrapper = styled("div")(() => ({
  zIndex: 1000,
  position: "absolute",
  top: "50%",
  left: "50%",
  translate: "-50% -50%",
}))

const CircleWrapper = styled("div")(({ style }) => ({
  position: "absolute",
  top: 0,
  left: "50%",
  translate: "-50% 0",
  "@keyframes pulsate": {
    from: {
      opacity: 1,
      scale: 1,
    },
    to: {
      scale: "3.5",
      opacity: 0,
    },
  },
  animation: "pulsate 3s infinite linear",
  ...style,
}))

export { LeaderboardBanner }
