import * as Styled from "./Modal.style"
import FailIcon from "images/icons/Fail.js"
import { Dialog, Typography } from "@mui/material"
import { ReactNode } from "react"
import { useWindowSizeChange } from "../../hooks"

const AdditionalTitleStyle = {
  fontWeight: "700",
  letterSpacing: "0.3px",
  lineHeight: "24px",
  paddingInline: "32px",
  maxWidth: "598px",
}

interface IProps {
  showCloseButton?: boolean
  children: ReactNode
  title?: string
  isOpen: boolean
  onClose: () => void
  size?: string
  width?: number
  height?: number
  error?: string
  borderRadius?: number
  bgColor?: string
}

const Modal = ({
  children,
  title,
  isOpen,
  onClose,
  size = "default",
  error,
  borderRadius = 0,
  height,
  width,
  showCloseButton = true,
  bgColor,
}: IProps) => {
  const isSmall = size === "small"
  const { width: windowWidth } = useWindowSizeChange()

  return (
    <Dialog
      aria-labelledby="Modal"
      aria-describedby="Modal Template"
      open={isOpen}
      onClose={onClose}
      fullScreen={windowWidth === "xs"}
      PaperProps={Styled.DialogStyle(isSmall, width, height, borderRadius, windowWidth === "xs", bgColor)}
    >
      <Styled.DialogHeader variant="h3" sx={isSmall ? AdditionalTitleStyle : null}>
        <Styled.HeaderWrapper>
          {title}
          {showCloseButton && (
            <Styled.CloseWrapper>
              <Styled.Close onClick={onClose} sx={{ scale: isSmall ? "1.25" : null }} />
            </Styled.CloseWrapper>
          )}
        </Styled.HeaderWrapper>
        <Error>{error}</Error>
      </Styled.DialogHeader>
      <Styled.ContentWrapper
        sx={{ padding: isSmall ? "32px" : "auto" }}
        isTopPadding={error || undefined}
        isCustomWidth={Boolean(width)}
      >
        {children}
      </Styled.ContentWrapper>
    </Dialog>
  )
}

const Error = ({ children }: { children: ReactNode }) => {
  if (children)
    return (
      <Styled.AlertWrapper sx={{ paddingTop: 8, marginBottom: 4 }}>
        <Styled.IconWrapper>
          <FailIcon />
        </Styled.IconWrapper>
        <Typography>{children}</Typography>
      </Styled.AlertWrapper>
    )
}

export { Modal }
