import * as Styled from "./TimerStartModal.styles"
import { useBackModalRedirection } from "entities/Test/model/backCourseRedirection"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { Button } from "shared/ui"
import { parseTime } from "entities/Test/lib/timer"
import { useSelector, useDispatch } from "react-redux"
import { timerActions } from "shared/store"
import { useEffect } from "react"

const TimerExitModal = () => {
  const dispatch = useDispatch()
  // @ts-ignore
  const testData = useSelector(state => state.timer.testData)
  // @ts-ignore
  const isOpen = useSelector(state => state.timer.isExitModal)

  const onContinue = () => {
    dispatch(timerActions.setIsExitModal(false))
  }

  return (
    <ModalTimer isOpen={isOpen} onClose={onContinue} size={"small"}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>
            Вы уже начали попытку выполнения теста. Вы сможете вернуться к выполнению, пока не вышло время таймера.
            После истечения времени отправка результатов будет недоступна. Вы точно хотите выйти?
          </Styled.Typography>
          <LeftTime attempts={testData.attempts} />
        </Styled.Wrapper>
        <Buttons onContinue={onContinue} courseSlug={testData.courseSlug} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ onContinue, courseSlug }: { onContinue: () => void; courseSlug: string }) => {
  const { redirect } = useBackModalRedirection(courseSlug)
  // @ts-ignore
  const testOnExit = useSelector(state => state.timer.onExit)
  // @ts-ignore
  const exitLink = useSelector(state => state.timer.exitLink)
  const dispatch = useDispatch()
  const onExit = () => {
    dispatch(timerActions.setOnExit(true))
  }

  useEffect(() => {
    if (testOnExit) {
      redirect(exitLink)
      dispatch(timerActions.resetModalState())
    }
  }, [testOnExit])

  return (
    <Styled.ButtonWrapper>
      {/* @ts-ignore */}
      <Button onClick={onContinue} variant="contained" width={154}>
        Закрыть
      </Button>
      {/* @ts-ignore */}
      <Button onClick={onExit} variant="outlined" width={154}>
        Выйти
      </Button>
    </Styled.ButtonWrapper>
  )
}

const LeftTime = ({ attempts }: { attempts: { value: number; timer: string } }) => {
  return (
    <Styled.LeftTimeWrapper>
      <Styled.LeftTimeTitle>Время выполнения:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>{parseTime(attempts.timer)}</Styled.LeftTimeText>
      <Styled.LeftTimeTitle>Попытка:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>
        {attempts.value} из {attempts.value}
      </Styled.LeftTimeText>
    </Styled.LeftTimeWrapper>
  )
}

export { TimerExitModal }
