import { useEffect, useState, useTransition } from "react"
import {
  ExaminationAnswers,
  ExaminationHeader,
  ExaminationHistory,
  ExaminationScore,
  ExaminationSlider,
} from "entities/Assignment/ui/ExaminationModal"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { Modal } from "shared/ui"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { assignmentsActions } from "shared/store"
import { IAssingment, IAssingmentReview } from "entities/Assignment/model/assignment.types"
import { getAssignment } from "entities/Assignment/api/assignment"
import { examinationHelpers as helpers } from "entities/Assignment/static/helpers"

const ExaminationModal = () => {
  const { isExamination } = useAppSelector(state => state.assignment.examination)
  const storedAssignment = useAppSelector(state => state.assignment)
  const { assignments, assignmentIndex } = storedAssignment
  const assignment: IAssingment = assignments.data[assignmentIndex || 0]
  const [warning, setWarning] = useState("")
  const [isUnsaved, setIsUnsaved] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [stashFunc, setStashFunc] = useState<Function | undefined>()
  const [review, setReview] = useState<IAssingmentReview | null>(null)
  const [_, startTransition] = useTransition()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isExamination) return
    fetchAssignment()
    return () => setReview(null)
  }, [isExamination, assignmentIndex])

  const fetchAssignment = async () => {
    if (!assignment) return
    try {
      setIsLoading(true)
      const response = await getAssignment(assignment?.id)
      setReview(response)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    dispatch(assignmentsActions.setExamination({ isExamination: false, initStatus: null }))
    setIsEdited(false)
    setTimeout(() => {
      startTransition(() => {
        if (isEdited) {
          dispatch(assignmentsActions.filterAssignments())
          dispatch(assignmentsActions.decreaseTotalCount())
        }
      })
    })
  }

  const preventClose = (func: () => void) => {
    if (isUnsaved && !stashFunc) {
      setWarning(helpers.unsaved)
      setStashFunc(() => func)
    } else if (stashFunc && String(func) !== String(stashFunc)) {
      setStashFunc(() => func)
    } else if (String(func) === String(stashFunc)) {
      onExit(func)
    } else {
      onExit(func)
    }
  }

  const onExit = (func: () => void) => {
    setWarning("")
    setIsUnsaved(false)
    func()
    setStashFunc(undefined)
  }

  return (
    <Modal
      isOpen={isExamination}
      onClose={() => preventClose(onClose)}
      title={"Проверка работы"}
      width={876}
      height={640}
    >
      <ExaminationHeader assignment={assignment} review={review} />
      <ExaminationAnswers assignment={assignment} review={review} />
      <ExaminationScore
        assignment={assignment}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsUnsaved={setIsUnsaved}
        setWarning={setWarning}
        setIsEdited={setIsEdited}
        setStashFunc={setStashFunc}
        review={review}
      />
      <ExaminationSlider
        assignments={assignments}
        assignmentIndex={assignmentIndex || 0}
        warning={warning}
        preventClose={preventClose}
      />
      <ExaminationHistory />
    </Modal>
  )
}

export default ExaminationModal
