import { courseActions } from "shared/store"
import { useEffect } from "react"
import { getParticipantCourses } from "entities/Participant/api/participant"
import { useDispatch } from "react-redux"

const useGetCourses = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    getCourses()
  }, [])

  async function getCourses() {
    const payload = { for_score_page: true, course__type: "event" }
    try {
      dispatch(courseActions.setIsLoadingCourses(true))
      const response = await getParticipantCourses(payload)
      const extendedResponse = [{ course: null, status: null, course_title: "Не выбрано" }, ...response]
      dispatch(
        courseActions.setCourses(
          extendedResponse.map(course => ({
            id: course.course,
            status: course.status,
            label: course.course_title,
          }))
        )
      )
    } catch {
    } finally {
      dispatch(courseActions.setIsLoadingCourses(false))
    }
  }
}

export default useGetCourses
