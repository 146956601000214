import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { Footer, Header } from "widgets"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import Devices from "./Devices"
import AccessControl from "./AccessControl"
import Iot from "../../widgets/Iot/Iot"

const InternetOfThings = () => {
  const [hardware, setHardware] = useState([])
  const [device, setDevice] = useState({ type: "placeholder" })

  const [hardwareSerial, setHardwareSerial] = useState(null)
  const [hardwareName, setHardwareName] = useState(null)
  const [hardwareRegisterStatus, setHardwareRegisterStatus] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    axios.get("/hardware/allowed_hardware/").then(res => {
      setHardware(res.data)
    })
  }, [])

  function registerHardware() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post("/hardware/register/", { serial: hardwareSerial, name: hardwareName })
      .then(res => {
        axios.get("/hardware/allowed_hardware/").then(res => {
          setHardwareRegisterStatus(`Оборудование успешно зарегистрировано.`)
          setHardware(res.data)
        })
      })
      .catch(err => {
        setHardwareRegisterStatus("Ошибка")
      })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", justifyContent: "space-between" }}>
      <Header variant={"shadow"} />
      <div style={{ width: "100%" }}>
        <Iot />
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  )
}

export default withRouter(InternetOfThings)
