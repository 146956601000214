// @ts-nocheck
import React from "react"
import { Autocomplete, TextField } from "shared/ui"
import * as Styled from "./Organizations.styles"

const statuses = [
  { label: "Модератор", value: "moderator" },
  { label: "Подписчик", value: "follower" },
  { label: "Не выбрано", value: "" },
]

interface IProps {
  fields: {
    last_name: string
    email: string
    role: string
  }
  setFields: (field: "role" | "last_name" | "email", value: string | null) => void
}
const MembersFilters = ({ fields, setFields }: IProps) => {
  return (
    <Styled.FiltersDiv>
      <TextField
        label={"Фамилия"}
        variant={"outlined"}
        value={fields.last_name}
        onChange={e => setFields("last_name", e.target.value)}
      />
      <TextField
        label={"Почта"}
        variant={"outlined"}
        value={fields.email}
        onChange={e => setFields("email", e.target.value)}
      />
      <Autocomplete
        value={statuses.find(e => e.label === fields["role"])}
        label={"Статус"}
        options={statuses}
        onChange={(event, newValue) => setFields("role", newValue.value || "")}
        width={225}
      />
    </Styled.FiltersDiv>
  )
}

export default MembersFilters
