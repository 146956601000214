import { axiosInstance as axios } from "shared/api/axiosInstance"

if (!localStorage.getItem("token_ref")) {
  delete axios.defaults.headers.common["Authorization"]
}

export const sendFeedbackEmail = async (name: string, text: string, email?: string) =>
  axios.post("/send-email-feedback/", { name: name, text: text, email: email })

export const sendRequestEmail = async (name: string, text: string, email?: string) =>
  axios.post("/send-email-request-join/", { name: name, text: text, email: email })
