// @ts-ignore
import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"
axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

interface INotification {
  course_id: number
  users: number[]
  title: string
  content: string
}

export const createNotification = async (payload: INotification) => {
  await axios.post(`/notifications/notify_group/`, payload)
}

export const createFeedbackNotification = async (
  content: string,
  assignmentId: number,
  courseId: number,
  onSuccess?: () => void,
  onError?: () => void
) => {
  axios
    .post(`content/assignments/${assignmentId}/feedback_notification/`, { content, course: courseId })
    .then(onSuccess)
    .catch(onError)
}
