import { styled } from "@mui/material/styles"
import { Chip, Grid, Typography } from "@mui/material"

export const MainOrganizationCardDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}))

export const OrganizationCardTitle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(19),
}))

export const OrganizationCardDescription = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: theme.spacing(28),
  marginTop: theme.spacing(12),
}))

export const OrganizationCardCategories = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(3),
}))

export const CategoryChip = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  background: "transparent",
}))

export const OrganizationCardForLandingMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  background: "#fff",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(6),
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  height: "auto",
}))

export const OrganizationCardForLandingTitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "flex-start",
  marginBottom: theme.spacing(8),
}))

export const TitleWithDescriptionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
}))

export const OrganizationLogoForLanding = styled("img")(({ theme }) => ({
  width: theme.spacing(25),
}))

export const OrganizationEventsItemsDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(6),
  },
}))

export const OrganizationEventItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "32%",
  height: "150px",
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  cursor: "pointer",
  "&:hover": {
    opacity: "0.5",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))

export const StyledOrganizationTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(5),
  },
}))

export const StyledEventTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    fontSize: theme.spacing(5),
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(4),
  },
}))

export const ProgramsLink = styled(Typography)(({ theme }) => ({
  color: "#1565C0",
  cursor: "pointer",
  textDecoration: "underline",
  "&:hover": {
    opacity: 0.5,
  },
  marginTop: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(6),
  },
}))
