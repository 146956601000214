import React, { useMemo, useState } from "react"
import { styled } from "@mui/material/styles"

import {
  Avatar,
  Button,
  Box,
  Link,
  Grid,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  CardActionArea,
  Typography as MuiTypography,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { AvatarGroup as MuiAvatarGroup } from "@mui/material"
import { spacing } from "@mui/system"
import { useTheme } from "shared/context"
import useStyles from "./styles"

import { Card } from "shared/ui"

import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import VideocamIcon from "@mui/icons-material/Videocam"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"

const CardContent = styled(MuiCardContent)`
  padding: 0px;
  padding-top: 16px;
`

const CardMedia = styled(MuiCardMedia)`
  height: 120px;
  width: 120px;
`

const Typography = styled(styled(MuiTypography)(spacing))`
  font-family: Roboto;
`

export function StageItemEvent(props) {
  const chip = props.chip

  const classes = useStyles()
  const theme = useTheme()

  const [openDialog, setOpenDialog] = useState(false)

  const AvatarGroup = styled(MuiAvatarGroup)`
    margin-left: ${theme.spacing(2)};
  `

  const bottomColor = "#FFFFFF"

  function getStyles() {
    let styles = { opacity: isAccessible() ? 1.0 : 0.5, height: "100%" }
    if (props.content_type === "content") {
      styles.backgroundColor = "#FFFFFF"
    } else if (props.content_type === "offline") {
      styles.background = "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)"
    } else if (props.content_type === "online") {
      styles.background = "linear-gradient(90deg, #2E72EF 0%, #438BFF 100%)"
    } else if (props.content_type === "hybrid") {
      styles.background = "linear-gradient(90.12deg, #FF6758 25.77%, #2E72EF 98.29%)"
    }
    return styles
  }

  function stringifyDates() {
    if (!props.start || !props.finish) {
      return "Без ограничений по времени"
    }
    return props.stringifyDates(props.start, props.finish)
  }

  const today = useMemo(() => {
    return new Date()
  }, [props])

  function isAccessible() {
    if (!!props.link_on_location && !!props.link_on_webinar && props.content_type === "hybrid") return true
    if (
      (!props.link_on_webinar && props.content_type === "online") ||
      (!props.link_on_location && props.content_type === "offline")
    )
      return false
    if (props.content_type === "offline") return true
    if (props.content_type === "online" && props.start == "") return false
    if (props.content_type === "online")
      return new Date(props.start) <= today.addDays(1) && Boolean(props.link_on_webinar)
  }

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  function getLinkDescription() {
    if (!props.link_on_webinar) return "Ссылка недоступна"
    if (!props.start) return "Нет даты начала"
    let t = new Date(props.start)
    t.setHours(props.time.split(":")[0])
    t.setMinutes(props.time.split(":")[1].split(" ")[0])
    if (props.link_on_webinar && t.toDateString() === today.addDays(1).toDateString()) return "Ссылка на конференцию"
    if (t < today) {
      return "Ссылка на запись"
    }
    if (t.toDateString() === today.toDateString() && props.link_on_webinar) {
      return "Ссылка на конференцию"
    }
    if (t > today) {
      return "Ссылка будет доступна позже"
    }
  }

  function handleClickOnRegistration() {
    if (props.remote_resource_link) window.open(props.remote_resource_link, "_blank")
  }

  function onClickCard() {
    if (props.content_type === "offline" || props.content_type === "hybrid") setOpenDialog(true)
  }

  return (
    <Card style={getStyles()}>
      <CardActionArea
        component={Link}
        disabled={!isAccessible()}
        href={props.content_type === "online" ? props.link_on_webinar : null}
        onClick={onClickCard}
        target="_blank"
        rel="noopener noreferrer"
        style={{ height: "100%" }}
      >
        <div style={{ height: "80%", padding: 24 }}>
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", gap: 20 }}>{chip}</div>
              <div className={classes.circle} style={{ color: bottomColor, border: `1px solid ${bottomColor}` }}>
                {props.index}
              </div>
            </div>
          </CardContent>
          <Grid
            container
            spacing={0}
            sx={{ [theme.breakpoints.down("xs")]: { display: "flex", flexDirection: "column" } }}
          >
            <Grid item xs={9}>
              <CardContent>
                <Typography gutterBottom variant="h4" sx={{ fontSize: 20, color: "#FFFFFF" }}>
                  {props.title}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={3}>
              {props.image ? <CardMedia image={props.image} /> : null}
            </Grid>
          </Grid>
          <CardContent>
            <Typography
              mb={4}
              fontSize={"small"}
              fontWeight={"small"}
              variant={"body2"}
              sx={{ fontSize: 16, color: "#FFFFFF" }}
            >
              <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
            </Typography>
          </CardContent>
        </div>
        <CardActions
          style={{
            justifyContent: "space-between",
            borderTop: `1px solid ${bottomColor}`,
            height: "20%",
            padding: 24,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 8 }}>
            {props.content_type === "offline" && (
              <>
                <LocationOnOutlinedIcon fontSize="large" style={{ color: bottomColor }} />
                <p style={{ color: bottomColor }}>Место проведения</p>
              </>
            )}
            {props.content_type === "online" && (
              <>
                <div className={classes.circle} style={{ backgroundColor: bottomColor }}>
                  <VideocamIcon style={{ color: "#2E72EF" }} />
                </div>
                <p style={{ color: bottomColor }}>{getLinkDescription()}</p>
              </>
            )}
            {props.content_type === "hybrid" && (
              <>
                <div className={classes.circle} style={{ backgroundColor: bottomColor }}>
                  <VideocamIcon style={{ color: "#2E72EF" }} />
                </div>
                <LocationOnOutlinedIcon fontSize="large" style={{ color: bottomColor }} />
                <p style={{ color: bottomColor }}>Место проведения</p>
              </>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 8 }}>
            <CalendarMonthIcon style={{ color: bottomColor }} />
            <Typography variant={"caption"} sx={{ color: bottomColor }}>{`${stringifyDates()}${
              !!props.time ? `, ${props.time}` : ""
            }`}</Typography>
          </div>
          <AvatarGroup max={3}>
            {props.affiliations_info && props.affiliations_info.map(e => <Avatar alt="Logo" src={e.icon_logo} />)}
          </AvatarGroup>
        </CardActions>
      </CardActionArea>
      <Dialog
        aria-labelledby="DialogTitle"
        aria-describedby="DialogDescription"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogContent>
          {!!props.link_on_webinar && (
            <>
              <p
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "20px",
                  letterSpacing: "0.0595px",
                  color: "#313439",
                }}
              >
                Онлайн-Конференция
              </p>
              <p
                onClick={() => {
                  window.open(props.link_on_webinar, "_blank")
                }}
                style={{
                  marginTop: "16px",
                  marginBottom: "40px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  cursor: "pointer",
                  lineHeight: "21px",
                  textDecoration: "underline",
                  letterSpacing: "0.07875px",
                  color: "#3483F6",
                }}
              >
                {getLinkDescription()}
              </p>
            </>
          )}
          <p
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "24px",
              lineHeight: "20px",
              letterSpacing: "0.0595px",
              color: "#313439",
            }}
          >
            Место проведения
          </p>
          <p
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "21px",
              letterSpacing: "0.07875px",
              color: "#313439",
            }}
          >
            {props.location_description}
          </p>
          {props.remote_resource_link && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={handleClickOnRegistration} className={classes.registrationButton}>
                РЕГИСТРАЦИЯ НА МЕРОПРИЯТИЕ
              </button>
            </div>
          )}
          <p
            style={{
              marginTop: "40px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "21px",
              letterSpacing: "0.07875px",
              color: "#313439",
            }}
          >
            Как добраться
          </p>
          <iframe src={props.link_on_location} width="500" height="300" style={{ border: 0 }}></iframe>
        </DialogContent>
        <DialogActions className={classes.dialogEmail}>
          <Button onClick={() => setOpenDialog(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
