import * as Styled from "./SectionTabs.styles"

interface IProps {
  options: { title: string; value: number; enable: boolean }[]
  onChange: (e: React.SyntheticEvent, value: number) => void
  value: number | null
}

const SectionTabs = ({ options, onChange, value }: IProps) => {
  return (
    <Styled.Tabs value={value} onChange={onChange}>
      {options.map(option => (
        <Styled.Tab label={<Styled.Label>{option.title}</Styled.Label>} disabled={!option.enable} />
      ))}
    </Styled.Tabs>
  )
}

export { SectionTabs }
