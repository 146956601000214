import React from "react"
import useStyles from "./styles"
import { Button } from "../../../shared/ui"
import { Typography } from "@mui/material"

const MainScreen = ({ e, r, titles, refs }) => {
  const classes = useStyles()

  function handleClick() {
    if (!!e?.to_block) {
      let index = titles.indexOf(e?.to_block)
      return refs.current[index].ref.current.scrollIntoView()
    }
  }

  return (
    <div ref={r}>
      <div className={classes.mainScreenContainer} style={{ backgroundColor: e.backgroundColor }}>
        <div className={classes.mainScreenContentContainer}>
          <div className={classes.mainScreenTextContainer}>
            {!!e.H1 && (
              <Typography variant={"h1"} sx={{ color: e.H1.color }}>
                {e.H1.text}
              </Typography>
            )}
            {!!e.BaseText && (
              <Typography variant={"body1"} sx={{ color: e.BaseText.color }}>
                {e.BaseText.text}
              </Typography>
            )}
            {!!e.button && (
              <Button
                sx={{
                  color: e?.button?.color,
                  background: e?.button?.backgroundColor,
                  width: "50%",
                  "&:hover": { backgroundColor: e?.button?.hoverBackgroundColor },
                }}
                onClick={handleClick}
              >
                {e.button.text}
              </Button>
            )}
          </div>
          {!!e.Image?.url && <img src={e.Image.url} className={classes.imageMain} />}
        </div>
      </div>
    </div>
  )
}

export default MainScreen
