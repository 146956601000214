import * as Styled from "./ModalTimer.style"
import { Dialog } from "@mui/material"
import { ReactNode } from "react"

interface IProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  size?: string
  error?: string
}
const ModalTimer = (props: IProps) => {
  const { children, isOpen, onClose, size = "default", error } = props
  const isSmall = size === "small"

  return (
    <Dialog
      aria-labelledby="Modal"
      aria-describedby="Modal Template"
      open={isOpen}
      onClose={onClose}
      PaperProps={Styled.DialogStyle(isSmall)}
    >
      <Styled.DialogHeader variant="h3">
        <Styled.CloseWrapper>
          <Styled.Close onClick={onClose} />
        </Styled.CloseWrapper>
      </Styled.DialogHeader>
      <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
    </Dialog>
  )
}

export { ModalTimer }
