import styled from "@emotion/styled"
import * as Styled from "./ExaminationModal.styles"
import { Typography } from "@mui/material"
import { AssignmentStatus } from "entities/Assignment/model/assignment.types"
import { wordMapper } from "shared/local"
import { useEffect, useState } from "react"

const mock_data = [
  {
    mentor: "hripunova.tatyanavadimovna@yandex.ru",
    status: AssignmentStatus.rejected,
    comment: "Описание команды концентрирует вихревой резонато",
    score: "1/3",
    date: "12.12.2023",
    time: "17:55:55",
  },
  {
    mentor: "drugoiteacher@example.com",
    status: AssignmentStatus.accepted,
    score: "1/3",
    comment: "Описание команды концентрирует вихревой резонато",
    date: "12.12.2023",
    time: "17:55:55",
  },
  {
    mentor: "drugoiteacher@example.com",
    status: AssignmentStatus.submitted,
    score: "1/3",
    comment: "Описание команды концентрирует вихревой резонато",
    date: "12.12.2023",
    time: "17:55:55",
  },
]

const ExaminationHistory = () => {
  const [history, setHistory] = useState(mock_data)

  useEffect(() => {
    setHistory([])
  }, [])

  if (!history.length) return null

  return (
    <Styled.HistoryWrapper>
      <Title>История проверок</Title>
      <List>
        {history.map(item => (
          <Item>
            <div>
              <ItemRow>
                <ItemLabel>Проверяющий: </ItemLabel>
                <ItemText>{item.mentor}</ItemText>
              </ItemRow>
              <ItemRow>
                <ItemLabel>Статус: </ItemLabel>
                <ItemText status={item.status}>{wordMapper(item.status)}</ItemText>
              </ItemRow>
              <ItemRow>
                <ItemLabel>Балл: </ItemLabel>
                <ItemText>{item.score}</ItemText>
              </ItemRow>
              <ItemRow>
                <ItemLabel>Комментарий к работе: </ItemLabel>
                <ItemText>{item.comment}</ItemText>
              </ItemRow>
            </div>
          </Item>
        ))}
      </List>
    </Styled.HistoryWrapper>
  )
}

const Title = styled(Typography)`
  margin-block: 16px 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 155%;
`

const List = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

const Item = styled.div`
  display: flex;
  gap: 64px;
  justify-content: space-between;
`

const ItemRow = styled.div`
  display: flex;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const ItemLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
`

const ItemText = styled(Typography)<{ status?: AssignmentStatus }>`
  color: ${props => getStatusColor(props.status)};
  font-size: 14px;
  font-weight: 300;
  line-height: 125%;
`

const getStatusColor = (status?: AssignmentStatus) => {
  if (status === AssignmentStatus.accepted) return "#228B45"
  if (status === AssignmentStatus.rejected) return "#D82E2E"
  return "#313439"
}

export { ExaminationHistory }
