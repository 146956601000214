import styled from "@emotion/styled"
import SliderButtonArrowIcon from "images/icons/SliderButtonArrow"
import { Typography } from "@mui/material"
import { Loader } from "shared/ui"

type DirectionType = "left" | "right"

export const SliderButton = ({
  onClick,
  isDisabled,
  direction,
  name,
  email,
  isLoading,
  hasNextPage,
}: {
  onClick: () => void
  direction: DirectionType
  name: string
  email: string
  isDisabled: boolean
  isLoading?: boolean
  hasNextPage?: boolean
}) => {
  const isLeft = direction === "left"
  const label = isLeft ? "НАЗАД" : "ВПЕРЁД"

  return (
    <ButtonWrapper onClick={onClick} isDisabled={isDisabled} isReverse={isLeft}>
      {isLoading ? (
        <Loader />
      ) : (
        <StudentWrapper>
          <ButtonLabel name={name} email={email} hasNextPage={hasNextPage} isLoading={isLoading} />
        </StudentWrapper>
      )}

      <DirectionWrapper isReverse={isLeft}>
        <ArrowWrapper isReverse={isLeft}>
          <SliderButtonArrowIcon />
        </ArrowWrapper>
        <Label>{label}</Label>
      </DirectionWrapper>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div<{ isReverse: boolean; isDisabled?: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-direction: ${props => (props.isReverse ? "row" : "row-reverse")};
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  width: 400px;
  height: 120px;
  border-radius: 12px;
  padding-block: 12px;
  padding-inline: ${props => (props.isReverse ? "32px 12px" : "12px 32px")};
  opacity: ${props => (props.isDisabled ? 0.4 : 1)};
  cursor: ${props => (!props.isDisabled ? "pointer" : "auto")};
  transition: background-color 0.4s, opacity 0.4s;

  &:hover {
    background-color: ${props => !props.isDisabled && props.theme.palette.secondary.dark};
  }
`

const ButtonLabel = ({
  name,
  email,
  hasNextPage,
  isLoading,
}: {
  name: string
  email: string
  hasNextPage?: boolean
  isLoading?: boolean
}) => {
  if (isLoading) return ""
  if (name)
    return (
      <>
        <StudentName>{name}</StudentName>
        <StudentEmail>{email}</StudentEmail>
      </>
    )

  if (hasNextPage) return "Загрузить участников"
  return ""
}

const StudentWrapper = styled.div`
  text-align: center;
  max-width: 230px;

  & > p:not(:last-child) {
    margin-bottom: 8px;
  }
`

const StudentName = styled(Typography)`
  font-size: 14px;
`

const StudentEmail = styled(Typography)`
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 300;
  line-height: 125%;
`

const DirectionWrapper = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isReverse ? "row" : "row-reverse")};
  gap: 10px;
  width: 114px;
  height: 32px;
  padding: 8px 16px;
`

const Label = styled(Typography)`
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.28px;
`

const ArrowWrapper = styled.div<{ isReverse: boolean }>`
  rotate: ${props => (props.isReverse ? "0deg" : "180deg")};
`
