import { styled } from "@mui/styles"

const radius = 16

export const SelectorContainer = styled("div")(() => ({
  position: "relative",
}))

export const SelectWrapper = styled("div")(() => ({
  position: "relative",
  width: "fit-content",
  marginLeft: "auto",
  display: "flex",
  gap: 8,
  backgroundColor: "#F9F9F9",
  borderRadius: radius,
  cursor: "pointer",
  paddingRight: 15,
  transition: "opacity 0.2s",

  "&:hover": {
    opacity: 0.7,
  },
}))

export const Label = styled("span")(({ isOutlined, enableEventDateSelector }: { isOutlined: boolean, enableEventDateSelector: boolean }) => ({
  textAlign: "center",
  minWidth: 140,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "12px",
  backgroundColor: isOutlined ? '#F9F9F9' : enableEventDateSelector ? '#00A355' : '#EBEBEB',
  color: isOutlined ? "#313439" : enableEventDateSelector ? "#FFFFFF" : "#98999C",
  border: `1px solid ${isOutlined ? "#00A355" : "transparent"}`,
  borderRadius: radius,
  padding: "6px 12px",
}))

export const IconWrapper = styled("div")(({ enableEventDateSelector }: { enableEventDateSelector: boolean }) => ({
  height: "24px",
  rotate: "180deg",
  backgroundColor: '#F9F9F9',
  color: enableEventDateSelector ? "#313439" : "#98999C",
}))

export const ContentWrapper = styled("div")(({ wrapperRef }: { wrapperRef: any }) => ({
  zIndex: 900,
  width: "fit-content",
  position: "fixed",
  top: wrapperRef?.current?.getBoundingClientRect().bottom,

  "&::before": {
    content: '""',
    display: "block",
    height: "2px",
  },
}))

export const Content = styled("div")(({ theme, type }: { theme: any; type: "default" | "rounded" }) => ({
  display: "inline-block",
  boxShadow:
    "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.09), 0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 9px 0px rgba(0, 0, 0, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0.00);",
  backgroundColor: type === "rounded" ? "#F9F9F9" : "#fff",
  borderRadius: type === "rounded" ? 12 : 0,
}))
