import React, { useState } from "react"
import { Button } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { RequestMessage } from "../../shared/assets/RequestMessage/RequestMessage"

export const DeleteTag = ({
  tag,
  deleteTag,
}: {
  tag: { value: number; label: string }
  deleteTag: (id: number) => void
}) => {
  const [error, setError] = useState<boolean | null>(null)
  const [text, setText] = useState("")

  function handleDeleteTag() {
    axios
      .delete(`content/tags/${tag.value}/`)
      .then(res => {
        deleteTag(tag.value)
        setError(false)
        setText("Тег удалён")
      })
      .catch(err => {
        setError(true)
        setText("Тега не существует")
      })
  }

  return (
    <>
      {/*@ts-ignore*/}
      <Button variant={"outlined"} color={"accent"} onClick={handleDeleteTag}>
        Удалить тег
      </Button>
      {text.length > 0 && <RequestMessage error={!!error} text={text} />}
    </>
  )
}
