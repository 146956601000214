import { styled } from "@mui/material"
import WarningModal from "../../../entities/Statistic/ui/WarningModal"

interface IProps {
  isOpen: boolean
  onClose: () => void
  filterCount: number
  userCount: number
  onSubmit: () => void
}

const WarningDownloadModal = ({ isOpen, onClose, filterCount, userCount, onSubmit }: IProps) => {
  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Вы скачиваете неполную таблицу статистики"}
      label="Всё равно скачать"
      onSubmit={onSubmit}
      isDownload
      isCheckbox
    >
      <Typography>
        В скачиваемой таблице будут отображены только <Strong>{userCount}</Strong> из <Strong>{filterCount}</Strong>{" "}
        выбранных вами пользователей.
      </Typography>
    </WarningModal>
  )
}

const Typography = styled("span")(() => ({
  color: "#000",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "155%",
}))

const Strong = styled("span")(() => ({
  fontWeight: 500,
  lineHeight: "115%",
  letterSpacing: "0.2px",
}))

export default WarningDownloadModal
