export const getMargin = (count: number) => {
  if (count === 1) {
    return 10
  } else {
    return 30
  }
}
export const getSize = (count: number) => {
  if (count === 1) {
    return 355
  } else if (count === 2) {
    return 355
  } else {
    return 355
  }
}

export const filterTotalFromCharts = (charts: any): any => {
  const result = {}
  const chartLabels = Object.keys(charts)
  chartLabels.forEach((label: string) => {
    const chartData = charts[label]
    const filteredData = chartData.filter((data: any) => data?.type !== "total")
    // @ts-ignore
    result[label] = filteredData
  })

  return result
}
