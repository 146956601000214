import * as Mui from "@mui/material"
import { useRef, useState } from "react"
import { HeaderCell, TableContainer, SortIcons, TableWrapper, Table, Header } from "./TableTemplate.styles"
import { ScrollButtons, TableLoader } from "shared/assets"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import VisibilitySensor from "react-visibility-sensor"
import { IconButton } from "../IconButton/IconButton"
import { Size } from "../styles.const"

interface IProps {
  headers: IHeader[]
  setSortBy: (x: { type: string; direction: number }) => void
  loadMore: () => void
  hasNextPage: boolean
  isLoading: boolean
  children?: React.ReactNode
  height?: string
}

interface IHeader {
  label: string
  width: number
  sortType?: string
}

const TableTemplate = ({ headers, loadMore, hasNextPage, isLoading, setSortBy, children, height = "80vw" }: IProps) => {
  const tableContainerRef = useRef<HTMLTableSectionElement>(null)
  const [sort, setSort] = useState<{ type: null | string; direction: null | number }>({ type: null, direction: null })

  const handleScrolledToBottom = (isBottomVisible: boolean) => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      loadMore()
    }
  }

  const handleSort = (e: { type: string; direction: number }) => {
    if (sort.type === e.type && sort.direction === e.direction) return
    tableContainerRef.current && tableContainerRef.current.scroll({ top: 0 })
    setSort(e)
    setSortBy(e)
  }

  return (
    <TableContainer>
      <ScrollButtons tableRef={tableContainerRef} />
      <TableWrapper ref={tableContainerRef} height={height}>
        <Table stickyHeader size="small">
          <Header>
            <Mui.TableRow>
              {headers.map(header =>
                header.sortType ? (
                  <SortableCell
                    label={header.label}
                    value={header.sortType}
                    setSortBy={handleSort}
                    key={header.label}
                    width={header.width}
                  />
                ) : (
                  <UnsortableCell label={header.label} width={header.width} />
                )
              )}
            </Mui.TableRow>
          </Header>
          <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
            <Mui.TableBody>{children}</Mui.TableBody>
          </VisibilitySensor>
        </Table>
        <TableLoader isLoading={isLoading}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
      </TableWrapper>
    </TableContainer>
  )
}

const SortableCell = ({
  label,
  value,
  width,
  setSortBy,
}: {
  label: string
  value: string
  width: number
  setSortBy: (x: { type: string; direction: number }) => void
}) => (
  <Mui.TableCell align="center" sx={{ width }}>
    <HeaderCell>
      <Mui.Typography variant={"caption"} fontWeight={"small"} sx={{ height: "34px" }}>
        {label.includes("\n") && <br />}
        {label}
      </Mui.Typography>
      <SortIcons>
        {/*@ts-ignore*/}
        <IconButton onClick={() => setSortBy({ type: value, direction: -1 })} size={Size.small_x}>
          <ArrowDropDownIcon fontSize="large" sx={{ color: "#313439" }} />
        </IconButton>
        {/*@ts-ignore*/}
        <IconButton onClick={() => setSortBy({ type: value, direction: 1 })} size={Size.small_x}>
          <ArrowDropUpIcon fontSize="large" sx={{ color: "#313439" }} />
        </IconButton>
      </SortIcons>
    </HeaderCell>
  </Mui.TableCell>
)

const UnsortableCell = ({ label, width, isLeft }: { label: string; width: number; isLeft?: boolean }) => (
  <Mui.TableCell
    align={isLeft ? "left" : "center"}
    sx={{ paddingLeft: isLeft ? 20 : "auto", paddingBottom: 14, verticalAlign: "bottom", lineHeight: "1.25", width }}
  >
    <Mui.Typography variant={"caption"} fontWeight={"small"}>
      {label}
    </Mui.Typography>
  </Mui.TableCell>
)

export default TableTemplate
