export const defaultTemplate = {
  header_text: "Курсы",
  props: [
    {
      index: 1,
      type: "carousel",
      activeButtonColor: "#06062E",
      borderButtonColor: "#06062E",
      navButtonColor: "#06062E",
      backgroundColor: "#FFF",
      slides: [
        {
          backgroundColor: "#06062E",
          title: { text: "Название 1", color: "#FFF" },
          button: {
            backgroundColor: "#FFF",
            color: "#0069B4",
            borderColor: "#FFF",
            hoverColor: "#FFF",
            text: "Подробнее",
            to_block: "2",
          },
          image: "",
          description: { text: "Описание" },
        },
        {
          title: { text: "Название 2", color: "#FFF" },
          description: { color: "#FFF", text: "Описание" },
          backgroundColor: "#06062E",
          image: "",
          button: {
            text: "Подробнее",
            color: "#0069B4",
            backgroundColor: "#FFF",
            hoverColor: "#FFF",
            borderColor: "#FFF",
            to_block: "7",
          },
          to_block: "",
        },
        {
          title: { text: "Название 3", color: "#FFF" },
          description: { color: "#FFF", text: "Описание" },
          backgroundColor: "#06062E",
          image: "",
          to_block: "3",
          button: {
            text: "Подробнее",
            color: "#0069B4",
            backgroundColor: "#FFF",
            hoverColor: "#FFF",
            borderColor: "#FFF",
            to_block: "5",
          },
        },
      ],
    },
    {
      type: "features_list",
      backgroundColor: "#FFF",
      items: [
        {
          image: "https://beta.syncwoia.com/static/media/extra/1/img_istok_v2/img_istok_v2/adaptive.png",
          icon: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/task.svg",
          title: "Фича 1",
          text: "Опичание фичи 1",
          textColor: "#000",
          direction: "normal",
          backgroundImage: "#EFEFEF",
        },
        {
          image: "https://beta.syncwoia.com/static/media/extra/1/img_istok_v2/img_istok_v2/tests.png",
          icon: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/certificate.svg",
          title: "Фича 2",
          text: "Описание фичи 2",
          textColor: "#000",
          direction: "reverse",
          backgroundImage: "#EFEFEF",
        },
      ],
      H2: { text: "Фичи", color: "#000" },
      index: 2,
      title: "Фичи 1",
    },
    {
      index: 3,
      type: "advantages_new",
      title: "Преимущества",
      options: [
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/group.svg",
          title: { text: "Приемущество 1", color: "#313439" },
          text: { text: "Описание", color: "#313439" },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/certificate.svg",
          title: { text: "Приемущество 2", color: "#313439" },
          text: { text: "Описание", color: "#313439" },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/task.svg",
          title: { text: "Приемущество 3", color: "#313439" },
          text: { text: "Описание", color: "#313439" },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/fast.svg",
          title: { text: "Приемущество 4", color: "#313439" },
          text: { text: "Описание", color: "#313439" },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/online.svg",
          title: { text: "Приемущество 5", color: "#313439" },
          text: { text: "Описание", color: "#313439" },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/security.svg",
          title: { text: "Приемущество 6", color: "#313439" },
          text: { text: "Описание", color: "#313439" },
        },
      ],
      H2: { text: "Преимущества" },
    },
    {
      type: "description_company",
      index: 4,
      H2: { text: "Исследования", color: "#000" },
      Text: {
        text: "<b>В основу системы оценивания положены соответствующие научные исследования.</b> <p>Существует постоянная программа исследований и проверки, предназначенная для оценивания результатов и совершенствования системы тестирования.</p>",
        color: "#000",
      },
      backgroundTextColor: "#EFEFEF",
      title: "Исследования",
    },
    {
      type: "catalog",
      index: 5,
      title: "Каталог",
      H2: { text: "Тесты и программы", color: "#000" },
    },
    {
      type: "description_company",
      index: 6,
      H2: { text: "Процедура тестирования", color: "#000" },
      Text: {
        text: "<b>Тестирование проводится в аккредитованном центре ИСТОК под наблюдением проктора или в формате онлайн-персонализированного участия с онлайн-прокторингом.</b> <p>Во время тестирования экзаменующийся подключается к платформе, включает камеру и оборудование (гранитуру) и приступает к выполнению заданий. <br/>Процедура тестирования прописана в регламенте, заказчик теста подписывает согласие на проведение тестирования с прокторингом в соответствии с регламентом, экспертизу ответов на определение/подтверждение уровня владения языком.</p>",
        color: "#000",
      },
      backgroundTextColor: "#EFEFEF",
      title: "Описание компании",
      Image: { url: "https://syncwoia.com/static/media/extra/837/org_istok/protsedura_testirovaniya.png" },
    },
    {
      index: 7,
      type: "gallery",
      title: "Документы",
      options: [
        { url: "https://syncwoia.com/static/media/extra/837/org_istok/sv.png" },
        { url: "https://syncwoia.com/static/media/extra/837/org_istok/sv.png" },
        { url: "https://syncwoia.com/static/media/extra/837/org_istok/sv.png" },
        { url: "https://syncwoia.com/static/media/extra/837/org_istok/sv.png" },
      ],
      H2: { text: "Документы", color: "#FFF" },
      backgroundColor: "#06062E",
      arrowColor: "#0069B4",
    },
    {
      index: 8,
      type: "advantages_new",
      title: "Преимущества",
      options: [
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/people.svg",
          title: { text: ">10 000 человек", color: "#313439" },
          text: { text: "Прошли тестирование с 2022 г. по 2024 г." },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/university_new.svg",
          title: { text: "5 вузов-партнеров, апробировавших тестирование", color: "#313439" },
          text: {
            text: "Тюменский государственный университет, Тамбовский технический университет, Всероссийская академия внешней торговли, Российская экономическая школа, Поволжский технический университет",
          },
        },
        {
          backgroundColor: "#9CEEF5",
          image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/expert.svg",
          title: { text: "5 внешних экспертов", color: "#313439" },
          text: { text: "Сертифицированные экзаменаторы IELTS проверяют письменную часть тестирования" },
        },
      ],
      H2: { text: "Статистика" },
    },
    {
      index: 9,
      type: "organization_team",
      background_image: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/background_tem_project.png",
      main_block: {
        background_color: "#06062E",
        title: { text: "Команда разработчиков", color: "#FFF" },
        text: {
          text: "Мы создали новый стандарт в языковой подготовке для высшего образования. Апробировали его, получили результаты и положительные отзывы внешних университетов.",
          color: "#FFF",
        },
      },
      items: [
        {
          full_name: "Базанова Елена Михайловна",
          about:
            "Научный руководитель проекта, кандидат педагогических наук, доцент, руководитель Департамента иностранных языков МФТИ",
          avatar: "https://syncwoia.com/static/media/extra/837/org_istok/bazanova.png",
        },
        {
          full_name: "Горизонтова Анна Всеволодовна",
          about:
            "Руководитель группы разработчиков тестового материала, кандидат исторических наук, доцент Департамента иностранных языков МФТИ",
          avatar: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/gorizontova.png",
        },
        {
          full_name: "Чикаке Тендай Мапунгвана",
          about: "Программный инженер (AI-тренер), инженер лаборатории ДИЯ МФТИ",
          avatar: "https://beta.syncwoia.com/static/media/extra/1/istok/istok/chikake.png",
        },
        {
          full_name: "Зухба Расим Даурович",
          about: "Математик-исследователь, старший преподаватель кафедры высшей математики МФТИ",
          avatar: "https://syncwoia.com/static/media/extra/837/org_istok/zuhba.png",
        },
        {
          full_name: "Грибова Наталья Николаевна",
          about: "Лингвист-исследователь, кандидат филологических наук, доцент Департамента иностранных языков МФТИ",
          avatar: "https://syncwoia.com/static/media/extra/837/org_istok/gribova.png",
        },
      ],
      title: "Команда проекта",
    },
    {
      type: "partners",
      backgroundColor: "#FFF",
      H2: { text: "Партнеры", color: "#000" },
      options: [
        {
          url: "https://beta.syncwoia.com/static/media/extra/1/img_istok_v2/img_istok_v2/logo_fld_mipt.png",
          website: "https://dfl.mipt.ru/",
        },
        {
          url: "https://beta.syncwoia.com/static/media/extra/1/img_istok_v2/img_istok_v2/logo_deeppavlov.png",
          website: "https://deeppavlov.ai/",
        },
        {
          url: "https://syncwoia.com/static/media/extra/837/orglogo/syncwoia-logo.svg",
          website: "https://beta.syncwoia.com",
        },
        {
          url: "https://old.mipt.ru/upload/medialibrary/426/nti_logotip_bez_fona_siniy_rgb.png",
          website: "https://ai.mipt.ru/",
        },
        {
          url: "https://sun9-38.userapi.com/impf/c845124/v845124955/ef586/N_mYOk-HxUA.jpg?size=2048x2048&quality=96&sign=ec9c91fd2149f2e852dcee4292fb0d9a&type=album",
          website: "https://vk.com/nwccconsortium",
        },
      ],
      index: 10,
    },
    {
      index: 11,
      type: "contacts",
      location_url:
        "https://yandex.ru/map-widget/v1/?um=constructor%3Ab1e5cc5e97e705cf10703d3286237d314e30fc18def6b6f136ce1164f9d2e23f&amp;source=constructor",
      phone: "+7 (498) 713-92-01<br/>+7 (495) 408-54-27<br/>+7 (498) 744-65-67",
      location:
        "г. Долгопрудный, ул. Первомайская, д. 5, корпус Микроэлектроники МФТИ, к. 303.<p>г. Долгопрудный, ул. Первомайская, д. 30, Центр языковой подготовки и тестирования.</p>",
      title: "Контакты",
    },
    {
      type: "footer",
      textColor: "#FFF",
      contacts: {
        text: "Интеллектуальная Система Тестирования Общеязыковых Компетенций<p>г. Долгопрудный, ул. Первомайская, д. 5, корпус Микроэлектроники МФТИ, к. 303;<br/>г. Долгопрудный, ул. Первомайская, д. 30, Центр языковой подготовки и тестирования.</p>",
      },
      index: 12,
      backgroundColor: "#06062E",
      catalog: [
        {
          title: "Тест на определение уровня владения языком",
          url: "https://istoktest.ru/event/leveltest",
        },
      ],
      title: "Футер",
      catalog_title: "Тесты и программы",
    },
    {
      type: "main_screen",
      index: 13,
      backgroundColor: "#29A4C1",
      H1: {
        text: "Тут название организации",
        color: "#FFF",
      },
      BaseText: {
        text: "Тут должно быть какое-то подробное описсание организации",
        color: "#FFF",
      },
      Image: {
        url: "https://syncwoia.com/static/media/extra/1/newImageMainPark/newImageMainPark/image_main.svg",
      },
    },
    {
      type: "tab",
      index: 14,
      backgroundColor: "#29A4C1",
      textColor: "#FFF",
      lineColor: "#FBE521",
      activeTextColor: "#FBE521",
      arrowColor: "#FFF",
    },
    {
      title: "Описание",
      index: 15,
      type: "description_company",
      backgroundColor: "#FFF",
      H2: {
        text: "Тут заголовок блока",
        color: "#000",
        lineColor: "#FBE521",
      },
      Text: {
        text: "Тут должен быть большой текст",
      },
      Image: {
        url: "https://syncwoia.com/static/media/extra/1/smartpark/smartpark/image_about.jpg",
      },
    },
    {
      type: "video",
      index: 16,
      backgroundColor: "#FFF",
      url: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video/video.mp4",
      title: "ВИДЕО",
    },
    {
      title: "Преимущества",
      type: "advantages",
      index: 17,
      backgroundColor: "#FFF",
      H2: {
        lineColor: "#FBE521",
        color: "rgba(0, 0, 0, 1)",
        text: "Тут должно быть название блока",
      },
      options: [
        {
          H3: { text: "ПУНКТ 1", color: "#000" },
          BaseText: {
            text: "Описание пункта 1",
            color: "#000",
          },
          Image: { url: "https://syncwoia.com/static/media/extra/1/stages/stages/stage_1.png" },
        },
        {
          Image: { url: "https://syncwoia.com/static/media/extra/1/stages/stages/stage_2.png" },
          H3: { text: "ПУНКТ 2", color: "#000" },
          BaseText: {
            text: "Описание пункта 2",
            color: "#000",
          },
        },
        {
          Image: { url: "https://syncwoia.com/static/media/extra/1/stages/stages/stage_3.png" },
          H3: { text: "ПУНКТ 3", color: "#000" },
          BaseText: {
            text: "Описание пункта 3",
            color: "#000",
          },
        },
      ],
    },
    {
      type: "gallery",
      title: "ГАЛЛЕРЕЯ",
      index: 18,
      backgroundColor: "#2DB8C7",
      arrowColor: "#FBE521",
      options: [
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_1.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_2.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_3.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_4.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_5.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_6.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_7.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_8.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_9.jpg" },
      ],
    },
    {
      title: "ВИДЕО-ГАЛЕРЕЯ",
      type: "video_gallery",
      index: 19,
      backgroundColor: "#FFF",
      arrowColor: "#FBE521",
      H2: {
        text: "Видео-галлерея",
        color: "#000",
        lineColor: "#212121",
      },
      options: [
        {
          preview: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video1/preview1.png",
          video: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video1/video_1.mp4",
        },
        {
          preview: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video2/preview2.png",
          video: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video2/video_2.mp4",
        },
      ],
    },
    {
      title: "ОТЗЫВЫ",
      type: "reviews",
      index: 20,
      backgroundImage: "https://syncwoia.com/static/media/extra/1/smartpark/smartpark/feedback_img.jpg",
      H2: {
        text: "Отзывы",
        color: "#FFF",
        lineColor: "#FFF",
      },
      options: [
        {
          user_data: {
            status: "student",
            organization: "null",
            first_name: "Имя 1",
            last_name: "Фамилия 1",
            middle_name: "Отчество 1",
            about: "",
          },
          text: "Тут текст отзыва 1.",
        },
        {
          user_data: {
            status: "student",
            organization: "null",
            first_name: "Имя 2",
            last_name: "Фамилия 2",
            middle_name: "Отчество 2",
            about: "",
          },
          text: "Тут текст отзыва 2.",
        },
      ],
    },
    {
      type: "partners",
      title: "Партнеры",
      index: 21,
      backgroundColor: "#FFF",
      arrowColor: "#FBE521",
      H2: {
        text: "Организаторы и партнеры",
        color: "#000",
        lineColor: "#FBE521",
      },
      options: [
        {
          website: "https://syncwoia.com/",
          url: "https://syncwoia.com/static/media/extra/837/orglogo/syncwoia-logo.png",
        },
      ],
    },
    {
      title: "ВОПРОСЫ",
      type: "faq",
      backgroundColor: "#29A4C1",
      index: 22,
      textColor: "#FFF",
      iconColor: "#FFF",
      H2: {
        text: "Вопросы и ответы",
        color: "#FFF",
        lineColor: "#FBE521",
      },
      options: [
        {
          question: "Вопрос 1",
          answer: "Ответ 1",
        },
        {
          question: "Вопрос 2",
          answer: "Ответ 2",
        },
        { question: "Вопрос 3", answer: "Ответ 3" },
      ],
      lineColor: "#FBE521",
      borderBottomColor: "#FFF",
      textFieldColor: "#FFF",
    },
    {
      type: "feedback_form",
      backgroundColor: "#29A4C1",
      borderBottomColor: "#FBE521",
      index: 23,
      H2: {
        text: "Узнать больше",
        color: "#FFF",
        lineColor: "#FBE521",
      },
      baseText: {
        text: "Остались вопросы? Напишите нам!",
        color: "#FFF",
      },
      textFieldColor: "#FFF",
      button: {
        hoverBackgroundColor: "#F0F0F0",
        color: "#FFF",
        backgroundColor: "#F21",
      },
    },
  ],
}
