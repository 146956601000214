import React from "react";
import { styled } from '@mui/material/styles';
import { Card as MuiCard } from '@mui/material'

export const Card = styled(MuiCard)({
  background: "#FFFFFF",
  border: "2px solid #FBFBFB",
  boxShadow: "0px 0px 10px rgba(54, 54, 54, 0.1)",
  borderRadius: 6,
  fontFamily: "Roboto",
});
