import { filterNumericFields } from "entities/Course/lib/filterNumericFields"
import { sortItems } from "entities/Course/lib/CourseStatic.js"

function formCourseStatistic(courseProgress, infoFields, modules, sortBy) {
  let statistics = courseProgress.filter(filterNumericFields).sort((a, b) => sortItems(a, b, sortBy))
  let scores = new Array(statistics.length + 1)
  const titles = ["full_name", "email", "phone", "status", "category", "score", "completed"]
  scores[0] = [
    "ФИО",
    "Почта",
    "Телефон",
    "Статус",
    "Категория",
    "Баллы",
    "Статус прохождения",
    ...infoFields.map(e => e.title),
    ...modules,
  ]
  for (let index = 0; index < statistics.length; index++) {
    let info = statistics[index].participant.info
    scores[index + 1] = [
      ...titles.map(title => {
        if (title !== "score" && title !== "completed") return statistics[index].participant[title] || "\u2014"
        else if (title === "score") return Math.floor(statistics[index].score)
        else return statistics[index].completed ? "Пройдено" : "Не пройдено"
      }),
      ...infoFields.map(field => {
        for (let idx = 0; idx < info?.length; idx++) {
          if (info[idx].id == field.id) return info[idx].value
        }
        return "\u2014"
      }),
      ...modules.map(module => {
        const findedModule = getProgressModule(module, statistics[index].module_progress)
        if (!findedModule) return 0
        return findedModule.feedback ? `${findedModule.score}__${findedModule.feedback}` : findedModule.score
      }),
    ]
  }
  return scores
}

const getProgressModule = (task, modules) => {
  const result =
    (Array.isArray(modules) ? modules.find(module => module.module_info.title === task) : modules[task]) || null
  return result
}

export default formCourseStatistic
