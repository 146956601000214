import { Autocomplete, Dropdown, TextField } from "shared/ui"
import { FlexRow, FormWrapper } from "shared/ui/Filters/Filter.styles"
import { ruleEnum } from "entities/Course/lib/CourseStatic.js"
import { IFilters } from "shared/hooks/useFiltersForm"
import { OptionType } from "shared/ui/Autocomplete/Autocomplete.types"
import { PickedOption } from "../model/useGetOptions"
import { statusOptions } from "entities/Assignment/static"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { Controller, useFormContext } from "react-hook-form"
import { Variant } from "shared/ui/styles.const"

interface IProps {
  handleFilters: (x: { target: { value: string | number; name: string } }) => void
  filters: IFilters
  teams: PickedOption[] | null
  scores: PickedOption[]
  criteria: OptionType
  disable: boolean
  setIsTriggerFilter: (x: boolean) => void
}

export const AssignmentFilters = (props: IProps) => {
  const { filters, handleFilters, teams, scores, criteria, disable, setIsTriggerFilter } = props
  const tasks = useAppSelector(state => state.assignment.filteredTasks)

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const handleChange = (e: any, field: any) => {
    setIsTriggerFilter(true)
    if (field.name === "email") {
      const trimmedValue = e.target.value.replace(/\s/g, "")
      const lowerCaseValue = trimmedValue.toLowerCase()
      field.onChange(lowerCaseValue)
      return
    }
    field.onChange(e.target.value)
  }

  return (
    <>
      <FormWrapper>
        <Controller
          name={"full_name"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              key={filters.course}
              variant={Variant.outlined}
              onChange={e => handleChange(e, field)}
              disabled={disable}
              label={"Фамилия"}
            />
          )}
        />
        <Controller
          name={"email"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              key={filters.course}
              variant={Variant.outlined}
              onChange={e => handleChange(e, field)}
              error={Boolean(errors[field.name])}
              helperText={errors[field.name]?.message}
              disabled={disable}
              label={"Почта"}
            />
          )}
        />
        <Controller
          name={"date"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              key={filters.course}
              variant={Variant.outlined}
              onChange={e => handleChange(e, field)}
              disabled={disable}
              error={Boolean(errors[field.name])}
              helperText={errors[field.name]?.message}
              label={"Дата загрузки задания (дд.мм.гггг)"}
            />
          )}
        />
        <Autocomplete
          id="statistics__field-content_block"
          key={`${filters.course}+${filters.stage}+${filters.module}`}
          name={"content_block"}
          value={filters.content_block}
          filterChange={handleFilters}
          onReset={handleFilters}
          options={tasks}
          label="Название задания"
          disabled={disable}
        />
        <Dropdown
          id={"statistics__field-value-status"}
          name={"status"}
          value={filters.status}
          onChange={handleFilters}
          disabled={disable}
          options={statusOptions}
          label={"Статус проверки"}
        />
        <Controller
          name={"user_id"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              key={filters.course}
              variant={Variant.outlined}
              onChange={e => handleChange(e, field)}
              disabled={disable}
              label={"ID ученика"}
            />
          )}
        />
        {/* {teams && (
          <Autocomplete
            label="Команда"
            name={"team"}
            value={filters.team}
            filterChange={handleFilters}
            onReset={handleFilters}
            // @ts-ignore
            options={teams}
            disabled={disable}
            width={224}
          />
        )} */}
      </FormWrapper>
      <FlexRow>
        <Dropdown
          id={"statistics__field-value-criterion"}
          name={"criterion"}
          value={filters.criterion}
          onChange={handleFilters}
          disabled={disable}
          options={criteria}
          label={"Критерий"}
        />
        <Dropdown
          id={"statistics__field-value-rule"}
          name={"rule"}
          value={filters.rule}
          onChange={handleFilters}
          disabled={disable || !filters.criterion}
          options={ruleEnum}
          label={"Правило"}
        />
        <Autocomplete
          id="statistics__field-mark_value"
          key={filters.course}
          label="Значение"
          name={"mark_value"}
          value={filters.mark_value}
          filterChange={handleFilters}
          onReset={handleFilters}
          // @ts-ignore
          options={scores}
          disabled={disable || !filters.criterion || !filters.rule}
        />
      </FlexRow>
    </>
  )
}
