import * as Mui from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import VisibilitySensor from "react-visibility-sensor"
import { ChangeEvent, useEffect, useRef } from "react"
import { HeaderCell, ParticipantTableContainer, SortIcons } from "./styles/PaticipantsTable.styles"
import { ScrollButtons, TableLoader } from "shared/assets"
import { ParticipantAccess } from "features/Organization/ParticipantAccess"
import { ParticipantRow } from "./ParticipantRow"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { IParticipant } from "entities/Participant/model/participant.types"
import { CategoriesType } from "entities/Statistic/lib/teamStatistic.types"
import { Checkbox, FormControlLabel } from "@mui/material"
import { resetParticipantCompleted, setParticipantCompleted } from "../../Participant/api/participant"
import { courseKeyDefault, CourseKeyType } from "../../../shared/store/course/CourseSlice"

interface IProps {
  headers: { label: string; value: string }[]
  participants: IParticipant[]
  setSortBy: (x: { type: string; direction: number }) => void
  isLoading: boolean
  completedVisible?: boolean
  loadMore: () => void
  hasNextPage: boolean
  isAccessButtons?: boolean
  categories?: CategoriesType
  status?: string
  setParticipants?: (x: IParticipant) => void
  setTotal?: (x: number) => void
  triggerReloading?: (x: boolean) => void
  courseKey: CourseKeyType
}

const ParticipantsTable = ({
  headers,
  participants,
  setSortBy,
  isLoading,
  loadMore,
  hasNextPage,
  isAccessButtons,
  categories,
  status,
  setParticipants,
  setTotal,
  triggerReloading,
  completedVisible,
  courseKey = courseKeyDefault,
}: IProps) => {
  const course = useAppSelector(state => state.course[courseKey])
  // @ts-ignore
  const infoFields = [...course?.info_fields].sort((a, b) => (a.index > b.index ? 1 : -1))
  const tableContainerRef = useRef(null)

  const handleScrolledToBottom = (isBottomVisible: boolean) => {
    if (isBottomVisible && hasNextPage && !isLoading) {
      loadMore()
    }
  }

  const handleChangeParticipantCompleted = (
    e: ChangeEvent<HTMLInputElement>,
    p: Record<string, string | number | boolean>
  ) => {
    let value = e.target.checked
    if (value) {
      setParticipantCompleted({ progress: p.progress, score: course?.max_score, completed: value }).then(res => {
        participants[participants.indexOf(p)]["is_completed"] = value
        setParticipants([...participants])
      })
    } else {
      resetParticipantCompleted({ progress: p.progress }).then(res => {
        participants[participants.indexOf(p)]["is_completed"] = value
        setParticipants([...participants])
      })
    }
  }

  //ToDo пофиксить типизацию
  const handleChangeParticipantsCompleted = async (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.checked
    Promise.all(
      participants.map(item => {
        if (value) {
          setParticipantCompleted({ progress: item.progress, score: course?.max_score, completed: value })
        } else {
          resetParticipantCompleted({ progress: item.progress })
        }
      })
    ).then(res => {
      participants.forEach((item, index) => {
        participants[index].is_completed = value
      })
      setParticipants([...participants])
    })
  }

  return (
    <ParticipantTableContainer>
      <ScrollButtons tableRef={tableContainerRef} />
      <Mui.TableContainer ref={tableContainerRef} sx={{ backgroundColor: "#FFF", height: "clamp(515px, 50vh, 620px)" }}>
        <Mui.Table stickyHeader sx={{ width: "100%", overflowX: "auto" }} size="small">
          <Mui.TableHead>
            <Mui.TableRow>
              <Mui.TableCell sx={{ minWidth: status === "requested" && "306px" }}></Mui.TableCell>
              {!!completedVisible && (
                <Mui.TableCell sx={{ minWidth: "180px" }} align={"center"}>
                  <FormControlLabel
                    control={<Checkbox onChange={handleChangeParticipantsCompleted} />}
                    label={
                      <Mui.Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
                        Выбрать всё
                      </Mui.Typography>
                    }
                    labelPlacement={"bottom"}
                  />
                </Mui.TableCell>
              )}
              {headers.map(e =>
                e.value ? (
                  <SortableCell label={e.label} value={e.value} setSortBy={setSortBy} key={e.label} />
                ) : (
                  <UnsortableCell value={e.label} key={e.label} />
                )
              )}
              {!!infoFields && infoFields.map(e => <UnsortableCell value={e.title} key={e.label} />)}
            </Mui.TableRow>
          </Mui.TableHead>
          <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
            <Mui.TableBody>
              {participants &&
                participants.map(participant => (
                  <Mui.TableRow
                    key={participant.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, height: 104 }}
                  >
                    <Mui.TableCell>
                      <ParticipantAccess
                        participant={participant}
                        isAccessButtons={isAccessButtons}
                        categories={categories}
                        setParticipants={setParticipants}
                        setTotal={setTotal}
                        triggerReloading={triggerReloading}
                      />
                    </Mui.TableCell>
                    {!!completedVisible && (
                      <Mui.TableCell align={"center"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={e => handleChangeParticipantCompleted(e, participant)}
                              checked={!!participant?.is_completed}
                            />
                          }
                          label={
                            <Mui.Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
                              {!!participant?.is_completed ? "Посетил" : "Не посетил"}
                            </Mui.Typography>
                          }
                          labelPlacement={"bottom"}
                        />
                      </Mui.TableCell>
                    )}
                    <ParticipantRow participant={participant} infoFields={infoFields} />
                  </Mui.TableRow>
                ))}
              {!participants.length && !isLoading && (
                <Mui.TableRow>
                  <Mui.TableCell
                    colSpan={headers.length + infoFields.length}
                    align="center"
                    sx={{ border: 0, height: 104 }}
                  >
                    Нет результатов, соответствующих фильтру. Проверьте правильность введённых данных.
                  </Mui.TableCell>
                </Mui.TableRow>
              )}
            </Mui.TableBody>
          </VisibilitySensor>
        </Mui.Table>
        <TableLoader isLoading={isLoading}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
      </Mui.TableContainer>
    </ParticipantTableContainer>
  )
}

const SortableCell = ({
  label,
  value,
  setSortBy,
}: {
  label: string
  value: string | number
  setSortBy: (x: { type: string; direction: number }) => void
}) => (
  <Mui.TableCell align="center">
    <HeaderCell>
      <Mui.Typography variant={"caption"} fontWeight={"small"}>
        {label}
      </Mui.Typography>
      <SortIcons>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: -1 })}
        >
          <ArrowDropDownIcon fontSize="small" />
        </Mui.IconButton>
        <Mui.IconButton
          size="medium"
          sx={{ color: "#313439" }}
          onClick={() => setSortBy({ type: value, direction: 1 })}
        >
          <ArrowDropUpIcon fontSize="small" />
        </Mui.IconButton>
      </SortIcons>
    </HeaderCell>
  </Mui.TableCell>
)

const UnsortableCell = ({ value, isLeft }: any) => (
  <Mui.TableCell align={isLeft ? "left" : "center"} sx={{ paddingLeft: isLeft && 20 }}>
    <Mui.Typography variant={"caption"} fontWeight={"small"}>
      {value}
    </Mui.Typography>
  </Mui.TableCell>
)

export default ParticipantsTable
