import React from "react"
import * as Styled from "./Landing.styles"
import { Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { OrganizationCardForLanding } from "entities/Organization"
import { organizationItems } from "./mock"

export const OurOrganizations = () => {
  const theme = useTheme()

  return (
    <Styled.OurOrganizationMainDiv>
      <Styled.StyledTitle
        sx={{
          color: "#313439",
          marginBottom: theme.spacing(16),
          [theme.breakpoints.down("sm")]: { marginBottom: theme.spacing(6) },
        }}
        variant={"h2"}
      >
        Нам доверяют
      </Styled.StyledTitle>
      <Styled.OurOrganizationsItemsDiv>
        {organizationItems.map(e => (
          <OrganizationCardForLanding {...e} />
        ))}
      </Styled.OurOrganizationsItemsDiv>
    </Styled.OurOrganizationMainDiv>
  )
}
