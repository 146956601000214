import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { Tab, Tabs } from "@mui/material"

import { Header, TrajectoryConstructor, MyCourses, Teams, UserWebinars } from "widgets"
import Scores from "./Scores"
import { useTab } from "shared/hooks/useTab"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useTheme } from "../../shared/context"
import { fetchProfile, trajectoryActions } from "shared/store"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { PageContainer } from "shared/ui/PageContainer"

const Statistics = props => {
  const status = useSelector(state => state.user.user?.status) || null
  const [isStaff, setIsStaff] = useState(null)
  const { tab, changeTab } = useTab("statistic_tab")
  const dispatch = useDispatch()
  const theme = useTheme()
  const pathname = props.location.pathname

  useEffect(() => {
    dispatch(fetchProfile())
    axios.get("/content/actions/participants/is_staff/").then(res => {
      setIsStaff(res.data.is_staff)
    })
    if (pathname.includes("courses")) {
      changeTab("", 0)
    }
    if (pathname.includes("webinars")) {
      changeTab("", 1)
    }
    if (pathname.includes("teams")) {
      changeTab("", 3)
    }
  }, [])

  useEffect(() => {
    if (tab === 4) {
      dispatch(trajectoryActions.setInitState())
    }
  }, [tab])

  if (!status) return null

  return (
    <PageContainer isFooter={true}>
      <Header variant={"shadow"} />
      <div style={{ height: 75 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "left",
          width: "100%",
          marginBottom: theme.spacing(8),
          marginTop: 48,
        }}
      >
        <Tabs value={tab} onChange={changeTab} aria-label="tabs">
          <Tab label="Мои курсы" value={0} />
          <Tab label="Мои встречи" value={1} />
          {isStaff && <Tab label="Статистика" value={2} />}
          <Tab label="Команды" value={3} />
          {(status == "admin" || status == "moderator" || isStaff) && <Tab label="Конструктор траекторий" value={4} />}
        </Tabs>
        <hr style={{ marginTop: -1, width: "100%" }} />
      </div>
      {tab === 0 && <MyCourses />}
      {tab === 1 && <UserWebinars />}
      {tab === 2 && <Scores />}
      {tab === 3 && <Teams props={props} />}
      {tab === 4 && <TrajectoryConstructor />}
    </PageContainer>
  )
}

export default withRouter(Statistics)
