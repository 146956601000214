export const defaultTemplate = {
  template_props: {
    categories: "",
    specialization: "",
    telegram: "",
    main_screen: {
      H1: { color: "", text: "" },
      Subtitle: { color: "", text: "" },
      Image: { url: "" },
      backgroundColor: "",
    },
    description_block: { visibility: true, title: "О проекте", text: "" },
    calendar_block: { visibility: true, title: "Календарь" },
    partners_block: { visibility: true, title: "Партнеры" },
    team_of_project_block: { visibility: true, title: "Команада проекта" },
    review_block: { visibility: true, title: "Отзывы" },
    footer_block: {
      visibility: true,
      text: "",
      image: "",
    },
    location_badge: "г. Москва",
    registrationButtonText: "ПРИСОЕДИНИТЬСЯ",
    trajectoryButtonText: "ПЕРЕЙТИ В ТРАЕКТОРИЮ",
  },
}
