import { useRef } from "react"

const useDebounce = <T extends (...args: any[]) => any>(func: T, delay: number) => {
  const debouncedFunc = useRef(debounce(func, delay))

  function debounce(func: T, delay: number) {
    let timeout: ReturnType<typeof setTimeout>
    return function (this: any, ...args: any) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), delay)
    }
  }

  return debouncedFunc.current
}

export default useDebounce
