import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useUserState } from "shared/context"
import { Header } from "widgets"
import { ModalSmallTemplate } from "shared/ui/Modal/ModalSmallTemplate"

const TeamLinkInvitation = () => {
  const { isAuthenticated: auth, user, login, registration } = useUserState()
  const [isOpen, setIsOpen] = useState(true)
  const [status, setStatus] = useState(null)
  const [team, setTeam] = useState(null)
  const [isLoading, setLoading] = useState(null)
  const [slug, setSlug] = useState(null)
  const [buttonLabel, setButtonLabel] = useState("ОК")

  useEffect(() => {
    if (auth) {
      getUserTeam()
    } else if (!auth) {
      setButtonLabel("ВХОД")
      setStatus(600)
    }
  }, [user])

  const getUserTeam = async () => {
    setLoading(true)
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    const params = window.location?.href?.split("?")[1]?.split("&")
    const team = params[0]?.split("=")[1]
    const hash = params[1]?.split("=")[1]
    try {
      await axios.post(`/content/actions/teams/invitation_link_reply/?team=${team}&hash=${hash}`).then(res => {
        setStatus(res.status)
        setTeam(res.data)
      })
    } catch (err) {
      const status = err.response.status
      if (status === 404) {
        setSlug(err.response.data.slug)
      }
      if (status === 406) {
        setSlug(err.response.data.slug)
        setButtonLabel("СТРАНИЦА КУРСА")
      }
      setStatus(status)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    if (status === 404 || status === 406) return (window.location.href = `/event/${slug}`)
    if (status === 409 || status === 202) return (window.location.href = "/statistics/teams/user-teams")
    window.location.href = "/statistics/courses"
  }

  const handleClick = () => {
    auth ? handleClose() : login.setOpen(true)
  }

  const getText = status => {
    switch (status) {
      case 202:
        return `Вы вступили в команду "${team.team_title}" на курсе "${team.course_title}". Перед прохождением курса дождитесь, пока Администратор команды назначит вам
            роль.`
      case 403:
        return "Для вступления в команду, авторизуйтесь на платформе."
      case 404:
        return "Перед вступлением в команду нужно зарегистрироваться на курсе."
      case 406:
        return "Чтобы принять приглашение в команду, вы должны быть участником курса. Пожалуйста, подайте заявку на участие через страницу курса и дождитесь её одобрения Администратором курса. Затем перейдите по ссылке снова."
      case 409:
        return "Вы уже состоите в этой команде."
      case 416:
        return 'Вы достигли предела по участию в командах с категорией "Без роли" на курсе. Покиньте одну из ваших команд с категорией "Без роли".'
      case 423:
        return "У вас нет доступа к курсу."
      case 426:
        return "Вы ещё не являетесь участником курса, подождите пока Администратор курса рассмотрит вашу заявку."
      case 600:
        return "Сейчас вы не можете принять приглашение в команду. Пожалуйста, войдите в аккаунт или зарегистрируйтесь на платформе."
      default:
        return "Пожалуйста, зарегистрируйтесь или войдите в аккаунт."
    }
  }

  return (
    <>
      <Header variant={"shadow"} />
      <ModalSmallTemplate
        title={"Приглашение в команду"}
        text={getText(status)}
        isLoading={isLoading}
        isAbortButton={!auth}
        isOpen={isOpen}
        onClick={handleClick}
        onAltClick={() => !auth && registration.setOpen(true)}
        onClose={handleClose}
        label={buttonLabel}
        abortLabel={!auth && "РЕГИСТРАЦИЯ"}
      />
    </>
  )
}

export default withRouter(TeamLinkInvitation)
