import { useEffect, useState } from "react"
import { Button, Typography } from "@mui/material"
import useStyles from "./styles"
import { AudioRecorder } from "react-audio-voice-recorder"

export const Attach = ({ question, value, handleChange, result, ...props }) => {
  const classes = useStyles()
  const [filename, setFilename] = useState(null)
  const [audioUrl, setAudioUrl] = useState(null)

  useEffect(() => {
    setFilename("")
    setAudioUrl(null)
  }, [props.id])

  function onCompleteRecord(blob) {
    const file = new File([blob], "recording.webm", { type: "audio/webm" })
    setAudioUrl(URL.createObjectURL(file))
    let container = new DataTransfer()
    container.items.add(file)
    const input = document.createElement("input")
    input.type = "file"
    input.files = container.files
    props.fileInput.current[props.index] = input
    setFilename(file.name)
  }

  return (
    <div style={{ display: "flex", alignItems: "center", outline: "gray", gap: 12 }}>
      {!!JSON.parse(question.props).input_type && JSON.parse(question.props).input_type === "audio" && (
        <>
          <AudioRecorder
            onRecordingComplete={blob => onCompleteRecord(blob)}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            onNotAllowedOrFound={err => console.table(err)}
            downloadFileExtension="webm"
            mediaRecorderOptions={{
              audioBitsPerSecond: 128000,
            }}
          />
          {!!audioUrl && (
            <audio controls>
              <source src={audioUrl} />
            </audio>
          )}
        </>
      )}
      {JSON.parse(question.props)?.input_type !== "audio" && (
        <>
          <Button component="label" className={classes.checkButtonWt}>
            Выбрать файл
            <input
              id={props.id}
              type="file"
              hidden
              onChange={e => {
                setFilename(e.target.files[0]?.name)
                props.fileInput.current[props.index] = e.target
              }}
            />
          </Button>
          <Typography className={classes.textFileName}>{filename}</Typography>
        </>
      )}
    </div>
  )
}
