import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, TextField } from "../../shared/ui"
import { createAnswer } from "../../entities/AdaptiveTest"
import { RequestMessage } from "../../shared/assets/RequestMessage/RequestMessage"

const questionPreset = {
  content_type: "missing_word",
  correct_answer: JSON.stringify(["", "", "", "", "", "", "", "", "", ""]),
  difficulty_level: 1,
  file: null,
  max_score: 10,
  props: { options: [[], [], [], [], [], [], [], [], [], []] },
  question: "Новый вопрос",
  type: 1,
}

export const CreateQuestion = ({ reload }: { reload: () => void }) => {
  const [error, setError] = useState(false)
  const [statusText, setStatusText] = useState("")

  function handleClick() {
    createAnswer(questionPreset).then(res => {
      reload()
      setStatusText("Вопрос добавлен")
      setError(false)
    })
  }

  return (
    <CreateQuestionMainDiv>
      <Button variant={"contained"} color={"primary"} onClick={handleClick}>
        Создать вопрос
      </Button>
      {statusText.length > 0 && <RequestMessage error={error} text={statusText} />}
    </CreateQuestionMainDiv>
  )
}

const CreateQuestionMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(5),
}))
