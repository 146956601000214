import { AssignmentStatus } from "../model/assignment.types"
import { removeObjectProperty } from "shared/util/functions"

export const statusOptions = {
  "": "",
  "Не проверено": AssignmentStatus.submitted,
  Принято: AssignmentStatus.accepted,
  Возврат: AssignmentStatus.rejected,
}

export const tableStatusOptions = removeObjectProperty("", statusOptions)
