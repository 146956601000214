import React, { useEffect, useState } from "react"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { defaultTemplate } from "entities/Course"
import { Autocomplete, Button, TextField } from "shared/ui"

const templatesOptions = [
  { label: "Не выбрано", value: {} },
  { label: "Шаблон 1", value: defaultTemplate },
]

export const CreateCourse = () => {
  const [content, setContent] = useState({ owner: 8 })
  const [helperText, setHelperText] = useState("")
  const [template, setTemplate] = useState({ label: "Шаблон 1", value: defaultTemplate })

  function createCourse() {
    content.template_props = JSON.stringify(template.value.template_props)
    setContent({ ...content, owner: 8 })
    if (content.type === "event" || content.type == "kruzhok") {
      delete content["badge"]
      content.start = "2024-01-01"
      content.finish = "2024-01-01"
      setContent({ ...content })
    } else if (content.type === "webinar" && template.label === "Шаблон 1") {
      let presets = JSON.parse(content.template_props)
      content.start = "2024-01-01T00:00:00Z"
      content.finish = "2024-01-01T00:00:00Z"
      presets.location_block = { text: "", url: "", visibility: true, title: "Как добраться" }
      presets.location_badge = ""
      content.template_props = JSON.stringify(presets)
      setContent({ ...content })
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`/content/courses/`, content)
      .then(res => {
        setHelperText(`Confirmed with ID: ${res.data.id}`)
        setContent({})
        window.scrollTo(0, 0)
      })
      .catch(err => {
        setHelperText("Error")
        window.scrollTo(0, 0)
      })
  }

  useEffect(() => {
    updateContent("event", "type")
  }, [])

  function updateContent(value, field) {
    content[field] = value
    if (field === "type" && value === "webinar" && !!content[field]) {
      content["badge"] = "online"
    }
    setContent({ ...content })
    setHelperText("")
  }

  return (
    <div style={{ width: "60vw", padding: 48, display: "flex", flexDirection: "column", gap: 24 }}>
      <Typography variant="h5" style={{ textAlign: "center", color: "red" }}>
        {helperText}
      </Typography>
      <TextField
        variant="outlined"
        value={content.title}
        label={"Название курса"}
        onChange={e => updateContent(e.target.value, "title")}
        type="email"
        fullWidth
      />
      <TextField
        variant="outlined"
        label={"Уникальный идентификатор (используется в ссылке на страницу курса)"}
        value={content.slug}
        onChange={e => updateContent(e.target.value, "slug")}
        type="email"
        fullWidth
      />
      <Autocomplete
        label={"Выбор шаблона"}
        options={templatesOptions}
        value={template}
        onChange={(e, newValue) => setTemplate(newValue)}
      />
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">Тип курса</FormLabel>
        <RadioGroup row onChange={event => updateContent(event.target.value, "type")}>
          <FormControlLabel value="event" control={<Radio />} label="Событие" />
          <FormControlLabel value="webinar" control={<Radio />} label="Разовое мероприятие" />
          <FormControlLabel value="kruzhok" control={<Radio />} label="Кружок" />
        </RadioGroup>
      </FormControl>
      {content.type === "webinar" && (
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Тип разового мероприятия</FormLabel>
          <RadioGroup row onChange={event => updateContent(event.target.value, "badge")}>
            <FormControlLabel value="online" control={<Radio />} label="online" />
            <FormControlLabel value="offline" control={<Radio />} label="offline" />
            <FormControlLabel value="hybrid" control={<Radio />} label="hybrid" />
          </RadioGroup>
        </FormControl>
      )}
      <Button sx={{ width: "140px" }} onClick={createCourse} variant="contained" color="primary" size="medium">
        Создать курс
      </Button>
    </div>
  )
}
