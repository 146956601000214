import React, { useEffect, useState } from "react"
import * as Styled from "./ui/Carousel.styles"
import Carousel from "react-material-ui-carousel"
import { useTheme } from "../../shared/context"
import { CarouselItem } from "./ui/CarouselItem"
import { ICarouselItem } from "./models/Carousel.types"

interface IProps {
  slides: ICarouselItem[]
  backgroundColor: string | null | undefined
  navButtonColor: string | null | undefined
  activeButtonColor: string | null | undefined
  borderButtonColor: string | null | undefined
}

export const LandingCarousel = ({
  props,
  refs,
  titles,
}: {
  props: IProps
  refs: { current: { [key: number]: React.RefObject<any> } }
  titles: string[]
}) => {
  const theme = useTheme()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleClickNext()
    }, 8000)
    return () => {
      clearInterval(intervalId)
    }
  }, [activeIndex])

  const handleClickPrev = () => {
    setActiveIndex(prevIndex => (prevIndex - 1 + props.slides.length) % props.slides.length)
  }

  const handleClickNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % props.slides.length)
  }

  return (
    <Styled.CarouselMainContainer>
      <Styled.PrevCarouselItem
        sx={{
          backgroundColor: props.slides[(activeIndex - 1 + props.slides.length) % props.slides.length].backgroundColor,
        }}
        onClick={handleClickPrev}
      />
      <Carousel
        animation={"slide"}
        onChange={now => {
          //@ts-ignore
          setActiveIndex(now)
        }}
        index={activeIndex}
        navButtonsAlwaysInvisible={true}
        autoPlay={false}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: theme.spacing(6),
          background: props.backgroundColor || "#FFF",
        }}
        navButtonsProps={{
          style: {
            color: props.navButtonColor || "#FA5D55",
            background: "transparent",
            border: `1px solid ${props.borderButtonColor || "#313439"}`,
            height: 12,
            width: 12,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            margin: 8,
            padding: 1,
            color: "transparent",
            backgroundColor: "transparent",
            border: `1px solid ${props.borderButtonColor || "#313439"}`,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: props.activeButtonColor || "#FA5D55",
          },
        }}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            zIndex: 10,
            textAlign: "center",
          },
        }}
      >
        {props.slides.map((item, i) => (
          <CarouselItem titles={titles} refs={refs} key={i} props={activeIndex === i && item} />
        ))}
      </Carousel>
      <Styled.NextCarouselItem
        onClick={handleClickNext}
        sx={{ backgroundColor: props.slides[(activeIndex + 1) % props.slides.length].backgroundColor }}
      />
    </Styled.CarouselMainContainer>
  )
}
