import React from "react"
//@ts-ignore
import { withRouter } from "react-router-dom"
import { styled } from "@mui/material"
import { Organizations } from "../../widgets/OrganizationsProfile/Organizations"
import { Footer, Header } from "../../widgets"

const OrganizationsControl = () => {
  return (
    <StyledMainContainer>
      <Header variant={"shadow"} />
      <ContentWrapper>
        <Organizations />
      </ContentWrapper>
      <Footer />
    </StyledMainContainer>
  )
}

export default withRouter(OrganizationsControl)

const StyledMainContainer = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "space-between",
  minHeight: "100vh"
}))

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  padding: `${theme.spacing(31)} ${theme.spacing(31)}`,
}))
