import React from "react"
import { TableCell as MuiTableCell, Typography } from "@mui/material"
import { MemberWrapper } from "./Team.style.ts"
import { formMembersRow } from "entities/Statistic/lib/teamStatistic"
import { TableCell } from "shared/ui/TableTemplate/TableRow"

export const TeamRow = ({ team, headers, isShowMembers }) => {
  const membersRow = formMembersRow(team.membersRow)
  const getProgress = moduleId => {
    //БЭК: получение прогресса такое, потому что с бэка приходят иногда объекты, а иногда массивы
    if (!team?.modules) return
    if (!Array.isArray(team?.modules)) return team?.modules[module]
    let result = team?.modules.find(progress => progress.module_info.id === moduleId)
    return result
  }

  return (
    <>
      <TableCell value={team.id} />
      <TableCell value={team.title} />
      <TableCell value={team.full_name} />
      <TableCell value={team.email} />
      {isShowMembers && membersRow.map(member => <MemberCell member={member} />)}
      <TableCell value={team.score} />
      {headers &&
        headers.map(header => (
          <MuiTableCell
            align="center"
            key={header.id}
            style={{
              backgroundColor: getProgress(header.id)?.completed ? "#81FF81" : "#FF8181",
            }}
          >
            <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>{`${
              getProgress(header.id)?.score || 0
            }`}</Typography>
          </MuiTableCell>
        ))}
    </>
  )
}

const MemberCell = ({ member }) => {
  return (
    <MuiTableCell align="center">
      <MemberWrapper>
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px", maxWidth: "150px" }}>
          {member.name || "-"}
        </Typography>
        {member.name !== " " && <br />}
        <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: "14px", maxWidth: "150px" }}>
          {member.email}
        </Typography>
      </MemberWrapper>
    </MuiTableCell>
  )
}
