import React from "react"
import styled from "@emotion/styled"

export type DateProps = {
  date?: string
}
export const DateLabel = ({ date }: DateProps) => {
  return (
    <Label>
      {date?.slice(0, 6)}
      <SmallLabel>{date?.slice(-4)}</SmallLabel>
    </Label>
  )
}

const Label = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 27.9px;
`
const SmallLabel = styled.span`
  font-size: 14px;
  line-height: 21.7px;
`