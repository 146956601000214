import * as Styled from "./TimerStartModal.styles"
import { ModalTimer } from "entities/Test/ui/ModalTimer/ModalTimer"
import { WarningIcon } from "images/icons/Warning"
import { Button } from "shared/ui"
import { parseTime } from "entities/Test/lib/timer"
import { Variant } from "shared/ui/styles.const"

interface IProps {
  isOpen: boolean
  onClose: () => void
  attempts: { value: number; timer: string }
}

const TimerInfoModal = ({ isOpen, onClose, attempts }: IProps) => {
  return (
    <ModalTimer isOpen={isOpen} onClose={onClose}>
      <Styled.Title variant="h2">Тест с ограниченным временем выполнения</Styled.Title>
      <Styled.Container>
        <Styled.IconWrapper>
          <WarningIcon />
        </Styled.IconWrapper>
        <Styled.Wrapper>
          <Styled.Typography>Прохождение теста ограничено по времени и количеству попыток.</Styled.Typography>
          <br />
          <Styled.Typography>
            Чтобы завершить выполнение, отправьте результаты с помощью кнопки «Отправить».{" "}
            <Styled.Strong>Результаты не сохранятся</Styled.Strong>, если вы не отправите результат при выходе из теста.
          </Styled.Typography>
          <br />
          <Styled.Typography>
            Если в процессе выполнения у вас возникли <Styled.Strong>технические неполадки</Styled.Strong>, вы можете
            продолжить выполнение теста с другой вкладки или устройства в рамках отведённого времени.
          </Styled.Typography>
          <br />
          <Styled.Typography>
            Оставшееся время до завершения попытки отображено на таймере. <Styled.Strong>Таймер</Styled.Strong> на
            период технический неполадок не останавливается, время не добавляется.
          </Styled.Typography>
          <LeftTime attempts={attempts} />
        </Styled.Wrapper>
        <Buttons onClose={onClose} />
      </Styled.Container>
    </ModalTimer>
  )
}

const Buttons = ({ onClose }: { onClose: () => void }) => {
  return (
    <Styled.ButtonWrapper>
      <Button onClick={onClose} variant={Variant.outlined} width={154}>
        Закрыть
      </Button>
    </Styled.ButtonWrapper>
  )
}

const LeftTime = ({ attempts }: { attempts: { value: number; timer: string } }) => {
  return (
    <Styled.LeftTimeWrapper>
      <Styled.LeftTimeTitle>Время выполнения:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>{parseTime(attempts.timer)}</Styled.LeftTimeText>
      <Styled.LeftTimeTitle>Попытка:</Styled.LeftTimeTitle>
      <Styled.LeftTimeText>
        {attempts.value} из {attempts.value}
      </Styled.LeftTimeText>
    </Styled.LeftTimeWrapper>
  )
}

export { TimerInfoModal }
