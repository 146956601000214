import { IExtendFilters } from "shared/hooks/useExtendFiltersForm"
import { useEffect, useState, useMemo } from "react"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import { ModuleType } from "entities/Module/api/module.types"
import { useAppSelector } from "shared/hooks/useAppSelector"
import {
  getHelper,
  getMinMaxScore,
  getScoreOptions,
  getUserScore,
  getTitle,
  getScoreColor,
  findModule,
} from "../lib/progressEditor"

const useProgressEditor = (filters: IExtendFilters, progress: IParticipantProgress) => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState({ success: "", fail: "" })
  const [score, setScore] = useState<string>("")
  const storedModules = useAppSelector(state => state.assignment.modules) as ModuleType[]
  const currentModule = findModule(filters, storedModules)
  const minMaxScore = getMinMaxScore(currentModule)
  const scoreColor = getScoreColor(filters, progress, currentModule)
  const scoreOptions = useMemo(() => getScoreOptions(filters, currentModule), [currentModule?.max_score])
  const userScore = useMemo(() => getUserScore(filters, progress), [filters, progress])
  const helperText = getHelper(progress, filters, userScore, currentModule)
  const displayedScore = score !== "" ? { score } : {}

  useEffect(() => {
    setMessage({ success: "", fail: "" })
    setScore("")
  }, [filters])

  const onChangeScore = (e: { target: { value: string | number } }) => {
    setScore(String(e.target.value))
  }

  return {
    currentModule,
    isLoading,
    message,
    score,
    displayedScore,
    minMaxScore,
    scoreColor,
    helperText,
    scoreOptions,
    onChangeScore,
    getTitle,
    userScore,
    setMessage,
    setScore,
    setIsLoading,
  }
}

export default useProgressEditor
