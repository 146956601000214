import { useEffect, useState } from "react"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { getOrganizationEventStatistics } from "entities/Organization/api"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { formActivityChart, formGeneralInfo } from "entities/Organization/lib/statistics"
import { generalInfoLegendLabels } from "entities/Organization/static/statistics"
import { useAppDispatch } from "../../../shared/hooks/useAppDispatch"
import { organizationActions } from "../../../shared/store"

const globalWindow = window

export const usePersonalStatistics = () => {
  const windowTab = 1
  const type = "personal" as StatisticsVariants
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const emailProp = useAppSelector(state => state.organization.email)
  const isEmpty = useAppSelector(state => state.organization.isEmpty)
  const { statistics, setGeneral, setChart, setTableData, resetStatistics } = useStatistics()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [statusBadge, setStatusBadge] = useState(2)
  const [submitTrigger, setSubmitTrigger] = useState(false)
  const [email, setEmail] = useState(emailProp || "")
  const currentStatistics = statistics[type]
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentStatistics.data.isLoaded) {
      setIsLoaded(true)
    } else if (emailProp) {
      globalWindow.scrollTo(0, 0)
      onSubmit()
    }
  }, [emailProp])

  const onSubmit = async () => {
    try {
      resetStatistics(type)
      dispatch(organizationActions.setEmail(email))
      await fetchStatusBadge()
      await fetchChart()
      await fetchTable()
    } catch {
      setIsLoaded(false)
      dispatch(organizationActions.setIsEmpty(true))
    } finally {
      setIsLoadingTable(false)
      setSubmitTrigger(false)
    }
  }

  const fetchStatusBadge = async () => {
    if (!organization?.id) return
    try {
      getOrganizationEventStatistics(organization?.id, {
        type: "personal_general",
        email: email,
      })
        .then(res => {
          setStatusBadge(res.status_badge)
          dispatch(organizationActions.setIsEmpty(statusBadge !== 2))
        })
        .catch(() => {
          dispatch(organizationActions.setIsEmpty(true))
        })
    } catch {
      dispatch(organizationActions.setIsEmpty(true))
    }
  }


  const fetchChart = async () => {
    if (!organization?.id) return
    try {
      setIsLoadingChart(true)
      setSubmitTrigger(true)
      const payloadDiagram = {
        type: `${type}_general`,
        email: email,
      }
      const dataGeneral = await getOrganizationEventStatistics(organization?.id, payloadDiagram)
      dispatch(organizationActions.setIsEmpty(dataGeneral.organization === 0))
      const payloadLegend = {
        type: `${type}_detailed`,
        email: email,
      }
      const dataLegend = await getOrganizationEventStatistics(organization?.id, payloadLegend)
      const formedGeneral = formGeneralInfo(dataGeneral)
      const formedActivity = formActivityChart(dataLegend)
      // @ts-ignore
      setChart(type, {
        charts: { general: formedGeneral, activity: formedActivity },
        chartLabels: [generalInfoLegendLabels.activity],
      })
      setGeneral(type, {
        submitter: {
          full_name: dataGeneral.full_name,
          email: dataGeneral.email,
          status_badge: dataGeneral.status_badge,
        },
        info: formedGeneral[generalInfoLegendLabels.activity as any] as any,
      })
      setIsLoaded(true)
    } catch {
      dispatch(organizationActions.setIsEmpty(true))
    } finally {
      setIsLoadingChart(false)
    }
  }

  const fetchTable = async () => {
    if (!organization?.id) return
    try {
      setIsLoadingTable(true)
      const payload = {
        type: `${type}`,
        email: email,
      }
      const dataTable = await getOrganizationEventStatistics(organization?.id, payload)
      setTableData(type, {
        count: dataTable.length,
        next: false,
        results: dataTable,
        isLoaded: true,
      })
    } catch {
      dispatch(organizationActions.setIsEmpty(true))
    } finally {
      setIsLoadingTable(false)
    }
  }

  return {
    isLoaded,
    isLoadingChart,
    isLoadingTable,
    isEmpty,
    windowTab,
    onSubmit,
    email,
    setEmail,
    submitTrigger,
  }
}
