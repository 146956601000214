import {makeStyles} from "@mui/styles";

export default makeStyles(theme => ({

    feedbackContentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 100,
        paddingBottom: 160,
        paddingLeft: 124,
        paddingRight: 124,

        [theme.breakpoints.down("md")] : {
            flexDirection: "column",
            paddingLeft: 100,
            paddingRight: 100,
          },
          [theme.breakpoints.down("sm")] : {
            paddingLeft: 20,
            paddingRight: 20,
          },
    },

    feedbackDescriptionContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 78,
        "& > div": {
            width:200,
            [theme.breakpoints.down("md")] : {
                width: "100%",
            },
        },
        "& > div:first-of-type": {
            width:"auto",
        },
    },

    feedbackFormContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 24,
        width: "50vw",
        "& > button": {
            marginTop:60,
        },
        [theme.breakpoints.between("md", "lg")] : {
            width: "42vw",
        },
        [theme.breakpoints.down("md")] : {
            width: "100%",
            paddingTop:20,
          },
    },

}))
