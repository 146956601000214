import { TableCell, Typography } from "@mui/material"
import { IParticipant } from "entities/Participant/model/participant.types"
import { wordMapper } from "shared/local"
import { useAppSelector } from "shared/hooks/useAppSelector"

const ParticipantRow = ({
  participant,
  infoFields,
}: {
  participant: IParticipant
  infoFields: Record<string, string>[]
}) => {
  const course = useAppSelector(state => state.course.course)
  const info = !!participant.info ? participant.info : []
  const getInfoValue = (field: any) => {
    const participantInfo = info.find((key: any) => String(key.id) === String(field.id))
    return participantInfo?.value
  }

  return (
    <>
      <TransparentCell value={participant?.full_name} />
      <TransparentCell value={participant?.email} />
      <TransparentCell value={participant?.phone} />
      <TransparentCell value={wordMapper(participant?.status)} />
      <TransparentCell value={participant?.category} />
      {course?.type !== "webinar" && <TransparentCell value={participant?.teams?.join(", ")} />}
      <TransparentCell value={participant?.created} />
      <TransparentCell value={participant?.comment || ""} />
      {course?.type !== "webinar" && <TransparentCell value={participant?.stages?.join(", ")} />}
      <TransparentCell value={participant?.is_active ? "Активен" : "Не активен"} />
      {infoFields.map((field: any) => (
        <TransparentCell value={getInfoValue(field)} />
      ))}
    </>
  )
}

const TransparentCell = ({ value }: { value: string }) => (
  <TableCell align="center" sx={{ maxWidth: 200, overflowWrap: "break-word" }}>
    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
      {value || "-"}
    </Typography>
  </TableCell>
)

export { ParticipantRow }
