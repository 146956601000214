import { createSlice } from "@reduxjs/toolkit"
import { fetchProfile } from "./userActions"
import axios from "axios"

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    isAuth: false,
  },
  reducers: {
    isAuthenticated: state => {
      const token = localStorage.getItem("token")
      state.isAuth = token
    },
    signOut: state => {
      localStorage.removeItem("token")
      localStorage.removeItem("token_ref")
      document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"
      state.user = {}
      state.isAuth = false
      axios.defaults.headers.common["Authorization"] = ""
      window.location.reload()
    },
    receiveToken: (state, action) => {
      if (!!action.payload.social) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + action.payload.token.token
        localStorage.setItem("token", action.payload.token.token)
      } else {
        axios.defaults.headers.common["Authorization"] = "Bearer " + action.payload.token.access
        localStorage.setItem("token", action.payload.token.access)
      }
      localStorage.setItem("token_ref", action.payload.token.refresh)
      localStorage.setItem("user", JSON.stringify("Waiting"))
      localStorage.setItem("theme", "default")
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProfile.fulfilled, (state, actions) => {
      state.user = actions.payload
    })
  },
})

export const { reducer, actions } = userSlice
