import { useState } from "react"
import { ModalSmallTemplate } from "shared/ui"
import { teamActions } from "shared/store"
import { useDispatch } from "react-redux"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

export const DeleteTeamModal = ({ isOpen, onClose, team }) => {
  const [isDeletable, setIsDeletable] = useState(true)
  const dispatch = useDispatch()

  function deleteTeam() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .delete(`content/teams/${team.id}/`)
      .then(res => {
        if (res.status === 204) {
          dispatch(teamActions.filterTeams(team.id))
        }
      })
      .catch(e => {
        setIsDeletable(false)
      })
  }

  function handleClose() {
    // TODO из-за этого сброса есть визуальный баг при выходе из модалки
    setIsDeletable(true)
    onClose()
  }

  const getTitle = isDeletable ? "Вы действительно хотите удалить команду?" : "Нельзя удалить команду"
  const getDescription = isDeletable
    ? "Это действие нельзя отменить. Вы хотите продолжить?"
    : "В команде ещё остались участники. Удалите всех участников и попробуйте снова."

  const handleDelete = () => {
    isDeletable ? deleteTeam() : handleClose()
  }

  return (
    <ModalSmallTemplate
      title={getTitle}
      text={getDescription}
      isOpen={isOpen}
      onClick={handleDelete}
      onClose={handleClose}
      isAbortButton={isDeletable}
      isDeleting={isDeletable}
    />
  )
}
