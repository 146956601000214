import React, { useEffect, useRef } from "react"
import useStyle from "./styles"
import VisibilitySensor from "react-visibility-sensor"

const Video = ({ e, handleChangeRef }) => {
  const classes = useStyle()
  const ref = useRef()
  const videoRef = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  function handleVisible(isVisible) {
    if (!!isVisible) videoRef.current.play()
    else videoRef.current.pause()
  }

  return (
    <div ref={ref} className={classes.videoContainer}>
      {!!e.url && (
        <VisibilitySensor onChange={handleVisible} partialVisibility={true}>
          <video width="100%" ref={videoRef} loop={true} controls={true} muted={true}>
            <source src={e.url} type="video/mp4" />
          </video>
        </VisibilitySensor>
      )}
    </div>
  )
}

export default Video
