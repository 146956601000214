import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { Card } from "shared/ui"
import { boxShadow } from "./Course.style.ts"

const Grid = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "0.9fr 1.1fr",
  gap: "24px",
}))

const GridItem = styled(Card)(({ theme }) => ({
  padding: theme.spacing(8),
  boxShadow: boxShadow,
  border: 0,
}))

const AccessWrapper = styled("div")(() => ({
  display: "flex",
  gap: 12,
  alignItems: "end",
}))

const Row = styled("div")(() => ({
  display: "flex",
  gap: "24px",
  alignItems: "center",
  marginBlock: "40px 24px",
}))

const BalanceRow = styled(Row)(() => ({
  "& > *": {
    flex: 1,
  },
}))

const Description = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: "16px",
  fontWeight: "300",
}))

const DescriptionRow = styled("div")(() => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
}))

export { Grid, GridItem, Row, BalanceRow, Description, DescriptionRow, SubTitle, AccessWrapper }
