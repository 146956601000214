import {createSlice} from "@reduxjs/toolkit";
import {acceptInvitation, fetchInvitations, rejectInvitation} from "./invitationActions";


const invitationSlice = createSlice({
    name: "invitationSlice",
    initialState: {
        invitations: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchInvitations.fulfilled, (state, action) => {
            state.invitations = [...action.payload]
        }).addCase(acceptInvitation.fulfilled, (state, action) => {
            state.invitations = state.invitations.filter(e => e.id !== action.payload.id)
        }).addCase(rejectInvitation.fulfilled, (state, action) => {
            state.invitations = state.invitations.filter(e => e.id !== action.payload.id)
        })
    }
})

export const {actions, reducer} = invitationSlice
