import { styled } from "@mui/material/styles"
import { Box, Grid, Typography } from "@mui/material"
//@ts-ignore
import BackgroundCommunities from "images/backgroundCommunities.png"

export const CarouselMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "90vh",
  marginTop: theme.spacing(20),
  gap: theme.spacing(4),
  justifyContent: "space-between",
}))

export const NextCarouselItem = styled("div")(({ theme }) => ({
  height: "80vh",
  width: theme.spacing(6),
  cursor: "pointer",
  borderBottomLeftRadius: "24px",
  borderTopLeftRadius: "24px",
  [theme.breakpoints.down("lg")]: {},
}))

export const PrevCarouselItem = styled("div")(({ theme }) => ({
  height: "80vh",
  width: theme.spacing(6),
  borderBottomRightRadius: "24px",
  borderTopRightRadius: "24px",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {},
}))

export const LandingBackground = styled("div")(({ theme }) => ({
  height: "80vh",
  borderRadius: theme.spacing(6),
}))

export const LandingContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(32),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(8),
  },
}))

export const LandingImage = styled(Box)(({ theme }) => ({
  right: 0,
  top: 0,
  marginRight: "0%",
  marginBottom: "10%",
  "& span": {
    display: "flex !important",
  },
  [theme.breakpoints.up("xl")]: {
    position: "absolute",
    height: "40vw",
  },
  [theme.breakpoints.up("lg")]: {
    position: "absolute",
    height: "40vw",
  },
  [theme.breakpoints.down("lg")]: {
    height: "65%",
    position: "relative",
  },
  [theme.breakpoints.down("sm")]: {
    height: "60%",
  },
  [theme.breakpoints.down("xs")]: {
    height: "40%",
  },
}))

export const SloganContainer = styled("div")(({ theme }) => ({
  width: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
  },
}))

export const DigitalTechnoparkMainDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(31),
  paddingTop: theme.spacing(31),
  display: "flex",
  width: "100vw",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const DigitalTechnoparkDescriptionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(16),
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    gap: theme.spacing(8),
  },
}))

export const ImageTechnopark = styled(Box)(({ theme }) => ({
  width: "55%",
  "& span": {
    display: "flex !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}))

export const InfoTechnoparkDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "50%",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}))

export const InfoTechnoparkItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "48%",
  marginTop: theme.spacing(10),
  [theme.breakpoints.down("lg")]: {},
}))

export const RequestForUsingMainDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(24),
  marginLeft: theme.spacing(31),
  marginRight: theme.spacing(31),
  borderRadius: theme.spacing(6),
  maxWidth: "100vw",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  background: "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(16),
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(8),
    padding: theme.spacing(8),
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(16),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    gap: theme.spacing(6),
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
  },
}))

export const ImageRequestForUsing = styled("img")(({ theme }) => ({
  width: "48%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}))

export const DescriptionForUsingDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
  width: "50%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    gap: theme.spacing(4),
  },
}))

export const WorkWithHardwareMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingTop: theme.spacing(31),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(31),
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(0),
    paddingTop: theme.spacing(14),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(14),
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    gap: theme.spacing(0),
  },
}))

export const HardwareDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(6),
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export const WorkWithHardwareDescriptionDiv = styled("div")(({ theme }) => ({
  width: "60%",
  display: "flex",
  flexDirection: "column",
  slignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(16),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}))

export const GetWorkWithHardwareDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}))

export const UiIotImage = styled("img")(({ theme }) => ({
  width: "30vw",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}))

export const FunctionalOfPlatformMainDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingTop: theme.spacing(14),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(14),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const FunctionalImage = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "auto",
  "& span": {
    display: "flex !important",
  },
}))

export const OurOrganizationMainDiv = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(31),
  marginBottom: theme.spacing(31),
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  backgroundColor: "#E7EBF1",
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(31),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(31),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    marginBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(0),
  },
}))

export const OurOrganizationsItemsDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "column",
  alignItems: "center",
  gap: theme.spacing(6),
}))

export const RequestForOrganizationMainDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(24),
  display: "flex",
  flexDirection: "row",
  alignItems: "space-between",
  justifyContent: "flex-start",
  background: "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)",
  [theme.breakpoints.down("lg")]: {
    visibility: "hidden",
  },
}))

export const RequestsForOrganizationsDescriptionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}))

export const RequestsForOrgsAuthorizationDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(3),
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: theme.spacing(26),
}))

export const ProgramsOfPlatformMainDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(31),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(31),
    paddingBottom: theme.spacing(31),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

export const CommunitesMainDiv = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(31),
  background: `url(${BackgroundCommunities})`,
  width: "100%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "start center",
  height: "auto",
  display: "flex",
  gap: theme.spacing(16),
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(31),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(31),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    gap: theme.spacing(6),
  },
}))

export const MentorDescriptionDiv = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(12),
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  background: "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)",
  borderRadius: theme.spacing(3),
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(4),
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: 0,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    marginBottom: 0,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

export const MiddleTypography = styled(Typography)(({ theme }) => ({
  width: "60%",
  color: "#313439",
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(16),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
}))

export const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "33%",
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
  [theme.breakpoints.down("md")]: {
    width: "30%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(6),
  },
}))

export const StyledText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(4),
  },
}))

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(5),
  },
}))

export const ImageButtonUp = styled("img")(({ theme }) => ({
  opacity: 0.4,
  "&:hover": {
    opacity: 1.0,
  },
}))

export const tooltip = {
  width: 400,
  backgroundColor: "#555",
  color: "#fff",
  borderRadius: 6,
  padding: "7px 10px",
  zIndex: 1,
  opacity: 0,
  transition: "opacity 0.3s",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: 1.2,
  fontStyle: "normal",
  fontFamily: "Roboto",
}
export const tooltipArrow = {
  color: "#555",
}
