import { styled } from "@mui/material/styles"
import { TableCell, TableContainer as MuiTableContainer } from "@mui/material"
// @ts-ignore
import { boxShadow } from "shared/ui"
import { Card, Box } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"

export const TableWrapper = styled("div")(() => ({
  position: "relative",
  width: "100%",
}))

// @ts-ignore
export const TableContainer = styled(MuiTableContainer)(({ theme, isEdit }) => ({
  maxHeight: isEdit ? "auto" : "80vh",
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(3),
  overflowY: isEdit && "hidden",
}))

export const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

export const SettingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: theme.spacing(28),
}))

export const BaseSettingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}))

export const SettingButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  width: theme.spacing(50),
}))

export const DescriptionSettingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "50%",
  gap: theme.spacing(6),
}))

const TeamWrapper = styled(Card)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(8),
  boxShadow: "unset",
  border: "unset",
  backgroundColor: theme.palette.common.white,
}))

// @ts-ignore
export const TeamSection = styled(TeamWrapper)(({ theme, isDisabled }) => ({
  boxShadow: boxShadow,
  backgroundColor: isDisabled ? theme.palette.text.disabled : theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}))

export const TeamHeader = styled("div")(({ theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(8)}`,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
}))

export const TeamContent = styled("div")(({ theme }) => ({
  width: "100%",
  paddingInline: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}))

export const DropDownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  cursor: "pointer",
}))

export const DropUpIcon = styled(ArrowDropUpIcon)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  cursor: "pointer",
}))

export const FlexWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(6),
}))

export const Description = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(6),
  maxWidth: "472px",
}))

export const TeamScoreBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  gap: theme.spacing(2),
  marginBottom: theme.spacing(8),
}))

export const ActionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(6),
}))

export const MemberWrapper = styled("div")(({ theme }) => ({
  maxWidth: "200px",
}))
