import React from "react";

import {
  Grid,
  Typography,
} from "@mui/material";

import { Card } from "shared/ui";
import quotes_open from "../../images/icons/quotes_open.jpg"
import quotes_close from "../../images/icons/quotes_close.jpg"

export const Review = props => {

  var review= props.review

  function getUserInfo(){
    if(review.position) return review.position
    else if(review.user_data.about) return review.user_data.about
    else return ""
  }

  function getFullName(){
    if(review.full_name) return review.full_name
    else if(review.user_data.last_name) return `${review.user_data.last_name} ${review.user_data.first_name}`
    else return ""
  }

  return (
    <Card style={{ marginLeft: 8, marginRight: 8 }}>
      <Grid container>
        <Grid item container xs={2} alignItems="flex-end" style={{ paddingBottom: 24 }}>
          <div style={{ width: 10, height: 72, backgroundColor: "#FA5D55" }}/>
        </Grid>
        <Grid item container xs={8} gap={4} style={{ padding: 48, paddingBottom: 24, flexDirection: "column" }}>
          <Typography variant="h3" fontWeight={700}>{getFullName()}</Typography>
          <Typography variant="body1">{getUserInfo()}</Typography>
        </Grid>
        <Grid item container xs={2}>
        </Grid>
        <Grid item container xs={2} justifyContent="flex-end" alignItems="flex-start">
          <img src={quotes_open} />
        </Grid>
        <Grid item container xs={8} style={{ padding: 48, paddingTop: 0 }}>
          <Typography variant="body1">{review.text}</Typography>
        </Grid>
        <Grid item container xs={2} alignItems="flex-end">
          <img src={quotes_close} style={{ marginBottom: 48 }}/>
        </Grid>
      </Grid>
    </Card>
  )
}
