import { useRef } from "react"
import * as Styled from "./Team.style.ts"
import { Table, TableBody, TableHead, TableRow, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { InvitedMembers } from "entities/Team"
import { ScrollButtons } from "shared/assets"
import { useSelector } from "react-redux"
import { Loader } from "shared/ui"
import { EditRolesTable } from "entities/Team/ui/EditRolesTable"
import { MyTeamsTable } from "entities/Team/ui/MyTeamsTable"

const uniqueStatuses = ["invited", "requested", "rejected"]

const TeamTable = ({ team, progress, modules, isEdit, setErrorMsg }) => {
  const user = useSelector(state => state.user.user)
  const isModerator = user.status.includes("moderator") | user.status.includes("admin")
  const tableField = isEdit ? ["ФИО", "Почта", "Роль/Сменить роль", "Удалить участника"] : ["ФИО", "Почта", "Роль"]
  const theme = useTheme()
  const tableContainerRef = useRef()
  const invitedMembers = !isEdit && team.members.filter(member => uniqueStatuses.includes(member.status))

  if (!isEdit && !progress.length) return <Loader />

  return (
    <Styled.TableWrapper>
      {modules && <ScrollButtons tableRef={tableContainerRef} />}
      <Styled.TableContainer ref={tableContainerRef}>
        <Table stickyHeader sx={{ width: "100%", overflowX: "hidden" }} size="small">
          <TableHead>
            <TableRow sx={{ height: theme.spacing(25) }}>
              {tableField.map(field =>
                field === "Почта" && !isModerator ? null : (
                  <Styled.HeaderCell align={"center"} key={field}>
                    <Typography variant={"caption"} fontWeight={"small"}>
                      {field}
                    </Typography>{" "}
                  </Styled.HeaderCell>
                )
              )}
              <ModulesHeader modules={modules} />
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowX: "auto" }}>
            {progress ? (
              <MyTeamsTable
                progress={progress}
                team={team}
                isEdit={isEdit}
                setErrorMsg={setErrorMsg}
                modules={modules}
              />
            ) : (
              <EditRolesTable team={team} isEdit={isEdit} setErrorMsg={setErrorMsg} />
            )}
          </TableBody>
        </Table>
        <InvitedMembers members={invitedMembers} />
      </Styled.TableContainer>
    </Styled.TableWrapper>
  )
}

const ModulesHeader = ({ modules }) => {
  if (!modules) return

  return modules.map(module => (
    <Styled.HeaderCell align={"center"}>
      <Typography variant={"caption"} fontWeight={"small"}>
        {module}
      </Typography>{" "}
    </Styled.HeaderCell>
  ))
}

export { TeamTable }
