import React, { useState, useEffect } from "react"
import { Button, TextField, Typography } from "@mui/material"

import { axiosInstance, axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { defaultTemplate } from "../../entities/Organization"
import { Autocomplete } from "../../shared/ui"

const options = [
  { label: "Не выбрано", value: {} },
  { label: "Шаблон 1", value: defaultTemplate },
]

export function OrganizationsPannel(props) {
  const [existingOrgs, setExistingOrgs] = useState(null)
  const [organization, setOrganization] = useState({})
  const [template, setTemplate] = useState({ label: "Шаблон 1", value: defaultTemplate })
  const [helperText, setHelperText] = useState("")

  useEffect(() => {
    axios.get("/organizations/").then(res => {
      setExistingOrgs(res.data)
    })
  }, [])

  function setOrgParams(value, field) {
    organization[field] = value
    setOrganization({ ...organization })
    setHelperText("")
  }

  function updateOrganization() {
    axios.get("/organizations/").then(() => {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      for (let index = 0; index < existingOrgs.length; index++) {
        if (existingOrgs[index].id == organization.id) {
          axios.put(`/organizations/${organization.id}/`, organization).then(res => {
            setHelperText("Обновлено")
          })
          return
        }
      }
      organization.template_props = JSON.stringify(template.value)
      axiosInstance.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios.post("/organizations/", organization).then(res => {
        setHelperText("Создано")
      })
    })
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        alignItems: "flex-start",
        marginBottom: "64px",
        justifyContent: "flex-start",
      }}
    >
      {/*existingOrgs && existingOrgs.map(org => (
        Object.entries(org).map(c => (
          <Typography>{c[0] !== "template_props" && `${c[0]}: ${c[1]}`}</Typography>
        ))
      ))*/}
      <Typography variant="h5" style={{ textAlign: "center", color: "red", marginTop: 24 }}>
        {helperText}
      </Typography>
      <TextField
        variant="outlined"
        value={organization.title}
        onChange={e => setOrgParams(e.target.value, "title")}
        helperText="Title"
        type="title"
        fullWidth
        style={{ margin: 12 }}
      />
      <TextField
        variant="outlined"
        value={organization.information}
        onChange={e => setOrgParams(e.target.value, "information")}
        helperText="Information"
        type="information"
        fullWidth
        style={{ margin: 12 }}
      />
      <TextField
        variant="outlined"
        value={organization.slug}
        onChange={e => setOrgParams(e.target.value, "slug")}
        helperText="Slug"
        type="url"
        fullWidth
        style={{ margin: 12 }}
      />
      <Autocomplete
        label={"Выбрать шаблон"}
        style={{ marginTop: 12, marginBottom: 12 }}
        value={template}
        options={options}
        onChange={(e, newValue) => setTemplate(newValue)}
        width={225}
      />
      <Button
        onClick={updateOrganization}
        variant="outlined"
        color="primary"
        size="large"
        style={{ width: 250, height: 50 }}
      >
        Создать организацию
      </Button>
    </div>
  )
}
