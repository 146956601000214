import { styled } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { DateRange } from "features/Organization/ui/EventDateSelector"
import { DateLabel } from "./DateLabel"

interface IProps {
  displayedDate?: DateRange
  isLoading?: boolean
}

  export const DisplayedDate = ({ displayedDate, isLoading }: IProps) => {
  const startDate = displayedDate?.start !== "Invalid Date" ? displayedDate?.start : ""
  const endDate = displayedDate?.finish !== "Invalid Date" ? displayedDate?.finish : ""

  if (!displayedDate) return

  if (isLoading) return <CircularProgress size={26} />

  return (
    <Wrapper>
      <DateLabel date={startDate}/>{" "}
      {startDate && endDate ? (
        <>
          <Hyphen>-</Hyphen> <DateLabel date={endDate} />
        </>
      ) : (
        (endDate && <DateLabel date={endDate} />) || null
      )}
    </Wrapper>
  )
}

const Wrapper = styled("div")(({theme}) => ({
  minWidth: theme.spacing(44),
  height: theme.spacing(7),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}))

const Hyphen = styled("span")(({theme}) => ({
   fontSize: theme.spacing(4),
  fontWeight: "300",
  lineHeight: "24.8px",
}))

export default DisplayedDate
