//@ts-nocheck
import React, { useEffect, useRef, useState } from "react"
import Plot from "react-plotly.js"
import { Button, Typography } from "@mui/material"
import * as Styled from "./Iot.styles"
import { useTheme } from "shared/context"
import { Modal } from "shared/ui"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { ChangeChartParams } from "features/Iot/ChangeChartParams"
import {
  fetchChartCsv,
  fetchChartHistoryData,
  IHistory,
  procTime,
  saveChartCsv,
  settingsBigChart,
  userSettingsIcon,
} from "entities/Iot"
import { downloadIcon } from "entities/Iot"

interface IProps {
  setIsOpenChooseParams: (boolean) => void
  isOpenChooseParam: boolean
}

export const Charts = ({ isOpenChooseParam, setIsOpenChooseParams }: IProps) => {
  const theme = useTheme()
  const startDate = useAppSelector(state => state.iot.chartSettings.startDate)
  const finishDate = useAppSelector(state => state.iot.chartSettings.finishDate)
  const startTime = useAppSelector(state => state.iot.chartSettings.startTime)
  const finishTime = useAppSelector(state => state.iot.chartSettings.finishTime)

  const intervals = useRef<{
    startDate: string | null
    finishDate: string | null
    startTime: string | null
    finishTime: string | null
  }>({
    startDate: null,
    finishDate: null,
    startTime: null,
    finishTime: null,
  })
  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const paramsChecked = useAppSelector(state => state.iot.chartSettings.paramsChecked)
  const config = useAppSelector(state => state.iot.config)
  const [layout_s, set_layout] = useState({ ...settingsBigChart })
  const [data_s, set_data] = useState([])

  useEffect(() => {
    if (!!startDate) {
      intervals.current.startDate = startDate
    }
  }, [startDate])

  useEffect(() => {
    if (!!finishDate) {
      intervals.current.finishDate = finishDate
    }
  }, [finishDate])

  useEffect(() => {
    if (!!startTime) intervals.current.startTime = startTime
  }, [startTime])

  useEffect(() => {
    if (!!finishTime) intervals.current.finishTime = finishTime
  }, [finishTime])

  function downloadData(
    params: Array<{
      pid: number
      label: string
    }>
  ) {
    const fetchData = async () => {
      const newGraphData = []
      Promise.all(
        params.map(async e => {
          await fetchChartHistoryData(
            currentHardware,
            e.pid,
            e.label,
            intervals.current.startDate,
            intervals.current.startTime,
            intervals.current.finishDate,
            intervals.current.finishTime
          ).then(res => {
            let data = res.data
            let x: Array<number> = []
            let y: Array<string> = []
            let text: Array<string> = []
            data.forEach((item: IHistory) => {
              y.push(item._value)
              x.push(item._time)
              text.push(`${procTime(item._time)}<b>Y:</b>${item._value}`)
            })
            let new_data = {
              x: [],
              y: [],
              text: [],
              type: "scatter",
              mode: "lines",
              hoverinfo: "text",
              name: e.label,
            }
            new_data.y = y
            new_data.x = x
            new_data.text = text
            newGraphData.push({ ...new_data })
            set_data([...newGraphData])
          })
        })
      ).finally(() => {
        setIsOpenChooseParams(false)
      })
    }
    if (!!currentHardware) {
      fetchData()
    }
  }

  function downloadCsv() {
    const fetchData = async () => {
      await Promise.all(
        paramsChecked.map(async e => {
          await fetchChartCsv(
            currentHardware,
            e.pid,
            e.label,
            intervals.current.startDate,
            intervals.current.startTime,
            intervals.current.finishDate,
            intervals.current.finishTime
          ).then(res => {
            saveChartCsv(res.data, e, intervals.current.startDate, intervals.current.finishDate)
          })
        })
      )
    }
    fetchData()
  }

  return (
    <Styled.ChartMainDiv>
      <Modal
        isOpen={isOpenChooseParam}
        onClose={() => {
          setIsOpenChooseParams(false)
        }}
        title={"Выбор показаний графика"}
      >
        <ChangeChartParams config={config} downloadData={downloadData} />
      </Modal>
      {data_s.length === 0 && (
        <Styled.SettingsOfChartDiv>
          <Button
            variant={"contained"}
            color={"primary"}
            disabled={!config}
            onClick={() => {
              setIsOpenChooseParams(true)
            }}
          >
            Выбрать показания
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: theme.spacing(6),
              width: "60%",
            }}
          >
            <Typography textAlign={"center"} variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
              Пожалуйста, выберите, какие показания вы хотите увидеть на графике
            </Typography>
          </div>
        </Styled.SettingsOfChartDiv>
      )}
      {data_s.length > 0 && (
        <Styled.PlotStyle>
          <Plot
            layout={layout_s}
            data={data_s}
            style={{ width: "100%", alignSelf: "center" }}
            useResizeHandler={true}
            config={{
              toImageButtonOptions: {
                filename: `${intervals.current.startDate}_${intervals.current.startDate}`,
              },
              displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToAdd: [
                {
                  icon: {
                    svg: [downloadIcon()],
                  },
                  name: "Скачать данные",
                  click: () => {
                    downloadCsv()
                  },
                },
                {
                  icon: {
                    svg: [userSettingsIcon()],
                  },
                  name: "Настройки показателей",
                  click: () => {
                    setIsOpenChooseParams(true)
                  },
                },
              ],
            }}
          />
        </Styled.PlotStyle>
      )}
    </Styled.ChartMainDiv>
  )
}
