import * as Styled from "./ui/DownloadStatisticsTable.styles"
import DownloadIcon from "images/icons/Download"
import { useRef, useState } from "react"
import { useClickOutside } from "shared/hooks/useClickOutside"
import { CircularProgress, Grow } from "@mui/material"
import { useStatistics } from "entities/Organization/model/StatisticsContext"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import {
  requestsDownloadOptions,
  statusesDownloadOptions,
  personalDownloadOptions,
} from "entities/Organization/static/statistics"
interface IProps {
  type: StatisticsVariants
  onDownload: (value: number | "all") => void
  isDownloading: boolean
}
const DownloadStatisticsTable = ({ type, onDownload, isDownloading }: IProps) => {
  const downloadRef = useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = useState(false)
  const { eventFilters } = useStatistics()

  const questionOptions = eventFilters.questions.map(question => ({
    value: question.id,
    label: `Только по вопросу “${question.title}”`,
  }))

  const surveyDownloadOptions = [
    { value: "all", label: "Сводка по выбранным вопросам анкеты" },
    ...questionOptions,
  ]

  const getOptions = () => {
    if (type === "requests") return requestsDownloadOptions
    if (type === "statuses") return statusesDownloadOptions
    if (type === "survey") return surveyDownloadOptions
    if (type === "personal") return personalDownloadOptions
    return []
  }

  useClickOutside(downloadRef, () => {
    if (isActive) setIsActive(false)
  })

  const handleDownload = (value: number | "all") => {
    setIsActive(false)
    onDownload(value)
  }

  if (isDownloading)
    return (
      <Styled.Loader>
        <CircularProgress size={26} />
      </Styled.Loader>
    )
  return (
    <Styled.DownloadWrapper ref={downloadRef}>
      <Styled.DownloadButton onClick={() => setIsActive(prev => !prev)}>
        <span>Скачать таблицу</span>
        <DownloadIcon />
      </Styled.DownloadButton>
      <Grow in={isActive} style={{ transformOrigin: "top right" }}>
        <Styled.Options>
          {getOptions().map(option => (
            <Styled.Option key={option.value} onClick={() => handleDownload(option.value as number | "all")}>
              {option.label}
            </Styled.Option>
          ))}
        </Styled.Options>
      </Grow>
    </Styled.DownloadWrapper>
  )
}

export default DownloadStatisticsTable
