import styled from "@emotion/styled"

export const FilesWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  flex-wrap: wrap;

  & :not(:first-child) {
    margin-right: 8px;
  }
`

export const TasksWrapper = styled.div``

export const Submission = styled.div<{ isInRow?: boolean }>`
  display: ${props => (props.isInRow ? "flex" : "block")};
  line-height: 18px;
  color: #313439;
  flex-wrap: wrap;
  row-gap: 8px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Index = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-right: 4px;
`

export const Question = styled.span<{ isInRow?: boolean }>`
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  margin-right: 8px;
  margin-bottom: ${props => (props.isInRow ? "" : "8px")};
`

export const Asnwer = styled.span`
  font-size: 14px;
  font-weight: 400;
`

export const Indent = styled.div`
  width: 34px;
`

export const FilesTitle = styled.span`
  color: #313439;
  font-size: 16px;
  line-height: 115%;
`

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > a {
    color: #313439;
    font-size: 14px;
    line-height: 125%;
    border-bottom: 1px solid #313439;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }
`

export const Error = styled.div`
  display: inline-block;
  margin-block: 16px 34px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17.5px;
  color: #d82e2e;
`

export const Link = styled.a`
  color: #3483f6;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`
