import { Typography, styled } from "@mui/material"
import { ArrowBack } from "images/icons/ArrowBack"

const BackButton = ({ url }: { url: string }) => {
  const handleClick = () => {
    window.location.href = url
  }

  return (
    <Wrapper onClick={handleClick}>
      <ArrowBack />
      <Typography fontWeight={"small"}>Назад</Typography>
    </Wrapper>
  )
}

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
}))

export { BackButton }
