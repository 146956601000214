// @ts-ignore
import React, { useEffect, useRef, useState } from "react"
import Plot from "react-plotly.js"


import axios from "axios"
import { RequestsAndStatusesChartType } from "./RequestsAndStatusesCharts"
import { RangesType, StatisticsVariants } from "../../model/StatisticsContext.types"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"
import { DateRange } from "../../../../features/Organization/ui/EventDateSelector"
import dayjs from "dayjs"
import { ChartsOptions } from "./ChartsOptions"
import { useClickOutside } from "../../../../shared/hooks"

export type RequestsScatterChartType = {
  changeChartType: (title: RequestsAndStatusesChartType) => void
  rangeType?: RangesType
  type: StatisticsVariants
  statisticsDisplayedDate?: DateRange
}
const RequestsScatterChart = ({
                                changeChartType,
                                rangeType,
                                type,
                                statisticsDisplayedDate,
                              }: RequestsScatterChartType) => {
  const [isActive, setIsActive] = useState(false)
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)
  const [requestScatterChartXData, setRequestScatterChartXData] = useState<string[]>([])
  const [requestScatterChartYData, setRequestScatterChartYData] = useState<number[]>([])
  const [contentExistence, setContentExistence] = useState<boolean>(true)
  const today = dayjs().format("DD.MM.YYYY")
  const refChartsOptions = useRef<HTMLDivElement>(null)
  useClickOutside(refChartsOptions, () => setIsActive(false));

  let startDate =
    statisticsDisplayedDate?.start !== "Invalid Date" && statisticsDisplayedDate?.start !== undefined
      ? statisticsDisplayedDate?.start
      : "01.01.2022"
  let endDate =
    statisticsDisplayedDate?.finish !== "Invalid Date" && statisticsDisplayedDate?.finish !== undefined
      ? statisticsDisplayedDate?.finish
      : today

  useEffect(() => {
    let formattedStartDate
    let formattedFinishDate
    if (
      statisticsDisplayedDate &&
      statisticsDisplayedDate.start !== "Invalid Date" &&
      statisticsDisplayedDate.finish !== "Invalid Date"
    ) {
      formattedStartDate = dayjs(statisticsDisplayedDate.start, "DD.MM.YYYY").format("YYYY-MM-DD")
      formattedFinishDate = dayjs(statisticsDisplayedDate.finish, "DD.MM.YYYY").format("YYYY-MM-DD")
    }
    const config =
      rangeType === "custom" && formattedStartDate && formattedFinishDate
        ? {
          params: {
            course: currentCourse?.id,
            type: "applications_graph",
            date_range: rangeType,
            start: formattedStartDate,
            finish: formattedFinishDate,
          },
        }
        : {
          params: {
            course: currentCourse?.id,
            type: "applications_graph",
            date_range: rangeType,
          },
        }

    axios.get(`organizations/${organization?.id}/statistics/`, config).then(res => {
      if (res.status === 204 && res.statusText === "No Content") {
        setContentExistence(false)
      }
      let obj = res.data
      let xData = Object.keys(obj) as string[]
      let yData = Object.values(obj) as number[]
      setRequestScatterChartXData(xData)
      setRequestScatterChartYData(yData)
    })
  }, [statisticsDisplayedDate])

  const handleIconClick = () => {
    setIsActive(!isActive)
  }

  const showChangeChartIcon = () => {
    return (
      "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"Layer_1\" data-name=\"Layer 1\" viewBox=\"0 0 24 24\">\n" +
      "<path d=\"m0,24h10v-10H0v10Zm2-8h6v6H2v-6ZM24,5.5c0-3.032-2.468-5.5-5.5-5.5s-5.5,2.468-5.5,5.5,2.468,5.5,5.5,5.5,5.5-2.468,5.5-5.5Zm-9,0c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5-3.5-1.57-3.5-3.5ZM5.362,2h-3.362V0h5c1.103,0,2,.897,2,2v5h-2v-3.665c-3.079,1.765-5,5.025-5,8.665H0C0,7.89,2.044,4.189,5.362,2Zm13.276,20h3.362v2h-5c-1.103,0-2-.897-2-2v-5h2v3.666c3.079-1.765,5-5.025,5-8.666h2c0,4.109-2.043,7.812-5.362,10Z\" fill=\"#313439\"/>\n" +
      "</svg>"
    )
  }

  return (
    <>
      {contentExistence ? (
        <>
          {rangeType === "all" ? (
            <Plot
              data={[
                {
                  type: "scatter",
                  mode: "lines+markers",
                  line: { shape: "spline", smoothing: 1.3 },
                  x: requestScatterChartXData,
                  y: requestScatterChartYData,
                },
              ]}
              layout={{
                showlegend: false,
                autosize: true,
                yaxis: { range: [0, Math.max.apply(null, [...requestScatterChartYData])], fixedrange: true },
              }}
              useResizeHandler
              config={{
                modeBarButtonsToRemove: ["select2d", "autoScale2d", "zoom2d", "lasso2d"],
                displaylogo: false,
                toImageButtonOptions: {
                  filename: `${today}_Динамика по заявкам_${
                    currentCourse?.id || ""
                  }_${organization?.title}_График_${startDate}-${endDate}`,
                  format: "png",
                  width: 1920,
                  height: 1080,
                },
                modeBarButtonsToAdd: [
                  {
                    icon: {
                      // @ts-ignore
                      svg: [showChangeChartIcon()],
                    },
                    name: "Смена вида визуализации",
                    click: handleIconClick,
                  },
                ],
              }}
            />
          ) : (
            <Plot
              data={[
                {
                  type: "scatter",
                  mode: "lines+markers",
                  line: { shape: "spline" },
                  x: requestScatterChartXData,
                  y: requestScatterChartYData,
                },
              ]}
              layout={{
                showlegend: false,
                autosize: true,
              }}
              useResizeHandler
              config={{
                modeBarButtonsToRemove: ["select2d", "autoScale2d", "zoom2d", "lasso2d"],
                displaylogo: false,
                toImageButtonOptions: {
                  filename: `${today}_Динамика по заявкам_${
                    currentCourse?.id || ""
                  }_${organization?.title}_График_${startDate}-${endDate}`,
                  format: "png",
                  width: 1920,
                  height: 1080,
                },
              }}
            />
          )}
          {isActive && (
            <div ref={refChartsOptions}>
              <ChartsOptions chartType={"scatter"} changeChartType={changeChartType} type={type} isActive={isActive} />
            </div>
          )}
          <div>Данные по заявкам мероприятия "{currentCourse?.title}"</div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            style={{
              margin: "0, auto",
              textAlign: "center",
            }}
          >
            Отсутствуют данные за выбранный временной промежуток
          </div>
        </div>
      )}
    </>
  )
}

export default RequestsScatterChart
