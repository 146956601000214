import React, { useEffect, useRef } from "react"
import Advantage from "./Advantage"
import useStyle from "./styles"
import { Line } from "shared/ui"
import { Typography } from "@mui/material"

const Advantages = ({ e, handleChangeRef }) => {
  const classes = useStyle()
  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  return (
    <div ref={ref} className={classes.advantagesContainer}>
      <Typography variant={"h2"} sx={{ color: e.H2.color }}>
        {e.H2.text}
      </Typography>
      <div className={classes.advantagesContentContainer}>{!!e.options && e.options.map(r => <Advantage e={r} />)}</div>
    </div>
  )
}

export default Advantages
