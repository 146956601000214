import { styled } from "@mui/material/styles"

export const MainDiv = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}))

export const BaseDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}))

export const TeamsCourse = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}))

export const ChoiseCourseMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginTop: theme.spacing(8),
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(6),
  },
}))

export const ChoiseCourseDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(6),
  alignItems: "flex-end",
  justifyContent: "flex-start",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(6),
  },
}))

export const TeamsMainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing(31),
  marginRight: theme.spacing(31),
}))
