import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosInstance as axios } from "shared/api/axiosInstance"
//@ts-ignore
import Cookies from "js-cookie"
import { Hardware } from "../model/Iot.types"

export const fetchMyHardware = createAsyncThunk("iot/fetchMyHardware", async () => {
  const response = await axios.get("/hardware/my_hardware/")
  return response.data
})

export const fetchAllowedHardware = createAsyncThunk("iot/fetchAllowedHardware", async () => {
  const response = await axios.get("/hardware/allowed_hardware/")
  return response.data
})

export const fetchUsers = createAsyncThunk("iot/fetchUsers", async (serial: string) => {
  const response = await axios.get(`/hardware/${serial}/user_statuses/`)
  return response.data
})

export const deleteUser = createAsyncThunk("iot/deleteUser", async (data: { email: string; serial: string }) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  const response = await axios.delete(`/hardware/${data.serial}/revoke_access/`, { params: { email: data.email } })
  return { ...response.data, email: data.email }
})

export const setUserStatus = createAsyncThunk(
  "iot/setStatus",
  async (data: { email: string; serial: string; role: string }) => {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    const response = await axios.patch(`/hardware/${data.serial}/set_status/`, { email: data.email, role: data.role })
    return { ...response.data, email: data.email, role: data.role }
  }
)

export const fetchCsvOfSensor = async (serial: string, pid: number, title: string, range: string, aggr: string) =>
  await axios.get(`/hardware/${serial}/history/`, {
    params: {
      pid: pid,
      range: range,
      field: title,
      aggregate: aggr,
      download: "csv",
    },
  })

export const fetchSensorHistory = async (
  serial: string,
  pid: number,
  range: string,
  title: string,
  aggregate: string
) =>
  await axios.get(`/hardware/${serial}/history/`, {
    params: {
      pid: pid,
      range: range,
      field: title,
      aggregate: aggregate,
    },
  })

export const getToken = async (ref_token: string) => await axios.post("/token/refresh/", { refresh: ref_token })

export const fetchChartHistoryData = async (
  currentHardware: Hardware,
  pid: string,
  label: string,
  startDate: string,
  startTime: string,
  finishDate: string,
  finishTime: string
) =>
  await axios.get(`/hardware/${currentHardware.serial}/history`, {
    params: {
      pid: pid,
      field: label,
      start: startDate + "T" + startTime + ":00Z",
      stop: finishDate + "T" + finishTime + ":00Z",
      aggregate: "1h",
      range: "custom",
    },
  })

export const fetchChartCsv = async (
  currentHardware: Hardware,
  pid: string,
  label: string,
  startDate: string,
  startTime: string,
  finishDate: string,
  finishTime: string
) =>
  await axios.get(`/hardware/${currentHardware.serial}/history`, {
    params: {
      pid: pid,
      field: label,
      start: startDate + "T" + startTime + ":00Z",
      stop: finishDate + "T" + finishTime + ":00Z",
      aggregate: "1h",
      range: "custom",
      download: "csv",
    },
  })
