import { createSlice } from "@reduxjs/toolkit"
import {
  deleteUser,
  fetchAllowedHardware,
  fetchUsers,
  Hardware,
  HardwareConfig,
  Sensor,
  sensorsIndexes,
  setUserStatus,
  UserType,
} from "entities/Iot"
import { Simulate } from "react-dom/test-utils"
import { fetchMyHardware } from "entities/Iot"
import { ChangeEvent } from "react"

interface Iot {
  currentHardware: Hardware | null
  hardwareList: Array<Hardware>
  userStatus: "observer" | "controller"
  gotConfig: boolean
  config: HardwareConfig | null
  values: {} | null
  users: Array<UserType>
  chartSettings: {
    yDateScale: boolean
    startDate: string
    finishDate: string
    startTime: string
    finishTime: string
    paramsChecked: Array<{
      pid: number
      label: string
    }>
    sensorsChecked: Array<number>
  }
  connectionStatus: boolean
}

const initialState: Iot = {
  currentHardware: null,
  userStatus: "observer",
  hardwareList: [],
  users: [],
  gotConfig: false,
  config: null,
  chartSettings: {
    yDateScale: false,
    startDate: "",
    finishDate: "",
    startTime: "00:00",
    finishTime: "00:00",
    sensorsChecked: [],
    paramsChecked: [],
  },
  values: null,
  connectionStatus: false,
}

const iotSlice = createSlice({
  name: "iot",
  initialState: initialState,
  reducers: {
    setCurrentHardware: (state, action) => {
      state.users = []
      if (!action.payload?.value) state.currentHardware = null
      else {
        state.hardwareList.forEach(item => {
          if (item.id === action.payload.value && item.name === action.payload.label) {
            state.currentHardware = item
            localStorage.setItem("current_hardware", item.serial)
          }
        })
      }
      state.gotConfig = false
    },
    setConnectionStatus: (state, action) => {
      state.connectionStatus = action.payload
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload
    },
    setGotConfig: (state, action) => {
      state.gotConfig = action.payload
    },
    handleParamsChecked: (
      state,
      action: { payload: { event: ChangeEvent<HTMLInputElement>; item: { label: string; pid: number } } }
    ) => {
      if (action.payload.event.target.checked) {
        state.chartSettings.paramsChecked.push(action.payload.item)
      } else {
        state.chartSettings.paramsChecked.splice(
          state.chartSettings.paramsChecked.findIndex(
            p => p.pid === action.payload.item.pid && p.label === action.payload.item.label
          ),
          1
        )
      }
    },
    handleSensorChecked: (
      state,
      action: { payload: { event: ChangeEvent<HTMLInputElement>; item: { pid: number; descr: string } } }
    ) => {
      if (action.payload.event.target.checked) {
        state.chartSettings.sensorsChecked.push(action.payload.item.pid)
      } else {
        state.chartSettings.sensorsChecked.splice(
          state.chartSettings.sensorsChecked.indexOf(action.payload.item.pid),
          1
        )
        state.chartSettings.paramsChecked = state.chartSettings.paramsChecked.filter(
          e => e.pid !== action.payload.item.pid
        )
      }
    },
    handleChangeStartDate: (state, action: { payload: { date: string } }) => {
      state.chartSettings.startDate = action.payload.date
    },
    setYDataScale: (state, action) => {
      state.chartSettings.yDateScale = action.payload
    },
    handleChangeFinishDate: (state, action: { payload: { date: string } }) => {
      state.chartSettings.finishDate = action.payload.date
    },
    handleChangeStartTime: (state, action: { payload: { time: string } }) => {
      state.chartSettings.startTime = action.payload.time
    },
    handleChangeFinishTime: (state, action: { payload: { time: string } }) => {
      state.chartSettings.finishTime = action.payload.time
    },
    resetChartSettings: state => {
      state.chartSettings = initialState.chartSettings
    },
    updateConfig: (state, action) => {
      if (action.payload === null) {
        state.config = null
      } else {
        state.config = { ...action.payload }
        try {
          state?.config?.real_devs?.sort((a: Sensor, b: Sensor) =>
            sensorsIndexes[a.pid] > sensorsIndexes[b.pid] ? 1 : -1
          )
        } catch (err) {}
        let newValues: any = {}
        state?.config?.real_devs.forEach((item: Sensor) => {
          newValues[item?.pid] = item
        })
        state.values = { ...newValues }
      }
    },
    updateUiValues: (state, action) => {
      if (action.payload === null) {
        state.values = null
      } else {
        let newValues = { ...state.values }
        action.payload.devs_state.forEach((item: Sensor) => {
          //@ts-ignore
          newValues[item.pid] = item
        })
        state.values = { ...newValues }
      }
    },
    updateUiValue: (state, action) => {
      let newValues = { ...state.values }
      //@ts-ignore
      newValues[action.payload.state.pid] = action.payload.state
      state.values = { ...newValues }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMyHardware.fulfilled, (state, action) => {
        state.hardwareList = [...action.payload]
        if (state.hardwareList.filter(e => e.serial === localStorage.getItem("current_hardware")).length > 0) {
          state.currentHardware = state.hardwareList.filter(
            e => e.serial === localStorage.getItem("current_hardware")
          )[0]
        }
      })
      .addCase(fetchAllowedHardware.fulfilled, (state, action) => {
        state.hardwareList = [...action.payload]
        if (state.hardwareList.filter(e => e.serial === localStorage.getItem("current_hardware")).length > 0) {
          state.currentHardware = state.hardwareList.filter(
            e => e.serial === localStorage.getItem("current_hardware")
          )[0]
        }
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = [...action.payload]
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        for (let index = 0; index < state.users.length; index++) {
          if (state.users[index].email === action.payload.email) {
            state.users.splice(index, 1)
          }
        }
      })
      .addCase(setUserStatus.fulfilled, (state, action) => {
        for (let index = 0; index < state.users.length; index++) {
          if (state.users[index].email === action.payload.email) {
            state.users[index].user_role = action.payload.role
          }
        }
      })
  },
})

export const { actions, reducer } = iotSlice
