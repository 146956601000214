import React from "react"
import * as Styled from "./Iot.styles"
import { Switch, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { useAppSelector } from "../../../shared/hooks/useAppSelector"

interface IProps {
  title: string,
  id: number,
  value: number
  changeValue: (id: number, port: number, value: boolean) => void
}

export const SwitchCard = ({ title, value, changeValue, id }: IProps) => {

  const theme = useTheme()
  const status = useAppSelector(state => state.iot.userStatus)

  return (
    <Styled.SensorCard sx={status === "observer" ? { border: "4px solid #EBEBEB" } : {}}>
      <Typography variant={"h4"} sx={{ alignSelf: "flex-start", flexGrow: 1 }}>{title}</Typography>
      <div style={{ flexGrow: 2 }}>
        <Switch defaultChecked checked={!!value}
                disabled={status === "observer"}
                onChange={(event) => changeValue(id, 0, event.target.checked)} />
      </div>
    </Styled.SensorCard>
  )
}