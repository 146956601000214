import * as Styled from "entities/Organization/ui/styles/QuestionListCard.styles"
import { useEffect, useState } from "react"
import { Checkbox, TextField } from "shared/ui"
import { Size, Variant } from "shared/ui/styles.const"
import { IAnswer, IQuestion } from "../model/useStatisticsDataList"
import InfoTooltip from "shared/ui/InfoTooltip"
import { useStatistics } from "../model/StatisticsContext"

interface ICardProps {
  title: string
  tooltip: string
  options: IQuestion[] | IAnswer[]
  type: "question" | "answer"
  selected: IQuestion[] | IAnswer[]
  limit?: number
  onSelect: (type: "question" | "answer", option: IQuestion | IAnswer) => void
  activeQuestion?: IQuestion
  setActiveQuestion?: (x: IQuestion | undefined) => void
}

const QuestionsListCard = ({
  title,
  tooltip,
  options,
  type,
  activeQuestion,
  selected,
  limit,
  setActiveQuestion,
  onSelect,
}: ICardProps) => {
  const [searchValue, setSearchValue] = useState<string>()
  const { eventFilters } = useStatistics()

  useEffect(() => {
    if (limit && eventFilters.questions.length >= limit) {
      handleQuestion(undefined)
    }
  }, [eventFilters.questions])

  const handleQuestion = (activeQuestion: IQuestion | undefined) => {
    if (!setActiveQuestion) return
    setActiveQuestion(activeQuestion)
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, option: IQuestion | IAnswer) => {
    e.stopPropagation()
    onSelect(type, option)
  }

  const isChecked = (option: IQuestion | IAnswer) => {
    if (type === "question" && "id" in option) {
      return Boolean(selected.find(select => "id" in select && select.id === option.id))
    }
    if (type === "answer" && "question" in option && isAnswer(selected)) {
      return Boolean(
        selected.find(select => select.title === option.title && select.question.id === option.question.id)
      )
    }
    return false
  }

  function isAnswer(value: IQuestion[] | IAnswer[]): value is IAnswer[] {
    return (value[0] as IAnswer)?.question !== undefined
  }

  const isDisableCheckbox = (option: IQuestion | IAnswer) => {
    return (!isChecked(option) && limit && eventFilters.questions.length >= limit) || false
  }

  const isSelectableOption = (option: IQuestion | IAnswer) => {
    return type === "question" && !isDisableCheckbox(option)
  }

  return (
    <Styled.Card>
      <Styled.CardHeader>
        <Styled.CardTitle>{title}</Styled.CardTitle>
        <InfoTooltip text={tooltip} theme={"dark"} size={16} />
      </Styled.CardHeader>
      <TextField
        size={Size.small}
        variant={Variant.outlined}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        label={"Поиск"}
        width={264}
        disabled={!options.length}
      />
      <Styled.Options>
        {options
          .filter(option => option.title.toLowerCase().includes(searchValue?.toLowerCase() || ""))
          .map((option, index) => (
            <Styled.Option
              key={index}
              onClick={() => "answers" in option && isSelectableOption(option) && handleQuestion(option)}
              isSelectable={isSelectableOption(option)}
              isActive={"answers" in option && option.id === activeQuestion?.id}
            >
              <Checkbox
                isChecked={isChecked(option)}
                onChange={e => handleCheckbox(e, option)}
                label={option.title}
                isDisabled={isDisableCheckbox(option)}
              />
            </Styled.Option>
          ))}
      </Styled.Options>
    </Styled.Card>
  )
}

export default QuestionsListCard
