export const settingsMapper = {
  maxScore:
    "Если несколько участников выполнили командный модуль, то в командный прогресс зачтётся результат участника с наибольшим количеством баллов за модуль.",
}

export const accessTypeMapper = value => {
  switch (value) {
    case "private":
      return "Непубличная"
    case "public":
      return "Публичная"
    case "open":
      return "Открытая"
  }
}
