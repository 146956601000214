import * as Styled from "../styles/ExaminationScore.styles"
import SaveAssignmentIcon from "images/SaveAssignment"
import SuccessIcon from "images/icons/Success"
import FailIcon from "images/icons/Fail"
import { ScoreWrapper } from "./ExaminationModal.styles"
import { useEffect, useState } from "react"
import { formScoreOptions } from "entities/Assignment/lib/formOptions"
import { AssignmentStatus, IAssingment, IAssingmentReview } from "entities/Assignment/model/assignment.types"
import { statusOptions as defaultStatusOptions } from "entities/Assignment/static"
import { Autocomplete, Button, Dropdown, TextField } from "shared/ui"
import { Size, Variant } from "shared/ui/styles.const"
import { IDropdownEvent } from "shared/ui/Dropdown/Dropdown.types"
import { examinationHelpers as helpers } from "entities/Assignment/static/helpers"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { assignmentsActions } from "shared/store"
import { saveAssignment } from "entities/Assignment/api/assignment"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { removeObjectProperty } from "shared/util"
import { createFeedbackNotification } from "entities/Notification/api/notifications"
import { IFilters } from "shared/hooks/useFiltersForm"

interface IProps {
  isLoading: boolean
  setIsLoading: (x: boolean) => void
  setIsUnsaved: (x: boolean) => void
  setIsEdited: (x: boolean) => void
  setWarning: (x: string) => void
  setStashFunc: (x: undefined) => void
  assignment?: IAssingment
  review?: IAssingmentReview | null
}

const statusOptions = removeObjectProperty("", defaultStatusOptions)

const ExaminationScore = (props: IProps) => {
  const { assignment, review, isLoading, setIsLoading, setIsUnsaved, setIsEdited, setWarning, setStashFunc } = props
  const storedAssignment = useAppSelector(state => state.assignment)
  const filters: IFilters = useAppSelector(state => state.assignment.filters)
  const storedStatus = storedAssignment.examination.initStatus
  const course = storedAssignment.course
  const initHelper = storedStatus === AssignmentStatus.rejected ? helpers.reject : ""
  const initFeedback = assignment?.feedback === null ? "" : assignment?.feedback || ""
  const initStatus = storedStatus || assignment?.status || null
  const initScore = assignment?.score === 0 && initStatus === AssignmentStatus.submitted ? null : assignment?.score || 0
  const [status, setStatus] = useState<AssignmentStatus | null>(initStatus)
  const [score, setScore] = useState<number | string | null>(initScore)
  const [feedback, setFeedback] = useState<string>(initFeedback)
  const [helper, setHelper] = useState(initHelper)
  const [alert, setAlert] = useState<undefined | { status: string; msg: string }>()
  const scoreOptions = formScoreOptions(review?.scores.dropdown_min_score, review?.scores.max, false)
  const isDisableScore = !status || status === AssignmentStatus.submitted || status === AssignmentStatus.rejected
  const displayedScore = status !== AssignmentStatus.accepted ? {} : { score: String(score) }

  const isDisableSave =
    !status ||
    (status === AssignmentStatus.rejected && !feedback) ||
    (status === AssignmentStatus.accepted && (score === null || score === ""))

  const dispatch = useAppDispatch()

  useEffect(() => {
    setStatus(initStatus)
    setScore(initScore)
    setFeedback(initFeedback)
    setHelper(initHelper)
  }, [assignment])

  useEffect(() => {
    setWarning("")
    setAlert(undefined)
    if (status === initStatus && score === initScore && feedback === initFeedback) {
      setIsUnsaved(false)
    } else {
      setIsUnsaved(true)
      setStashFunc(undefined)
    }
  }, [status, score, feedback, assignment])

  useEffect(() => {
    if (review?.status === AssignmentStatus.accepted) setScore(review.score)
  }, [review])

  const handleStatus = (e: IDropdownEvent) => {
    setHelper("")
    if (e.target.value === AssignmentStatus.rejected) {
      setHelper(helpers.reject)
    }
    if (e.target.value === AssignmentStatus.accepted) {
      !feedback && setFeedback(helpers.accept)
      setScore("")
    } else {
      setFeedback("")
    }
    if (e.target.value === initStatus) {
      setScore(initScore)
      setFeedback(initFeedback)
    }
    setStatus(e.target.value as AssignmentStatus)
  }

  const onSave = async () => {
    if (!assignment?.id) return
    const payload = {
      ...assignment,
      feedback,
      status,
      score: status === AssignmentStatus.accepted ? score : 0,
    } as IAssingment
    try {
      setIsLoading(true)
      dispatch(assignmentsActions.updateAssignments(payload))
      await saveAssignment(payload)
      if (feedback) {
        await createFeedbackNotification(feedback, assignment?.id, course?.id)
        setHelper(helpers.submit)
      }
      setIsEdited(filters.status === AssignmentStatus.submitted && status !== AssignmentStatus.submitted)
      setAlert({ status: "success", msg: "Изменения  сохранены" })
      setIsUnsaved(false)
      setWarning("")
    } catch {
      setAlert({ status: "fail", msg: "Ошибка" })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ScoreWrapper>
      <Styled.SavingWrapper>
        <Styled.StatusWrapper>
          <Dropdown label="Статус" onChange={handleStatus} options={statusOptions} width={168} value={status} />
        </Styled.StatusWrapper>
        <Styled.ScoreWrapper>
          <Autocomplete
            label="Балл"
            key={status?.toString()}
            name={"score"}
            options={scoreOptions}
            value={displayedScore?.score}
            filterChange={e => setScore(e.target.value)}
            width={100}
            size={Size.small}
            disabled={isDisableScore || isLoading}
          />
        </Styled.ScoreWrapper>
        <Button
          onClick={onSave}
          endIcon={<SaveAssignmentIcon />}
          variant={Variant.outlined}
          disabled={isDisableSave || isLoading}
          width={178}
        >
          Сохранить
        </Button>
        {alert && (
          <Styled.AlertWrapper>
            <Styled.IconWrapper>{alert.status === "success" ? <SuccessIcon /> : <FailIcon />}</Styled.IconWrapper>
            <Styled.AlertText>{alert.msg}</Styled.AlertText>
          </Styled.AlertWrapper>
        )}
      </Styled.SavingWrapper>
      <Styled.HelperText variant="body2" fontWeight="small">
        {helper}
      </Styled.HelperText>
      <Styled.TextFieldWrapper>
        <TextField
          label="Комментарий к работе"
          value={status !== AssignmentStatus.submitted ? feedback : ""}
          onChange={e => setFeedback(e.target.value)}
          variant={Variant.outlined}
          width={800}
          height={82}
          disabled={isLoading || status === AssignmentStatus.submitted}
          multiLine={true}
          disableTooltip
          style={{ marginBottom: "50px" }}
        />
      </Styled.TextFieldWrapper>
    </ScoreWrapper>
  )
}

export { ExaminationScore }
