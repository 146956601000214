import React, { useRef, useState } from "react"
import * as Styled from "./AdminTeams.style.ts"
import { ScrollButtons, TableLoader } from "shared/assets"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import VisibilitySensor from "react-visibility-sensor"
import { IconButton as SharedIconButton } from "shared/ui"
import { ModerateTeam } from "../../../features/Teams/ModerateTeam"
import { ModeratedTeam } from "../../../entities/Team"
import { useTheme } from "shared/context"
import { useSelector } from "react-redux"
import SendSingleNotification from "features/Notifications/SendSingleNotification.js"

const tableField = [
  "ФИО Администратора",
  "Почта Администратора",
  "Название команды",
  "Тип команды",
  "ID команды",
  "Действие с приглашением",
]

export const TeamsTable = ({ isLoading, handleScrolledToBottom }) => {
  const tableContainerRef = useRef()
  const [openDialog, setOpenDialog] = useState(false)
  const requests = useSelector(state => state.team.requests)
  const theme = useTheme()

  function handleDialog(e) {
    if (!openDialog) {
      const params = {
        title: `Модерация команды ${e.team.title}`,
        user: e.participant.user,
      }
      setOpenDialog({ ...params })
    } else {
      setOpenDialog(false)
    }
  }

  return (
    <Styled.ParticipantMainTableContainer>
      <ScrollButtons tableRef={tableContainerRef} />
      <Styled.ParticipantTableContainer ref={tableContainerRef}>
        <Table stickyHeader sx={{ width: "100%", overflowX: "auto" }} size="small">
          <TableHead>
            <TableRow sx={{ height: theme.spacing(25) }}>
              <Styled.HeaderCell></Styled.HeaderCell>
              {tableField.map(e => (
                <Styled.HeaderCell align={"center"}>
                  <Typography variant={"caption"} fontWeight={"small"}>
                    {e}
                  </Typography>{" "}
                </Styled.HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
            <TableBody>
              {requests?.map(e => (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <>
                    <TableCell align="center">
                      <SharedIconButton
                        id={`notification`}
                        color={"inherit"}
                        size={"medium"}
                        onClick={() => handleDialog(e.members?.find(e => e.category === "chief_moderator"))}
                      >
                        <Styled.NotificationIcon />
                      </SharedIconButton>
                    </TableCell>
                    <ModeratedTeam team={e} />
                    <ModerateTeam team={e} />
                  </>
                </TableRow>
              ))}
            </TableBody>
          </VisibilitySensor>
        </Table>
        <TableLoader isLoading={isLoading}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
        <SendSingleNotification
          user={openDialog?.user}
          title={openDialog?.title}
          isOpen={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      </Styled.ParticipantTableContainer>
    </Styled.ParticipantMainTableContainer>
  )
}
