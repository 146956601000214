import {makeStyles} from "@mui/styles";

export default makeStyles(theme => ({

    videoContainer: {
        paddingLeft: 124,
        paddingRight: 124,
        paddingBottom: "96px",
        width: "100%",
        
        [theme.breakpoints.down("md")] : {
            paddingLeft: 100,
            paddingRight: 100,
        },
        [theme.breakpoints.down("sm")] : {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },

    video:{
        border: 0,
        width: "100%",
        height: "80vh"
    },

}))
