import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
    profileContainer: {
        marginTop: 21, 
        padding: "40px 40px 80px 40px", 
    },
    profileH1: {
        fontFamily: "Roboto",
        fontSize: 20,
        lineHeight: '25px',
        fontWeight: 600,
        marginBottom: 20,
    },
    profileP: {
        fontFamily: "Roboto",
        fontSize: 16,
        lineHeight: '155%',
        fontWeight: 400,
        letterSpacing: 0.06,
    },
}))
