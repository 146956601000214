import React from 'react'
import {
   Typography,
   Tooltip,
   Grid,
   Icon,
} from '@mui/material'

import useStyles from "./styles";
import AchievementIcon from '../../../images/icons/kubok.svg';


export const Achievement = (props) => {
  const classes = useStyles();

  function timeProcess(time){
    let date = new Date(time)
    let format = new Intl.DateTimeFormat('ru-RU', { month: 'long', day: 'numeric', year: 'numeric' })
    return `${format.format(date)}`
  }

  return (
    <Tooltip title={props.content} placement={"top"}>
      <Grid item xs={3} className={classes.achievBlock}>
        <div className={classes.achievIcon} style={{ backgroundColor: "#FA5D55"}}>
          <div><Icon><img src={AchievementIcon} /></Icon></div>
        </div>
        <div className={classes.achievTitle}>
          <Typography variant='body2'>{props.title}</Typography>
        </div>
        <div className={classes.achievDate} >
          <Typography variant='body2'>{timeProcess(props.creation_time)}</Typography>
        </div>
        </Grid>
    </Tooltip>
  )
}
