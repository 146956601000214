import React from "react"
import { TableCell, Typography } from "@mui/material"
import { UserType } from "../model/Iot.types"

export const UserRow = ({ user }: { user: UserType }) => {
  return (
    <>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {`${user.last_name} ${user.first_name} ${user.middle_name}`}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
          {user.email}
        </Typography>
      </TableCell>
    </>
  )
}