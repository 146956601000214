import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

const InviteHelperText = () => {
  return (
    <Wrapper>
      <Title>Правила добавления участников в команду</Title>
      <ListItem>Участника можно добавить в команду, если:</ListItem>
      <ol>
        <ListItem>
          <li>он уже зарегистрирован на платформе;</li>
        </ListItem>
        <ListItem>
          <li>подал заявку на участие в данном мероприятии;</li>
        </ListItem>
        <ListItem>
          <li>его заявка на участие была одобрена.</li>
        </ListItem>
      </ol>
    </Wrapper>
  )
}

const Wrapper = styled("div")(() => ({
  marginBottom: "16px",
}))

const StyledTypography = styled(Typography)(() => ({
  fontSize: "18px",
  fontStyle: "normal",
}))

const Title = styled(StyledTypography)(() => ({
  fontWeight: "500",
  marginBottom: "24px",
  letterSpacing: "0.2px",
}))

const ListItem = styled(StyledTypography)(() => ({
  fontWeight: "400",
  marginBottom: 16,
  lineHeight: "155%",
}))

export { InviteHelperText }
