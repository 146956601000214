import React, { useState } from "react"
import { RequestsAndStatusesChartType } from "./RequestsAndStatusesCharts"
import * as Styled from "../../../../features/Organization/ui/ChangeChartTable.styles"
import { Grow } from "@mui/material"
import { requestsChartsOptions, statusesChartsOptions } from "../../static/statistics"
import { ChartsOptionsType } from "./chartsOptions.types"

export const ChartsOptions = ({
                                changeChartType,
                                chartType,
                                type,
                                isActive,
                              }: ChartsOptionsType) => {

  const [activeOptionKey, setActiveOptionKey] = useState<RequestsAndStatusesChartType>(chartType)
  const changeOptionHandler = (value: RequestsAndStatusesChartType) => {
    changeChartType(value)
    setActiveOptionKey(value)
  }

  const getOptions = () => {
    if (type === "requests") return requestsChartsOptions
    if (type === "statuses") return statusesChartsOptions
    return []
  }

  return (
    <>
      <Grow in={isActive} style={{ transformOrigin: "top right" }}>
        <Styled.Options>
          {getOptions().map(option => (
            <Styled.Option
              key={option.value}
              onClick={() => changeOptionHandler(option.value)}
              isActive={activeOptionKey === option.value}
            >
              {option.label}
            </Styled.Option>
          ))}
        </Styled.Options>
      </Grow>
    </>
  )
}
