import useStyles from "../styles/Static.table.styles"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"

function StaticTable({ content }: any) {
  const classes = useStyles()
  let columns = Object.keys(content[0]).filter(c => c !== "")

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" className={classes.interactTable}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.interactTableTh}></TableCell>
            {columns.map(col => (
              <TableCell align="center" className={classes.interactTableTh} style={{ fontWeight: "bold" }}>
                {`${col}`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map((row: any) => (
            <TableRow
              key={row}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className={classes.interactTableTr}
            >
              <TableCell
                align="center"
                className={classes.interactTableTd}
                style={{ fontWeight: "bold" }}
              >{`${row[""]}`}</TableCell>
              {columns.map(col => (
                <TableCell align="center" className={classes.interactTableTd}>
                  {row[col]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StaticTable
