import React, { useState, useEffect, useMemo } from "react"
import useStyles from "./styles"
import { FormControl, FormHelperText, FormLabel, Button, Typography, CircularProgress } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

import CheckIcon from "@mui/icons-material/Check"

import { Choice, SingleChoice, Matrix, Match, Sort, Detailed, Attach } from "entities/Test/ui/questionTypes"

function parseText(text, index = null) {
  if (!text) return
  if (text.startsWith("<")) {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>
  } else if (index != null) {
    return <Typography>{`${index + 1}.  ${text}`}</Typography>
  } else {
    return <Typography>{text}</Typography>
  }
}

export function ReviewBlock(props) {
  const classes = useStyles()

  const tests = useMemo(
    () => props.block.components.map(c => ({ ...c, ...JSON.parse(c.props), disableShuffle: true })),
    [props.block.id]
  )

  const [value, setValue] = useState(new Array(tests.length).fill({}))
  const [showHeader, setShowHeader] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fileInput = React.useRef()
  var answer

  const submitReview = event => {
    setIsLoading(true)
    event.preventDefault()
    let answers = new Array()
    let text = ""
    for (let index = 0; index < tests.length; index++) {
      if (value[index]) {
        if (tests[index].type == "attach") {
          continue
        }
        if (tests[index].type == "choice") {
          answer = new Array()
          for (let item in value[index]) {
            if (value[index][item]) {
              answer.push(item)
            }
          }
          answer = JSON.stringify(answer)
        }
        if (tests[index].type == "matrix") {
          answer = new Array()
          let checked = new Array(tests[index].variants_y.length).fill(false)
          for (let item in value[index]) {
            if (value[index][item]) {
              answer.push(item)
              checked[parseInt(item.split(",").pop()) - 1] = true
            }
          }
          for (let index = 0; index < checked.length; index++) {
            if (!checked[index]) {
              answer.push(`0,${index + 1}`)
            }
          }
          answer = JSON.stringify(answer)
        }
        if (tests[index].type === "detailed" || tests[index].type == "numeric") {
          answer = value[index][tests[index].index] || ""
          text = answer
        }
        if (tests[index].type === "single-choice" || tests[index].type === "sort" || tests[index].type === "match") {
          answer = JSON.stringify(value[index])
        }
        answers.push({ question: tests[index].text, key: answer, index: index + 1 })
      }
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`/content/reviews/`, {
        course: props.course,
        text: text,
        other: JSON.stringify(answers),
        content_block: props.block.id,
      })
      .then(res => {
        setShowHeader(true)
        setIsLoading(false)
        window.scrollTo(0, 0)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  function restartTest() {
    setValue(new Array(tests.length).fill({}))
    setShowHeader(false)
    setIsLoading(false)
  }

  useEffect(() => {
    restartTest()
  }, [props.block.id])

  function changeByIndex(object, index, value) {
    object[index] = value
    return [...object]
  }

  const handleChangeChoice = event => {
    event.persist()
    for (let index = 0; index < tests.length; index++) {
      if (event.target.name == tests[index].index) {
        setValue(c => changeByIndex(c, index, { ...c[index], [event.target.value]: event.target.checked }))
      }
    }
  }

  const handleChangeSingleChoice = (event, value) => {
    event.persist()
    for (let index = 0; index < tests.length; index++) {
      if (event.target.name == tests[index].index) {
        setValue(c => changeByIndex(c, index, [value]))
      }
    }
  }

  const handleChangeDetailed = event => {
    event.persist()
    for (let index = 0; index < tests.length; index++) {
      if (event.target.name == tests[index].index) {
        setValue(c => changeByIndex(c, index, { [event.target.name]: event.target.value }))
      }
    }
  }

  const handleChangeDragAndDrop = (index, newValue) => {
    setValue(c => changeByIndex(c, index, newValue))
  }

  function questionMapper(question, index) {
    switch (question.type) {
      case "choice":
        return <Choice question={question} value={value[index]} handleChange={handleChangeChoice} />
      case "single-choice":
        return <SingleChoice question={question} value={value[index]} handleChange={handleChangeSingleChoice} />
      case "matrix":
        return <Matrix question={question} value={value[index]} handleChange={handleChangeChoice} />
      case "detailed":
        return (
          <Detailed question={question} value={value[index]} handleChange={handleChangeDetailed} inputType="text" />
        )
      case "numeric":
        return (
          <Detailed question={question} value={value[index]} handleChange={handleChangeDetailed} inputType="number" />
        )
      case "attach":
        return <Attach fileInput={fileInput} />
      case "match":
        return <Match question={question} index={index} handleChange={handleChangeDragAndDrop} />
      case "sort":
        return <Sort question={question} index={index} handleChange={handleChangeDragAndDrop} />
      default:
        return
    }
  }

  if (!tests || !value) return <></>

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        marginLeft: "5vw",
        marginRight: "5vw",
      }}
    >
      <form onSubmit={submitReview} className={classes.test}>
        {showHeader && (
          <div className={classes.testHeader} style={{ backgroundColor: "#DEF3FC", borderColor: "#81D9FF" }}>
            <div style={{ display: "flex" }}>
              <CheckIcon style={{ marginRight: 6, color: "#2C6BB5" }} />
              <Typography variant="h4">Ваш отзыв принят, спасибо!</Typography>
            </div>
          </div>
        )}
        {tests.map((e, index) => (
          <div style={{ width: 800 }}>
            <FormControl component="fieldset" style={{ marginTop: 18, marginBottom: 50 }}>
              <FormLabel component="legend" style={{ color: "black", maxWidth: 800, lineHeight: 1.3 }}>
                <div className={classes.testQuest}>{parseText(e.text, index)}</div>
                <Typography className={classes.testNotice}>{parseText(e.description)}</Typography>
              </FormLabel>
              <div>
                <img src={e.image} className={classes.testImg} />
                {questionMapper(e, index)}
              </div>
            </FormControl>
          </div>
        ))}
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.testButtonSubmit}
            style={{ float: "left", marginTop: 25 }}
          >
            Отправить
          </Button>
        )}
      </form>
    </div>
  )
}
