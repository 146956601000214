export const invitationErrorHandler = (isInvitationReply: boolean, errStatus: number, action?: "accept" | "reject") => {
  if (action === "accept")
    if (isInvitationReply) {
      switch (errStatus) {
        case 403:
          return "Чтобы совершить это действие, вы должны быть приглашены в команду."
        case 404:
          return "Участник не обнаружен."
        case 412:
          return "Превышено максимальное число участников в команде."
        case 416:
          return `Вы достигли предела по участию в нескольких командах с выбранной ролью на курсе.
            Покиньте одну из ваших команд и попробуйте снова.`
      }
    } else {
      switch (errStatus) {
        case 412:
          return "Это действие предназначено только для обработки приглашённых участников."
        case 416:
          return "Пользователь достиг предела по участию в нескольких командах с выбранной ролью на курсе."
      }
    }
  if (action === "reject")
    if (isInvitationReply) {
      switch (errStatus) {
        case 412:
          return "Это действие предназначено только для обработки приглашённых участников."
        case 403:
          return "Чтобы совершить это действие, вы должны быть приглашены в команду."
      }
    }
  if (errStatus === 404) {
    return "Участник не обнаружен"
  }
  return ""
}
