import React, { useState } from "react"
import * as Styled from "./Organization.styles"
import { Avatar, Grid, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { Button } from "../../../shared/ui"
import { CategoryChip } from "./CategoryChip"
import { useDispatch, useSelector } from "react-redux"
import { organizationActions } from "shared/store"
export const OrganizationCard = ({ setOpenEditLanding }) => {
  const theme = useTheme()
  const [isOpenEditing, setIsOpenEditing] = useState(false)
  const organization = useSelector(state => state.organization.currentOrganization)
  const dispatch = useDispatch()

  if (!organization) return <></>

  return (
    <Styled.MainOrganizationCardDiv>
      <Styled.OrganizationCardTitle>
        <Typography variant={"h3"}>{organization.title}</Typography>
        <div style={{ display: "flex", gap: theme.spacing(4) }}>
          <Button color={"primary"} variant={"contained"} onClick={() => setOpenEditLanding(true)}>
            Шаблон организации
          </Button>
          <Button
            color={"primary"}
            variant={"outlined"}
            onClick={() => {
              dispatch(organizationActions.setEditOrganization(true))
            }}
          >
            Изменить данные
          </Button>
        </div>
      </Styled.OrganizationCardTitle>
      <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"} sx={{ marginTop: theme.spacing(4) }}>
        ID: {organization.id}
      </Typography>
      <Styled.OrganizationCardDescription>
        <Avatar sx={{ width: 240, height: 240, objectFit: "fill" }} src={organization.icon_logo} />
        <div style={{ display: "flex", flexDirection: "column", gap: theme.spacing(8) }}>
          <Styled.OrganizationCardCategories>
            <Typography variant={"subtitle3"}>Категория:</Typography>
            <Grid container spacing={theme.spacing(4)}>
              <Grid item>
                <CategoryChip label={organization.category} />
              </Grid>
            </Grid>
          </Styled.OrganizationCardCategories>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            {organization.information}
          </Typography>
        </div>
      </Styled.OrganizationCardDescription>
    </Styled.MainOrganizationCardDiv>
  )
}
