import * as Styled from "../styles/ExaminationFiles.styles"
import { IAssingment, IAssingmentReview, IReviewSubmision } from "../../model/assignment.types"
import baseUrlApi from "shared/config"
import { TestTypes } from "entities/Test/lib/Test.types"
import StaticTable from "./StaticTable"

export const ExaminationTasks = ({
  assignment,
  review,
}: {
  assignment?: IAssingment
  review?: IAssingmentReview | null
}) => {
  const link = baseUrlApi.hostApi + "#feedback-form"
  const isComponent = Boolean(assignment?.task_submissions.find(submission => submission.component !== null))

  if (review?.task_submissions?.length)
    return (
      <Styled.TasksWrapper>
        {review?.task_submissions.map((submission, index) => {
          const lengthSum = submission.question?.length + submission.answer?.length
          const isWraping = lengthSum > 115
          const isInRow = submission?.type !== TestTypes.table && submission?.type !== TestTypes.sort
          return (
            <Styled.Submission key={index} isInRow={isInRow}>
              <Styled.Question isInRow={isInRow}>
                <Styled.Index>№{index + 1}:</Styled.Index>
                {submission.question}
              </Styled.Question>{" "}
              {isWraping && <Styled.Indent />}
              <Styled.Asnwer>
                <SubmissionAnswer submission={submission} />
              </Styled.Asnwer>
            </Styled.Submission>
          )
        })}
      </Styled.TasksWrapper>
    )

  if (!assignment?.attachments?.length && !isComponent)
    return (
      <Styled.Error>
        Ошибка отображения ответа. Система не может распознать, к какому заданию относятся ответы. Если вам необходимо
        увидеть ответы, пожалуйста, сообщите об этом напрямую через{" "}
        <Styled.Link href={link} target="_blank">
          форму обратной связи
        </Styled.Link>
        .
      </Styled.Error>
    )
}

const SubmissionAnswer = ({ submission }: { submission: IReviewSubmision }) => {
  if (submission?.type === TestTypes.table) {
    const content = JSON.parse(submission?.answer).splice(1)
    return <StaticTable content={content} />
  }
  if (submission.type === TestTypes.sort) {
    const { answers, firstAnswerPart } = parseSortAnswer(submission.answer)
    return (
      <div>
        Ответ: {firstAnswerPart}
        {answers.map(answer => (
          <div>{answer}</div>
        ))}
      </div>
    )
  }
  return submission.answer ? `Ответ: ${submission.answer}` : " - "
}

const parseSortAnswer = (answer: string) => {
  const lastBrace = answer.lastIndexOf("]") + 2
  const cuttedString = answer.slice(lastBrace, answer.length)
  const firstAnswerPart = answer.slice(0, lastBrace)
  const answers = cuttedString.split(", ")
  return { answers, firstAnswerPart }
}
