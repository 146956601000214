import { useStatistics } from "../model/StatisticsContext"
import { StatisticsVariants } from "../model/StatisticsContext.types"
import { infoLegendColors } from "../static/statistics"
import {useTheme} from "../../../shared/context";

const useSunburstChart = (type: StatisticsVariants, infoType: string) => {
  const theme = useTheme()
  const { statistics } = useStatistics()
  const { charts } = statistics[type]
  const margin = 10
  const size = 500
  // @ts-ignore
  const currentCharts = charts[infoType]

  if (!currentCharts) return {data: [], layout: {}}

  const chartLabels = Object.keys(currentCharts)
  const totalYours = currentCharts[chartLabels[0]]?.reduce((acc: any, item: any) => acc + item.data, 0)
  const totalOthers = currentCharts[chartLabels[1]]?.reduce((acc: any, item: any) => acc + item.data, 0)
  const total = totalYours + totalOthers
  const genLabels = chartLabels
    .map(label => currentCharts[label]?.map((item: any) => (label === "Ваши мероприятия" ? item.title : item.title)))
    .flat(1)
  const genValues = chartLabels.map(label => currentCharts[label]?.map((item: any) => item.data)).flat(1)
  const colors = chartLabels.map(label => currentCharts[label]?.map((item: any) => item.color)).flat(1)
  const genParents = [
    ...currentCharts[chartLabels[0]].map(() => "your_events"),
    ...currentCharts[chartLabels[1]].map(() => "other_events"),
  ]
  const genIds = [
    ...currentCharts[chartLabels[0]].map((_: any, index: number) => `your_events_${index}`),
    ...currentCharts[chartLabels[1]].map((_: any, index: number) => `other_events_${index}`),
  ]
  const ids = ["total", "your_events", "other_events", ...genIds]
  const labels = ["Всего", "Ваши мероприятия", "Мероприятия других орг-й", ...genLabels]
  const parents = ["", "total", "total", ...genParents]
  const values = [total, totalYours, totalOthers, ...genValues]

  const data = [
    {
      type: "sunburst",
      ids: ids,
      labels: labels,
      parents: parents,
      values: values,
      outsidetextfont: { size: theme.spacing(4), color: "#377eb8" },
      marker: { line: { width: 2 }, colors: ["", infoLegendColors.organization, infoLegendColors.other, ...colors] },
      branchvalues: "total",
      textinfo: "percent entry",
      hoverinfo: "label+value+percent",
      hovertemplate: "<b>%{label}</b><br>" + "Значение: %{value}<br>" + "Процент: %{percentEntry:.2%}<extra></extra>",
    },
  ]

  const layout = {
    margin: { l: margin, r: margin, b: margin, t: margin },
    width: size,
    height: size,
  }
  return { data, layout }
}

export default useSunburstChart
