import React from "react"
import { Typography } from "@mui/material"
import * as Styled from "./ui/Event.styles"
import { useTheme } from "shared/context"

export const LocationBlock = ({ item }: { item: { text: string; url: string; title: string } }) => {
  const theme = useTheme()
  return (
    <Styled.LocationBlockMainDiv>
      <Typography variant={"h2"}>{item.title || "Как добраться?"}</Typography>
      <Styled.LocationBlockContentContainer>
        {/*@ts-ignore*/}
        <Typography
          variant={"body1"}
          fontWeight={"medium"}
          fontSize={"large"}
          sx={{ width: "40%", [theme.breakpoints.down("sm")]: { width: "100%" } }}
        >
          <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
        </Typography>
        <Styled.IframeBlock style={{ border: "none" }} src={item.url} />
      </Styled.LocationBlockContentContainer>
    </Styled.LocationBlockMainDiv>
  )
}
