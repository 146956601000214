import * as yup from "yup"

const subsectionSchema = yup.object().shape({
  title: yup
    .string()
    .required("Заполните это поле.")
    .max(128, "Превышен лимит символов. Максимальное количество символов - 128."),
})

const sectionSchema = yup.object().shape({
  title: yup
    .string()
    .required("Заполните это поле.")
    .max(128, "Превышен лимит символов. Максимальное количество символов - 128."),
  subsections: yup.array().of(subsectionSchema),
})

export const validationModuleSchema = yup.object().shape({
  title: yup
    .string()
    .required("Заполните это поле.")
    .max(128, "Превышен лимит символов для ввода заголовка модуля. Максимальное количество символов - 32."),
  index: yup
    .number()
    .typeError("Введите число.")
    .required("Заполните это поле")
    .min(0, "Минимальное допустимое значение - 0")
    .max(1000, "Максимальное допустимое значение - 1000"),
  description: yup
    .string()
    .notRequired()
    .max(256, "Превышен лимит символов для ввода описания модуля. Максимальное количество символов - 32."),
  start: yup.string().required("Заполните это поле."),
  link_on_webinar: yup.string().notRequired().url("Введите действительную ссылку."),
  link_on_location: yup.string().notRequired().url("Введите действительную ссылку."),
  location_description: yup
    .string()
    .notRequired()
    .max(256, "Превышен лимит символов для ввода описания места проведения. Максимальное количество символов - 256."),
  recording_description: yup
    .string()
    .notRequired()
    .max(
      256,
      "Превышен лимит символов для ввода описания ссылки на трансляцию. Максимальное количество символов - 256."
    ),
  min_score: yup
    .number()
    .typeError("Введите число.")
    .min(0, "Минимальное допустимое значение - 0")
    .max(1000, "Максимальное допустимое значение - 1000"),
  max_score: yup
    .number()
    .typeError("Введите число.")
    .min(0, "Минимальное допустимое значение - 0")
    .max(1000, "Максимальное допустимое значение - 1000"),
  sections: yup.array().of(sectionSchema),
})
