import { TableCell, Typography } from "@mui/material"
import { IPersonal } from "entities/Organization/model/StatisticsContext.types"
import { wordMapper } from "shared/local"

const StatisticsPersonalRow = ({ participant }: { participant: IPersonal }) => {
  return (
    <>
      <TransparentCell value={wordMapper(participant?.status)} />
      <TransparentCell value={participant?.category} />
      <TransparentCell value={participant?.is_active ? "Активен" : "Не активен"} />
      <TransparentCell value={participant?.course} />
      <TransparentCell value={participant?.activity_status} />
    </>
  )
}

const TransparentCell = ({ value }: { value: string }) => (
  <TableCell align="center" sx={{ maxWidth: 200, overflowWrap: "break-word" }}>
    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
      {value || "-"}
    </Typography>
  </TableCell>
)
export { StatisticsPersonalRow }
