import React, { useEffect, useState } from "react"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

export const FormInput = ({ name, label, type = "text", required }) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)
  const handleMouseDownPassword = event => event.preventDefault()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors[name] ? errors[name].message : ""

  const handleChange = (e, field) => {
    if (type === "email") {
      const trimmedValue = e.target.value.replace(/\s/g, "")
      const lowerCaseValue = trimmedValue.toLowerCase()
      field.onChange(lowerCaseValue)
    } else if (type === "text") {
      let newValue = e.target.value
      if (!newValue.charAt(0).match(/\p{L}/u)) newValue = newValue.slice(1)
      if (
        newValue.slice(-1) === " " ||
        newValue.slice(-1) === "-" ||
        newValue === "" ||
        newValue.slice(-1).match(/\p{L}/u)
      ) {
        field.onChange(newValue)
      }
    } else if (type === "password") {
      const trimmedValue = e.target.value.replace(/\s/g, "")
      field.onChange(trimmedValue)
    } else {
      field.onChange(e.target.value)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          onChange={e => handleChange(e, field)}
          focused={!!field.value}
          error={!!errorMessage}
          helperText={<div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>}
          label={label}
          fullWidth
          required={!!required}
          type={type === "password" ? (showPassword ? "text" : "password") : "text"}
          InputProps={
            type === "password" && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={event => handleClickShowPassword(event)}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          }
        />
      )}
    />
  )
}
