import { styled } from "@mui/material/styles"
import { Box, Tooltip, Typography } from "@mui/material"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import themes from "../../shared/themes"

export const MainScreenContainer = styled("div")(({ theme }) => ({
  height: "auto",
  width: "100%",
  marginTop: 75,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingTop: theme.spacing(24),
  paddingBottom: theme.spacing(24),
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(8),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(8),
  },
}))

export const MainScreenImage = styled(Box)(({ theme }) => ({
  "& span": {
    display: "flex !important",
  },
  width: "90%",
  [theme.breakpoints.down("md")]: {
    alignSelf: "center",
    width: "80%",
  },
}))

export const ImageAndButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "40%",
  height: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "flex-start",
    gap: theme.spacing(16),
    flexDirection: "column-reverse",
  },
  [theme.breakpoints.down("xs")]: {
    alignItems: "center",
    justifyContent: "center",
  },
}))

export const EventActionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "auto",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}))

export const MainScreenMessage = styled("p")(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  borderRadius: theme.spacing(3),
  textAlign: "center",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  maxWidth: 400,
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}))

export const MainScreenTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "35%",
  justifyContent: "flex-start",
  zIndex: 4,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  "& > div:last-of-type": {
    fontSize: 20,
  },
  "& h6": {
    fontSize: 16,
  },
  [theme.breakpoints.up("lg")]: {
    gap: 45,
    width: "45vw",
    "& > div:last-of-type": {
      fontSize: 20,
    },
    "& h1": {
      fontSize: "4vw",
    },
    "& h6": {
      fontSize: 28,
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "35vw",
    "& > div:last-of-type": {
      fontSize: 18,
    },
    "& h1": {
      fontSize: "4vw",
    },
    "& h6": {
      fontSize: 20,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "50vw",
    gap: 10,
    "& h1": {
      fontSize: "6vw",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: theme.spacing(14),
    "& h1": {
      fontSize: "6vw",
    },
    "& h6": {
      fontSize: 16,
      width: "60vw",
    },
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: theme.spacing(12),
    "& h1": {
      fontSize: 24,
    },
    "& h6": {
      width: "100%",
    },
  },
}))

export const StatusMessageDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(3),
  backgroundColor: "#DEF3FC",
  gap: theme.spacing(6),
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
}))

export const StatusOutlinedIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  color: "#3483F6",
  width: theme.spacing(6),
  height: theme.spacing(6),
}))

export const StatusText = styled(Typography)(({ theme }) => ({
  color: "#0444A3",
}))

export const StatusLinkText = styled(Typography)(({ theme }) => ({
  color: "#0444A3",
  textDecoration: "underline",
  cursor: "pointer",
  display: "inline-block",
  "&:hover": {
    opacity: 0.5,
  },
}))

export const PriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4),
}))

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#FFDEDE",
}))

export const ArrowStyle = {
  color: "#FFF",
}

export const TooltipStyle = {
  padding: "16px",
  backgroundColor: "#FFF",
  fontSize: "16px",
  fontWeight: "400",
  color: "#313439",
  minWidth: "fit-content",
  maxWidth: `300px`,
  borderRadius: "12px",
}
