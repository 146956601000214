import React, { useEffect, useState } from "react"
import * as Styled from "./ui/QuestionnaireEditor.styles"
import { CircularProgress, Typography } from "@mui/material"
import { Questions } from "./ui/Questions"
import { Autocomplete, Button } from "../../shared/ui"
import { infoType } from "./models/Questionnaire.types"
import { questionTypes } from "./static/static"
import { AutocompleteEvent, OptionType } from "../../shared/ui/Autocomplete/Autocomplete.types"
import { axiosInstance } from "../../shared/api/axiosInstance"
import { RequestMessage } from "../../shared/assets/RequestMessage/RequestMessage"
import AddIcon from "@mui/icons-material/Add"

export const QuestionnaireEditor = ({
  info,
  course_id,
  loadCourse,
}: {
  info: infoType[]
  course_id: number
  loadCourse: () => void
}) => {
  const [questions, setQuestions] = useState<infoType[]>([])
  const [questionTypeForCreate, setQuestionTypeForCreate] = useState<null | { value: string; label: string }>(null)
  const [removedFields, setRemovedFields] = useState<number[]>([])
  const [error, setError] = useState(false)
  const [statusText, setStatusText] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setQuestions([...info])
  }, [info])

  function pushNewQuestion() {
    const newQuestion: infoType = {
      type: questionTypeForCreate?.value || "",
      title: `${questions.length + 1}`,
      visible: false,
      is_required: false,
      course: 1,
      props: { options: [] },
      index: questions.length + 1,
    }
    setQuestions(prev => [...prev, newQuestion])
  }

  function deleteVariant(question_index: number, variant_index: number) {
    if (!!questions[question_index].props) {
      //@ts-ignore
      questions[question_index].props["options"].splice(variant_index, 1)
      setQuestions([...questions])
    }
  }

  function editVariants(question_index: number, variant_index: number, value: string) {
    if (!!questions[question_index].props) {
      //@ts-ignore
      questions[question_index].props["options"][variant_index] = value
      setQuestions([...questions])
    }
  }

  function editQuestion(field: keyof infoType, newValue: string | boolean | number | string[], index: number) {
    if (field === "props") {
      if (!questions[index].props) questions[index]["props"] = { options: [] }
      //@ts-ignore
      else questions[index].props["options"] = newValue as string[]
      setQuestions([...questions])
    } else {
      //@ts-ignore
      questions[index][field] = newValue
      setQuestions([...questions])
    }
  }

  function saveChanges() {
    setIsLoading(true)
    Promise.all([
      Promise.all(
        questions.map(e => {
          if (!e.id) {
            axiosInstance.post("/content/info-fields/", { ...e, course: course_id, props: JSON.stringify(e.props) })
          } else axiosInstance.put(`/content/info-fields/${e.id}/`, { ...e, props: JSON.stringify(e.props) })
        })
      ),
      Promise.all(
        removedFields.map(e => {
          axiosInstance.delete(`/content/info-fields/${e}`)
        })
      ),
    ])
      .then(res => {
        setTimeout(() => {
          setRemovedFields([])
          setError(false)
          setStatusText("Изменения сохранены")
          clearStatus()
          loadCourse()
          setIsLoading(false)
        }, 10000)
      })
      .catch(err => {
        // Если что-то не сохранилось
        setStatusText("Изменения частично сохранены/не сохранены")
        setError(true)
        clearStatus()
        setIsLoading(false)
      })
  }

  function clearStatus() {
    setTimeout(() => {
      setError(false)
      setStatusText("")
    }, 4000)
  }

  function handleChangeAutocomplete(e: AutocompleteEvent, value: OptionType | null, name: string) {
    !!value && setQuestionTypeForCreate({ label: value["label"] as string, value: value["value"] as string })
  }

  return (
    <Styled.MainEditorContainer>
      <Typography variant={"h4"}>Настройки анкеты</Typography>
      <Questions
        deleteVariant={deleteVariant}
        editVariants={editVariants}
        removedFields={removedFields}
        setRemovedFields={setRemovedFields}
        questions={questions}
        editQuestion={editQuestion}
        setQuestions={setQuestions}
      />
      <Styled.CreateQuestionContainer>
        <Autocomplete
          style={{ width: "300px" }}
          value={questionTypeForCreate?.label || ""}
          options={questionTypes}
          label={"Тип вопроса"}
          onChange={handleChangeAutocomplete}
        />
        <Button variant={"outlined"} color={"primary"} disabled={!questionTypeForCreate} onClick={pushNewQuestion}>
          Добавить вопрос
          <AddIcon sx={{ height: "100%", padding: 0 }} />
        </Button>
      </Styled.CreateQuestionContainer>
      <Styled.SaveButtonContainer>
        <Button onClick={() => saveChanges()} variant={"contained"} color={"primary"}>
          Сохранить изменения
        </Button>
        {isLoading && <CircularProgress />}
        {!isLoading && statusText.length > 0 && <RequestMessage error={error} text={statusText} />}
      </Styled.SaveButtonContainer>
    </Styled.MainEditorContainer>
  )
}
