import React, { useState } from "react"
import { styled } from "@mui/material"
import { Button, TextField } from "shared/ui"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import { createModule } from "entities/Module"
import { ModuleType } from "entities/Module/api/module.types"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { trajectoryActions } from "shared/store"
import AddIcon from "@mui/icons-material/Add"
import { useAppSelector } from "../../shared/hooks/useAppSelector"

export const CreateModule = ({ stage_id, last_index }: { stage_id: number; last_index: number }) => {
  const dispatch = useAppDispatch()
  const content_type = useAppSelector(state => state.trajectory.course_badge)
  const [value, setValue] = useState("")
  const [error, setError] = useState(true)
  const [text, setText] = useState("")

  function handleChangeTextField(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  function handleClickButton() {
    let t = () => {
      if (content_type === null) return "content"
      if (content_type === "online") return "online"
      if (content_type === "offline") return "offline"
      if (content_type === "hybrid") return "hybrid"
      return "content"
    }
    let type = t()
    createModule(value, stage_id, !!last_index ? last_index : 1, type)
      .then((res: { data: ModuleType }) => {
        setError(false)
        setText("Модуль создан")
        dispatch(trajectoryActions.pushNewModule(res.data))
      })
      .catch(err => {
        setError(true)
        if (err.response.status === 409) {
          setText("Вы не можете создать более одного модуля.")
        } else {
          setText("Ошибка")
        }
      })
  }

  return (
    <CreateModuleMainDiv>
      <TextField
        style={{ width: "300px" }}
        variant={"outlined"}
        label={"Название модуля"}
        value={value}
        onChange={handleChangeTextField}
      />
      <Button variant={"outlined"} color={"primary"} disabled={value.length === 0} onClick={handleClickButton}>
        Добавить новый модуль
        <AddIcon sx={{ height: "100%", alignSelf: "center", padding: 0, marginLeft: 2 }} color={"primary"} />
      </Button>
      {text.length > 0 && <RequestMessage error={error} text={text} />}
    </CreateModuleMainDiv>
  )
}

const CreateModuleMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
  marginTop: theme.spacing(12),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}))
