import * as Styled from "./ManageCourseStats.style"
import { useState } from "react"
import { Button, Dropdown, StatusMessage } from "shared/ui"
import { formCategories, formStages, formatEnum, newActivityEnum } from "entities/Course/lib/CourseStatic.js"
import { useManageStats } from "entities/Course/model/useManageStats"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import InfoTooltip from "shared/ui/InfoTooltip"
import WarningDownloadModal from "./WarningDownloadModal"
import WarningNotifyModal from "./WarningNotifyModal"
import WarningAccessModal from "./WarningAccessModal"

const tooltipText =
  "После нажатия кнопки будет загружена только часть таблицы, отображённая ниже. Чтобы отобразить больше участников в таблице, прокрутите страницу вниз до появления значка прогрузки. Количество прогруженных участников указано в заголовке таблицы."

const accessOptions = {
  Этап: "Этап",
  Модуль: "Модуль",
}

const ManageStats = ({
  isLoading: isLoadingStatistic,
  stages,
  scores,
  progress,
  selectedCourse,
  availableCategories,
  personalModules,
  filterCount,
}) => {
  const [isDownloadWarning, setIsDownloadWarning] = useState(false)
  const [isNotifyWarning, setIsNotifyWarning] = useState(false)
  const [isAccessWarning, setIsAccessWarning] = useState(false)
  const {
    getRecieviers,
    newCategory,
    stage,
    setStage,
    access,
    setAccess,
    setNewCategory,
    isLoading,
    isActive,
    setIsActive,
    setDownloadType,
    updateActive,
    updateCategory,
    downloadType,
    successMessage,
    errorMessage,
    handleWarning,
    download,
    onDownload,
    onAccess,
    handleCloseAccess,
    handleAccessFunc,
    giveAccess,
    removeAccess,
    completedStatus,
    setCompletedStatus,
    updateCompletedStatus,
  } = useManageStats(progress, scores, filterCount, selectedCourse, setIsDownloadWarning, setIsAccessWarning)
  const isModerator = selectedCourse.status === "moderator"
  const isPersonalModules = personalModules ? Object.keys(personalModules).length : false

  return (
    <>
      <WarningDownloadModal
        isOpen={isDownloadWarning}
        onClose={() => setIsDownloadWarning(false)}
        onSubmit={onDownload}
        filterCount={filterCount}
        userCount={progress.length}
      />
      <WarningNotifyModal
        isOpen={isNotifyWarning}
        onClose={() => setIsNotifyWarning(false)}
        filterCount={filterCount}
        userCount={progress.length}
        messageTitle={selectedCourse?.label}
        users={getRecieviers()}
        courseId={selectedCourse?.id}
      />
      <WarningAccessModal
        isOpen={isAccessWarning}
        onClose={handleCloseAccess}
        onSubmit={onAccess}
        filterCount={filterCount}
        userCount={progress.length}
      />
      <Styled.Grid>
        <Styled.GridItem>
          <Styled.SubTitle variant="h4">Скачать таблицу статистики</Styled.SubTitle>
          <Styled.Row>
            <Button
              variant="outlined"
              onClick={() => handleWarning("download", download, setIsDownloadWarning)}
              disabled={isLoadingStatistic}
            >
              СКАЧАТЬ СТАТИСТИКУ
            </Button>
            <Dropdown
              id={"download-type-select"}
              name={"participant__isActive"}
              value={downloadType}
              onChange={e => setDownloadType(e.target.value)}
              disabled={!selectedCourse?.id}
              options={formatEnum}
              label={"Формат"}
              style={{ width: 115 }}
            />
          </Styled.Row>
          <Styled.DescriptionRow>
            <Styled.Description style={{ maxWidth: "337px" }}>
              После нажатия кнопки будет загружена только часть таблицы, отображённая ниже.
            </Styled.Description>
            <InfoTooltip text={tooltipText} />
          </Styled.DescriptionRow>
        </Styled.GridItem>
        {isModerator && (
          <Styled.GridItem sx={{ gridColumn: "2", gridRow: "1 / 3" }}>
            <Styled.SubTitle variant="h4" sx={{ marginBottom: 7 }}>
              Выдача доступов
            </Styled.SubTitle>
            <Styled.BalanceRow sx={{ marginTop: 7, marginBottom: 11 }}>
              <Styled.AccessWrapper>
                {isPersonalModules ? (
                  <Dropdown
                    id={"stage-select"}
                    name={"participant__new-stage"}
                    value={access}
                    onChange={e => {
                      setAccess(e.target.value)
                      setStage()
                    }}
                    options={accessOptions}
                    label={"Доступ"}
                    style={{ width: "112px", height: 32 }}
                  />
                ) : null}
                <Dropdown
                  id={"stage-select"}
                  name={"participant__new-stage"}
                  value={stage}
                  onChange={e => setStage(e.target.value)}
                  options={access === "Этап" ? formStages(stages) : personalModules}
                  label={access === "Этап" ? "Этап с огр. доступом" : "Персональный модуль"}
                  style={{ width: isPersonalModules ? 187 : 311 }}
                />
              </Styled.AccessWrapper>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => handleAccessFunc(giveAccess)}
                  sx={{ marginBottom: 8, width: "100%" }}
                  disabled={!stage || isLoading}
                  size={"small"}
                >
                  ДАТЬ ДОСТУП
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleAccessFunc(removeAccess)}
                  sx={{ width: "100%" }}
                  disabled={!stage || isLoading}
                  size={"small"}
                >
                  ЗАБРАТЬ ДОСТУП
                </Button>
              </div>
            </Styled.BalanceRow>
            {isModerator && availableCategories && (
              <Styled.BalanceRow sx={{ marginBottom: 17 }}>
                <Dropdown
                  id={"new-category-select"}
                  name={"participant__new-category"}
                  value={newCategory}
                  onChange={e => setNewCategory(e.target.value)}
                  options={formCategories(availableCategories)}
                  label={"Категория"}
                  style={{ minWidth: "311px" }}
                  isTranslate={false}
                />
                <Button
                  variant="outlined"
                  onClick={() => handleAccessFunc(updateCategory)}
                  disabled={!newCategory || isLoading}
                >
                  ЗАДАТЬ
                </Button>
              </Styled.BalanceRow>
            )}
            {isModerator && (
              <Styled.BalanceRow>
                <Dropdown
                  id={"new-active-select"}
                  name={"participant__new-active"}
                  value={isActive}
                  onChange={e => setIsActive(e.target.value)}
                  options={newActivityEnum}
                  label={"Статус активности"}
                  style={{ minWidth: "311px" }}
                />
                <Button
                  variant="outlined"
                  onClick={() => handleAccessFunc(updateActive)}
                  disabled={!isActive || isLoading}
                >
                  ПОДТВЕРДИТЬ
                </Button>
              </Styled.BalanceRow>
            )}
            {isModerator && (
              <Styled.BalanceRow>
                <Dropdown
                  style={{ minWidth: "311px" }}
                  onChange={e => {
                    setCompletedStatus(e.target.value)
                  }}
                  value={completedStatus}
                  options={{ "Не выбрано": null, Пройдено: true, "Не пройдено": false }}
                  label={"Задать статус прохождения"}
                />
                <Button
                  onClick={updateCompletedStatus}
                  variant={"outlined"}
                  color={"primary"}
                  disabled={completedStatus === null && !isLoading}
                >
                  Подтвердить
                </Button>
              </Styled.BalanceRow>
            )}
            <StatusMessage isLoading={isLoading} successMessage={successMessage} errorMessage={errorMessage} />
          </Styled.GridItem>
        )}
        <Styled.GridItem>
          <Styled.SubTitle variant="h4">Отправить уведомления участникам курса</Styled.SubTitle>
          <Styled.Row sx={{ maxWidth: "293px" }}>
            <Button
              variant="outlined"
              onClick={() => setIsNotifyWarning(true)}
              startIcon={<AnnouncementIcon style={{ fontSize: "medium" }} />}
            >
              Отправить уведомление
            </Button>
          </Styled.Row>
          <Styled.Description style={{ maxWidth: "408px" }}>
            Уведомление будет отправлено только участникам курса, отображённым ниже. Убедитесь, что вы прогрузили
            необходимых участников в таблице.
          </Styled.Description>
        </Styled.GridItem>
      </Styled.Grid>
    </>
  )
}

export default ManageStats
