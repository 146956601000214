import React, { ChangeEvent, useEffect } from "react"
import { FormControl, FormControlLabel, FormLabel, TextField } from "@mui/material"
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from "react-hook-form"
import { useAppDispatch } from "../../../shared/hooks/useAppDispatch"
import { iotActions } from "../../../shared/store"

interface IProps {
  name: string
  label: string
  required: boolean
  value: string
  type: "time" | "date"
}

export const DateFormInput = ({ name, label, required, value, type }: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const dispatch = useAppDispatch()

  const errorMessage = errors[name] ? errors[name]?.message : ""

  function handleChange(
    field: ControllerRenderProps<FieldValues, string>,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { value } = event.target
    if (type === "date") {
      if (field.name === "dateStart") dispatch(iotActions.handleChangeStartDate({ date: value }))
      else dispatch(iotActions.handleChangeFinishDate({ date: value }))
    } else {
      if (field.name === "timeStart") dispatch(iotActions.handleChangeStartTime({ time: value }))
      else dispatch(iotActions.handleChangeFinishTime({ time: value }))
    }
    field.onChange(value)
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <TextField
            {...field}
            onChange={e => handleChange(field, e)}
            focused={!!field.value}
            helperText={`${errorMessage}` || ""}
            error={!!errorMessage}
            value={value}
            style={{ width: "140px" }}
            inputProps={{ type: type, max: new Date().toISOString().slice(0, 16) }}
            required={!!required}
          />
        </FormControl>
      )}
    />
  )
}
