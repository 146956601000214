import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { ThemeType } from "shared/themes/default.types"
export { ShadowedSection, SectionWrapper, boxShadow } from "shared/ui"

// TODO применить эти стили в Course.style.ts

const Header = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}))

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(10),
}))

const SubTitle = styled(Typography)(() => ({
  textAlign: "left",
  letterSpacing: "0.2px",
}))

const LightText = styled(Typography)(() => ({
  fontWeight: "300",
}))

const Helper = styled(Typography)(({ theme }: { theme?: ThemeType }) => ({
  fontSize: theme?.spacing(3.5),
  lineHeight: theme?.spacing(4.5),
  letterSpacing: "0em",
  color: theme?.palette.additional.link,
  textDecoration: `underline dashed ${theme?.palette.additional.link}`,
  textUnderlineOffset: theme?.spacing(1.25),
  minWidth: "fit-content",
  cursor: "pointer",
  ":hover": {
    opacity: "0.5",
  },
}))

const ActionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: 46,
  gap: 24,
}))

const ActionLeftWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
  flex: 1,

  "& > div:nth-child(1)": {
    flexGrow: 1,
  },
  "& > div:nth-child(2)": {
    minWidth: "clamp(152px, 10vw, 200px)",
  },
}))

const ActionRightWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
  flex: 1,
  justifyContent: "space-between",

  "& > div:nth-child(1)": {
    minWidth: "clamp(152px, 10vw, 200px)",
  },
  "& :nth-child(2)": {
    flexGrow: 1.2,
  },
}))

// TODO этот стиль не применять
const LoaderWrapper = styled("div")(() => ({
  flexShrink: 1,
  display: "inline-block",

  "& span": {
    content: "''",
    display: "block",
    width: "26px",
  },
}))

const FormWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  columnGap: theme.spacing(6),
  rowGap: 38,
  flexWrap: "wrap",
  marginBottom: 46,
}))

const FlexRow = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: theme.spacing(6),
  alignItems: "center",

  "& > button, & > div": {
    minWidth: "clamp(152px, 10vw, 200px)",
  },
}))

const FormHelper = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
}))

const TableWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(11),
}))

export {
  Header,
  Title,
  SubTitle,
  LightText,
  Helper,
  ActionLeftWrapper,
  ActionRightWrapper,
  ActionWrapper,
  LoaderWrapper,
  FormWrapper,
  FlexRow,
  FormHelper,
  TableWrapper,
}
