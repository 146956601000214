import React, { useState } from "react"
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { wordMapper } from "shared/local"

export const UserStatusSetter = ({ user }) => {
  const [newStatus, setNewStatus] = useState(null)
  const [helperText, setHelperText] = React.useState("")

  function setUserStatus() {
    if (!user) return
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios.put(`/profiles/${user.id}/status/`, { status: newStatus }).then(res => {
      setHelperText(`Set to: ${newStatus.toUpperCase()}`)
    })
  }

  return (
    <>
      <Typography variant="h5" style={{ textAlign: "center", color: "red", marginTop: 24 }}>
        {helperText}
      </Typography>
      <FormControl sx={{ minWidth: 240, marginRight: 24 }}>
        <InputLabel id="simple-select-label">Статус на платформе</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={newStatus}
          input={<OutlinedInput label={"Статус на платформе"} />}
          onChange={e => setNewStatus(e.target.value)}
        >
          <MenuItem value={"student"}>{wordMapper("student")}</MenuItem>
          <MenuItem value={"moderator"}>{wordMapper("moderator")}</MenuItem>
          <MenuItem value={"admin"}>{wordMapper("admin")}</MenuItem>
        </Select>
      </FormControl>
      <Button onClick={setUserStatus} variant="contained" color="primary">
        Назначить права
      </Button>
    </>
  )
}
