import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  videoGalleryContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: 64,
    marginBottom: 64,
  },

  titleContainer: {
    marginRight: 124,
    marginLeft: 124,
    [theme.breakpoints.down("md")]: {
      marginLeft: "100px",
      marginRight: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },

  videoGalleryContentContainer: {
    justifyContent: "space-around",
    gap: 10,
    paddingTop: 48,
    paddingLeft: 124,
    paddingRight: 124,
  },

  previewItem: {
    width: "auto",
    height: 240,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "180px",
    },
  },

  navButtons: {
    background: "transparent",
    boxSizing: "content-box",
    padding: 10,
    margin: "0 20px",
    width: 32,
    height: 32,
    backgroundColor: "transparent",
    "& svg": {
      width: "240%",
      height: "240%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
    },
  },

  dialog: {
    background: "transparent !important",
  },
}))
