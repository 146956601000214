import { Russian } from "./ru/Russian"

function getLocalization() {
  return "ru"
}

export const wordMapper = (word, customMapper) => {
  if (getLocalization() === "ru") {
    const mapper = customMapper || Russian
    return mapper[word] || word
  }
}
