import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import * as Styled from "./ui/Subscribes.style"
import { OrganizationsTable } from "./ui/OrganizationsTable"
import { axiosInstance as axios } from "shared/api/axiosInstance"
// @ts-ignore
import Cookies from "js-cookie"

export const Subscribes = () => {
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    updateOrganizations()
  }, [])

  function updateOrganizations() {
    axios.get("/organizations/my_organizations", { params: { role: "follower" } }).then(res => {
      setOrganizations(res.data)
    })
  }

  return (
    <Styled.MainDiv>
      <Typography variant={"h3"}>Отслеживаемые организации</Typography>
      <OrganizationsTable organizations={organizations} updateOrganizations={updateOrganizations} />
    </Styled.MainDiv>
  )
}
