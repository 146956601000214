import React from "react"
import * as Styled from "./Carousel.styles"
import { Box, styled, Typography } from "@mui/material"
//@ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Button } from "shared/ui"
import { useTheme } from "../../../shared/context"
import { ICarouselItem } from "../models/Carousel.types"

const TypographyTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(6),
  },
  userSelect: "text",
}))

const TypographyDescription = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(4),
  },
  userSelect: "text",
}))

export const CarouselItem = ({
  props,
  titles,
  refs,
}: {
  props: ICarouselItem | false
  titles: string[]
  refs: { current: { [key: number]: React.RefObject<any> } }
}) => {
  const theme = useTheme()

  function handleClick() {
    if (!!props) {
      if (!!props.button && !!props.button.to_block) {
        let index = props.button.to_block
        console.log(refs)
        console.log(index)
        return refs.current[index].current.scrollIntoView()
      }
    }
  }

  return (
    <>
      {!!props && (
        <Styled.LandingBackground style={{ background: props.backgroundColor || "#FFF" }}>
          <Styled.LandingContainer>
            <Styled.SloganContainer>
              <TypographyTitle variant={"h1"} sx={{ color: props.title.color || "#FFF" }}>
                <div dangerouslySetInnerHTML={{ __html: props.title.text || "" }}></div>
              </TypographyTitle>
              <TypographyDescription
                variant={"body1"}
                fontWeight={"medium"}
                fontSize={"medium"}
                sx={{ color: props.description.color || "#FFF", fontSize: 24, zIndex: 1 }}
              >
                <div dangerouslySetInnerHTML={{ __html: props.description.text || "" }}></div>
              </TypographyDescription>
              {!!props.button && (
                <Button
                  height={"large"}
                  sx={{
                    borderColor: props.button.borderColor || "none",
                    backgroundColor: props.button.backgroundColor || "#FFF",
                    color: props.button.color || "#FFF",
                    //@ts-ignore
                    "&:hover": { backgroundColor: props.button.hoverColor },
                    [theme.breakpoints.down("xs")]: { width: "100%" },
                  }}
                  onClick={handleClick}
                >
                  {props.button.text}
                </Button>
              )}
            </Styled.SloganContainer>
            <Styled.LandingImage>
              <LazyLoadImage
                wrapperProps={{ style: { objectFit: "cover" } }}
                width={"100%"}
                src={props.image}
                placeholderSrc={props.lowImage || ""}
                effect="blur"
              />
            </Styled.LandingImage>
          </Styled.LandingContainer>
        </Styled.LandingBackground>
      )}
    </>
  )
}
