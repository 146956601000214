import { styled } from "@mui/material/styles"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import { TableCell, TableContainer } from "@mui/material"

export const MainDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(12),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
}))

export const ChoiseCourseMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
}))

export const ChoiseCourseDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: theme.spacing(6),
}))

export const ChoiseStatusMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginTop: theme.spacing(8),
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(6),
  },
}))

export const ChoiseStatusDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(6),
  alignItems: "flex-end",
  justifyContent: "flex-start",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(6),
  },
}))

export const NotificationIcon = styled(AnnouncementIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export const ParticipantMainTableContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
}))

export const ParticipantTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "80vh",
  background: theme.palette.background.paper,
}))

export const TableBlockWrapped = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(7),
}))

export const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))
