import React, { useState } from "react"
import { Button, Modal, TextField } from "shared/ui"
import * as Styled from "./ui/Iot.styles"
import { RequestMessage } from "../../shared/assets/RequestMessage/RequestMessage"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
//@ts-ignore
import Cookies from "js-cookie"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { fetchAllowedHardware, fetchMyHardware } from "../../entities/Iot"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

interface newHardwareType {
  name: string
  serial: string
}

export const AddHardware = ({ isOpen, onClose }: IProps) => {
  const [newHardware, setNewHardware] = useState<newHardwareType>({ name: "", serial: "" })
  const [error, setError] = useState(true)
  const [text, setText] = useState("")

  const dispatch = useAppDispatch()

  function handleChangeField(event: React.ChangeEvent<HTMLInputElement>, field: "name" | "serial") {
    newHardware[field] = event.target.value
    setNewHardware({ ...newHardware })
  }

  function registerHardware() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post("/hardware/register/", { ...newHardware, type: "flower" })
      .then(res => {
        dispatch(fetchMyHardware())
        dispatch(fetchAllowedHardware())
        setError(false)
        setText("Устройство добавлено")
      })
      .catch(err => {
        setError(true)
        if (err.response?.data?.detail === "Your device is already registered.") setText("Устройство уже добавлено")
        else setText("Неправильный серийный номер")
      })
  }

  return (
    <Modal size={"default"} error={""} isOpen={isOpen} onClose={onClose} title={"Добавление нового устройства"}>
      <Styled.DialogMainContainer>
        <TextField
          /*@ts-ignore*/
          value={newHardware.serial}
          style={{ width: "300px" }}
          label={"Серийный номер"}
          variant={"outlined"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeField(e, "serial")}
        />
        <TextField
          /*@ts-ignore*/
          value={newHardware.name}
          style={{ width: "300px" }}
          label={"Название"}
          variant={"outlined"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeField(e, "name")}
        />
        {/*@ts-ignore*/}
        <Button variant={"outlined"} color={"primary"} onClick={registerHardware}>
          Добавить
        </Button>
        {text.length > 0 && <RequestMessage error={error} text={text} />}
      </Styled.DialogMainContainer>
    </Modal>
  )
}
