import React from "react"
import { styled } from "@mui/material/styles"
import { Chip, Typography } from "@mui/material"

export const EventChip = ({ label, variant }: { label: string; variant: "type" | "tag" }) => {
  return (
    <StyledChip
      sx={variant === "tag" ? { border: "1px solid  #CBCBCB" } : {}}
      label={
        <Typography variant={"caption"} fontWeight={"medium"} sx={{ color: "#313439" }}>
          {label}
        </Typography>
      }
    />
  )
}

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "1px solid #858585",
  borderRadius: theme.spacing(4),
}))
