import React, { useState, useMemo, useEffect } from "react"
import { Button, Menu, styled, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"

import NotificationsIcon from "@mui/icons-material/Notifications"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import notificationBellWhite from "../../images/icons/bell_white.svg"
import notificationBellBlue from "../../images/icons/bell_blue.svg"

import { useUserState } from "shared/context"
import { NotificationItem } from "entities/Notification"

const CustomMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    border: "2px solid #FBFBFB",
    boxShadow: "0px 0px 10px rgba(54, 54, 54, 0.1)",
    borderRadius: "6px",
    minWidth: 600,
    maxWidth: 800,
    maxHeight: "90vh",
    overflowY: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiMenu-paper": {
      minWidth: "70%",
      maxWidth: "95%",
      maxHeight: "50%",
      overflowY: "auto",
    },
  },
}))

export const Notifications = props => {
  const { user, isAuthenticated } = useUserState()

  const [anchorNotifications, setAnchorNotifications] = useState(null)
  const [notifications, setNotifications] = useState([])

  const hasNewNotification = useMemo(() => {
    return notifications.some(e => !e.is_received)
  }, [notifications])

  function sendReceived() {
    axios.get("/notifications/confirm_received/")
  }

  const toggleNotifications = event => {
    setAnchorNotifications(event.currentTarget)
    sendReceived()
  }

  const closeNotifications = () => {
    setAnchorNotifications(null)
    setNotifications([...notifications.map(e => ({ ...e, is_received: true }))])
  }

  useEffect(() => {
    if (isAuthenticated) {
      axios.get("/notifications/my_notifications/").then(res => {
        setNotifications(res.data.sort((a, b) => (a.creation_time < b.creation_time ? 1 : -1)))
      })
    }
  }, [user])

  return (
    <>
      <Button onClick={toggleNotifications} color="inherit" size="large" disableRipple sx={{ right: "4%" }}>
        {Boolean(anchorNotifications) ? (
          <NotificationsIcon fontSize="large" style={{ color: props.color }} />
        ) : hasNewNotification ? (
          // <NotificationsActiveIcon fontSize="large" style={{ color: props.color }} />
          props.color === "white" ? (
            <img src={notificationBellWhite} style={{ width: 30 }} />
          ) : (
            <img src={notificationBellBlue} style={{ width: 30 }} />
          )
        ) : (
          <NotificationsNoneIcon fontSize="large" style={{ color: props.color }} />
        )}
      </Button>
      <CustomMenu
        id="notifications-menu"
        open={Boolean(anchorNotifications)}
        anchorEl={anchorNotifications}
        onClose={closeNotifications}
        disableAutoFocusItem
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ zIndex: 8888 }}
      >
        <Typography variant="h4" style={{ padding: 24, borderBottom: "1px solid #E0E0E0" }}>
          Уведомления
        </Typography>
        {notifications.map(e => (
          <NotificationItem {...e} key={e.id} />
        ))}
      </CustomMenu>
    </>
  )
}
