import { styled } from "@mui/material/styles"
import AnnouncementIcon from "@mui/icons-material/Announcement"

export const HeaderCell = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const SortIcons = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}))

export const NotificationIcon = styled(AnnouncementIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export const ParticipantTableContainer = styled("div")(({ theme }) => ({
  position: "relative",
  marginBottom: "64px",
}))
