import * as Styled from "entities/Invitation/ui/Invitations.style"
import * as Static from "entities/Invitation/static/Invitations"
import SendRequest from "features/Teams/SendRequest"
import InvitationTable from "entities/Team/ui/InvitationTable"
import useInvitations from "entities/Invitation/model/useInvitations"
import { CircularProgress, Typography } from "@mui/material"
import { SectionWrapper } from "shared/ui"

export const Invitations = () => {
  const { incoming, requests, sents, isLoading } = useInvitations()

  return (
    <Styled.BaseDiv>
      <SendRequest />
      {isLoading ? (
        <CircularProgress color={"primary"} />
      ) : (
        <>
          <SectionWrapper>
            <Typography variant={"h3"}>Командные приглашения</Typography>
          </SectionWrapper>
          {incoming.length !== 0 && (
            <InvitationTable
              id={"income_notification"}
              title={Static.incomingTitle}
              headers={Static.incomingHeaders}
              data={incoming}
              isActions={true}
            />
          )}
          {requests.length !== 0 && (
            <InvitationTable
              id={"request_notification"}
              title={Static.requestTitle}
              headers={Static.requestHeaders}
              data={requests}
            />
          )}
          {sents.length !== 0 && (
            <InvitationTable
              id={"sent_notification"}
              title={Static.sentTitle}
              headers={Static.sentHeaders}
              data={sents}
            />
          )}
        </>
      )}
    </Styled.BaseDiv>
  )
}
