import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Button, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import { useUserState } from "shared/context"
import { Header, Questionnaire } from "widgets"
import { Card } from "shared/ui"

const FormatLinkInvitation = props => {
  const [text, setText] = useState("Some text")
  const [formFields, setFormFields] = useState(null)
  const [formDescription, setFormDescription] = useState(null)
  const [formValues, setFormValues] = useState([])
  const [isSent, setIsSent] = useState(false)
  const { isAuthenticated, user, login } = useUserState()
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    if (isAuthenticated) setAuth(true)
    else if (!isAuthenticated) {
      login.setOpen(true)
      setAuth(true)
    }
  }, [])

  useEffect(() => {
    let params = window.location.href.split("?")[1].split("&")
    let course = params[0].split("=")[1]
    axios
      .get(`/content/courses/`, { params: { id: course } })
      .then(res => {
        let data = res.data[0]
        if (!!data.use_info) {
          setFormFields(data.info_fields.filter(e => !!e.visible))
          setFormDescription(data.info_description)
        } else {
          sendInfo(false)
        }
      })
      .catch(err => {
        setText("Ошибка")
        setIsSent(true)
      })
  }, [user])

  function sendInfo(hasInfo) {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    let params = window.location.href.split("?")[1].split("&")
    let course = params[0].split("=")[1]
    let hash = params[1].split("=")[1]
    axios
      .post("/content/actions/courses/invitation/", {
        course: course,
        token: hash,
        info: hasInfo ? JSON.stringify(formValues) : "[]",
      })
      .then(res => {
        setText(`Вы зарегистрированы на мероприятие "${res.data.title}"`)
        setIsSent(true)
      })
      .catch(err => {
        if (err.response.status == 400) {
          setText("Вы уже зарегистрированы на мероприятии")
        } else if (err.response.status == 403) {
          setText("Отказано в доступе")
        } else {
          setText("Ошибка")
        }
        setIsSent(true)
      })
  }

  if (isSent) {
    return (
      <>
        <Header variant={"shadow"} />
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "50%",
            gap: 20,
            padding: 81,
            margin: "auto",
            marginTop: 100,
          }}
        >
          <Typography variant="h5">{text}</Typography>
          <a href="/">На главную</a>
        </Card>
      </>
    )
  }
  return (
    <>
      <Header variant={"shadow"} />
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "50%",
          gap: 20,
          padding: 81,
          margin: "auto",
          marginTop: 100,
        }}
      >
        <Typography variant="h2" style={{ fontWeight: 600, marginBottom: 12 }}>
          Регистрация на мероприятие
        </Typography>
        <Typography variant="h5" style={{ fontWeight: 400 }}>
          Для регистрации на участие в мероприятии, заполните информацию.
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: formDescription }} style={{ width: "100%" }} />
        {formFields && (
          <div style={{ alignSelf: "flex-start" }}>
            <Questionnaire fields={formFields} setValues={setFormValues} />
          </div>
        )}
        <Button
          onClick={() => sendInfo(true)}
          disabled={
            !formValues
              .filter(e => !!e.is_required)
              .reduce((totalCorrect, field) => totalCorrect && !!field.is_required && !field.value, true)
          }
          variant="contained"
          color="primary"
          size="large"
          style={{ width: 240, height: 50, textTransform: "uppercase", marginTop: 16 }}
        >
          Подать заявку
        </Button>
      </Card>
    </>
  )
}

export default withRouter(FormatLinkInvitation)
