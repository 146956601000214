import { styled } from "@mui/material/styles"

export const EditOrganizationMainDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(12),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(10),
}))

export const EditCategory = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(10),
  width: "100%",
}))

export const SendInvitationFoModeratorMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}))

export const CopyLinkDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing(6),
}))

export const AddUserDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: theme.spacing(6),
  width: "100%",
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(6),
}))

export const GenerateLinkDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  width: "30%",
}))

export const CopyBlockDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  width: "auto",
  marginBottom: theme.spacing(6),
  marginTop: theme.spacing(6),
}))

export const CreateCourseMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  height: "100%",
}))

export const TypeOfCourseDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: theme.spacing(4),
}))

export const TypeOfCourseItem = styled("div")(({ theme, props }) => ({
  width: "48%",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  padding: `${theme.spacing(10)} ${theme.spacing(8)}`,
  borderRadius: theme.spacing(4),
}))
