import styled from "@emotion/styled"
import * as Styled from "./ExaminationModal.styles"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { assignmentsActions } from "shared/store"
import { IAssingment } from "entities/Assignment/model/assignment.types"
import { SliderButton } from "./SliderButton"
import { fetchAssignments } from "shared/store"
import { useAppSelector } from "shared/hooks/useAppSelector"
import useSliderButtons from "entities/Assignment/model/useSliderButtons"
import { useCallback } from "react"

interface IProps {
  assignments: { data: IAssingment[]; hasNextPage: boolean; isLoading: boolean; page: number; isLoaded: boolean }
  assignmentIndex: number
  warning: string
  preventClose: (x: () => void) => void
}

const ExaminationSlider = ({ assignments, assignmentIndex, warning, preventClose }: IProps) => {
  const { filters } = useAppSelector(state => state.assignment)
  const { data, page, isLoading, hasNextPage } = assignments
  const prevAssignment = data.find((assignment, index) => index === assignmentIndex - 1)
  const nextAssignment = data.find((assignment, index) => index === assignmentIndex + 1)
  const isPrev = assignmentIndex !== 0
  const isNext = assignmentIndex !== data.length - 1
  const dispatch = useAppDispatch()

  const onPrev = useCallback(() => {
    if (isPrev) dispatch(assignmentsActions.prevAssignment())
  }, [isPrev])

  const onNext = useCallback(async () => {
    if (assignmentIndex === data.length - 1 && hasNextPage) {
      dispatch(fetchAssignments({ ...filters, page }))
      return
    }
    if (isNext) dispatch(assignmentsActions.nextAssignment())
  }, [assignmentIndex, isNext])

  useSliderButtons({
    assignmentIndex,
    preventClose,
    onPrev,
    onNext,
    isPrev,
    isNext,
    hasNextPage,
    isLoading,
  })

  return (
    <Styled.SliderWrapper>
      {warning && <Warning>{warning}</Warning>}
      <Buttons>
        <SliderButton
          onClick={() => isPrev && preventClose(onPrev)}
          isDisabled={!isPrev}
          direction={"left"}
          name={prevAssignment?.participant.full_name || ""}
          email={prevAssignment?.participant.email || ""}
        />
        <SliderButton
          onClick={() => !isLoading && preventClose(onNext)}
          isDisabled={!isNext && !hasNextPage}
          direction={"right"}
          name={nextAssignment?.participant.full_name || ""}
          email={nextAssignment?.participant.email || ""}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
        />
      </Buttons>
    </Styled.SliderWrapper>
  )
}

const Buttons = styled.div`
  display: flex;
`

const Warning = styled.div`
  color: ${props => props.theme.palette.text.error};
  font-size: 16px;
  font-weight: 300;
  line-height: 155%;
  margin-top: 16px;
  margin-bottom: 3px;
  text-align: center;
`

export { ExaminationSlider }
