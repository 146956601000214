export const sensorsConfig: { [key: string]: object } = {
  gpio_adc: {
    firstColor: "#EA4228",
    secondColor: "#F5CD19",
    thirdColor: "#5BE12C",
    fourthColor: "#EA4228",
    maxValue: 8,
    minValue: 0,
    firstLimit: 0,
    secondLimit: 3,
    thirdLimit: 5,
    fourthLimit: 8,
    firstTick: 0,
    secondTick: 8,
    units: " В",
  },
  temperature: {
    firstColor: "#EA4228",
    secondColor: "#F5CD19",
    thirdColor: "#5BE12C",
    fourthColor: "#F5CD19",
    maxValue: 35,
    minValue: 10,
    firstLimit: 15,
    secondLimit: 20,
    thirdLimit: 25,
    fourthLimit: 27,
    firstTick: 10,
    secondTick: 35,
    units: " ºC",
  },
  orp: {
    firstColor: "#EA4228",
    secondColor: "#F5CD19",
    thirdColor: "#5BE12C",
    fourthColor: "#F5CD19",
    maxValue: 500,
    minValue: 80,
    firstLimit: 150,
    secondLimit: 180,
    thirdLimit: 400,
    fourthLimit: 500,
    firstTick: 80,
    secondTick: 0,
    units: " мВ",
  },
  es: {
    firstColor: "#5BE12C",
    secondColor: "#F5CD19",
    thirdColor: "#EA4228",
    fourthColor: "#EA4228",
    maxValue: 800,
    minValue: 0,
    firstLimit: 500,
    secondLimit: 600,
    thirdLimit: 700,
    fourthLimit: 800,
    firstTick: 0,
    secondTick: 800,
    units: " мкСм/см",
  },
  salt: {
    firstColor: "#EA4228",
    secondColor: "#EA4228",
    thirdColor: "#5BE12C",
    fourthColor: "#EA4228",
    maxValue: 40,
    minValue: 10,
    firstLimit: 10,
    secondLimit: 20,
    thirdLimit: 30,
    fourthLimit: 40,
    firstTick: 10,
    secondTick: 40,
    units: " º",
  },
  conductivity: {
    firstColor: "#EA4228",
    secondColor: "#F5CD19",
    thirdColor: "#5BE12C",
    fourthColor: "#F5CD19",
    maxValue: 30,
    minValue: 4,
    firstLimit: 10,
    secondLimit: 14,
    thirdLimit: 20,
    fourthLimit: 25,
    firstTick: 4,
    secondTick: 30,
    units: " С",
  },
  tds: {
    firstColor: "#5BE12C",
    secondColor: "#5BE12C",
    thirdColor: "#F5CD19",
    fourthColor: "#EA4228",
    maxValue: 400,
    minValue: 0,
    firstLimit: 0,
    secondLimit: 250,
    thirdLimit: 300,
    fourthLimit: 400,
    firstTick: 0,
    secondTick: 400,
    units: " ppm",
  },
  ph: {
    firstColor: "#EA4228",
    secondColor: "#EA4228",
    thirdColor: "#5BE12C",
    fourthColor: "#EA4228",
    maxValue: 8.5,
    minValue: 5,
    firstLimit: 5,
    secondLimit: 6,
    thirdLimit: 7.5,
    fourthLimit: 8.5,
    firstTick: 5,
    secondTick: 8.5,
    units: "",
  },
  dissolvedOxygen: {
    firstColor: "#EA4228",
    secondColor: "#EA4228",
    thirdColor: "#5BE12C",
    fourthColor: "#EA4228",
    maxValue: 20,
    minValue: 0,
    firstLimit: 0,
    secondLimit: 5,
    thirdLimit: 15,
    fourthLimit: 20,
    firstTick: 0,
    secondTick: 20,
    units: " мг/мл",
  },
  concentrationOxygen: {
    firstColor: "#EA4228",
    secondColor: "#EA4228",
    thirdColor: "#5BE12C",
    fourthColor: "#EA4228",
    maxValue: 250,
    minValue: 30,
    firstLimit: 30,
    secondLimit: 80,
    thirdLimit: 200,
    fourthLimit: 250,
    firstTick: 30,
    secondTick: 250,
    units: " %",
  },
}
