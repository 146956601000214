import { styled } from "@mui/material"
import { ShadowedSection } from "../../../shared/ui"

export const TagsMainDiv = styled("div")(({ theme }) => ({
  padding: `0 ${theme.spacing(31)}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
  width: "100%",
}))

export const TagsListMainDiv = styled(ShadowedSection)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: theme.spacing(6),
  flexDirection: "row",
}))
