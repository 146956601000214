import React, { useState } from "react"
import { Dialog, DialogContent, Grid } from "@mui/material"
import * as Styled from "./styles"

const GalleryItem = ({ e }: { e: { url: string } }) => {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Styled.ImageContainer
        src={e.url}
        onClick={() => {
          setOpenDialog(true)
        }}
      />
      <Dialog
        aria-labelledby="DialogTitle"
        aria-describedby="DialogDescription"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="md"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Set transparent black background for the backdrop
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          }, // Set transparent background for the dialog content
        }}
      >
        <DialogContent
          style={{
            backgroundColor: "rgba(255, 0, 0, 0)",
          }}
        >
          <img
            alt="Full size"
            style={{
              width: "100%",
              height: "auto",
            }}
            src={e.url}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default GalleryItem
