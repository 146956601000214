import { useState, useEffect } from "react"

function storageTab(value, tab, course) {
  let resultTab = value
  if (tab === "stages_tab") {
    const storagedTab = JSON.parse(localStorage.getItem(tab))
    resultTab = {
      ...storagedTab,
      [course]: resultTab,
    }
  }
  localStorage.setItem(tab, JSON.stringify(resultTab))
}

function getStoragedTab(initial, tab, course) {
  let result = localStorage.getItem(tab) || initial
  if (tab === "stages_tab") {
    const stagesTab = JSON.parse(localStorage.getItem(tab))
    if (stagesTab) {
      result = JSON.parse(localStorage.getItem(tab))[course] || initial
    }
  }
  return result
}

export const useTab = (tabName, courseName) => {
  let initialTab
  switch (tabName) {
    case "admin_tab":
      initialTab = 1
      break
    case "iot_tab":
      initialTab = 0
      break
    case "stages_tab":
      initialTab = null
      break
    case "organizations_control_tab":
      initialTab = 0
      break
    default:
      initialTab = 0
      break
  }
  const [tab, setTab] = useState(initialTab)

  const changeTab = (e, newValue) => {
    storageTab(newValue, tabName, courseName)
    setTab(newValue)
  }

  useEffect(() => {
    const storagedTab = getStoragedTab(initialTab, tabName, courseName)
    setTab(Number(storagedTab))
  }, [])

  return {
    tab,
    changeTab,
  }
}
