import { DialogActions, DialogContent } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
// @ts-ignore
import themes from "shared/themes"
import styled from "@emotion/styled"
export { DialogTitle as DialogHeader } from "@mui/material"

export const DialogStyle = (isSmall: boolean): object => ({
  style: {
    display: "inline-block",
    boxShadow: "none",
    minWidth: 960,
    minHeight: isSmall ? 304 : 657,
    maxHeight: 657,
    borderRadius: "12px",
    overflowY: "hidden",
    // @ts-ignore
    backgroundColor: themes.default.palette.additional.modal,
    // @ts-ignore
    border: `1px solid ${themes.default.palette.additional.modalBorder}`,
  },
})

export const CloseWrapper = styled(DialogActions)(() => ({
  position: "absolute",
  right: 0,
  top: 0,
}))

export const Close = styled(ClearIcon)(() => ({
  cursor: "pointer",
  ":hover": { opacity: 0.5 },
}))

export const ContentWrapper = styled(DialogContent)(() => ({
  padding: 0,
}))

export const LoaderWrapper = styled("div")(({ theme }) => ({
  width: "26px",
  padding: theme.spacing(6),
  marginInline: "auto",
}))
