import { styled } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  EventPageContentConteiner: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {},
  },
  EventPageMenuConteiner: {
    paddingRight: 124,
    paddingLeft: 124,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      "& button": {
        padding: "0",
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  EventPageAboutConteiner: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 124,
    paddingLeft: 124,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPageAboutTitle: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 48,
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
      paddingTop: 24,
      paddingBottom: 24,
      paddingRight: 0,
    },
  },
  EventPageAboutText: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 0,
    paddingLeft: 48,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 0,
    },
  },
  EventPageAboutImage: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 0,
    paddingLeft: 0,
    minHeight: "66.6vw",
    "& span": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
      minHeight: "5vh",
    },
  },
  EventPageAboutImg: {
    width: "100%",
    /*height:"100vh",*/
  },
  EventPageH2: {
    paddingTop: 48,
    paddingBottom: 0,
    paddingRight: 124,
    paddingLeft: 124,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPageCalendar: {
    paddingTop: 96,
    paddingBottom: 96,
    paddingRight: 124,
    paddingLeft: 124,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPageCalendarGrid: {
    width: "100%",
    margin: 0,
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      /*flexDirection: "column",*/
      border: "none !important",
      "& div.MuiGrid-item": {
        paddingTop: "0 !important",
        paddingBottom: "20px !important",
        border: "none !important",
      },
      "& div.MuiGrid-grid-xs-4": {
        borderBottom: "1px solid #FA5D55 !important",
      },
    },
  },
  EventPageConteiner: {
    paddingTop: 0,
    paddingBottom: 48,
    paddingRight: 124,
    paddingLeft: 124,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPagePartners: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#FA5D55",
    marginTop: 48,
    marginBottom: 48,
    padding: 100,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 48,
      paddingBottom: 48,
      gap: 20,
    },
    [theme.breakpoints.down("sm")]: {
      /*flexDirection: "column",*/
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 28,
      paddingBottom: 28,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPageLogoPartner: {
    margin: 16,
    height: 76,
    [theme.breakpoints.down("sm")]: {
      margin: 12,
      height: 60,
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
    },
  },
  EventPageTeamConteiner: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 124,
    paddingLeft: 124,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPageTeamTitle: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 48,
    paddingLeft: 0,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
      paddingTop: 24,
      paddingBottom: 24,
      paddingRight: 0,
    },
  },
  EventPageTeam: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 0,
    paddingLeft: 48,
    [theme.breakpoints.down("md")]: {
      gap: 40,
      justifyContent: "space-between",
      marginLeft: 0,
      maxWidth: "100%",
      minWidth: "100%",
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      gap: 20,
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
    },
    "& div": {
      minWidth: 260,
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "0!important",
        flexBasis: "40%",
        /*flexGrow: 0,*/
        maxWidth: "40%",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: 216,
      },
    },
  },
  EventPageReview: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingRight: 0,
    paddingLeft: 48,
    [theme.breakpoints.down("lg")]: {
      "& div.MuiPaper-elevation div div:nth-child(1), div.MuiPaper-elevation div div:nth-child(3), div.MuiPaper-elevation div div:nth-child(4), div.MuiPaper-elevation div div:nth-child(6)":
        {
          flexBasis: "10%",
          maxWidth: "10%",
        },
      "& div.MuiPaper-elevation div div:nth-child(2), div.MuiPaper-elevation div div:nth-child(5)": {
        flexBasis: "80%",
        maxWidth: "80%",
      },
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
      paddingTop: 24,
      paddingBottom: 24,
      paddingRight: 0,
      paddingLeft: 0,
      "& div.MuiPaper-elevation div div:nth-child(1), div.MuiPaper-elevation div div:nth-child(3), div.MuiPaper-elevation div div:nth-child(4), div.MuiPaper-elevation div div:nth-child(6)":
        {
          display: "none",
        },
      "& div.MuiPaper-elevation div div:nth-child(2), div.MuiPaper-elevation div div:nth-child(5)": {
        flexBasis: "100%",
        maxWidth: "100%",
        position: "relative",
      },
      "& div.MuiPaper-elevation div div:nth-child(2):before": {
        width: 8,
        height: 70,
        backgroundColor: "rgb(250, 93, 85)",
        display: "block",
        position: "absolute",
        left: 0,
        top: 50,
        content: "''",
      },
    },
  },
  EventPageBottomConteiner: {
    width: "100%",
    height: "auto",
    padding: "112px 124px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "start center",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  EventPageBottomBlock: {
    width: "100%",
    padding: 64,
    borderRadius: 6,
    fontSize: "1.4em",
    [theme.breakpoints.down("md")]: {
      padding: 40,
      fontSize: "1.2em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  EventPageBottomGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": {
      minWidth: 220,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
}))
