import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
export { ShadowedSection, SectionWrapper, boxShadow } from "shared/ui"

const Header = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}))

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(10),
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
}))

const LightText = styled(Typography)(() => ({
  fontWeight: "300",
}))

const Helper = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  lineHeight: theme.spacing(4.5),
  letterSpacing: "0em",
  color: theme.palette.additional.link,
  textDecoration: `underline dashed ${theme.palette.additional.link}`,
  textUnderlineOffset: theme.spacing(1.25),
  minWidth: "fit-content",
  cursor: "pointer",
  ":hover": {
    opacity: "0.5",
  },
}))

const ActionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(6),
  marginBottom: theme.spacing(10),
  alignItems: "center",
}))

const LoaderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
}))

const FormWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  columnGap: theme.spacing(6),
  rowGap: theme.spacing(10),
  flexWrap: "wrap",
  marginBottom: theme.spacing(13),
}))

const FlexRow = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: theme.spacing(6),
  alignItems: "center",
}))

const FormHelper = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
}))

const TableWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(11),
}))

export {
  Header,
  Title,
  SubTitle,
  LightText,
  Helper,
  ActionWrapper,
  LoaderWrapper,
  FormWrapper,
  FlexRow,
  FormHelper,
  TableWrapper,
}
