//@ts-nocheck
import React from "react"
import { Tab, Tabs, Typography } from "@mui/material"
import * as Styled from "./Catalog.styles"
import { courseTypesVariants } from "../static/CatalogStatic"

export const CourseTypes = ({
  tab,
  setTab,
  courseTypes = courseTypesVariants,
}: {
  tab: number | string
  setTab: React.Dispatch<React.SetStateAction<number | string>>
  courseTypes?: typeof courseTypesVariants
}) => {
  return (
    <Styled.CourseTypesMainDiv>
      <Tabs
        //@ts-ignore
        textColor={"#313439"}
        value={tab}
        TabIndicatorProps={{ style: { height: 6, borderRadius: 3, backgroundColor: "#313439" } }}
        onChange={(e, newValue) => setTab(newValue as number)}
      >
        <Tab
          label={
            <Typography sx={{ color: "#313439" }} variant={"subtitle3"}>
              Все
            </Typography>
          }
          sx={{ textTransform: "none" }}
          value={0}
          style={{ fontSize: 18 }}
        />
        {courseTypes
          .filter(e => e.visibility)
          .map(e => (
            <Tab
              label={
                <Typography sx={{ color: "#313439" }} variant={"subtitle3"}>
                  {e.label}
                </Typography>
              }
              sx={{ textTransform: "none" }}
              value={e.value}
              style={{ fontSize: 18 }}
            />
          ))}
      </Tabs>
      <hr style={{ marginTop: -3, color: "#313439" }} />
    </Styled.CourseTypesMainDiv>
  )
}
