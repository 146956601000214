import { makeStyles } from "@mui/styles"
import subBgr1 from "../../images/icons/tile_Syncwoia.gif"
import subBgr2 from "../../images/icons/tile_directions.gif"
import whiteBgr from "../../images/icons/tile_white.gif"
import { borderColor } from "@mui/system"

export default makeStyles(theme => ({
  footerWrapper: {
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      flexDirection: "column-reverse",
    },
  },
  footer: {
    backgroundColor: "#313439",
    height: 112,
    paddingLeft: 124,
    paddingRight: 124,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 20,
      paddingTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 40,
      paddingTop: 40,
    },
  },

  footerLeftContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: 0,

    [theme.breakpoints.down("xl")]: {
      paddingTop: 20,
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      paddingTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },

  elem: {
    color: "white",
    fontSize: 14,
    marginLeft: 8,
    flex: "none",
    order: 0,
    flexGrow: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    "& button": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
  },

  elemLink: {
    textDecoration: "none",
    cursor: "pointer",
    color: "white",
    "&:hover": {
      color: "#FF6557",
    },
  },

  footerLink: {
    color: "white",
    textDecoration: "none",
    fontSize: 14,
    "&:hover": {
      color: "#FF6557",
    },
  },
}))
