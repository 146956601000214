import { styled } from "@mui/material/styles"
import { Avatar } from "@mui/material"

export const EventItemMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  height: "auto",
  backgroundColor: "#FFF",
  borderRadius: theme.spacing(3),
  gap: 0,
  justifyContent: "space-between",
  marginBottom: theme.spacing(5),
  boxShadow: "0px 0px 10px 0px #3636361A",
  [theme.breakpoints.down("sm")]: {},
}))

export const DescriptionEventItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "85%",
  },
}))

export const EventItemImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "50%",
  height: "auto",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: theme.spacing(3),
  borderBottomRightRadius: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    width: "30%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "15%",
  },
}))

export const EventItemImage = styled("img")(({ theme }) => ({
  height: "275px",
  objectFit: "cover",
  borderTopRightRadius: theme.spacing(3),
  borderBottomRightRadius: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
  },
  [theme.breakpoints.down("xs")]: {
    display: "none",
  },
}))

export const ChipsDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(2),
}))

export const EventDescriptionData = styled("div")(({ theme }) => ({
  borderTop: "1px solid #E0E0E0",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "auto",
  paddingTop: theme.spacing(3),
}))

export const EventDataItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
}))

export const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}))

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "auto",
  "&:hover": {
    opacity: "0.5",
  },
}))
