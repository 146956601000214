// @ts-ignore
import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import {
  AttachmentType,
  SubmitType,
  StartTimerType,
  ParticipantProgressPayload,
  TasksPayload,
} from "./assignment.types"
import { GenericAbortSignal } from "axios"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import { IAssingment } from "../model/assignment.types"

export const getActualSubmission = async (blockId: number) =>
  await axios
    .get(`/content/actions/assignments/get_actual_submission/`, {
      params: {
        content_block: blockId,
        timestamp: new Date().getTime(), // preventing browser cache
      },
    })
    .then(res => res.data)

export const getAssignment = async (assignmentId: number) =>
  await axios.get(`/content/assignments/${assignmentId}/review/`).then(res => res.data)

export const getAssignments = async (
  payload: { [x: string]: string | number | undefined },
  signal?: GenericAbortSignal
) =>
  await axios
    .get("/content/assignments/", {
      params: payload,
      signal: signal,
    })
    .then(res => res.data)
    .catch(() => ({
      count: 0,
      next: null,
      previous: null,
      results: [],
    }))

export const getAssignmentsForDownload = async (payload: { [x: string]: string | number | boolean | undefined }) =>
  await axios.get("/content/assignments/", {
    params: payload,
    responseType: "arraybuffer",
  })

export const getParticipantProgress = async (
  payload: ParticipantProgressPayload
): Promise<IParticipantProgress | undefined> =>
  await axios
    .get("/content/progress/", {
      params: payload,
    })
    .then(res => res.data[0])

export const getTasks = async (payload: TasksPayload) =>
  await axios.get(`/content/blocks/`, { params: payload }).then(res => res.data)

export const putAssignmentAttach = async (payload: AttachmentType) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  await axios.put(`/content/assignments/${payload.submission_id}/attach/`, payload.file, {
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `attachment; filename=q${payload.question_index}_${payload.blockId}.${
        payload.filename_split[payload.filename_split.length - 1]
      }`,
    },
  })
}

export const submitAssigment = async (payload: SubmitType) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  return await axios.post("/content/actions/assignments/submit/", payload).then(res => res.data)
}

export const saveAssignment = async (assingment: IAssingment) =>
  await axios.put(`/content/actions/assignments/${assingment.id}/resolve/`, assingment).then(res => res.data)

// TODO методы таймера относятся к сущностям теста

export const startTimer = async (payload: StartTimerType) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  return await axios.post("/content/actions/timer/", payload)
}

export const getTimeLeft = async (timerId: number) =>
  await axios.get(`/content/actions/timer/${timerId}/`).then(res => res.data)

export const getTimer = async (blockId: number) =>
  await axios.get(`content/actions/timer/get_timer/?content_block=${blockId}`)

export const getDeadline = async (contentBlock: number, predeadline: boolean = false) =>
  await axios
    .get(`/content/actions/timer/check_deadline/`, { params: { content_block: contentBlock, predeadline } })
    .then(res => res.data)

export const getPredeadlineDate = async (contentBlock: number) =>
  await axios
    .get(`/content/actions/timer/get_predeadline_date/`, { params: { content_block: contentBlock } })
    .then(res => res.data)

export const resetTimer = async (blockId: number) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  return await axios.put(`/content/actions/timer/delete_timer/?content_block=${blockId}`)
}

export const resetAttempts = async (blockId: number) => {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  return await axios.put(`/content/actions/timer/reset_attempts/?content_block=${blockId}`)
}
