import React from "react"
import { Chip, Typography } from "@mui/material"
import * as Styled from "./Kruzhok.styles"
import PlaceIcon from "@mui/icons-material/Place"

export const LocationChip = ({ location }: { location: string }) => {
  return (
    <Chip
      sx={{ backgroundColor: "#FFF" }}
      label={
        <Styled.FormatChipDiv>
          <PlaceIcon />{" "}
          <Typography variant={"caption"} fontWeight={"medium"}>
            {location}
          </Typography>
        </Styled.FormatChipDiv>
      }
    />
  )
}
