import { styled } from "@mui/material/styles"
import DeleteIcon from "@mui/icons-material/Delete"

export const DialogMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  gap: theme.spacing(8),
  marginTop: theme.spacing(5),
  height: "100%",
}))

export const ButtonSaveContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  alignSelf: "flex-end",
}))

export const StatusDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-end",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}))

export const CustomDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  cursor: "pointer",
  color: "#777777",
  "&:hover": {
    opacity: 0.5,
  },
}))

export const ChangeChartMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "480px",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  justifyItems: "space-between",
}))

export const SettingChartDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

export const SensorsDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderRadius: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "40%",
  maxHeight: "450px",
  overflowY: "scroll",
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))

export const TimeIntervalMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
}))

export const ItemTimeDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(4),
}))
