import moment from "moment"

export function moveItemInArray(arr, from, to) {
  let cutOut = arr.splice(from, 1)[0]
  arr.splice(to, 0, cutOut)
}

export function zeroPad(num) {
  return num.toString().padStart(2, "0")
}

export function fyShuffle(arr) {
  let i = arr.length
  while (--i > 0) {
    let randIndex = Math.floor(Math.random() * (i + 1))
    ;[arr[randIndex], arr[i]] = [arr[i], arr[randIndex]]
  }
  return arr
}

export function formatDate(date) {
  let newDate = moment(date.split("T")[0].toString(), "YYYY/MM/DD").format("DD.MM.YYYY")
  return newDate
}

export function toTimeZone(time) {
  let newTime = time.split("T")[1].split(".")[0]
  return moment.utc(newTime, "HH:mm:ss").add(3, "hour").format("HH:mm:ss")
}

export const compareStringsAscending = (a, b) => {
  if (a === null && b === null) return 0
  if (a === null) return -1
  if (b === null) return 1
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export const compareStringsByDescending = (a, b) => {
  if (a === null && b === null) return 0
  if (a === null) return 1
  if (b === null) return -1
  if (a > b) return -1
  if (a < b) return 1
  return 0
}

export function sortModules(a, b) {
  if (a[1][0] === b[1][0]) return a[1][1] > b[1][1] ? 1 : -1
  return a[1][0] > b[1][0] ? 1 : -1
}

export const removeObjectProperty = (key, { [key]: value, ...rest }) => rest

export function compareArrays(array1, array2) {
  if (!array1.length || !array2.length) return false

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false
    }
  }
  return true
}
