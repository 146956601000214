import { Dialog, Link } from "@mui/material"
import { Helper } from "./Course.style.ts"
import {
  DialogStyle,
  CloseWrapper,
  Close,
  ContentWrapper,
  HelperWrapper,
  Text,
  Title,
  UnorderList,
  Indent,
  OrderList,
  Paragraph,
  ThinText,
} from "./HelperModal.style.js"

const HelperModal = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      aria-labelledby="Popup Helper"
      aria-describedby="Participant Panel Popup"
      open={isOpen}
      onClose={handleClose}
      PaperProps={DialogStyle}
      elevation={0}
    >
      <CloseWrapper>
        <Close onClick={handleClose} />
      </CloseWrapper>
      <ContentWrapper>
        <Text>С помощью статистики вы можете увидеть основную информацию об участниках и их баллы за модули.</Text>
        <Paragraph>
          <Title>Название курса</Title>
          <Text>
            Для начала работы со статистикой необходимо выбрать нужный курс из выпадающего списка, либо ввести часть
            названия и выбрать один из предложённых вариантов. <br />
            <Indent /> После нажатия на кнопку <ThinText>Посмотреть статистику</ThinText> загрузится статистика по всем
            участникам курса.
          </Text>
        </Paragraph>
        <Paragraph>
          <Title>Фильтр поиска</Title>
          <Text>
            С помощью фильтра вы можете посмотреть статистику по одному или нескольким участникам, соответствующим
            определенным параметрам.
          </Text>
          <br />
          <UnorderList>
            <li>
              <Text>
                <ThinText>Статус</ThinText> соответствует уровню доступа участника на курс.
              </Text>
            </li>
            <li>
              <Text>
                <ThinText>Категория</ThinText> — это роль участника на курсе.
              </Text>
            </li>
            <li>
              <Text>
                <ThinText>Статус</ThinText> прохождения указывает на то, прошел участник курс полностью или нет.
              </Text>
            </li>
            <li>
              <Text>
                <ThinText>Критерий</ThinText> позволяет отобрать участников курса по определенному баллу за модуль или
                по общему баллу за весь курс.
              </Text>
            </li>
          </UnorderList>
        </Paragraph>
        <Paragraph>
          <Title>Выдача доступов</Title>
          <Text>
            <OrderList>
              <li>
                Этот блок позволяет: Выдать или забрать у участника доступ на <ThinText>Этап</ThinText>.
              </li>
              <li>
                Задать <ThinText>Категорию</ThinText> участника на курсе.
              </li>
              <li>
                <ThinText>Активировать</ThinText> или <ThinText>Деактивировать</ThinText> доступ к траектории курса.
              </li>
            </OrderList>
            Чтобы увидеть изменения в таблице Статистики, нужно нажать на кнопку{" "}
            <ThinText>Посмотреть статистику</ThinText> еще раз.
            <br /> Обратите внимание, что при открытии списка <ThinText>Этап</ThinText> будут отображаться только этапы
            с ограниченным доступом.
          </Text>
        </Paragraph>
        <Paragraph>
          <Title>Таблица</Title>
          <Text>
            Если в модуле нет заданий или есть задания без контроля, то в статистике этот модуль отображаться не будет.
            Зеленая ячейка означает, что участник набрал число баллов за модуль, равное минимальному баллу и выше.
          </Text>
        </Paragraph>
        <HelperWrapper>
          <Helper component={Link} href="https://youtu.be/Zg06L8fXW8o?si=rAcDBUORBtTqLSh8" target="blank">
            Смотреть обучающее видео
          </Helper>
        </HelperWrapper>
      </ContentWrapper>
    </Dialog>
  )
}

export default HelperModal
