// @ts-nocheck
import React, { useState } from "react"
import * as Styled from "./ui/Organizations.styles"
import { Typography } from "@mui/material"
import { Button, TextField } from "shared/ui"
import { useTheme } from "shared/context"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useSelector } from "react-redux"
import Cookies from "js-cookie"
import { RequestMessage } from "shared/assets/RequestMessage/RequestMessage"
import { OrganizationMember } from "../../entities/Organization/model/Organization.types"

export const SendInvitationForModerator = ({
  setMembers,
}: {
  setMembers: React.Dispatch<React.SetStateAction<OrganizationMember[]>>
}) => {
  const organization = useSelector(state => state.organization.currentOrganization)
  const theme = useTheme()
  const [email, setEmail] = useState("")
  const [result, setResult] = useState<{ error: boolean; text: string } | null>(null)

  function handleClickButton() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`/organizations/${organization.id}/members/`, { email: email })
      .then(res => {
        setResult({ error: false, text: "Пользователь добавлен" })
        //setMembers(prevState => [...prevState, res.data])
      })
      .catch(err => {
        setResult({ error: true, text: "Ошибка" })
      })
  }

  return (
    <>
      <Styled.SendInvitationFoModeratorMainContainer>
        <Typography sx={{ marginTop: theme.spacing(8) }} variant={"subtitle3"}>
          Пригласить модератора в организацию
        </Typography>
        <Styled.AddUserDiv>
          <TextField
            style={{ width: "30%" }}
            label={"Почта"}
            value={email}
            onChange={e => setEmail(e.target.value)}
            variant={"outlined"}
          />
          <Button variant={"outlined"} color={"primary"} disabled={!email} onClick={handleClickButton}>
            Добавить пользователя
          </Button>
        </Styled.AddUserDiv>
        {!!result && <RequestMessage error={result.error} text={result.text} />}
      </Styled.SendInvitationFoModeratorMainContainer>
    </>
  )
}
