import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export const SavingWrapper = styled.div`
  display: flex;
`

export const StatusWrapper = styled.div`
  margin-right: 24px;
`

export const ScoreWrapper = styled.div`
  margin-right: 40px;
  padding-top: 8px;
`

export const HelperText = styled(Typography)`
  margin-top: 16px;
  font-size: 16px;
`

export const TextFieldWrapper = styled.div`
  margin-top: 46px;
`

export const AlertWrapper = styled.div`
  display: flex;
  margin-left: 40px;
  align-items: center;
  gap: 16px;
`

export const IconWrapper = styled.div`
  scale: 0.5;
  width: 24px;
  height: 20px;
  translate: -5px -5px;
`

export const AlertText = styled.span`
  line-height: 1.55;
  font-weight: 300;
  margin-top: 16px;
  font-size: 16px;
`
