import React, { useState, useRef, createElement, useEffect, forwardRef, useImperativeHandle } from 'react';
import '@wokwi/elements';
import { parse } from 'intel-hex';
import {
  CPU,
  avrInstruction,
  AVRIOPort,
  portDConfig,
  PinState,
  AVRTimer,
  timer0Config
} from 'avr8js';
import { useStopwatch } from 'react-timer-hook';
import { styled } from '@mui/material/styles';

import { axiosInstance as axios } from "shared/api/axiosInstance";
import Wire from './Wire';
import { Timer } from 'shared/assets';

const WokwiStopwatch = styled(Timer)({
  position: "relative",
  top: 0,
  right: 0,
  fontSize: 18,
  height: 40,
  borderRadius: 20
})

function updater(elementType, pinState) {
  if (elementType === "wokwi-led") {
    let turnOn = pinState["A"] === PinState.High
    console.log('LED', turnOn)
    return { value: turnOn ? true : "" }
  }
  return 
}

const pinMap = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "GND.1": 0,
  "GND.2": 0,
} // GND HAS INCORRECT MAPPING!

const MHZ = 16

export const Wokwi = forwardRef((props, ref) => {
  const config = JSON.parse(props.config)
  

  const [states, setStates] = useState(getInitStates());
  const [values, setValues] = useState(getInitStates());
  const [wires, setWires] = useState(null);
  const [helperText, setHelperText] = useState(" ");

  const cycle = useRef();
  const elementsRef = useRef({});
  const elementIds = useRef({});

  useImperativeHandle(ref, () => {
    return { runCode, stopCode }
  }, []);

  function getInitStates() {
    return config.parts.map(e => (e.id, null))
  }

  const stopwatch = useStopwatch({ autoStart: false });

  const runCode = (code) => {
    setHelperText(prev => " ")
    stopwatch.start()
    if (!code) return
    axios.post(
      'https://hexi.wokwi.com/build',
      { sketch: code },
      {headers: {'Content-Type': 'application/json'}}
    ).then(res => {
      let data
      try {
        data = parse(res.data.hex).data
      } catch (err) {
        setHelperText(prev => "Ошибка в коде")
        return
      }
      const progData = new Uint8Array(data);
      const cpu = new CPU(new Uint16Array(progData.buffer));
      const port = new AVRIOPort(cpu, portDConfig);
      const timer = new AVRTimer(cpu, timer0Config);
      port.addListener(() => {
        let newStates = updateStates(port)
        updateValues(newStates)
        setStates(newStates)
      });
      cycle.current = setInterval(() => {
        for (let i = 0; i < MHZ * 10**6; i++) {
          avrInstruction(cpu);
          cpu.tick()
        }
      }, 1000)
    })
  };

  function updateState(id, port) {
    if (["uno", "nano", "mega"].includes(id)) return
    let pins = {}
    let elConnections = config.connections.filter(c => c[0].split(":")[0] === id || c[1].split(":")[0] === id)
    for (let index = 0; index < elConnections.length; index++) {
      let conn = elConnections[index]
      conn = [conn[0].split(":"), conn[1].split(":")]
      if (conn[0][0] === id) {
        pins[conn[0][1]] = port.pinState(pinMap[conn[1][1]])
      }
      if (conn[1][0] === id) {
        pins[conn[1][1]] = port.pinState(pinMap[conn[0][1]])
      }
    }
    return pins
  }

  function updateStates(port) {
    let newStates = {}
    Object.keys(elementIds.current).map(e => {
      newStates[e] = updateState(e, port)
    })
    return newStates
  }

  function updateValues(states) {
    Object.entries(elementIds.current).map(e => {
      setValues(prev => ({ ...prev, [e[0]]: updater(e[1], states[e[0]]) }));
    })
  }

  const stopCode = () => {
    stopwatch.reset(null, false)
    clearInterval(cycle.current)
    cycle.current = null
    setStates(getInitStates())
  }

  useEffect(() => {
    if (elementsRef.current === {}) return
    setWires(setConnections(config.connections))
  }, [elementsRef.current])

  function setConnections(connections) {
    let wires = new Array()
    for (let conn of connections) {
      let wire = [ ...conn ]
      for (let index = 0; index < 2; index++) {
        let pin = conn[index].split(":")
        let element = elementsRef.current[pin[0]]
        if (!element || !element.el) {
          console.log(element)
          return []
        }
        wire[index] = { ...element.el.pinInfo.filter(e => e.name === pin[1])[0] }
        wire[index].x += element.left
        wire[index].y += element.top
      }
      if (!!wire[0] && !!wire[1]) {
        wires.push(wire)
      }
    }
    return wires
  }

  const elementsMapper = (e) => {
    elementIds.current[e.id] = e.type
    return (
      <div id={e.id} style={{ display: "inline-block", position: "absolute", top: e.top, left: e.left, rotate: e.rotate, zIndex: -1 }}>
        {createElement(e.type, { ...e.attrs, ...values[e.id],
          ref: (el => elementsRef.current[e.id] = { el: el, top: e.top, left: e.left }) })}
      </div>
    )
  }

  if (!config) return null

  return (
    <div style={{ width: "50%", marginLeft: 20 }}>
      {helperText}
      <WokwiStopwatch hours={stopwatch.hours} minutes={stopwatch.minutes} seconds={stopwatch.seconds} />
      <div className='wokwi-container' style={{ position: "relative", top: "5%", left: "5%", width: "100%", height: "100%" }}>
        {config.parts.map(elementsMapper)}
        <svg width="100%" height="100%" overflow="visible">
          {!!wires && wires.map(w => (<Wire wire={w} />))}
        </svg>
      </div>
    </div>
  );
})
