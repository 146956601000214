import styled from "@emotion/styled"

export const Wrapper = styled.div`
  margin-block: 12px 40px;
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 20.7px;
  letter-spacing: 0.2px;
`

export const Row = styled("div")(() => ({
  display: "flex",
  gap: "24px",
  alignItems: "center",

  "&:not(:last-child)": {
    marginTop: "30px",
    marginBottom: "38px",
  },
}))

export const BalanceRow = styled(Row)(() => ({
  "& > *": {
    flex: 1,
  },
}))

export const AccessWrapper = styled("div")(() => ({
  display: "flex",
  gap: 12,
  alignItems: "end",
}))

export const StatusWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  gap: 16,
}))

export const IconWrapper = styled.div`
  transform: scale(0.5);
`
