import { useMemo, useState } from "react"
import { filterNumericFields } from "entities/Course/lib/filterNumericFields"
import downloadSheetFile from "features/downloadSheetFile"
import {
  giveStageAccess as giveStageRequest,
  removeStageAccess as removeStageRequest,
} from "entities/Course/api/course"
import {
  giveModuleAccess as giveModuleRequest,
  removeModuleAccess as removeModuleRequest,
} from "entities/Module/api/module"
import { updateMassPaticipants } from "entities/Participant/api/participant"

const formParticipants = progress => {
  const result = progress
    .filter(filterNumericFields)
    .map(e => (e.participant ? e.participant.id : e.members.map(member => member.participant.id)))
    .flat(1)

  return result
}

const useManageStats = (progress, scores, filterCount, selectedCourse, setIsDownloadWarning, setIsAccessWarning) => {
  const [stage, setStage] = useState()
  const [newCategory, setNewCategory] = useState()
  const [isActive, setIsActive] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [downloadType, setDownloadType] = useState(".xlsx")
  const [access, setAccess] = useState("Этап")
  const [accessFunc, setAccessFunc] = useState(null)
  const participantsPayload = useMemo(() => ({ participants: formParticipants(progress) }), [progress])
  const [completedStatus, setCompletedStatus] = useState(null)

  async function giveStageAccess() {
    if (!stage) return
    initialLoading()
    await giveStageRequest(stage, participantsPayload)
      .then(() => setSuccessMessage("Доступ дан"))
      .catch(() => setErrorMessage("Не удалось изменить состояние доступа"))
      .finally(() => setIsLoading(false))
  }

  async function removeStageAccess() {
    if (!stage) return
    initialLoading()
    await removeStageRequest(stage, participantsPayload)
      .then(() => setSuccessMessage("Доступ забран"))
      .catch(() => setErrorMessage("Не удалось изменить состояние категории"))
      .finally(() => setIsLoading(false))
  }

  async function giveModuleAccess() {
    if (!stage) return
    initialLoading()
    await giveModuleRequest(stage, participantsPayload)
      .then(() => setSuccessMessage("Доступ дан"))
      .catch(() => setErrorMessage("Не удалось изменить состояние доступа"))
      .finally(() => setIsLoading(false))
  }

  async function removeModuleAccess() {
    if (!stage) return
    initialLoading()
    await removeModuleRequest(stage, participantsPayload)
      .then(() => setSuccessMessage("Доступ забран"))
      .catch(() => setErrorMessage("Не удалось изменить состояние доступа"))
      .finally(() => setIsLoading(false))
  }

  async function updateActive() {
    if (isActive === null) return
    initialLoading()
    const payload = {
      ...participantsPayload,
      is_active: isActive,
      course: selectedCourse.id,
    }
    await updateMassPaticipants(payload)
      .then(() => setSuccessMessage("Статус активности обновлён"))
      .catch(() => setErrorMessage("Не удалось изменить состояние активности"))
      .finally(() => setIsLoading(false))
  }

  async function updateCategory() {
    initialLoading()
    const payload = {
      ...participantsPayload,
      category: newCategory,
      course: selectedCourse.id,
    }
    await updateMassPaticipants(payload)
      .then(() => setSuccessMessage("Категория обновлена"))
      .catch(() => setErrorMessage("Не удалось изменить состояние категории"))
      .finally(() => setIsLoading(false))
  }

  async function updateCompletedStatus() {
    initialLoading()
    const payload = {
      ...participantsPayload,
      is_completed: completedStatus,
      course: selectedCourse.id,
    }
    await updateMassPaticipants(payload)
      .then(() => setSuccessMessage("Статус прохождения обновлен"))
      .catch(() => setErrorMessage("Не удалось изменить статус прохождения"))
      .finally(() => setIsLoading(false))
  }

  const initialLoading = () => {
    setIsLoading(true)
    setSuccessMessage("")
    setErrorMessage("")
  }

  const getRecieviers = () => {
    let result = []
    if (progress[0]?.participant?.user) {
      result = progress.map(team => team?.participant?.user)
    } else if (progress[0]?.members) {
      progress.forEach(team =>
        team?.members.forEach(member => {
          result.push(member.participant.user)
        })
      )
    }
    return result
  }

  const handleWarning = (key, func, setModal) => {
    const isUnlockDisable = filterCount === progress.length
    if (isUnlockDisable) return func()
    const storedState = localStorage.getItem(`${key}_warning_disable`)
    const isStoredDisable = storedState ? JSON.parse(storedState) : null
    isStoredDisable ? func() : setModal(true)
  }

  const onDownload = () => {
    download()
    setIsDownloadWarning(false)
  }

  const download = () => {
    downloadSheetFile(`scores_course_${selectedCourse.id}`, scores, downloadType)
  }

  const onAccess = () => {
    accessFunc()
    setIsAccessWarning(false)
  }

  const handleAccessFunc = func => {
    setAccessFunc(() => func)
    handleWarning("access", func, setIsAccessWarning)
  }

  const handleCloseAccess = () => {
    setAccessFunc(null)
    setIsAccessWarning(false)
  }

  const giveAccess = () => {
    access === "Этап" ? giveStageAccess() : giveModuleAccess()
  }

  const removeAccess = () => {
    access === "Этап" ? removeStageAccess() : removeModuleAccess()
  }

  return {
    getRecieviers,
    newCategory,
    stage,
    access,
    setAccess,
    setStage,
    setNewCategory,
    isLoading,
    isActive,
    setIsActive,
    setDownloadType,
    updateActive,
    updateCategory,
    downloadType,
    successMessage,
    errorMessage,
    handleWarning,
    download,
    onDownload,
    onAccess,
    handleCloseAccess,
    handleAccessFunc,
    giveAccess,
    removeAccess,
    completedStatus,
    setCompletedStatus,
    updateCompletedStatus,
  }
}

export { useManageStats }
