import React, {useEffect, useState} from "react";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import { axiosInstance as axios } from "shared/api/axiosInstance";
import Cookies from 'js-cookie';

export const AdminProfileEditor = props => {
  

  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [helperText, setHelperText] = React.useState("");

  useEffect(() => {
    setUser(props.user)
  }, [props.user])

  const handleChangeUser = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  function changeName() {
    setIsLoading(true)
    axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    axios.patch(`/profiles/${user.id}/`, user).then(() => {
      setHelperText("Сохранено")
      setIsLoading(false)
    }).catch(err => {
      setHelperText("Ошибка")
      setIsLoading(false)
    })
  }

  if (!user) return null

  return (
    <>
    <Typography variant="body2">{helperText}</Typography>
    <Typography variant="h5">Изменить ФИО</Typography>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 20, margin: 12 }}>
      <TextField
        id="admin-last-name-edit"
        variant="outlined"
        name="last_name"
        value={user.last_name}
        onChange={handleChangeUser}
        type="email"
        helperText="Фамилия"
        style={{ minWidth: 240 }}
      />
      <TextField
        id="admin-first-name-edit"
        variant="outlined"
        name="first_name"
        value={user.first_name}
        onChange={handleChangeUser}
        type="email"
        helperText="Имя"
        style={{ minWidth: 240 }}
      />
      <TextField
        id="admin-middle-name-edit"
        variant="outlined"
        name="middle_name"
        value={user.middle_name}
        onChange={handleChangeUser}
        type="email"
        helperText="Отчество"
        style={{ minWidth: 240 }}
      />
      {isLoading ? (
        <CircularProgress size={26} />
      ) : (
        <Button
          onClick={changeName}
          variant="contained"
          color="primary"
          size="large"
          style={{ width: 240, height: 50, marginBottom: 6 }}
        >
          Сохранить
        </Button>
      )}
    </div>
    </>
  )
}
