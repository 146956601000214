import React, { useState } from "react"
import {Button, CircularProgress, TextField as Input, Typography, IconButton} from "@mui/material"

import useStyles from "./styles"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function ForgotPassword(props) {
  let classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [passwordValue, setPasswordValue] = useState("")
  const [passwordConfirmValue, setPasswordConfirmValue] = useState("")
  const [forgotEmail, setForgotEmail] = useState("")
  const [forgotKey, setForgotKey] = useState("")
  const [forgotHash, setForgotHash] = useState("")
  const [helperText, setHelperText] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }
   const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  function sendPasswordResetEmail() {
    setIsLoading(true)
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post("/forgot/email/", { email: forgotEmail })
      .then(res => {
        setForgotHash(res.data.key)
        setIsLoading(false)
      })
      .catch(err => {
        if (err.response.status === 404) {
          setHelperText("Пользователя с такой почтой не существует")
          setIsLoading(false)
        }
      })
  }

  function setNewPassword() {
    if (passwordValue !== passwordConfirmValue) {
      setHelperText("Пароли не совпадают")
      return
    }
    setIsLoading(true)
    axios
      .post("/forgot/", {
        key: forgotKey,
        password: passwordValue,
        hash: forgotHash,
        email: forgotEmail,
      })
      .then(() => {
        setForgotHash("")
        setIsLoading(false)
        props.setIsForgot(false)
      })
      .catch(err => {
        if (err.response.status === 400) {
          setHelperText("Введён неверный код")
        }
        setIsLoading(false)
      })
  }

  const handleKeyDownReset = React.useCallback(e => {
    if (e.nativeEvent.isComposing) {
      return
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      sendPasswordResetEmail()
    }
  })

  if (!forgotHash) {
    return (
      <div className={classes.form}>
        <Typography variant="h2" className={classes.greeting}>
          Восстановление пароля
        </Typography>
        <Typography variant="h6" style={{ textAlign: "center", marginTop: 40 }}>
          Введите почту, на которой был зарегистрирован Ваш аккаунт. На указанную почту будет отправлен код для смены
          пароля
        </Typography>
        <Typography className={classes.errorMessage}>{helperText}</Typography>
        <Input
          id="email"
          name="email"
          variant="outlined"
          value={forgotEmail}
          onChange={e => {
            setForgotEmail(e.target.value.toLowerCase())
            if (helperText) {
              setHelperText("")
            }
          }}
          margin="normal"
          placeholder="Почта"
          type="email"
          onKeyDown={handleKeyDownReset}
          fullWidth
        />
        <div className={classes.formButtons}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button
              disabled={forgotEmail.length === 0}
              onClick={sendPasswordResetEmail}
              variant="contained"
              color="primary"
              size="large"
              style={{ width: 150, height: 50 }}
            >
              Отправить
            </Button>
          )}
          <Button
            onClick={() => props.setIsForgot(false)}
            color="primary"
            size="large"
            className={classes.forgetButton}
          >
            Вернуться ко входу
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.form}>
      <Typography variant="h2" className={classes.greeting}>
        Восстановление пароля
      </Typography>
      <Typography className={classes.errorMessage}>{helperText}</Typography>
      <Input
        id="key"
        name="one-time-code"
        variant="outlined"
        value={forgotKey}
        onChange={e => setForgotKey(e.target.value)}
        margin="normal"
        placeholder="Код"
        type="key"
        fullWidth
      />
      <Input
        id="password"
        name="new-password"
        variant="outlined"
        value={passwordValue}
        onChange={e => setPasswordValue(e.target.value)}
        margin="normal"
        placeholder="Пароль"
        type={showPassword ? "text" : "password"}
        InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={event => handleClickShowPassword(event)}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
        fullWidth
      />
      <Input
        id="password"
        name="new-password"
        variant="outlined"
        value={passwordConfirmValue}
        onChange={e => setPasswordConfirmValue(e.target.value)}
        margin="normal"
        placeholder="Повторите пароль"
        type={showPassword ? "text" : "password"}
        InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={event => handleClickShowPassword(event)}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
        fullWidth
      />
      <div className={classes.formButtons}>
        {isLoading ? (
          <CircularProgress size={26} className={classes.loginLoader} />
        ) : (
          <Button
            disabled={forgotEmail.length === 0}
            onClick={setNewPassword}
            variant="contained"
            color="primary"
            size="large"
            style={{ width: 250, height: 50 }}
          >
            Обновить пароль
          </Button>
        )}
        <Button onClick={() => props.setIsForgot(false)} color="primary" size="large" className={classes.forgetButton}>
          Вернуться ко входу
        </Button>
      </div>
    </div>
  )
}

export default ForgotPassword
