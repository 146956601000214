import styled from "@emotion/styled"
import { useState } from "react"
import { Dropdown, TextField } from "shared/ui"
import { formStages, statusEnum } from "entities/Course/lib/CourseStatic.js"
import { inviteParticipantsFilters as filterOptions } from "../static/ParticipantsTablesHeaders"
import { IStage } from "entities/Course/api/course.types"

interface IProps {
  isStageFilter?: boolean
  handleFilters?: any
  stages?: IStage[]
}

const participantStatuses = { ...statusEnum, requested: "requested", banned: "banned" }

const InvitesParticipantFilters = ({ isStageFilter = false, handleFilters, stages }: IProps) => {
  const [filter, setFilter] = useState<any>(null)
  const [value, setValue] = useState(null)
  const [stage, setStage] = useState(null)

  const handleFilter = (e: any) => {
    setFilter(e.target)
    if (e.target.value !== filter?.value && value) {
      setValue(null)
      handleFilters({ ["stage"]: stage })
    }
  }

  const handleType = (e: any) => {
    value !== e.target.value && handleFilters({ [filter?.value]: e.target.value, ["stage"]: stage })
    setValue(e.target.value)
  }

  const handleStage = (e: any) => {
    setStage(e.target.value)
    handleFilters({ [filter?.value]: value, ["stage"]: e.target.value })
  }

  return (
    <Wrapper>
      <Title>Фильтры</Title>
      <FiltersWrapper>
        <Dropdown
          id={"invite-filter--creterion"}
          name={"criterion"}
          value={filter?.value || undefined}
          onChange={handleFilter}
          options={filterOptions}
          label={"Параметр фильтра"}
          width={280}
        />
        {filter?.label === "Статус" ? (
          <Dropdown
            key={filter?.value}
            id={"invite-filter--value"}
            variant="outlined"
            name={"participant__value"}
            value={value}
            onChange={handleType}
            disabled={!filter?.value}
            options={participantStatuses}
            label={"Значение"}
            size={"small"}
            width={280}
          />
        ) : (
          <TextField
            key={filter?.value}
            id="invite-filter--value"
            variant="outlined"
            name={"participant__value"}
            value={value}
            onChange={handleType}
            disabled={!filter?.value}
            label="Значение"
            size={"small"}
          />
        )}
        {isStageFilter && (
          <Dropdown
            id={"invite-filter--stage"}
            name={"participant__stage"}
            value={stage || undefined}
            onChange={handleStage}
            options={formStages(stages)}
            label={"Фильтр по доступу к огр. этапу"}
            width={280}
          />
        )}
      </FiltersWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-block: 12px 40px;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 20.7px;
  letter-spacing: 0.2px;
`

const FiltersWrapper = styled.div`
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  width: 280px;
  gap: 34px;
`

export { InvitesParticipantFilters }
