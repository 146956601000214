import React, { ChangeEvent, useEffect, useState } from "react"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import * as Styled from "./Tags.styles"
import { Autocomplete } from "../../../shared/ui"
import { DeleteTag } from "../../../features"

export const TagsList = ({
  tags,
  setTags,
}: {
  tags: Array<{ value: number; label: string }>
  setTags: (value: Array<{ value: number; label: string }>) => void
}) => {
  const [currentTag, setCurrentTag] = useState<null | { value: number; label: string }>(null)

  useEffect(() => {
    axios.get("content/tags/").then(res => {
      setTags(res.data.map((e: { value: string; id: number }) => ({ label: e.value, value: e.id })))
    })
  }, [])

  function deleteTag(id: number) {
    let newTags = tags.filter(e => e.value !== id)
    setTags([...newTags])
  }

  return (
    <Styled.TagsListMainDiv>
      {/*@ts-ignore*/}
      <Autocomplete
        label={"Выберите тег"}
        value={currentTag}
        options={tags}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          newValue: {
            label: string
            value: number
          }
        ) => setCurrentTag(newValue)}
        width={225}
      />
      {!!currentTag && <DeleteTag tag={currentTag} deleteTag={deleteTag} />}
    </Styled.TagsListMainDiv>
  )
}
