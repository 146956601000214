import React from "react"
import { styled } from "@mui/material/styles"
import { Autocomplete, TextField } from "shared/ui"
import { AudioPlayer } from "./AudioPlayer"

export const QuestionsPull = ({
  questions,
  answers,
  setAnswers,
  preview = false,
}: {
  questions: adaptiveQuestion[]
  answers: { [key: string | number]: string | string[] }
  setAnswers: React.Dispatch<React.SetStateAction<{ [key: string | number]: string | string[] }>>
  preview?: boolean
}) => {
  function questionsMapper(question: adaptiveQuestion) {
    switch (question.content_type) {
      case "missing_word":
        return <div key={question.id}>{parseQuestionWithHTML(question.id, question.question, question.props)}</div>
      case "audio": {
        return (
          <div key={question.id}>
            {!preview && <AudioPlayer audio={question.file} />}
            {parseQuestionWithHTML(question.id, question.question, question.props)}
          </div>
        )
      }
      default:
        return null
    }
  }

  function handleChangeMissingWord(value: string, index: number, id: number, length: number) {
    let new_answers: string[] | string = []
    if (!answers[id] || answers[id].length === 0) new_answers = Array(10).fill("")
    else new_answers = answers[id] as string[]
    new_answers[index] = value
    answers[id] = new_answers
    setAnswers({ ...answers })
  }

  const parseQuestionWithHTML = (id: number, question: string, props: { options: Array<string[]> }) => {
    const parts = question.split(/({autocomplete})/)
    let i: number = -1
    return parts.map((part, index) => {
      if (part === "{autocomplete}") {
        i = i + 1
        return (
          <span
            key={index}
            style={{
              display: "inline-block",
              width:
                [...props.options[i]]?.sort((a, b) => (a?.length > b?.length ? -1 : 1))[0]?.length > 5
                  ? "300px"
                  : "150px",
              alignSelf: "center",
              paddingLeft: "8px",
              paddingRight: "8px",
              verticalAlign: "middle",
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
          >
            {props.options[i]?.length > 0 && (
              <Autocomplete
                label={""}
                size={"small"}
                name={String(i)}
                style={{
                  width: "100%",
                }}
                onChange={(event, newValue, name) =>
                  handleChangeMissingWord(
                    !!newValue ? (newValue["value"] as string) : "",
                    Number(name),
                    id,
                    props.options.length
                  )
                }
                value={!!answers[id] ? answers[id][i] || null : null}
                options={props.options[i].map(e => ({ value: e, label: e }))}
              />
            )}
            {!props.options[i].length && (
              <TextField
                name={String(i)}
                onChange={event =>
                  handleChangeMissingWord(event.target.value, Number(event.target.name), id, props.options.length)
                }
                style={{ width: "100%" }}
                variant={"outlined"}
                label={""}
                size={"small"}
              />
            )}
          </span>
        )
      } else {
        return (
          <span
            style={{
              paddingTop: "16px",
              paddingBottom: "8px",
            }}
            key={index}
            dangerouslySetInnerHTML={{ __html: part }}
          />
        )
      }
    })
  }

  return (
    <QuestionWrapper>
      {questions.map((e, i) => (
        <React.Fragment key={i}>{questionsMapper(e)}</React.Fragment>
      ))}
    </QuestionWrapper>
  )
}

export const QuestionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: theme.spacing(5),
}))
