import React from 'react';
import {
  Typography,
  Button,
  TextField,
  IconButton
} from '@mui/material';
import useStyles from '../../pages/profile/styles'

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { changePassword } from 'entities/User';

export const ResetPassword = () => {
  
  const classes = useStyles()
  const [password, setPassword] = React.useState({
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [helperText, setHelperText] = React.useState("")

  const onEditSuccess = res => {
    setHelperText("Сохранено")
  }

  const onEditError = err => {
    if(err.response.status === 403){
        setHelperText("Текущий пароль введен не верно. Попробуйте снова")
    }
  }

  function handleSubmit() {
    if (password.currentPassword && password.newPassword && password.newPassword == password.confirmPassword) {
      changePassword(password, onEditSuccess, onEditError)
    } else {
      setHelperText("Пароли не совпадают")
    }
  }

  function handleChangePassword(e) {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    })
  }
  function handleClickShowPassword(e) {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

    return (
      <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", width: "100%", gap: 10, padding: 36 }}>
        <Typography
          variant={'h5'}
          weight={'medium'}
          style={{ marginBottom: 12, alignSelf: "center" }}
        >
          Изменить пароль
        </Typography>
        <Typography weight={'medium'}>
          Текущий пароль
        </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          defaultValue={'Current Password'}
          value={password.currentPassword || ''}
          name="currentPassword"
          onChange={handleChangePassword}
          type={showPassword ? "text" : "password"}
          InputProps={{ endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(event) => handleClickShowPassword(event)}
                onMouseDown={handleMouseDownPassword}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }}
        />
        <Typography weight={'medium'}>
          Новый пароль
        </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          defaultValue={'New Password'}
          value={password.newPassword || ''}
          name="newPassword"
          onChange={handleChangePassword}
          type="password"
        />
        <Typography weight={'medium'}>
          Повторите пароль
        </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          defaultValue={'Verify Password'}
          value={password.confirmPassword || ''}
          name="confirmPassword"
          onChange={handleChangePassword}
          type="password"
        />
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: 6 }}>
            Сохранить
          </Button>
          <Typography
            variant={'body1'}
            weight={'medium'}
          >
            {helperText}
          </Typography>
        </div>
      </div>
    );
}
