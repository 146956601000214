import { styled } from "@mui/material/styles"
import { Chip } from "@mui/material"

export const MemberChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  width: "fit-content",
  height: theme.spacing(6),
}))

export const ChipTypography = styled("div")(({ theme }) => ({
  padding: "6px 12px",
  color: theme.palette.common.white,
}))

export const Typography = styled("div")(() => ({
  maxWidth: 148,
}))
