import * as Styled from "../styles/LegendCard"
import ArrowDropDownIcon from "images/icons/ArrowDropDown"
import { useState } from "react"
import { Collapse } from "@mui/material"
import { ILegendItem } from "entities/Organization/model/StatisticsContext.types"
import { getDisplayedLegend, getDisplayedValue } from "../../lib/statistics"
import { infoLegendColors, infoLegendLabels } from "entities/Organization/static/statistics"

interface CardProps {
  label?: string
  legend?: ILegendItem[]
}
const LegendCard = ({ label, legend }: CardProps) => {
  const isDisabled = !legend?.find(item => item.data !== 0) || legend?.length === 0
  const [isActive, setIsActive] = useState(!isDisabled)
  const isYours = label === infoLegendLabels.organization
  const isOthers = label === infoLegendLabels.other
  const displayedLegend = getDisplayedLegend({ legend, isCompany: isYours || isOthers })
  const companyMarkerColor = isYours ? infoLegendColors["organization"] : infoLegendColors["other"]

  const handleCollapse = () => {
    if (isDisabled) return
    setIsActive(prev => !prev)
  }

  return (
    <Styled.ListItem isDisabled={isDisabled}>
      <Styled.ItemHeader onClick={handleCollapse} isDisabled={isDisabled}>
        <Styled.LabelWrapper>
          {(isYours || isOthers) && <Styled.Marker color={companyMarkerColor} />}
          <Styled.Label>{label}</Styled.Label>
        </Styled.LabelWrapper>
        <Styled.StatsWrapper>
          <Styled.Stats>
            {legend?.map((item, index) => (
              <>
                <Styled.Number color={item.color || "black"}>{getDisplayedValue(0, item.data)}</Styled.Number>
                {index !== legend.length - 1 && <span>/</span>}
              </>
            ))}
          </Styled.Stats>
          <Styled.ArrowWrapper isActive={isActive}>
            <ArrowDropDownIcon />
          </Styled.ArrowWrapper>
        </Styled.StatsWrapper>
      </Styled.ItemHeader>
      <Collapse in={isActive}>
        <Styled.Answers>
          {displayedLegend?.map(item => (
            <Styled.Answer key={item.title}>
              {item.type !== "total" && <Styled.Marker color={item.color || "transparent"} />}
              <Styled.Title>{item.title}</Styled.Title>
              <Styled.Count color={item?.color || "transparent"}>{getDisplayedValue(0, item.data)}</Styled.Count>
            </Styled.Answer>
          ))}
        </Styled.Answers>
      </Collapse>
    </Styled.ListItem>
  )
}

export { LegendCard }
