import { styled } from "@mui/material/styles"
import EditRolesTooltip from "./EditRolesTooltip"

export const MembersLimit = ({ count, limit }: { count: number; limit: number }) => {
  if (limit === Infinity) return null

  return (
    <Wrapper>
      <Typography>
        Всего <Accent>участников</Accent>:{" "}
        <Strong>
          {count} из {limit}
        </Strong>
      </Typography>
      <EditRolesTooltip />
    </Wrapper>
  )
}

const Wrapper = styled("div")(() => ({
  marginBottom: 16,
  display: "flex",
  alignItems: "center",
  gap: 24,
}))

const Typography = styled("span")(() => ({
  fontWeight: "300",
}))

const Accent = styled("span")(() => ({
  paddingBottom: 2,
  borderBottom: "3px solid #3EBD44",
}))

const Strong = styled("span")(() => ({
  fontSize: "18px",
  fontWeight: "400",
}))
