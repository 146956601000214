import React, { useEffect, useState } from "react"
import { Menu, MenuItem, Button } from "@mui/material"
import * as Styled from "./ui/Organizations.styles"
import { useDispatch, useSelector } from "react-redux"
import { fetchOrganization, fetchOrganizations, fetchOrganizationsAsAdmin } from "entities/Organization"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
import { useTheme } from "../../shared/context"

const ChangeOrganization = ({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const organizations = useSelector(state => state.organization.organizations)
  const organization = useSelector(state => state.organization.currentOrganization)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    axios.get("/profiles/my_profile").then(res => {
      if (res.data.status === "admin") {
        dispatch(fetchOrganizationsAsAdmin())
      } else dispatch(fetchOrganizations())
    })
    dispatch(fetchOrganizations())
  }, [])

  function handleChange(newValue) {
    dispatch(fetchOrganization(newValue))
    setAnchorEl(null)
  }

  return (
    <div>
      {!!organizations && organizations.length > 1 && (
        <>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={handleClick}
            sx={{
              borderRadius: theme.spacing(2),
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            Выбрать организацию
          </Button>
          <Menu
            id={"changeOrgainzation_menu"}
            disableScrollLock={true}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            sx={{ maxHeight: "50vh" }}
          >
            {organizations?.map(e => (
              <MenuItem
                id={e.label}
                key={e.value}
                selected={e.value === organization?.id}
                onClick={() => handleChange(e.value)}
              >
                {e?.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  )
}

export default ChangeOrganization
