import { useCallback, useState } from "react"
import { removeObjectProperty } from "shared/util"

export interface IFilters {
  course?: string
  stage?: string
  module?: string
  participant__user__last_name__icontains?: string
  participant__user__email__icontains?: string
  participant__user__submission__icontains?: string
  full_name__icontains?: string
  full_name?: string
  email__icontains?: string
  email?: string
  title__icontains?: string
  content_block?: string
  date?: string
  status?: string
  submission__status?: string
  criterion?: string
  participant__id?: string
  user_id?: string
  team?: string
  rule?: string
  mark_value?: string
  page?: number
}

export interface IFilter {
  target: { value: string | number; name: string }
}

interface IInitialFilters {
  [x: string]: string
}

export const useFiltersForm = (initialFilters?: IInitialFilters) => {
  const [filters, setFilters] = useState<IFilters>(initialFilters || {})

  const handleFilters = useCallback((filter: IFilter) => {
    if ((filter.target.name === "criterion" || filter.target.name === "module") && filter.target.value === "") {
      setFilters(prev => removeObjectProperty("rule", prev))
      setFilters(prev => removeObjectProperty("mark_value", prev))
    }
    if (filter.target.value === "" || filter.target.value === null) {
      setFilters(prev => removeObjectProperty(filter.target.name, prev))
    } else {
      setFilters(prev => ({ ...prev, [filter.target.name]: filter.target.value }))
    }
  }, [])

  const resetFilters = () => {
    setFilters(initialFilters || {})
  }

  return {
    filters,
    handleFilters,
    setFilters,
    resetFilters,
  }
}
