//@ts-nocheck
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import EmailOk from "images/mail_ok.svg"
import EmailLate from "images/mail_error.svg"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { LandingFormInput } from "./ui/LandingFormInput"
import { useTheme } from "shared/context"
import { Button, Modal } from "shared/ui"
import { PolicyText } from "shared/assets"
import * as Styled from "./ui/Landing.styles"
import { schema } from "./lib/FeedbackFormSchema"
import { Typography } from "@mui/material"
import ImageGirl from "images/girl_mail.svg"
import { sendFeedbackEmail } from "./api"

export const FeedbackForm = () => {
  const theme = useTheme()
  const methods = useForm({ resolver: yupResolver(schema) })
  const {
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = methods
  const [imageModal, setImageModal] = useState<string | null>(null)
  const [helperText, setHelperText] = useState("")
  const location = useLocation()
  const formRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      if (location.hash && formRef) {
        formRef.current.scrollIntoView({ behavior: "instant", block: "center" })
      }
    }, 1000)
  }, [formRef])

  const sendQuestion = (data: { text: string; email: string; name: string }) => {
    sendFeedbackEmail(data.name, data.text, data.email)
      .then(res => {
        setImageModal(EmailOk)
        setHelperText("Ваше письмо успешно отправлено! \n Новое письмо Вы можете отправить через 1 минуту.")
      })
      .catch(err => {
        if (err.response.data["email"]) {
          setError("email", {
            type: "server",
            message: "Почты с таким названием не существует. Проверьте правильность введённых данных.",
          })
        } else {
          let second = /\d+/.exec(err.response.data.detail)
          setHelperText(`Вы можете отправить новое письмо через ${second} секунд(ы), пожалуйста, подождите.`)
          setImageModal(EmailLate)
        }
      })
  }

  return (
    <Styled.FeedbackFormMainDiv id="feedback-form" ref={formRef}>
      <Styled.StyledTitle variant={"h2"} sx={{ color: "#313439", width: "100%" }}>
        Есть вопросы? Напишите нам!
      </Styled.StyledTitle>
      <Styled.FeedbackFormDiv>
        <Styled.StyledImage src={ImageGirl} />
        <FormProvider {...methods}>
          <Styled.FieldsDiv>
            <LandingFormInput required={true} name={"email"} type={"email"} label={"Почта"} />
            <LandingFormInput required={true} name={"name"} type={"name"} label={"Имя"} />
            <LandingFormInput required={true} name={"text"} type={"text"} label={"Текст письма"} />
            <PolicyText text={"ОТПРАВИТЬ"} />
            <Button
              onClick={handleSubmit(sendQuestion)}
              variant={"contained"}
              color={"primary"}
              size={"medium"}
              sx={{
                width: "30%",
                [theme.breakpoints.down("md")]: { width: "50%" },
                [theme.breakpoints.down("sm")]: { width: "100%" },
              }}
            >
              Отправить
            </Button>
          </Styled.FieldsDiv>
        </FormProvider>
      </Styled.FeedbackFormDiv>
      <Modal isOpen={imageModal} onClose={() => setImageModal(null)} borderRadius={theme.spacing(4)}>
        <Styled.FeedbackSendMailForm>
          <Styled.StyledResultImage src={imageModal} alt={"Результат отправки формы фидбэка"} />
          <Typography
            variant={"body1"}
            fontSize={"medium"}
            fontWeight={"medium"}
            sx={{ [theme.breakpoints.down("xs")]: { textAlign: "center" } }}
          >
            {helperText}
          </Typography>
        </Styled.FeedbackSendMailForm>
      </Modal>
    </Styled.FeedbackFormMainDiv>
  )
}
