import * as Styled from "../styles/OrganizationStatisticsTable.styled"
import OrganizationStatisticsTable from "./OrganizationStatisticsTable"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { OrganizationStatisticsFilters } from "./OrganizationStatisticsFilters"
import { useEffect, useState } from "react"
import {
  downloadOrganizationEventStatistics,
  getOrganizationEventStatistics,
  getOrganizationRequestsAndStatusesEventStatistics,
} from "../../api"
import { TableTotalCount } from "shared/assets"
import { downloadZipFile } from "shared/util/downloadZipFile"
import { useStatistics } from "../../model/StatisticsContext"
import { StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import {
  getStatisticsHeaders,
  pickCurrentCounterLabel,
  pickTotalCounterLabel,
  requestsDownloadOptions,
  statusesDownloadOptions,
} from "entities/Organization/static/statistics"
import { IEvent } from "../../model/Organization.types"

interface IProps {
  type: StatisticsVariants
  isLoadingTable: boolean
  submitTrigger: boolean
  onChangeTab?: (e: string) => void
  event?: IEvent
}

const INIT_PAGE = 2

const OrganizationStatisticsRequestsAndStatusesTemplate = ({
                                                             type,
                                                             isLoadingTable,
                                                             submitTrigger,
                                                             onChangeTab,
                                                             event,
                                                           }: IProps) => {
  const { statistics, setTableData, resetStatisticsTable, dateRange } = useStatistics()
  const { data } = statistics[type]
  const { count, results, next } = data
  const [filters, setFilters] = useState({})
  const [sortBy, setSortBy] = useState<{ type: string; direction: number } | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [page, setPage] = useState(INIT_PAGE)

  const organization = useAppSelector(state => state.organization.currentOrganization)
  const currentCourse = useAppSelector(state => state.course.course)

  useEffect(() => {
    if (isLoadingTable) {
      setPage(INIT_PAGE)
    }
  }, [isLoadingTable])

  useEffect(() => {
    if (event && (!dateRange || dateRange.type !== "custom")) {
      const tablePayload = {
        course: currentCourse?.id,
        type: type === "requests" ? "applications_table" : "statuses_table",
        page: 1,
        date_range: dateRange.type,
        start: dateRange?.type === "custom" ? dateRange?.start : undefined,
        finish: dateRange?.type === "custom" ? dateRange?.finish : undefined,
      }
      getOrganizationRequestsAndStatusesEventStatistics(organization?.id, tablePayload).then(res => {
        setTableData(type, res)
      })
    }
  }, [event, type, dateRange])


  const basePayload = {
    course: currentCourse?.id,
    type: type === "requests" ? "applications_table" : "statuses_table",
    date_range: dateRange.type,
    start: dateRange?.type === "custom" ? dateRange?.start : undefined,
    finish: dateRange?.type === "custom" ? dateRange?.finish : undefined,
    sort: sortBy?.type,
    direction: sortBy?.direction === 1 ? "asc" : "desc",
    ...filters,
  }

  async function fetchParticipants() {
    if (!organization?.id) return
    try {
      setIsLoading(true)
      const tablePayload = {
        page: page,
        ...basePayload,
      }

      getOrganizationRequestsAndStatusesEventStatistics(organization?.id, tablePayload).then(res => {
        setTableData(type, { ...res, results: [...results, ...res.results] })
        setPage(prev => prev + 1)
      })
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchFiltersParticipants(newFilters: any) {
    if (!organization?.id) return
    resetStatisticsTable(type)
    try {
      setPage(2)
      setIsLoading(true)
      const tablePayload = {
        page: 1,
        ...basePayload,
        ...newFilters,
        sort: sortBy?.type,
        direction: sortBy?.direction === 1 ? "asc" : "desc",
      }
      await getOrganizationRequestsAndStatusesEventStatistics(organization?.id, tablePayload).then(res => {
        setTableData(type, {
          count: res.count,
          next: res.next,
          results: res.results,
          isLoaded: true,
        })
      })
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchSortParticipants(sortData: { type: string; direction: number }) {
    if (!organization?.id) return
    try {
      setPage(2)
      setIsLoading(true)
      const tablePayload = {
        page: 1,
        ...basePayload,
        ...filters,
        sort: sortData.type,
        direction: sortData.direction === 1 ? "asc" : "desc",
      }
      await getOrganizationEventStatistics(organization?.id, tablePayload).then(res => {
        setTableData(type, {
          count: res.count,
          next: res.next,
          results: res.results,
          isLoaded: true,
        })
      })
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchDownloadParticipants(downloadType: number | "all") {
    if (!organization?.id) return
    try {
      setIsDownloading(true)
      const payload = {
        ...basePayload,
        sort: sortBy?.type,
        direction: sortBy?.direction === 1 ? "asc" : "desc",
        download: "xlsx",
        download_type: type === "requests" ? "total" :
          downloadType === 1 ? "staff" :
            downloadType === 2 ? "active" :
              downloadType === 3 ? "others" :
                "total"
      }
      const response = await downloadOrganizationEventStatistics(organization?.id, payload)
      const requestsFileName = `Таблица динамики по заявкам_${currentCourse?.id}_${organization.title}_(${requestsDownloadOptions[0].fileName}).xlsx`
      const statusesFileName = `Таблица данных по статусам_${currentCourse?.id}_${organization.title}_(${downloadType === "all" ? "все участники" : statusesDownloadOptions.find(option => option.value === downloadType)?.fileName}).xlsx`
      type === "requests"
        ? await downloadZipFile(response.data, requestsFileName)
        : await downloadZipFile(response.data, statusesFileName)
    } catch {
    } finally {
      setIsDownloading(false)
    }
  }

  const handleFilters = (filters: any) => {
    const newFilters = Object.keys(filters).reduce((acc, key) => ({ ...acc, [key]: filters[key] }), {})
    setFilters(newFilters)
    fetchFiltersParticipants(newFilters)
  }

  const handleSort = (sortData: { type: string; direction: number }) => {
    setSortBy(sortData)
    resetStatisticsTable(type)
    fetchSortParticipants(sortData)
  }

  if (!event) return <EmptyTable type={type} />

  return (
    <div>
      <OrganizationStatisticsFilters
        type={type}
        submitTrigger={submitTrigger}
        handleFilters={handleFilters}
        onDownload={fetchDownloadParticipants}
        isDownloading={isDownloading || isLoadingTable || isLoading}
      />
      <TableTotalCount
        totalCount={count || 0}
        filterCount={results?.length}
        totalLabel={pickTotalCounterLabel(type)}
        currentLabel={pickCurrentCounterLabel(type)}
      />
      <OrganizationStatisticsTable
        type={type}
        headers={getStatisticsHeaders(type)}
        participants={results}
        setSortBy={handleSort}
        isLoading={isLoading || isLoadingTable}
        loadMore={fetchParticipants}
        hasNextPage={next}
        onChangeTab={onChangeTab}
        isBadges={type === "requests" ? true : type === "statuses" ? true : type === "survey"}
      />
    </div>
  )
}

const EmptyTable = ({ type }: { type: StatisticsVariants }) => {
  const pickLabel = () => {
    if (type === "requests") {
      return "Здесь будет отображена таблица вкладки “Динамика по заявкам мероприятий организации”"
    }
    if (type === "statuses") {
      return "Здесь будет отображена таблица вкладки “Данные по статусам участников на мероприятии”"
    }
  }

  return (
    <Styled.EmptyStatisticsTable>
      <Styled.EmptyLabel>{pickLabel()}</Styled.EmptyLabel>
      <Styled.EmptyLabel>Но сначала необходимо выбрать данные для отображения</Styled.EmptyLabel>
    </Styled.EmptyStatisticsTable>
  )
}

export { OrganizationStatisticsRequestsAndStatusesTemplate }
