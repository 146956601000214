import * as Styled from "./ui/Teams.style.js"
import { Modal } from "shared/ui"
import { TeamTable, MembersLimit } from "entities/Team/index.js"
import { useSelector } from "react-redux"
import useErrorMsg from "shared/hooks/useErrorMsg.ts"

export const EditRolesModal = ({ isOpen, onClose, team }) => {
  const { errorMsg, setErrorMsg } = useErrorMsg(null)
  const membersLimit = useSelector(state => state.course.membersLimit)
  const membersCount = (team?.members && team?.members.filter(member => member.status === "member").length) || 0

  return (
    <Modal title={"Изменение ролей в команде"} isOpen={isOpen} onClose={onClose} error={errorMsg}>
      <Styled.ContentWrapper>
        <MembersLimit count={membersCount} limit={membersLimit} />
        <TeamTable team={team} isEdit={true} setErrorMsg={setErrorMsg} />
      </Styled.ContentWrapper>
    </Modal>
  )
}
