import { compareStringsAscending, compareStringsByDescending } from "shared/util"

export const statusEnum = {
  "": "",
  failed: "failed",
  in_progress: "in_progress",
  teacher: "teacher",
  moderator: "moderator",
}

export const progressEnum = { "": "", done: "true", fail: "false" }

export const activityEnum = { "": "", active: "true", inactive: "false" }

export const newActivityEnum = { "": "", activate: "true", deactivate: "false" }

export const ruleEnum = { "": "", ">": "asc", "<": "desc" }

export const formatEnum = { xlsx: ".xlsx", csv: ".csv" }

export function formCategories(options) {
  let result = { "Не выбрано": "" }
  if (!options) return result
  options.forEach(option => (result = { ...result, [option]: option }))
  return result
}

export function formStages(stages) {
  const accesibleOptions = stages?.filter(stage => !stage.is_empty).filter(stage => stage.access_type === "restricted")
  let result = accesibleOptions?.length ? { "": "" } : {}
  if (!stages) return result
  accesibleOptions.forEach(stage => (result = { ...result, [`${stage.title}`]: stage.id }))

  return result
}

export const sortItems = (a, b, sortBy) => {
  if (sortBy.type === "score") {
    return a.score > b.score ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "rank") {
    return a.rank > b.rank ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "completed") {
    return a.completed ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "email") {
    return a.participant.email > b.participant.email ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "status") {
    return statusEnum[a.participant.status] > statusEnum[b.participant.status] ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "category") {
    if (sortBy.direction === 1) return compareStringsAscending(a.participant.category, b.participant.category)
    else return compareStringsByDescending(a.participant.category, b.participant.category)
  }
  if (sortBy.type === "is_active") {
    return a.participant.is_active > b.participant.is_active ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "name" || sortBy.type === "full_name") {
    if (sortBy.direction === 1) return compareStringsAscending(a.participant.full_name, b.participant.full_name)
    else return compareStringsByDescending(a.participant.full_name, b.participant.full_name)
  }
  if (sortBy.type === "team_id") {
    return a.id > b.id ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "team_name") {
    return a.title > b.title ? sortBy.direction : -sortBy.direction
  }
  if (sortBy.type === "mentor_name") {
    if (sortBy.direction === 1) return compareStringsAscending(a.full_name, b.full_name)
    else return compareStringsByDescending(a.full_name, b.full_name)
  }
  if (sortBy.type === "mentor_email") {
    return a.email > b.email ? sortBy.direction : -sortBy.direction
  }
}
