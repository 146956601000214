import { styled } from "@mui/styles"
import { BackButton } from "shared/ui/Button/BackButton"
import { PageContainer } from "shared/ui/PageContainer"
import { TeamLeaderboard } from "widgets/Statistics/TeamLeaderboard"
// @ts-ignore
import { useParams } from "react-router-dom"

const LeaderboardPage = () => {
  const params = useParams()
  const backLink = `/event/${params.name}/stages`

  return (
    <PageContainer>
      <Indent />
      <BackButton url={backLink} />
      <TeamLeaderboard />
    </PageContainer>
  )
}

const Indent = styled("div")(() => ({
  marginTop: 105,
}))

export default LeaderboardPage
