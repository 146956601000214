import styled from "@emotion/styled"

export const CardWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  min-height: 350px;

  & > :nth-child(1) {
    flex: 1;
  }
  & > :nth-child(2) {
    flex: 0.87;
  }
`

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`

export const CreateTemplateButton = styled.span`
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #a1c1e6;
`

export const InitialLabel = styled.span`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding-top: 150px;
  min-height: 485px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27.9px;
  width: fit-content;
  margin-inline: auto;
  color: #52555c;
`

export const EmptyLabel = styled.span`
  line-height: 20.7px;
  color: #d82e2e;
`

export const LoadingLabel = styled(InitialLabel)`
  color: #000000;
`
