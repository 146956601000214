export default function getSetCategoryMessage(status: number) {
  if (status === 400) {
    return "Выбранная роль не доступна на данном курсе."
  }
  if (status === 409) {
    return "Достигнут лимит участников команды."
  }
  if (status === 412) {
    return "Главный Администратор может быть только один. Cнимите роль, чтобы назначить нового Главного Администратора."
  }
  if (status === 416) {
    return "Превышен лимит участников в командах на курсе. Проверьте, в каком количестве команд состоит пользователь на курсе."
  }
  if (status === 423) {
    return 'Превышен лимит пользователей с ролью "Без роли" в командах на курсе. Проверьте, в каком количестве команд состоит пользователь на курсе.'
  }
  if (status === 426) {
    return "Вы не можете понизить роль Главному Администратору команды."
  }
}
