import image1 from "images/programs_01.png"
import image2 from "images/programs_02.png"
import image3 from "images/programs_03.png"

export const carouselItems = []

export const programItems: Array<{ image: string; title: string; text: string; href: string }> = [
  {
    image: image1,
    title: "Проекты и исследования",
    text: "Изучение нейротехнологий и физиологии человека на практике",
    href: "/catalog/Проекты и исследования",
  },
  {
    image: image2,
    title: "Инженерно-техническое направление",
    text: "Создание и программирование роботов на базе Raspberry и Arduino",
    href: "/catalog/Инженерно-техническое направление",
  },
  {
    image: image3,
    title: "Научное волонтерство",
    text: "Создание сайтов, разработка игр и интерфейсов, анализ данных на Python",
    href: "/catalog/Научное волонтерство",
  },
]

export const mentorItems: Array<{ full_name: string; status: string; image: string }> = [
  {
    full_name: "Бондарь Анна",
    status:
      "Руководитель образовательной программы Проекта «Всероссийский атлас почвенных микроорганизмов», руководитель Проекта «Подготовка наставников на основе сетевых исследовательских проектов»",
    image: "https://syncwoia.com/static/media/extra/837/event-atlas/prof/Bondar.png",
  },
  {
    full_name: "Гичгелдиева Майя",
    status:
      "Менеджер Проекта «Подготовка наставников на основе сетевых исследовательских проектов», преподаватель Проекта «Всероссийский атлас почвенных микроорганизмов»",
    image: "https://www.syncwoia.com/static/media/extra/837/event-atlas/prof/Gichgeldieva.png",
  },
  {
    full_name: "Седых Сергей",
    status:
      "Кандидат биологических наук, научный руководитель Фонда «Образование», научный сотрудник ИХБФМ СО РАН, ст. преподаватель НГУ и СУНЦ НГУ, методист направления «Агропромышленные и биотехнологии»",
    image: "https://syncwoia.com/static/media/extra/837/event-atlas/prof/Sedykh.png",
  },
]

export const organizationItems: Array<{
  title: string
  logo: string
  slug: string | null
  location: string
  description: string
  id: number
}> = [
  {
    title: "Дворец школьников г. Алматы",
    logo: "https://syncwoia.com/user-files/extra/orglogo/logo_school-palace.png",
    slug: "smartpark",
    location: "Казахстан, г. Алматы",
    description:
      "Дворец Школьников \u2013 старейшая и одна из ведущих организаций развития дополнительного образования Республики Казахстан. Сегодня включает в себя более 10 направлений развития детей, например, научно-техническое, творческое, спортивное и другие направления. Обладает развитой инфраструктурой и передовой научно-технической базой.",
    id: 53,
  },
  {
    title: "Фонд «Поддержка проектов в области образования»",
    logo: "https://syncwoia.com/user-files/extra/orglogo/logo_fond-edu_color.png",
    slug: null,
    description:
      "Инициатором создания фонда является группа компаний «Тион». Учредители фонда: Новосибирский Государственный Университет, Технопарк Новосибирского Академгородка.<br/>" +
      "Фонд «Поддержка проектов в области образования» предназначен для стимулирования различных инициатив, связанных с развитием одаренности у детей школьного возраста в естественно-научной и научно-технической сфере.",
    location: "Россия, г. Новосибирск",
    id: 9,
  },
]
