import { InvitesParticipantFilters } from "entities/Organization/ui/InvitesParticipantFilters"
import { ParticipantsCard } from "entities/Organization/ui/ParticipantsCard"
import { TableTotalCount as ParticipantsCount } from "shared/assets"
import { useAppSelector } from "shared/hooks/useAppSelector"
import ParticipantsTable from "entities/Organization/ui/ParticipantsTable"
import styled from "@emotion/styled"
import ParicipantMassAccess from "entities/Organization/ui/ParicipantMassAccess"
import ParticipantsMassNotification from "entities/Organization/ui/ParticipantsMassNotification"
import useParticipants from "../model/useParticipants"
import { FormControlLabel, Switch, Typography } from "@mui/material"
import { useState } from "react"
import { Tooltip } from "shared/ui"
import HelpIcon from "@mui/icons-material/Help"
import { courseKeyDefault, CourseKeyType } from "shared/store/course/CourseSlice"

export type PageLocationType = "admin_courses" | "organizations"

interface IProps {
  isTriggered: boolean
  setIsTriggered: (x: boolean) => void
  pageLocation: PageLocationType
  courseKey: CourseKeyType
}

const ParticipantsInProgress = ({ isTriggered, setIsTriggered, pageLocation, courseKey = courseKeyDefault }: IProps) => {
  const status = "in_progress"
  const [visibilityCompleted, setVisibilityCompleted] = useState(false)
  const {
    isLoading,
    participants,
    allParticipants,
    allUsers,
    total,
    hasNextPage,
    stages,
    headers,
    setParticipants,
    handleSort,
    handleFilters,
    fetchParticipants,
  } = useParticipants({pageLocation, status, isTriggered, setIsTriggered, courseKey})

  const course = useAppSelector(state => state.course[courseKey])

  if (!course?.id) return

  return (
    <ParticipantsCard title="Участники курса">
      <div>
        <FiltersWrapper>
          <InvitesParticipantFilters isStageFilter={true} handleFilters={handleFilters} stages={stages} />
          <ParicipantMassAccess allParticipants={allParticipants} stages={stages} />
        </FiltersWrapper>
        <ParticipantsMassNotification allUsers={allUsers} />
        <CountAndCompleteWrapper>
          <ParticipantsCount
            filterCount={participants.length}
            totalCount={total || 0}
            totalLabel={"Всего участников, соответствующих фильтру: "}
          />
          {course.type === "webinar" && status === "in_progress" && (
            <CompletedVisibilitySettingWrapper>
              <FormControlLabel
                control={
                  <Switch checked={visibilityCompleted} onChange={e => setVisibilityCompleted(e.target.checked)} />
                }
                label={
                  <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
                    Отображать посещаемость
                  </Typography>
                }
              />
              <Tooltip
                color={"#FFF"}
                backgroundColor={"#52555C"}
                width={200}
                text={"Пользователям, посетившим мероприятие, будут вручены сертификаты"}
                placement={"left"}
              >
                <HelpIcon sx={{ color: "#52555C" }} />
              </Tooltip>
            </CompletedVisibilitySettingWrapper>
          )}
        </CountAndCompleteWrapper>
        <ParticipantsTable
          status={status}
          headers={headers}
          participants={participants}
          setParticipants={setParticipants}
          setSortBy={handleSort}
          isLoading={isLoading}
          completedVisible={visibilityCompleted}
          loadMore={fetchParticipants}
          hasNextPage={hasNextPage}
          isAccessButtons={false}
          courseKey={courseKey}
        />
      </div>
    </ParticipantsCard>
  )
}

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const CountAndCompleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

const CompletedVisibilitySettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`

export { ParticipantsInProgress }
