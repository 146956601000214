import React, { useEffect, useState } from "react"
import { Autocomplete, Button, TextField } from "@mui/material"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useUserData } from "./UserContext"

export const UserAutocompleteChoice = () => {
  const { user, setUser } = useUserData()

  const [emails, setEmails] = useState(null)
  const [users, setUsers] = useState(null)
  const [userLoading, setUserLoading] = useState(false)

  var abortController = new AbortController()

  function getUsers(startsWith) {
    setUserLoading(true)
    abortController.abort() // прерываем предыдущий запрос
    let newAbortController = new AbortController()
    axios
      .get("/profiles/", {
        params: { page: 1, email__startswith: startsWith },
        signal: newAbortController.signal,
      })
      .then(res => {
        setUsers(res.data.results)
        setEmails(res.data.results.map(e => e.email))
        setUserLoading(false)
      })
      .catch(error => {
        setUserLoading(false)
      })
    abortController = newAbortController
  }

  function handleInputChange(event, value) {
    getUsers(value)
  }

  const handleChangeUser = (event, newValue) => {
    setUser(users.filter(e => e.email == newValue)[0])
  }

  function getUserProfile() {
    if (!user) return
    axios.get(`/profiles/${user.id}/`).then(res => {
      axios.get("/content/progress/", { params: { participant__user__id: user.id } }).then(r => {
        let arr = []
        r.data.map(e => arr.push(e.participant))
        res.data.progress = arr
        setUser(res.data)
      })
    })
  }

  useEffect(() => {
    getUsers("")
  }, [])

  if (!users) return null

  return (
    <div style={{ display: "flex", alignItems: "center", width: 1200, flexDirection: "column" }}>
      <Autocomplete
        disablePortal
        id="emails"
        onChange={handleChangeUser}
        onInputChange={handleInputChange}
        loading={userLoading}
        loadingText={"Загрузка данных..."}
        options={emails}
        renderInput={params => <TextField {...params} variant="outlined" style={{ width: 800 }} label="Почта" />}
      />
      <Button onClick={getUserProfile} variant="contained" color="primary">
        Посмотреть профиль
      </Button>
    </div>
  )
}
