import * as yup from "yup"
import moment from "moment"
import { IFilters } from "shared/hooks/useFiltersForm"
import { AssignmentStatus } from "../model/assignment.types"
import { assignmentsActions } from "shared/store"
import { removeObjectProperty } from "shared/util"
import { getAssignments } from "entities/Assignment/api/assignment"

type FilterKeys = keyof IFilters

export const formingFilters = (filters: IFilters) => {
  let result = Object.keys(filters).reduce((acc, key) => {
    if (!filters[key as FilterKeys]) return acc
    if (key === "date") return { ...acc, [key]: formingDate(filters[key]) }
    return { ...acc, [key]: filters[key as FilterKeys] }
  }, {} as IFilters)
  let status
  if (result.status) {
    status = result.status
  } else {
    status = filters.mark_value ? AssignmentStatus.accepted : AssignmentStatus.submitted
  }
  if (status) result = { ...result, status }
  return result
}

const formingDate = (date?: string) => moment(date, "DD.MM.YYYY").format("YYYY-MM-DD")

export const assignmentFiltersSchema = yup.object().shape({
  full_name: yup.string(),
  email: yup
    .string()
    .max(32, "Длина почты не должна превышать 32 символа.")
    .test({
      name: "email",
      test: function (value) {
        if (!value) return true
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      },
      message: "Почта введена неверно.",
    }),
  date: yup.string().test({
    name: "date",
    test: function (value) {
      if (!value) return true
      return /^\d{2}\.\d{2}\.\d{4}$/.test(value)
    },
    message: "Неверный формат даты.",
  }),
  user_id: yup.string(),
})

export const handleFetchAssignments = async (newFilters: IFilters, page: number, dispatch: any) => {
  try {
    dispatch(assignmentsActions.setIsLoading(true))
    const filteredPayload = removeObjectProperty("page", newFilters)
    const payload = { ...filteredPayload, page }
    const response = await getAssignments(payload)
    dispatch(assignmentsActions.setFilters(newFilters))
    dispatch(assignmentsActions.setAssignments(response))
  } catch {
    dispatch(
      assignmentsActions.setAssignments({
        data: [],
        next: false,
        count: 0,
      })
    )
  } finally {
    dispatch(assignmentsActions.setIsLoading(false))
  }
}
