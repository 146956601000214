import React from "react"
import { TableCell } from "@mui/material"
import { useAppSelector } from "../../shared/hooks/useAppSelector"
import * as Styled from "./ui/Iot.styles"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch"
import { deleteUser } from "../../entities/Iot"

export const DeleteUser = ({ email }: { email: string }) => {

  const currentHardware = useAppSelector(state => state.iot.currentHardware)
  const dispatch = useAppDispatch()

  function handleClick(){
    if(!!currentHardware) dispatch(deleteUser({ email: email, serial: currentHardware?.serial }))
  }

  return (
    <TableCell align={"center"}>
      <Styled.CustomDeleteIcon onClick={handleClick}/>
    </TableCell>
  )
}