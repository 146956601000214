import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
    certificatesBlock: {
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "column",
        padding:"0 0 60px 0 !important",
        gap: 20,
    },
    certificatesIcon: {
        width: 350,
        height: 250,
        display:"block",
        "& span": {
            width: 350,
            height: 250,
            display:"block",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        },
    },
    certificatesTitle: {
        "& p": {
            fontFamily: "Roboto",
            fontSize: 18,
            lineHeight: '22px',
            fontWeight: 600,
            letterSpasing:0.08,
            width:350,
            /*padding:"20px 0 20px 0",*/
        },  
    },
    certificatesDate: {
        "& p": {
            fontFamily: "Roboto",
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 400,
            width:350,
            color:"#5E5C5C",
        },  
    },

}))
