import React, { useState } from "react";
import { ThemeProvider as MuiThemeProvider, useTheme as muiUseTheme } from "@mui/material/styles";
import Themes from "../themes";

const ThemeDispatchContext = React.createContext();

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(
    Themes[localStorage.getItem("theme")] || Themes.default
  );

  const handleChangeTheme = (newThemeName) => {
    localStorage.setItem("theme", newThemeName)
    setTheme(Themes[newThemeName] || Themes.default)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeDispatchContext.Provider value={handleChangeTheme}>
        {children}
      </ThemeDispatchContext.Provider>
    </MuiThemeProvider>
  );
}

function useTheme() {
  var context = muiUseTheme();
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
}

function useThemeDispatch() {
  var context = React.useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error("useThemeDispatch must be used within a ThemeProvider");
  }
  return context;
}

export { ThemeProvider, useTheme, useThemeDispatch };
