import React from "react"
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from "react-hook-form"
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Typography } from "@mui/material"
import { useTheme } from "../../../shared/context"
import { TextField } from "../../../shared/ui"
import { errorMessages } from "react-unity-webgl/distribution/constants/error-messages"

export const CheckBoxFormInput = ({
  name,
  label,
  options,
}: {
  options: Array<string>
  name: string
  label: string
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()

  const theme = useTheme()

  const errorMessage = errors[name] ? errors[name]?.message : ""
  const textFieldErrorMessage = errors[`text_field_${name}`] ? errors[`text_field_${name}`]?.message : ""

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    let arr = field?.value?.split(";") || []
    let value = option
    if (event.target.checked) arr.push(value)
    else {
      let indexToRemove = arr.indexOf(value)
      if (indexToRemove !== -1) {
        arr.splice(indexToRemove, 1)
      }
    }
    if (arr.length > 0 && arr[0].trim() === "") {
      arr.splice(0, 1)
    }
    field.onChange(arr.join(";"))
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errorMessage} sx={{ [theme.breakpoints.down("sm")]: { gap: theme.spacing(3) } }}>
            <Typography variant={"h4"} sx={{ marginBottom: theme.spacing(2) }}>
              {label}
            </Typography>
            {options.map((option: string, index: number) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => handleChange(event, option, field)}
                    checked={field?.value?.includes(option)}
                  />
                }
                label={
                  <Typography variant={"body1"} fontSize={"medium"} fontWeight={"small"}>
                    {option}
                  </Typography>
                }
              />
            ))}
            {!!errorMessage && <FormHelperText>{`${errorMessage}`}</FormHelperText>}
          </FormControl>
        )}
      />
      {watch(name)?.split(";")?.includes("Другое (указать)") && (
        <Controller
          control={control}
          name={`text_field_${name}`}
          render={({ field }) => (
            <TextField
              error={!!textFieldErrorMessage}
              helperText={textFieldErrorMessage}
              variant={"outlined"}
              {...field}
              //@ts-ignore
              style={{
                marginTop: theme.spacing(4),
                width: "300px",
                [theme.breakpoints.down("xs")]: { width: "100%" },
              }}
              label={"Введите свой вариант"}
            />
          )}
        />
      )}
    </div>
  )
}
