import { makeStyles } from "@mui/styles"
import { styled } from "@mui/material/styles"

export const PartnersMainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(12),
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(24),
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const IconPartner = styled("img")(({ theme }) => ({
  cursor: "pointer",
  height: "100px",
  [theme.breakpoints.down("md")]: {
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
}))

export const PartnerItem = styled("a")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
}))
