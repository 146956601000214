export interface IStyle {
  [x: string]: string | number
}

export enum Variant {
  outlined = "outlined",
  contained = "contained",
  standard = "standard",
}

export enum Color {
  inherit = "inherit",
  primary = "primary",
  secondary = "secondary",
  success = "success",
  error = "error",
  info = "info",
  warning = "warning",
}

export enum Size {
  small_x = "small_x",
  small = "small",
  medium = "medium",
  large = "large",
  large_x = "large_x",
}

export type SizeType = Extract<keyof typeof Size, "small" | "medium" | "large">
export type VariantType = keyof typeof Variant
export type ColorType = keyof typeof Color
