import React, {useEffect, useRef, useState} from 'react';
import {Button, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import { axiosInstance as axios } from "shared/api/axiosInstance";
import Cookies from "js-cookie";
import VisibilitySensor from "react-visibility-sensor";
import { NonUserReview } from 'entities/Review';

export const ReviewsNotForUsers = () => {
    
    const [reviews, setReviews] = useState([])
    const [helperText, setHelperText] = useState("")

    const courseRef = useRef()
    const userRef = useRef()
    const textRef = useRef()
    const infoRef = useRef()

    const [page, setPage] = useState(0)
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleScrolledToBottom = (isBottomVisible) => {
        if (isBottomVisible && hasNextPage && !isLoading) {
            getReviews()
        }
    }

    function getReviews() {
        let index = page + 1
        setPage(index)
        setIsLoading(true)
        axios.get("/content/free_reviews/", {params: { page: index }}).then(res => {
            if (!!res.data.results) {
                setReviews([...reviews, ...res.data.results])
            }
            setHasNextPage(!!res.data.next)
            setIsLoading(false)
        })
    }

    function addReview() {
        let data = {
            course: courseRef.current.value,
            full_name: userRef.current.value,
            text: textRef.current.value,
            position: infoRef.current.value
        }
        axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
        axios.post('/content/free_reviews/', data).then(res => {
            reviews.push(res.data)
            setReviews([...reviews])
            setHelperText("Отзыв добавлен")
        }).catch(err => {
            if (err.response.status === 400 && err.response.data.non_field_errors) {
                let index = searchIndex(Number(data.course), data.full_name)
                axios.put(`/content/free_reviews/${reviews[index].id}/`, data).then(res => {
                    reviews[index] = res.data
                    setReviews([...reviews])
                    setHelperText("Отзыв обновлен")
                })
            }
        })
    }

    function deleteReview(index, e) {
        axios.defaults.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
        axios.delete(`/content/free_reviews/${e.id}`,).then(res => {
            reviews.splice(index, 1)
            setReviews([...reviews])
            setHelperText("Отзыв удален")
        })
    }

    function searchIndex(course, full_name) {
        let index
        for (let i in reviews) {
            if (reviews[i]["course"] === course && reviews[i]["full_name"] === full_name)
                index = i
        }
        return index
    }

    useEffect(() => {
        getReviews()
    }, [])


    return (
        <div style={{width: "70%", margin: "auto"}}>
            <Typography variant="h5" style={{ textAlign: "center", color: "red", marginTop: 24 }}>
                {helperText}
            </Typography>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 24}}>
                <TextField
                    inputRef={courseRef}
                    variant="outlined"
                    helperText="ID курса"
                    fullWidth
                    style={{marginTop: 24}}
                />
                <TextField
                    inputRef={userRef}
                    variant="outlined"
                    helperText="ФИО"
                    fullWidth
                    style={{marginTop: 24}}
                />
            </div>
            <TextField
                inputRef={infoRef}
                variant="outlined"
                helperText="Информация о пользователе"
                type="number"
                fullWidth
                multiline={true}
                style={{marginTop: 24}}
            />
            <TextField
                inputRef={textRef}
                variant="outlined"
                helperText="Текст комментария"
                fullWidth
                multiline
                style={{marginTop: 24}}
            />

            <div style={{marginTop: 24}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{width: 250, height: 50, marginBottom: 6}}
                    onClick={addReview}
                >
                    Создать / обновить
                </Button>
            </div>
            <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
                <Grid spacing={6} direction={"row"} container justifyContent={"start"} style={{marginTop: "40px"}}>
                    {
                        reviews.map((e, index) =>
                            <NonUserReview review={e} actions={
                                <Button onClick={p => deleteReview(index, e)}>Удалить</Button>
                            }/>
                        )
                    }
                </Grid>
            </VisibilitySensor>
            {isLoading &&
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 40 }}><CircularProgress size={26} /></div>
            }
        </div>
    );
};
