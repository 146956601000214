import React from "react"
import * as Styled from "./Landing.styles"
import Image1 from "images/iot_farm.gif"
import Image2 from "images/iot_sensors.gif"
import { useTheme } from "shared/context"

export const WorkWithHardware = () => {
  const theme = useTheme()
  return (
    <Styled.WorkWithHardwareMainDiv>
      <Styled.StyledTitle variant={"h2"} sx={{ color: "#313439" }}>
        Интернет научно-образовательных вещей
      </Styled.StyledTitle>
      <Styled.MiddleTypography
        variant={"body1"}
        fontWeight={"medium"}
        fontSize={"medium"}
        sx={{ [theme.breakpoints.down("sm")]: { marginBottom: theme.spacing(6) } }}
      >
        Работайте с оборудованием беспрерывно – очно и удаленно.
        <p>
          Собирайте, анализируйте данные и проводите эксперименты с помощью киберфизической платформы Syncwoia и
          научно-образовательных комплексов нового поколения!
        </p>
      </Styled.MiddleTypography>
      <Styled.HardwareDiv>
        <Styled.WorkWithHardwareDescriptionDiv>
          <img src={Image1} alt={"Image with scheme of IOT"} />
        </Styled.WorkWithHardwareDescriptionDiv>
        <Styled.GetWorkWithHardwareDiv>
          <Styled.UiIotImage src={Image2} />
        </Styled.GetWorkWithHardwareDiv>
      </Styled.HardwareDiv>
    </Styled.WorkWithHardwareMainDiv>
  )
}
