export const assignmentHeaders = [
  { label: "", sortType: "", width: 96 },
  { label: "\nФИО Ученика", sortType: "name", width: 160 },
  { label: "\nПочта", sortType: "email", width: 160 },
  { label: "\nНазвание задания", sortType: "task", width: 200 },
  { label: "Файлы", sortType: "", width: 200 },
  { label: "Проверить задание", sortType: "", width: 104 },
  { label: "Дата и время загрузки (Мск)", sortType: "date", width: 132 },
  { label: "Статус задания", sortType: "", width: 200 },
  { label: "Балл", sortType: "", width: 132 },
  { label: "Сохранить балл", sortType: "", width: 104 },
]
