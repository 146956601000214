import { useState } from "react"
import { styled } from "@mui/styles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import themes from "shared/themes"
import { PaletteType } from "shared/themes/default.types"
import { Size, SizeType } from "../styles.const"

// @ts-ignore
const palette: PaletteType = themes.default.palette

export const useStyles = (anchor?: HTMLButtonElement | null, value?: string | number, width?: number) => {
  function isActive() {
    return anchor || (value !== undefined && value !== null && value !== "")
  }

  function getColor(isDisabled?: boolean) {
    return isDisabled && !isActive()
      ? palette.additional.light
      : isActive()
      ? palette.additional.main
      : palette.text.hover
  }

  const Title = styled("span")({
    width: "100%",
    paddingRight: themes.default.spacing(6),
    textAlign: "left",
    textWrap: "nowrap",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: themes.default.spacing(1),
    color: palette.text.fill,
  })

  const Arrow = styled(ArrowDropDownIcon)(() => ({
    position: "absolute",
    right: themes.default.spacing(2.5),
    scale: 1.4,
    color: palette.additional.gray,
    transform: anchor ? "rotate(180deg)" : "",
  }))

  const getLabelStyle = (size?: SizeType, isDisabled?: boolean): React.CSSProperties => ({
    position: "absolute",
    left: themes.default.spacing(3),
    translate: isActive() ? getLabelPosition(size) : "",
    fontSize: getLabelFontSize(size),
    scale: isActive() ? "0.87" : "1",
    transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    color: getColor(isDisabled),
    textOverflow: "ellipsis",
    overflow: "hidden",
    textWrap: "nowrap",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    fontSize: "16px",
  })

  const getButtonStyle = (style?: React.CSSProperties, size?: SizeType): React.CSSProperties => {
    const borderRadius = getBorderRadius(size)
    return {
      position: "relative",
      boxSizing: "border-box",
      textTransform: "none",
      borderRadius: anchor ? `${borderRadius} ${borderRadius} 0 0` : borderRadius,
      border: `1px solid ${palette.additional.light}`,
      height: getHeight(size),
      // @ts-expect-error
      "&:hover": {
        border: `1px solid ${palette.primary.main}`,
        backgroundColor: "unset",
      },
      "&.Mui-disabled": {
        backgroundColor: palette.text.disabled,
        borderColor: palette.text.disabled,
      },
      width: `${width}px`,
      ...style,
    }
  }

  const MenuListStyle: React.CSSProperties = {
    pointerEvents: "auto",
    minWidth: 261,
    paddingTop: 0,
  }

  return { Title, Arrow, getLabelStyle, getButtonStyle, MenuListStyle }
}

export const useAnimation = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return { anchorEl, handleClick, handleClose }
}

const getLabelFontSize = (size: SizeType = Size.medium) => {
  return "16px"
}

const getLabelPosition = (size: SizeType = Size.medium) => {
  if (size === Size.small) return "-15px -28px"
  if (size === Size.medium) return "-15px -32px"
}

const getBorderRadius = (size: SizeType = Size.medium) => {
  if (size === Size.small) return "8px"
  if (size === Size.medium) return "8px"
  return "12px"
}

const getHeight = (size: SizeType = Size.medium) => {
  if (size === Size.small) return 32
  if (size === Size.medium) return 40
  if (size === Size.large) return 48
  if (size === Size.large_x) return 64
}

export const NoOptions = styled("div")({
  color: "#98999C",
  marginTop: "8px",
  paddingLeft: "16px",
})
