import * as Styled from "entities/Assignment/ui/styles/EditStudentProgress.styles"
import { useState } from "react"
import { useExtendFiltersForm } from "shared/hooks/useExtendFiltersForm"
import { StudentProgressEditor } from "entities/Assignment/ui/StudentProgressEditor"
import { IParticipantProgress } from "entities/Participant/model/participant.types"
import StudentProgressFilters from "entities/Assignment/ui/StudentProgressFilters"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { PickedCourse } from "entities/Assignment/model/useGetOptions"
import { Loader } from "shared/ui"

const EditStudentProgress = () => {
  const { extendFilters, handleExtendFilters, resetFilters } = useExtendFiltersForm()
  const [progress, setProgress] = useState<IParticipantProgress>()
  const { courses, isLoadingCourses } = useAppSelector(state => state.course)
  const isModerator = Boolean(courses.find((course: PickedCourse) => course.status === "moderator"))

  if (!isModerator) return null
  if (isLoadingCourses) return <Loader />

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title variant="h4">Изменить прогресс ученика</Styled.Title>
      </Styled.Header>
      <Styled.Contatiner>
        <StudentProgressFilters
          filters={extendFilters}
          handleExtendFilters={handleExtendFilters}
          resetFilters={resetFilters}
          progress={progress}
          setProgress={setProgress}
        />
        {progress ? (
          <StudentProgressEditor filters={extendFilters} progress={progress} setProgress={setProgress} />
        ) : (
          <div style={{ flex: 1 }} />
        )}
      </Styled.Contatiner>
    </Styled.Wrapper>
  )
}

export { EditStudentProgress }
