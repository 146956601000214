export const ExitIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.3629 6.19666C12.4172 6.15141 12.4873 6.12959 12.5577 6.13599C12.628 6.14239 12.6929 6.17643 12.7382 6.23064C12.7382 6.23065 12.7382 6.23067 12.7383 6.23068C12.7383 6.23072 12.7383 6.23075 12.7383 6.23079L14.3994 8.22442C14.4407 8.27837 14.4599 8.34612 14.4529 8.41382L14.9835 8.46848L14.4529 8.41382C14.4458 8.48307 14.4119 8.54678 14.3584 8.59135C14.3049 8.63592 14.2361 8.6578 14.1667 8.65233C14.0989 8.64699 14.0357 8.61592 13.9901 8.56559L12.329 6.57193C12.329 6.5719 12.3289 6.57187 12.3289 6.57184C12.2837 6.51753 12.2619 6.4475 12.2683 6.37713C12.2747 6.30672 12.3088 6.24174 12.3631 6.19646L12.0216 5.78684L12.3629 6.19666Z"
        fill="#1565C0"
        stroke="#1565C0"
        stroke-width="1.06667"
      />
      <path
        d="M12.3629 10.6053L12.3628 10.6052C12.3084 10.56 12.2743 10.495 12.2678 10.4246C12.2614 10.3542 12.2831 10.2842 12.3283 10.2299C12.3283 10.2298 12.3283 10.2298 12.3284 10.2297L13.9952 8.23018L13.9953 8.23028L14.0033 8.22019C14.0254 8.19233 14.0529 8.16918 14.0841 8.15209L13.8535 7.73133L14.0841 8.15209C14.1153 8.135 14.1495 8.12432 14.1849 8.12068C14.2203 8.11703 14.256 8.1205 14.29 8.13087C14.324 8.14124 14.3556 8.15831 14.383 8.18108C14.4103 8.20384 14.4328 8.23184 14.4491 8.26342C14.4654 8.295 14.4753 8.32953 14.4781 8.36498C14.4809 8.40043 14.4766 8.43608 14.4654 8.46983C14.4542 8.50359 14.4364 8.53476 14.4129 8.56152L14.4128 8.56142L14.4046 8.57129L12.7383 10.5711C12.7383 10.5711 12.7383 10.5712 12.7383 10.5712C12.693 10.6254 12.6281 10.6595 12.5577 10.6659C12.4873 10.6723 12.4172 10.6505 12.3629 10.6053Z"
        fill="#1565C0"
        stroke="#1565C0"
        stroke-width="1.06667"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9999 8.4C13.9999 8.61217 13.9156 8.81566 13.7656 8.96569C13.6156 9.11571 13.4121 9.2 13.1999 9.2H7.9999C7.78773 9.2 7.58425 9.11571 7.43422 8.96569C7.28419 8.81566 7.1999 8.61217 7.1999 8.4C7.1999 8.18783 7.28419 7.98434 7.43422 7.83431C7.58425 7.68429 7.78773 7.6 7.9999 7.6H13.1999C13.4121 7.6 13.6156 7.68429 13.7656 7.83431C13.9156 7.98434 13.9999 8.18783 13.9999 8.4ZM2.3999 2.8C2.3999 2.58783 2.48419 2.38434 2.63422 2.23431C2.78425 2.08429 2.98773 2 3.1999 2H10.3999C10.6121 2 10.8156 2.08429 10.9656 2.23431C11.1156 2.38434 11.1999 2.58783 11.1999 2.8C11.1999 3.01217 11.1156 3.21566 10.9656 3.36569C10.8156 3.51571 10.6121 3.6 10.3999 3.6H3.1999C2.98773 3.6 2.78425 3.51571 2.63422 3.36569C2.48419 3.21566 2.3999 3.01217 2.3999 2.8ZM2.3999 14C2.3999 13.7878 2.48419 13.5843 2.63422 13.4343C2.78425 13.2843 2.98773 13.2 3.1999 13.2H10.3999C10.6121 13.2 10.8156 13.2843 10.9656 13.4343C11.1156 13.5843 11.1999 13.7878 11.1999 14C11.1999 14.2122 11.1156 14.4157 10.9656 14.5657C10.8156 14.7157 10.6121 14.8 10.3999 14.8H3.1999C2.98773 14.8 2.78425 14.7157 2.63422 14.5657C2.48419 14.4157 2.3999 14.2122 2.3999 14Z"
        fill="#1565C0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3999 2C10.6121 2 10.8156 2.08429 10.9656 2.23431C11.1156 2.38434 11.1999 2.58783 11.1999 2.8V6C11.1999 6.21217 11.1156 6.41566 10.9656 6.56569C10.8156 6.71571 10.6121 6.8 10.3999 6.8C10.1877 6.8 9.98425 6.71571 9.83422 6.56569C9.68419 6.41566 9.5999 6.21217 9.5999 6V2.8C9.5999 2.58783 9.68419 2.38434 9.83422 2.23431C9.98425 2.08429 10.1877 2 10.3999 2ZM10.3999 10C10.6121 10 10.8156 10.0843 10.9656 10.2343C11.1156 10.3843 11.1999 10.5878 11.1999 10.8V14C11.1999 14.2122 11.1156 14.4157 10.9656 14.5657C10.8156 14.7157 10.6121 14.8 10.3999 14.8C10.1877 14.8 9.98425 14.7157 9.83422 14.5657C9.68419 14.4157 9.5999 14.2122 9.5999 14V10.8C9.5999 10.5878 9.68419 10.3843 9.83422 10.2343C9.98425 10.0843 10.1877 10 10.3999 10ZM3.1999 2C3.41208 2 3.61556 2.08429 3.76559 2.23431C3.91562 2.38434 3.9999 2.58783 3.9999 2.8V14C3.9999 14.2122 3.91562 14.4157 3.76559 14.5657C3.61556 14.7157 3.41208 14.8 3.1999 14.8C2.98773 14.8 2.78425 14.7157 2.63422 14.5657C2.48419 14.4157 2.3999 14.2122 2.3999 14V2.8C2.3999 2.58783 2.48419 2.38434 2.63422 2.23431C2.78425 2.08429 2.98773 2 3.1999 2Z"
        fill="#1565C0"
      />
    </svg>
  )
}
