import { Dialog } from "@mui/material"
import {
  DialogStyle,
  CloseWrapper,
  Close,
  ContentWrapper,
  Text,
  Title,
  UnorderList,
  Paragraph,
  ThinText,
} from "./HelperModal.style.js"

const HelperTableModal = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      aria-labelledby="Popup Helper"
      aria-describedby="Participant Panel Popup"
      open={isOpen}
      onClose={handleClose}
      PaperProps={DialogStyle}
      elevation={0}
    >
      <CloseWrapper>
        <Close onClick={handleClose} />
      </CloseWrapper>
      <ContentWrapper style={{}}>
        <Title>Как прокрутить таблицу в сторону?</Title>
        <Paragraph>
          <UnorderList>
            <li>
              <Text>С помощью полосы прокрутки под таблицей в нижний части экрана.</Text>
            </li>
            <li>
              <Text>
                Навести курсор на таблицу, зажать <ThinText>Shift</ThinText> и вращать{" "}
                <ThinText>колёсико мыши</ThinText>.
              </Text>
            </li>
            <li>
              <Text>
                Выделить текст строки с помощью зажатой <ThinText>левой кнопки мыши</ThinText>, перемещая курсор к краю
                экрана.
              </Text>
            </li>
            <li>
              <Text>
                Зажать <ThinText>среднюю кнопку мыши (колёсико мыши)</ThinText>, перемещая курсор к краю экрана.
              </Text>
            </li>
            <li>
              <Text>
                С помощью тачпада ноутбука. Навести курсор на таблицу, переместить два пальца в сторону края тачпада.
              </Text>
            </li>
            <li>
              <Text>
                Перместите курсор к краю экрана. Наведите курсор на появившуюся кнопку, чтобы начать прокрутку таблицы.
              </Text>
            </li>
          </UnorderList>
        </Paragraph>
      </ContentWrapper>
    </Dialog>
  )
}

export default HelperTableModal
