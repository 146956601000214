import React, { useState, useEffect } from "react"
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { JsonEditor } from "shared/assets"
import { AdminCourse, Participants, QuestionnaireEditor } from "widgets"
import { updateCourse, addAffiliations, removeAffiliations, sortStages, settingsConfig } from "entities/Course"
import { Autocomplete } from "shared/ui"
import { ParticipantsRequested, ParticipantsOther, ParticipantsInProgress } from "features"
import { getCourse } from "entities/Course/api/course"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { courseActions } from "shared/store"

export const CoursePannel = props => {
  const [course, setCourse] = useState(null)
  const [variant, setVariant] = useState(!!props.course ? { value: props.course, label: "" } : null)
  const [content, setContent] = useState({})
  const [helperText, setHelperText] = useState("")
  const [presets, setPresets] = useState(null)
  const [stages, setStages] = useState(null)
  const [infoFields, setInfoFields] = useState(null)
  const [invitationLink, setInvitationLink] = useState(null)
  const [existingOrgs, setExistingOrgs] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [courseVariants, setCourseVariants] = useState([])
  const [isTriggered, setIsTriggered] = useState(false)
  const [organizationMain, setOrganizationMain] = useState(null)
  const dispatch = useAppDispatch()

  const triggerReloading = () => {
    setIsTriggered(true)
  }

  function updateContent(value, field) {
    content[field] = value
    setContent({ ...content })
    setHelperText("")
  }

  function clearOnUpdate() {
    setContent({})
    setPresets(null)
    setStages(null)
    setInfoFields(null)
    dispatch(courseActions.setCourse(null))
  }

  function updateInfoFields() {
    axios.get(`/content/courses/${variant.value}/`).then(res => {
      let course = res.data
      if (course.info_fields) {
        course.info_fields.map(e => (e.props = JSON.parse(e.props)))
        setInfoFields([...course.info_fields])
      }
    })
  }

  async function findCourse() {
    clearOnUpdate()
    try {
      const courseResponse = await getCourse(variant.value)
      setContent({ ...courseResponse })
      setCourse(courseResponse)
      axios.get(`/content/courses/${variant.value}/`).then(res => {
        let course = res.data
        axios.get(`/organizations/${course.owner}/`).then(res_org => {
          setOrganizationMain(res_org.data)
        })
        if (course.stages) {
          setStages(course.stages.sort(sortStages))
        } else {
          setStages([])
        }
        if (course.info_fields) {
          course.info_fields.map(e => (e.props = JSON.parse(e.props)))
          setInfoFields([...course.info_fields])
        } else {
          setInfoFields([])
        }
      })
      if (courseResponse) {
        if (courseResponse.template_props) {
          setPresets(JSON.parse(courseResponse.template_props))
        } else {
          setPresets({})
        }
        setHelperText("")
        axios.get("/organizations/").then(res => {
          setExistingOrgs(res.data)
        })
        axios.get(`/content/courses/${courseResponse.id}/get_invitation_link/`).then(res => {
          setInvitationLink(res.data)
        })
        dispatch(courseActions.setCourse(courseResponse))
      } else {
        setHelperText("Does not exist")
        dispatch(courseActions.setCourse(null))
      }
    } catch {
      setHelperText("Server error")
      dispatch(courseActions.setCourse(null))
    }
  }

  function setMainOrganization() {
    axios.put()
  }

  const onUpdateSubmit = () => {
    setHelperText("Confirmed")
    infoFields.map(e => {
      if (e.props && Object.keys(e.props).length) e.props = JSON.parse(e.props)
    })
    setInfoFields([...infoFields])
  }

  const onUpdateError = () => {
    setHelperText("Error")
  }

  // function updateParticipantsState(current_user, status) {
  //   let participants = [...content.participants]
  //   for (let index = 0; index < participants.length; index++) {
  //     if (participants[index].user === current_user) {
  //       participants[index].status = status
  //       break
  //     }
  //   }
  //   setContent({ ...content, participants: participants })
  // }

  useEffect(() => {
    if (!props.course) {
      axios.get("/content/actions/participants/my_courses/", { params: { status: "moderator" } }).then(res => {
        setCourseVariants([...res.data.map(e => ({ value: e.course, label: e.course_title }))])
      })
    } else {
      axios.get(`/content/courses/${variant.value}/`).then(res => {
        setVariant({ label: res.data.title, value: res.data.id })
        setCourse({ ...res.data })
        findCourse()
        axios.get("/content/actions/participants/my_courses/", { params: { status: "moderator" } }).then(res => {
          setCourseVariants([...res.data.map(e => ({ value: e.course, label: e.course_title }))])
        })
      })
    }
  }, [props.course])

  function saveOrganization() {
    axios
      .put(`/content/courses/${course.id}/update_owner/`, { owner: organizationMain.id })
      .then(res => {
        setHelperText("Организация установлена")
      })
      .catch(err => {
        setHelperText("Ошибка")
      })
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Autocomplete
          options={courseVariants}
          value={variant}
          onChange={(event, newValue) => setVariant(newValue)}
          label={"Выбор курса"}
          style={{ width: "400px" }}
        />
        <Button
          onClick={findCourse}
          variant="contained"
          color="primary"
          size="large"
          disabled={!variant}
          style={{ width: 150, height: 50, margin: 24 }}
        >
          Найти курс
        </Button>
      </div>
      {content.title && (
        <Typography
          style={{ marginBottom: "50px" }}
          variant="h5"
        >{`Название курса/события: ${content.title}`}</Typography>
      )}
      {invitationLink && <Typography variant="h5">{`Ссылка для приглашения: ${invitationLink}`}</Typography>}
      <div style={{ width: "100%", padding: 48 }}>
        <Typography variant="h5" style={{ textAlign: "center", color: "red" }}>
          {helperText}
        </Typography>
        <ParticipantsRequested triggerReloading={triggerReloading} pageLocation={"admin_courses"} />
        <ParticipantsInProgress
          isTriggered={isTriggered}
          setIsTriggered={setIsTriggered}
          pageLocation={"admin_courses"}
        />
        <ParticipantsOther isTriggered={isTriggered} setIsTriggered={setIsTriggered} pageLocation={"admin_courses"} />
        {/* {content.participants && (
                    <>
                        <Typography variant="h3" style={{textAlign: "left", marginBottom: 24}}>
                            Заявки на участие в курсе:
                        </Typography>
                        <Participants
                            course={course.id}
                            categories={presets.categories}
                            infoFields={infoFields}
                            participants={content.participants.filter(e => e.status === "requested")}
                            type="requests"
                            updateParticipantsState={updateParticipantsState}
                        />
                        <Typography variant="h3" style={{textAlign: "left", marginBottom: 24}}>
                            Участники курса:
                        </Typography>
                        <Participants
                            course={course.id}
                            infoFields={infoFields}
                            stages={content.stages
                .filter(e => !e.is_empty)
                .filter(e => e.access_type === "restricted")
                                .map(e => ({label: e.title, value: e.id}))}
                            participants={content.participants.filter(e => e.status === "in_progress" || e.status === "mentor")}
                            type="active"
                        />
                        <Typography variant="h3" style={{textAlign: "left", marginBottom: 24}}>
                            Другие пользователи на курсе:
                        </Typography>
                        <Participants
                            course={course.id}
                            infoFields={infoFields}
                            participants={content.participants.filter(
                                e => e.status !== "in_progress" && e.status !== "mentor" && e.status !== "requested"
                            )}
                        />
                    </>
        )} */}
        {(props.status === "admin" || props.status === "moderator") && (
          <>
            {Object.entries(content).map(
              c =>
                settingsConfig(content.type)[c[0]]?.visibility === true && (
                  <TextField
                    variant="outlined"
                    value={c[1]}
                    onChange={e => updateContent(e.target.value, c[0])}
                    placeholder={getLabel(content.type)[c[0]] || c[0]}
                    disabled={
                      ((c[0] === "registration_start" || c[0] === "registration_finish") &&
                        content.registration_type === "manual") ||
                      (c[0] === "is_registration_opened" && content.registration_type === "auto")
                    }
                    helperText={getLabel(content.type)[c[0]] || c[0]}
                    fullWidth
                    multiline
                    style={{ margin: 12 }}
                  />
                )
            )}
            {!!course && <AdminCourse course_id={course.id} course_tags={course.tags_info} />}
            {!!course && !!existingOrgs && !!organizationMain && (
              <>
                <Typography variant={"body1"}>Организатор курса:</Typography>
                <FormControl
                  style={{
                    display: "flex",
                    gap: 16,
                    flexDirection: "row",
                    marginTop: "24px",
                    marginBottom: "24px",
                  }}
                >
                  <Autocomplete
                    style={{ width: "300px" }}
                    value={{ label: organizationMain.title, value: organizationMain.id } || null}
                    options={existingOrgs.map(e => ({ id: e.id, label: e.title }))}
                    label={"Выберите организацию"}
                    onChange={(e, newValue) => {
                      setOrganizationMain(existingOrgs.filter(e => newValue.id === e.id)[0])
                    }}
                  />
                  <Button sx={{ width: "200px" }} onClick={saveOrganization} variant={"outlined"} color={"primary"}>
                    Сохранить
                  </Button>
                </FormControl>
              </>
            )}
            <Typography variant="body1" style={{ textAlign: "left", marginBottom: 24 }}>
              Affiliations: {content.affiliations_info && content.affiliations_info.map(org => org.title + "; ")}
            </Typography>
            <FormControl sx={{ minWidth: 240, marginRight: 24 }}>
              <InputLabel id="org-label">Выбрать организацию</InputLabel>
              <Select
                labelId="org-label"
                id="org"
                variant={"outlined"}
                input={<OutlinedInput label={"Выбрать организацию"} />}
                value={organization}
                onChange={e => setOrganization(e.target.value)}
              >
                {existingOrgs && existingOrgs.map(org => <MenuItem value={org.id}>{org.title}</MenuItem>)}
              </Select>
            </FormControl>
            <Button
              onClick={() => addAffiliations(course, [organization], setHelperText("Добавлено"))}
              variant="outlined"
              color="primary"
              size="large"
              style={{ width: 150, height: 50 }}
            >
              Добавить
            </Button>
            <Button
              onClick={() => removeAffiliations(course, [organization], setHelperText("Удалено"))}
              variant="outlined"
              color="primary"
              size="large"
              style={{ width: 150, height: 50 }}
            >
              Удалить
            </Button>
            {presets && (
              <>
                <Typography variant="h5">Настройки шаблона</Typography>
                <JsonEditor data={presets} setter={setPresets} />
              </>
            )}
            {infoFields && (
              <QuestionnaireEditor info={infoFields || []} course_id={course.id} loadCourse={updateInfoFields} />
            )}
            {stages && (
              <>
                <Typography variant="h5">Этапы</Typography>
                <JsonEditor data={stages} setter={setStages} />
              </>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  updateCourse({ ...content }, stages, presets, [...infoFields], onUpdateSubmit, onUpdateError)
                  window.scrollTo(0, 0)
                }}
                variant="outlined"
                disabled={!Object.keys(content).length}
                color="primary"
                size="large"
                style={{ width: 150, height: 50 }}
              >
                Обновить
              </Button>
              <Button
                onClick={clearOnUpdate}
                variant="outlined"
                color="primary"
                size="large"
                style={{ width: 150, height: 50 }}
              >
                Сбросить заполнение
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const getLabel = type => {
  return {
    start:
      type === "webinar"
        ? "Дата и время начала (пример: 2024-05-05T09:00:00Z) по UTC"
        : "Дата начала в формате YYYY:MM:DD",
    finish:
      type === "webinar"
        ? "Дата и время окончания (пример: 2024-05-05T09:00:00Z) по UTC"
        : "Дата окончания в формате YYYY:MM:DD",
    image: "Изображение на плитке курса/вебинара",
    price: "Цена курса в копейках",
    payment_status: "Платный/бесплатный курс (указать free или paid)",
  }
}
