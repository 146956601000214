import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  projectContainer: {
    paddingBottom: 96,
    paddingLeft: 124,
    paddingRight: 124,
    gap: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  eventsContainer: {
    paddingTop: 88,
    "& > div ": {
      border: 0,
      borderRadius: 12,
      marginBottom: 40,
    },
  },

  btnEventsAll: {
    alignSelf: "center",
    paddingTop: 36,
  },
}))
