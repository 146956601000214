import {createAsyncThunk} from "@reduxjs/toolkit";
import { axiosInstance as axios } from "shared/api/axiosInstance";

export const fetchAchievements = createAsyncThunk(
    'achievement/fetchAchievements',
    async () => {
        const response = await axios.get("/achievements/my_achievements/")
        return response.data
    }
)
