import { axiosInstance as axios } from "shared/api/axiosInstance"

export async function fetchTeamProps(courseId) {
  const response = await axios.get(`content/courses/${courseId}/`)
  const teamProps = response.data?.team_props ? JSON.parse(response.data?.team_props) : []
  return teamProps
}

export function getTeamCategories(teamProps) {
  let categories = {}
  teamProps.members_categories?.split(",").map(category => {
    categories = { ...categories, [category]: category }
  })
  categories = { moderator: "moderator", ...categories }
  return categories
}

export function getTeamLimit(teamProps) {
  return teamProps.max_members || Infinity
}
