import { styled } from "@mui/material"
import { ChangeEvent, MouseEventHandler } from "react"

interface IProps {
  isChecked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
  isDisabled?: boolean
}

const Checkbox = ({ isChecked, onChange, label, isDisabled }: IProps) => {
  const handleChange = (e: any) => {
    if (isDisabled) return
    const event = e as ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  return (
    <Wrapper isDisabled={isDisabled}>
      <Container isDisabled={isDisabled}>
        <Input id="checkbox_component" type="checkbox" checked={isChecked} onChange={handleChange} />
        <Checkmark isChecked={isChecked} onClick={handleChange} isDisabled={isDisabled} />
      </Container>
      <Label>{label}</Label>
    </Wrapper>
  )
}

const Wrapper = styled("div")(({ isDisabled }: { isDisabled?: boolean }) => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
  opacity: isDisabled ? 0.7 : 1,
}))

const Container = styled("div")(({ isDisabled }: { isDisabled?: boolean }) => ({
  display: "flex",
  position: "relative",

  "&:hover > input": {
    backgroundColor: !isDisabled && "#F0F5FF",
  },
}))

const Input = styled("input")(
  ({}: { id?: string; type?: string; checked?: boolean; onChange?: (e: ChangeEvent<HTMLInputElement>) => void }) => ({
    width: 24,
    height: 24,
    backgroundColor: "white",
    borderRadius: "4px",
    border: "1px solid #313439",
    appearance: "none",
    outline: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
  })
)

const Checkmark = styled("span")(
  ({
    isChecked,
    isDisabled,
  }: {
    isChecked: boolean
    isDisabled?: boolean
    onClick?: (e: MouseEventHandler<HTMLSpanElement>) => void
  }) => ({
    position: "absolute",
    top: 3,
    left: 4,
    height: 24,
    width: 24,
    backgroundColor: "#1565C0",
    opacity: isChecked ? "1" : "0",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s, opacity 0.3s",

    "&:after": {
      content: "''",
      position: "absolute",
      left: "10px",
      top: "5px",
      width: "5px",
      height: "11px",
      border: "solid white",
      borderWidth: "0 2px 2px 0",
      transform: "rotate(45deg)",
    },

    "&:hover": {
      backgroundColor: !isDisabled && "#145197",
    },
  })
)

const Label = styled("label")(({}: { for?: string }) => ({
  color: "#313439",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "200%",
  cursor: "inherit",
}))

export { Checkbox }
