import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"

export function editUser(profile, onSuccess, onError) {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  axios.patch(`/profiles/${profile.id}/`, profile).then(onSuccess).catch(onError)
}

export function changePassword(password, onSuccess, onError) {
  axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
  axios
    .patch("/profiles/password/", {
      current_password: password.currentPassword,
      new_password: password.newPassword,
    })
    .then(onSuccess)
    .catch(onError)
}
