import { styled } from "@mui/material/styles"

export const ButtonWrapper = styled("div")(({}) => ({
  width: "15%",
  zIndex: 1,
  position: "absolute",
  top: "50%",
  opacity: 0,
  transition: "opacity 0.5s",
  "&:hover": {
    opacity: 1,
  },
}))

const ScrollButton = styled("button")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  height: theme.spacing(25),
  padding: "34px 7px 34px 8px",
  border: "none",
  zIndex: 200,
  backgroundColor: "#9D9D9DE5",
  "&:hover": {
    backgroundColor: "#656565E5",
  },
}))

export const ScrollButtonLeft = styled(ScrollButton)(({ theme }) => ({
  left: 0,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
}))

export const ScrollButtonRight = styled(ScrollButton)(({ theme }) => ({
  right: 0,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
}))
