export const parseTime = (timer: string) => {
  if (!timer) return
  const timeArray = timer.split(":")
  const isHour = timeArray[0] !== "00"
  const isMinute = timeArray[1] !== "00"
  const hour = isHour ? `${timeArray[0]} час${getHourEnding(timeArray[0])} ` : ""
  const minute = isMinute ? `${timeArray[1]} минут${getMinuteEnding(timeArray[1])}` : ""
  return hour + minute
}

const getHourEnding = (value: string) => {
  const digit = Number(value.slice(-2))

  if (10 <= digit && digit <= 20) return "ов"
  if (digit === 0 || 5 <= digit) return "ов"
  if (digit === 1) return ""
  if (2 <= digit && digit <= 4) return "а"
}

const getMinuteEnding = (value: string) => {
  const digit = Number(value.slice(-1))

  if (digit === 0 || 5 <= digit) return ""
  if (digit === 1) return "а"
  if (2 <= digit && digit <= 4) return "ы"
}

export const parseDeadline = (deadline: string, isSeconds?: boolean): string | undefined => {
  if (!deadline) return

  const options = {
    timeZone: "Europe/Moscow",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: isSeconds && "numeric",
    hour12: false,
  }

  // @ts-expect-error
  const formatter = new Intl.DateTimeFormat("ru-RU", options)
  const currentDateAndTime = formatter.format(new Date(deadline))

  return currentDateAndTime
}
